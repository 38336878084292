.chart-doc-page {

    .content {
        max-width: 800px;
        .c3 {
            > svg {
                min-height: 300px;
            }
        }
    }

}