#goods-receipts-add {

    > .header {
        height: 120px;
        min-height: 120px;
        max-height: 120px;

        .goto-goods-receipts-button {
            margin-right: 16px;
        }

        .subtitle {
            margin: 6px 0 0 0;
        }

        .md-icon-button {
            
            &:hover {
                background-color: #f2f2f233;
            }

            md-icon {
                color: #fafafa;
            }

            &[disabled] {

                md-icon {
                    color: #fafafa69;
                }
            }
        }
    }

    > .content {
        padding: 24px;

        .detail-form-container {
            padding: 24px;
            margin-bottom: 24px;
            max-width: 990px;
    
            form {
                font-size: 12px!important;
    
                table.simple {
                    thead {
        
                        tr {
                            th {
                                padding: 0 5px 0 5px;
                            }
    
                            th.unit-price, th.quantity, th.net-total {
                                text-align: right;
                                padding-right: 9px;
                            }
    
                            th.actions {
                                text-align: center;
                            }
        
                        }
        
                    }
        
                    tbody {
        
                        tr {
                            padding-top: 0;
                            padding-bottom: 0;
        
                            td {
                                padding: 0 5px 0 5px;
                                border-bottom: none;
                            }
    
                            td.quantity, td.unit-price, td.net-total {
                                width: 81px;
                                max-width: 81px;
                                min-width: 81px;
                                text-align: right;
    
                                md-input-container {
                                    width: inherit;
                                    margin: 0;
        
                                    .md-errors-spacer {
                                        min-height: 6px;
                                    }
    
                                    /* Chrome, Safari, Edge, Opera */
                                    input::-webkit-outer-spin-button,
                                    input::-webkit-inner-spin-button {
                                        -webkit-appearance: none;
                                        margin: 0;
                                    }
    
                                    /* Firefox */
                                    input[type=number] {
                                        -moz-appearance:textfield;
                                    }
    
                                    input {
                                        text-align: end;
                                    }
                                }
                            }

                            md-select {
                                margin: 0 0 6px 0;
                            }
        
                            td.unit .md-select-value {
                                width: 81px;
                                max-width: 81px;
                                min-width: 81px;
                            }
        
                            td.item .md-select-value {
                                width: 300px;
                                max-width: 300px;
                                min-width: 300px;
                            }
        
                            td.actions {
                                text-align: center;
    
                                .md-button {
                                    min-width: auto;
                                    min-height: auto;
                                    line-height: unset;
    
                                    .icon-delete {
                                        font-size: 18px;
                                        width: 18px;
                                        height: 18px;
                                        min-width: 18px;
                                        min-height: 18px;
                                        line-height: 18px;
                                    }
                                }
                            }
    
                            md-input-container {
                                width: inherit;
                                margin: 0;
    
                                .md-errors-spacer {
                                    min-height: 6px;
                                }
                            }
                        }
        
                        tr:first-child td {
                            padding-top: 15px!important;
                        }
        
                    }
        
        
                }

                md-input-container.remarks {
                    margin-bottom: 18px;
                }
        
                md-input-container.supplier {
                    margin-bottom: 0;
                    margin-top: 0;
                }
    
                md-input-container.city {
                    margin-bottom: 0;
                    margin-top: 0;
                }
    
                md-input-container.date {
                    margin-bottom: 18px;
                }

                md-input-container.receipt-number {
                    margin-top: 0px;
                    margin-bottom: 0px;
                }

                md-input-container.city {
                    margin-top: 18px;
                    margin-bottom: 0px;
                }

                md-input-container.country {
                    margin-top: 36px;
                    margin-bottom: 0px;
                }
    
                md-input-container.text-area {
                    margin-top: 36px;
                    margin-bottom: 0px;
                }
    
                .header-col:last-child {
                    margin-left: 15px;
                }
            }
        }
    }

}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-xs) {

    #goods-receipts-add {

        > .header {
            padding: 24px 0;
        }

        > .content {
            padding: 0;

            .detail-form-container {

                form {
                    
                    .header-col:last-child {
                        margin-left: 0!important;
                    }
                }
            }
        }
    }

}
