md-dialog {

    &.change-plan-dialog {
        max-width: 600px;
        width: 600px;

        form {
            font-size: 14px;

            md-dialog-content {

                ms-widget {
                    width: 270px;
                    max-width: 270px;
                    min-width: 270px;
                    margin-right: auto;
                    margin-left: auto;
                }
            }

            md-input-container.text {
                margin: 3px 0;
            }

            md-input-container.select {
                margin: 3px 6px;
            }

            md-input-container.select:first-child {
                margin-left: 0px;
            }

            md-input-container.select:last-child {
                margin-right: 0px;
            }

            .cc-panel {
                padding: 18px 9px;
            }

            .amount-panel {
                margin-top: 24px;
                margin-bottom: 24px;

                table.simple {
        
                    tbody { 
                        tr {
                            td {
                                padding: 9px 3px;
                            }
    
                            td:first-child {
                                padding-left: 9px;
                            }
    
                            td:last-child {
                                text-align: right;
                                padding-right: 9px;
                            }
                        }
                    }
                }

                p {
                    font-size: 12px;
                }
            }

            table.simple {
                margin-top: 30px;
                
                thead {

                    tr {

                        th {
                            padding: 8px;

                            &:first-child {
                                text-align: left;
                            }

                            &:last-child {
                                text-align: right;
                            }
                        }
                    }
                }
            }
        }

    }
}