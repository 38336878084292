md-dialog {

    &.quotation-email-dialog {
        max-width: 720px;
        width: 720px;

        md-toolbar {

            .title {
                font-size: 17px;
            }
        }

        md-dialog-content {
            display: block;
            position: relative;

            .toLabel {
                font-size: 12px;
                color: rgba(0,0,0,0.54);
            }

            md-input-container {
                margin-bottom: 0px;
            }

            md-input-container.subject {
                margin-top: 36px;
                margin-bottom: 12px;
            }

            .md-errors-spacer {
                min-height: 9px;
                height: 9px;
            }

            .ta-scroll-window > .ta-bind {
                height: auto;
                min-height: 240px;
                padding: 6px 12px;
            }

            .ta-editor.ta-html, .ta-scroll-window.form-control {
                min-height: 240px;
                height: auto;
                overflow: auto;
                font-family: inherit;
                font-size: 100%;

                i {
                    font-size: inherit;
                    width: inherit;
                    height: inherit;
                    min-width: inherit;
                    min-height: inherit;
                    line-height: inherit;
                }
            }

            .to {
                position: relative;

                &.hidden-cc {

                    input {
                        padding-right: 32px;
                    }

                    &.hidden-bcc {

                        input {
                            padding-right: 56px;
                        }
                    }
                }

                &.hidden-bcc {

                    input {
                        padding-right: 32px;
                    }
                }

                .cc-bcc {
                    position: absolute;
                    bottom: 8px;
                    right: 0;

                    .show-cc,
                    .show-bcc {
                        cursor: pointer;
                        font-size: 12px;
                    }

                    .show-bcc {
                        margin-left: 8px;
                    }
                }

            }
        }

        md-dialog-actions {
            position: relative;
            justify-content: space-between;
            border-top: 1px solid rgba(0, 0, 0, 0.12);
            background-color: rgba(0, 0, 0, 0.03);
        }
    }
}



#rfqEmailContactChips {

    md-content.autocomplete {
        min-height: 250px;

        input {
            min-width: 400px;
        }
    }

    .md-item-text.compact {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .contact-item {
        box-sizing: border-box;

        &.selected {
            background-color: #E3ECF5;

            p {
                color: rgba(0, 0, 0, 0.87);
                font-weight: 400;
            }
        }

        .md-list-item-text {
            padding: 14px 0;
            max-width: 190px;

            h3 {
                margin: 0 !important;
                padding: 0;
                line-height: 1.2em !important;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden; 
            }

            p {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }
    }

    md-contact-chips {
        margin-bottom: 10px;
    }
      
    .md-chips {
        padding: 5px 0 8px;

        .md-chip-input-container {
            width: 100%;
        }
    }

} 

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-sm) {

    md-dialog {

        &.quotation-email-dialog {
            width: 80%;
        }
    }
}

@media screen and (max-width: $layout-breakpoint-xs) {

    md-dialog {

        &.quotation-email-dialog {
            width: 90%;
        }
    }
}

@media (min-width: 960px) {
    #rfqEmailContactChips {

        .contact-item {
            float: left;
            width: 33%;
        }
    }
}