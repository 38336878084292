#subscription-success {

    .content {
        padding: 24px;
        background: #fff;
        margin: 12px auto;
        max-width: 600px;
        text-align: center;
        margin-top: 10%;

        md-icon {
            color: #4caf50;
        }

        .md-button {
            text-transform: inherit;
        }

        p {
            text-align: center;
        }

        table {
            width: 100%;
            border: 1px solid #cac8c8;
            
            tr {
                td {
                    text-align: left;
                }

                td:first-child {
                    width: 105px;
                }
            }
        }
    }
}


@media only screen and (max-width: $layout-breakpoint-xs) {
    
    #subscription-success {

        .content {
            margin: 0;
        }
    }
}