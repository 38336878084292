md-dialog {

    &.verification-warning-dialog {
        max-width: 500px;
        width: 500px;

        md-toolbar {
            .title {
                font-size: 17px;
            }
        }

        md-dialog-content {
            display: block;
            position: relative;
            font-size: 15px;

            table {
                margin-top: 20px;
                font-size: 12px;
                tr {
                    td {
                        border: 1px solid #c0c8d4;
                        padding: 5px;
                    }
                }
            }
        }

        md-dialog-actions {
            position: relative;
            overflow-y: hidden;
            overflow-x: auto;
            justify-content: space-between;
            background-color: rgba(0, 0, 0, 0.03);
            border-top: 1px solid rgba(0, 0, 0, 0.12);
        }
    }
}