md-dialog {

    &.request-dialog {
        max-width: 1200px;
        width: 1200px;

        md-toolbar {
            height: 60px;
            min-height: 60px;
            max-height: 60px;

            .title {
                font-size: 17px;
            }
        }

        md-dialog-content {
            display: block;
            position: relative;
            height: 400px;
            max-height: 400px;
            min-height: 400px;

            table {
                margin-top: 0px;
                font-size: 12px;
                thead {

                    tr {

                        th {
                            font-weight: 600;
                            padding: 3px 21px 0 12px;

                            &:first-child {
                                padding-left: 8px;
                            }

                            &:last-child {
                                padding-right: 8px;
                                width: 60px;
                                max-width: 60px;
                                min-width: 60px;
                            }
                        }
                    }
                }

                tbody {

                    tr {

                        &:first-child {
                            td {
                                padding-top:30px;
                            }
                        }

                        td {
                            padding: 6px 6px 6px 12px;
                            border-bottom: none;

                            &:first-child {
                                padding-left: 8px;
                            }

                            &:last-child {
                                padding-right: 8px;
                                width: 60px;
                                max-width: 60px;
                                min-width: 60px;
                            }

                            &.quantity {
                                width: 120px;
                                max-width: 120px;
                                min-width: 120px;

                                md-input-container {
                                    width: 90px;
                                    max-width: 90px;
                                    min-width: 90px;

                                    /* Chrome, Safari, Edge, Opera */
                                    input::-webkit-outer-spin-button,
                                    input::-webkit-inner-spin-button {
                                        -webkit-appearance: none;
                                        margin: 0;
                                    }

                                    /* Firefox */
                                    input[type=number] {
                                        -moz-appearance:textfield;
                                    }

                                    input {
                                        text-align: end;
                                    }
                                }
                            }

                            &.unit {
                                width: 90px;
                                max-width: 90px;
                                min-width: 90px;

                                md-input-container {
                                    width: 90px;
                                    max-width: 90px;
                                    min-width: 90px;
                                }
                            }

                            &.desc {
                                width: 180px;
                                max-width: 180px;
                                min-width: 180px;

                                md-input-container {
                                    width: 180px;
                                    max-width: 180px;
                                    min-width: 180px;
                                }
                            }

                            .md-errors-spacer { 
                                display: none; 
                            }

                            md-input-container {
                                height: 40px;
                                max-height: 40px;
                                min-height: 40px;
                                margin: 0;
                                padding: 0;
                            }
                            
                            md-autocomplete {

                                > md-autocomplete-wrap {
                                    
                                    > md-input-container {
                                        margin-top: 0px;

                                        > label {
                                            -webkit-transform: translate3d(1px,28px,0) scale(1) !important;
                                            transform: translate3d(1px,28px,0) scale(1) !important;
                                            color: #BDBDBD;  // input placeholder gray
                                        }

                                        &.md-input-has-value {

                                            > label {
                                                display: none !important;
                                            }
                                        }
                                    }
                                }
                            }                          
                        }
                    }
                }
            }
        }

        md-dialog-actions {
            position: relative;
            overflow-y: hidden;
            overflow-x: auto;
            justify-content: space-between;
            background-color: rgba(0, 0, 0, 0.03);
            border-top: 1px solid rgba(0, 0, 0, 0.12);
        }
    }
}