/**
 *  Do not remove this comments bellow. It's the markers used by wiredep to inject
 *  sass dependencies when defined in the bower.json of your dependencies
 */
/**
 *  Do not remove this comments bellow. It's the markers used by gulp-inject to inject
 *  all your sass files automatically
 */
/*----------------------------------------------------------------*/
/*  Reset
/*----------------------------------------------------------------*/
* {
  text-rendering: optimizeLegibility;
  -o-text-rendering: optimizeLegibility;
  -ms-text-rendering: optimizeLegibility;
  -moz-text-rendering: optimizeLegibility;
  -webkit-text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box; }
  *:before, *:after {
    box-sizing: border-box; }
  *:focus {
    outline: none; }

*:not(md-input-container) > input[type="text"],
*:not(md-input-container) > input[type="tel"],
*:not(md-input-container) > input[type="email"],
*:not(md-input-container) > input[type="search"],
*:not(md-input-container) > input[type="password"],
*:not(md-input-container) > input[type="button"],
*:not(md-input-container) > button,
*:not(md-input-container) > input[type="submit"],
*:not(md-input-container) > input[type="image"],
*:not(md-input-container) > textarea {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border: none;
  padding: 0;
  margin: 0; }

*:not(md-input-container) > input[type="button"],
*:not(md-input-container) > button,
*:not(md-input-container) > input[type="submit"] {
  background: none; }

button {
  border-radius: 0;
  font-family: inherit;
  font-size: inherit;
  padding: 0;
  margin: 0;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none; }

/*----------------------------------------------------------------*/
/*  Variables
/*----------------------------------------------------------------*/
/*----------------------------------------------------------------*/
/*  Mixins
/*----------------------------------------------------------------*/
/*----------------------------------------------------------------*/
/*  Angular Material Extend
/*  Adds and/or modifies Angular Material styles
/*----------------------------------------------------------------*/
/* --- FIXES --- */
[layout] > * {
  min-height: auto;
  min-height: initial; }

md-card > img,
md-card > :not(md-card-content) img {
  height: inherit;
  height: initial; }

.md-datepicker-calendar-pane * {
  box-sizing: initial; }

/* --- MODIFICATIONS --- */
md-autocomplete-wrap > input[type="search"] {
  background: #FFFFFF;
  padding: 0 15px !important; }

md-backdrop.md-opaque {
  background-color: rgba(33, 33, 33, 0.48) !important; }

md-card md-card-header md-card-avatar md-icon {
  width: 40px;
  height: 40px; }

md-checkbox.no-label {
  margin: 0; }

md-chip .md-chip-remove md-icon {
  margin-left: 2px; }
  md-chip .md-chip-remove md-icon svg {
    width: 18px;
    height: 18px;
    max-width: 18px;
    max-height: 18px;
    margin: 4px; }

md-datepicker .md-datepicker-button {
  padding: 0 !important;
  margin: 0 !important;
  vertical-align: middle; }

md-datepicker .md-datepicker-input-container {
  margin-left: 0; }

.md-inline-form md-datepicker {
  margin-top: 14px; }

md-dialog md-dialog-content {
  padding: 32px 24px 24px 24px; }

md-dialog md-dialog-actions {
  min-height: 64px;
  padding-top: 8px;
  padding-bottom: 8px; }
  md-dialog md-dialog-actions .md-button {
    margin: 0 8px; }

md-input-container.no-errors-spacer .md-errors-spacer {
  display: none; }

md-input-container .input-hint {
  position: absolute;
  right: auto;
  bottom: 7px;
  left: 2px;
  font-size: 12px;
  line-height: 14px;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
  color: rgba(0, 0, 0, 0.54); }

md-input-container.md-icon-left .input-hint {
  left: 38px; }

md-list-item .md-list-item-text p {
  font-size: 13px !important; }

md-menu,
.md-menu {
  padding: 0; }

md-menu-content.md-menu-bar-menu.md-dense {
  padding: 8px 0; }
  md-menu-content.md-menu-bar-menu.md-dense .md-button,
  md-menu-content.md-menu-bar-menu.md-dense md-menu-item {
    height: 40px;
    line-height: 40px; }
    md-menu-content.md-menu-bar-menu.md-dense .md-button.md-indent > md-icon,
    md-menu-content.md-menu-bar-menu.md-dense md-menu-item.md-indent > md-icon {
      top: 8px; }

.md-open-menu-container md-menu-content md-menu-item.selected {
  opacity: 0.4; }

md-menu-content md-menu-item .md-button {
  text-align: left; }

md-select.simplified {
  margin: 0 8px; }
  md-select.simplified .md-select-value {
    border: none !important;
    box-sizing: border-box;
    padding: 0; }
    md-select.simplified .md-select-value *:first-child {
      transform: none;
      height: auto; }
    md-select.simplified .md-select-value .md-select-icon {
      transform: none;
      font-size: 16px; }
      md-select.simplified .md-select-value .md-select-icon:after {
        top: 1px; }

md-select[multiple] md-select-value > *:first-child {
  display: flex; }
  md-select[multiple] md-select-value > *:first-child > *:first-child {
    flex: 0; }
  md-select[multiple] md-select-value > *:first-child .md-container {
    padding-right: 6px; }
    md-select[multiple] md-select-value > *:first-child .md-container:first-of-type {
      padding-right: 0; }

md-select-menu md-select-header {
  position: relative;
  display: flex;
  align-items: center;
  width: auto;
  height: 48px;
  padding-left: 10.667px;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.12);
  cursor: pointer; }
  md-select-menu md-select-header input[type="search"] {
    border: none;
    outline: none;
    height: 100%;
    width: 100%;
    padding: 0; }

md-sidenav {
  -webkit-overflow-scrolling: touch; }

md-tab-content {
  touch-action: initial !important; }

md-toolbar.colored-toolbar {
  color: rgba(255, 255, 255, 0.87); }
  md-toolbar.colored-toolbar md-menu-bar md-menu._md-open > button {
    background: rgba(0, 0, 0, 0.12); }
  md-toolbar.colored-toolbar md-menu._md-open > button {
    background: rgba(0, 0, 0, 0.12); }
  md-toolbar.colored-toolbar input {
    color: rgba(255, 255, 255, 0.87) !important; }

md-progress-circular[disabled] {
  visibility: hidden; }

/*----------------------------------------------------------------*/
/*  Template Layouts
/*----------------------------------------------------------------*/
html,
body {
  overflow: hidden !important; }

#main {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

body.boxed {
  background: #3F4450; }
  body.boxed #main {
    overflow: hidden !important;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.45); }

#loading-panel {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.6);
  transition: 1s 0.4s; }

#layout-content-only {
  position: relative;
  height: 100%;
  max-height: 100%; }
  #layout-content-only #content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }

#layout-content-with-toolbar {
  position: relative;
  height: 100%;
  max-height: 100%; }
  #layout-content-with-toolbar #content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }

#layout-vertical-navigation {
  height: 100%;
  max-height: 100%; }
  #layout-vertical-navigation #content-container {
    position: relative;
    overflow: hidden; }
    #layout-vertical-navigation #content-container #content {
      position: absolute;
      top: 64px;
      right: 0;
      bottom: 0;
      left: 0; }

#layout-vertical-navigation-fullwidth-toolbar {
  height: 100%;
  max-height: 100%; }
  #layout-vertical-navigation-fullwidth-toolbar #main-container {
    position: relative; }
    #layout-vertical-navigation-fullwidth-toolbar #main-container #content-container {
      position: relative;
      overflow: hidden; }
      #layout-vertical-navigation-fullwidth-toolbar #main-container #content-container #content {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0; }

#layout-vertical-navigation-fullwidth-toolbar-2 {
  height: 100%;
  max-height: 100%; }
  #layout-vertical-navigation-fullwidth-toolbar-2 #main-container {
    position: relative; }
    #layout-vertical-navigation-fullwidth-toolbar-2 #main-container #content-container {
      position: relative;
      overflow: hidden; }
      #layout-vertical-navigation-fullwidth-toolbar-2 #main-container #content-container #content {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0; }

#layout-horizontal-navigation {
  height: 100%;
  max-height: 100%; }
  #layout-horizontal-navigation #content-container {
    position: relative;
    overflow: hidden; }
    #layout-horizontal-navigation #content-container #content {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0; }

/*----------------------------------------------------------------*/
/*  Page Layouts
/*----------------------------------------------------------------*/
.page-layout {
  position: relative;
  overflow: hidden; }
  .page-layout .top-bg {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    left: 0;
    height: 200px;
    background-image: url("/assets/images/backgrounds/header-bg.png");
    background-size: cover; }
  .page-layout md-backdrop {
    z-index: 50; }
  .page-layout > .header .breadcrumb {
    margin-bottom: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.54); }
    .page-layout > .header .breadcrumb md-icon {
      margin: 0; }
    .page-layout > .header .breadcrumb .separator {
      margin: 0 8px; }
  .page-layout > .header .title {
    font-size: 34px; }
  .page-layout.carded {
    min-height: 100%;
    height: 100%; }
    .page-layout.carded.fullwidth.single-scroll {
      height: auto; }
      .page-layout.carded.fullwidth.single-scroll > .center .content-card .content {
        overflow: hidden; }
    .page-layout.carded.fullwidth > .center {
      position: relative;
      z-index: 2;
      margin-left: 32px;
      margin-right: 32px; }
      .page-layout.carded.fullwidth > .center .header {
        height: 136px;
        min-height: 136px;
        max-height: 136px;
        padding: 24px; }
      .page-layout.carded.fullwidth > .center .content-card {
        background: #FFFFFF;
        box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
        overflow: hidden; }
        .page-layout.carded.fullwidth > .center .content-card .toolbar {
          padding: 8px 24px;
          height: 64px;
          min-height: 64px;
          max-height: 64px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
        .page-layout.carded.fullwidth > .center .content-card .content {
          padding: 24px;
          background: #FFFFFF; }
    .page-layout.carded.left-sidenav.single-scroll {
      height: auto; }
      .page-layout.carded.left-sidenav.single-scroll > .center .content-card .content {
        overflow: hidden; }
    .page-layout.carded.left-sidenav > .sidenav {
      width: 240px;
      min-width: 240px;
      max-width: 240px;
      box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
      z-index: 51; }
      .page-layout.carded.left-sidenav > .sidenav .header {
        height: 200px;
        min-height: 200px;
        max-height: 200px;
        padding: 24px; }
      .page-layout.carded.left-sidenav > .sidenav .content {
        background: transparent;
        padding: 24px; }
      .page-layout.carded.left-sidenav > .sidenav.md-locked-open {
        width: 220px;
        min-width: 220px;
        max-width: 220px;
        z-index: 2;
        background: transparent;
        box-shadow: none; }
        .page-layout.carded.left-sidenav > .sidenav.md-locked-open + .center {
          margin-left: 0; }
      .page-layout.carded.left-sidenav > .sidenav:not(.md-locked-open) .header {
        border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
      .page-layout.carded.left-sidenav > .sidenav:not(.md-locked-open) .content {
        overflow: hidden; }
    .page-layout.carded.left-sidenav > .center {
      position: relative;
      z-index: 2;
      margin-left: 32px;
      margin-right: 32px; }
      .page-layout.carded.left-sidenav > .center .header {
        height: 136px;
        min-height: 136px;
        max-height: 136px;
        padding: 24px; }
      .page-layout.carded.left-sidenav > .center .content-card {
        background: #FFFFFF;
        box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
        overflow: hidden; }
        .page-layout.carded.left-sidenav > .center .content-card .toolbar {
          padding: 8px 24px;
          height: 64px;
          min-height: 64px;
          max-height: 64px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
          .page-layout.carded.left-sidenav > .center .content-card .toolbar .sidenav-toggle {
            margin: 0 8px 0 0 !important;
            padding: 0 !important;
            border-radius: 0; }
        .page-layout.carded.left-sidenav > .center .content-card .content {
          padding: 24px;
          background: #FFFFFF; }
    .page-layout.carded.right-sidenav.single-scroll {
      height: auto; }
      .page-layout.carded.right-sidenav.single-scroll > .center .content-card .content {
        overflow: hidden; }
    .page-layout.carded.right-sidenav > .sidenav {
      width: 240px;
      min-width: 240px;
      max-width: 240px;
      box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
      z-index: 51; }
      .page-layout.carded.right-sidenav > .sidenav .header {
        height: 200px;
        min-height: 200px;
        max-height: 200px;
        padding: 24px 24px 24px 0; }
      .page-layout.carded.right-sidenav > .sidenav .content {
        background: transparent;
        padding: 24px 24px 24px 0; }
      .page-layout.carded.right-sidenav > .sidenav.md-locked-open {
        width: 196px;
        min-width: 196px;
        max-width: 196px;
        z-index: 2;
        background: transparent;
        box-shadow: none; }
      .page-layout.carded.right-sidenav > .sidenav:not(.md-locked-open) .header {
        padding: 24px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
      .page-layout.carded.right-sidenav > .sidenav:not(.md-locked-open) .content {
        overflow: hidden;
        padding: 24px; }
    .page-layout.carded.right-sidenav > .center {
      position: relative;
      z-index: 2;
      margin-left: 32px;
      margin-right: 32px; }
      .page-layout.carded.right-sidenav > .center .header {
        height: 136px;
        min-height: 136px;
        max-height: 136px;
        padding: 24px; }
      .page-layout.carded.right-sidenav > .center .content-card {
        background: #FFFFFF;
        box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
        overflow: hidden; }
        .page-layout.carded.right-sidenav > .center .content-card .toolbar {
          padding: 8px 24px;
          height: 64px;
          min-height: 64px;
          max-height: 64px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
          .page-layout.carded.right-sidenav > .center .content-card .toolbar .sidenav-toggle {
            margin: 0 0 0 8px !important;
            padding: 0 !important;
            border-radius: 0; }
        .page-layout.carded.right-sidenav > .center .content-card .content {
          padding: 24px;
          background: #FFFFFF; }
  .page-layout.simple.fullwidth, .page-layout.simple.inner-sidenav {
    min-height: 100%; }
    .page-layout.simple.fullwidth > .header, .page-layout.simple.inner-sidenav > .header {
      height: 200px;
      min-height: 200px;
      max-height: 200px;
      padding: 24px;
      background-image: url("/assets/images/backgrounds/header-bg.png");
      background-size: cover; }
    .page-layout.simple.fullwidth > .content, .page-layout.simple.inner-sidenav > .content {
      padding: 24px; }
  .page-layout.simple.left-sidenav {
    min-height: 100%;
    height: 100%; }
    .page-layout.simple.left-sidenav.single-scroll {
      height: auto; }
      .page-layout.simple.left-sidenav.single-scroll > .center {
        overflow: hidden; }
    .page-layout.simple.left-sidenav.inner-sidenav > .content > md-sidenav {
      margin-right: 24px; }
    .page-layout.simple.left-sidenav .sidenav {
      width: 240px;
      min-width: 240px;
      max-width: 240px;
      padding: 24px;
      z-index: 51;
      box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12); }
      .page-layout.simple.left-sidenav .sidenav.md-locked-open {
        width: 220px;
        min-width: 220px;
        max-width: 220px;
        z-index: 2;
        box-shadow: none;
        background: transparent; }
    .page-layout.simple.left-sidenav > .center {
      position: relative;
      overflow: auto;
      z-index: 3;
      box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12); }
      .page-layout.simple.left-sidenav > .center .header {
        height: 200px;
        min-height: 200px;
        max-height: 200px;
        padding: 24px;
        background-image: url("/assets/images/backgrounds/header-bg.png");
        background-size: cover; }
      .page-layout.simple.left-sidenav > .center .content {
        padding: 24px;
        background: #FFFFFF; }
  .page-layout.simple.right-sidenav {
    min-height: 100%;
    height: 100%; }
    .page-layout.simple.right-sidenav.single-scroll {
      height: auto; }
      .page-layout.simple.right-sidenav.single-scroll > .center {
        overflow: hidden; }
    .page-layout.simple.right-sidenav.inner-sidenav > .content > md-sidenav {
      margin-left: 24px; }
    .page-layout.simple.right-sidenav .sidenav {
      width: 240px;
      min-width: 240px;
      max-width: 240px;
      padding: 24px;
      z-index: 51;
      box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12); }
      .page-layout.simple.right-sidenav .sidenav.md-locked-open {
        width: 220px;
        min-width: 220px;
        max-width: 220px;
        z-index: 2;
        box-shadow: none;
        background: transparent; }
    .page-layout.simple.right-sidenav > .center {
      position: relative;
      overflow: auto;
      z-index: 3;
      box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12); }
      .page-layout.simple.right-sidenav > .center .header {
        height: 200px;
        min-height: 200px;
        max-height: 200px;
        padding: 24px;
        background-image: url("/assets/images/backgrounds/header-bg.png");
        background-size: cover; }
      .page-layout.simple.right-sidenav > .center .content {
        padding: 24px;
        background: #FFFFFF; }
  .page-layout.simple.inner-sidenav {
    height: auto; }
    .page-layout.simple.inner-sidenav > .content > md-sidenav.md-locked-open {
      padding: 0; }
  .page-layout.simple.tabbed {
    min-height: 100%; }
    .page-layout.simple.tabbed > .header {
      height: 200px;
      min-height: 200px;
      max-height: 200px;
      padding: 24px;
      background-image: url("/assets/images/backgrounds/header-bg.png");
      background-size: cover; }
    .page-layout.simple.tabbed > .content > md-tabs > md-tabs-wrapper {
      background: #FFFFFF;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
      padding: 0 24px; }
      .page-layout.simple.tabbed > .content > md-tabs > md-tabs-wrapper md-tabs-canvas {
        height: 56px; }
        .page-layout.simple.tabbed > .content > md-tabs > md-tabs-wrapper md-tabs-canvas md-pagination-wrapper {
          height: 56px; }
        .page-layout.simple.tabbed > .content > md-tabs > md-tabs-wrapper md-tabs-canvas .md-tab {
          padding: 16px 24px;
          text-transform: none; }
    .page-layout.simple.tabbed > .content > md-tabs:not(.md-dynamic-height) md-tabs-content-wrapper {
      top: 56px; }
    .page-layout.simple.tabbed > .content > md-tabs > md-tabs-content-wrapper > md-tab-content {
      padding: 24px; }
    .page-layout.simple.tabbed > .content > md-nav-bar .md-nav-bar {
      background: #FFFFFF;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
      border: none;
      height: 56px; }
      .page-layout.simple.tabbed > .content > md-nav-bar .md-nav-bar nav {
        margin: 0 12px; }
        .page-layout.simple.tabbed > .content > md-nav-bar .md-nav-bar nav .md-nav-item {
          height: 56px; }
          .page-layout.simple.tabbed > .content > md-nav-bar .md-nav-bar nav .md-nav-item ._md-nav-button {
            height: 56px;
            line-height: 32px; }
          .page-layout.simple.tabbed > .content > md-nav-bar .md-nav-bar nav .md-nav-item ._md-nav-button-text {
            text-transform: none; }
  .page-layout.blank {
    min-height: 100%;
    padding: 24px; }

.single-scroll .carded.fullwidth {
  height: auto; }
  .single-scroll .carded.fullwidth > .center .content-card .content {
    overflow: hidden; }

.single-scroll .carded.left-sidenav {
  height: auto; }
  .single-scroll .carded.left-sidenav > .center .content-card .content {
    overflow: hidden; }

.single-scroll .carded.right-sidenav {
  height: auto; }
  .single-scroll .carded.right-sidenav > .center .content-card .content {
    overflow: hidden; }

.single-scroll .simple.left-sidenav {
  height: auto; }
  .single-scroll .simple.left-sidenav > .center {
    overflow: hidden; }

.single-scroll .simple.right-sidenav {
  height: auto; }
  .single-scroll .simple.right-sidenav > .center {
    overflow: hidden; }

@media screen and (max-width: 1280px) {
  .page-layout.simple.inner-sidenav.right-sidenav > .content, .page-layout.simple.inner-sidenav.left-sidenav > .content {
    height: auto !important; }
    .page-layout.simple.inner-sidenav.right-sidenav > .content > md-sidenav, .page-layout.simple.inner-sidenav.left-sidenav > .content > md-sidenav {
      margin-left: 0;
      margin-right: 0; }
  .sidenav-open .page-layout.simple.inner-sidenav {
    height: 100%; } }

@media screen and (max-width: 600px) {
  .page-layout .top-bg {
    height: 160px; }
  .page-layout.carded.right-sidenav > .center, .page-layout.carded.left-sidenav > .center, .page-layout.carded.fullwidth > .center {
    margin-left: 16px;
    margin-right: 16px; }
    .page-layout.carded.right-sidenav > .center .header, .page-layout.carded.left-sidenav > .center .header, .page-layout.carded.fullwidth > .center .header {
      height: 96px;
      min-height: 96px;
      max-height: 96px;
      padding: 16px; }
  .page-layout.carded.fullwidth {
    height: auto; }
    .page-layout.carded.fullwidth > .center .content-card .content {
      overflow: hidden; }
  .page-layout.carded.right-sidenav, .page-layout.carded.left-sidenav {
    height: auto; }
    .page-layout.carded.right-sidenav > .center .content-card .content, .page-layout.carded.left-sidenav > .center .content-card .content {
      overflow: hidden; }
  .page-layout.simple.fullwidth > .header, .page-layout.simple.inner-sidenav > .header {
    height: 160px;
    min-height: 160px;
    max-height: 160px;
    padding: 16px; }
  .page-layout.simple.right-sidenav > .center .header, .page-layout.simple.left-sidenav > .center .header {
    height: 160px;
    min-height: 160px;
    max-height: 160px;
    padding: 16px; }
  .page-layout.simple.left-sidenav, .page-layout.simple.right-sidenav {
    height: auto; }
    .page-layout.simple.left-sidenav > .center, .page-layout.simple.right-sidenav > .center {
      overflow: hidden; } }

/*----------------------------------------------------------------*/
/*  Animations
/*----------------------------------------------------------------*/
.animate-slide-up {
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000px;
  transition-property: none;
  transition-duration: 400ms;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 1; }
  .animate-slide-up.ng-enter {
    transform: translateY(100%);
    opacity: 0; }
    .animate-slide-up.ng-enter.ng-enter-active {
      transition-property: all;
      transform: translateY(0);
      opacity: 1; }
  .animate-slide-up.ng-leave {
    transform: translateY(0);
    opacity: 1; }
    .animate-slide-up.ng-leave.ng-leave-active {
      transition-property: all;
      transform: translateY(-100%);
      opacity: 0; }
  .animate-slide-up.ng-hide {
    transform: translateY(-100%);
    opacity: 0; }
  .animate-slide-up.ng-hide-add-active, .animate-slide-up.ng-hide-remove-active {
    transition-property: all; }

.animate-slide-down {
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000px;
  transition-property: none;
  transition-duration: 400ms;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 1; }
  .animate-slide-down.ng-enter {
    transform: translateY(-100%);
    opacity: 0; }
    .animate-slide-down.ng-enter.ng-enter-active {
      transition-property: all;
      transform: translateY(0);
      opacity: 1; }
  .animate-slide-down.ng-leave {
    transform: translateY(0);
    opacity: 1; }
    .animate-slide-down.ng-leave.ng-leave-active {
      transition-property: all;
      transform: translateY(100%);
      opacity: 0; }
  .animate-slide-down.ng-hide {
    transform: translateY(-100%);
    opacity: 0; }
  .animate-slide-down.ng-hide-add-active, .animate-slide-down.ng-hide-remove-active {
    transition-property: all; }

.animate-slide-left {
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000px;
  transition-property: none;
  transition-duration: 400ms;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 1; }
  .animate-slide-left.ng-enter {
    transform: translateX(100%);
    opacity: 0; }
    .animate-slide-left.ng-enter.ng-enter-active {
      transition-property: all;
      transform: translateX(0);
      opacity: 1; }
  .animate-slide-left.ng-leave {
    transform: translateX(0);
    opacity: 1; }
    .animate-slide-left.ng-leave.ng-leave-active {
      transition-property: all;
      transform: translateX(-100%);
      opacity: 0; }
  .animate-slide-left.ng-hide {
    transform: translateX(-100%);
    opacity: 0; }
  .animate-slide-left.ng-hide-add-active, .animate-slide-left.ng-hide-remove-active {
    transition-property: all; }

.animate-slide-right {
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000px;
  transition-property: none;
  transition-duration: 400ms;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 1; }
  .animate-slide-right.ng-enter {
    transform: translateX(-100%);
    opacity: 0; }
    .animate-slide-right.ng-enter.ng-enter-active {
      transition-property: all;
      transform: translateX(0);
      opacity: 1; }
  .animate-slide-right.ng-leave {
    transform: translateX(0);
    opacity: 1; }
    .animate-slide-right.ng-leave.ng-leave-active {
      transition-property: all;
      transform: translateX(100%);
      opacity: 0; }
  .animate-slide-right.ng-hide {
    transform: translateX(100%);
    opacity: 0; }
  .animate-slide-right.ng-hide-add-active, .animate-slide-right.ng-hide-remove-active {
    transition-property: all; }

.animate-fade-in-out {
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000px;
  transition-property: none;
  transition-duration: 400ms;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 1; }
  .animate-fade-in-out.ng-enter {
    opacity: 0; }
    .animate-fade-in-out.ng-enter.ng-enter-active {
      transition-property: all;
      opacity: 1; }
  .animate-fade-in-out.ng-leave {
    opacity: 1; }
    .animate-fade-in-out.ng-leave.ng-leave-active {
      transition-property: all;
      opacity: 0; }
  .animate-fade-in-out.ng-hide {
    opacity: 0; }
  .animate-fade-in-out.ng-hide-add-active, .animate-fade-in-out.ng-hide-remove-active {
    transition-property: all; }

/*----------------------------------------------------------------*/
/*  @ Custom Animation Keyframes
/*----------------------------------------------------------------*/
@keyframes slide-in-left {
  0% {
    opacity: 0;
    transform: translateX(-500px); }
  65% {
    opacity: 1;
    transform: translateX(0); }
  100% {
    transform: translateX(0); } }

@keyframes slide-in-right {
  0% {
    opacity: 0;
    transform: translateX(500px); }
  65% {
    opacity: 1;
    transform: translateX(0); }
  100% {
    transform: translateX(0); } }

@keyframes slide-in-bottom {
  0% {
    opacity: 0;
    transform: translateY(200px); }
  65% {
    opacity: 1;
    transform: translateY(0); }
  100% {
    transform: translateY(0); } }

.animate-rotate {
  animation: rotate 1.75s linear infinite; }

@keyframes rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/*----------------------------------------------------------------*/
/*  Colors
/*----------------------------------------------------------------*/
.black-fg,
.black-text {
  color: rgba(0, 0, 0, 0.87) !important; }
  .black-fg.secondary-text,
  .black-fg .secondary-text, .black-fg.icon,
  .black-fg .icon,
  .black-text.secondary-text,
  .black-text .secondary-text,
  .black-text.icon,
  .black-text .icon {
    color: rgba(0, 0, 0, 0.54) !important; }
  .black-fg.hint-text,
  .black-fg .hint-text, .black-fg.disabled-text,
  .black-fg .disabled-text,
  .black-text.hint-text,
  .black-text .hint-text,
  .black-text.disabled-text,
  .black-text .disabled-text {
    color: rgba(0, 0, 0, 0.26) !important; }
  .black-fg.divider,
  .black-fg .divider, .black-fg.faint-text,
  .black-fg .faint-text,
  .black-text.divider,
  .black-text .divider,
  .black-text.faint-text,
  .black-text .faint-text {
    color: rgba(0, 0, 0, 0.12) !important; }

.white-fg,
.white-text {
  color: white !important; }
  .white-fg.secondary-text,
  .white-fg .secondary-text, .white-fg.icon,
  .white-fg .icon,
  .white-text.secondary-text,
  .white-text .secondary-text,
  .white-text.icon,
  .white-text .icon {
    color: rgba(255, 255, 255, 0.7) !important; }
  .white-fg.hint-text,
  .white-fg .hint-text, .white-fg.disabled-text,
  .white-fg .disabled-text,
  .white-text.hint-text,
  .white-text .hint-text,
  .white-text.disabled-text,
  .white-text .disabled-text {
    color: rgba(255, 255, 255, 0.3) !important; }
  .white-fg.divider,
  .white-fg .divider, .white-fg.faint-text,
  .white-fg .faint-text,
  .white-text.divider,
  .white-text .divider,
  .white-text.faint-text,
  .white-text .faint-text {
    color: rgba(255, 255, 255, 0.12) !important; }
  .white-fg md-icon,
  .white-text md-icon {
    color: #FFFFFF; }
  .white-fg md-progress-linear .md-container,
  .white-text md-progress-linear .md-container {
    background: rgba(255, 255, 255, 0.3); }
  .white-fg md-progress-linear .md-bar,
  .white-text md-progress-linear .md-bar {
    background: rgba(255, 255, 255, 0.7); }

.red-50-bg {
  background-color: #FFEBEE !important; }

.md-red-50-bg {
  background-color: #FFEBEE !important;
  color: rgba(0, 0, 0, 0.87); }

.red-50-fg {
  color: #FFEBEE !important; }

.red-50-border {
  border-color: #FFEBEE !important; }

.red-50-border-top {
  border-top-color: #FFEBEE !important; }

.red-50-border-right {
  border-right-color: #FFEBEE !important; }

.red-50-border-bottom {
  border-bottom-color: #FFEBEE !important; }

.red-50-border-left {
  border-left-color: #FFEBEE !important; }

.red-100-bg {
  background-color: #FFCDD2 !important; }

.md-red-100-bg {
  background-color: #FFCDD2 !important;
  color: rgba(0, 0, 0, 0.87); }

.red-100-fg {
  color: #FFCDD2 !important; }

.red-100-border {
  border-color: #FFCDD2 !important; }

.red-100-border-top {
  border-top-color: #FFCDD2 !important; }

.red-100-border-right {
  border-right-color: #FFCDD2 !important; }

.red-100-border-bottom {
  border-bottom-color: #FFCDD2 !important; }

.red-100-border-left {
  border-left-color: #FFCDD2 !important; }

.red-200-bg {
  background-color: #EF9A9A !important; }

.md-red-200-bg {
  background-color: #EF9A9A !important;
  color: rgba(0, 0, 0, 0.87); }

.red-200-fg {
  color: #EF9A9A !important; }

.red-200-border {
  border-color: #EF9A9A !important; }

.red-200-border-top {
  border-top-color: #EF9A9A !important; }

.red-200-border-right {
  border-right-color: #EF9A9A !important; }

.red-200-border-bottom {
  border-bottom-color: #EF9A9A !important; }

.red-200-border-left {
  border-left-color: #EF9A9A !important; }

.red-300-bg {
  background-color: #E57373 !important; }

.md-red-300-bg {
  background-color: #E57373 !important;
  color: rgba(0, 0, 0, 0.87); }

.red-300-fg {
  color: #E57373 !important; }

.red-300-border {
  border-color: #E57373 !important; }

.red-300-border-top {
  border-top-color: #E57373 !important; }

.red-300-border-right {
  border-right-color: #E57373 !important; }

.red-300-border-bottom {
  border-bottom-color: #E57373 !important; }

.red-300-border-left {
  border-left-color: #E57373 !important; }

.red-400-bg {
  background-color: #EF5350 !important; }

.md-red-400-bg {
  background-color: #EF5350 !important;
  color: white; }

.red-400-fg {
  color: #EF5350 !important; }

.red-400-border {
  border-color: #EF5350 !important; }

.red-400-border-top {
  border-top-color: #EF5350 !important; }

.red-400-border-right {
  border-right-color: #EF5350 !important; }

.red-400-border-bottom {
  border-bottom-color: #EF5350 !important; }

.red-400-border-left {
  border-left-color: #EF5350 !important; }

.red-500-bg {
  background-color: #F44336 !important; }

.md-red-500-bg {
  background-color: #F44336 !important;
  color: white; }

.red-500-fg {
  color: #F44336 !important; }

.red-500-border {
  border-color: #F44336 !important; }

.red-500-border-top {
  border-top-color: #F44336 !important; }

.red-500-border-right {
  border-right-color: #F44336 !important; }

.red-500-border-bottom {
  border-bottom-color: #F44336 !important; }

.red-500-border-left {
  border-left-color: #F44336 !important; }

.md-red-bg {
  background-color: #F44336 !important;
  color: white; }

.red-bg {
  background-color: #F44336 !important; }

.red-fg {
  color: #F44336 !important; }

.red-border {
  border-color: #F44336 !important; }

.red-border-top {
  border-top-color: #F44336 !important; }

.red-border-right {
  border-right-color: #F44336 !important; }

.red-border-bottom {
  border-bottom-color: #F44336 !important; }

.red-border-left {
  border-left-color: #F44336 !important; }

.red-600-bg {
  background-color: #E53935 !important; }

.md-red-600-bg {
  background-color: #E53935 !important;
  color: white; }

.red-600-fg {
  color: #E53935 !important; }

.red-600-border {
  border-color: #E53935 !important; }

.red-600-border-top {
  border-top-color: #E53935 !important; }

.red-600-border-right {
  border-right-color: #E53935 !important; }

.red-600-border-bottom {
  border-bottom-color: #E53935 !important; }

.red-600-border-left {
  border-left-color: #E53935 !important; }

.red-700-bg {
  background-color: #D32F2F !important; }

.md-red-700-bg {
  background-color: #D32F2F !important;
  color: white; }

.red-700-fg {
  color: #D32F2F !important; }

.red-700-border {
  border-color: #D32F2F !important; }

.red-700-border-top {
  border-top-color: #D32F2F !important; }

.red-700-border-right {
  border-right-color: #D32F2F !important; }

.red-700-border-bottom {
  border-bottom-color: #D32F2F !important; }

.red-700-border-left {
  border-left-color: #D32F2F !important; }

.red-800-bg {
  background-color: #C62828 !important; }

.md-red-800-bg {
  background-color: #C62828 !important;
  color: rgba(255, 255, 255, 0.87); }

.red-800-fg {
  color: #C62828 !important; }

.red-800-border {
  border-color: #C62828 !important; }

.red-800-border-top {
  border-top-color: #C62828 !important; }

.red-800-border-right {
  border-right-color: #C62828 !important; }

.red-800-border-bottom {
  border-bottom-color: #C62828 !important; }

.red-800-border-left {
  border-left-color: #C62828 !important; }

.red-900-bg {
  background-color: #B71C1C !important; }

.md-red-900-bg {
  background-color: #B71C1C !important;
  color: rgba(255, 255, 255, 0.87); }

.red-900-fg {
  color: #B71C1C !important; }

.red-900-border {
  border-color: #B71C1C !important; }

.red-900-border-top {
  border-top-color: #B71C1C !important; }

.red-900-border-right {
  border-right-color: #B71C1C !important; }

.red-900-border-bottom {
  border-bottom-color: #B71C1C !important; }

.red-900-border-left {
  border-left-color: #B71C1C !important; }

.red-A100-bg {
  background-color: #FF8A80 !important; }

.md-red-A100-bg {
  background-color: #FF8A80 !important;
  color: rgba(0, 0, 0, 0.87); }

.red-A100-fg {
  color: #FF8A80 !important; }

.red-A100-border {
  border-color: #FF8A80 !important; }

.red-A100-border-top {
  border-top-color: #FF8A80 !important; }

.red-A100-border-right {
  border-right-color: #FF8A80 !important; }

.red-A100-border-bottom {
  border-bottom-color: #FF8A80 !important; }

.red-A100-border-left {
  border-left-color: #FF8A80 !important; }

.red-A200-bg {
  background-color: #FF5252 !important; }

.md-red-A200-bg {
  background-color: #FF5252 !important;
  color: white; }

.red-A200-fg {
  color: #FF5252 !important; }

.red-A200-border {
  border-color: #FF5252 !important; }

.red-A200-border-top {
  border-top-color: #FF5252 !important; }

.red-A200-border-right {
  border-right-color: #FF5252 !important; }

.red-A200-border-bottom {
  border-bottom-color: #FF5252 !important; }

.red-A200-border-left {
  border-left-color: #FF5252 !important; }

.red-A400-bg {
  background-color: #FF1744 !important; }

.md-red-A400-bg {
  background-color: #FF1744 !important;
  color: white; }

.red-A400-fg {
  color: #FF1744 !important; }

.red-A400-border {
  border-color: #FF1744 !important; }

.red-A400-border-top {
  border-top-color: #FF1744 !important; }

.red-A400-border-right {
  border-right-color: #FF1744 !important; }

.red-A400-border-bottom {
  border-bottom-color: #FF1744 !important; }

.red-A400-border-left {
  border-left-color: #FF1744 !important; }

.red-A700-bg {
  background-color: #D50000 !important; }

.md-red-A700-bg {
  background-color: #D50000 !important;
  color: white; }

.red-A700-fg {
  color: #D50000 !important; }

.red-A700-border {
  border-color: #D50000 !important; }

.red-A700-border-top {
  border-top-color: #D50000 !important; }

.red-A700-border-right {
  border-right-color: #D50000 !important; }

.red-A700-border-bottom {
  border-bottom-color: #D50000 !important; }

.red-A700-border-left {
  border-left-color: #D50000 !important; }

.pink-50-bg {
  background-color: #FCE4EC !important; }

.md-pink-50-bg {
  background-color: #FCE4EC !important;
  color: rgba(0, 0, 0, 0.87); }

.pink-50-fg {
  color: #FCE4EC !important; }

.pink-50-border {
  border-color: #FCE4EC !important; }

.pink-50-border-top {
  border-top-color: #FCE4EC !important; }

.pink-50-border-right {
  border-right-color: #FCE4EC !important; }

.pink-50-border-bottom {
  border-bottom-color: #FCE4EC !important; }

.pink-50-border-left {
  border-left-color: #FCE4EC !important; }

.pink-100-bg {
  background-color: #F8BBD0 !important; }

.md-pink-100-bg {
  background-color: #F8BBD0 !important;
  color: rgba(0, 0, 0, 0.87); }

.pink-100-fg {
  color: #F8BBD0 !important; }

.pink-100-border {
  border-color: #F8BBD0 !important; }

.pink-100-border-top {
  border-top-color: #F8BBD0 !important; }

.pink-100-border-right {
  border-right-color: #F8BBD0 !important; }

.pink-100-border-bottom {
  border-bottom-color: #F8BBD0 !important; }

.pink-100-border-left {
  border-left-color: #F8BBD0 !important; }

.pink-200-bg {
  background-color: #F48FB1 !important; }

.md-pink-200-bg {
  background-color: #F48FB1 !important;
  color: rgba(0, 0, 0, 0.87); }

.pink-200-fg {
  color: #F48FB1 !important; }

.pink-200-border {
  border-color: #F48FB1 !important; }

.pink-200-border-top {
  border-top-color: #F48FB1 !important; }

.pink-200-border-right {
  border-right-color: #F48FB1 !important; }

.pink-200-border-bottom {
  border-bottom-color: #F48FB1 !important; }

.pink-200-border-left {
  border-left-color: #F48FB1 !important; }

.pink-300-bg {
  background-color: #F06292 !important; }

.md-pink-300-bg {
  background-color: #F06292 !important;
  color: rgba(255, 255, 255, 0.87); }

.pink-300-fg {
  color: #F06292 !important; }

.pink-300-border {
  border-color: #F06292 !important; }

.pink-300-border-top {
  border-top-color: #F06292 !important; }

.pink-300-border-right {
  border-right-color: #F06292 !important; }

.pink-300-border-bottom {
  border-bottom-color: #F06292 !important; }

.pink-300-border-left {
  border-left-color: #F06292 !important; }

.pink-400-bg {
  background-color: #EC407A !important; }

.md-pink-400-bg {
  background-color: #EC407A !important;
  color: rgba(255, 255, 255, 0.87); }

.pink-400-fg {
  color: #EC407A !important; }

.pink-400-border {
  border-color: #EC407A !important; }

.pink-400-border-top {
  border-top-color: #EC407A !important; }

.pink-400-border-right {
  border-right-color: #EC407A !important; }

.pink-400-border-bottom {
  border-bottom-color: #EC407A !important; }

.pink-400-border-left {
  border-left-color: #EC407A !important; }

.pink-500-bg {
  background-color: #E91E63 !important; }

.md-pink-500-bg {
  background-color: #E91E63 !important;
  color: white; }

.pink-500-fg {
  color: #E91E63 !important; }

.pink-500-border {
  border-color: #E91E63 !important; }

.pink-500-border-top {
  border-top-color: #E91E63 !important; }

.pink-500-border-right {
  border-right-color: #E91E63 !important; }

.pink-500-border-bottom {
  border-bottom-color: #E91E63 !important; }

.pink-500-border-left {
  border-left-color: #E91E63 !important; }

.md-pink-bg {
  background-color: #E91E63 !important;
  color: white; }

.pink-bg {
  background-color: #E91E63 !important; }

.pink-fg {
  color: #E91E63 !important; }

.pink-border {
  border-color: #E91E63 !important; }

.pink-border-top {
  border-top-color: #E91E63 !important; }

.pink-border-right {
  border-right-color: #E91E63 !important; }

.pink-border-bottom {
  border-bottom-color: #E91E63 !important; }

.pink-border-left {
  border-left-color: #E91E63 !important; }

.pink-600-bg {
  background-color: #D81B60 !important; }

.md-pink-600-bg {
  background-color: #D81B60 !important;
  color: white; }

.pink-600-fg {
  color: #D81B60 !important; }

.pink-600-border {
  border-color: #D81B60 !important; }

.pink-600-border-top {
  border-top-color: #D81B60 !important; }

.pink-600-border-right {
  border-right-color: #D81B60 !important; }

.pink-600-border-bottom {
  border-bottom-color: #D81B60 !important; }

.pink-600-border-left {
  border-left-color: #D81B60 !important; }

.pink-700-bg {
  background-color: #C2185B !important; }

.md-pink-700-bg {
  background-color: #C2185B !important;
  color: rgba(255, 255, 255, 0.87); }

.pink-700-fg {
  color: #C2185B !important; }

.pink-700-border {
  border-color: #C2185B !important; }

.pink-700-border-top {
  border-top-color: #C2185B !important; }

.pink-700-border-right {
  border-right-color: #C2185B !important; }

.pink-700-border-bottom {
  border-bottom-color: #C2185B !important; }

.pink-700-border-left {
  border-left-color: #C2185B !important; }

.pink-800-bg {
  background-color: #AD1457 !important; }

.md-pink-800-bg {
  background-color: #AD1457 !important;
  color: rgba(255, 255, 255, 0.87); }

.pink-800-fg {
  color: #AD1457 !important; }

.pink-800-border {
  border-color: #AD1457 !important; }

.pink-800-border-top {
  border-top-color: #AD1457 !important; }

.pink-800-border-right {
  border-right-color: #AD1457 !important; }

.pink-800-border-bottom {
  border-bottom-color: #AD1457 !important; }

.pink-800-border-left {
  border-left-color: #AD1457 !important; }

.pink-900-bg {
  background-color: #880E4F !important; }

.md-pink-900-bg {
  background-color: #880E4F !important;
  color: rgba(255, 255, 255, 0.87); }

.pink-900-fg {
  color: #880E4F !important; }

.pink-900-border {
  border-color: #880E4F !important; }

.pink-900-border-top {
  border-top-color: #880E4F !important; }

.pink-900-border-right {
  border-right-color: #880E4F !important; }

.pink-900-border-bottom {
  border-bottom-color: #880E4F !important; }

.pink-900-border-left {
  border-left-color: #880E4F !important; }

.pink-A100-bg {
  background-color: #FF80AB !important; }

.md-pink-A100-bg {
  background-color: #FF80AB !important;
  color: rgba(0, 0, 0, 0.87); }

.pink-A100-fg {
  color: #FF80AB !important; }

.pink-A100-border {
  border-color: #FF80AB !important; }

.pink-A100-border-top {
  border-top-color: #FF80AB !important; }

.pink-A100-border-right {
  border-right-color: #FF80AB !important; }

.pink-A100-border-bottom {
  border-bottom-color: #FF80AB !important; }

.pink-A100-border-left {
  border-left-color: #FF80AB !important; }

.pink-A200-bg {
  background-color: #FF4081 !important; }

.md-pink-A200-bg {
  background-color: #FF4081 !important;
  color: white; }

.pink-A200-fg {
  color: #FF4081 !important; }

.pink-A200-border {
  border-color: #FF4081 !important; }

.pink-A200-border-top {
  border-top-color: #FF4081 !important; }

.pink-A200-border-right {
  border-right-color: #FF4081 !important; }

.pink-A200-border-bottom {
  border-bottom-color: #FF4081 !important; }

.pink-A200-border-left {
  border-left-color: #FF4081 !important; }

.pink-A400-bg {
  background-color: #F50057 !important; }

.md-pink-A400-bg {
  background-color: #F50057 !important;
  color: white; }

.pink-A400-fg {
  color: #F50057 !important; }

.pink-A400-border {
  border-color: #F50057 !important; }

.pink-A400-border-top {
  border-top-color: #F50057 !important; }

.pink-A400-border-right {
  border-right-color: #F50057 !important; }

.pink-A400-border-bottom {
  border-bottom-color: #F50057 !important; }

.pink-A400-border-left {
  border-left-color: #F50057 !important; }

.pink-A700-bg {
  background-color: #C51162 !important; }

.md-pink-A700-bg {
  background-color: #C51162 !important;
  color: white; }

.pink-A700-fg {
  color: #C51162 !important; }

.pink-A700-border {
  border-color: #C51162 !important; }

.pink-A700-border-top {
  border-top-color: #C51162 !important; }

.pink-A700-border-right {
  border-right-color: #C51162 !important; }

.pink-A700-border-bottom {
  border-bottom-color: #C51162 !important; }

.pink-A700-border-left {
  border-left-color: #C51162 !important; }

.purple-50-bg {
  background-color: #F3E5F5 !important; }

.md-purple-50-bg {
  background-color: #F3E5F5 !important;
  color: rgba(0, 0, 0, 0.87); }

.purple-50-fg {
  color: #F3E5F5 !important; }

.purple-50-border {
  border-color: #F3E5F5 !important; }

.purple-50-border-top {
  border-top-color: #F3E5F5 !important; }

.purple-50-border-right {
  border-right-color: #F3E5F5 !important; }

.purple-50-border-bottom {
  border-bottom-color: #F3E5F5 !important; }

.purple-50-border-left {
  border-left-color: #F3E5F5 !important; }

.purple-100-bg {
  background-color: #E1BEE7 !important; }

.md-purple-100-bg {
  background-color: #E1BEE7 !important;
  color: rgba(0, 0, 0, 0.87); }

.purple-100-fg {
  color: #E1BEE7 !important; }

.purple-100-border {
  border-color: #E1BEE7 !important; }

.purple-100-border-top {
  border-top-color: #E1BEE7 !important; }

.purple-100-border-right {
  border-right-color: #E1BEE7 !important; }

.purple-100-border-bottom {
  border-bottom-color: #E1BEE7 !important; }

.purple-100-border-left {
  border-left-color: #E1BEE7 !important; }

.purple-200-bg {
  background-color: #CE93D8 !important; }

.md-purple-200-bg {
  background-color: #CE93D8 !important;
  color: rgba(0, 0, 0, 0.87); }

.purple-200-fg {
  color: #CE93D8 !important; }

.purple-200-border {
  border-color: #CE93D8 !important; }

.purple-200-border-top {
  border-top-color: #CE93D8 !important; }

.purple-200-border-right {
  border-right-color: #CE93D8 !important; }

.purple-200-border-bottom {
  border-bottom-color: #CE93D8 !important; }

.purple-200-border-left {
  border-left-color: #CE93D8 !important; }

.purple-300-bg {
  background-color: #BA68C8 !important; }

.md-purple-300-bg {
  background-color: #BA68C8 !important;
  color: white; }

.purple-300-fg {
  color: #BA68C8 !important; }

.purple-300-border {
  border-color: #BA68C8 !important; }

.purple-300-border-top {
  border-top-color: #BA68C8 !important; }

.purple-300-border-right {
  border-right-color: #BA68C8 !important; }

.purple-300-border-bottom {
  border-bottom-color: #BA68C8 !important; }

.purple-300-border-left {
  border-left-color: #BA68C8 !important; }

.purple-400-bg {
  background-color: #AB47BC !important; }

.md-purple-400-bg {
  background-color: #AB47BC !important;
  color: white; }

.purple-400-fg {
  color: #AB47BC !important; }

.purple-400-border {
  border-color: #AB47BC !important; }

.purple-400-border-top {
  border-top-color: #AB47BC !important; }

.purple-400-border-right {
  border-right-color: #AB47BC !important; }

.purple-400-border-bottom {
  border-bottom-color: #AB47BC !important; }

.purple-400-border-left {
  border-left-color: #AB47BC !important; }

.purple-500-bg {
  background-color: #9C27B0 !important; }

.md-purple-500-bg {
  background-color: #9C27B0 !important;
  color: rgba(255, 255, 255, 0.87); }

.purple-500-fg {
  color: #9C27B0 !important; }

.purple-500-border {
  border-color: #9C27B0 !important; }

.purple-500-border-top {
  border-top-color: #9C27B0 !important; }

.purple-500-border-right {
  border-right-color: #9C27B0 !important; }

.purple-500-border-bottom {
  border-bottom-color: #9C27B0 !important; }

.purple-500-border-left {
  border-left-color: #9C27B0 !important; }

.md-purple-bg {
  background-color: #9C27B0 !important;
  color: rgba(255, 255, 255, 0.87); }

.purple-bg {
  background-color: #9C27B0 !important; }

.purple-fg {
  color: #9C27B0 !important; }

.purple-border {
  border-color: #9C27B0 !important; }

.purple-border-top {
  border-top-color: #9C27B0 !important; }

.purple-border-right {
  border-right-color: #9C27B0 !important; }

.purple-border-bottom {
  border-bottom-color: #9C27B0 !important; }

.purple-border-left {
  border-left-color: #9C27B0 !important; }

.purple-600-bg {
  background-color: #8E24AA !important; }

.md-purple-600-bg {
  background-color: #8E24AA !important;
  color: rgba(255, 255, 255, 0.87); }

.purple-600-fg {
  color: #8E24AA !important; }

.purple-600-border {
  border-color: #8E24AA !important; }

.purple-600-border-top {
  border-top-color: #8E24AA !important; }

.purple-600-border-right {
  border-right-color: #8E24AA !important; }

.purple-600-border-bottom {
  border-bottom-color: #8E24AA !important; }

.purple-600-border-left {
  border-left-color: #8E24AA !important; }

.purple-700-bg {
  background-color: #7B1FA2 !important; }

.md-purple-700-bg {
  background-color: #7B1FA2 !important;
  color: rgba(255, 255, 255, 0.87); }

.purple-700-fg {
  color: #7B1FA2 !important; }

.purple-700-border {
  border-color: #7B1FA2 !important; }

.purple-700-border-top {
  border-top-color: #7B1FA2 !important; }

.purple-700-border-right {
  border-right-color: #7B1FA2 !important; }

.purple-700-border-bottom {
  border-bottom-color: #7B1FA2 !important; }

.purple-700-border-left {
  border-left-color: #7B1FA2 !important; }

.purple-800-bg {
  background-color: #6A1B9A !important; }

.md-purple-800-bg {
  background-color: #6A1B9A !important;
  color: rgba(255, 255, 255, 0.87); }

.purple-800-fg {
  color: #6A1B9A !important; }

.purple-800-border {
  border-color: #6A1B9A !important; }

.purple-800-border-top {
  border-top-color: #6A1B9A !important; }

.purple-800-border-right {
  border-right-color: #6A1B9A !important; }

.purple-800-border-bottom {
  border-bottom-color: #6A1B9A !important; }

.purple-800-border-left {
  border-left-color: #6A1B9A !important; }

.purple-900-bg {
  background-color: #4A148C !important; }

.md-purple-900-bg {
  background-color: #4A148C !important;
  color: rgba(255, 255, 255, 0.87); }

.purple-900-fg {
  color: #4A148C !important; }

.purple-900-border {
  border-color: #4A148C !important; }

.purple-900-border-top {
  border-top-color: #4A148C !important; }

.purple-900-border-right {
  border-right-color: #4A148C !important; }

.purple-900-border-bottom {
  border-bottom-color: #4A148C !important; }

.purple-900-border-left {
  border-left-color: #4A148C !important; }

.purple-A100-bg {
  background-color: #EA80FC !important; }

.md-purple-A100-bg {
  background-color: #EA80FC !important;
  color: rgba(0, 0, 0, 0.87); }

.purple-A100-fg {
  color: #EA80FC !important; }

.purple-A100-border {
  border-color: #EA80FC !important; }

.purple-A100-border-top {
  border-top-color: #EA80FC !important; }

.purple-A100-border-right {
  border-right-color: #EA80FC !important; }

.purple-A100-border-bottom {
  border-bottom-color: #EA80FC !important; }

.purple-A100-border-left {
  border-left-color: #EA80FC !important; }

.purple-A200-bg {
  background-color: #E040FB !important; }

.md-purple-A200-bg {
  background-color: #E040FB !important;
  color: white; }

.purple-A200-fg {
  color: #E040FB !important; }

.purple-A200-border {
  border-color: #E040FB !important; }

.purple-A200-border-top {
  border-top-color: #E040FB !important; }

.purple-A200-border-right {
  border-right-color: #E040FB !important; }

.purple-A200-border-bottom {
  border-bottom-color: #E040FB !important; }

.purple-A200-border-left {
  border-left-color: #E040FB !important; }

.purple-A400-bg {
  background-color: #D500F9 !important; }

.md-purple-A400-bg {
  background-color: #D500F9 !important;
  color: white; }

.purple-A400-fg {
  color: #D500F9 !important; }

.purple-A400-border {
  border-color: #D500F9 !important; }

.purple-A400-border-top {
  border-top-color: #D500F9 !important; }

.purple-A400-border-right {
  border-right-color: #D500F9 !important; }

.purple-A400-border-bottom {
  border-bottom-color: #D500F9 !important; }

.purple-A400-border-left {
  border-left-color: #D500F9 !important; }

.purple-A700-bg {
  background-color: #AA00FF !important; }

.md-purple-A700-bg {
  background-color: #AA00FF !important;
  color: white; }

.purple-A700-fg {
  color: #AA00FF !important; }

.purple-A700-border {
  border-color: #AA00FF !important; }

.purple-A700-border-top {
  border-top-color: #AA00FF !important; }

.purple-A700-border-right {
  border-right-color: #AA00FF !important; }

.purple-A700-border-bottom {
  border-bottom-color: #AA00FF !important; }

.purple-A700-border-left {
  border-left-color: #AA00FF !important; }

.deep-purple-50-bg {
  background-color: #EDE7F6 !important; }

.md-deep-purple-50-bg {
  background-color: #EDE7F6 !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-purple-50-fg {
  color: #EDE7F6 !important; }

.deep-purple-50-border {
  border-color: #EDE7F6 !important; }

.deep-purple-50-border-top {
  border-top-color: #EDE7F6 !important; }

.deep-purple-50-border-right {
  border-right-color: #EDE7F6 !important; }

.deep-purple-50-border-bottom {
  border-bottom-color: #EDE7F6 !important; }

.deep-purple-50-border-left {
  border-left-color: #EDE7F6 !important; }

.deep-purple-100-bg {
  background-color: #D1C4E9 !important; }

.md-deep-purple-100-bg {
  background-color: #D1C4E9 !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-purple-100-fg {
  color: #D1C4E9 !important; }

.deep-purple-100-border {
  border-color: #D1C4E9 !important; }

.deep-purple-100-border-top {
  border-top-color: #D1C4E9 !important; }

.deep-purple-100-border-right {
  border-right-color: #D1C4E9 !important; }

.deep-purple-100-border-bottom {
  border-bottom-color: #D1C4E9 !important; }

.deep-purple-100-border-left {
  border-left-color: #D1C4E9 !important; }

.deep-purple-200-bg {
  background-color: #B39DDB !important; }

.md-deep-purple-200-bg {
  background-color: #B39DDB !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-purple-200-fg {
  color: #B39DDB !important; }

.deep-purple-200-border {
  border-color: #B39DDB !important; }

.deep-purple-200-border-top {
  border-top-color: #B39DDB !important; }

.deep-purple-200-border-right {
  border-right-color: #B39DDB !important; }

.deep-purple-200-border-bottom {
  border-bottom-color: #B39DDB !important; }

.deep-purple-200-border-left {
  border-left-color: #B39DDB !important; }

.deep-purple-300-bg {
  background-color: #9575CD !important; }

.md-deep-purple-300-bg {
  background-color: #9575CD !important;
  color: white; }

.deep-purple-300-fg {
  color: #9575CD !important; }

.deep-purple-300-border {
  border-color: #9575CD !important; }

.deep-purple-300-border-top {
  border-top-color: #9575CD !important; }

.deep-purple-300-border-right {
  border-right-color: #9575CD !important; }

.deep-purple-300-border-bottom {
  border-bottom-color: #9575CD !important; }

.deep-purple-300-border-left {
  border-left-color: #9575CD !important; }

.deep-purple-400-bg {
  background-color: #7E57C2 !important; }

.md-deep-purple-400-bg {
  background-color: #7E57C2 !important;
  color: white; }

.deep-purple-400-fg {
  color: #7E57C2 !important; }

.deep-purple-400-border {
  border-color: #7E57C2 !important; }

.deep-purple-400-border-top {
  border-top-color: #7E57C2 !important; }

.deep-purple-400-border-right {
  border-right-color: #7E57C2 !important; }

.deep-purple-400-border-bottom {
  border-bottom-color: #7E57C2 !important; }

.deep-purple-400-border-left {
  border-left-color: #7E57C2 !important; }

.deep-purple-500-bg {
  background-color: #673AB7 !important; }

.md-deep-purple-500-bg {
  background-color: #673AB7 !important;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-500-fg {
  color: #673AB7 !important; }

.deep-purple-500-border {
  border-color: #673AB7 !important; }

.deep-purple-500-border-top {
  border-top-color: #673AB7 !important; }

.deep-purple-500-border-right {
  border-right-color: #673AB7 !important; }

.deep-purple-500-border-bottom {
  border-bottom-color: #673AB7 !important; }

.deep-purple-500-border-left {
  border-left-color: #673AB7 !important; }

.md-deep-purple-bg {
  background-color: #673AB7 !important;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-bg {
  background-color: #673AB7 !important; }

.deep-purple-fg {
  color: #673AB7 !important; }

.deep-purple-border {
  border-color: #673AB7 !important; }

.deep-purple-border-top {
  border-top-color: #673AB7 !important; }

.deep-purple-border-right {
  border-right-color: #673AB7 !important; }

.deep-purple-border-bottom {
  border-bottom-color: #673AB7 !important; }

.deep-purple-border-left {
  border-left-color: #673AB7 !important; }

.deep-purple-600-bg {
  background-color: #5E35B1 !important; }

.md-deep-purple-600-bg {
  background-color: #5E35B1 !important;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-600-fg {
  color: #5E35B1 !important; }

.deep-purple-600-border {
  border-color: #5E35B1 !important; }

.deep-purple-600-border-top {
  border-top-color: #5E35B1 !important; }

.deep-purple-600-border-right {
  border-right-color: #5E35B1 !important; }

.deep-purple-600-border-bottom {
  border-bottom-color: #5E35B1 !important; }

.deep-purple-600-border-left {
  border-left-color: #5E35B1 !important; }

.deep-purple-700-bg {
  background-color: #512DA8 !important; }

.md-deep-purple-700-bg {
  background-color: #512DA8 !important;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-700-fg {
  color: #512DA8 !important; }

.deep-purple-700-border {
  border-color: #512DA8 !important; }

.deep-purple-700-border-top {
  border-top-color: #512DA8 !important; }

.deep-purple-700-border-right {
  border-right-color: #512DA8 !important; }

.deep-purple-700-border-bottom {
  border-bottom-color: #512DA8 !important; }

.deep-purple-700-border-left {
  border-left-color: #512DA8 !important; }

.deep-purple-800-bg {
  background-color: #4527A0 !important; }

.md-deep-purple-800-bg {
  background-color: #4527A0 !important;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-800-fg {
  color: #4527A0 !important; }

.deep-purple-800-border {
  border-color: #4527A0 !important; }

.deep-purple-800-border-top {
  border-top-color: #4527A0 !important; }

.deep-purple-800-border-right {
  border-right-color: #4527A0 !important; }

.deep-purple-800-border-bottom {
  border-bottom-color: #4527A0 !important; }

.deep-purple-800-border-left {
  border-left-color: #4527A0 !important; }

.deep-purple-900-bg {
  background-color: #311B92 !important; }

.md-deep-purple-900-bg {
  background-color: #311B92 !important;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-900-fg {
  color: #311B92 !important; }

.deep-purple-900-border {
  border-color: #311B92 !important; }

.deep-purple-900-border-top {
  border-top-color: #311B92 !important; }

.deep-purple-900-border-right {
  border-right-color: #311B92 !important; }

.deep-purple-900-border-bottom {
  border-bottom-color: #311B92 !important; }

.deep-purple-900-border-left {
  border-left-color: #311B92 !important; }

.deep-purple-A100-bg {
  background-color: #B388FF !important; }

.md-deep-purple-A100-bg {
  background-color: #B388FF !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-purple-A100-fg {
  color: #B388FF !important; }

.deep-purple-A100-border {
  border-color: #B388FF !important; }

.deep-purple-A100-border-top {
  border-top-color: #B388FF !important; }

.deep-purple-A100-border-right {
  border-right-color: #B388FF !important; }

.deep-purple-A100-border-bottom {
  border-bottom-color: #B388FF !important; }

.deep-purple-A100-border-left {
  border-left-color: #B388FF !important; }

.deep-purple-A200-bg {
  background-color: #7C4DFF !important; }

.md-deep-purple-A200-bg {
  background-color: #7C4DFF !important;
  color: white; }

.deep-purple-A200-fg {
  color: #7C4DFF !important; }

.deep-purple-A200-border {
  border-color: #7C4DFF !important; }

.deep-purple-A200-border-top {
  border-top-color: #7C4DFF !important; }

.deep-purple-A200-border-right {
  border-right-color: #7C4DFF !important; }

.deep-purple-A200-border-bottom {
  border-bottom-color: #7C4DFF !important; }

.deep-purple-A200-border-left {
  border-left-color: #7C4DFF !important; }

.deep-purple-A400-bg {
  background-color: #651FFF !important; }

.md-deep-purple-A400-bg {
  background-color: #651FFF !important;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-A400-fg {
  color: #651FFF !important; }

.deep-purple-A400-border {
  border-color: #651FFF !important; }

.deep-purple-A400-border-top {
  border-top-color: #651FFF !important; }

.deep-purple-A400-border-right {
  border-right-color: #651FFF !important; }

.deep-purple-A400-border-bottom {
  border-bottom-color: #651FFF !important; }

.deep-purple-A400-border-left {
  border-left-color: #651FFF !important; }

.deep-purple-A700-bg {
  background-color: #6200EA !important; }

.md-deep-purple-A700-bg {
  background-color: #6200EA !important;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-A700-fg {
  color: #6200EA !important; }

.deep-purple-A700-border {
  border-color: #6200EA !important; }

.deep-purple-A700-border-top {
  border-top-color: #6200EA !important; }

.deep-purple-A700-border-right {
  border-right-color: #6200EA !important; }

.deep-purple-A700-border-bottom {
  border-bottom-color: #6200EA !important; }

.deep-purple-A700-border-left {
  border-left-color: #6200EA !important; }

.indigo-50-bg {
  background-color: #E8EAF6 !important; }

.md-indigo-50-bg {
  background-color: #E8EAF6 !important;
  color: rgba(0, 0, 0, 0.87); }

.indigo-50-fg {
  color: #E8EAF6 !important; }

.indigo-50-border {
  border-color: #E8EAF6 !important; }

.indigo-50-border-top {
  border-top-color: #E8EAF6 !important; }

.indigo-50-border-right {
  border-right-color: #E8EAF6 !important; }

.indigo-50-border-bottom {
  border-bottom-color: #E8EAF6 !important; }

.indigo-50-border-left {
  border-left-color: #E8EAF6 !important; }

.indigo-100-bg {
  background-color: #C5CAE9 !important; }

.md-indigo-100-bg {
  background-color: #C5CAE9 !important;
  color: rgba(0, 0, 0, 0.87); }

.indigo-100-fg {
  color: #C5CAE9 !important; }

.indigo-100-border {
  border-color: #C5CAE9 !important; }

.indigo-100-border-top {
  border-top-color: #C5CAE9 !important; }

.indigo-100-border-right {
  border-right-color: #C5CAE9 !important; }

.indigo-100-border-bottom {
  border-bottom-color: #C5CAE9 !important; }

.indigo-100-border-left {
  border-left-color: #C5CAE9 !important; }

.indigo-200-bg {
  background-color: #9FA8DA !important; }

.md-indigo-200-bg {
  background-color: #9FA8DA !important;
  color: rgba(0, 0, 0, 0.87); }

.indigo-200-fg {
  color: #9FA8DA !important; }

.indigo-200-border {
  border-color: #9FA8DA !important; }

.indigo-200-border-top {
  border-top-color: #9FA8DA !important; }

.indigo-200-border-right {
  border-right-color: #9FA8DA !important; }

.indigo-200-border-bottom {
  border-bottom-color: #9FA8DA !important; }

.indigo-200-border-left {
  border-left-color: #9FA8DA !important; }

.indigo-300-bg {
  background-color: #7986CB !important; }

.md-indigo-300-bg {
  background-color: #7986CB !important;
  color: white; }

.indigo-300-fg {
  color: #7986CB !important; }

.indigo-300-border {
  border-color: #7986CB !important; }

.indigo-300-border-top {
  border-top-color: #7986CB !important; }

.indigo-300-border-right {
  border-right-color: #7986CB !important; }

.indigo-300-border-bottom {
  border-bottom-color: #7986CB !important; }

.indigo-300-border-left {
  border-left-color: #7986CB !important; }

.indigo-400-bg {
  background-color: #5C6BC0 !important; }

.md-indigo-400-bg {
  background-color: #5C6BC0 !important;
  color: white; }

.indigo-400-fg {
  color: #5C6BC0 !important; }

.indigo-400-border {
  border-color: #5C6BC0 !important; }

.indigo-400-border-top {
  border-top-color: #5C6BC0 !important; }

.indigo-400-border-right {
  border-right-color: #5C6BC0 !important; }

.indigo-400-border-bottom {
  border-bottom-color: #5C6BC0 !important; }

.indigo-400-border-left {
  border-left-color: #5C6BC0 !important; }

.indigo-500-bg {
  background-color: #3F51B5 !important; }

.md-indigo-500-bg {
  background-color: #3F51B5 !important;
  color: rgba(255, 255, 255, 0.87); }

.indigo-500-fg {
  color: #3F51B5 !important; }

.indigo-500-border {
  border-color: #3F51B5 !important; }

.indigo-500-border-top {
  border-top-color: #3F51B5 !important; }

.indigo-500-border-right {
  border-right-color: #3F51B5 !important; }

.indigo-500-border-bottom {
  border-bottom-color: #3F51B5 !important; }

.indigo-500-border-left {
  border-left-color: #3F51B5 !important; }

.md-indigo-bg {
  background-color: #3F51B5 !important;
  color: rgba(255, 255, 255, 0.87); }

.indigo-bg {
  background-color: #3F51B5 !important; }

.indigo-fg {
  color: #3F51B5 !important; }

.indigo-border {
  border-color: #3F51B5 !important; }

.indigo-border-top {
  border-top-color: #3F51B5 !important; }

.indigo-border-right {
  border-right-color: #3F51B5 !important; }

.indigo-border-bottom {
  border-bottom-color: #3F51B5 !important; }

.indigo-border-left {
  border-left-color: #3F51B5 !important; }

.indigo-600-bg {
  background-color: #3949AB !important; }

.md-indigo-600-bg {
  background-color: #3949AB !important;
  color: rgba(255, 255, 255, 0.87); }

.indigo-600-fg {
  color: #3949AB !important; }

.indigo-600-border {
  border-color: #3949AB !important; }

.indigo-600-border-top {
  border-top-color: #3949AB !important; }

.indigo-600-border-right {
  border-right-color: #3949AB !important; }

.indigo-600-border-bottom {
  border-bottom-color: #3949AB !important; }

.indigo-600-border-left {
  border-left-color: #3949AB !important; }

.indigo-700-bg {
  background-color: #303F9F !important; }

.md-indigo-700-bg {
  background-color: #303F9F !important;
  color: rgba(255, 255, 255, 0.87); }

.indigo-700-fg {
  color: #303F9F !important; }

.indigo-700-border {
  border-color: #303F9F !important; }

.indigo-700-border-top {
  border-top-color: #303F9F !important; }

.indigo-700-border-right {
  border-right-color: #303F9F !important; }

.indigo-700-border-bottom {
  border-bottom-color: #303F9F !important; }

.indigo-700-border-left {
  border-left-color: #303F9F !important; }

.indigo-800-bg {
  background-color: #283593 !important; }

.md-indigo-800-bg {
  background-color: #283593 !important;
  color: rgba(255, 255, 255, 0.87); }

.indigo-800-fg {
  color: #283593 !important; }

.indigo-800-border {
  border-color: #283593 !important; }

.indigo-800-border-top {
  border-top-color: #283593 !important; }

.indigo-800-border-right {
  border-right-color: #283593 !important; }

.indigo-800-border-bottom {
  border-bottom-color: #283593 !important; }

.indigo-800-border-left {
  border-left-color: #283593 !important; }

.indigo-900-bg {
  background-color: #1A237E !important; }

.md-indigo-900-bg {
  background-color: #1A237E !important;
  color: rgba(255, 255, 255, 0.87); }

.indigo-900-fg {
  color: #1A237E !important; }

.indigo-900-border {
  border-color: #1A237E !important; }

.indigo-900-border-top {
  border-top-color: #1A237E !important; }

.indigo-900-border-right {
  border-right-color: #1A237E !important; }

.indigo-900-border-bottom {
  border-bottom-color: #1A237E !important; }

.indigo-900-border-left {
  border-left-color: #1A237E !important; }

.indigo-A100-bg {
  background-color: #8C9EFF !important; }

.md-indigo-A100-bg {
  background-color: #8C9EFF !important;
  color: rgba(0, 0, 0, 0.87); }

.indigo-A100-fg {
  color: #8C9EFF !important; }

.indigo-A100-border {
  border-color: #8C9EFF !important; }

.indigo-A100-border-top {
  border-top-color: #8C9EFF !important; }

.indigo-A100-border-right {
  border-right-color: #8C9EFF !important; }

.indigo-A100-border-bottom {
  border-bottom-color: #8C9EFF !important; }

.indigo-A100-border-left {
  border-left-color: #8C9EFF !important; }

.indigo-A200-bg {
  background-color: #536DFE !important; }

.md-indigo-A200-bg {
  background-color: #536DFE !important;
  color: white; }

.indigo-A200-fg {
  color: #536DFE !important; }

.indigo-A200-border {
  border-color: #536DFE !important; }

.indigo-A200-border-top {
  border-top-color: #536DFE !important; }

.indigo-A200-border-right {
  border-right-color: #536DFE !important; }

.indigo-A200-border-bottom {
  border-bottom-color: #536DFE !important; }

.indigo-A200-border-left {
  border-left-color: #536DFE !important; }

.indigo-A400-bg {
  background-color: #3D5AFE !important; }

.md-indigo-A400-bg {
  background-color: #3D5AFE !important;
  color: white; }

.indigo-A400-fg {
  color: #3D5AFE !important; }

.indigo-A400-border {
  border-color: #3D5AFE !important; }

.indigo-A400-border-top {
  border-top-color: #3D5AFE !important; }

.indigo-A400-border-right {
  border-right-color: #3D5AFE !important; }

.indigo-A400-border-bottom {
  border-bottom-color: #3D5AFE !important; }

.indigo-A400-border-left {
  border-left-color: #3D5AFE !important; }

.indigo-A700-bg {
  background-color: #304FFE !important; }

.md-indigo-A700-bg {
  background-color: #304FFE !important;
  color: rgba(255, 255, 255, 0.87); }

.indigo-A700-fg {
  color: #304FFE !important; }

.indigo-A700-border {
  border-color: #304FFE !important; }

.indigo-A700-border-top {
  border-top-color: #304FFE !important; }

.indigo-A700-border-right {
  border-right-color: #304FFE !important; }

.indigo-A700-border-bottom {
  border-bottom-color: #304FFE !important; }

.indigo-A700-border-left {
  border-left-color: #304FFE !important; }

.blue-50-bg {
  background-color: #E3F2FD !important; }

.md-blue-50-bg {
  background-color: #E3F2FD !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-50-fg {
  color: #E3F2FD !important; }

.blue-50-border {
  border-color: #E3F2FD !important; }

.blue-50-border-top {
  border-top-color: #E3F2FD !important; }

.blue-50-border-right {
  border-right-color: #E3F2FD !important; }

.blue-50-border-bottom {
  border-bottom-color: #E3F2FD !important; }

.blue-50-border-left {
  border-left-color: #E3F2FD !important; }

.blue-100-bg {
  background-color: #BBDEFB !important; }

.md-blue-100-bg {
  background-color: #BBDEFB !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-100-fg {
  color: #BBDEFB !important; }

.blue-100-border {
  border-color: #BBDEFB !important; }

.blue-100-border-top {
  border-top-color: #BBDEFB !important; }

.blue-100-border-right {
  border-right-color: #BBDEFB !important; }

.blue-100-border-bottom {
  border-bottom-color: #BBDEFB !important; }

.blue-100-border-left {
  border-left-color: #BBDEFB !important; }

.blue-200-bg {
  background-color: #90CAF9 !important; }

.md-blue-200-bg {
  background-color: #90CAF9 !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-200-fg {
  color: #90CAF9 !important; }

.blue-200-border {
  border-color: #90CAF9 !important; }

.blue-200-border-top {
  border-top-color: #90CAF9 !important; }

.blue-200-border-right {
  border-right-color: #90CAF9 !important; }

.blue-200-border-bottom {
  border-bottom-color: #90CAF9 !important; }

.blue-200-border-left {
  border-left-color: #90CAF9 !important; }

.blue-300-bg {
  background-color: #64B5F6 !important; }

.md-blue-300-bg {
  background-color: #64B5F6 !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-300-fg {
  color: #64B5F6 !important; }

.blue-300-border {
  border-color: #64B5F6 !important; }

.blue-300-border-top {
  border-top-color: #64B5F6 !important; }

.blue-300-border-right {
  border-right-color: #64B5F6 !important; }

.blue-300-border-bottom {
  border-bottom-color: #64B5F6 !important; }

.blue-300-border-left {
  border-left-color: #64B5F6 !important; }

.blue-400-bg {
  background-color: #42A5F5 !important; }

.md-blue-400-bg {
  background-color: #42A5F5 !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-400-fg {
  color: #42A5F5 !important; }

.blue-400-border {
  border-color: #42A5F5 !important; }

.blue-400-border-top {
  border-top-color: #42A5F5 !important; }

.blue-400-border-right {
  border-right-color: #42A5F5 !important; }

.blue-400-border-bottom {
  border-bottom-color: #42A5F5 !important; }

.blue-400-border-left {
  border-left-color: #42A5F5 !important; }

.blue-500-bg {
  background-color: #2196F3 !important; }

.md-blue-500-bg {
  background-color: #2196F3 !important;
  color: white; }

.blue-500-fg {
  color: #2196F3 !important; }

.blue-500-border {
  border-color: #2196F3 !important; }

.blue-500-border-top {
  border-top-color: #2196F3 !important; }

.blue-500-border-right {
  border-right-color: #2196F3 !important; }

.blue-500-border-bottom {
  border-bottom-color: #2196F3 !important; }

.blue-500-border-left {
  border-left-color: #2196F3 !important; }

.md-blue-bg {
  background-color: #2196F3 !important;
  color: white; }

.blue-bg {
  background-color: #2196F3 !important; }

.blue-fg {
  color: #2196F3 !important; }

.blue-border {
  border-color: #2196F3 !important; }

.blue-border-top {
  border-top-color: #2196F3 !important; }

.blue-border-right {
  border-right-color: #2196F3 !important; }

.blue-border-bottom {
  border-bottom-color: #2196F3 !important; }

.blue-border-left {
  border-left-color: #2196F3 !important; }

.blue-600-bg {
  background-color: #1E88E5 !important; }

.md-blue-600-bg {
  background-color: #1E88E5 !important;
  color: white; }

.blue-600-fg {
  color: #1E88E5 !important; }

.blue-600-border {
  border-color: #1E88E5 !important; }

.blue-600-border-top {
  border-top-color: #1E88E5 !important; }

.blue-600-border-right {
  border-right-color: #1E88E5 !important; }

.blue-600-border-bottom {
  border-bottom-color: #1E88E5 !important; }

.blue-600-border-left {
  border-left-color: #1E88E5 !important; }

.blue-700-bg {
  background-color: #1976D2 !important; }

.md-blue-700-bg {
  background-color: #1976D2 !important;
  color: white; }

.blue-700-fg {
  color: #1976D2 !important; }

.blue-700-border {
  border-color: #1976D2 !important; }

.blue-700-border-top {
  border-top-color: #1976D2 !important; }

.blue-700-border-right {
  border-right-color: #1976D2 !important; }

.blue-700-border-bottom {
  border-bottom-color: #1976D2 !important; }

.blue-700-border-left {
  border-left-color: #1976D2 !important; }

.blue-800-bg {
  background-color: #1565C0 !important; }

.md-blue-800-bg {
  background-color: #1565C0 !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-800-fg {
  color: #1565C0 !important; }

.blue-800-border {
  border-color: #1565C0 !important; }

.blue-800-border-top {
  border-top-color: #1565C0 !important; }

.blue-800-border-right {
  border-right-color: #1565C0 !important; }

.blue-800-border-bottom {
  border-bottom-color: #1565C0 !important; }

.blue-800-border-left {
  border-left-color: #1565C0 !important; }

.blue-900-bg {
  background-color: #0D47A1 !important; }

.md-blue-900-bg {
  background-color: #0D47A1 !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-900-fg {
  color: #0D47A1 !important; }

.blue-900-border {
  border-color: #0D47A1 !important; }

.blue-900-border-top {
  border-top-color: #0D47A1 !important; }

.blue-900-border-right {
  border-right-color: #0D47A1 !important; }

.blue-900-border-bottom {
  border-bottom-color: #0D47A1 !important; }

.blue-900-border-left {
  border-left-color: #0D47A1 !important; }

.blue-A100-bg {
  background-color: #82B1FF !important; }

.md-blue-A100-bg {
  background-color: #82B1FF !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-A100-fg {
  color: #82B1FF !important; }

.blue-A100-border {
  border-color: #82B1FF !important; }

.blue-A100-border-top {
  border-top-color: #82B1FF !important; }

.blue-A100-border-right {
  border-right-color: #82B1FF !important; }

.blue-A100-border-bottom {
  border-bottom-color: #82B1FF !important; }

.blue-A100-border-left {
  border-left-color: #82B1FF !important; }

.blue-A200-bg {
  background-color: #448AFF !important; }

.md-blue-A200-bg {
  background-color: #448AFF !important;
  color: white; }

.blue-A200-fg {
  color: #448AFF !important; }

.blue-A200-border {
  border-color: #448AFF !important; }

.blue-A200-border-top {
  border-top-color: #448AFF !important; }

.blue-A200-border-right {
  border-right-color: #448AFF !important; }

.blue-A200-border-bottom {
  border-bottom-color: #448AFF !important; }

.blue-A200-border-left {
  border-left-color: #448AFF !important; }

.blue-A400-bg {
  background-color: #2979FF !important; }

.md-blue-A400-bg {
  background-color: #2979FF !important;
  color: white; }

.blue-A400-fg {
  color: #2979FF !important; }

.blue-A400-border {
  border-color: #2979FF !important; }

.blue-A400-border-top {
  border-top-color: #2979FF !important; }

.blue-A400-border-right {
  border-right-color: #2979FF !important; }

.blue-A400-border-bottom {
  border-bottom-color: #2979FF !important; }

.blue-A400-border-left {
  border-left-color: #2979FF !important; }

.blue-A700-bg {
  background-color: #2962FF !important; }

.md-blue-A700-bg {
  background-color: #2962FF !important;
  color: white; }

.blue-A700-fg {
  color: #2962FF !important; }

.blue-A700-border {
  border-color: #2962FF !important; }

.blue-A700-border-top {
  border-top-color: #2962FF !important; }

.blue-A700-border-right {
  border-right-color: #2962FF !important; }

.blue-A700-border-bottom {
  border-bottom-color: #2962FF !important; }

.blue-A700-border-left {
  border-left-color: #2962FF !important; }

.light-blue-50-bg {
  background-color: #E1F5FE !important; }

.md-light-blue-50-bg {
  background-color: #E1F5FE !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-50-fg {
  color: #E1F5FE !important; }

.light-blue-50-border {
  border-color: #E1F5FE !important; }

.light-blue-50-border-top {
  border-top-color: #E1F5FE !important; }

.light-blue-50-border-right {
  border-right-color: #E1F5FE !important; }

.light-blue-50-border-bottom {
  border-bottom-color: #E1F5FE !important; }

.light-blue-50-border-left {
  border-left-color: #E1F5FE !important; }

.light-blue-100-bg {
  background-color: #B3E5FC !important; }

.md-light-blue-100-bg {
  background-color: #B3E5FC !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-100-fg {
  color: #B3E5FC !important; }

.light-blue-100-border {
  border-color: #B3E5FC !important; }

.light-blue-100-border-top {
  border-top-color: #B3E5FC !important; }

.light-blue-100-border-right {
  border-right-color: #B3E5FC !important; }

.light-blue-100-border-bottom {
  border-bottom-color: #B3E5FC !important; }

.light-blue-100-border-left {
  border-left-color: #B3E5FC !important; }

.light-blue-200-bg {
  background-color: #81D4FA !important; }

.md-light-blue-200-bg {
  background-color: #81D4FA !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-200-fg {
  color: #81D4FA !important; }

.light-blue-200-border {
  border-color: #81D4FA !important; }

.light-blue-200-border-top {
  border-top-color: #81D4FA !important; }

.light-blue-200-border-right {
  border-right-color: #81D4FA !important; }

.light-blue-200-border-bottom {
  border-bottom-color: #81D4FA !important; }

.light-blue-200-border-left {
  border-left-color: #81D4FA !important; }

.light-blue-300-bg {
  background-color: #4FC3F7 !important; }

.md-light-blue-300-bg {
  background-color: #4FC3F7 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-300-fg {
  color: #4FC3F7 !important; }

.light-blue-300-border {
  border-color: #4FC3F7 !important; }

.light-blue-300-border-top {
  border-top-color: #4FC3F7 !important; }

.light-blue-300-border-right {
  border-right-color: #4FC3F7 !important; }

.light-blue-300-border-bottom {
  border-bottom-color: #4FC3F7 !important; }

.light-blue-300-border-left {
  border-left-color: #4FC3F7 !important; }

.light-blue-400-bg {
  background-color: #29B6F6 !important; }

.md-light-blue-400-bg {
  background-color: #29B6F6 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-400-fg {
  color: #29B6F6 !important; }

.light-blue-400-border {
  border-color: #29B6F6 !important; }

.light-blue-400-border-top {
  border-top-color: #29B6F6 !important; }

.light-blue-400-border-right {
  border-right-color: #29B6F6 !important; }

.light-blue-400-border-bottom {
  border-bottom-color: #29B6F6 !important; }

.light-blue-400-border-left {
  border-left-color: #29B6F6 !important; }

.light-blue-500-bg {
  background-color: #03A9F4 !important; }

.md-light-blue-500-bg {
  background-color: #03A9F4 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-500-fg {
  color: #03A9F4 !important; }

.light-blue-500-border {
  border-color: #03A9F4 !important; }

.light-blue-500-border-top {
  border-top-color: #03A9F4 !important; }

.light-blue-500-border-right {
  border-right-color: #03A9F4 !important; }

.light-blue-500-border-bottom {
  border-bottom-color: #03A9F4 !important; }

.light-blue-500-border-left {
  border-left-color: #03A9F4 !important; }

.md-light-blue-bg {
  background-color: #03A9F4 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-bg {
  background-color: #03A9F4 !important; }

.light-blue-fg {
  color: #03A9F4 !important; }

.light-blue-border {
  border-color: #03A9F4 !important; }

.light-blue-border-top {
  border-top-color: #03A9F4 !important; }

.light-blue-border-right {
  border-right-color: #03A9F4 !important; }

.light-blue-border-bottom {
  border-bottom-color: #03A9F4 !important; }

.light-blue-border-left {
  border-left-color: #03A9F4 !important; }

.light-blue-600-bg {
  background-color: #039BE5 !important; }

.md-light-blue-600-bg {
  background-color: #039BE5 !important;
  color: white; }

.light-blue-600-fg {
  color: #039BE5 !important; }

.light-blue-600-border {
  border-color: #039BE5 !important; }

.light-blue-600-border-top {
  border-top-color: #039BE5 !important; }

.light-blue-600-border-right {
  border-right-color: #039BE5 !important; }

.light-blue-600-border-bottom {
  border-bottom-color: #039BE5 !important; }

.light-blue-600-border-left {
  border-left-color: #039BE5 !important; }

.light-blue-700-bg {
  background-color: #0288D1 !important; }

.md-light-blue-700-bg {
  background-color: #0288D1 !important;
  color: white; }

.light-blue-700-fg {
  color: #0288D1 !important; }

.light-blue-700-border {
  border-color: #0288D1 !important; }

.light-blue-700-border-top {
  border-top-color: #0288D1 !important; }

.light-blue-700-border-right {
  border-right-color: #0288D1 !important; }

.light-blue-700-border-bottom {
  border-bottom-color: #0288D1 !important; }

.light-blue-700-border-left {
  border-left-color: #0288D1 !important; }

.light-blue-800-bg {
  background-color: #0277BD !important; }

.md-light-blue-800-bg {
  background-color: #0277BD !important;
  color: white; }

.light-blue-800-fg {
  color: #0277BD !important; }

.light-blue-800-border {
  border-color: #0277BD !important; }

.light-blue-800-border-top {
  border-top-color: #0277BD !important; }

.light-blue-800-border-right {
  border-right-color: #0277BD !important; }

.light-blue-800-border-bottom {
  border-bottom-color: #0277BD !important; }

.light-blue-800-border-left {
  border-left-color: #0277BD !important; }

.light-blue-900-bg {
  background-color: #01579B !important; }

.md-light-blue-900-bg {
  background-color: #01579B !important;
  color: white; }

.light-blue-900-fg {
  color: #01579B !important; }

.light-blue-900-border {
  border-color: #01579B !important; }

.light-blue-900-border-top {
  border-top-color: #01579B !important; }

.light-blue-900-border-right {
  border-right-color: #01579B !important; }

.light-blue-900-border-bottom {
  border-bottom-color: #01579B !important; }

.light-blue-900-border-left {
  border-left-color: #01579B !important; }

.light-blue-A100-bg {
  background-color: #80D8FF !important; }

.md-light-blue-A100-bg {
  background-color: #80D8FF !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-A100-fg {
  color: #80D8FF !important; }

.light-blue-A100-border {
  border-color: #80D8FF !important; }

.light-blue-A100-border-top {
  border-top-color: #80D8FF !important; }

.light-blue-A100-border-right {
  border-right-color: #80D8FF !important; }

.light-blue-A100-border-bottom {
  border-bottom-color: #80D8FF !important; }

.light-blue-A100-border-left {
  border-left-color: #80D8FF !important; }

.light-blue-A200-bg {
  background-color: #40C4FF !important; }

.md-light-blue-A200-bg {
  background-color: #40C4FF !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-A200-fg {
  color: #40C4FF !important; }

.light-blue-A200-border {
  border-color: #40C4FF !important; }

.light-blue-A200-border-top {
  border-top-color: #40C4FF !important; }

.light-blue-A200-border-right {
  border-right-color: #40C4FF !important; }

.light-blue-A200-border-bottom {
  border-bottom-color: #40C4FF !important; }

.light-blue-A200-border-left {
  border-left-color: #40C4FF !important; }

.light-blue-A400-bg {
  background-color: #00B0FF !important; }

.md-light-blue-A400-bg {
  background-color: #00B0FF !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-A400-fg {
  color: #00B0FF !important; }

.light-blue-A400-border {
  border-color: #00B0FF !important; }

.light-blue-A400-border-top {
  border-top-color: #00B0FF !important; }

.light-blue-A400-border-right {
  border-right-color: #00B0FF !important; }

.light-blue-A400-border-bottom {
  border-bottom-color: #00B0FF !important; }

.light-blue-A400-border-left {
  border-left-color: #00B0FF !important; }

.light-blue-A700-bg {
  background-color: #0091EA !important; }

.md-light-blue-A700-bg {
  background-color: #0091EA !important;
  color: white; }

.light-blue-A700-fg {
  color: #0091EA !important; }

.light-blue-A700-border {
  border-color: #0091EA !important; }

.light-blue-A700-border-top {
  border-top-color: #0091EA !important; }

.light-blue-A700-border-right {
  border-right-color: #0091EA !important; }

.light-blue-A700-border-bottom {
  border-bottom-color: #0091EA !important; }

.light-blue-A700-border-left {
  border-left-color: #0091EA !important; }

.cyan-50-bg {
  background-color: #E0F7FA !important; }

.md-cyan-50-bg {
  background-color: #E0F7FA !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-50-fg {
  color: #E0F7FA !important; }

.cyan-50-border {
  border-color: #E0F7FA !important; }

.cyan-50-border-top {
  border-top-color: #E0F7FA !important; }

.cyan-50-border-right {
  border-right-color: #E0F7FA !important; }

.cyan-50-border-bottom {
  border-bottom-color: #E0F7FA !important; }

.cyan-50-border-left {
  border-left-color: #E0F7FA !important; }

.cyan-100-bg {
  background-color: #B2EBF2 !important; }

.md-cyan-100-bg {
  background-color: #B2EBF2 !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-100-fg {
  color: #B2EBF2 !important; }

.cyan-100-border {
  border-color: #B2EBF2 !important; }

.cyan-100-border-top {
  border-top-color: #B2EBF2 !important; }

.cyan-100-border-right {
  border-right-color: #B2EBF2 !important; }

.cyan-100-border-bottom {
  border-bottom-color: #B2EBF2 !important; }

.cyan-100-border-left {
  border-left-color: #B2EBF2 !important; }

.cyan-200-bg {
  background-color: #80DEEA !important; }

.md-cyan-200-bg {
  background-color: #80DEEA !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-200-fg {
  color: #80DEEA !important; }

.cyan-200-border {
  border-color: #80DEEA !important; }

.cyan-200-border-top {
  border-top-color: #80DEEA !important; }

.cyan-200-border-right {
  border-right-color: #80DEEA !important; }

.cyan-200-border-bottom {
  border-bottom-color: #80DEEA !important; }

.cyan-200-border-left {
  border-left-color: #80DEEA !important; }

.cyan-300-bg {
  background-color: #4DD0E1 !important; }

.md-cyan-300-bg {
  background-color: #4DD0E1 !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-300-fg {
  color: #4DD0E1 !important; }

.cyan-300-border {
  border-color: #4DD0E1 !important; }

.cyan-300-border-top {
  border-top-color: #4DD0E1 !important; }

.cyan-300-border-right {
  border-right-color: #4DD0E1 !important; }

.cyan-300-border-bottom {
  border-bottom-color: #4DD0E1 !important; }

.cyan-300-border-left {
  border-left-color: #4DD0E1 !important; }

.cyan-400-bg {
  background-color: #26C6DA !important; }

.md-cyan-400-bg {
  background-color: #26C6DA !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-400-fg {
  color: #26C6DA !important; }

.cyan-400-border {
  border-color: #26C6DA !important; }

.cyan-400-border-top {
  border-top-color: #26C6DA !important; }

.cyan-400-border-right {
  border-right-color: #26C6DA !important; }

.cyan-400-border-bottom {
  border-bottom-color: #26C6DA !important; }

.cyan-400-border-left {
  border-left-color: #26C6DA !important; }

.cyan-500-bg {
  background-color: #00BCD4 !important; }

.md-cyan-500-bg {
  background-color: #00BCD4 !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-500-fg {
  color: #00BCD4 !important; }

.cyan-500-border {
  border-color: #00BCD4 !important; }

.cyan-500-border-top {
  border-top-color: #00BCD4 !important; }

.cyan-500-border-right {
  border-right-color: #00BCD4 !important; }

.cyan-500-border-bottom {
  border-bottom-color: #00BCD4 !important; }

.cyan-500-border-left {
  border-left-color: #00BCD4 !important; }

.md-cyan-bg {
  background-color: #00BCD4 !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-bg {
  background-color: #00BCD4 !important; }

.cyan-fg {
  color: #00BCD4 !important; }

.cyan-border {
  border-color: #00BCD4 !important; }

.cyan-border-top {
  border-top-color: #00BCD4 !important; }

.cyan-border-right {
  border-right-color: #00BCD4 !important; }

.cyan-border-bottom {
  border-bottom-color: #00BCD4 !important; }

.cyan-border-left {
  border-left-color: #00BCD4 !important; }

.cyan-600-bg {
  background-color: #00ACC1 !important; }

.md-cyan-600-bg {
  background-color: #00ACC1 !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-600-fg {
  color: #00ACC1 !important; }

.cyan-600-border {
  border-color: #00ACC1 !important; }

.cyan-600-border-top {
  border-top-color: #00ACC1 !important; }

.cyan-600-border-right {
  border-right-color: #00ACC1 !important; }

.cyan-600-border-bottom {
  border-bottom-color: #00ACC1 !important; }

.cyan-600-border-left {
  border-left-color: #00ACC1 !important; }

.cyan-700-bg {
  background-color: #0097A7 !important; }

.md-cyan-700-bg {
  background-color: #0097A7 !important;
  color: white; }

.cyan-700-fg {
  color: #0097A7 !important; }

.cyan-700-border {
  border-color: #0097A7 !important; }

.cyan-700-border-top {
  border-top-color: #0097A7 !important; }

.cyan-700-border-right {
  border-right-color: #0097A7 !important; }

.cyan-700-border-bottom {
  border-bottom-color: #0097A7 !important; }

.cyan-700-border-left {
  border-left-color: #0097A7 !important; }

.cyan-800-bg {
  background-color: #00838F !important; }

.md-cyan-800-bg {
  background-color: #00838F !important;
  color: white; }

.cyan-800-fg {
  color: #00838F !important; }

.cyan-800-border {
  border-color: #00838F !important; }

.cyan-800-border-top {
  border-top-color: #00838F !important; }

.cyan-800-border-right {
  border-right-color: #00838F !important; }

.cyan-800-border-bottom {
  border-bottom-color: #00838F !important; }

.cyan-800-border-left {
  border-left-color: #00838F !important; }

.cyan-900-bg {
  background-color: #006064 !important; }

.md-cyan-900-bg {
  background-color: #006064 !important;
  color: white; }

.cyan-900-fg {
  color: #006064 !important; }

.cyan-900-border {
  border-color: #006064 !important; }

.cyan-900-border-top {
  border-top-color: #006064 !important; }

.cyan-900-border-right {
  border-right-color: #006064 !important; }

.cyan-900-border-bottom {
  border-bottom-color: #006064 !important; }

.cyan-900-border-left {
  border-left-color: #006064 !important; }

.cyan-A100-bg {
  background-color: #84FFFF !important; }

.md-cyan-A100-bg {
  background-color: #84FFFF !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-A100-fg {
  color: #84FFFF !important; }

.cyan-A100-border {
  border-color: #84FFFF !important; }

.cyan-A100-border-top {
  border-top-color: #84FFFF !important; }

.cyan-A100-border-right {
  border-right-color: #84FFFF !important; }

.cyan-A100-border-bottom {
  border-bottom-color: #84FFFF !important; }

.cyan-A100-border-left {
  border-left-color: #84FFFF !important; }

.cyan-A200-bg {
  background-color: #18FFFF !important; }

.md-cyan-A200-bg {
  background-color: #18FFFF !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-A200-fg {
  color: #18FFFF !important; }

.cyan-A200-border {
  border-color: #18FFFF !important; }

.cyan-A200-border-top {
  border-top-color: #18FFFF !important; }

.cyan-A200-border-right {
  border-right-color: #18FFFF !important; }

.cyan-A200-border-bottom {
  border-bottom-color: #18FFFF !important; }

.cyan-A200-border-left {
  border-left-color: #18FFFF !important; }

.cyan-A400-bg {
  background-color: #00E5FF !important; }

.md-cyan-A400-bg {
  background-color: #00E5FF !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-A400-fg {
  color: #00E5FF !important; }

.cyan-A400-border {
  border-color: #00E5FF !important; }

.cyan-A400-border-top {
  border-top-color: #00E5FF !important; }

.cyan-A400-border-right {
  border-right-color: #00E5FF !important; }

.cyan-A400-border-bottom {
  border-bottom-color: #00E5FF !important; }

.cyan-A400-border-left {
  border-left-color: #00E5FF !important; }

.cyan-A700-bg {
  background-color: #00B8D4 !important; }

.md-cyan-A700-bg {
  background-color: #00B8D4 !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-A700-fg {
  color: #00B8D4 !important; }

.cyan-A700-border {
  border-color: #00B8D4 !important; }

.cyan-A700-border-top {
  border-top-color: #00B8D4 !important; }

.cyan-A700-border-right {
  border-right-color: #00B8D4 !important; }

.cyan-A700-border-bottom {
  border-bottom-color: #00B8D4 !important; }

.cyan-A700-border-left {
  border-left-color: #00B8D4 !important; }

.teal-50-bg {
  background-color: #E0F2F1 !important; }

.md-teal-50-bg {
  background-color: #E0F2F1 !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-50-fg {
  color: #E0F2F1 !important; }

.teal-50-border {
  border-color: #E0F2F1 !important; }

.teal-50-border-top {
  border-top-color: #E0F2F1 !important; }

.teal-50-border-right {
  border-right-color: #E0F2F1 !important; }

.teal-50-border-bottom {
  border-bottom-color: #E0F2F1 !important; }

.teal-50-border-left {
  border-left-color: #E0F2F1 !important; }

.teal-100-bg {
  background-color: #B2DFDB !important; }

.md-teal-100-bg {
  background-color: #B2DFDB !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-100-fg {
  color: #B2DFDB !important; }

.teal-100-border {
  border-color: #B2DFDB !important; }

.teal-100-border-top {
  border-top-color: #B2DFDB !important; }

.teal-100-border-right {
  border-right-color: #B2DFDB !important; }

.teal-100-border-bottom {
  border-bottom-color: #B2DFDB !important; }

.teal-100-border-left {
  border-left-color: #B2DFDB !important; }

.teal-200-bg {
  background-color: #80CBC4 !important; }

.md-teal-200-bg {
  background-color: #80CBC4 !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-200-fg {
  color: #80CBC4 !important; }

.teal-200-border {
  border-color: #80CBC4 !important; }

.teal-200-border-top {
  border-top-color: #80CBC4 !important; }

.teal-200-border-right {
  border-right-color: #80CBC4 !important; }

.teal-200-border-bottom {
  border-bottom-color: #80CBC4 !important; }

.teal-200-border-left {
  border-left-color: #80CBC4 !important; }

.teal-300-bg {
  background-color: #4DB6AC !important; }

.md-teal-300-bg {
  background-color: #4DB6AC !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-300-fg {
  color: #4DB6AC !important; }

.teal-300-border {
  border-color: #4DB6AC !important; }

.teal-300-border-top {
  border-top-color: #4DB6AC !important; }

.teal-300-border-right {
  border-right-color: #4DB6AC !important; }

.teal-300-border-bottom {
  border-bottom-color: #4DB6AC !important; }

.teal-300-border-left {
  border-left-color: #4DB6AC !important; }

.teal-400-bg {
  background-color: #26A69A !important; }

.md-teal-400-bg {
  background-color: #26A69A !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-400-fg {
  color: #26A69A !important; }

.teal-400-border {
  border-color: #26A69A !important; }

.teal-400-border-top {
  border-top-color: #26A69A !important; }

.teal-400-border-right {
  border-right-color: #26A69A !important; }

.teal-400-border-bottom {
  border-bottom-color: #26A69A !important; }

.teal-400-border-left {
  border-left-color: #26A69A !important; }

.teal-500-bg {
  background-color: #009688 !important; }

.md-teal-500-bg {
  background-color: #009688 !important;
  color: white; }

.teal-500-fg {
  color: #009688 !important; }

.teal-500-border {
  border-color: #009688 !important; }

.teal-500-border-top {
  border-top-color: #009688 !important; }

.teal-500-border-right {
  border-right-color: #009688 !important; }

.teal-500-border-bottom {
  border-bottom-color: #009688 !important; }

.teal-500-border-left {
  border-left-color: #009688 !important; }

.md-teal-bg {
  background-color: #009688 !important;
  color: white; }

.teal-bg {
  background-color: #009688 !important; }

.teal-fg {
  color: #009688 !important; }

.teal-border {
  border-color: #009688 !important; }

.teal-border-top {
  border-top-color: #009688 !important; }

.teal-border-right {
  border-right-color: #009688 !important; }

.teal-border-bottom {
  border-bottom-color: #009688 !important; }

.teal-border-left {
  border-left-color: #009688 !important; }

.teal-600-bg {
  background-color: #00897B !important; }

.md-teal-600-bg {
  background-color: #00897B !important;
  color: white; }

.teal-600-fg {
  color: #00897B !important; }

.teal-600-border {
  border-color: #00897B !important; }

.teal-600-border-top {
  border-top-color: #00897B !important; }

.teal-600-border-right {
  border-right-color: #00897B !important; }

.teal-600-border-bottom {
  border-bottom-color: #00897B !important; }

.teal-600-border-left {
  border-left-color: #00897B !important; }

.teal-700-bg {
  background-color: #00796B !important; }

.md-teal-700-bg {
  background-color: #00796B !important;
  color: white; }

.teal-700-fg {
  color: #00796B !important; }

.teal-700-border {
  border-color: #00796B !important; }

.teal-700-border-top {
  border-top-color: #00796B !important; }

.teal-700-border-right {
  border-right-color: #00796B !important; }

.teal-700-border-bottom {
  border-bottom-color: #00796B !important; }

.teal-700-border-left {
  border-left-color: #00796B !important; }

.teal-800-bg {
  background-color: #00695C !important; }

.md-teal-800-bg {
  background-color: #00695C !important;
  color: rgba(255, 255, 255, 0.87); }

.teal-800-fg {
  color: #00695C !important; }

.teal-800-border {
  border-color: #00695C !important; }

.teal-800-border-top {
  border-top-color: #00695C !important; }

.teal-800-border-right {
  border-right-color: #00695C !important; }

.teal-800-border-bottom {
  border-bottom-color: #00695C !important; }

.teal-800-border-left {
  border-left-color: #00695C !important; }

.teal-900-bg {
  background-color: #004D40 !important; }

.md-teal-900-bg {
  background-color: #004D40 !important;
  color: rgba(255, 255, 255, 0.87); }

.teal-900-fg {
  color: #004D40 !important; }

.teal-900-border {
  border-color: #004D40 !important; }

.teal-900-border-top {
  border-top-color: #004D40 !important; }

.teal-900-border-right {
  border-right-color: #004D40 !important; }

.teal-900-border-bottom {
  border-bottom-color: #004D40 !important; }

.teal-900-border-left {
  border-left-color: #004D40 !important; }

.teal-A100-bg {
  background-color: #A7FFEB !important; }

.md-teal-A100-bg {
  background-color: #A7FFEB !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-A100-fg {
  color: #A7FFEB !important; }

.teal-A100-border {
  border-color: #A7FFEB !important; }

.teal-A100-border-top {
  border-top-color: #A7FFEB !important; }

.teal-A100-border-right {
  border-right-color: #A7FFEB !important; }

.teal-A100-border-bottom {
  border-bottom-color: #A7FFEB !important; }

.teal-A100-border-left {
  border-left-color: #A7FFEB !important; }

.teal-A200-bg {
  background-color: #64FFDA !important; }

.md-teal-A200-bg {
  background-color: #64FFDA !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-A200-fg {
  color: #64FFDA !important; }

.teal-A200-border {
  border-color: #64FFDA !important; }

.teal-A200-border-top {
  border-top-color: #64FFDA !important; }

.teal-A200-border-right {
  border-right-color: #64FFDA !important; }

.teal-A200-border-bottom {
  border-bottom-color: #64FFDA !important; }

.teal-A200-border-left {
  border-left-color: #64FFDA !important; }

.teal-A400-bg {
  background-color: #1DE9B6 !important; }

.md-teal-A400-bg {
  background-color: #1DE9B6 !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-A400-fg {
  color: #1DE9B6 !important; }

.teal-A400-border {
  border-color: #1DE9B6 !important; }

.teal-A400-border-top {
  border-top-color: #1DE9B6 !important; }

.teal-A400-border-right {
  border-right-color: #1DE9B6 !important; }

.teal-A400-border-bottom {
  border-bottom-color: #1DE9B6 !important; }

.teal-A400-border-left {
  border-left-color: #1DE9B6 !important; }

.teal-A700-bg {
  background-color: #00BFA5 !important; }

.md-teal-A700-bg {
  background-color: #00BFA5 !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-A700-fg {
  color: #00BFA5 !important; }

.teal-A700-border {
  border-color: #00BFA5 !important; }

.teal-A700-border-top {
  border-top-color: #00BFA5 !important; }

.teal-A700-border-right {
  border-right-color: #00BFA5 !important; }

.teal-A700-border-bottom {
  border-bottom-color: #00BFA5 !important; }

.teal-A700-border-left {
  border-left-color: #00BFA5 !important; }

.green-50-bg {
  background-color: #E8F5E9 !important; }

.md-green-50-bg {
  background-color: #E8F5E9 !important;
  color: rgba(0, 0, 0, 0.87); }

.green-50-fg {
  color: #E8F5E9 !important; }

.green-50-border {
  border-color: #E8F5E9 !important; }

.green-50-border-top {
  border-top-color: #E8F5E9 !important; }

.green-50-border-right {
  border-right-color: #E8F5E9 !important; }

.green-50-border-bottom {
  border-bottom-color: #E8F5E9 !important; }

.green-50-border-left {
  border-left-color: #E8F5E9 !important; }

.green-100-bg {
  background-color: #C8E6C9 !important; }

.md-green-100-bg {
  background-color: #C8E6C9 !important;
  color: rgba(0, 0, 0, 0.87); }

.green-100-fg {
  color: #C8E6C9 !important; }

.green-100-border {
  border-color: #C8E6C9 !important; }

.green-100-border-top {
  border-top-color: #C8E6C9 !important; }

.green-100-border-right {
  border-right-color: #C8E6C9 !important; }

.green-100-border-bottom {
  border-bottom-color: #C8E6C9 !important; }

.green-100-border-left {
  border-left-color: #C8E6C9 !important; }

.green-200-bg {
  background-color: #A5D6A7 !important; }

.md-green-200-bg {
  background-color: #A5D6A7 !important;
  color: rgba(0, 0, 0, 0.87); }

.green-200-fg {
  color: #A5D6A7 !important; }

.green-200-border {
  border-color: #A5D6A7 !important; }

.green-200-border-top {
  border-top-color: #A5D6A7 !important; }

.green-200-border-right {
  border-right-color: #A5D6A7 !important; }

.green-200-border-bottom {
  border-bottom-color: #A5D6A7 !important; }

.green-200-border-left {
  border-left-color: #A5D6A7 !important; }

.green-300-bg {
  background-color: #81C784 !important; }

.md-green-300-bg {
  background-color: #81C784 !important;
  color: rgba(0, 0, 0, 0.87); }

.green-300-fg {
  color: #81C784 !important; }

.green-300-border {
  border-color: #81C784 !important; }

.green-300-border-top {
  border-top-color: #81C784 !important; }

.green-300-border-right {
  border-right-color: #81C784 !important; }

.green-300-border-bottom {
  border-bottom-color: #81C784 !important; }

.green-300-border-left {
  border-left-color: #81C784 !important; }

.green-400-bg {
  background-color: #66BB6A !important; }

.md-green-400-bg {
  background-color: #66BB6A !important;
  color: rgba(0, 0, 0, 0.87); }

.green-400-fg {
  color: #66BB6A !important; }

.green-400-border {
  border-color: #66BB6A !important; }

.green-400-border-top {
  border-top-color: #66BB6A !important; }

.green-400-border-right {
  border-right-color: #66BB6A !important; }

.green-400-border-bottom {
  border-bottom-color: #66BB6A !important; }

.green-400-border-left {
  border-left-color: #66BB6A !important; }

.green-500-bg {
  background-color: #4CAF50 !important; }

.md-green-500-bg {
  background-color: #4CAF50 !important;
  color: rgba(0, 0, 0, 0.87); }

.green-500-fg {
  color: #4CAF50 !important; }

.green-500-border {
  border-color: #4CAF50 !important; }

.green-500-border-top {
  border-top-color: #4CAF50 !important; }

.green-500-border-right {
  border-right-color: #4CAF50 !important; }

.green-500-border-bottom {
  border-bottom-color: #4CAF50 !important; }

.green-500-border-left {
  border-left-color: #4CAF50 !important; }

.md-green-bg {
  background-color: #4CAF50 !important;
  color: rgba(0, 0, 0, 0.87); }

.green-bg {
  background-color: #4CAF50 !important; }

.green-fg {
  color: #4CAF50 !important; }

.green-border {
  border-color: #4CAF50 !important; }

.green-border-top {
  border-top-color: #4CAF50 !important; }

.green-border-right {
  border-right-color: #4CAF50 !important; }

.green-border-bottom {
  border-bottom-color: #4CAF50 !important; }

.green-border-left {
  border-left-color: #4CAF50 !important; }

.green-600-bg {
  background-color: #43A047 !important; }

.md-green-600-bg {
  background-color: #43A047 !important;
  color: white; }

.green-600-fg {
  color: #43A047 !important; }

.green-600-border {
  border-color: #43A047 !important; }

.green-600-border-top {
  border-top-color: #43A047 !important; }

.green-600-border-right {
  border-right-color: #43A047 !important; }

.green-600-border-bottom {
  border-bottom-color: #43A047 !important; }

.green-600-border-left {
  border-left-color: #43A047 !important; }

.green-700-bg {
  background-color: #388E3C !important; }

.md-green-700-bg {
  background-color: #388E3C !important;
  color: white; }

.green-700-fg {
  color: #388E3C !important; }

.green-700-border {
  border-color: #388E3C !important; }

.green-700-border-top {
  border-top-color: #388E3C !important; }

.green-700-border-right {
  border-right-color: #388E3C !important; }

.green-700-border-bottom {
  border-bottom-color: #388E3C !important; }

.green-700-border-left {
  border-left-color: #388E3C !important; }

.green-800-bg {
  background-color: #2E7D32 !important; }

.md-green-800-bg {
  background-color: #2E7D32 !important;
  color: rgba(255, 255, 255, 0.87); }

.green-800-fg {
  color: #2E7D32 !important; }

.green-800-border {
  border-color: #2E7D32 !important; }

.green-800-border-top {
  border-top-color: #2E7D32 !important; }

.green-800-border-right {
  border-right-color: #2E7D32 !important; }

.green-800-border-bottom {
  border-bottom-color: #2E7D32 !important; }

.green-800-border-left {
  border-left-color: #2E7D32 !important; }

.green-900-bg {
  background-color: #1B5E20 !important; }

.md-green-900-bg {
  background-color: #1B5E20 !important;
  color: rgba(255, 255, 255, 0.87); }

.green-900-fg {
  color: #1B5E20 !important; }

.green-900-border {
  border-color: #1B5E20 !important; }

.green-900-border-top {
  border-top-color: #1B5E20 !important; }

.green-900-border-right {
  border-right-color: #1B5E20 !important; }

.green-900-border-bottom {
  border-bottom-color: #1B5E20 !important; }

.green-900-border-left {
  border-left-color: #1B5E20 !important; }

.green-A100-bg {
  background-color: #B9F6CA !important; }

.md-green-A100-bg {
  background-color: #B9F6CA !important;
  color: rgba(0, 0, 0, 0.87); }

.green-A100-fg {
  color: #B9F6CA !important; }

.green-A100-border {
  border-color: #B9F6CA !important; }

.green-A100-border-top {
  border-top-color: #B9F6CA !important; }

.green-A100-border-right {
  border-right-color: #B9F6CA !important; }

.green-A100-border-bottom {
  border-bottom-color: #B9F6CA !important; }

.green-A100-border-left {
  border-left-color: #B9F6CA !important; }

.green-A200-bg {
  background-color: #69F0AE !important; }

.md-green-A200-bg {
  background-color: #69F0AE !important;
  color: rgba(0, 0, 0, 0.87); }

.green-A200-fg {
  color: #69F0AE !important; }

.green-A200-border {
  border-color: #69F0AE !important; }

.green-A200-border-top {
  border-top-color: #69F0AE !important; }

.green-A200-border-right {
  border-right-color: #69F0AE !important; }

.green-A200-border-bottom {
  border-bottom-color: #69F0AE !important; }

.green-A200-border-left {
  border-left-color: #69F0AE !important; }

.green-A400-bg {
  background-color: #00E676 !important; }

.md-green-A400-bg {
  background-color: #00E676 !important;
  color: rgba(0, 0, 0, 0.87); }

.green-A400-fg {
  color: #00E676 !important; }

.green-A400-border {
  border-color: #00E676 !important; }

.green-A400-border-top {
  border-top-color: #00E676 !important; }

.green-A400-border-right {
  border-right-color: #00E676 !important; }

.green-A400-border-bottom {
  border-bottom-color: #00E676 !important; }

.green-A400-border-left {
  border-left-color: #00E676 !important; }

.green-A700-bg {
  background-color: #00C853 !important; }

.md-green-A700-bg {
  background-color: #00C853 !important;
  color: rgba(0, 0, 0, 0.87); }

.green-A700-fg {
  color: #00C853 !important; }

.green-A700-border {
  border-color: #00C853 !important; }

.green-A700-border-top {
  border-top-color: #00C853 !important; }

.green-A700-border-right {
  border-right-color: #00C853 !important; }

.green-A700-border-bottom {
  border-bottom-color: #00C853 !important; }

.green-A700-border-left {
  border-left-color: #00C853 !important; }

.light-green-50-bg {
  background-color: #F1F8E9 !important; }

.md-light-green-50-bg {
  background-color: #F1F8E9 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-50-fg {
  color: #F1F8E9 !important; }

.light-green-50-border {
  border-color: #F1F8E9 !important; }

.light-green-50-border-top {
  border-top-color: #F1F8E9 !important; }

.light-green-50-border-right {
  border-right-color: #F1F8E9 !important; }

.light-green-50-border-bottom {
  border-bottom-color: #F1F8E9 !important; }

.light-green-50-border-left {
  border-left-color: #F1F8E9 !important; }

.light-green-100-bg {
  background-color: #DCEDC8 !important; }

.md-light-green-100-bg {
  background-color: #DCEDC8 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-100-fg {
  color: #DCEDC8 !important; }

.light-green-100-border {
  border-color: #DCEDC8 !important; }

.light-green-100-border-top {
  border-top-color: #DCEDC8 !important; }

.light-green-100-border-right {
  border-right-color: #DCEDC8 !important; }

.light-green-100-border-bottom {
  border-bottom-color: #DCEDC8 !important; }

.light-green-100-border-left {
  border-left-color: #DCEDC8 !important; }

.light-green-200-bg {
  background-color: #C5E1A5 !important; }

.md-light-green-200-bg {
  background-color: #C5E1A5 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-200-fg {
  color: #C5E1A5 !important; }

.light-green-200-border {
  border-color: #C5E1A5 !important; }

.light-green-200-border-top {
  border-top-color: #C5E1A5 !important; }

.light-green-200-border-right {
  border-right-color: #C5E1A5 !important; }

.light-green-200-border-bottom {
  border-bottom-color: #C5E1A5 !important; }

.light-green-200-border-left {
  border-left-color: #C5E1A5 !important; }

.light-green-300-bg {
  background-color: #AED581 !important; }

.md-light-green-300-bg {
  background-color: #AED581 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-300-fg {
  color: #AED581 !important; }

.light-green-300-border {
  border-color: #AED581 !important; }

.light-green-300-border-top {
  border-top-color: #AED581 !important; }

.light-green-300-border-right {
  border-right-color: #AED581 !important; }

.light-green-300-border-bottom {
  border-bottom-color: #AED581 !important; }

.light-green-300-border-left {
  border-left-color: #AED581 !important; }

.light-green-400-bg {
  background-color: #9CCC65 !important; }

.md-light-green-400-bg {
  background-color: #9CCC65 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-400-fg {
  color: #9CCC65 !important; }

.light-green-400-border {
  border-color: #9CCC65 !important; }

.light-green-400-border-top {
  border-top-color: #9CCC65 !important; }

.light-green-400-border-right {
  border-right-color: #9CCC65 !important; }

.light-green-400-border-bottom {
  border-bottom-color: #9CCC65 !important; }

.light-green-400-border-left {
  border-left-color: #9CCC65 !important; }

.light-green-500-bg {
  background-color: #8BC34A !important; }

.md-light-green-500-bg {
  background-color: #8BC34A !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-500-fg {
  color: #8BC34A !important; }

.light-green-500-border {
  border-color: #8BC34A !important; }

.light-green-500-border-top {
  border-top-color: #8BC34A !important; }

.light-green-500-border-right {
  border-right-color: #8BC34A !important; }

.light-green-500-border-bottom {
  border-bottom-color: #8BC34A !important; }

.light-green-500-border-left {
  border-left-color: #8BC34A !important; }

.md-light-green-bg {
  background-color: #8BC34A !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-bg {
  background-color: #8BC34A !important; }

.light-green-fg {
  color: #8BC34A !important; }

.light-green-border {
  border-color: #8BC34A !important; }

.light-green-border-top {
  border-top-color: #8BC34A !important; }

.light-green-border-right {
  border-right-color: #8BC34A !important; }

.light-green-border-bottom {
  border-bottom-color: #8BC34A !important; }

.light-green-border-left {
  border-left-color: #8BC34A !important; }

.light-green-600-bg {
  background-color: #7CB342 !important; }

.md-light-green-600-bg {
  background-color: #7CB342 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-600-fg {
  color: #7CB342 !important; }

.light-green-600-border {
  border-color: #7CB342 !important; }

.light-green-600-border-top {
  border-top-color: #7CB342 !important; }

.light-green-600-border-right {
  border-right-color: #7CB342 !important; }

.light-green-600-border-bottom {
  border-bottom-color: #7CB342 !important; }

.light-green-600-border-left {
  border-left-color: #7CB342 !important; }

.light-green-700-bg {
  background-color: #689F38 !important; }

.md-light-green-700-bg {
  background-color: #689F38 !important;
  color: white; }

.light-green-700-fg {
  color: #689F38 !important; }

.light-green-700-border {
  border-color: #689F38 !important; }

.light-green-700-border-top {
  border-top-color: #689F38 !important; }

.light-green-700-border-right {
  border-right-color: #689F38 !important; }

.light-green-700-border-bottom {
  border-bottom-color: #689F38 !important; }

.light-green-700-border-left {
  border-left-color: #689F38 !important; }

.light-green-800-bg {
  background-color: #558B2F !important; }

.md-light-green-800-bg {
  background-color: #558B2F !important;
  color: white; }

.light-green-800-fg {
  color: #558B2F !important; }

.light-green-800-border {
  border-color: #558B2F !important; }

.light-green-800-border-top {
  border-top-color: #558B2F !important; }

.light-green-800-border-right {
  border-right-color: #558B2F !important; }

.light-green-800-border-bottom {
  border-bottom-color: #558B2F !important; }

.light-green-800-border-left {
  border-left-color: #558B2F !important; }

.light-green-900-bg {
  background-color: #33691E !important; }

.md-light-green-900-bg {
  background-color: #33691E !important;
  color: white; }

.light-green-900-fg {
  color: #33691E !important; }

.light-green-900-border {
  border-color: #33691E !important; }

.light-green-900-border-top {
  border-top-color: #33691E !important; }

.light-green-900-border-right {
  border-right-color: #33691E !important; }

.light-green-900-border-bottom {
  border-bottom-color: #33691E !important; }

.light-green-900-border-left {
  border-left-color: #33691E !important; }

.light-green-A100-bg {
  background-color: #CCFF90 !important; }

.md-light-green-A100-bg {
  background-color: #CCFF90 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-A100-fg {
  color: #CCFF90 !important; }

.light-green-A100-border {
  border-color: #CCFF90 !important; }

.light-green-A100-border-top {
  border-top-color: #CCFF90 !important; }

.light-green-A100-border-right {
  border-right-color: #CCFF90 !important; }

.light-green-A100-border-bottom {
  border-bottom-color: #CCFF90 !important; }

.light-green-A100-border-left {
  border-left-color: #CCFF90 !important; }

.light-green-A200-bg {
  background-color: #B2FF59 !important; }

.md-light-green-A200-bg {
  background-color: #B2FF59 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-A200-fg {
  color: #B2FF59 !important; }

.light-green-A200-border {
  border-color: #B2FF59 !important; }

.light-green-A200-border-top {
  border-top-color: #B2FF59 !important; }

.light-green-A200-border-right {
  border-right-color: #B2FF59 !important; }

.light-green-A200-border-bottom {
  border-bottom-color: #B2FF59 !important; }

.light-green-A200-border-left {
  border-left-color: #B2FF59 !important; }

.light-green-A400-bg {
  background-color: #76FF03 !important; }

.md-light-green-A400-bg {
  background-color: #76FF03 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-A400-fg {
  color: #76FF03 !important; }

.light-green-A400-border {
  border-color: #76FF03 !important; }

.light-green-A400-border-top {
  border-top-color: #76FF03 !important; }

.light-green-A400-border-right {
  border-right-color: #76FF03 !important; }

.light-green-A400-border-bottom {
  border-bottom-color: #76FF03 !important; }

.light-green-A400-border-left {
  border-left-color: #76FF03 !important; }

.light-green-A700-bg {
  background-color: #64DD17 !important; }

.md-light-green-A700-bg {
  background-color: #64DD17 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-A700-fg {
  color: #64DD17 !important; }

.light-green-A700-border {
  border-color: #64DD17 !important; }

.light-green-A700-border-top {
  border-top-color: #64DD17 !important; }

.light-green-A700-border-right {
  border-right-color: #64DD17 !important; }

.light-green-A700-border-bottom {
  border-bottom-color: #64DD17 !important; }

.light-green-A700-border-left {
  border-left-color: #64DD17 !important; }

.lime-50-bg {
  background-color: #F9FBE7 !important; }

.md-lime-50-bg {
  background-color: #F9FBE7 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-50-fg {
  color: #F9FBE7 !important; }

.lime-50-border {
  border-color: #F9FBE7 !important; }

.lime-50-border-top {
  border-top-color: #F9FBE7 !important; }

.lime-50-border-right {
  border-right-color: #F9FBE7 !important; }

.lime-50-border-bottom {
  border-bottom-color: #F9FBE7 !important; }

.lime-50-border-left {
  border-left-color: #F9FBE7 !important; }

.lime-100-bg {
  background-color: #F0F4C3 !important; }

.md-lime-100-bg {
  background-color: #F0F4C3 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-100-fg {
  color: #F0F4C3 !important; }

.lime-100-border {
  border-color: #F0F4C3 !important; }

.lime-100-border-top {
  border-top-color: #F0F4C3 !important; }

.lime-100-border-right {
  border-right-color: #F0F4C3 !important; }

.lime-100-border-bottom {
  border-bottom-color: #F0F4C3 !important; }

.lime-100-border-left {
  border-left-color: #F0F4C3 !important; }

.lime-200-bg {
  background-color: #E6EE9C !important; }

.md-lime-200-bg {
  background-color: #E6EE9C !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-200-fg {
  color: #E6EE9C !important; }

.lime-200-border {
  border-color: #E6EE9C !important; }

.lime-200-border-top {
  border-top-color: #E6EE9C !important; }

.lime-200-border-right {
  border-right-color: #E6EE9C !important; }

.lime-200-border-bottom {
  border-bottom-color: #E6EE9C !important; }

.lime-200-border-left {
  border-left-color: #E6EE9C !important; }

.lime-300-bg {
  background-color: #DCE775 !important; }

.md-lime-300-bg {
  background-color: #DCE775 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-300-fg {
  color: #DCE775 !important; }

.lime-300-border {
  border-color: #DCE775 !important; }

.lime-300-border-top {
  border-top-color: #DCE775 !important; }

.lime-300-border-right {
  border-right-color: #DCE775 !important; }

.lime-300-border-bottom {
  border-bottom-color: #DCE775 !important; }

.lime-300-border-left {
  border-left-color: #DCE775 !important; }

.lime-400-bg {
  background-color: #D4E157 !important; }

.md-lime-400-bg {
  background-color: #D4E157 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-400-fg {
  color: #D4E157 !important; }

.lime-400-border {
  border-color: #D4E157 !important; }

.lime-400-border-top {
  border-top-color: #D4E157 !important; }

.lime-400-border-right {
  border-right-color: #D4E157 !important; }

.lime-400-border-bottom {
  border-bottom-color: #D4E157 !important; }

.lime-400-border-left {
  border-left-color: #D4E157 !important; }

.lime-500-bg {
  background-color: #CDDC39 !important; }

.md-lime-500-bg {
  background-color: #CDDC39 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-500-fg {
  color: #CDDC39 !important; }

.lime-500-border {
  border-color: #CDDC39 !important; }

.lime-500-border-top {
  border-top-color: #CDDC39 !important; }

.lime-500-border-right {
  border-right-color: #CDDC39 !important; }

.lime-500-border-bottom {
  border-bottom-color: #CDDC39 !important; }

.lime-500-border-left {
  border-left-color: #CDDC39 !important; }

.md-lime-bg {
  background-color: #CDDC39 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-bg {
  background-color: #CDDC39 !important; }

.lime-fg {
  color: #CDDC39 !important; }

.lime-border {
  border-color: #CDDC39 !important; }

.lime-border-top {
  border-top-color: #CDDC39 !important; }

.lime-border-right {
  border-right-color: #CDDC39 !important; }

.lime-border-bottom {
  border-bottom-color: #CDDC39 !important; }

.lime-border-left {
  border-left-color: #CDDC39 !important; }

.lime-600-bg {
  background-color: #C0CA33 !important; }

.md-lime-600-bg {
  background-color: #C0CA33 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-600-fg {
  color: #C0CA33 !important; }

.lime-600-border {
  border-color: #C0CA33 !important; }

.lime-600-border-top {
  border-top-color: #C0CA33 !important; }

.lime-600-border-right {
  border-right-color: #C0CA33 !important; }

.lime-600-border-bottom {
  border-bottom-color: #C0CA33 !important; }

.lime-600-border-left {
  border-left-color: #C0CA33 !important; }

.lime-700-bg {
  background-color: #AFB42B !important; }

.md-lime-700-bg {
  background-color: #AFB42B !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-700-fg {
  color: #AFB42B !important; }

.lime-700-border {
  border-color: #AFB42B !important; }

.lime-700-border-top {
  border-top-color: #AFB42B !important; }

.lime-700-border-right {
  border-right-color: #AFB42B !important; }

.lime-700-border-bottom {
  border-bottom-color: #AFB42B !important; }

.lime-700-border-left {
  border-left-color: #AFB42B !important; }

.lime-800-bg {
  background-color: #9E9D24 !important; }

.md-lime-800-bg {
  background-color: #9E9D24 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-800-fg {
  color: #9E9D24 !important; }

.lime-800-border {
  border-color: #9E9D24 !important; }

.lime-800-border-top {
  border-top-color: #9E9D24 !important; }

.lime-800-border-right {
  border-right-color: #9E9D24 !important; }

.lime-800-border-bottom {
  border-bottom-color: #9E9D24 !important; }

.lime-800-border-left {
  border-left-color: #9E9D24 !important; }

.lime-900-bg {
  background-color: #827717 !important; }

.md-lime-900-bg {
  background-color: #827717 !important;
  color: white; }

.lime-900-fg {
  color: #827717 !important; }

.lime-900-border {
  border-color: #827717 !important; }

.lime-900-border-top {
  border-top-color: #827717 !important; }

.lime-900-border-right {
  border-right-color: #827717 !important; }

.lime-900-border-bottom {
  border-bottom-color: #827717 !important; }

.lime-900-border-left {
  border-left-color: #827717 !important; }

.lime-A100-bg {
  background-color: #F4FF81 !important; }

.md-lime-A100-bg {
  background-color: #F4FF81 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-A100-fg {
  color: #F4FF81 !important; }

.lime-A100-border {
  border-color: #F4FF81 !important; }

.lime-A100-border-top {
  border-top-color: #F4FF81 !important; }

.lime-A100-border-right {
  border-right-color: #F4FF81 !important; }

.lime-A100-border-bottom {
  border-bottom-color: #F4FF81 !important; }

.lime-A100-border-left {
  border-left-color: #F4FF81 !important; }

.lime-A200-bg {
  background-color: #EEFF41 !important; }

.md-lime-A200-bg {
  background-color: #EEFF41 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-A200-fg {
  color: #EEFF41 !important; }

.lime-A200-border {
  border-color: #EEFF41 !important; }

.lime-A200-border-top {
  border-top-color: #EEFF41 !important; }

.lime-A200-border-right {
  border-right-color: #EEFF41 !important; }

.lime-A200-border-bottom {
  border-bottom-color: #EEFF41 !important; }

.lime-A200-border-left {
  border-left-color: #EEFF41 !important; }

.lime-A400-bg {
  background-color: #C6FF00 !important; }

.md-lime-A400-bg {
  background-color: #C6FF00 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-A400-fg {
  color: #C6FF00 !important; }

.lime-A400-border {
  border-color: #C6FF00 !important; }

.lime-A400-border-top {
  border-top-color: #C6FF00 !important; }

.lime-A400-border-right {
  border-right-color: #C6FF00 !important; }

.lime-A400-border-bottom {
  border-bottom-color: #C6FF00 !important; }

.lime-A400-border-left {
  border-left-color: #C6FF00 !important; }

.lime-A700-bg {
  background-color: #AEEA00 !important; }

.md-lime-A700-bg {
  background-color: #AEEA00 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-A700-fg {
  color: #AEEA00 !important; }

.lime-A700-border {
  border-color: #AEEA00 !important; }

.lime-A700-border-top {
  border-top-color: #AEEA00 !important; }

.lime-A700-border-right {
  border-right-color: #AEEA00 !important; }

.lime-A700-border-bottom {
  border-bottom-color: #AEEA00 !important; }

.lime-A700-border-left {
  border-left-color: #AEEA00 !important; }

.yellow-50-bg {
  background-color: #FFFDE7 !important; }

.md-yellow-50-bg {
  background-color: #FFFDE7 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-50-fg {
  color: #FFFDE7 !important; }

.yellow-50-border {
  border-color: #FFFDE7 !important; }

.yellow-50-border-top {
  border-top-color: #FFFDE7 !important; }

.yellow-50-border-right {
  border-right-color: #FFFDE7 !important; }

.yellow-50-border-bottom {
  border-bottom-color: #FFFDE7 !important; }

.yellow-50-border-left {
  border-left-color: #FFFDE7 !important; }

.yellow-100-bg {
  background-color: #FFF9C4 !important; }

.md-yellow-100-bg {
  background-color: #FFF9C4 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-100-fg {
  color: #FFF9C4 !important; }

.yellow-100-border {
  border-color: #FFF9C4 !important; }

.yellow-100-border-top {
  border-top-color: #FFF9C4 !important; }

.yellow-100-border-right {
  border-right-color: #FFF9C4 !important; }

.yellow-100-border-bottom {
  border-bottom-color: #FFF9C4 !important; }

.yellow-100-border-left {
  border-left-color: #FFF9C4 !important; }

.yellow-200-bg {
  background-color: #FFF59D !important; }

.md-yellow-200-bg {
  background-color: #FFF59D !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-200-fg {
  color: #FFF59D !important; }

.yellow-200-border {
  border-color: #FFF59D !important; }

.yellow-200-border-top {
  border-top-color: #FFF59D !important; }

.yellow-200-border-right {
  border-right-color: #FFF59D !important; }

.yellow-200-border-bottom {
  border-bottom-color: #FFF59D !important; }

.yellow-200-border-left {
  border-left-color: #FFF59D !important; }

.yellow-300-bg {
  background-color: #FFF176 !important; }

.md-yellow-300-bg {
  background-color: #FFF176 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-300-fg {
  color: #FFF176 !important; }

.yellow-300-border {
  border-color: #FFF176 !important; }

.yellow-300-border-top {
  border-top-color: #FFF176 !important; }

.yellow-300-border-right {
  border-right-color: #FFF176 !important; }

.yellow-300-border-bottom {
  border-bottom-color: #FFF176 !important; }

.yellow-300-border-left {
  border-left-color: #FFF176 !important; }

.yellow-400-bg {
  background-color: #FFEE58 !important; }

.md-yellow-400-bg {
  background-color: #FFEE58 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-400-fg {
  color: #FFEE58 !important; }

.yellow-400-border {
  border-color: #FFEE58 !important; }

.yellow-400-border-top {
  border-top-color: #FFEE58 !important; }

.yellow-400-border-right {
  border-right-color: #FFEE58 !important; }

.yellow-400-border-bottom {
  border-bottom-color: #FFEE58 !important; }

.yellow-400-border-left {
  border-left-color: #FFEE58 !important; }

.yellow-500-bg {
  background-color: #FFEB3B !important; }

.md-yellow-500-bg {
  background-color: #FFEB3B !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-500-fg {
  color: #FFEB3B !important; }

.yellow-500-border {
  border-color: #FFEB3B !important; }

.yellow-500-border-top {
  border-top-color: #FFEB3B !important; }

.yellow-500-border-right {
  border-right-color: #FFEB3B !important; }

.yellow-500-border-bottom {
  border-bottom-color: #FFEB3B !important; }

.yellow-500-border-left {
  border-left-color: #FFEB3B !important; }

.md-yellow-bg {
  background-color: #FFEB3B !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-bg {
  background-color: #FFEB3B !important; }

.yellow-fg {
  color: #FFEB3B !important; }

.yellow-border {
  border-color: #FFEB3B !important; }

.yellow-border-top {
  border-top-color: #FFEB3B !important; }

.yellow-border-right {
  border-right-color: #FFEB3B !important; }

.yellow-border-bottom {
  border-bottom-color: #FFEB3B !important; }

.yellow-border-left {
  border-left-color: #FFEB3B !important; }

.yellow-600-bg {
  background-color: #FDD835 !important; }

.md-yellow-600-bg {
  background-color: #FDD835 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-600-fg {
  color: #FDD835 !important; }

.yellow-600-border {
  border-color: #FDD835 !important; }

.yellow-600-border-top {
  border-top-color: #FDD835 !important; }

.yellow-600-border-right {
  border-right-color: #FDD835 !important; }

.yellow-600-border-bottom {
  border-bottom-color: #FDD835 !important; }

.yellow-600-border-left {
  border-left-color: #FDD835 !important; }

.yellow-700-bg {
  background-color: #FBC02D !important; }

.md-yellow-700-bg {
  background-color: #FBC02D !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-700-fg {
  color: #FBC02D !important; }

.yellow-700-border {
  border-color: #FBC02D !important; }

.yellow-700-border-top {
  border-top-color: #FBC02D !important; }

.yellow-700-border-right {
  border-right-color: #FBC02D !important; }

.yellow-700-border-bottom {
  border-bottom-color: #FBC02D !important; }

.yellow-700-border-left {
  border-left-color: #FBC02D !important; }

.yellow-800-bg {
  background-color: #F9A825 !important; }

.md-yellow-800-bg {
  background-color: #F9A825 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-800-fg {
  color: #F9A825 !important; }

.yellow-800-border {
  border-color: #F9A825 !important; }

.yellow-800-border-top {
  border-top-color: #F9A825 !important; }

.yellow-800-border-right {
  border-right-color: #F9A825 !important; }

.yellow-800-border-bottom {
  border-bottom-color: #F9A825 !important; }

.yellow-800-border-left {
  border-left-color: #F9A825 !important; }

.yellow-900-bg {
  background-color: #F57F17 !important; }

.md-yellow-900-bg {
  background-color: #F57F17 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-900-fg {
  color: #F57F17 !important; }

.yellow-900-border {
  border-color: #F57F17 !important; }

.yellow-900-border-top {
  border-top-color: #F57F17 !important; }

.yellow-900-border-right {
  border-right-color: #F57F17 !important; }

.yellow-900-border-bottom {
  border-bottom-color: #F57F17 !important; }

.yellow-900-border-left {
  border-left-color: #F57F17 !important; }

.yellow-A100-bg {
  background-color: #FFFF8D !important; }

.md-yellow-A100-bg {
  background-color: #FFFF8D !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-A100-fg {
  color: #FFFF8D !important; }

.yellow-A100-border {
  border-color: #FFFF8D !important; }

.yellow-A100-border-top {
  border-top-color: #FFFF8D !important; }

.yellow-A100-border-right {
  border-right-color: #FFFF8D !important; }

.yellow-A100-border-bottom {
  border-bottom-color: #FFFF8D !important; }

.yellow-A100-border-left {
  border-left-color: #FFFF8D !important; }

.yellow-A200-bg {
  background-color: #FFFF00 !important; }

.md-yellow-A200-bg {
  background-color: #FFFF00 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-A200-fg {
  color: #FFFF00 !important; }

.yellow-A200-border {
  border-color: #FFFF00 !important; }

.yellow-A200-border-top {
  border-top-color: #FFFF00 !important; }

.yellow-A200-border-right {
  border-right-color: #FFFF00 !important; }

.yellow-A200-border-bottom {
  border-bottom-color: #FFFF00 !important; }

.yellow-A200-border-left {
  border-left-color: #FFFF00 !important; }

.yellow-A400-bg {
  background-color: #FFEA00 !important; }

.md-yellow-A400-bg {
  background-color: #FFEA00 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-A400-fg {
  color: #FFEA00 !important; }

.yellow-A400-border {
  border-color: #FFEA00 !important; }

.yellow-A400-border-top {
  border-top-color: #FFEA00 !important; }

.yellow-A400-border-right {
  border-right-color: #FFEA00 !important; }

.yellow-A400-border-bottom {
  border-bottom-color: #FFEA00 !important; }

.yellow-A400-border-left {
  border-left-color: #FFEA00 !important; }

.yellow-A700-bg {
  background-color: #FFD600 !important; }

.md-yellow-A700-bg {
  background-color: #FFD600 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-A700-fg {
  color: #FFD600 !important; }

.yellow-A700-border {
  border-color: #FFD600 !important; }

.yellow-A700-border-top {
  border-top-color: #FFD600 !important; }

.yellow-A700-border-right {
  border-right-color: #FFD600 !important; }

.yellow-A700-border-bottom {
  border-bottom-color: #FFD600 !important; }

.yellow-A700-border-left {
  border-left-color: #FFD600 !important; }

.amber-50-bg {
  background-color: #FFF8E1 !important; }

.md-amber-50-bg {
  background-color: #FFF8E1 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-50-fg {
  color: #FFF8E1 !important; }

.amber-50-border {
  border-color: #FFF8E1 !important; }

.amber-50-border-top {
  border-top-color: #FFF8E1 !important; }

.amber-50-border-right {
  border-right-color: #FFF8E1 !important; }

.amber-50-border-bottom {
  border-bottom-color: #FFF8E1 !important; }

.amber-50-border-left {
  border-left-color: #FFF8E1 !important; }

.amber-100-bg {
  background-color: #FFECB3 !important; }

.md-amber-100-bg {
  background-color: #FFECB3 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-100-fg {
  color: #FFECB3 !important; }

.amber-100-border {
  border-color: #FFECB3 !important; }

.amber-100-border-top {
  border-top-color: #FFECB3 !important; }

.amber-100-border-right {
  border-right-color: #FFECB3 !important; }

.amber-100-border-bottom {
  border-bottom-color: #FFECB3 !important; }

.amber-100-border-left {
  border-left-color: #FFECB3 !important; }

.amber-200-bg {
  background-color: #FFE082 !important; }

.md-amber-200-bg {
  background-color: #FFE082 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-200-fg {
  color: #FFE082 !important; }

.amber-200-border {
  border-color: #FFE082 !important; }

.amber-200-border-top {
  border-top-color: #FFE082 !important; }

.amber-200-border-right {
  border-right-color: #FFE082 !important; }

.amber-200-border-bottom {
  border-bottom-color: #FFE082 !important; }

.amber-200-border-left {
  border-left-color: #FFE082 !important; }

.amber-300-bg {
  background-color: #FFD54F !important; }

.md-amber-300-bg {
  background-color: #FFD54F !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-300-fg {
  color: #FFD54F !important; }

.amber-300-border {
  border-color: #FFD54F !important; }

.amber-300-border-top {
  border-top-color: #FFD54F !important; }

.amber-300-border-right {
  border-right-color: #FFD54F !important; }

.amber-300-border-bottom {
  border-bottom-color: #FFD54F !important; }

.amber-300-border-left {
  border-left-color: #FFD54F !important; }

.amber-400-bg {
  background-color: #FFCA28 !important; }

.md-amber-400-bg {
  background-color: #FFCA28 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-400-fg {
  color: #FFCA28 !important; }

.amber-400-border {
  border-color: #FFCA28 !important; }

.amber-400-border-top {
  border-top-color: #FFCA28 !important; }

.amber-400-border-right {
  border-right-color: #FFCA28 !important; }

.amber-400-border-bottom {
  border-bottom-color: #FFCA28 !important; }

.amber-400-border-left {
  border-left-color: #FFCA28 !important; }

.amber-500-bg {
  background-color: #FFC107 !important; }

.md-amber-500-bg {
  background-color: #FFC107 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-500-fg {
  color: #FFC107 !important; }

.amber-500-border {
  border-color: #FFC107 !important; }

.amber-500-border-top {
  border-top-color: #FFC107 !important; }

.amber-500-border-right {
  border-right-color: #FFC107 !important; }

.amber-500-border-bottom {
  border-bottom-color: #FFC107 !important; }

.amber-500-border-left {
  border-left-color: #FFC107 !important; }

.md-amber-bg {
  background-color: #FFC107 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-bg {
  background-color: #FFC107 !important; }

.amber-fg {
  color: #FFC107 !important; }

.amber-border {
  border-color: #FFC107 !important; }

.amber-border-top {
  border-top-color: #FFC107 !important; }

.amber-border-right {
  border-right-color: #FFC107 !important; }

.amber-border-bottom {
  border-bottom-color: #FFC107 !important; }

.amber-border-left {
  border-left-color: #FFC107 !important; }

.amber-600-bg {
  background-color: #FFB300 !important; }

.md-amber-600-bg {
  background-color: #FFB300 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-600-fg {
  color: #FFB300 !important; }

.amber-600-border {
  border-color: #FFB300 !important; }

.amber-600-border-top {
  border-top-color: #FFB300 !important; }

.amber-600-border-right {
  border-right-color: #FFB300 !important; }

.amber-600-border-bottom {
  border-bottom-color: #FFB300 !important; }

.amber-600-border-left {
  border-left-color: #FFB300 !important; }

.amber-700-bg {
  background-color: #FFA000 !important; }

.md-amber-700-bg {
  background-color: #FFA000 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-700-fg {
  color: #FFA000 !important; }

.amber-700-border {
  border-color: #FFA000 !important; }

.amber-700-border-top {
  border-top-color: #FFA000 !important; }

.amber-700-border-right {
  border-right-color: #FFA000 !important; }

.amber-700-border-bottom {
  border-bottom-color: #FFA000 !important; }

.amber-700-border-left {
  border-left-color: #FFA000 !important; }

.amber-800-bg {
  background-color: #FF8F00 !important; }

.md-amber-800-bg {
  background-color: #FF8F00 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-800-fg {
  color: #FF8F00 !important; }

.amber-800-border {
  border-color: #FF8F00 !important; }

.amber-800-border-top {
  border-top-color: #FF8F00 !important; }

.amber-800-border-right {
  border-right-color: #FF8F00 !important; }

.amber-800-border-bottom {
  border-bottom-color: #FF8F00 !important; }

.amber-800-border-left {
  border-left-color: #FF8F00 !important; }

.amber-900-bg {
  background-color: #FF6F00 !important; }

.md-amber-900-bg {
  background-color: #FF6F00 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-900-fg {
  color: #FF6F00 !important; }

.amber-900-border {
  border-color: #FF6F00 !important; }

.amber-900-border-top {
  border-top-color: #FF6F00 !important; }

.amber-900-border-right {
  border-right-color: #FF6F00 !important; }

.amber-900-border-bottom {
  border-bottom-color: #FF6F00 !important; }

.amber-900-border-left {
  border-left-color: #FF6F00 !important; }

.amber-A100-bg {
  background-color: #FFE57F !important; }

.md-amber-A100-bg {
  background-color: #FFE57F !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-A100-fg {
  color: #FFE57F !important; }

.amber-A100-border {
  border-color: #FFE57F !important; }

.amber-A100-border-top {
  border-top-color: #FFE57F !important; }

.amber-A100-border-right {
  border-right-color: #FFE57F !important; }

.amber-A100-border-bottom {
  border-bottom-color: #FFE57F !important; }

.amber-A100-border-left {
  border-left-color: #FFE57F !important; }

.amber-A200-bg {
  background-color: #FFD740 !important; }

.md-amber-A200-bg {
  background-color: #FFD740 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-A200-fg {
  color: #FFD740 !important; }

.amber-A200-border {
  border-color: #FFD740 !important; }

.amber-A200-border-top {
  border-top-color: #FFD740 !important; }

.amber-A200-border-right {
  border-right-color: #FFD740 !important; }

.amber-A200-border-bottom {
  border-bottom-color: #FFD740 !important; }

.amber-A200-border-left {
  border-left-color: #FFD740 !important; }

.amber-A400-bg {
  background-color: #FFC400 !important; }

.md-amber-A400-bg {
  background-color: #FFC400 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-A400-fg {
  color: #FFC400 !important; }

.amber-A400-border {
  border-color: #FFC400 !important; }

.amber-A400-border-top {
  border-top-color: #FFC400 !important; }

.amber-A400-border-right {
  border-right-color: #FFC400 !important; }

.amber-A400-border-bottom {
  border-bottom-color: #FFC400 !important; }

.amber-A400-border-left {
  border-left-color: #FFC400 !important; }

.amber-A700-bg {
  background-color: #FFAB00 !important; }

.md-amber-A700-bg {
  background-color: #FFAB00 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-A700-fg {
  color: #FFAB00 !important; }

.amber-A700-border {
  border-color: #FFAB00 !important; }

.amber-A700-border-top {
  border-top-color: #FFAB00 !important; }

.amber-A700-border-right {
  border-right-color: #FFAB00 !important; }

.amber-A700-border-bottom {
  border-bottom-color: #FFAB00 !important; }

.amber-A700-border-left {
  border-left-color: #FFAB00 !important; }

.orange-50-bg {
  background-color: #FFF3E0 !important; }

.md-orange-50-bg {
  background-color: #FFF3E0 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-50-fg {
  color: #FFF3E0 !important; }

.orange-50-border {
  border-color: #FFF3E0 !important; }

.orange-50-border-top {
  border-top-color: #FFF3E0 !important; }

.orange-50-border-right {
  border-right-color: #FFF3E0 !important; }

.orange-50-border-bottom {
  border-bottom-color: #FFF3E0 !important; }

.orange-50-border-left {
  border-left-color: #FFF3E0 !important; }

.orange-100-bg {
  background-color: #FFE0B2 !important; }

.md-orange-100-bg {
  background-color: #FFE0B2 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-100-fg {
  color: #FFE0B2 !important; }

.orange-100-border {
  border-color: #FFE0B2 !important; }

.orange-100-border-top {
  border-top-color: #FFE0B2 !important; }

.orange-100-border-right {
  border-right-color: #FFE0B2 !important; }

.orange-100-border-bottom {
  border-bottom-color: #FFE0B2 !important; }

.orange-100-border-left {
  border-left-color: #FFE0B2 !important; }

.orange-200-bg {
  background-color: #FFCC80 !important; }

.md-orange-200-bg {
  background-color: #FFCC80 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-200-fg {
  color: #FFCC80 !important; }

.orange-200-border {
  border-color: #FFCC80 !important; }

.orange-200-border-top {
  border-top-color: #FFCC80 !important; }

.orange-200-border-right {
  border-right-color: #FFCC80 !important; }

.orange-200-border-bottom {
  border-bottom-color: #FFCC80 !important; }

.orange-200-border-left {
  border-left-color: #FFCC80 !important; }

.orange-300-bg {
  background-color: #FFB74D !important; }

.md-orange-300-bg {
  background-color: #FFB74D !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-300-fg {
  color: #FFB74D !important; }

.orange-300-border {
  border-color: #FFB74D !important; }

.orange-300-border-top {
  border-top-color: #FFB74D !important; }

.orange-300-border-right {
  border-right-color: #FFB74D !important; }

.orange-300-border-bottom {
  border-bottom-color: #FFB74D !important; }

.orange-300-border-left {
  border-left-color: #FFB74D !important; }

.orange-400-bg {
  background-color: #FFA726 !important; }

.md-orange-400-bg {
  background-color: #FFA726 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-400-fg {
  color: #FFA726 !important; }

.orange-400-border {
  border-color: #FFA726 !important; }

.orange-400-border-top {
  border-top-color: #FFA726 !important; }

.orange-400-border-right {
  border-right-color: #FFA726 !important; }

.orange-400-border-bottom {
  border-bottom-color: #FFA726 !important; }

.orange-400-border-left {
  border-left-color: #FFA726 !important; }

.orange-500-bg {
  background-color: #FF9800 !important; }

.md-orange-500-bg {
  background-color: #FF9800 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-500-fg {
  color: #FF9800 !important; }

.orange-500-border {
  border-color: #FF9800 !important; }

.orange-500-border-top {
  border-top-color: #FF9800 !important; }

.orange-500-border-right {
  border-right-color: #FF9800 !important; }

.orange-500-border-bottom {
  border-bottom-color: #FF9800 !important; }

.orange-500-border-left {
  border-left-color: #FF9800 !important; }

.md-orange-bg {
  background-color: #FF9800 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-bg {
  background-color: #FF9800 !important; }

.orange-fg {
  color: #FF9800 !important; }

.orange-border {
  border-color: #FF9800 !important; }

.orange-border-top {
  border-top-color: #FF9800 !important; }

.orange-border-right {
  border-right-color: #FF9800 !important; }

.orange-border-bottom {
  border-bottom-color: #FF9800 !important; }

.orange-border-left {
  border-left-color: #FF9800 !important; }

.orange-600-bg {
  background-color: #FB8C00 !important; }

.md-orange-600-bg {
  background-color: #FB8C00 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-600-fg {
  color: #FB8C00 !important; }

.orange-600-border {
  border-color: #FB8C00 !important; }

.orange-600-border-top {
  border-top-color: #FB8C00 !important; }

.orange-600-border-right {
  border-right-color: #FB8C00 !important; }

.orange-600-border-bottom {
  border-bottom-color: #FB8C00 !important; }

.orange-600-border-left {
  border-left-color: #FB8C00 !important; }

.orange-700-bg {
  background-color: #F57C00 !important; }

.md-orange-700-bg {
  background-color: #F57C00 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-700-fg {
  color: #F57C00 !important; }

.orange-700-border {
  border-color: #F57C00 !important; }

.orange-700-border-top {
  border-top-color: #F57C00 !important; }

.orange-700-border-right {
  border-right-color: #F57C00 !important; }

.orange-700-border-bottom {
  border-bottom-color: #F57C00 !important; }

.orange-700-border-left {
  border-left-color: #F57C00 !important; }

.orange-800-bg {
  background-color: #EF6C00 !important; }

.md-orange-800-bg {
  background-color: #EF6C00 !important;
  color: white; }

.orange-800-fg {
  color: #EF6C00 !important; }

.orange-800-border {
  border-color: #EF6C00 !important; }

.orange-800-border-top {
  border-top-color: #EF6C00 !important; }

.orange-800-border-right {
  border-right-color: #EF6C00 !important; }

.orange-800-border-bottom {
  border-bottom-color: #EF6C00 !important; }

.orange-800-border-left {
  border-left-color: #EF6C00 !important; }

.orange-900-bg {
  background-color: #E65100 !important; }

.md-orange-900-bg {
  background-color: #E65100 !important;
  color: white; }

.orange-900-fg {
  color: #E65100 !important; }

.orange-900-border {
  border-color: #E65100 !important; }

.orange-900-border-top {
  border-top-color: #E65100 !important; }

.orange-900-border-right {
  border-right-color: #E65100 !important; }

.orange-900-border-bottom {
  border-bottom-color: #E65100 !important; }

.orange-900-border-left {
  border-left-color: #E65100 !important; }

.orange-A100-bg {
  background-color: #FFD180 !important; }

.md-orange-A100-bg {
  background-color: #FFD180 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-A100-fg {
  color: #FFD180 !important; }

.orange-A100-border {
  border-color: #FFD180 !important; }

.orange-A100-border-top {
  border-top-color: #FFD180 !important; }

.orange-A100-border-right {
  border-right-color: #FFD180 !important; }

.orange-A100-border-bottom {
  border-bottom-color: #FFD180 !important; }

.orange-A100-border-left {
  border-left-color: #FFD180 !important; }

.orange-A200-bg {
  background-color: #FFAB40 !important; }

.md-orange-A200-bg {
  background-color: #FFAB40 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-A200-fg {
  color: #FFAB40 !important; }

.orange-A200-border {
  border-color: #FFAB40 !important; }

.orange-A200-border-top {
  border-top-color: #FFAB40 !important; }

.orange-A200-border-right {
  border-right-color: #FFAB40 !important; }

.orange-A200-border-bottom {
  border-bottom-color: #FFAB40 !important; }

.orange-A200-border-left {
  border-left-color: #FFAB40 !important; }

.orange-A400-bg {
  background-color: #FF9100 !important; }

.md-orange-A400-bg {
  background-color: #FF9100 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-A400-fg {
  color: #FF9100 !important; }

.orange-A400-border {
  border-color: #FF9100 !important; }

.orange-A400-border-top {
  border-top-color: #FF9100 !important; }

.orange-A400-border-right {
  border-right-color: #FF9100 !important; }

.orange-A400-border-bottom {
  border-bottom-color: #FF9100 !important; }

.orange-A400-border-left {
  border-left-color: #FF9100 !important; }

.orange-A700-bg {
  background-color: #FF6D00 !important; }

.md-orange-A700-bg {
  background-color: #FF6D00 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-A700-fg {
  color: #FF6D00 !important; }

.orange-A700-border {
  border-color: #FF6D00 !important; }

.orange-A700-border-top {
  border-top-color: #FF6D00 !important; }

.orange-A700-border-right {
  border-right-color: #FF6D00 !important; }

.orange-A700-border-bottom {
  border-bottom-color: #FF6D00 !important; }

.orange-A700-border-left {
  border-left-color: #FF6D00 !important; }

.deep-orange-50-bg {
  background-color: #FBE9E7 !important; }

.md-deep-orange-50-bg {
  background-color: #FBE9E7 !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-orange-50-fg {
  color: #FBE9E7 !important; }

.deep-orange-50-border {
  border-color: #FBE9E7 !important; }

.deep-orange-50-border-top {
  border-top-color: #FBE9E7 !important; }

.deep-orange-50-border-right {
  border-right-color: #FBE9E7 !important; }

.deep-orange-50-border-bottom {
  border-bottom-color: #FBE9E7 !important; }

.deep-orange-50-border-left {
  border-left-color: #FBE9E7 !important; }

.deep-orange-100-bg {
  background-color: #FFCCBC !important; }

.md-deep-orange-100-bg {
  background-color: #FFCCBC !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-orange-100-fg {
  color: #FFCCBC !important; }

.deep-orange-100-border {
  border-color: #FFCCBC !important; }

.deep-orange-100-border-top {
  border-top-color: #FFCCBC !important; }

.deep-orange-100-border-right {
  border-right-color: #FFCCBC !important; }

.deep-orange-100-border-bottom {
  border-bottom-color: #FFCCBC !important; }

.deep-orange-100-border-left {
  border-left-color: #FFCCBC !important; }

.deep-orange-200-bg {
  background-color: #FFAB91 !important; }

.md-deep-orange-200-bg {
  background-color: #FFAB91 !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-orange-200-fg {
  color: #FFAB91 !important; }

.deep-orange-200-border {
  border-color: #FFAB91 !important; }

.deep-orange-200-border-top {
  border-top-color: #FFAB91 !important; }

.deep-orange-200-border-right {
  border-right-color: #FFAB91 !important; }

.deep-orange-200-border-bottom {
  border-bottom-color: #FFAB91 !important; }

.deep-orange-200-border-left {
  border-left-color: #FFAB91 !important; }

.deep-orange-300-bg {
  background-color: #FF8A65 !important; }

.md-deep-orange-300-bg {
  background-color: #FF8A65 !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-orange-300-fg {
  color: #FF8A65 !important; }

.deep-orange-300-border {
  border-color: #FF8A65 !important; }

.deep-orange-300-border-top {
  border-top-color: #FF8A65 !important; }

.deep-orange-300-border-right {
  border-right-color: #FF8A65 !important; }

.deep-orange-300-border-bottom {
  border-bottom-color: #FF8A65 !important; }

.deep-orange-300-border-left {
  border-left-color: #FF8A65 !important; }

.deep-orange-400-bg {
  background-color: #FF7043 !important; }

.md-deep-orange-400-bg {
  background-color: #FF7043 !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-orange-400-fg {
  color: #FF7043 !important; }

.deep-orange-400-border {
  border-color: #FF7043 !important; }

.deep-orange-400-border-top {
  border-top-color: #FF7043 !important; }

.deep-orange-400-border-right {
  border-right-color: #FF7043 !important; }

.deep-orange-400-border-bottom {
  border-bottom-color: #FF7043 !important; }

.deep-orange-400-border-left {
  border-left-color: #FF7043 !important; }

.deep-orange-500-bg {
  background-color: #FF5722 !important; }

.md-deep-orange-500-bg {
  background-color: #FF5722 !important;
  color: white; }

.deep-orange-500-fg {
  color: #FF5722 !important; }

.deep-orange-500-border {
  border-color: #FF5722 !important; }

.deep-orange-500-border-top {
  border-top-color: #FF5722 !important; }

.deep-orange-500-border-right {
  border-right-color: #FF5722 !important; }

.deep-orange-500-border-bottom {
  border-bottom-color: #FF5722 !important; }

.deep-orange-500-border-left {
  border-left-color: #FF5722 !important; }

.md-deep-orange-bg {
  background-color: #FF5722 !important;
  color: white; }

.deep-orange-bg {
  background-color: #FF5722 !important; }

.deep-orange-fg {
  color: #FF5722 !important; }

.deep-orange-border {
  border-color: #FF5722 !important; }

.deep-orange-border-top {
  border-top-color: #FF5722 !important; }

.deep-orange-border-right {
  border-right-color: #FF5722 !important; }

.deep-orange-border-bottom {
  border-bottom-color: #FF5722 !important; }

.deep-orange-border-left {
  border-left-color: #FF5722 !important; }

.deep-orange-600-bg {
  background-color: #F4511E !important; }

.md-deep-orange-600-bg {
  background-color: #F4511E !important;
  color: white; }

.deep-orange-600-fg {
  color: #F4511E !important; }

.deep-orange-600-border {
  border-color: #F4511E !important; }

.deep-orange-600-border-top {
  border-top-color: #F4511E !important; }

.deep-orange-600-border-right {
  border-right-color: #F4511E !important; }

.deep-orange-600-border-bottom {
  border-bottom-color: #F4511E !important; }

.deep-orange-600-border-left {
  border-left-color: #F4511E !important; }

.deep-orange-700-bg {
  background-color: #E64A19 !important; }

.md-deep-orange-700-bg {
  background-color: #E64A19 !important;
  color: white; }

.deep-orange-700-fg {
  color: #E64A19 !important; }

.deep-orange-700-border {
  border-color: #E64A19 !important; }

.deep-orange-700-border-top {
  border-top-color: #E64A19 !important; }

.deep-orange-700-border-right {
  border-right-color: #E64A19 !important; }

.deep-orange-700-border-bottom {
  border-bottom-color: #E64A19 !important; }

.deep-orange-700-border-left {
  border-left-color: #E64A19 !important; }

.deep-orange-800-bg {
  background-color: #D84315 !important; }

.md-deep-orange-800-bg {
  background-color: #D84315 !important;
  color: white; }

.deep-orange-800-fg {
  color: #D84315 !important; }

.deep-orange-800-border {
  border-color: #D84315 !important; }

.deep-orange-800-border-top {
  border-top-color: #D84315 !important; }

.deep-orange-800-border-right {
  border-right-color: #D84315 !important; }

.deep-orange-800-border-bottom {
  border-bottom-color: #D84315 !important; }

.deep-orange-800-border-left {
  border-left-color: #D84315 !important; }

.deep-orange-900-bg {
  background-color: #BF360C !important; }

.md-deep-orange-900-bg {
  background-color: #BF360C !important;
  color: white; }

.deep-orange-900-fg {
  color: #BF360C !important; }

.deep-orange-900-border {
  border-color: #BF360C !important; }

.deep-orange-900-border-top {
  border-top-color: #BF360C !important; }

.deep-orange-900-border-right {
  border-right-color: #BF360C !important; }

.deep-orange-900-border-bottom {
  border-bottom-color: #BF360C !important; }

.deep-orange-900-border-left {
  border-left-color: #BF360C !important; }

.deep-orange-A100-bg {
  background-color: #FF9E80 !important; }

.md-deep-orange-A100-bg {
  background-color: #FF9E80 !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-orange-A100-fg {
  color: #FF9E80 !important; }

.deep-orange-A100-border {
  border-color: #FF9E80 !important; }

.deep-orange-A100-border-top {
  border-top-color: #FF9E80 !important; }

.deep-orange-A100-border-right {
  border-right-color: #FF9E80 !important; }

.deep-orange-A100-border-bottom {
  border-bottom-color: #FF9E80 !important; }

.deep-orange-A100-border-left {
  border-left-color: #FF9E80 !important; }

.deep-orange-A200-bg {
  background-color: #FF6E40 !important; }

.md-deep-orange-A200-bg {
  background-color: #FF6E40 !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-orange-A200-fg {
  color: #FF6E40 !important; }

.deep-orange-A200-border {
  border-color: #FF6E40 !important; }

.deep-orange-A200-border-top {
  border-top-color: #FF6E40 !important; }

.deep-orange-A200-border-right {
  border-right-color: #FF6E40 !important; }

.deep-orange-A200-border-bottom {
  border-bottom-color: #FF6E40 !important; }

.deep-orange-A200-border-left {
  border-left-color: #FF6E40 !important; }

.deep-orange-A400-bg {
  background-color: #FF3D00 !important; }

.md-deep-orange-A400-bg {
  background-color: #FF3D00 !important;
  color: white; }

.deep-orange-A400-fg {
  color: #FF3D00 !important; }

.deep-orange-A400-border {
  border-color: #FF3D00 !important; }

.deep-orange-A400-border-top {
  border-top-color: #FF3D00 !important; }

.deep-orange-A400-border-right {
  border-right-color: #FF3D00 !important; }

.deep-orange-A400-border-bottom {
  border-bottom-color: #FF3D00 !important; }

.deep-orange-A400-border-left {
  border-left-color: #FF3D00 !important; }

.deep-orange-A700-bg {
  background-color: #DD2C00 !important; }

.md-deep-orange-A700-bg {
  background-color: #DD2C00 !important;
  color: white; }

.deep-orange-A700-fg {
  color: #DD2C00 !important; }

.deep-orange-A700-border {
  border-color: #DD2C00 !important; }

.deep-orange-A700-border-top {
  border-top-color: #DD2C00 !important; }

.deep-orange-A700-border-right {
  border-right-color: #DD2C00 !important; }

.deep-orange-A700-border-bottom {
  border-bottom-color: #DD2C00 !important; }

.deep-orange-A700-border-left {
  border-left-color: #DD2C00 !important; }

.brown-50-bg {
  background-color: #EFEBE9 !important; }

.md-brown-50-bg {
  background-color: #EFEBE9 !important;
  color: rgba(0, 0, 0, 0.87); }

.brown-50-fg {
  color: #EFEBE9 !important; }

.brown-50-border {
  border-color: #EFEBE9 !important; }

.brown-50-border-top {
  border-top-color: #EFEBE9 !important; }

.brown-50-border-right {
  border-right-color: #EFEBE9 !important; }

.brown-50-border-bottom {
  border-bottom-color: #EFEBE9 !important; }

.brown-50-border-left {
  border-left-color: #EFEBE9 !important; }

.brown-100-bg {
  background-color: #D7CCC8 !important; }

.md-brown-100-bg {
  background-color: #D7CCC8 !important;
  color: rgba(0, 0, 0, 0.87); }

.brown-100-fg {
  color: #D7CCC8 !important; }

.brown-100-border {
  border-color: #D7CCC8 !important; }

.brown-100-border-top {
  border-top-color: #D7CCC8 !important; }

.brown-100-border-right {
  border-right-color: #D7CCC8 !important; }

.brown-100-border-bottom {
  border-bottom-color: #D7CCC8 !important; }

.brown-100-border-left {
  border-left-color: #D7CCC8 !important; }

.brown-200-bg {
  background-color: #BCAAA4 !important; }

.md-brown-200-bg {
  background-color: #BCAAA4 !important;
  color: rgba(0, 0, 0, 0.87); }

.brown-200-fg {
  color: #BCAAA4 !important; }

.brown-200-border {
  border-color: #BCAAA4 !important; }

.brown-200-border-top {
  border-top-color: #BCAAA4 !important; }

.brown-200-border-right {
  border-right-color: #BCAAA4 !important; }

.brown-200-border-bottom {
  border-bottom-color: #BCAAA4 !important; }

.brown-200-border-left {
  border-left-color: #BCAAA4 !important; }

.brown-300-bg {
  background-color: #A1887F !important; }

.md-brown-300-bg {
  background-color: #A1887F !important;
  color: white; }

.brown-300-fg {
  color: #A1887F !important; }

.brown-300-border {
  border-color: #A1887F !important; }

.brown-300-border-top {
  border-top-color: #A1887F !important; }

.brown-300-border-right {
  border-right-color: #A1887F !important; }

.brown-300-border-bottom {
  border-bottom-color: #A1887F !important; }

.brown-300-border-left {
  border-left-color: #A1887F !important; }

.brown-400-bg {
  background-color: #8D6E63 !important; }

.md-brown-400-bg {
  background-color: #8D6E63 !important;
  color: white; }

.brown-400-fg {
  color: #8D6E63 !important; }

.brown-400-border {
  border-color: #8D6E63 !important; }

.brown-400-border-top {
  border-top-color: #8D6E63 !important; }

.brown-400-border-right {
  border-right-color: #8D6E63 !important; }

.brown-400-border-bottom {
  border-bottom-color: #8D6E63 !important; }

.brown-400-border-left {
  border-left-color: #8D6E63 !important; }

.brown-500-bg {
  background-color: #795548 !important; }

.md-brown-500-bg {
  background-color: #795548 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-500-fg {
  color: #795548 !important; }

.brown-500-border {
  border-color: #795548 !important; }

.brown-500-border-top {
  border-top-color: #795548 !important; }

.brown-500-border-right {
  border-right-color: #795548 !important; }

.brown-500-border-bottom {
  border-bottom-color: #795548 !important; }

.brown-500-border-left {
  border-left-color: #795548 !important; }

.md-brown-bg {
  background-color: #795548 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-bg {
  background-color: #795548 !important; }

.brown-fg {
  color: #795548 !important; }

.brown-border {
  border-color: #795548 !important; }

.brown-border-top {
  border-top-color: #795548 !important; }

.brown-border-right {
  border-right-color: #795548 !important; }

.brown-border-bottom {
  border-bottom-color: #795548 !important; }

.brown-border-left {
  border-left-color: #795548 !important; }

.brown-600-bg {
  background-color: #6D4C41 !important; }

.md-brown-600-bg {
  background-color: #6D4C41 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-600-fg {
  color: #6D4C41 !important; }

.brown-600-border {
  border-color: #6D4C41 !important; }

.brown-600-border-top {
  border-top-color: #6D4C41 !important; }

.brown-600-border-right {
  border-right-color: #6D4C41 !important; }

.brown-600-border-bottom {
  border-bottom-color: #6D4C41 !important; }

.brown-600-border-left {
  border-left-color: #6D4C41 !important; }

.brown-700-bg {
  background-color: #5D4037 !important; }

.md-brown-700-bg {
  background-color: #5D4037 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-700-fg {
  color: #5D4037 !important; }

.brown-700-border {
  border-color: #5D4037 !important; }

.brown-700-border-top {
  border-top-color: #5D4037 !important; }

.brown-700-border-right {
  border-right-color: #5D4037 !important; }

.brown-700-border-bottom {
  border-bottom-color: #5D4037 !important; }

.brown-700-border-left {
  border-left-color: #5D4037 !important; }

.brown-800-bg {
  background-color: #4E342E !important; }

.md-brown-800-bg {
  background-color: #4E342E !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-800-fg {
  color: #4E342E !important; }

.brown-800-border {
  border-color: #4E342E !important; }

.brown-800-border-top {
  border-top-color: #4E342E !important; }

.brown-800-border-right {
  border-right-color: #4E342E !important; }

.brown-800-border-bottom {
  border-bottom-color: #4E342E !important; }

.brown-800-border-left {
  border-left-color: #4E342E !important; }

.brown-900-bg {
  background-color: #3E2723 !important; }

.md-brown-900-bg {
  background-color: #3E2723 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-900-fg {
  color: #3E2723 !important; }

.brown-900-border {
  border-color: #3E2723 !important; }

.brown-900-border-top {
  border-top-color: #3E2723 !important; }

.brown-900-border-right {
  border-right-color: #3E2723 !important; }

.brown-900-border-bottom {
  border-bottom-color: #3E2723 !important; }

.brown-900-border-left {
  border-left-color: #3E2723 !important; }

.brown-A100-bg {
  background-color: #D7CCC8 !important; }

.md-brown-A100-bg {
  background-color: #D7CCC8 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-A100-fg {
  color: #D7CCC8 !important; }

.brown-A100-border {
  border-color: #D7CCC8 !important; }

.brown-A100-border-top {
  border-top-color: #D7CCC8 !important; }

.brown-A100-border-right {
  border-right-color: #D7CCC8 !important; }

.brown-A100-border-bottom {
  border-bottom-color: #D7CCC8 !important; }

.brown-A100-border-left {
  border-left-color: #D7CCC8 !important; }

.brown-A200-bg {
  background-color: #BCAAA4 !important; }

.md-brown-A200-bg {
  background-color: #BCAAA4 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-A200-fg {
  color: #BCAAA4 !important; }

.brown-A200-border {
  border-color: #BCAAA4 !important; }

.brown-A200-border-top {
  border-top-color: #BCAAA4 !important; }

.brown-A200-border-right {
  border-right-color: #BCAAA4 !important; }

.brown-A200-border-bottom {
  border-bottom-color: #BCAAA4 !important; }

.brown-A200-border-left {
  border-left-color: #BCAAA4 !important; }

.brown-A400-bg {
  background-color: #8D6E63 !important; }

.md-brown-A400-bg {
  background-color: #8D6E63 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-A400-fg {
  color: #8D6E63 !important; }

.brown-A400-border {
  border-color: #8D6E63 !important; }

.brown-A400-border-top {
  border-top-color: #8D6E63 !important; }

.brown-A400-border-right {
  border-right-color: #8D6E63 !important; }

.brown-A400-border-bottom {
  border-bottom-color: #8D6E63 !important; }

.brown-A400-border-left {
  border-left-color: #8D6E63 !important; }

.brown-A700-bg {
  background-color: #5D4037 !important; }

.md-brown-A700-bg {
  background-color: #5D4037 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-A700-fg {
  color: #5D4037 !important; }

.brown-A700-border {
  border-color: #5D4037 !important; }

.brown-A700-border-top {
  border-top-color: #5D4037 !important; }

.brown-A700-border-right {
  border-right-color: #5D4037 !important; }

.brown-A700-border-bottom {
  border-bottom-color: #5D4037 !important; }

.brown-A700-border-left {
  border-left-color: #5D4037 !important; }

.grey-50-bg {
  background-color: #FAFAFA !important; }

.md-grey-50-bg {
  background-color: #FAFAFA !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-50-fg {
  color: #FAFAFA !important; }

.grey-50-border {
  border-color: #FAFAFA !important; }

.grey-50-border-top {
  border-top-color: #FAFAFA !important; }

.grey-50-border-right {
  border-right-color: #FAFAFA !important; }

.grey-50-border-bottom {
  border-bottom-color: #FAFAFA !important; }

.grey-50-border-left {
  border-left-color: #FAFAFA !important; }

.grey-100-bg {
  background-color: #F5F5F5 !important; }

.md-grey-100-bg {
  background-color: #F5F5F5 !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-100-fg {
  color: #F5F5F5 !important; }

.grey-100-border {
  border-color: #F5F5F5 !important; }

.grey-100-border-top {
  border-top-color: #F5F5F5 !important; }

.grey-100-border-right {
  border-right-color: #F5F5F5 !important; }

.grey-100-border-bottom {
  border-bottom-color: #F5F5F5 !important; }

.grey-100-border-left {
  border-left-color: #F5F5F5 !important; }

.grey-200-bg {
  background-color: #EEEEEE !important; }

.md-grey-200-bg {
  background-color: #EEEEEE !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-200-fg {
  color: #EEEEEE !important; }

.grey-200-border {
  border-color: #EEEEEE !important; }

.grey-200-border-top {
  border-top-color: #EEEEEE !important; }

.grey-200-border-right {
  border-right-color: #EEEEEE !important; }

.grey-200-border-bottom {
  border-bottom-color: #EEEEEE !important; }

.grey-200-border-left {
  border-left-color: #EEEEEE !important; }

.grey-300-bg {
  background-color: #E0E0E0 !important; }

.md-grey-300-bg {
  background-color: #E0E0E0 !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-300-fg {
  color: #E0E0E0 !important; }

.grey-300-border {
  border-color: #E0E0E0 !important; }

.grey-300-border-top {
  border-top-color: #E0E0E0 !important; }

.grey-300-border-right {
  border-right-color: #E0E0E0 !important; }

.grey-300-border-bottom {
  border-bottom-color: #E0E0E0 !important; }

.grey-300-border-left {
  border-left-color: #E0E0E0 !important; }

.grey-400-bg {
  background-color: #BDBDBD !important; }

.md-grey-400-bg {
  background-color: #BDBDBD !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-400-fg {
  color: #BDBDBD !important; }

.grey-400-border {
  border-color: #BDBDBD !important; }

.grey-400-border-top {
  border-top-color: #BDBDBD !important; }

.grey-400-border-right {
  border-right-color: #BDBDBD !important; }

.grey-400-border-bottom {
  border-bottom-color: #BDBDBD !important; }

.grey-400-border-left {
  border-left-color: #BDBDBD !important; }

.grey-500-bg {
  background-color: #9E9E9E !important; }

.md-grey-500-bg {
  background-color: #9E9E9E !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-500-fg {
  color: #9E9E9E !important; }

.grey-500-border {
  border-color: #9E9E9E !important; }

.grey-500-border-top {
  border-top-color: #9E9E9E !important; }

.grey-500-border-right {
  border-right-color: #9E9E9E !important; }

.grey-500-border-bottom {
  border-bottom-color: #9E9E9E !important; }

.grey-500-border-left {
  border-left-color: #9E9E9E !important; }

.md-grey-bg {
  background-color: #9E9E9E !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-bg {
  background-color: #9E9E9E !important; }

.grey-fg {
  color: #9E9E9E !important; }

.grey-border {
  border-color: #9E9E9E !important; }

.grey-border-top {
  border-top-color: #9E9E9E !important; }

.grey-border-right {
  border-right-color: #9E9E9E !important; }

.grey-border-bottom {
  border-bottom-color: #9E9E9E !important; }

.grey-border-left {
  border-left-color: #9E9E9E !important; }

.grey-600-bg {
  background-color: #757575 !important; }

.md-grey-600-bg {
  background-color: #757575 !important;
  color: rgba(255, 255, 255, 0.87); }

.grey-600-fg {
  color: #757575 !important; }

.grey-600-border {
  border-color: #757575 !important; }

.grey-600-border-top {
  border-top-color: #757575 !important; }

.grey-600-border-right {
  border-right-color: #757575 !important; }

.grey-600-border-bottom {
  border-bottom-color: #757575 !important; }

.grey-600-border-left {
  border-left-color: #757575 !important; }

.grey-700-bg {
  background-color: #616161 !important; }

.md-grey-700-bg {
  background-color: #616161 !important;
  color: rgba(255, 255, 255, 0.87); }

.grey-700-fg {
  color: #616161 !important; }

.grey-700-border {
  border-color: #616161 !important; }

.grey-700-border-top {
  border-top-color: #616161 !important; }

.grey-700-border-right {
  border-right-color: #616161 !important; }

.grey-700-border-bottom {
  border-bottom-color: #616161 !important; }

.grey-700-border-left {
  border-left-color: #616161 !important; }

.grey-800-bg {
  background-color: #424242 !important; }

.md-grey-800-bg {
  background-color: #424242 !important;
  color: rgba(255, 255, 255, 0.87); }

.grey-800-fg {
  color: #424242 !important; }

.grey-800-border {
  border-color: #424242 !important; }

.grey-800-border-top {
  border-top-color: #424242 !important; }

.grey-800-border-right {
  border-right-color: #424242 !important; }

.grey-800-border-bottom {
  border-bottom-color: #424242 !important; }

.grey-800-border-left {
  border-left-color: #424242 !important; }

.grey-900-bg {
  background-color: #212121 !important; }

.md-grey-900-bg {
  background-color: #212121 !important;
  color: rgba(255, 255, 255, 0.87); }

.grey-900-fg {
  color: #212121 !important; }

.grey-900-border {
  border-color: #212121 !important; }

.grey-900-border-top {
  border-top-color: #212121 !important; }

.grey-900-border-right {
  border-right-color: #212121 !important; }

.grey-900-border-bottom {
  border-bottom-color: #212121 !important; }

.grey-900-border-left {
  border-left-color: #212121 !important; }

.grey-1000-bg {
  background-color: #000000 !important; }

.md-grey-1000-bg {
  background-color: #000000 !important; }

.grey-1000-fg {
  color: #000000 !important; }

.grey-1000-border {
  border-color: #000000 !important; }

.grey-1000-border-top {
  border-top-color: #000000 !important; }

.grey-1000-border-right {
  border-right-color: #000000 !important; }

.grey-1000-border-bottom {
  border-bottom-color: #000000 !important; }

.grey-1000-border-left {
  border-left-color: #000000 !important; }

.grey-A100-bg {
  background-color: #FFFFFF !important; }

.md-grey-A100-bg {
  background-color: #FFFFFF !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-A100-fg {
  color: #FFFFFF !important; }

.grey-A100-border {
  border-color: #FFFFFF !important; }

.grey-A100-border-top {
  border-top-color: #FFFFFF !important; }

.grey-A100-border-right {
  border-right-color: #FFFFFF !important; }

.grey-A100-border-bottom {
  border-bottom-color: #FFFFFF !important; }

.grey-A100-border-left {
  border-left-color: #FFFFFF !important; }

.grey-A200-bg {
  background-color: #EEEEEE !important; }

.md-grey-A200-bg {
  background-color: #EEEEEE !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-A200-fg {
  color: #EEEEEE !important; }

.grey-A200-border {
  border-color: #EEEEEE !important; }

.grey-A200-border-top {
  border-top-color: #EEEEEE !important; }

.grey-A200-border-right {
  border-right-color: #EEEEEE !important; }

.grey-A200-border-bottom {
  border-bottom-color: #EEEEEE !important; }

.grey-A200-border-left {
  border-left-color: #EEEEEE !important; }

.grey-A400-bg {
  background-color: #BDBDBD !important; }

.md-grey-A400-bg {
  background-color: #BDBDBD !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-A400-fg {
  color: #BDBDBD !important; }

.grey-A400-border {
  border-color: #BDBDBD !important; }

.grey-A400-border-top {
  border-top-color: #BDBDBD !important; }

.grey-A400-border-right {
  border-right-color: #BDBDBD !important; }

.grey-A400-border-bottom {
  border-bottom-color: #BDBDBD !important; }

.grey-A400-border-left {
  border-left-color: #BDBDBD !important; }

.grey-A700-bg {
  background-color: #616161 !important; }

.md-grey-A700-bg {
  background-color: #616161 !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-A700-fg {
  color: #616161 !important; }

.grey-A700-border {
  border-color: #616161 !important; }

.grey-A700-border-top {
  border-top-color: #616161 !important; }

.grey-A700-border-right {
  border-right-color: #616161 !important; }

.grey-A700-border-bottom {
  border-bottom-color: #616161 !important; }

.grey-A700-border-left {
  border-left-color: #616161 !important; }

.blue-grey-50-bg {
  background-color: #ECEFF1 !important; }

.md-blue-grey-50-bg {
  background-color: #ECEFF1 !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-grey-50-fg {
  color: #ECEFF1 !important; }

.blue-grey-50-border {
  border-color: #ECEFF1 !important; }

.blue-grey-50-border-top {
  border-top-color: #ECEFF1 !important; }

.blue-grey-50-border-right {
  border-right-color: #ECEFF1 !important; }

.blue-grey-50-border-bottom {
  border-bottom-color: #ECEFF1 !important; }

.blue-grey-50-border-left {
  border-left-color: #ECEFF1 !important; }

.blue-grey-100-bg {
  background-color: #CFD8DC !important; }

.md-blue-grey-100-bg {
  background-color: #CFD8DC !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-grey-100-fg {
  color: #CFD8DC !important; }

.blue-grey-100-border {
  border-color: #CFD8DC !important; }

.blue-grey-100-border-top {
  border-top-color: #CFD8DC !important; }

.blue-grey-100-border-right {
  border-right-color: #CFD8DC !important; }

.blue-grey-100-border-bottom {
  border-bottom-color: #CFD8DC !important; }

.blue-grey-100-border-left {
  border-left-color: #CFD8DC !important; }

.blue-grey-200-bg {
  background-color: #B0BEC5 !important; }

.md-blue-grey-200-bg {
  background-color: #B0BEC5 !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-grey-200-fg {
  color: #B0BEC5 !important; }

.blue-grey-200-border {
  border-color: #B0BEC5 !important; }

.blue-grey-200-border-top {
  border-top-color: #B0BEC5 !important; }

.blue-grey-200-border-right {
  border-right-color: #B0BEC5 !important; }

.blue-grey-200-border-bottom {
  border-bottom-color: #B0BEC5 !important; }

.blue-grey-200-border-left {
  border-left-color: #B0BEC5 !important; }

.blue-grey-300-bg {
  background-color: #90A4AE !important; }

.md-blue-grey-300-bg {
  background-color: #90A4AE !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-grey-300-fg {
  color: #90A4AE !important; }

.blue-grey-300-border {
  border-color: #90A4AE !important; }

.blue-grey-300-border-top {
  border-top-color: #90A4AE !important; }

.blue-grey-300-border-right {
  border-right-color: #90A4AE !important; }

.blue-grey-300-border-bottom {
  border-bottom-color: #90A4AE !important; }

.blue-grey-300-border-left {
  border-left-color: #90A4AE !important; }

.blue-grey-400-bg {
  background-color: #78909C !important; }

.md-blue-grey-400-bg {
  background-color: #78909C !important;
  color: white; }

.blue-grey-400-fg {
  color: #78909C !important; }

.blue-grey-400-border {
  border-color: #78909C !important; }

.blue-grey-400-border-top {
  border-top-color: #78909C !important; }

.blue-grey-400-border-right {
  border-right-color: #78909C !important; }

.blue-grey-400-border-bottom {
  border-bottom-color: #78909C !important; }

.blue-grey-400-border-left {
  border-left-color: #78909C !important; }

.blue-grey-500-bg {
  background-color: #607D8B !important; }

.md-blue-grey-500-bg {
  background-color: #607D8B !important;
  color: white; }

.blue-grey-500-fg {
  color: #607D8B !important; }

.blue-grey-500-border {
  border-color: #607D8B !important; }

.blue-grey-500-border-top {
  border-top-color: #607D8B !important; }

.blue-grey-500-border-right {
  border-right-color: #607D8B !important; }

.blue-grey-500-border-bottom {
  border-bottom-color: #607D8B !important; }

.blue-grey-500-border-left {
  border-left-color: #607D8B !important; }

.md-blue-grey-bg {
  background-color: #607D8B !important;
  color: white; }

.blue-grey-bg {
  background-color: #607D8B !important; }

.blue-grey-fg {
  color: #607D8B !important; }

.blue-grey-border {
  border-color: #607D8B !important; }

.blue-grey-border-top {
  border-top-color: #607D8B !important; }

.blue-grey-border-right {
  border-right-color: #607D8B !important; }

.blue-grey-border-bottom {
  border-bottom-color: #607D8B !important; }

.blue-grey-border-left {
  border-left-color: #607D8B !important; }

.blue-grey-600-bg {
  background-color: #546E7A !important; }

.md-blue-grey-600-bg {
  background-color: #546E7A !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-600-fg {
  color: #546E7A !important; }

.blue-grey-600-border {
  border-color: #546E7A !important; }

.blue-grey-600-border-top {
  border-top-color: #546E7A !important; }

.blue-grey-600-border-right {
  border-right-color: #546E7A !important; }

.blue-grey-600-border-bottom {
  border-bottom-color: #546E7A !important; }

.blue-grey-600-border-left {
  border-left-color: #546E7A !important; }

.blue-grey-700-bg {
  background-color: #455A64 !important; }

.md-blue-grey-700-bg {
  background-color: #455A64 !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-700-fg {
  color: #455A64 !important; }

.blue-grey-700-border {
  border-color: #455A64 !important; }

.blue-grey-700-border-top {
  border-top-color: #455A64 !important; }

.blue-grey-700-border-right {
  border-right-color: #455A64 !important; }

.blue-grey-700-border-bottom {
  border-bottom-color: #455A64 !important; }

.blue-grey-700-border-left {
  border-left-color: #455A64 !important; }

.blue-grey-800-bg {
  background-color: #37474F !important; }

.md-blue-grey-800-bg {
  background-color: #37474F !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-800-fg {
  color: #37474F !important; }

.blue-grey-800-border {
  border-color: #37474F !important; }

.blue-grey-800-border-top {
  border-top-color: #37474F !important; }

.blue-grey-800-border-right {
  border-right-color: #37474F !important; }

.blue-grey-800-border-bottom {
  border-bottom-color: #37474F !important; }

.blue-grey-800-border-left {
  border-left-color: #37474F !important; }

.blue-grey-900-bg {
  background-color: #263238 !important; }

.md-blue-grey-900-bg {
  background-color: #263238 !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-900-fg {
  color: #263238 !important; }

.blue-grey-900-border {
  border-color: #263238 !important; }

.blue-grey-900-border-top {
  border-top-color: #263238 !important; }

.blue-grey-900-border-right {
  border-right-color: #263238 !important; }

.blue-grey-900-border-bottom {
  border-bottom-color: #263238 !important; }

.blue-grey-900-border-left {
  border-left-color: #263238 !important; }

.blue-grey-A100-bg {
  background-color: #CFD8DC !important; }

.md-blue-grey-A100-bg {
  background-color: #CFD8DC !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-A100-fg {
  color: #CFD8DC !important; }

.blue-grey-A100-border {
  border-color: #CFD8DC !important; }

.blue-grey-A100-border-top {
  border-top-color: #CFD8DC !important; }

.blue-grey-A100-border-right {
  border-right-color: #CFD8DC !important; }

.blue-grey-A100-border-bottom {
  border-bottom-color: #CFD8DC !important; }

.blue-grey-A100-border-left {
  border-left-color: #CFD8DC !important; }

.blue-grey-A200-bg {
  background-color: #B0BEC5 !important; }

.md-blue-grey-A200-bg {
  background-color: #B0BEC5 !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-A200-fg {
  color: #B0BEC5 !important; }

.blue-grey-A200-border {
  border-color: #B0BEC5 !important; }

.blue-grey-A200-border-top {
  border-top-color: #B0BEC5 !important; }

.blue-grey-A200-border-right {
  border-right-color: #B0BEC5 !important; }

.blue-grey-A200-border-bottom {
  border-bottom-color: #B0BEC5 !important; }

.blue-grey-A200-border-left {
  border-left-color: #B0BEC5 !important; }

.blue-grey-A400-bg {
  background-color: #78909C !important; }

.md-blue-grey-A400-bg {
  background-color: #78909C !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-A400-fg {
  color: #78909C !important; }

.blue-grey-A400-border {
  border-color: #78909C !important; }

.blue-grey-A400-border-top {
  border-top-color: #78909C !important; }

.blue-grey-A400-border-right {
  border-right-color: #78909C !important; }

.blue-grey-A400-border-bottom {
  border-bottom-color: #78909C !important; }

.blue-grey-A400-border-left {
  border-left-color: #78909C !important; }

.blue-grey-A700-bg {
  background-color: #455A64 !important; }

.md-blue-grey-A700-bg {
  background-color: #455A64 !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-A700-fg {
  color: #455A64 !important; }

.blue-grey-A700-border {
  border-color: #455A64 !important; }

.blue-grey-A700-border-top {
  border-top-color: #455A64 !important; }

.blue-grey-A700-border-right {
  border-right-color: #455A64 !important; }

.blue-grey-A700-border-bottom {
  border-bottom-color: #455A64 !important; }

.blue-grey-A700-border-left {
  border-left-color: #455A64 !important; }

.white-500-bg {
  background-color: #FFFFFF !important; }

.md-white-500-bg {
  background-color: #FFFFFF !important;
  color: rgba(0, 0, 0, 0.87); }

.white-500-fg {
  color: #FFFFFF !important; }

.white-500-border {
  border-color: #FFFFFF !important; }

.white-500-border-top {
  border-top-color: #FFFFFF !important; }

.white-500-border-right {
  border-right-color: #FFFFFF !important; }

.white-500-border-bottom {
  border-bottom-color: #FFFFFF !important; }

.white-500-border-left {
  border-left-color: #FFFFFF !important; }

.md-white-bg {
  background-color: #FFFFFF !important;
  color: rgba(0, 0, 0, 0.87); }

.white-bg {
  background-color: #FFFFFF !important; }

.white-fg {
  color: #FFFFFF !important; }

.white-border {
  border-color: #FFFFFF !important; }

.white-border-top {
  border-top-color: #FFFFFF !important; }

.white-border-right {
  border-right-color: #FFFFFF !important; }

.white-border-bottom {
  border-bottom-color: #FFFFFF !important; }

.white-border-left {
  border-left-color: #FFFFFF !important; }

.black-500-bg {
  background-color: #000000 !important; }

.md-black-500-bg {
  background-color: #000000 !important;
  color: rgba(255, 255, 255, 0.87); }

.black-500-fg {
  color: #000000 !important; }

.black-500-border {
  border-color: #000000 !important; }

.black-500-border-top {
  border-top-color: #000000 !important; }

.black-500-border-right {
  border-right-color: #000000 !important; }

.black-500-border-bottom {
  border-bottom-color: #000000 !important; }

.black-500-border-left {
  border-left-color: #000000 !important; }

.md-black-bg {
  background-color: #000000 !important;
  color: rgba(255, 255, 255, 0.87); }

.black-bg {
  background-color: #000000 !important; }

.black-fg {
  color: #000000 !important; }

.black-border {
  border-color: #000000 !important; }

.black-border-top {
  border-top-color: #000000 !important; }

.black-border-right {
  border-right-color: #000000 !important; }

.black-border-bottom {
  border-bottom-color: #000000 !important; }

.black-border-left {
  border-left-color: #000000 !important; }

/*----------------------------------------------------------------*/
/*  Icons
/*----------------------------------------------------------------*/
md-icon[md-font-icon],
i {
  font-size: 24px;
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  line-height: 24px; }
  md-icon[md-font-icon].s4,
  i.s4 {
    font-size: 4px !important;
    width: 4px !important;
    height: 4px !important;
    min-width: 4px !important;
    min-height: 4px !important;
    line-height: 4px !important; }
  md-icon[md-font-icon].s6,
  i.s6 {
    font-size: 6px !important;
    width: 6px !important;
    height: 6px !important;
    min-width: 6px !important;
    min-height: 6px !important;
    line-height: 6px !important; }
  md-icon[md-font-icon].s8,
  i.s8 {
    font-size: 8px !important;
    width: 8px !important;
    height: 8px !important;
    min-width: 8px !important;
    min-height: 8px !important;
    line-height: 8px !important; }
  md-icon[md-font-icon].s10,
  i.s10 {
    font-size: 10px !important;
    width: 10px !important;
    height: 10px !important;
    min-width: 10px !important;
    min-height: 10px !important;
    line-height: 10px !important; }
  md-icon[md-font-icon].s12,
  i.s12 {
    font-size: 12px !important;
    width: 12px !important;
    height: 12px !important;
    min-width: 12px !important;
    min-height: 12px !important;
    line-height: 12px !important; }
  md-icon[md-font-icon].s14,
  i.s14 {
    font-size: 14px !important;
    width: 14px !important;
    height: 14px !important;
    min-width: 14px !important;
    min-height: 14px !important;
    line-height: 14px !important; }
  md-icon[md-font-icon].s16,
  i.s16 {
    font-size: 16px !important;
    width: 16px !important;
    height: 16px !important;
    min-width: 16px !important;
    min-height: 16px !important;
    line-height: 16px !important; }
  md-icon[md-font-icon].s18,
  i.s18 {
    font-size: 18px !important;
    width: 18px !important;
    height: 18px !important;
    min-width: 18px !important;
    min-height: 18px !important;
    line-height: 18px !important; }
  md-icon[md-font-icon].s20,
  i.s20 {
    font-size: 20px !important;
    width: 20px !important;
    height: 20px !important;
    min-width: 20px !important;
    min-height: 20px !important;
    line-height: 20px !important; }
  md-icon[md-font-icon].s22,
  i.s22 {
    font-size: 22px !important;
    width: 22px !important;
    height: 22px !important;
    min-width: 22px !important;
    min-height: 22px !important;
    line-height: 22px !important; }
  md-icon[md-font-icon].s24,
  i.s24 {
    font-size: 24px !important;
    width: 24px !important;
    height: 24px !important;
    min-width: 24px !important;
    min-height: 24px !important;
    line-height: 24px !important; }
  md-icon[md-font-icon].s26,
  i.s26 {
    font-size: 26px !important;
    width: 26px !important;
    height: 26px !important;
    min-width: 26px !important;
    min-height: 26px !important;
    line-height: 26px !important; }
  md-icon[md-font-icon].s28,
  i.s28 {
    font-size: 28px !important;
    width: 28px !important;
    height: 28px !important;
    min-width: 28px !important;
    min-height: 28px !important;
    line-height: 28px !important; }
  md-icon[md-font-icon].s30,
  i.s30 {
    font-size: 30px !important;
    width: 30px !important;
    height: 30px !important;
    min-width: 30px !important;
    min-height: 30px !important;
    line-height: 30px !important; }
  md-icon[md-font-icon].s32,
  i.s32 {
    font-size: 32px !important;
    width: 32px !important;
    height: 32px !important;
    min-width: 32px !important;
    min-height: 32px !important;
    line-height: 32px !important; }
  md-icon[md-font-icon].s34,
  i.s34 {
    font-size: 34px !important;
    width: 34px !important;
    height: 34px !important;
    min-width: 34px !important;
    min-height: 34px !important;
    line-height: 34px !important; }
  md-icon[md-font-icon].s36,
  i.s36 {
    font-size: 36px !important;
    width: 36px !important;
    height: 36px !important;
    min-width: 36px !important;
    min-height: 36px !important;
    line-height: 36px !important; }
  md-icon[md-font-icon].s38,
  i.s38 {
    font-size: 38px !important;
    width: 38px !important;
    height: 38px !important;
    min-width: 38px !important;
    min-height: 38px !important;
    line-height: 38px !important; }
  md-icon[md-font-icon].s40,
  i.s40 {
    font-size: 40px !important;
    width: 40px !important;
    height: 40px !important;
    min-width: 40px !important;
    min-height: 40px !important;
    line-height: 40px !important; }
  md-icon[md-font-icon].s42,
  i.s42 {
    font-size: 42px !important;
    width: 42px !important;
    height: 42px !important;
    min-width: 42px !important;
    min-height: 42px !important;
    line-height: 42px !important; }
  md-icon[md-font-icon].s44,
  i.s44 {
    font-size: 44px !important;
    width: 44px !important;
    height: 44px !important;
    min-width: 44px !important;
    min-height: 44px !important;
    line-height: 44px !important; }
  md-icon[md-font-icon].s46,
  i.s46 {
    font-size: 46px !important;
    width: 46px !important;
    height: 46px !important;
    min-width: 46px !important;
    min-height: 46px !important;
    line-height: 46px !important; }
  md-icon[md-font-icon].s48,
  i.s48 {
    font-size: 48px !important;
    width: 48px !important;
    height: 48px !important;
    min-width: 48px !important;
    min-height: 48px !important;
    line-height: 48px !important; }
  md-icon[md-font-icon].s50,
  i.s50 {
    font-size: 50px !important;
    width: 50px !important;
    height: 50px !important;
    min-width: 50px !important;
    min-height: 50px !important;
    line-height: 50px !important; }
  md-icon[md-font-icon].s52,
  i.s52 {
    font-size: 52px !important;
    width: 52px !important;
    height: 52px !important;
    min-width: 52px !important;
    min-height: 52px !important;
    line-height: 52px !important; }
  md-icon[md-font-icon].s54,
  i.s54 {
    font-size: 54px !important;
    width: 54px !important;
    height: 54px !important;
    min-width: 54px !important;
    min-height: 54px !important;
    line-height: 54px !important; }
  md-icon[md-font-icon].s56,
  i.s56 {
    font-size: 56px !important;
    width: 56px !important;
    height: 56px !important;
    min-width: 56px !important;
    min-height: 56px !important;
    line-height: 56px !important; }
  md-icon[md-font-icon].s58,
  i.s58 {
    font-size: 58px !important;
    width: 58px !important;
    height: 58px !important;
    min-width: 58px !important;
    min-height: 58px !important;
    line-height: 58px !important; }
  md-icon[md-font-icon].s60,
  i.s60 {
    font-size: 60px !important;
    width: 60px !important;
    height: 60px !important;
    min-width: 60px !important;
    min-height: 60px !important;
    line-height: 60px !important; }
  md-icon[md-font-icon].s62,
  i.s62 {
    font-size: 62px !important;
    width: 62px !important;
    height: 62px !important;
    min-width: 62px !important;
    min-height: 62px !important;
    line-height: 62px !important; }
  md-icon[md-font-icon].s64,
  i.s64 {
    font-size: 64px !important;
    width: 64px !important;
    height: 64px !important;
    min-width: 64px !important;
    min-height: 64px !important;
    line-height: 64px !important; }
  md-icon[md-font-icon].s66,
  i.s66 {
    font-size: 66px !important;
    width: 66px !important;
    height: 66px !important;
    min-width: 66px !important;
    min-height: 66px !important;
    line-height: 66px !important; }
  md-icon[md-font-icon].s68,
  i.s68 {
    font-size: 68px !important;
    width: 68px !important;
    height: 68px !important;
    min-width: 68px !important;
    min-height: 68px !important;
    line-height: 68px !important; }
  md-icon[md-font-icon].s70,
  i.s70 {
    font-size: 70px !important;
    width: 70px !important;
    height: 70px !important;
    min-width: 70px !important;
    min-height: 70px !important;
    line-height: 70px !important; }
  md-icon[md-font-icon].s72,
  i.s72 {
    font-size: 72px !important;
    width: 72px !important;
    height: 72px !important;
    min-width: 72px !important;
    min-height: 72px !important;
    line-height: 72px !important; }
  md-icon[md-font-icon].s74,
  i.s74 {
    font-size: 74px !important;
    width: 74px !important;
    height: 74px !important;
    min-width: 74px !important;
    min-height: 74px !important;
    line-height: 74px !important; }
  md-icon[md-font-icon].s76,
  i.s76 {
    font-size: 76px !important;
    width: 76px !important;
    height: 76px !important;
    min-width: 76px !important;
    min-height: 76px !important;
    line-height: 76px !important; }
  md-icon[md-font-icon].s78,
  i.s78 {
    font-size: 78px !important;
    width: 78px !important;
    height: 78px !important;
    min-width: 78px !important;
    min-height: 78px !important;
    line-height: 78px !important; }
  md-icon[md-font-icon].s80,
  i.s80 {
    font-size: 80px !important;
    width: 80px !important;
    height: 80px !important;
    min-width: 80px !important;
    min-height: 80px !important;
    line-height: 80px !important; }
  md-icon[md-font-icon].s82,
  i.s82 {
    font-size: 82px !important;
    width: 82px !important;
    height: 82px !important;
    min-width: 82px !important;
    min-height: 82px !important;
    line-height: 82px !important; }
  md-icon[md-font-icon].s84,
  i.s84 {
    font-size: 84px !important;
    width: 84px !important;
    height: 84px !important;
    min-width: 84px !important;
    min-height: 84px !important;
    line-height: 84px !important; }
  md-icon[md-font-icon].s86,
  i.s86 {
    font-size: 86px !important;
    width: 86px !important;
    height: 86px !important;
    min-width: 86px !important;
    min-height: 86px !important;
    line-height: 86px !important; }
  md-icon[md-font-icon].s88,
  i.s88 {
    font-size: 88px !important;
    width: 88px !important;
    height: 88px !important;
    min-width: 88px !important;
    min-height: 88px !important;
    line-height: 88px !important; }
  md-icon[md-font-icon].s90,
  i.s90 {
    font-size: 90px !important;
    width: 90px !important;
    height: 90px !important;
    min-width: 90px !important;
    min-height: 90px !important;
    line-height: 90px !important; }
  md-icon[md-font-icon].s92,
  i.s92 {
    font-size: 92px !important;
    width: 92px !important;
    height: 92px !important;
    min-width: 92px !important;
    min-height: 92px !important;
    line-height: 92px !important; }
  md-icon[md-font-icon].s94,
  i.s94 {
    font-size: 94px !important;
    width: 94px !important;
    height: 94px !important;
    min-width: 94px !important;
    min-height: 94px !important;
    line-height: 94px !important; }
  md-icon[md-font-icon].s96,
  i.s96 {
    font-size: 96px !important;
    width: 96px !important;
    height: 96px !important;
    min-width: 96px !important;
    min-height: 96px !important;
    line-height: 96px !important; }
  md-icon[md-font-icon].s98,
  i.s98 {
    font-size: 98px !important;
    width: 98px !important;
    height: 98px !important;
    min-width: 98px !important;
    min-height: 98px !important;
    line-height: 98px !important; }
  md-icon[md-font-icon].s100,
  i.s100 {
    font-size: 100px !important;
    width: 100px !important;
    height: 100px !important;
    min-width: 100px !important;
    min-height: 100px !important;
    line-height: 100px !important; }
  md-icon[md-font-icon].s102,
  i.s102 {
    font-size: 102px !important;
    width: 102px !important;
    height: 102px !important;
    min-width: 102px !important;
    min-height: 102px !important;
    line-height: 102px !important; }
  md-icon[md-font-icon].s104,
  i.s104 {
    font-size: 104px !important;
    width: 104px !important;
    height: 104px !important;
    min-width: 104px !important;
    min-height: 104px !important;
    line-height: 104px !important; }
  md-icon[md-font-icon].s106,
  i.s106 {
    font-size: 106px !important;
    width: 106px !important;
    height: 106px !important;
    min-width: 106px !important;
    min-height: 106px !important;
    line-height: 106px !important; }
  md-icon[md-font-icon].s108,
  i.s108 {
    font-size: 108px !important;
    width: 108px !important;
    height: 108px !important;
    min-width: 108px !important;
    min-height: 108px !important;
    line-height: 108px !important; }
  md-icon[md-font-icon].s110,
  i.s110 {
    font-size: 110px !important;
    width: 110px !important;
    height: 110px !important;
    min-width: 110px !important;
    min-height: 110px !important;
    line-height: 110px !important; }
  md-icon[md-font-icon].s112,
  i.s112 {
    font-size: 112px !important;
    width: 112px !important;
    height: 112px !important;
    min-width: 112px !important;
    min-height: 112px !important;
    line-height: 112px !important; }
  md-icon[md-font-icon].s114,
  i.s114 {
    font-size: 114px !important;
    width: 114px !important;
    height: 114px !important;
    min-width: 114px !important;
    min-height: 114px !important;
    line-height: 114px !important; }
  md-icon[md-font-icon].s116,
  i.s116 {
    font-size: 116px !important;
    width: 116px !important;
    height: 116px !important;
    min-width: 116px !important;
    min-height: 116px !important;
    line-height: 116px !important; }
  md-icon[md-font-icon].s118,
  i.s118 {
    font-size: 118px !important;
    width: 118px !important;
    height: 118px !important;
    min-width: 118px !important;
    min-height: 118px !important;
    line-height: 118px !important; }
  md-icon[md-font-icon].s120,
  i.s120 {
    font-size: 120px !important;
    width: 120px !important;
    height: 120px !important;
    min-width: 120px !important;
    min-height: 120px !important;
    line-height: 120px !important; }
  md-icon[md-font-icon].s122,
  i.s122 {
    font-size: 122px !important;
    width: 122px !important;
    height: 122px !important;
    min-width: 122px !important;
    min-height: 122px !important;
    line-height: 122px !important; }
  md-icon[md-font-icon].s124,
  i.s124 {
    font-size: 124px !important;
    width: 124px !important;
    height: 124px !important;
    min-width: 124px !important;
    min-height: 124px !important;
    line-height: 124px !important; }
  md-icon[md-font-icon].s126,
  i.s126 {
    font-size: 126px !important;
    width: 126px !important;
    height: 126px !important;
    min-width: 126px !important;
    min-height: 126px !important;
    line-height: 126px !important; }
  md-icon[md-font-icon].s128,
  i.s128 {
    font-size: 128px !important;
    width: 128px !important;
    height: 128px !important;
    min-width: 128px !important;
    min-height: 128px !important;
    line-height: 128px !important; }
  md-icon[md-font-icon].s130,
  i.s130 {
    font-size: 130px !important;
    width: 130px !important;
    height: 130px !important;
    min-width: 130px !important;
    min-height: 130px !important;
    line-height: 130px !important; }
  md-icon[md-font-icon].s132,
  i.s132 {
    font-size: 132px !important;
    width: 132px !important;
    height: 132px !important;
    min-width: 132px !important;
    min-height: 132px !important;
    line-height: 132px !important; }
  md-icon[md-font-icon].s134,
  i.s134 {
    font-size: 134px !important;
    width: 134px !important;
    height: 134px !important;
    min-width: 134px !important;
    min-height: 134px !important;
    line-height: 134px !important; }
  md-icon[md-font-icon].s136,
  i.s136 {
    font-size: 136px !important;
    width: 136px !important;
    height: 136px !important;
    min-width: 136px !important;
    min-height: 136px !important;
    line-height: 136px !important; }
  md-icon[md-font-icon].s138,
  i.s138 {
    font-size: 138px !important;
    width: 138px !important;
    height: 138px !important;
    min-width: 138px !important;
    min-height: 138px !important;
    line-height: 138px !important; }
  md-icon[md-font-icon].s140,
  i.s140 {
    font-size: 140px !important;
    width: 140px !important;
    height: 140px !important;
    min-width: 140px !important;
    min-height: 140px !important;
    line-height: 140px !important; }
  md-icon[md-font-icon].s142,
  i.s142 {
    font-size: 142px !important;
    width: 142px !important;
    height: 142px !important;
    min-width: 142px !important;
    min-height: 142px !important;
    line-height: 142px !important; }
  md-icon[md-font-icon].s144,
  i.s144 {
    font-size: 144px !important;
    width: 144px !important;
    height: 144px !important;
    min-width: 144px !important;
    min-height: 144px !important;
    line-height: 144px !important; }
  md-icon[md-font-icon].s146,
  i.s146 {
    font-size: 146px !important;
    width: 146px !important;
    height: 146px !important;
    min-width: 146px !important;
    min-height: 146px !important;
    line-height: 146px !important; }
  md-icon[md-font-icon].s148,
  i.s148 {
    font-size: 148px !important;
    width: 148px !important;
    height: 148px !important;
    min-width: 148px !important;
    min-height: 148px !important;
    line-height: 148px !important; }
  md-icon[md-font-icon].s150,
  i.s150 {
    font-size: 150px !important;
    width: 150px !important;
    height: 150px !important;
    min-width: 150px !important;
    min-height: 150px !important;
    line-height: 150px !important; }
  md-icon[md-font-icon].s152,
  i.s152 {
    font-size: 152px !important;
    width: 152px !important;
    height: 152px !important;
    min-width: 152px !important;
    min-height: 152px !important;
    line-height: 152px !important; }
  md-icon[md-font-icon].s154,
  i.s154 {
    font-size: 154px !important;
    width: 154px !important;
    height: 154px !important;
    min-width: 154px !important;
    min-height: 154px !important;
    line-height: 154px !important; }
  md-icon[md-font-icon].s156,
  i.s156 {
    font-size: 156px !important;
    width: 156px !important;
    height: 156px !important;
    min-width: 156px !important;
    min-height: 156px !important;
    line-height: 156px !important; }
  md-icon[md-font-icon].s158,
  i.s158 {
    font-size: 158px !important;
    width: 158px !important;
    height: 158px !important;
    min-width: 158px !important;
    min-height: 158px !important;
    line-height: 158px !important; }
  md-icon[md-font-icon].s160,
  i.s160 {
    font-size: 160px !important;
    width: 160px !important;
    height: 160px !important;
    min-width: 160px !important;
    min-height: 160px !important;
    line-height: 160px !important; }
  md-icon[md-font-icon].s162,
  i.s162 {
    font-size: 162px !important;
    width: 162px !important;
    height: 162px !important;
    min-width: 162px !important;
    min-height: 162px !important;
    line-height: 162px !important; }
  md-icon[md-font-icon].s164,
  i.s164 {
    font-size: 164px !important;
    width: 164px !important;
    height: 164px !important;
    min-width: 164px !important;
    min-height: 164px !important;
    line-height: 164px !important; }
  md-icon[md-font-icon].s166,
  i.s166 {
    font-size: 166px !important;
    width: 166px !important;
    height: 166px !important;
    min-width: 166px !important;
    min-height: 166px !important;
    line-height: 166px !important; }
  md-icon[md-font-icon].s168,
  i.s168 {
    font-size: 168px !important;
    width: 168px !important;
    height: 168px !important;
    min-width: 168px !important;
    min-height: 168px !important;
    line-height: 168px !important; }
  md-icon[md-font-icon].s170,
  i.s170 {
    font-size: 170px !important;
    width: 170px !important;
    height: 170px !important;
    min-width: 170px !important;
    min-height: 170px !important;
    line-height: 170px !important; }
  md-icon[md-font-icon].s172,
  i.s172 {
    font-size: 172px !important;
    width: 172px !important;
    height: 172px !important;
    min-width: 172px !important;
    min-height: 172px !important;
    line-height: 172px !important; }
  md-icon[md-font-icon].s174,
  i.s174 {
    font-size: 174px !important;
    width: 174px !important;
    height: 174px !important;
    min-width: 174px !important;
    min-height: 174px !important;
    line-height: 174px !important; }
  md-icon[md-font-icon].s176,
  i.s176 {
    font-size: 176px !important;
    width: 176px !important;
    height: 176px !important;
    min-width: 176px !important;
    min-height: 176px !important;
    line-height: 176px !important; }
  md-icon[md-font-icon].s178,
  i.s178 {
    font-size: 178px !important;
    width: 178px !important;
    height: 178px !important;
    min-width: 178px !important;
    min-height: 178px !important;
    line-height: 178px !important; }
  md-icon[md-font-icon].s180,
  i.s180 {
    font-size: 180px !important;
    width: 180px !important;
    height: 180px !important;
    min-width: 180px !important;
    min-height: 180px !important;
    line-height: 180px !important; }
  md-icon[md-font-icon].s182,
  i.s182 {
    font-size: 182px !important;
    width: 182px !important;
    height: 182px !important;
    min-width: 182px !important;
    min-height: 182px !important;
    line-height: 182px !important; }
  md-icon[md-font-icon].s184,
  i.s184 {
    font-size: 184px !important;
    width: 184px !important;
    height: 184px !important;
    min-width: 184px !important;
    min-height: 184px !important;
    line-height: 184px !important; }
  md-icon[md-font-icon].s186,
  i.s186 {
    font-size: 186px !important;
    width: 186px !important;
    height: 186px !important;
    min-width: 186px !important;
    min-height: 186px !important;
    line-height: 186px !important; }
  md-icon[md-font-icon].s188,
  i.s188 {
    font-size: 188px !important;
    width: 188px !important;
    height: 188px !important;
    min-width: 188px !important;
    min-height: 188px !important;
    line-height: 188px !important; }
  md-icon[md-font-icon].s190,
  i.s190 {
    font-size: 190px !important;
    width: 190px !important;
    height: 190px !important;
    min-width: 190px !important;
    min-height: 190px !important;
    line-height: 190px !important; }
  md-icon[md-font-icon].s192,
  i.s192 {
    font-size: 192px !important;
    width: 192px !important;
    height: 192px !important;
    min-width: 192px !important;
    min-height: 192px !important;
    line-height: 192px !important; }
  md-icon[md-font-icon].s194,
  i.s194 {
    font-size: 194px !important;
    width: 194px !important;
    height: 194px !important;
    min-width: 194px !important;
    min-height: 194px !important;
    line-height: 194px !important; }
  md-icon[md-font-icon].s196,
  i.s196 {
    font-size: 196px !important;
    width: 196px !important;
    height: 196px !important;
    min-width: 196px !important;
    min-height: 196px !important;
    line-height: 196px !important; }
  md-icon[md-font-icon].s198,
  i.s198 {
    font-size: 198px !important;
    width: 198px !important;
    height: 198px !important;
    min-width: 198px !important;
    min-height: 198px !important;
    line-height: 198px !important; }
  md-icon[md-font-icon].s200,
  i.s200 {
    font-size: 200px !important;
    width: 200px !important;
    height: 200px !important;
    min-width: 200px !important;
    min-height: 200px !important;
    line-height: 200px !important; }
  md-icon[md-font-icon].s202,
  i.s202 {
    font-size: 202px !important;
    width: 202px !important;
    height: 202px !important;
    min-width: 202px !important;
    min-height: 202px !important;
    line-height: 202px !important; }
  md-icon[md-font-icon].s204,
  i.s204 {
    font-size: 204px !important;
    width: 204px !important;
    height: 204px !important;
    min-width: 204px !important;
    min-height: 204px !important;
    line-height: 204px !important; }
  md-icon[md-font-icon].s206,
  i.s206 {
    font-size: 206px !important;
    width: 206px !important;
    height: 206px !important;
    min-width: 206px !important;
    min-height: 206px !important;
    line-height: 206px !important; }
  md-icon[md-font-icon].s208,
  i.s208 {
    font-size: 208px !important;
    width: 208px !important;
    height: 208px !important;
    min-width: 208px !important;
    min-height: 208px !important;
    line-height: 208px !important; }
  md-icon[md-font-icon].s210,
  i.s210 {
    font-size: 210px !important;
    width: 210px !important;
    height: 210px !important;
    min-width: 210px !important;
    min-height: 210px !important;
    line-height: 210px !important; }
  md-icon[md-font-icon].s212,
  i.s212 {
    font-size: 212px !important;
    width: 212px !important;
    height: 212px !important;
    min-width: 212px !important;
    min-height: 212px !important;
    line-height: 212px !important; }
  md-icon[md-font-icon].s214,
  i.s214 {
    font-size: 214px !important;
    width: 214px !important;
    height: 214px !important;
    min-width: 214px !important;
    min-height: 214px !important;
    line-height: 214px !important; }
  md-icon[md-font-icon].s216,
  i.s216 {
    font-size: 216px !important;
    width: 216px !important;
    height: 216px !important;
    min-width: 216px !important;
    min-height: 216px !important;
    line-height: 216px !important; }
  md-icon[md-font-icon].s218,
  i.s218 {
    font-size: 218px !important;
    width: 218px !important;
    height: 218px !important;
    min-width: 218px !important;
    min-height: 218px !important;
    line-height: 218px !important; }
  md-icon[md-font-icon].s220,
  i.s220 {
    font-size: 220px !important;
    width: 220px !important;
    height: 220px !important;
    min-width: 220px !important;
    min-height: 220px !important;
    line-height: 220px !important; }
  md-icon[md-font-icon].s222,
  i.s222 {
    font-size: 222px !important;
    width: 222px !important;
    height: 222px !important;
    min-width: 222px !important;
    min-height: 222px !important;
    line-height: 222px !important; }
  md-icon[md-font-icon].s224,
  i.s224 {
    font-size: 224px !important;
    width: 224px !important;
    height: 224px !important;
    min-width: 224px !important;
    min-height: 224px !important;
    line-height: 224px !important; }
  md-icon[md-font-icon].s226,
  i.s226 {
    font-size: 226px !important;
    width: 226px !important;
    height: 226px !important;
    min-width: 226px !important;
    min-height: 226px !important;
    line-height: 226px !important; }
  md-icon[md-font-icon].s228,
  i.s228 {
    font-size: 228px !important;
    width: 228px !important;
    height: 228px !important;
    min-width: 228px !important;
    min-height: 228px !important;
    line-height: 228px !important; }
  md-icon[md-font-icon].s230,
  i.s230 {
    font-size: 230px !important;
    width: 230px !important;
    height: 230px !important;
    min-width: 230px !important;
    min-height: 230px !important;
    line-height: 230px !important; }
  md-icon[md-font-icon].s232,
  i.s232 {
    font-size: 232px !important;
    width: 232px !important;
    height: 232px !important;
    min-width: 232px !important;
    min-height: 232px !important;
    line-height: 232px !important; }
  md-icon[md-font-icon].s234,
  i.s234 {
    font-size: 234px !important;
    width: 234px !important;
    height: 234px !important;
    min-width: 234px !important;
    min-height: 234px !important;
    line-height: 234px !important; }
  md-icon[md-font-icon].s236,
  i.s236 {
    font-size: 236px !important;
    width: 236px !important;
    height: 236px !important;
    min-width: 236px !important;
    min-height: 236px !important;
    line-height: 236px !important; }
  md-icon[md-font-icon].s238,
  i.s238 {
    font-size: 238px !important;
    width: 238px !important;
    height: 238px !important;
    min-width: 238px !important;
    min-height: 238px !important;
    line-height: 238px !important; }
  md-icon[md-font-icon].s240,
  i.s240 {
    font-size: 240px !important;
    width: 240px !important;
    height: 240px !important;
    min-width: 240px !important;
    min-height: 240px !important;
    line-height: 240px !important; }
  md-icon[md-font-icon].s242,
  i.s242 {
    font-size: 242px !important;
    width: 242px !important;
    height: 242px !important;
    min-width: 242px !important;
    min-height: 242px !important;
    line-height: 242px !important; }
  md-icon[md-font-icon].s244,
  i.s244 {
    font-size: 244px !important;
    width: 244px !important;
    height: 244px !important;
    min-width: 244px !important;
    min-height: 244px !important;
    line-height: 244px !important; }
  md-icon[md-font-icon].s246,
  i.s246 {
    font-size: 246px !important;
    width: 246px !important;
    height: 246px !important;
    min-width: 246px !important;
    min-height: 246px !important;
    line-height: 246px !important; }
  md-icon[md-font-icon].s248,
  i.s248 {
    font-size: 248px !important;
    width: 248px !important;
    height: 248px !important;
    min-width: 248px !important;
    min-height: 248px !important;
    line-height: 248px !important; }
  md-icon[md-font-icon].s250,
  i.s250 {
    font-size: 250px !important;
    width: 250px !important;
    height: 250px !important;
    min-width: 250px !important;
    min-height: 250px !important;
    line-height: 250px !important; }
  md-icon[md-font-icon].s252,
  i.s252 {
    font-size: 252px !important;
    width: 252px !important;
    height: 252px !important;
    min-width: 252px !important;
    min-height: 252px !important;
    line-height: 252px !important; }
  md-icon[md-font-icon].s254,
  i.s254 {
    font-size: 254px !important;
    width: 254px !important;
    height: 254px !important;
    min-width: 254px !important;
    min-height: 254px !important;
    line-height: 254px !important; }
  md-icon[md-font-icon].s256,
  i.s256 {
    font-size: 256px !important;
    width: 256px !important;
    height: 256px !important;
    min-width: 256px !important;
    min-height: 256px !important;
    line-height: 256px !important; }

@font-face {
  font-family: 'icomoon';
  src: url("../assets/icons/fonts/icomoon.eot?5km1tg");
  src: url("../assets/icons/fonts/icomoon.eot?#iefix5km1tg") format("embedded-opentype"), url("../assets/icons/fonts/icomoon.ttf?5km1tg") format("truetype"), url("../assets/icons/fonts/icomoon.woff?5km1tg") format("woff"), url("../assets/icons/fonts/icomoon.svg?5km1tg#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-account-alert:before {
  content: "\e600"; }

.icon-account-box-outline:before {
  content: "\e601"; }

.icon-account-box:before {
  content: "\e602"; }

.icon-account-check:before {
  content: "\e603"; }

.icon-account-circle:before {
  content: "\e604"; }

.icon-account-key:before {
  content: "\e605"; }

.icon-account-location:before {
  content: "\e606"; }

.icon-account-minus:before {
  content: "\e607"; }

.icon-account-multiple-outline:before {
  content: "\e608"; }

.icon-account-multiple-plus:before {
  content: "\e609"; }

.icon-account-multiple:before {
  content: "\e60a"; }

.icon-account-network:before {
  content: "\e60b"; }

.icon-account-outline:before {
  content: "\e60c"; }

.icon-account-plus:before {
  content: "\e60d"; }

.icon-account-remove:before {
  content: "\e60e"; }

.icon-account-search:before {
  content: "\e60f"; }

.icon-account-switch:before {
  content: "\e610"; }

.icon-account:before {
  content: "\e611"; }

.icon-airballoon:before {
  content: "\e612"; }

.icon-airplane-off:before {
  content: "\e613"; }

.icon-airplane:before {
  content: "\e614"; }

.icon-alarm-check:before {
  content: "\e615"; }

.icon-alarm-multiple:before {
  content: "\e616"; }

.icon-alarm-off:before {
  content: "\e617"; }

.icon-alarm-plus:before {
  content: "\e618"; }

.icon-alarm:before {
  content: "\e619"; }

.icon-album:before {
  content: "\e61a"; }

.icon-alert-box:before {
  content: "\e61b"; }

.icon-alert-circle:before {
  content: "\e61c"; }

.icon-alert-octagon:before {
  content: "\e61d"; }

.icon-alert:before {
  content: "\e61e"; }

.icon-alpha:before {
  content: "\e61f"; }

.icon-alphabetical:before {
  content: "\e620"; }

.icon-amazon-clouddrive:before {
  content: "\e621"; }

.icon-amazon:before {
  content: "\e622"; }

.icon-ambulance:before {
  content: "\e623"; }

.icon-android-debug-bridge:before {
  content: "\e624"; }

.icon-android-studio:before {
  content: "\e625"; }

.icon-android:before {
  content: "\e626"; }

.icon-apple-finder:before {
  content: "\e627"; }

.icon-apple-ios:before {
  content: "\e628"; }

.icon-apple-mobileme:before {
  content: "\e629"; }

.icon-apple-safari:before {
  content: "\e62a"; }

.icon-apple:before {
  content: "\e62b"; }

.icon-appnet:before {
  content: "\e62c"; }

.icon-apps:before {
  content: "\e62d"; }

.icon-archive:before {
  content: "\e62e"; }

.icon-arrange-bring-forward:before {
  content: "\e62f"; }

.icon-arrange-bring-to-front:before {
  content: "\e630"; }

.icon-arrange-send-backward:before {
  content: "\e631"; }

.icon-arrange-send-to-back:before {
  content: "\e632"; }

.icon-arrow-all:before {
  content: "\e633"; }

.icon-arrow-bottom-left:before {
  content: "\e634"; }

.icon-arrow-bottom-right:before {
  content: "\e635"; }

.icon-arrow-collapse:before {
  content: "\e636"; }

.icon-arrow-down-bold-circle-outline:before {
  content: "\e637"; }

.icon-arrow-down-bold-circle:before {
  content: "\e638"; }

.icon-arrow-down-bold-hexagon-outline:before {
  content: "\e639"; }

.icon-arrow-down-bold:before {
  content: "\e63a"; }

.icon-arrow-down:before {
  content: "\e63b"; }

.icon-arrow-expand:before {
  content: "\e63c"; }

.icon-arrow-left-bold-circle-outline:before {
  content: "\e63d"; }

.icon-arrow-left-bold-circle:before {
  content: "\e63e"; }

.icon-arrow-left-bold-hexagon-outline:before {
  content: "\e63f"; }

.icon-arrow-left-bold:before {
  content: "\e640"; }

.icon-arrow-left:before {
  content: "\e641"; }

.icon-arrow-right-bold-circle-outline:before {
  content: "\e642"; }

.icon-arrow-right-bold-circle:before {
  content: "\e643"; }

.icon-arrow-right-bold-hexagon-outline:before {
  content: "\e644"; }

.icon-arrow-right-bold:before {
  content: "\e645"; }

.icon-arrow-right:before {
  content: "\e646"; }

.icon-arrow-top-left:before {
  content: "\e647"; }

.icon-arrow-top-right:before {
  content: "\e648"; }

.icon-arrow-up-bold-circle-outline:before {
  content: "\e649"; }

.icon-arrow-up-bold-circle:before {
  content: "\e64a"; }

.icon-arrow-up-bold-hexagon-outline:before {
  content: "\e64b"; }

.icon-arrow-up-bold:before {
  content: "\e64c"; }

.icon-arrow-up:before {
  content: "\e64d"; }

.icon-at:before {
  content: "\e64e"; }

.icon-attachment:before {
  content: "\e64f"; }

.icon-audiobook:before {
  content: "\e650"; }

.icon-auto-fix:before {
  content: "\e651"; }

.icon-auto-upload:before {
  content: "\e652"; }

.icon-baby:before {
  content: "\e653"; }

.icon-backburger:before {
  content: "\e654"; }

.icon-backup-restore:before {
  content: "\e655"; }

.icon-bank:before {
  content: "\e656"; }

.icon-barcode:before {
  content: "\e657"; }

.icon-barley:before {
  content: "\e658"; }

.icon-barrel:before {
  content: "\e659"; }

.icon-basecamp:before {
  content: "\e65a"; }

.icon-basket-fill:before {
  content: "\e65b"; }

.icon-basket-unfill:before {
  content: "\e65c"; }

.icon-basket:before {
  content: "\e65d"; }

.icon-battery-10:before {
  content: "\e65e"; }

.icon-battery-20:before {
  content: "\e65f"; }

.icon-battery-30:before {
  content: "\e660"; }

.icon-battery-40:before {
  content: "\e661"; }

.icon-battery-50:before {
  content: "\e662"; }

.icon-battery-60:before {
  content: "\e663"; }

.icon-battery-70:before {
  content: "\e664"; }

.icon-battery-80:before {
  content: "\e665"; }

.icon-battery-90:before {
  content: "\e666"; }

.icon-battery-alert:before {
  content: "\e667"; }

.icon-battery-charging-20:before {
  content: "\e668"; }

.icon-battery-charging-30:before {
  content: "\e669"; }

.icon-battery-charging-40:before {
  content: "\e66a"; }

.icon-battery-charging-60:before {
  content: "\e66b"; }

.icon-battery-charging-80:before {
  content: "\e66c"; }

.icon-battery-charging-90:before {
  content: "\e66d"; }

.icon-battery-charging-100:before {
  content: "\e66e"; }

.icon-battery-minus:before {
  content: "\e66f"; }

.icon-battery-negative:before {
  content: "\e670"; }

.icon-battery-outline:before {
  content: "\e671"; }

.icon-battery-plus:before {
  content: "\e672"; }

.icon-battery-positive:before {
  content: "\e673"; }

.icon-battery-standard:before {
  content: "\e674"; }

.icon-battery-unknown:before {
  content: "\e675"; }

.icon-battery:before {
  content: "\e676"; }

.icon-beach:before {
  content: "\e677"; }

.icon-beaker-empty-outline:before {
  content: "\e678"; }

.icon-beaker-empty:before {
  content: "\e679"; }

.icon-beaker-outline:before {
  content: "\e67a"; }

.icon-beaker:before {
  content: "\e67b"; }

.icon-beats:before {
  content: "\e67c"; }

.icon-beer:before {
  content: "\e67d"; }

.icon-behance:before {
  content: "\e67e"; }

.icon-bell-off:before {
  content: "\e67f"; }

.icon-bell-outline:before {
  content: "\e680"; }

.icon-bell-ring-outline:before {
  content: "\e681"; }

.icon-bell-ring:before {
  content: "\e682"; }

.icon-bell-sleep:before {
  content: "\e683"; }

.icon-bell:before {
  content: "\e684"; }

.icon-beta:before {
  content: "\e685"; }

.icon-bike:before {
  content: "\e686"; }

.icon-bing:before {
  content: "\e687"; }

.icon-binoculars:before {
  content: "\e688"; }

.icon-bio:before {
  content: "\e689"; }

.icon-bitbucket:before {
  content: "\e68a"; }

.icon-black-mesa:before {
  content: "\e68b"; }

.icon-blackberry:before {
  content: "\e68c"; }

.icon-blinds:before {
  content: "\e68d"; }

.icon-block-helper:before {
  content: "\e68e"; }

.icon-blogger:before {
  content: "\e68f"; }

.icon-bluetooth-audio:before {
  content: "\e690"; }

.icon-bluetooth-connect:before {
  content: "\e691"; }

.icon-bluetooth-settings:before {
  content: "\e692"; }

.icon-bluetooth-transfer:before {
  content: "\e693"; }

.icon-bluetooth:before {
  content: "\e694"; }

.icon-blur-linear:before {
  content: "\e695"; }

.icon-blur-off:before {
  content: "\e696"; }

.icon-blur-radial:before {
  content: "\e697"; }

.icon-blur:before {
  content: "\e698"; }

.icon-bone:before {
  content: "\e699"; }

.icon-book-multiple-variant:before {
  content: "\e69a"; }

.icon-book-multiple:before {
  content: "\e69b"; }

.icon-book-open:before {
  content: "\e69c"; }

.icon-book-variant:before {
  content: "\e69d"; }

.icon-book:before {
  content: "\e69e"; }

.icon-bookmark-check:before {
  content: "\e69f"; }

.icon-bookmark-music:before {
  content: "\e6a0"; }

.icon-bookmark-outline-plus:before {
  content: "\e6a1"; }

.icon-bookmark-outline:before {
  content: "\e6a2"; }

.icon-bookmark-plus:before {
  content: "\e6a3"; }

.icon-bookmark-remove:before {
  content: "\e6a4"; }

.icon-bookmark:before {
  content: "\e6a5"; }

.icon-border-all:before {
  content: "\e6a6"; }

.icon-border-bottom:before {
  content: "\e6a7"; }

.icon-border-color:before {
  content: "\e6a8"; }

.icon-border-horizontal:before {
  content: "\e6a9"; }

.icon-border-inside:before {
  content: "\e6aa"; }

.icon-border-left:before {
  content: "\e6ab"; }

.icon-border-none:before {
  content: "\e6ac"; }

.icon-border-outside:before {
  content: "\e6ad"; }

.icon-border-right:before {
  content: "\e6ae"; }

.icon-border-top:before {
  content: "\e6af"; }

.icon-border-vertical:before {
  content: "\e6b0"; }

.icon-bowling:before {
  content: "\e6b1"; }

.icon-box-download:before {
  content: "\e6b2"; }

.icon-box-upload:before {
  content: "\e6b3"; }

.icon-box:before {
  content: "\e6b4"; }

.icon-briefcase-checked:before {
  content: "\e6b5"; }

.icon-briefcase-download:before {
  content: "\e6b6"; }

.icon-briefcase-upload:before {
  content: "\e6b7"; }

.icon-briefcase:before {
  content: "\e6b8"; }

.icon-brightness-1:before {
  content: "\e6b9"; }

.icon-brightness-2:before {
  content: "\e6ba"; }

.icon-brightness-3:before {
  content: "\e6bb"; }

.icon-brightness-4:before {
  content: "\e6bc"; }

.icon-brightness-5:before {
  content: "\e6bd"; }

.icon-brightness-6:before {
  content: "\e6be"; }

.icon-brightness-7:before {
  content: "\e6bf"; }

.icon-brightness-auto:before {
  content: "\e6c0"; }

.icon-brightness:before {
  content: "\e6c1"; }

.icon-broom:before {
  content: "\e6c2"; }

.icon-brush:before {
  content: "\e6c3"; }

.icon-bug:before {
  content: "\e6c4"; }

.icon-bulletin-board:before {
  content: "\e6c5"; }

.icon-bullhorn:before {
  content: "\e6c6"; }

.icon-bus:before {
  content: "\e6c7"; }

.icon-cake-variant:before {
  content: "\e6c8"; }

.icon-cake:before {
  content: "\e6c9"; }

.icon-calculator:before {
  content: "\e6ca"; }

.icon-calendar-blank:before {
  content: "\e6cb"; }

.icon-calendar-check-multiple:before {
  content: "\e6cc"; }

.icon-calendar-check:before {
  content: "\e6cd"; }

.icon-calendar-clock:before {
  content: "\e6ce"; }

.icon-calendar-multiple:before {
  content: "\e6cf"; }

.icon-calendar-plus:before {
  content: "\e6d0"; }

.icon-calendar-remove:before {
  content: "\e6d1"; }

.icon-calendar-select:before {
  content: "\e6d2"; }

.icon-calendar-text:before {
  content: "\e6d3"; }

.icon-calendar-today:before {
  content: "\e6d4"; }

.icon-calendar:before {
  content: "\e6d5"; }

.icon-camcorder-box-off:before {
  content: "\e6d6"; }

.icon-camcorder-box:before {
  content: "\e6d7"; }

.icon-camcorder-off:before {
  content: "\e6d8"; }

.icon-camcorder:before {
  content: "\e6d9"; }

.icon-camera-front-variant:before {
  content: "\e6da"; }

.icon-camera-front:before {
  content: "\e6db"; }

.icon-camera-iris:before {
  content: "\e6dc"; }

.icon-camera-party-mode:before {
  content: "\e6dd"; }

.icon-camera-rear-variant:before {
  content: "\e6de"; }

.icon-camera-rear:before {
  content: "\e6df"; }

.icon-camera-switch:before {
  content: "\e6e0"; }

.icon-camera-timer:before {
  content: "\e6e1"; }

.icon-camera:before {
  content: "\e6e2"; }

.icon-cancel:before {
  content: "\e6e3"; }

.icon-candycane:before {
  content: "\e6e4"; }

.icon-car-wash:before {
  content: "\e6e5"; }

.icon-car:before {
  content: "\e6e6"; }

.icon-carrot:before {
  content: "\e6e7"; }

.icon-cart-outline:before {
  content: "\e6e8"; }

.icon-cart:before {
  content: "\e6e9"; }

.icon-cash-100:before {
  content: "\e6ea"; }

.icon-cash-multiple:before {
  content: "\e6eb"; }

.icon-cash-usd:before {
  content: "\e6ec"; }

.icon-cash:before {
  content: "\e6ed"; }

.icon-cast-connected:before {
  content: "\e6ee"; }

.icon-cast:before {
  content: "\e6ef"; }

.icon-castle:before {
  content: "\e6f0"; }

.icon-cat:before {
  content: "\e6f1"; }

.icon-cellphone-android:before {
  content: "\e6f2"; }

.icon-cellphone-dock:before {
  content: "\e6f3"; }

.icon-cellphone-iphone:before {
  content: "\e6f4"; }

.icon-cellphone-link-off:before {
  content: "\e6f5"; }

.icon-cellphone-link:before {
  content: "\e6f6"; }

.icon-cellphone-settings:before {
  content: "\e6f7"; }

.icon-cellphone:before {
  content: "\e6f8"; }

.icon-chair-school:before {
  content: "\e6f9"; }

.icon-chart-arc:before {
  content: "\e6fa"; }

.icon-chart-areaspline:before {
  content: "\e6fb"; }

.icon-chart-bar:before {
  content: "\e6fc"; }

.icon-chart-histogram:before {
  content: "\e6fd"; }

.icon-chart-line:before {
  content: "\e6fe"; }

.icon-chart-pie:before {
  content: "\e6ff"; }

.icon-check-all:before {
  content: "\e700"; }

.icon-check-bookmark:before {
  content: "\e701"; }

.icon-check-circle:before {
  content: "\e702"; }

.icon-check:before {
  content: "\e703"; }

.icon-checkbox-blank-circle-outline:before {
  content: "\e704"; }

.icon-checkbox-blank-circle:before {
  content: "\e705"; }

.icon-checkbox-blank-outline:before {
  content: "\e706"; }

.icon-checkbox-blank:before {
  content: "\e707"; }

.icon-checkbox-marked-circle-outline:before {
  content: "\e708"; }

.icon-checkbox-marked-circle:before {
  content: "\e709"; }

.icon-checkbox-marked-outline:before {
  content: "\e70a"; }

.icon-checkbox-marked:before {
  content: "\e70b"; }

.icon-checkbox-multiple-blank-outline:before {
  content: "\e70c"; }

.icon-checkbox-multiple-blank:before {
  content: "\e70d"; }

.icon-checkbox-multiple-marked-outline:before {
  content: "\e70e"; }

.icon-checkbox-multiple-marked:before {
  content: "\e70f"; }

.icon-checkerboard:before {
  content: "\e710"; }

.icon-chevron-double-down:before {
  content: "\e711"; }

.icon-chevron-double-left:before {
  content: "\e712"; }

.icon-chevron-double-right:before {
  content: "\e713"; }

.icon-chevron-double-up:before {
  content: "\e714"; }

.icon-chevron-down:before {
  content: "\e715"; }

.icon-chevron-left:before {
  content: "\e716"; }

.icon-chevron-right:before {
  content: "\e717"; }

.icon-chevron-up:before {
  content: "\e718"; }

.icon-church:before {
  content: "\e719"; }

.icon-cisco-webex:before {
  content: "\e71a"; }

.icon-city:before {
  content: "\e71b"; }

.icon-clapperboard:before {
  content: "\e71c"; }

.icon-clipboard-account:before {
  content: "\e71d"; }

.icon-clipboard-alert:before {
  content: "\e71e"; }

.icon-clipboard-arrow-down:before {
  content: "\e71f"; }

.icon-clipboard-arrow-left:before {
  content: "\e720"; }

.icon-clipboard-check:before {
  content: "\e721"; }

.icon-clipboard-outline:before {
  content: "\e722"; }

.icon-clipboard-text:before {
  content: "\e723"; }

.icon-clipboard:before {
  content: "\e724"; }

.icon-clippy:before {
  content: "\e725"; }

.icon-clock-fast:before {
  content: "\e726"; }

.icon-clock:before {
  content: "\e727"; }

.icon-close-circle-outline:before {
  content: "\e728"; }

.icon-close-circle:before {
  content: "\e729"; }

.icon-close-network:before {
  content: "\e72a"; }

.icon-close:before {
  content: "\e72b"; }

.icon-closed-caption:before {
  content: "\e72c"; }

.icon-cloud-check:before {
  content: "\e72d"; }

.icon-cloud-circle:before {
  content: "\e72e"; }

.icon-cloud-download:before {
  content: "\e72f"; }

.icon-cloud-outline-off:before {
  content: "\e730"; }

.icon-cloud-outline:before {
  content: "\e731"; }

.icon-cloud-upload:before {
  content: "\e732"; }

.icon-cloud:before {
  content: "\e733"; }

.icon-code-array:before {
  content: "\e734"; }

.icon-code-braces:before {
  content: "\e735"; }

.icon-code-equal:before {
  content: "\e736"; }

.icon-code-greater-than-or-equal:before {
  content: "\e737"; }

.icon-code-greater-than:before {
  content: "\e738"; }

.icon-code-less-than-or-equal:before {
  content: "\e739"; }

.icon-code-less-than:before {
  content: "\e73a"; }

.icon-code-not-equal-variant:before {
  content: "\e73b"; }

.icon-code-not-equal:before {
  content: "\e73c"; }

.icon-code-string:before {
  content: "\e73d"; }

.icon-code-tags:before {
  content: "\e73e"; }

.icon-codepen:before {
  content: "\e73f"; }

.icon-coffee-to-go:before {
  content: "\e740"; }

.icon-coffee:before {
  content: "\e741"; }

.icon-cog-box:before {
  content: "\e742"; }

.icon-cog:before {
  content: "\e743"; }

.icon-coin:before {
  content: "\e744"; }

.icon-color-helper:before {
  content: "\e745"; }

.icon-comment-account-outline:before {
  content: "\e746"; }

.icon-comment-account:before {
  content: "\e747"; }

.icon-comment-alert-outline:before {
  content: "\e748"; }

.icon-comment-alert:before {
  content: "\e749"; }

.icon-comment-check-outline:before {
  content: "\e74a"; }

.icon-comment-check:before {
  content: "\e74b"; }

.icon-comment-multipe-outline:before {
  content: "\e74c"; }

.icon-comment-outline:before {
  content: "\e74d"; }

.icon-comment-plus-outline:before {
  content: "\e74e"; }

.icon-comment-processing-outline:before {
  content: "\e74f"; }

.icon-comment-processing:before {
  content: "\e750"; }

.icon-comment-remove-outline:before {
  content: "\e751"; }

.icon-comment-text-outline:before {
  content: "\e752"; }

.icon-comment-text:before {
  content: "\e753"; }

.icon-comment:before {
  content: "\e754"; }

.icon-compare:before {
  content: "\e755"; }

.icon-compass-outline:before {
  content: "\e756"; }

.icon-compass:before {
  content: "\e757"; }

.icon-console:before {
  content: "\e758"; }

.icon-content-copy:before {
  content: "\e759"; }

.icon-content-cut:before {
  content: "\e75a"; }

.icon-content-duplicate:before {
  content: "\e75b"; }

.icon-content-paste:before {
  content: "\e75c"; }

.icon-content-save-all:before {
  content: "\e75d"; }

.icon-content-save:before {
  content: "\e75e"; }

.icon-contrast-box:before {
  content: "\e75f"; }

.icon-contrast-circle:before {
  content: "\e760"; }

.icon-contrast:before {
  content: "\e761"; }

.icon-controller-xbox:before {
  content: "\e762"; }

.icon-cow:before {
  content: "\e763"; }

.icon-credit-card-multiple:before {
  content: "\e764"; }

.icon-credit-card:before {
  content: "\e765"; }

.icon-crop-free:before {
  content: "\e766"; }

.icon-crop-landscape:before {
  content: "\e767"; }

.icon-crop-portrait:before {
  content: "\e768"; }

.icon-crop-square:before {
  content: "\e769"; }

.icon-crop:before {
  content: "\e76a"; }

.icon-crosshairs-gps:before {
  content: "\e76b"; }

.icon-crosshairs:before {
  content: "\e76c"; }

.icon-crown:before {
  content: "\e76d"; }

.icon-cube-outline:before {
  content: "\e76e"; }

.icon-cube-unfolded:before {
  content: "\e76f"; }

.icon-cube:before {
  content: "\e770"; }

.icon-cup-water:before {
  content: "\e771"; }

.icon-cup:before {
  content: "\e772"; }

.icon-currency-btc:before {
  content: "\e773"; }

.icon-currency-eur:before {
  content: "\e774"; }

.icon-currency-gbp:before {
  content: "\e775"; }

.icon-currency-inr:before {
  content: "\e776"; }

.icon-currency-rub:before {
  content: "\e777"; }

.icon-currency-try:before {
  content: "\e778"; }

.icon-currency-usd:before {
  content: "\e779"; }

.icon-cursor-default-outline:before {
  content: "\e77a"; }

.icon-cursor-default:before {
  content: "\e77b"; }

.icon-cursor-move:before {
  content: "\e77c"; }

.icon-cursor-pointer:before {
  content: "\e77d"; }

.icon-data:before {
  content: "\e77e"; }

.icon-debug-step-into:before {
  content: "\e77f"; }

.icon-debug-step-out:before {
  content: "\e780"; }

.icon-debug-step-over:before {
  content: "\e781"; }

.icon-decimal-decrease:before {
  content: "\e782"; }

.icon-decimal-increase:before {
  content: "\e783"; }

.icon-delete-variant:before {
  content: "\e784"; }

.icon-delete:before {
  content: "\e785"; }

.icon-deskphone:before {
  content: "\e786"; }

.icon-desktop-mac:before {
  content: "\e787"; }

.icon-desktop-tower:before {
  content: "\e788"; }

.icon-details:before {
  content: "\e789"; }

.icon-deviantart:before {
  content: "\e78a"; }

.icon-diamond:before {
  content: "\e78b"; }

.icon-dice-1:before {
  content: "\e78c"; }

.icon-dice-2:before {
  content: "\e78d"; }

.icon-dice-3:before {
  content: "\e78e"; }

.icon-dice-4:before {
  content: "\e78f"; }

.icon-dice-5:before {
  content: "\e790"; }

.icon-dice-6:before {
  content: "\e791"; }

.icon-dice:before {
  content: "\e792"; }

.icon-directions:before {
  content: "\e793"; }

.icon-disk-alert:before {
  content: "\e794"; }

.icon-disqus-outline:before {
  content: "\e795"; }

.icon-disqus:before {
  content: "\e796"; }

.icon-division-box:before {
  content: "\e797"; }

.icon-division:before {
  content: "\e798"; }

.icon-dns:before {
  content: "\e799"; }

.icon-document:before {
  content: "\e79a"; }

.icon-domain:before {
  content: "\e79b"; }

.icon-dots-horizontal:before {
  content: "\e79c"; }

.icon-dots-vertical:before {
  content: "\e79d"; }

.icon-download:before {
  content: "\e79e"; }

.icon-drag-horizontal:before {
  content: "\e79f"; }

.icon-drag-vertical:before {
  content: "\e7a0"; }

.icon-drag:before {
  content: "\e7a1"; }

.icon-drawing-box:before {
  content: "\e7a2"; }

.icon-drawing:before {
  content: "\e7a3"; }

.icon-dribbble-box:before {
  content: "\e7a4"; }

.icon-dribbble:before {
  content: "\e7a5"; }

.icon-drone:before {
  content: "\e7a6"; }

.icon-dropbox:before {
  content: "\e7a7"; }

.icon-drupal:before {
  content: "\e7a8"; }

.icon-duck:before {
  content: "\e7a9"; }

.icon-dumbbell:before {
  content: "\e7aa"; }

.icon-earth-off:before {
  content: "\e7ab"; }

.icon-earth:before {
  content: "\e7ac"; }

.icon-ebay:before {
  content: "\e7ad"; }

.icon-edge:before {
  content: "\e7ae"; }

.icon-eject:before {
  content: "\e7af"; }

.icon-elevation-decline:before {
  content: "\e7b0"; }

.icon-elevation-rise:before {
  content: "\e7b1"; }

.icon-elevator:before {
  content: "\e7b2"; }

.icon-email-open:before {
  content: "\e7b3"; }

.icon-email-secure:before {
  content: "\e7b4"; }

.icon-email:before {
  content: "\e7b5"; }

.icon-emoticon-cool:before {
  content: "\e7b6"; }

.icon-emoticon-devil:before {
  content: "\e7b7"; }

.icon-emoticon-happy:before {
  content: "\e7b8"; }

.icon-emoticon-neutral:before {
  content: "\e7b9"; }

.icon-emoticon-poop:before {
  content: "\e7ba"; }

.icon-emoticon-sad:before {
  content: "\e7bb"; }

.icon-emoticon-tongue:before {
  content: "\e7bc"; }

.icon-emoticon:before {
  content: "\e7bd"; }

.icon-engine-outline:before {
  content: "\e7be"; }

.icon-engine:before {
  content: "\e7bf"; }

.icon-equal-box:before {
  content: "\e7c0"; }

.icon-equal:before {
  content: "\e7c1"; }

.icon-eraser:before {
  content: "\e7c2"; }

.icon-escalator:before {
  content: "\e7c3"; }

.icon-etsy:before {
  content: "\e7c4"; }

.icon-evernote:before {
  content: "\e7c5"; }

.icon-exclamation:before {
  content: "\e7c6"; }

.icon-exit-to-app:before {
  content: "\e7c7"; }

.icon-export:before {
  content: "\e7c8"; }

.icon-eye-off:before {
  content: "\e7c9"; }

.icon-eye:before {
  content: "\e7ca"; }

.icon-eyedropper-variant:before {
  content: "\e7cb"; }

.icon-eyedropper:before {
  content: "\e7cc"; }

.icon-facebook-box:before {
  content: "\e7cd"; }

.icon-facebook:before {
  content: "\e7ce"; }

.icon-factory:before {
  content: "\e7cf"; }

.icon-fan:before {
  content: "\e7d0"; }

.icon-fast-forward:before {
  content: "\e7d1"; }

.icon-ferry:before {
  content: "\e7d2"; }

.icon-file-cloud:before {
  content: "\e7d3"; }

.icon-file-delimited:before {
  content: "\e7d4"; }

.icon-file-document-box:before {
  content: "\e7d5"; }

.icon-file-document:before {
  content: "\e7d6"; }

.icon-file-excel-box:before {
  content: "\e7d7"; }

.icon-file-excel:before {
  content: "\e7d8"; }

.icon-file-find:before {
  content: "\e7d9"; }

.icon-file-hidden:before {
  content: "\e7da"; }

.icon-file-image-box:before {
  content: "\e7db"; }

.icon-file-image:before {
  content: "\e7dc"; }

.icon-file-multiple:before {
  content: "\e7dd"; }

.icon-file-music:before {
  content: "\e7de"; }

.icon-file-outline:before {
  content: "\e7df"; }

.icon-file-pdf-box:before {
  content: "\e7e0"; }

.icon-file-pdf:before {
  content: "\e7e1"; }

.icon-file-powerpoint-box:before {
  content: "\e7e2"; }

.icon-file-powerpoint:before {
  content: "\e7e3"; }

.icon-file-presentation-box:before {
  content: "\e7e4"; }

.icon-file-video:before {
  content: "\e7e5"; }

.icon-file-word-box:before {
  content: "\e7e6"; }

.icon-file-word:before {
  content: "\e7e7"; }

.icon-file-xml:before {
  content: "\e7e8"; }

.icon-file:before {
  content: "\e7e9"; }

.icon-fill:before {
  content: "\e7ea"; }

.icon-film:before {
  content: "\e7eb"; }

.icon-filmstrip-off:before {
  content: "\e7ec"; }

.icon-filmstrip:before {
  content: "\e7ed"; }

.icon-filter-outline:before {
  content: "\e7ee"; }

.icon-filter-remove-outline:before {
  content: "\e7ef"; }

.icon-filter-remove:before {
  content: "\e7f0"; }

.icon-filter-variant:before {
  content: "\e7f1"; }

.icon-filter:before {
  content: "\e7f2"; }

.icon-fire:before {
  content: "\e7f3"; }

.icon-fish:before {
  content: "\e7f4"; }

.icon-flag-checkered-variant:before {
  content: "\e7f5"; }

.icon-flag-checkered:before {
  content: "\e7f6"; }

.icon-flag-outline-variant:before {
  content: "\e7f7"; }

.icon-flag-outline:before {
  content: "\e7f8"; }

.icon-flag-triangle:before {
  content: "\e7f9"; }

.icon-flag-variant:before {
  content: "\e7fa"; }

.icon-flag:before {
  content: "\e7fb"; }

.icon-flash-auto:before {
  content: "\e7fc"; }

.icon-flash-off:before {
  content: "\e7fd"; }

.icon-flash:before {
  content: "\e7fe"; }

.icon-flashlight-off:before {
  content: "\e7ff"; }

.icon-flashlight:before {
  content: "\e800"; }

.icon-flattr:before {
  content: "\e801"; }

.icon-flickr-after:before {
  content: "\e802"; }

.icon-flickr-before:before {
  content: "\e803"; }

.icon-flip-to-back:before {
  content: "\e804"; }

.icon-flip-to-front:before {
  content: "\e805"; }

.icon-floppy:before {
  content: "\e806"; }

.icon-flower:before {
  content: "\e807"; }

.icon-folder-account:before {
  content: "\e808"; }

.icon-folder-download:before {
  content: "\e809"; }

.icon-folder-google-drive:before {
  content: "\e80a"; }

.icon-folder-image:before {
  content: "\e80b"; }

.icon-folder-lock-open:before {
  content: "\e80c"; }

.icon-folder-lock:before {
  content: "\e80d"; }

.icon-folder-move:before {
  content: "\e80e"; }

.icon-folder-multiple-image:before {
  content: "\e80f"; }

.icon-folder-multiple-outline:before {
  content: "\e810"; }

.icon-folder-multiple:before {
  content: "\e811"; }

.icon-folder-outline-lock:before {
  content: "\e812"; }

.icon-folder-outline:before {
  content: "\e813"; }

.icon-folder-plus:before {
  content: "\e814"; }

.icon-folder-remove:before {
  content: "\e815"; }

.icon-folder-upload:before {
  content: "\e816"; }

.icon-folder:before {
  content: "\e817"; }

.icon-food-apple:before {
  content: "\e818"; }

.icon-food-variant:before {
  content: "\e819"; }

.icon-food:before {
  content: "\e81a"; }

.icon-football-helmet:before {
  content: "\e81b"; }

.icon-football:before {
  content: "\e81c"; }

.icon-format-align-center:before {
  content: "\e81d"; }

.icon-format-align-justify:before {
  content: "\e81e"; }

.icon-format-align-left:before {
  content: "\e81f"; }

.icon-format-align-right:before {
  content: "\e820"; }

.icon-format-bold:before {
  content: "\e821"; }

.icon-format-clear:before {
  content: "\e822"; }

.icon-format-color-fill:before {
  content: "\e823"; }

.icon-format-color:before {
  content: "\e824"; }

.icon-format-float-center:before {
  content: "\e825"; }

.icon-format-float-left:before {
  content: "\e826"; }

.icon-format-float-none:before {
  content: "\e827"; }

.icon-format-float-right:before {
  content: "\e828"; }

.icon-format-header-1:before {
  content: "\e829"; }

.icon-format-header-2:before {
  content: "\e82a"; }

.icon-format-header-3:before {
  content: "\e82b"; }

.icon-format-header-4:before {
  content: "\e82c"; }

.icon-format-header-5:before {
  content: "\e82d"; }

.icon-format-header-6:before {
  content: "\e82e"; }

.icon-format-header-decrease:before {
  content: "\e82f"; }

.icon-format-header-down:before {
  content: "\e830"; }

.icon-format-header-equal:before {
  content: "\e831"; }

.icon-format-header-increase:before {
  content: "\e832"; }

.icon-format-header-pound:before {
  content: "\e833"; }

.icon-format-header-up:before {
  content: "\e834"; }

.icon-format-indent-decrease:before {
  content: "\e835"; }

.icon-format-indent-increase:before {
  content: "\e836"; }

.icon-format-italic:before {
  content: "\e837"; }

.icon-format-line-spacing:before {
  content: "\e838"; }

.icon-format-list-bulleted:before {
  content: "\e839"; }

.icon-format-list-numbers:before {
  content: "\e83a"; }

.icon-format-object-inline:before {
  content: "\e83b"; }

.icon-format-object-square:before {
  content: "\e83c"; }

.icon-format-object-tight:before {
  content: "\e83d"; }

.icon-format-object-top-bottom:before {
  content: "\e83e"; }

.icon-format-paint:before {
  content: "\e83f"; }

.icon-format-paragraph:before {
  content: "\e840"; }

.icon-format-quote:before {
  content: "\e841"; }

.icon-format-size:before {
  content: "\e842"; }

.icon-format-strikethrough:before {
  content: "\e843"; }

.icon-format-subscript:before {
  content: "\e844"; }

.icon-format-superscript:before {
  content: "\e845"; }

.icon-format-text:before {
  content: "\e846"; }

.icon-format-textdirection-l-to-r:before {
  content: "\e847"; }

.icon-format-textdirection-r-to-l:before {
  content: "\e848"; }

.icon-format-underline:before {
  content: "\e849"; }

.icon-forum:before {
  content: "\e84a"; }

.icon-forward:before {
  content: "\e84b"; }

.icon-foursquare:before {
  content: "\e84c"; }

.icon-fridge:before {
  content: "\e84d"; }

.icon-fullscreen-exit:before {
  content: "\e84e"; }

.icon-fullscreen:before {
  content: "\e84f"; }

.icon-function:before {
  content: "\e850"; }

.icon-gamepad-variant:before {
  content: "\e851"; }

.icon-gamepad:before {
  content: "\e852"; }

.icon-gas-station:before {
  content: "\e853"; }

.icon-gavel:before {
  content: "\e854"; }

.icon-gender-female:before {
  content: "\e855"; }

.icon-gender-male-female:before {
  content: "\e856"; }

.icon-gender-male:before {
  content: "\e857"; }

.icon-gender-transgender:before {
  content: "\e858"; }

.icon-gift:before {
  content: "\e859"; }

.icon-git:before {
  content: "\e85a"; }

.icon-github-box:before {
  content: "\e85b"; }

.icon-github:before {
  content: "\e85c"; }

.icon-glass-flute:before {
  content: "\e85d"; }

.icon-glass-mug:before {
  content: "\e85e"; }

.icon-glass-stange:before {
  content: "\e85f"; }

.icon-glass-tulip:before {
  content: "\e860"; }

.icon-glasses:before {
  content: "\e861"; }

.icon-gmail:before {
  content: "\e862"; }

.icon-google-chrome:before {
  content: "\e863"; }

.icon-google-circles-communities:before {
  content: "\e864"; }

.icon-google-circles-extended:before {
  content: "\e865"; }

.icon-google-circles-invite:before {
  content: "\e866"; }

.icon-google-circles:before {
  content: "\e867"; }

.icon-google-controller-off:before {
  content: "\e868"; }

.icon-google-controller:before {
  content: "\e869"; }

.icon-google-drive:before {
  content: "\e86a"; }

.icon-google-earth:before {
  content: "\e86b"; }

.icon-google-glass:before {
  content: "\e86c"; }

.icon-google-maps:before {
  content: "\e86d"; }

.icon-google-pages:before {
  content: "\e86e"; }

.icon-google-play:before {
  content: "\e86f"; }

.icon-google-plus-box:before {
  content: "\e870"; }

.icon-google-plus:before {
  content: "\e871"; }

.icon-google:before {
  content: "\e872"; }

.icon-grid-off:before {
  content: "\e873"; }

.icon-grid:before {
  content: "\e874"; }

.icon-group:before {
  content: "\e875"; }

.icon-hand-pointing-right:before {
  content: "\e876"; }

.icon-hanger:before {
  content: "\e877"; }

.icon-hangouts:before {
  content: "\e878"; }

.icon-harddisk:before {
  content: "\e879"; }

.icon-headphones-box:before {
  content: "\e87a"; }

.icon-headphones-dots:before {
  content: "\e87b"; }

.icon-headphones:before {
  content: "\e87c"; }

.icon-headset-dock:before {
  content: "\e87d"; }

.icon-headset-off:before {
  content: "\e87e"; }

.icon-headset:before {
  content: "\e87f"; }

.icon-heart-box-outline:before {
  content: "\e880"; }

.icon-heart-box:before {
  content: "\e881"; }

.icon-heart-broken:before {
  content: "\e882"; }

.icon-heart-outline:before {
  content: "\e883"; }

.icon-heart:before {
  content: "\e884"; }

.icon-help-circle:before {
  content: "\e885"; }

.icon-help:before {
  content: "\e886"; }

.icon-hexagon-outline:before {
  content: "\e887"; }

.icon-hexagon:before {
  content: "\e888"; }

.icon-history:before {
  content: "\e889"; }

.icon-hololens:before {
  content: "\e88a"; }

.icon-home-modern:before {
  content: "\e88b"; }

.icon-home-variant:before {
  content: "\e88c"; }

.icon-home:before {
  content: "\e88d"; }

.icon-hops:before {
  content: "\e88e"; }

.icon-hospital-building:before {
  content: "\e88f"; }

.icon-hospital-marker:before {
  content: "\e890"; }

.icon-hospital:before {
  content: "\e891"; }

.icon-hotel:before {
  content: "\e892"; }

.icon-houzz-box:before {
  content: "\e893"; }

.icon-human-child:before {
  content: "\e894"; }

.icon-human-male-female:before {
  content: "\e895"; }

.icon-human:before {
  content: "\e896"; }

.icon-image-album:before {
  content: "\e897"; }

.icon-image-area-close:before {
  content: "\e898"; }

.icon-image-area:before {
  content: "\e899"; }

.icon-image-broken:before {
  content: "\e89a"; }

.icon-image-filter-black-white:before {
  content: "\e89b"; }

.icon-image-filter-center-focus:before {
  content: "\e89c"; }

.icon-image-filter-drama:before {
  content: "\e89d"; }

.icon-image-filter-frames:before {
  content: "\e89e"; }

.icon-image-filter-hdr:before {
  content: "\e89f"; }

.icon-image-filter-none:before {
  content: "\e8a0"; }

.icon-image-filter-tilt-shift:before {
  content: "\e8a1"; }

.icon-image-filter-vintage:before {
  content: "\e8a2"; }

.icon-image-filter:before {
  content: "\e8a3"; }

.icon-image:before {
  content: "\e8a4"; }

.icon-import:before {
  content: "\e8a5"; }

.icon-inbox:before {
  content: "\e8a6"; }

.icon-indent:before {
  content: "\e8a7"; }

.icon-information-outline:before {
  content: "\e8a8"; }

.icon-information:before {
  content: "\e8a9"; }

.icon-instagram:before {
  content: "\e8aa"; }

.icon-instapaper:before {
  content: "\e8ab"; }

.icon-internet-explorer:before {
  content: "\e8ac"; }

.icon-invert-colors:before {
  content: "\e8ad"; }

.icon-jira:before {
  content: "\e8ae"; }

.icon-jsfiddle:before {
  content: "\e8af"; }

.icon-keg:before {
  content: "\e8b0"; }

.icon-key-change:before {
  content: "\e8b1"; }

.icon-key-minus:before {
  content: "\e8b2"; }

.icon-key-plus:before {
  content: "\e8b3"; }

.icon-key-remove:before {
  content: "\e8b4"; }

.icon-key-variant:before {
  content: "\e8b5"; }

.icon-key:before {
  content: "\e8b6"; }

.icon-keyboard-backspace:before {
  content: "\e8b7"; }

.icon-keyboard-caps:before {
  content: "\e8b8"; }

.icon-keyboard-close:before {
  content: "\e8b9"; }

.icon-keyboard-off:before {
  content: "\e8ba"; }

.icon-keyboard-return:before {
  content: "\e8bb"; }

.icon-keyboard-tab:before {
  content: "\e8bc"; }

.icon-keyboard-variant:before {
  content: "\e8bd"; }

.icon-keyboard:before {
  content: "\e8be"; }

.icon-label-outline:before {
  content: "\e8bf"; }

.icon-label:before {
  content: "\e8c0"; }

.icon-language-csharp:before {
  content: "\e8c1"; }

.icon-language-css3:before {
  content: "\e8c2"; }

.icon-language-html5:before {
  content: "\e8c3"; }

.icon-language-javascript:before {
  content: "\e8c4"; }

.icon-language-python-text:before {
  content: "\e8c5"; }

.icon-language-python:before {
  content: "\e8c6"; }

.icon-laptop-chromebook:before {
  content: "\e8c7"; }

.icon-laptop-mac:before {
  content: "\e8c8"; }

.icon-laptop-windows:before {
  content: "\e8c9"; }

.icon-laptop:before {
  content: "\e8ca"; }

.icon-lastfm:before {
  content: "\e8cb"; }

.icon-launch:before {
  content: "\e8cc"; }

.icon-layers-off:before {
  content: "\e8cd"; }

.icon-layers:before {
  content: "\e8ce"; }

.icon-leaf:before {
  content: "\e8cf"; }

.icon-library-books:before {
  content: "\e8d0"; }

.icon-library-music:before {
  content: "\e8d1"; }

.icon-library-plus:before {
  content: "\e8d2"; }

.icon-library:before {
  content: "\e8d3"; }

.icon-lightbulb-outline:before {
  content: "\e8d4"; }

.icon-lightbulb:before {
  content: "\e8d5"; }

.icon-link-off:before {
  content: "\e8d6"; }

.icon-link-variant-off:before {
  content: "\e8d7"; }

.icon-link-variant:before {
  content: "\e8d8"; }

.icon-link:before {
  content: "\e8d9"; }

.icon-linkedin-box:before {
  content: "\e8da"; }

.icon-linkedin:before {
  content: "\e8db"; }

.icon-linode:before {
  content: "\e8dc"; }

.icon-linux:before {
  content: "\e8dd"; }

.icon-lock-outline:before {
  content: "\e8de"; }

.icon-lock-unlocked-outline:before {
  content: "\e8df"; }

.icon-lock-unlocked:before {
  content: "\e8e0"; }

.icon-lock:before {
  content: "\e8e1"; }

.icon-login:before {
  content: "\e8e2"; }

.icon-logout:before {
  content: "\e8e3"; }

.icon-looks:before {
  content: "\e8e4"; }

.icon-loupe:before {
  content: "\e8e5"; }

.icon-lumx:before {
  content: "\e8e6"; }

.icon-magnet-on:before {
  content: "\e8e7"; }

.icon-magnet:before {
  content: "\e8e8"; }

.icon-magnify-minus:before {
  content: "\e8e9"; }

.icon-magnify-plus:before {
  content: "\e8ea"; }

.icon-magnify:before {
  content: "\e8eb"; }

.icon-mail-ru:before {
  content: "\e8ec"; }

.icon-map-marker-circle:before {
  content: "\e8ed"; }

.icon-map-marker-multiple:before {
  content: "\e8ee"; }

.icon-map-marker-off:before {
  content: "\e8ef"; }

.icon-map-marker-radius:before {
  content: "\e8f0"; }

.icon-map-marker:before {
  content: "\e8f1"; }

.icon-map:before {
  content: "\e8f2"; }

.icon-margin:before {
  content: "\e8f3"; }

.icon-markdown:before {
  content: "\e8f4"; }

.icon-marker-check:before {
  content: "\e8f5"; }

.icon-martini:before {
  content: "\e8f6"; }

.icon-material-ui:before {
  content: "\e8f7"; }

.icon-maxcdn:before {
  content: "\e8f8"; }

.icon-medium:before {
  content: "\e8f9"; }

.icon-memory:before {
  content: "\e8fa"; }

.icon-menu-down:before {
  content: "\e8fb"; }

.icon-menu-left:before {
  content: "\e8fc"; }

.icon-menu-right:before {
  content: "\e8fd"; }

.icon-menu-up:before {
  content: "\e8fe"; }

.icon-menu:before {
  content: "\e8ff"; }

.icon-message-alert:before {
  content: "\e900"; }

.icon-message-draw:before {
  content: "\e901"; }

.icon-message-image:before {
  content: "\e902"; }

.icon-message-processing:before {
  content: "\e903"; }

.icon-message-reply:before {
  content: "\e904"; }

.icon-message-text-outline:before {
  content: "\e905"; }

.icon-message-text:before {
  content: "\e906"; }

.icon-message-video:before {
  content: "\e907"; }

.icon-message:before {
  content: "\e908"; }

.icon-microphone-dots:before {
  content: "\e909"; }

.icon-microphone-off:before {
  content: "\e90a"; }

.icon-microphone-outline:before {
  content: "\e90b"; }

.icon-microphone-settings:before {
  content: "\e90c"; }

.icon-microphone-variant-off:before {
  content: "\e90d"; }

.icon-microphone-variant:before {
  content: "\e90e"; }

.icon-microphone:before {
  content: "\e90f"; }

.icon-minus-box:before {
  content: "\e910"; }

.icon-minus-circle-outline:before {
  content: "\e911"; }

.icon-minus-circle:before {
  content: "\e912"; }

.icon-minus-network:before {
  content: "\e913"; }

.icon-minus:before {
  content: "\e914"; }

.icon-monitor-multiple:before {
  content: "\e915"; }

.icon-monitor:before {
  content: "\e916"; }

.icon-more:before {
  content: "\e917"; }

.icon-mouse-off:before {
  content: "\e918"; }

.icon-mouse-variant-off:before {
  content: "\e919"; }

.icon-mouse-variant:before {
  content: "\e91a"; }

.icon-mouse:before {
  content: "\e91b"; }

.icon-movie:before {
  content: "\e91c"; }

.icon-multiplication-box:before {
  content: "\e91d"; }

.icon-multiplication:before {
  content: "\e91e"; }

.icon-music-box-outline:before {
  content: "\e91f"; }

.icon-music-box:before {
  content: "\e920"; }

.icon-music-circle:before {
  content: "\e921"; }

.icon-music-note-eighth:before {
  content: "\e922"; }

.icon-music-note-half:before {
  content: "\e923"; }

.icon-music-note-off:before {
  content: "\e924"; }

.icon-music-note-quarter:before {
  content: "\e925"; }

.icon-music-note-sixteenth:before {
  content: "\e926"; }

.icon-music-note-whole:before {
  content: "\e927"; }

.icon-music-note:before {
  content: "\e928"; }

.icon-nature-people:before {
  content: "\e929"; }

.icon-nature:before {
  content: "\e92a"; }

.icon-navigation:before {
  content: "\e92b"; }

.icon-needle:before {
  content: "\e92c"; }

.icon-nest-protect:before {
  content: "\e92d"; }

.icon-nest-thermostat:before {
  content: "\e92e"; }

.icon-newspaper:before {
  content: "\e92f"; }

.icon-nfc-tap:before {
  content: "\e930"; }

.icon-nfc-variant:before {
  content: "\e931"; }

.icon-nfc:before {
  content: "\e932"; }

.icon-no:before {
  content: "\e933"; }

.icon-not-equal:before {
  content: "\e934"; }

.icon-note-outline:before {
  content: "\e935"; }

.icon-note-text:before {
  content: "\e936"; }

.icon-note:before {
  content: "\e937"; }

.icon-numeric-0-box-multiple-outline:before {
  content: "\e938"; }

.icon-numeric-0-box-outline:before {
  content: "\e939"; }

.icon-numeric-0-box:before {
  content: "\e93a"; }

.icon-numeric-1-box-multiple-outline:before {
  content: "\e93b"; }

.icon-numeric-1-box-outline:before {
  content: "\e93c"; }

.icon-numeric-1-box:before {
  content: "\e93d"; }

.icon-numeric-2-box-multiple-outline:before {
  content: "\e93e"; }

.icon-numeric-2-box-outline:before {
  content: "\e93f"; }

.icon-numeric-2-box:before {
  content: "\e940"; }

.icon-numeric-3-box-multiple-outline:before {
  content: "\e941"; }

.icon-numeric-3-box-outline:before {
  content: "\e942"; }

.icon-numeric-3-box:before {
  content: "\e943"; }

.icon-numeric-4-box-multiple-outline:before {
  content: "\e944"; }

.icon-numeric-4-box-outline:before {
  content: "\e945"; }

.icon-numeric-4-box:before {
  content: "\e946"; }

.icon-numeric-5-box-multiple-outline:before {
  content: "\e947"; }

.icon-numeric-5-box-outline:before {
  content: "\e948"; }

.icon-numeric-5-box:before {
  content: "\e949"; }

.icon-numeric-6-box-multiple-outline:before {
  content: "\e94a"; }

.icon-numeric-6-box-outline:before {
  content: "\e94b"; }

.icon-numeric-6-box:before {
  content: "\e94c"; }

.icon-numeric-7-box-multiple-outline:before {
  content: "\e94d"; }

.icon-numeric-7-box-outline:before {
  content: "\e94e"; }

.icon-numeric-7-box:before {
  content: "\e94f"; }

.icon-numeric-8-box-multiple-outline:before {
  content: "\e950"; }

.icon-numeric-8-box-outline:before {
  content: "\e951"; }

.icon-numeric-8-box:before {
  content: "\e952"; }

.icon-numeric-9-box-multiple-outline:before {
  content: "\e953"; }

.icon-numeric-9-box-outline:before {
  content: "\e954"; }

.icon-numeric-9-box:before {
  content: "\e955"; }

.icon-numeric-9-plus-box-multiple-outline:before {
  content: "\e956"; }

.icon-numeric-9-plus-box-outline:before {
  content: "\e957"; }

.icon-numeric-9-plus-box:before {
  content: "\e958"; }

.icon-numeric:before {
  content: "\e959"; }

.icon-nutriton:before {
  content: "\e95a"; }

.icon-odnoklassniki:before {
  content: "\e95b"; }

.icon-office:before {
  content: "\e95c"; }

.icon-oil:before {
  content: "\e95d"; }

.icon-omega:before {
  content: "\e95e"; }

.icon-onedrive:before {
  content: "\e95f"; }

.icon-open-in-app:before {
  content: "\e960"; }

.icon-open-in-new:before {
  content: "\e961"; }

.icon-ornament-variant:before {
  content: "\e962"; }

.icon-ornament:before {
  content: "\e963"; }

.icon-outbox:before {
  content: "\e964"; }

.icon-outdent:before {
  content: "\e965"; }

.icon-owl:before {
  content: "\e966"; }

.icon-package-down:before {
  content: "\e967"; }

.icon-package-up:before {
  content: "\e968"; }

.icon-package-variant-closed:before {
  content: "\e969"; }

.icon-package-variant:before {
  content: "\e96a"; }

.icon-package:before {
  content: "\e96b"; }

.icon-palette-advanced:before {
  content: "\e96c"; }

.icon-palette:before {
  content: "\e96d"; }

.icon-panda:before {
  content: "\e96e"; }

.icon-pandora:before {
  content: "\e96f"; }

.icon-panorama-fisheye:before {
  content: "\e970"; }

.icon-panorama-horizontal:before {
  content: "\e971"; }

.icon-panorama-vertical:before {
  content: "\e972"; }

.icon-panorama-wide-angle:before {
  content: "\e973"; }

.icon-panorama:before {
  content: "\e974"; }

.icon-paper-cut-vertical:before {
  content: "\e975"; }

.icon-paperclip:before {
  content: "\e976"; }

.icon-parking:before {
  content: "\e977"; }

.icon-pause-circle-outline:before {
  content: "\e978"; }

.icon-pause-circle:before {
  content: "\e979"; }

.icon-pause-octagon-outline:before {
  content: "\e97a"; }

.icon-pause-octagon:before {
  content: "\e97b"; }

.icon-pause:before {
  content: "\e97c"; }

.icon-paw:before {
  content: "\e97d"; }

.icon-pen:before {
  content: "\e97e"; }

.icon-pencil-box-outline:before {
  content: "\e97f"; }

.icon-pencil-box:before {
  content: "\e980"; }

.icon-pencil:before {
  content: "\e981"; }

.icon-people:before {
  content: "\e982"; }

.icon-percent:before {
  content: "\e983"; }

.icon-person-box:before {
  content: "\e984"; }

.icon-person-minus:before {
  content: "\e985"; }

.icon-person-plus:before {
  content: "\e986"; }

.icon-pharmacy:before {
  content: "\e987"; }

.icon-phone-bluetooth:before {
  content: "\e988"; }

.icon-phone-dots:before {
  content: "\e989"; }

.icon-phone-forward:before {
  content: "\e98a"; }

.icon-phone-hangup:before {
  content: "\e98b"; }

.icon-phone-in-talk:before {
  content: "\e98c"; }

.icon-phone-incoming:before {
  content: "\e98d"; }

.icon-phone-locked:before {
  content: "\e98e"; }

.icon-phone-log:before {
  content: "\e98f"; }

.icon-phone-missed:before {
  content: "\e990"; }

.icon-phone-outgoing:before {
  content: "\e991"; }

.icon-phone-paused:before {
  content: "\e992"; }

.icon-phone:before {
  content: "\e993"; }

.icon-picture:before {
  content: "\e994"; }

.icon-pig:before {
  content: "\e995"; }

.icon-pill:before {
  content: "\e996"; }

.icon-pin-off:before {
  content: "\e997"; }

.icon-pin:before {
  content: "\e998"; }

.icon-pine-tree-box:before {
  content: "\e999"; }

.icon-pine-tree:before {
  content: "\e99a"; }

.icon-pinterest-box:before {
  content: "\e99b"; }

.icon-pinterest:before {
  content: "\e99c"; }

.icon-pizza:before {
  content: "\e99d"; }

.icon-plane:before {
  content: "\e99e"; }

.icon-play-box-outline:before {
  content: "\e99f"; }

.icon-play-circle-outline:before {
  content: "\e9a0"; }

.icon-play-circle:before {
  content: "\e9a1"; }

.icon-play:before {
  content: "\e9a2"; }

.icon-playlist-minus:before {
  content: "\e9a3"; }

.icon-playlist-plus:before {
  content: "\e9a4"; }

.icon-playstation:before {
  content: "\e9a5"; }

.icon-plus-box:before {
  content: "\e9a6"; }

.icon-plus-circle-outline:before {
  content: "\e9a7"; }

.icon-plus-circle:before {
  content: "\e9a8"; }

.icon-plus-network:before {
  content: "\e9a9"; }

.icon-plus-one:before {
  content: "\e9aa"; }

.icon-plus:before {
  content: "\e9ab"; }

.icon-pocket:before {
  content: "\e9ac"; }

.icon-poll-box:before {
  content: "\e9ad"; }

.icon-poll:before {
  content: "\e9ae"; }

.icon-polymer:before {
  content: "\e9af"; }

.icon-popcorn:before {
  content: "\e9b0"; }

.icon-pound-box:before {
  content: "\e9b1"; }

.icon-pound:before {
  content: "\e9b2"; }

.icon-power-settings:before {
  content: "\e9b3"; }

.icon-power-socket:before {
  content: "\e9b4"; }

.icon-power:before {
  content: "\e9b5"; }

.icon-presentation-play:before {
  content: "\e9b6"; }

.icon-presentation:before {
  content: "\e9b7"; }

.icon-printer-3d:before {
  content: "\e9b8"; }

.icon-printer:before {
  content: "\e9b9"; }

.icon-pulse:before {
  content: "\e9ba"; }

.icon-puzzle:before {
  content: "\e9bb"; }

.icon-qrcode:before {
  content: "\e9bc"; }

.icon-quadcopter:before {
  content: "\e9bd"; }

.icon-quality-high:before {
  content: "\e9be"; }

.icon-question-mark-circle:before {
  content: "\e9bf"; }

.icon-quick-reply:before {
  content: "\e9c0"; }

.icon-quicktime:before {
  content: "\e9c1"; }

.icon-rabbit:before {
  content: "\e9c2"; }

.icon-radiator:before {
  content: "\e9c3"; }

.icon-radio-tower:before {
  content: "\e9c4"; }

.icon-radio:before {
  content: "\e9c5"; }

.icon-radioactive:before {
  content: "\e9c6"; }

.icon-radiobox-blank:before {
  content: "\e9c7"; }

.icon-radiobox-marked:before {
  content: "\e9c8"; }

.icon-raspberrypi:before {
  content: "\e9c9"; }

.icon-rdio:before {
  content: "\e9ca"; }

.icon-read:before {
  content: "\e9cb"; }

.icon-readability:before {
  content: "\e9cc"; }

.icon-receipt:before {
  content: "\e9cd"; }

.icon-recycle:before {
  content: "\e9ce"; }

.icon-reddit:before {
  content: "\e9cf"; }

.icon-redo-variant:before {
  content: "\e9d0"; }

.icon-redo:before {
  content: "\e9d1"; }

.icon-refresh:before {
  content: "\e9d2"; }

.icon-relative-scale:before {
  content: "\e9d3"; }

.icon-reload:before {
  content: "\e9d4"; }

.icon-remote:before {
  content: "\e9d5"; }

.icon-rename-box:before {
  content: "\e9d6"; }

.icon-repeat-off:before {
  content: "\e9d7"; }

.icon-repeat-once:before {
  content: "\e9d8"; }

.icon-repeat:before {
  content: "\e9d9"; }

.icon-replay:before {
  content: "\e9da"; }

.icon-reply-all:before {
  content: "\e9db"; }

.icon-reply:before {
  content: "\e9dc"; }

.icon-reproduction:before {
  content: "\e9dd"; }

.icon-resize-bottom-right:before {
  content: "\e9de"; }

.icon-responsive:before {
  content: "\e9df"; }

.icon-restore:before {
  content: "\e9e0"; }

.icon-rewind:before {
  content: "\e9e1"; }

.icon-ribbon:before {
  content: "\e9e2"; }

.icon-road:before {
  content: "\e9e3"; }

.icon-rocket:before {
  content: "\e9e4"; }

.icon-rotate-3d:before {
  content: "\e9e5"; }

.icon-rotate-left-variant:before {
  content: "\e9e6"; }

.icon-rotate-left:before {
  content: "\e9e7"; }

.icon-rotate-right-variant:before {
  content: "\e9e8"; }

.icon-rotate-right:before {
  content: "\e9e9"; }

.icon-routes:before {
  content: "\e9ea"; }

.icon-rss-box:before {
  content: "\e9eb"; }

.icon-ruler:before {
  content: "\e9ec"; }

.icon-run:before {
  content: "\e9ed"; }

.icon-sale:before {
  content: "\e9ee"; }

.icon-satellite-variant:before {
  content: "\e9ef"; }

.icon-satellite:before {
  content: "\e9f0"; }

.icon-scale-bathroom:before {
  content: "\e9f1"; }

.icon-scale:before {
  content: "\e9f2"; }

.icon-school:before {
  content: "\e9f3"; }

.icon-screen-rotation-lock:before {
  content: "\e9f4"; }

.icon-screen-rotation:before {
  content: "\e9f5"; }

.icon-script:before {
  content: "\e9f6"; }

.icon-security-network:before {
  content: "\e9f7"; }

.icon-security:before {
  content: "\e9f8"; }

.icon-select-all:before {
  content: "\e9f9"; }

.icon-select-inverse:before {
  content: "\e9fa"; }

.icon-select-off:before {
  content: "\e9fb"; }

.icon-select:before {
  content: "\e9fc"; }

.icon-send:before {
  content: "\e9fd"; }

.icon-server-minus:before {
  content: "\e9fe"; }

.icon-server-network-off:before {
  content: "\e9ff"; }

.icon-server-network:before {
  content: "\ea00"; }

.icon-server-off:before {
  content: "\ea01"; }

.icon-server-plus:before {
  content: "\ea02"; }

.icon-server-remove:before {
  content: "\ea03"; }

.icon-server-security:before {
  content: "\ea04"; }

.icon-server:before {
  content: "\ea05"; }

.icon-shape-plus:before {
  content: "\ea06"; }

.icon-share-variant:before {
  content: "\ea07"; }

.icon-share:before {
  content: "\ea08"; }

.icon-shield-outline:before {
  content: "\ea09"; }

.icon-shield:before {
  content: "\ea0a"; }

.icon-shopping-music:before {
  content: "\ea0b"; }

.icon-shopping:before {
  content: "\ea0c"; }

.icon-shuffle:before {
  content: "\ea0d"; }

.icon-sigma:before {
  content: "\ea0e"; }

.icon-sign-caution:before {
  content: "\ea0f"; }

.icon-signal:before {
  content: "\ea10"; }

.icon-silverware-fork:before {
  content: "\ea11"; }

.icon-silverware-spoon:before {
  content: "\ea12"; }

.icon-silverware-variant:before {
  content: "\ea13"; }

.icon-silverware:before {
  content: "\ea14"; }

.icon-sim-alert:before {
  content: "\ea15"; }

.icon-sim:before {
  content: "\ea16"; }

.icon-sitemap:before {
  content: "\ea17"; }

.icon-skip-next:before {
  content: "\ea18"; }

.icon-skip-previous:before {
  content: "\ea19"; }

.icon-skype-business:before {
  content: "\ea1a"; }

.icon-skype:before {
  content: "\ea1b"; }

.icon-sleep-off:before {
  content: "\ea1c"; }

.icon-sleep:before {
  content: "\ea1d"; }

.icon-smoking-off:before {
  content: "\ea1e"; }

.icon-smoking:before {
  content: "\ea1f"; }

.icon-snapchat:before {
  content: "\ea20"; }

.icon-snowman:before {
  content: "\ea21"; }

.icon-sofa:before {
  content: "\ea22"; }

.icon-sort-alphabetical:before {
  content: "\ea23"; }

.icon-sort-ascending:before {
  content: "\ea24"; }

.icon-sort-descending:before {
  content: "\ea25"; }

.icon-sort-numeric:before {
  content: "\ea26"; }

.icon-sort-variant:before {
  content: "\ea27"; }

.icon-sort:before {
  content: "\ea28"; }

.icon-soundcloud:before {
  content: "\ea29"; }

.icon-source-fork:before {
  content: "\ea2a"; }

.icon-source-pull:before {
  content: "\ea2b"; }

.icon-speaker-off:before {
  content: "\ea2c"; }

.icon-speaker:before {
  content: "\ea2d"; }

.icon-speedometer:before {
  content: "\ea2e"; }

.icon-spellcheck:before {
  content: "\ea2f"; }

.icon-spotify:before {
  content: "\ea30"; }

.icon-spotlight-beam:before {
  content: "\ea31"; }

.icon-spotlight:before {
  content: "\ea32"; }

.icon-spreadsheet:before {
  content: "\ea33"; }

.icon-square-inc-cash:before {
  content: "\ea34"; }

.icon-square-inc:before {
  content: "\ea35"; }

.icon-stackoverflow:before {
  content: "\ea36"; }

.icon-star-circle:before {
  content: "\ea37"; }

.icon-star-half:before {
  content: "\ea38"; }

.icon-star-of-david:before {
  content: "\ea39"; }

.icon-star-outline:before {
  content: "\ea3a"; }

.icon-star:before {
  content: "\ea3b"; }

.icon-steam:before {
  content: "\ea3c"; }

.icon-stethoscope:before {
  content: "\ea3d"; }

.icon-stocking:before {
  content: "\ea3e"; }

.icon-stop:before {
  content: "\ea3f"; }

.icon-store-24-hour:before {
  content: "\ea40"; }

.icon-store:before {
  content: "\ea41"; }

.icon-stove:before {
  content: "\ea42"; }

.icon-subway:before {
  content: "\ea43"; }

.icon-sunglasses:before {
  content: "\ea44"; }

.icon-swap-horizontal:before {
  content: "\ea45"; }

.icon-swap-vertical:before {
  content: "\ea46"; }

.icon-swim:before {
  content: "\ea47"; }

.icon-sword:before {
  content: "\ea48"; }

.icon-sync-alert:before {
  content: "\ea49"; }

.icon-sync-off:before {
  content: "\ea4a"; }

.icon-sync:before {
  content: "\ea4b"; }

.icon-tab-unselected:before {
  content: "\ea4c"; }

.icon-tab:before {
  content: "\ea4d"; }

.icon-table-column-plus-after:before {
  content: "\ea4e"; }

.icon-table-column-plus-before:before {
  content: "\ea4f"; }

.icon-table-column-remove:before {
  content: "\ea50"; }

.icon-table-column-width:before {
  content: "\ea51"; }

.icon-table-edit:before {
  content: "\ea52"; }

.icon-table-large:before {
  content: "\ea53"; }

.icon-table-row-height:before {
  content: "\ea54"; }

.icon-table-row-plus-after:before {
  content: "\ea55"; }

.icon-table-row-plus-before:before {
  content: "\ea56"; }

.icon-table-row-remove:before {
  content: "\ea57"; }

.icon-table:before {
  content: "\ea58"; }

.icon-tablet-android:before {
  content: "\ea59"; }

.icon-tablet-ipad:before {
  content: "\ea5a"; }

.icon-tablet:before {
  content: "\ea5b"; }

.icon-tag-faces:before {
  content: "\ea5c"; }

.icon-tag-multiple:before {
  content: "\ea5d"; }

.icon-tag-outline:before {
  content: "\ea5e"; }

.icon-tag-text-outline:before {
  content: "\ea5f"; }

.icon-tag:before {
  content: "\ea60"; }

.icon-taxi:before {
  content: "\ea61"; }

.icon-teamviewer:before {
  content: "\ea62"; }

.icon-telegram:before {
  content: "\ea63"; }

.icon-television-guide:before {
  content: "\ea64"; }

.icon-television:before {
  content: "\ea65"; }

.icon-temperature-celsius:before {
  content: "\ea66"; }

.icon-temperature-fahrenheit:before {
  content: "\ea67"; }

.icon-temperature-kelvin:before {
  content: "\ea68"; }

.icon-tennis:before {
  content: "\ea69"; }

.icon-tent:before {
  content: "\ea6a"; }

.icon-terrain:before {
  content: "\ea6b"; }

.icon-text-to-speech-off:before {
  content: "\ea6c"; }

.icon-text-to-speech:before {
  content: "\ea6d"; }

.icon-texture:before {
  content: "\ea6e"; }

.icon-theater:before {
  content: "\ea6f"; }

.icon-theme-light-dark:before {
  content: "\ea70"; }

.icon-thermometer-lines:before {
  content: "\ea71"; }

.icon-thermometer:before {
  content: "\ea72"; }

.icon-thumb-down-outline:before {
  content: "\ea73"; }

.icon-thumb-down:before {
  content: "\ea74"; }

.icon-thumb-up-outline:before {
  content: "\ea75"; }

.icon-thumb-up:before {
  content: "\ea76"; }

.icon-thumbs-up-down:before {
  content: "\ea77"; }

.icon-ticket-account:before {
  content: "\ea78"; }

.icon-ticket:before {
  content: "\ea79"; }

.icon-tie:before {
  content: "\ea7a"; }

.icon-tile-four:before {
  content: "\ea7b"; }

.icon-timelapse:before {
  content: "\ea7c"; }

.icon-timer-3:before {
  content: "\ea7d"; }

.icon-timer-10:before {
  content: "\ea7e"; }

.icon-timer-off:before {
  content: "\ea7f"; }

.icon-timer-sand:before {
  content: "\ea80"; }

.icon-timer:before {
  content: "\ea81"; }

.icon-timetable:before {
  content: "\ea82"; }

.icon-toggle-switch-off:before {
  content: "\ea83"; }

.icon-toggle-switch-on:before {
  content: "\ea84"; }

.icon-tooltip-edit:before {
  content: "\ea85"; }

.icon-tooltip-image:before {
  content: "\ea86"; }

.icon-tooltip-outline-plus:before {
  content: "\ea87"; }

.icon-tooltip-outline:before {
  content: "\ea88"; }

.icon-tooltip-text:before {
  content: "\ea89"; }

.icon-tooltip:before {
  content: "\ea8a"; }

.icon-tor:before {
  content: "\ea8b"; }

.icon-traffic-light:before {
  content: "\ea8c"; }

.icon-train:before {
  content: "\ea8d"; }

.icon-tram:before {
  content: "\ea8e"; }

.icon-transcribe-close:before {
  content: "\ea8f"; }

.icon-transcribe:before {
  content: "\ea90"; }

.icon-transfer:before {
  content: "\ea91"; }

.icon-trash:before {
  content: "\ea92"; }

.icon-tree:before {
  content: "\ea93"; }

.icon-trello:before {
  content: "\ea94"; }

.icon-trending-down:before {
  content: "\ea95"; }

.icon-trending-neutral:before {
  content: "\ea96"; }

.icon-trending-up:before {
  content: "\ea97"; }

.icon-truck:before {
  content: "\ea98"; }

.icon-tshirt-crew:before {
  content: "\ea99"; }

.icon-tshirt-v:before {
  content: "\ea9a"; }

.icon-tumblr-reblog:before {
  content: "\ea9b"; }

.icon-tumblr:before {
  content: "\ea9c"; }

.icon-twitch:before {
  content: "\ea9d"; }

.icon-twitter-box:before {
  content: "\ea9e"; }

.icon-twitter-circle:before {
  content: "\ea9f"; }

.icon-twitter-retweet:before {
  content: "\eaa0"; }

.icon-twitter:before {
  content: "\eaa1"; }

.icon-ubuntu:before {
  content: "\eaa2"; }

.icon-umbrella-outline:before {
  content: "\eaa3"; }

.icon-umbrella:before {
  content: "\eaa4"; }

.icon-undo-variant:before {
  content: "\eaa5"; }

.icon-undo:before {
  content: "\eaa6"; }

.icon-unfold-less:before {
  content: "\eaa7"; }

.icon-unfold-more:before {
  content: "\eaa8"; }

.icon-ungroup:before {
  content: "\eaa9"; }

.icon-untappd:before {
  content: "\eaaa"; }

.icon-upload:before {
  content: "\eaab"; }

.icon-usb:before {
  content: "\eaac"; }

.icon-vector-curve:before {
  content: "\eaad"; }

.icon-vector-point:before {
  content: "\eaae"; }

.icon-vector-square:before {
  content: "\eaaf"; }

.icon-verified:before {
  content: "\eab0"; }

.icon-vibration:before {
  content: "\eab1"; }

.icon-video-off:before {
  content: "\eab2"; }

.icon-video-switch:before {
  content: "\eab3"; }

.icon-video:before {
  content: "\eab4"; }

.icon-view-agenda:before {
  content: "\eab5"; }

.icon-view-array:before {
  content: "\eab6"; }

.icon-view-carousel:before {
  content: "\eab7"; }

.icon-view-column:before {
  content: "\eab8"; }

.icon-view-dashboard:before {
  content: "\eab9"; }

.icon-view-day:before {
  content: "\eaba"; }

.icon-view-headline:before {
  content: "\eabb"; }

.icon-view-list:before {
  content: "\eabc"; }

.icon-view-module:before {
  content: "\eabd"; }

.icon-view-quilt:before {
  content: "\eabe"; }

.icon-view-stream:before {
  content: "\eabf"; }

.icon-view-week:before {
  content: "\eac0"; }

.icon-vimeo:before {
  content: "\eac1"; }

.icon-vine:before {
  content: "\eac2"; }

.icon-vk-box:before {
  content: "\eac3"; }

.icon-vk-circle:before {
  content: "\eac4"; }

.icon-vk:before {
  content: "\eac5"; }

.icon-voicemail:before {
  content: "\eac6"; }

.icon-volume-high:before {
  content: "\eac7"; }

.icon-volume-low:before {
  content: "\eac8"; }

.icon-volume-medium:before {
  content: "\eac9"; }

.icon-volume-off:before {
  content: "\eaca"; }

.icon-volume:before {
  content: "\eacb"; }

.icon-vpn:before {
  content: "\eacc"; }

.icon-walk:before {
  content: "\eacd"; }

.icon-wallet-giftcard:before {
  content: "\eace"; }

.icon-wallet-membership:before {
  content: "\eacf"; }

.icon-wallet-travel:before {
  content: "\ead0"; }

.icon-wallet:before {
  content: "\ead1"; }

.icon-washing-machine:before {
  content: "\ead2"; }

.icon-watch-export:before {
  content: "\ead3"; }

.icon-watch-import:before {
  content: "\ead4"; }

.icon-watch:before {
  content: "\ead5"; }

.icon-water-off:before {
  content: "\ead6"; }

.icon-water-pump:before {
  content: "\ead7"; }

.icon-water:before {
  content: "\ead8"; }

.icon-weather-cloudy:before {
  content: "\ead9"; }

.icon-weather-fog:before {
  content: "\eada"; }

.icon-weather-hail:before {
  content: "\eadb"; }

.icon-weather-lightning:before {
  content: "\eadc"; }

.icon-weather-night:before {
  content: "\eadd"; }

.icon-weather-partlycloudy:before {
  content: "\eade"; }

.icon-weather-pouring:before {
  content: "\eadf"; }

.icon-weather-rainy:before {
  content: "\eae0"; }

.icon-weather-snowy:before {
  content: "\eae1"; }

.icon-weather-sunny:before {
  content: "\eae2"; }

.icon-weather-sunset-down:before {
  content: "\eae3"; }

.icon-weather-sunset-up:before {
  content: "\eae4"; }

.icon-weather-sunset:before {
  content: "\eae5"; }

.icon-weather-windy-variant:before {
  content: "\eae6"; }

.icon-weather-windy:before {
  content: "\eae7"; }

.icon-web:before {
  content: "\eae8"; }

.icon-webcam:before {
  content: "\eae9"; }

.icon-weight-kilogram:before {
  content: "\eaea"; }

.icon-weight:before {
  content: "\eaeb"; }

.icon-whatsapp:before {
  content: "\eaec"; }

.icon-wheelchair-accessibility:before {
  content: "\eaed"; }

.icon-white-balance-auto:before {
  content: "\eaee"; }

.icon-white-balance-incandescent:before {
  content: "\eaef"; }

.icon-white-balance-irradescent:before {
  content: "\eaf0"; }

.icon-white-balance-sunny:before {
  content: "\eaf1"; }

.icon-wifi:before {
  content: "\eaf2"; }

.icon-wii:before {
  content: "\eaf3"; }

.icon-wikipedia:before {
  content: "\eaf4"; }

.icon-window-close:before {
  content: "\eaf5"; }

.icon-window-closed:before {
  content: "\eaf6"; }

.icon-window-maximize:before {
  content: "\eaf7"; }

.icon-window-minimize:before {
  content: "\eaf8"; }

.icon-window-open:before {
  content: "\eaf9"; }

.icon-window-restore:before {
  content: "\eafa"; }

.icon-windows:before {
  content: "\eafb"; }

.icon-wordpress:before {
  content: "\eafc"; }

.icon-worker:before {
  content: "\eafd"; }

.icon-wunderlist:before {
  content: "\eafe"; }

.icon-xbox-controller-off:before {
  content: "\eaff"; }

.icon-xbox-controller:before {
  content: "\eb00"; }

.icon-xbox:before {
  content: "\eb01"; }

.icon-xda:before {
  content: "\eb02"; }

.icon-xml:before {
  content: "\eb03"; }

.icon-yeast:before {
  content: "\eb04"; }

.icon-yelp:before {
  content: "\eb05"; }

.icon-youtube-play:before {
  content: "\eb06"; }

.icon-youtube:before {
  content: "\eb07"; }

.icon-zip-box:before {
  content: "\eb08"; }

/*-----------------------------------------------------------------------*/
/* Contains custom elements, components and general styles that
/* follows Google(TM) Material Design(TM) specs
/*
/* https://www.google.com/design/spec/material-design/introduction.html
/*-----------------------------------------------------------------------*/
/*----------------------------------------------------------------*/
/*  Avatars
/*----------------------------------------------------------------*/
.avatar {
  width: 40px;
  min-width: 40px;
  height: 40px;
  line-height: 40px;
  margin: 0 8px 0 0;
  border-radius: 50%;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  color: #FFFFFF; }
  .avatar.square {
    border-radius: 0; }
  .avatar.small {
    width: 20px;
    min-width: 20px;
    height: 20px;
    line-height: 20px; }
  .avatar.big {
    width: 72px;
    min-width: 72px;
    height: 72px;
    line-height: 72px; }
  .avatar.huge {
    width: 96px;
    min-width: 96px;
    height: 96px;
    line-height: 96px; }

/*----------------------------------------------------------------*/
/*  Forms
/*----------------------------------------------------------------*/
.form-wrapper {
  background: #FFFFFF;
  padding: 16px; }
  .form-wrapper .form-title {
    font-size: 21px;
    padding: 8px 0; }

/*----------------------------------------------------------------*/
/*  Navigation - Simple
/*----------------------------------------------------------------*/
.navigation-simple .item {
  position: relative;
  cursor: pointer;
  text-align: left;
  margin: 0;
  padding: 0 24px;
  text-transform: none;
  line-height: 48px;
  max-height: 48px;
  height: 48px; }
  .navigation-simple .item md-icon {
    margin: 0 16px 0 0; }
  .navigation-simple .item .title {
    font-size: 13px;
    font-weight: 500;
    line-height: 1; }
  .navigation-simple .item.selected {
    background-color: rgba(0, 0, 0, 0.06); }

.navigation-simple .subheader {
  font-size: 13px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  font-weight: 500;
  margin-top: 8px; }
  .navigation-simple .subheader.light {
    border-top: 1px solid rgba(255, 255, 255, 0.12); }

.navigation-simple md-divider {
  margin: 8px 0; }

/*----------------------------------------------------------------*/
/*  Pagination
/*----------------------------------------------------------------*/
.simple-pagination {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  background-color: #FFFFFF;
  padding: 0 8px; }
  .simple-pagination .pagination-item {
    min-width: 48px;
    min-height: 56px;
    line-height: 56px;
    border-radius: 0;
    margin: 0;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.54); }
    .simple-pagination .pagination-item:hover {
      color: rgba(0, 0, 0, 0.87); }
    .simple-pagination .pagination-item.active {
      cursor: default;
      color: rgba(0, 0, 0, 0.87); }
    .simple-pagination .pagination-item.disabled {
      cursor: default;
      color: rgba(0, 0, 0, 0.26); }

/*----------------------------------------------------------------*/
/*  Table - Simple
/*----------------------------------------------------------------*/
.simple-table-container {
  background: #FFFFFF; }
  .simple-table-container .table-title {
    font-size: 20px;
    padding: 24px; }

table.simple {
  width: 100%;
  border: none;
  border-spacing: 0;
  text-align: left; }
  table.simple thead tr th {
    padding: 16px 8px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.54);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    white-space: nowrap;
    min-width: 120px; }
    table.simple thead tr th:first-child {
      padding-left: 24px; }
    table.simple thead tr th:last-child {
      padding-right: 24px; }
  table.simple tbody tr td {
    padding: 16px 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
    table.simple tbody tr td:first-child {
      padding-left: 24px; }
    table.simple tbody tr td:last-child {
      padding-right: 24px; }
  table.simple tbody tr:last-child td {
    border-bottom: none; }
  table.simple.clickable tbody tr {
    cursor: pointer; }
    table.simple.clickable tbody tr:hover {
      background: rgba(0, 0, 0, 0.03); }

/*----------------------------------------------------------------*/
/*  Typography
/*----------------------------------------------------------------*/
html {
  font-size: 62.5%;
  font-family: "Roboto", "Helvetica Neue", "Arial", sans-serif;
  line-height: 1.4;
  letter-spacing: -0.1px; }

body {
  font-size: 1.3rem; }

html, body {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto; }

form {
  font-size: 1.6rem; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: normal; }

h1, .h1 {
  font-size: 2.4rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.6rem; }

h4, .h4 {
  font-size: 1.4rem; }

h5, .h5 {
  font-size: 1.3rem; }

h6, .h6 {
  font-size: 1.2rem; }

a {
  text-decoration: none; }
  a:hover, a:active {
    text-decoration: underline; }

abbr {
  cursor: help;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.54); }

blockquote {
  border-left: 3px solid rgba(0, 0, 0, 0.12);
  font-style: italic;
  margin: 1em 0;
  padding-left: 16px; }
  blockquote footer {
    font-style: normal; }
    blockquote footer:before {
      content: '\2014 \00A0'; }
  blockquote.reverse {
    border-left: none;
    border-right: 3px solid rgba(0, 0, 0, 0.12);
    text-align: right;
    padding-left: 0;
    padding-right: 16px; }
    blockquote.reverse footer:before {
      content: ''; }
    blockquote.reverse footer:after {
      content: '\2014 \00A0'; }

code {
  font-family: "Monaco", "Menlo", "Consolas", "Ubuntu Mono", monospace; }
  code:not(.highlight) {
    background: rgba(0, 0, 0, 0.065);
    color: #106CC8;
    margin: 0 1px;
    padding: 2px 3px;
    border-radius: 2px; }

dl dt {
  font-weight: bold; }

dl dd {
  margin: 4px 0 16px 0; }

mark {
  background: #F7F49A; }

pre {
  line-height: 1.6;
  margin: 8px 16px;
  white-space: pre-wrap; }

small {
  font-size: 80%; }

table thead tr th {
  text-align: left; }

.text-italic {
  font-style: italic; }

.text-semibold {
  font-weight: 600; }

.text-bold, strong {
  font-weight: 700; }

.text-strike {
  text-decoration: line-through; }

.text-super {
  vertical-align: super; }

.text-sub {
  vertical-align: sub; }

.text-capitalize {
  text-transform: capitalize; }

.text-lowercase {
  text-transform: lowercase; }

.text-uppercase {
  text-transform: uppercase; }

.text-left {
  text-align: left; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.font-weight-100 {
  font-weight: 100; }

.font-weight-200 {
  font-weight: 200; }

.font-weight-300 {
  font-weight: 300; }

.font-weight-400 {
  font-weight: 400; }

.font-weight-500 {
  font-weight: 500; }

.font-weight-600 {
  font-weight: 600; }

.font-weight-700 {
  font-weight: 700; }

.font-weight-800 {
  font-weight: 800; }

.font-weight-900 {
  font-weight: 900; }

.font-size-2 {
  font-size: 2px; }

.font-size-4 {
  font-size: 4px; }

.font-size-6 {
  font-size: 6px; }

.font-size-8 {
  font-size: 8px; }

.font-size-10 {
  font-size: 10px; }

.font-size-12 {
  font-size: 12px; }

.font-size-14 {
  font-size: 14px; }

.font-size-16 {
  font-size: 16px; }

.font-size-18 {
  font-size: 18px; }

.font-size-20 {
  font-size: 20px; }

.font-size-22 {
  font-size: 22px; }

.font-size-24 {
  font-size: 24px; }

.font-size-26 {
  font-size: 26px; }

.font-size-28 {
  font-size: 28px; }

.font-size-30 {
  font-size: 30px; }

.font-size-32 {
  font-size: 32px; }

.font-size-34 {
  font-size: 34px; }

.font-size-36 {
  font-size: 36px; }

.font-size-38 {
  font-size: 38px; }

.font-size-40 {
  font-size: 40px; }

.font-size-42 {
  font-size: 42px; }

.font-size-44 {
  font-size: 44px; }

.font-size-46 {
  font-size: 46px; }

.font-size-48 {
  font-size: 48px; }

.font-size-50 {
  font-size: 50px; }

.font-size-52 {
  font-size: 52px; }

.font-size-54 {
  font-size: 54px; }

.font-size-56 {
  font-size: 56px; }

.font-size-58 {
  font-size: 58px; }

.font-size-60 {
  font-size: 60px; }

.font-size-62 {
  font-size: 62px; }

.font-size-64 {
  font-size: 64px; }

.font-size-66 {
  font-size: 66px; }

.font-size-68 {
  font-size: 68px; }

.font-size-70 {
  font-size: 70px; }

.font-size-72 {
  font-size: 72px; }

.font-size-74 {
  font-size: 74px; }

.font-size-76 {
  font-size: 76px; }

.font-size-78 {
  font-size: 78px; }

.font-size-80 {
  font-size: 80px; }

.font-size-82 {
  font-size: 82px; }

.font-size-84 {
  font-size: 84px; }

.font-size-86 {
  font-size: 86px; }

.font-size-88 {
  font-size: 88px; }

.font-size-90 {
  font-size: 90px; }

.font-size-92 {
  font-size: 92px; }

.font-size-94 {
  font-size: 94px; }

.font-size-96 {
  font-size: 96px; }

.font-size-98 {
  font-size: 98px; }

.font-size-100 {
  font-size: 100px; }

.font-size-102 {
  font-size: 102px; }

.font-size-104 {
  font-size: 104px; }

.font-size-106 {
  font-size: 106px; }

.font-size-108 {
  font-size: 108px; }

.font-size-110 {
  font-size: 110px; }

.font-size-112 {
  font-size: 112px; }

.font-size-114 {
  font-size: 114px; }

.font-size-116 {
  font-size: 116px; }

.font-size-118 {
  font-size: 118px; }

.font-size-120 {
  font-size: 120px; }

.line-height-2 {
  line-height: 2px; }

.line-height-4 {
  line-height: 4px; }

.line-height-6 {
  line-height: 6px; }

.line-height-8 {
  line-height: 8px; }

.line-height-10 {
  line-height: 10px; }

.line-height-12 {
  line-height: 12px; }

.line-height-14 {
  line-height: 14px; }

.line-height-16 {
  line-height: 16px; }

.line-height-18 {
  line-height: 18px; }

.line-height-20 {
  line-height: 20px; }

.line-height-22 {
  line-height: 22px; }

.line-height-24 {
  line-height: 24px; }

.line-height-26 {
  line-height: 26px; }

.line-height-28 {
  line-height: 28px; }

.line-height-30 {
  line-height: 30px; }

.line-height-32 {
  line-height: 32px; }

.line-height-34 {
  line-height: 34px; }

.line-height-36 {
  line-height: 36px; }

.line-height-38 {
  line-height: 38px; }

.line-height-40 {
  line-height: 40px; }

.line-height-42 {
  line-height: 42px; }

.line-height-44 {
  line-height: 44px; }

.line-height-46 {
  line-height: 46px; }

.line-height-48 {
  line-height: 48px; }

.line-height-50 {
  line-height: 50px; }

.line-height-52 {
  line-height: 52px; }

.line-height-54 {
  line-height: 54px; }

.line-height-56 {
  line-height: 56px; }

.line-height-58 {
  line-height: 58px; }

.line-height-60 {
  line-height: 60px; }

.line-height-62 {
  line-height: 62px; }

.line-height-64 {
  line-height: 64px; }

.line-height-66 {
  line-height: 66px; }

.line-height-68 {
  line-height: 68px; }

.line-height-70 {
  line-height: 70px; }

.line-height-72 {
  line-height: 72px; }

.line-height-74 {
  line-height: 74px; }

.line-height-76 {
  line-height: 76px; }

.line-height-78 {
  line-height: 78px; }

.line-height-80 {
  line-height: 80px; }

.line-height-82 {
  line-height: 82px; }

.line-height-84 {
  line-height: 84px; }

.line-height-86 {
  line-height: 86px; }

.line-height-88 {
  line-height: 88px; }

.line-height-90 {
  line-height: 90px; }

.line-height-92 {
  line-height: 92px; }

.line-height-94 {
  line-height: 94px; }

.line-height-96 {
  line-height: 96px; }

.line-height-98 {
  line-height: 98px; }

.line-height-100 {
  line-height: 100px; }

.line-height-102 {
  line-height: 102px; }

.line-height-104 {
  line-height: 104px; }

.line-height-106 {
  line-height: 106px; }

.line-height-108 {
  line-height: 108px; }

.line-height-110 {
  line-height: 110px; }

.line-height-112 {
  line-height: 112px; }

.line-height-114 {
  line-height: 114px; }

.line-height-116 {
  line-height: 116px; }

.line-height-118 {
  line-height: 118px; }

.line-height-120 {
  line-height: 120px; }

.text-boxed, .text-boxed-light {
  border-radius: 2px;
  padding: 4px 8px;
  margin: 0 8px;
  font-size: 11px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.54);
  background-color: rgba(0, 0, 0, 0.12);
  white-space: nowrap; }

.text-boxed-light {
  background-color: rgba(255, 255, 255, 0.7); }

.text-truncate {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-nowrap {
  white-space: nowrap; }

.angular-google-map-container {
  height: 400px; }

.moment-picker .moment-picker-container {
  width: 296px;
  border: none;
  border-radius: 2px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  margin-left: 0;
  padding: 8px; }
  .moment-picker .moment-picker-container th,
  .moment-picker .moment-picker-container td {
    min-width: 0;
    width: 40px;
    height: 40px;
    padding: 8px;
    text-shadow: none;
    border-radius: 35px; }
    .moment-picker .moment-picker-container th:hover,
    .moment-picker .moment-picker-container td:hover {
      background: #F5F5F5;
      background-image: none; }
  .moment-picker .moment-picker-container td.selected {
    background: #03A9F4;
    background-image: none; }
  .moment-picker .moment-picker-container .header-view th {
    border-radius: 0;
    white-space: nowrap;
    width: auto; }
    .moment-picker .moment-picker-container .header-view th:first-child, .moment-picker .moment-picker-container .header-view th:last-child {
      width: 20%; }

/*----------------------------------------------------------------*/
/*  @ Chart C3
/*----------------------------------------------------------------*/
.c3 {
  transition: all 300ms ease;
  /*-- Chart --*/
  /*-- Line --*/
  /*-- Point --*/
  /*-- Axis --*/
  /*-- Grid --*/
  /*-- Text on Chart --*/
  /*-- Bar --*/
  /*-- Focus --*/
  /*-- Region --*/
  /*-- Brush --*/
  /*-- Select - Drag --*/
  /*-- Legend --*/
  /*-- Tooltip --*/
  /*-- Area --*/
  /*-- Arc --*/ }
  .c3 svg {
    font: 12px RobotoDraft, Roboto, 'Helvetica Neue', sans-serif; }
  .c3 path, .c3 line {
    fill: none;
    stroke: #000; }
  .c3 text {
    font-family: "Roboto", "Helvetica Neue", "Arial", sans-serif;
    user-select: none; }
  .c3 .c3-legend-item-tile, .c3 .c3-xgrid-focus, .c3 .c3-ygrid, .c3 .c3-event-rect, .c3 .c3-bars path {
    shape-rendering: crispEdges; }
  .c3 .c3-chart-line {
    transition: all 300ms ease; }
  .c3 .c3-line {
    stroke-width: 1px; }
  .c3.stroke-3 .c3-line {
    stroke-width: 3px; }
  .c3.stroke-2 .c3-line {
    stroke-width: 2px; }
  .c3 .c3-circle {
    transition: all 300ms ease;
    stroke: white;
    stroke-width: 2; }
    .c3 .c3-circle._expanded_ {
      stroke-width: 3;
      stroke: white; }
  .c3 .c3-chart-arc path {
    stroke: #fff; }
  .c3 .c3-chart-arc text {
    fill: #fff;
    font-size: 13px; }
  .c3 .c3-grid line {
    stroke: rgba(0, 0, 0, 0.04);
    stroke-width: 1px;
    stroke-dasharray: 0; }
  .c3 .c3-grid text {
    fill: #aaa; }
  .c3 .c3-axis .tick {
    stroke-width: 0; }
  .c3 .c3-axis text {
    fill: rgba(0, 0, 0, 0.33); }
  .c3 .c3-axis line, .c3 .c3-axis path {
    stroke: rgba(0, 0, 0, 0.04); }
  .c3 .c3-xgrid, .c3 .c3-ygrid {
    stroke-dasharray: 0 0; }
  .c3 .c3-text.c3-empty {
    fill: #808080;
    font-size: 2em; }
  .c3 .c3-bar {
    stroke-width: 0; }
    .c3 .c3-bar._expanded_ {
      fill-opacity: 0.75; }
  .c3 .c3-target.c3-focused {
    opacity: 1; }
    .c3 .c3-target.c3-focused path.c3-line, .c3 .c3-target.c3-focused .c3-target.c3-focused path.c3-step {
      stroke-width: 3px;
      transition: all 300ms ease; }
  .c3 .c3-target.c3-defocused {
    opacity: 0.3 !important; }
  .c3 .c3-region {
    fill: steelblue;
    fill-opacity: 0.1; }
  .c3 .c3-brush .extent {
    fill-opacity: 0.1; }
  .c3 .c3-legend-item {
    font-size: 12px; }
  .c3 .c3-legend-item-hidden {
    opacity: 0.15; }
  .c3 .c3-legend-background {
    opacity: 0.75;
    fill: white;
    stroke: lightgray;
    stroke-width: 1; }
  .c3 .c3-tooltip-container {
    z-index: 10; }
  .c3 .c3-tooltip {
    border-collapse: collapse;
    border-spacing: 0;
    background-color: #fff;
    empty-cells: show;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.14), 0 4px 5px 0 rgba(0, 0, 0, 0.098), 0 1px 10px 0 rgba(0, 0, 0, 0.084);
    opacity: 1;
    border-radius: 2px;
    overflow: hidden;
    padding: 4px 0; }
    .c3 .c3-tooltip tr {
      border: none; }
    .c3 .c3-tooltip th {
      background-color: transparent;
      font-size: 14px;
      font-weight: 400;
      padding: 8px 12px;
      text-align: left;
      color: rgba(0, 0, 0, 0.54); }
    .c3 .c3-tooltip td {
      font-size: 13px;
      padding: 4px 12px !important;
      background-color: #fff;
      border-left: none;
      color: rgba(0, 0, 0, 0.84); }
      .c3 .c3-tooltip td > span {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 6px; }
      .c3 .c3-tooltip td.value {
        font-size: 14px !important;
        text-align: right;
        color: rgba(0, 0, 0, 0.84); }
  .c3 .c3-area {
    stroke-width: 0;
    opacity: 0.2; }
  .c3 .c3-chart-arcs-title {
    dominant-baseline: middle;
    font-size: 1.3em; }
  .c3 .c3-chart-arcs .c3-chart-arcs-background {
    fill: #e0e0e0;
    stroke: none; }
  .c3 .c3-chart-arcs .c3-chart-arcs-gauge-unit {
    fill: #000;
    font-size: 16px; }
  .c3 .c3-chart-arcs .c3-chart-arcs-gauge-max {
    fill: #777; }
  .c3 .c3-chart-arcs .c3-chart-arcs-gauge-min {
    fill: #777; }
  .c3 .c3-chart-arc .c3-gauge-value {
    fill: #000; }

.ct-label {
  font-size: 13px; }

.ct-chart-donut .ct-label,
.ct-chart-pie .ct-label {
  font-size: 13px; }

.chart-legend,
.bar-legend,
.line-legend,
.pie-legend,
.radar-legend,
.polararea-legend,
.doughnut-legend {
  margin-top: 15px; }

table.dataTable {
  width: 100%;
  margin: 0 auto !important;
  clear: both;
  border-spacing: 0; }
  table.dataTable thead tr {
    background: #FFFFFF; }
  table.dataTable thead th {
    text-align: left;
    vertical-align: middle;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding: 16px 8px; }
    table.dataTable thead th:first-child {
      padding-left: 24px; }
    table.dataTable thead th:last-child {
      border-right: none;
      padding-right: 24px; }
    table.dataTable thead th:active {
      outline: none; }
    table.dataTable thead th.dt-head-left {
      text-align: left; }
    table.dataTable thead th.dt-head-center {
      text-align: center; }
    table.dataTable thead th.dt-head-right {
      text-align: right; }
    table.dataTable thead th.dt-head-justify {
      text-align: justify; }
    table.dataTable thead th.dt-head-nowrap {
      white-space: nowrap; }
    table.dataTable thead th .table-header .column-title {
      display: block;
      font-weight: 600;
      white-space: nowrap; }
    table.dataTable thead th .table-header .selectize-control {
      margin-top: 15px; }
      table.dataTable thead th .table-header .selectize-control.single .selectize-input {
        padding: 7px 10px; }
        table.dataTable thead th .table-header .selectize-control.single .selectize-input:after {
          right: 10px;
          border-width: 5px 4px 0 4px;
          border-color: rgba(0, 0, 0, 0.12) transparent transparent transparent; }
    table.dataTable thead th .table-header .column-search-input {
      display: inline-block;
      margin-top: 15px;
      width: 100%;
      height: 33px; }
  table.dataTable thead td {
    padding: 15px;
    vertical-align: middle; }
    table.dataTable thead td:active {
      outline: none; }
    table.dataTable thead td.dt-head-left {
      text-align: left; }
    table.dataTable thead td.dt-head-center {
      text-align: center; }
    table.dataTable thead td.dt-head-right {
      text-align: right; }
    table.dataTable thead td.dt-head-justify {
      text-align: justify; }
    table.dataTable thead td.dt-head-nowrap {
      white-space: nowrap; }
  table.dataTable thead .sorting, table.dataTable thead .sorting_asc, table.dataTable thead .sorting_desc {
    background: none; }
    table.dataTable thead .sorting .table-header .column-title, table.dataTable thead .sorting_asc .table-header .column-title, table.dataTable thead .sorting_desc .table-header .column-title {
      cursor: pointer; }
      table.dataTable thead .sorting .table-header .column-title:after, table.dataTable thead .sorting_asc .table-header .column-title:after, table.dataTable thead .sorting_desc .table-header .column-title:after {
        position: relative;
        font-family: 'icomoon';
        font-weight: normal;
        margin-left: 10px;
        top: 2px;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.54); }
  table.dataTable thead .sorting .table-header .column-title:after {
    content: '\eaa8'; }
  table.dataTable thead .sorting_asc .table-header .column-title:after {
    content: '\e718'; }
  table.dataTable thead .sorting_desc .table-header .column-title:after {
    content: '\e715'; }
  table.dataTable tfoot th {
    font-weight: 600;
    text-align: left;
    padding: 10px 18px 6px 18px;
    border-top: 1px solid #111111; }
    table.dataTable tfoot th.dt-head-left {
      text-align: left; }
    table.dataTable tfoot th.dt-head-center {
      text-align: center; }
    table.dataTable tfoot th.dt-head-right {
      text-align: right; }
    table.dataTable tfoot th.dt-head-justify {
      text-align: justify; }
    table.dataTable tfoot th.dt-head-nowrap {
      white-space: nowrap; }
  table.dataTable tfoot td {
    padding: 10px 18px 6px 18px;
    border-top: 1px solid #111111; }
    table.dataTable tfoot td.dt-head-left {
      text-align: left; }
    table.dataTable tfoot td.dt-head-center {
      text-align: center; }
    table.dataTable tfoot td.dt-head-right {
      text-align: right; }
    table.dataTable tfoot td.dt-head-justify {
      text-align: justify; }
    table.dataTable tfoot td.dt-head-nowrap {
      white-space: nowrap; }
  table.dataTable tbody tr {
    background-color: #FFFFFF; }
    table.dataTable tbody tr.selected {
      background-color: #c2dbff; }
    table.dataTable tbody tr.details-open td.detail-column i {
      background: #FFFFFF; }
    table.dataTable tbody tr.details-row.odd tr {
      background: #FFFFFF; }
    table.dataTable tbody tr.details-row > td {
      padding: 0; }
  table.dataTable tbody th {
    padding: 14px 15px;
    vertical-align: middle; }
    table.dataTable tbody th.dt-body-left {
      text-align: left; }
    table.dataTable tbody th.dt-body-center {
      text-align: center; }
    table.dataTable tbody th.dt-body-right {
      text-align: right; }
    table.dataTable tbody th.dt-body-justify {
      text-align: justify; }
    table.dataTable tbody th.dt-body-nowrap {
      white-space: nowrap; }
  table.dataTable tbody td {
    padding: 16px 8px;
    vertical-align: middle; }
    table.dataTable tbody td:first-child {
      padding-left: 24px; }
    table.dataTable tbody td:last-child {
      padding-right: 24px; }
    table.dataTable tbody td.dt-body-left {
      text-align: left; }
    table.dataTable tbody td.dt-body-center {
      text-align: center; }
    table.dataTable tbody td.dt-body-right {
      text-align: right; }
    table.dataTable tbody td.dt-body-justify {
      text-align: justify; }
    table.dataTable tbody td.dt-body-nowrap {
      white-space: nowrap; }
  table.dataTable th.dt-left {
    text-align: left; }
  table.dataTable th.dt-center {
    text-align: center; }
  table.dataTable th.dt-right {
    text-align: right; }
  table.dataTable th.dt-justify {
    text-align: justify; }
  table.dataTable th.dt-nowrap {
    white-space: nowrap; }
  table.dataTable td.dt-left {
    text-align: left; }
  table.dataTable td.dt-center {
    text-align: center; }
  table.dataTable td.dataTables_empty {
    height: 51px;
    text-align: center; }
  table.dataTable td.dt-right {
    text-align: right; }
  table.dataTable td.dt-justify {
    text-align: justify; }
  table.dataTable td.dt-nowrap {
    white-space: nowrap; }
  table.dataTable.row-border thead {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
  table.dataTable.row-border tbody tr:first-child th {
    border-top: none; }
  table.dataTable.row-border tbody tr:first-child td {
    border-top: none; }
  table.dataTable.row-border tbody tr:last-child td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
  table.dataTable.row-border tbody th {
    border-top: 1px solid rgba(0, 0, 0, 0.12); }
  table.dataTable.row-border tbody td {
    border-top: 1px solid rgba(0, 0, 0, 0.12); }
  table.dataTable.cell-border tbody tr:first-child th {
    border-top: none; }
  table.dataTable.cell-border tbody tr:first-child td {
    border-top: none; }
  table.dataTable.cell-border tbody th {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-right: 1px solid rgba(0, 0, 0, 0.12); }
    table.dataTable.cell-border tbody th:last-child {
      border-right: none; }
  table.dataTable.cell-border tbody td {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-right: 1px solid rgba(0, 0, 0, 0.12); }
    table.dataTable.cell-border tbody td:last-child {
      border-right: none; }
  table.dataTable.stripe tbody tr.odd {
    background-color: #FFFFFF; }
    table.dataTable.stripe tbody tr.odd.selected {
      background-color: #ABB9D3; }
  table.dataTable.hover tbody tr:hover {
    background-color: transparent;
    -webkit-box-shadow: inset 0px 0 0px 0px #e6e6e6, inset -1px 0 0 #dadce057, inset 0px 0px 1px 1px rgba(60, 64, 67, 0.28), 0px 0px 1px 1px rgba(60, 61, 67, 0.33);
    box-shadow: inset 0px 0 0px 0px #e6e6e6, inset -1px 0 0 #dadce057, inset 0px 0px 1px 1px rgba(60, 64, 67, 0.28), 0px 0px 1px 1px rgba(60, 61, 67, 0.33); }
    table.dataTable.hover tbody tr:hover.selected {
      background-color: #c2dbff; }
  table.dataTable.order-column tbody tr > .sorting_1 {
    background-color: #F9F9F9; }
  table.dataTable.order-column tbody tr > .sorting_2 {
    background-color: #F9F9F9; }
  table.dataTable.order-column tbody tr > .sorting_3 {
    background-color: #F9F9F9; }
  table.dataTable.order-column tbody tr.selected > .sorting_1 {
    background-color: #ACBAD4; }
  table.dataTable.order-column tbody tr.selected > .sorting_2 {
    background-color: #ACBAD4; }
  table.dataTable.order-column tbody tr.selected > .sorting_3 {
    background-color: #ACBAD4; }
  table.dataTable.order-column.stripe tbody tr.odd > .sorting_1 {
    background-color: #F1F1F1; }
  table.dataTable.order-column.stripe tbody tr.odd > .sorting_2 {
    background-color: #F3F3F3; }
  table.dataTable.order-column.stripe tbody tr.odd > .sorting_3 {
    background-color: whitesmoke; }
  table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_1 {
    background-color: #A6B3CD; }
  table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_2 {
    background-color: #A7B5CE; }
  table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_3 {
    background-color: #A9B6D0; }
  table.dataTable.order-column.stripe tbody tr.even > .sorting_1 {
    background-color: #F9F9F9; }
  table.dataTable.order-column.stripe tbody tr.even > .sorting_2 {
    background-color: #FBFBFB; }
  table.dataTable.order-column.stripe tbody tr.even > .sorting_3 {
    background-color: #FDFDFD; }
  table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_1 {
    background-color: #ACBAD4; }
  table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_2 {
    background-color: #ADBBD6; }
  table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_3 {
    background-color: #AFBDD8; }
  table.dataTable.order-column.hover tbody tr:hover > .sorting_1 {
    background-color: #EAEAEA; }
  table.dataTable.order-column.hover tbody tr:hover > .sorting_2 {
    background-color: #EBEBEB; }
  table.dataTable.order-column.hover tbody tr:hover > .sorting_3 {
    background-color: #EEEEEE; }
  table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_1 {
    background-color: #A1AEC7; }
  table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_2 {
    background-color: #A2AFC8; }
  table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_3 {
    background-color: #A4B2CB; }
  table.dataTable.order-column.hover tbody tr.odd:hover > .sorting_1 {
    background-color: #EAEAEA; }
  table.dataTable.order-column.hover tbody tr.odd:hover > .sorting_2 {
    background-color: #EBEBEB; }
  table.dataTable.order-column.hover tbody tr.odd:hover > .sorting_3 {
    background-color: #EEEEEE; }
  table.dataTable.order-column.hover tbody tr.odd:hover.selected > .sorting_1 {
    background-color: #A1AEC7; }
  table.dataTable.order-column.hover tbody tr.odd:hover.selected > .sorting_2 {
    background-color: #A2AFC8; }
  table.dataTable.order-column.hover tbody tr.odd:hover.selected > .sorting_3 {
    background-color: #A4B2CB; }
  table.dataTable.order-column.hover tbody tr.even:hover > .sorting_1 {
    background-color: #EAEAEA; }
  table.dataTable.order-column.hover tbody tr.even:hover > .sorting_2 {
    background-color: #EBEBEB; }
  table.dataTable.order-column.hover tbody tr.even:hover > .sorting_3 {
    background-color: #EEEEEE; }
  table.dataTable.order-column.hover tbody tr.even:hover.selected > .sorting_1 {
    background-color: #A1AEC7; }
  table.dataTable.order-column.hover tbody tr.even:hover.selected > .sorting_2 {
    background-color: #A2AFC8; }
  table.dataTable.order-column.hover tbody tr.even:hover.selected > .sorting_3 {
    background-color: #A4B2CB; }
  table.dataTable.no-footer {
    border-bottom: none; }
  table.dataTable.nowrap th {
    white-space: nowrap; }
  table.dataTable.nowrap td {
    white-space: nowrap; }
  table.dataTable.compact thead th {
    padding: 5px 9px; }
  table.dataTable.compact thead td {
    padding: 5px 9px; }
  table.dataTable.compact tfoot th {
    padding: 5px 9px 3px 9px; }
  table.dataTable.compact tfoot td {
    padding: 5px 9px 3px 9px; }
  table.dataTable.compact tbody th {
    padding: 4px 5px; }
  table.dataTable.compact tbody td {
    padding: 4px 5px; }

/*
 * Control feature layout
 */
.dataTables_wrapper {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  min-height: 0;
  padding: 0; }
  .dataTables_wrapper .top {
    padding: 16px 24px; }
  .dataTables_wrapper .bottom {
    display: flex;
    min-height: 64px;
    justify-content: space-between;
    padding: 16px 8px; }
    .dataTables_wrapper .bottom .left {
      display: flex;
      flex: 0 0 50%;
      max-width: 50%;
      align-self: center;
      padding: 0 8px; }
      .dataTables_wrapper .bottom .left .length {
        display: block; }
    .dataTables_wrapper .bottom .right {
      display: flex;
      flex: 0 0 50%;
      max-width: 50%;
      align-self: center;
      justify-content: flex-end;
      padding: 0 8px; }
      .dataTables_wrapper .bottom .right .info {
        display: block;
        align-self: center; }
        .dataTables_wrapper .bottom .right .info .dataTables_info {
          padding: 0 8px;
          font-weight: 600; }
      .dataTables_wrapper .bottom .right .pagination {
        display: block;
        align-self: center;
        white-space: nowrap; }
        .dataTables_wrapper .bottom .right .pagination .dataTables_paginate {
          padding: 0;
          margin: 0 !important; }
  .dataTables_wrapper .dataTables_filter {
    float: right; }
    .dataTables_wrapper .dataTables_filter input {
      margin-left: 0.5em;
      border: 1px solid rgba(0, 0, 0, 0.12);
      height: 24px;
      padding: 4px 8px; }
  .dataTables_wrapper .dataTables_paginate .paginate_button {
    background: #FFFFFF;
    color: rgba(0, 0, 0, 0.87);
    border: 1px solid rgba(0, 0, 0, 0.12);
    padding: 9px 12px;
    border-radius: 2px;
    cursor: pointer;
    user-select: none;
    margin: 0 2px; }
    .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
      box-shadow: inset 999em 0 0 0 rgba(0, 0, 0, 0.05);
      color: rgba(0, 0, 0, 0.87) !important;
      border: 1px solid rgba(0, 0, 0, 0.12) !important;
      background: none; }
    .dataTables_wrapper .dataTables_paginate .paginate_button:active {
      box-shadow: inset 999em 0 0 0 rgba(0, 0, 0, 0.08), inset 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 0 0 rgba(255, 255, 255, 0.35); }
    .dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
      color: rgba(0, 0, 0, 0.54) !important;
      border-color: rgba(0, 0, 0, 0.12) !important;
      background: #FFFFFF !important;
      box-shadow: none !important;
      cursor: default; }
  .dataTables_wrapper .dataTables_processing {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 40px;
    margin-left: -50%;
    margin-top: -25px;
    padding-top: 20px;
    text-align: center;
    font-size: 1.2em;
    background-color: white;
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(25%, rgba(255, 255, 255, 0.9)), color-stop(75%, rgba(255, 255, 255, 0.9)), color-stop(100%, rgba(255, 255, 255, 0)));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
    /* Chrome10+,Safari5.1+ */
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
    /* FF3.6+ */
    background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
    /* IE10+ */
    background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
    /* Opera 11.10+ */
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
    /* W3C */ }
  .dataTables_wrapper .dataTables_scroll {
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    clear: both;
    min-height: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    /* Clear the table border & shadows */ }
    .dataTables_wrapper .dataTables_scroll table.dataTable {
      box-shadow: none; }
      .dataTables_wrapper .dataTables_scroll table.dataTable.row-border thead {
        border: none; }
    .dataTables_wrapper .dataTables_scroll .dataTables_scrollHead {
      display: flex;
      min-height: 56px;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.095), 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 2px 1px 0 rgba(0, 0, 0, 0.05);
      background: #FFFFFF;
      overflow: visible !important; }
      .dataTables_wrapper .dataTables_scroll .dataTables_scrollHead tr th:last-child .table-header .column-title {
        position: relative;
        left: 7px; }
    .dataTables_wrapper .dataTables_scroll .dataTables_scrollBody {
      display: flex;
      overflow-x: hidden !important;
      overflow-y: scroll !important;
      -webkit-overflow-scrolling: touch; }
      .dataTables_wrapper .dataTables_scroll .dataTables_scrollBody th > .dataTables_sizing {
        height: 0;
        overflow: hidden;
        margin: 0 !important;
        padding: 0 !important; }
      .dataTables_wrapper .dataTables_scroll .dataTables_scrollBody td > .dataTables_sizing {
        height: 0;
        overflow: hidden;
        margin: 0 !important;
        padding: 0 !important; }
  .dataTables_wrapper.no-footer .dataTables_scrollBody {
    border-bottom: none; }
  .dataTables_wrapper.no-footer .dataTables_scrollHead table,
  .dataTables_wrapper.no-footer .dataTables_scrollBody table {
    border-top: none;
    border-bottom: none;
    margin: 0; }
  .dataTables_wrapper:after {
    visibility: hidden;
    display: block;
    content: "";
    clear: both;
    height: 0; }

/* DataTable Specific Content Rules & Fixes */
table.dataTable .show-details {
  padding: 5px 10px;
  font-weight: bold; }

table.dataTable .action-buttons {
  display: flex;
  justify-content: center; }
  table.dataTable .action-buttons .button {
    padding: 8px; }

/* Fullpage datatable */
.fullpage-datatable .dataTables_wrapper {
  height: 100%;
  max-height: 100%;
  padding: 0; }

/* Fullpage datatable IE11 Fix */
.explorer-11 .fullpage-datatable .dataTables_scroll {
  flex: 1; }

/* Responsive datatable */
table.dataTable.dtr-inline.collapsed.dtr-inline.collapsed > tbody > tr > td:first-child,
table.dataTable.dtr-inline.collapsed.dtr-inline.collapsed > tbody > tr > th:first-child {
  padding-left: 40px; }
  table.dataTable.dtr-inline.collapsed.dtr-inline.collapsed > tbody > tr > td:first-child:before,
  table.dataTable.dtr-inline.collapsed.dtr-inline.collapsed > tbody > tr > th:first-child:before {
    content: '+';
    width: 15px;
    height: 15px;
    line-height: 15px;
    top: 50%;
    margin-top: -7px;
    left: 8px;
    color: rgba(0, 0, 0, 0.54);
    background: none;
    border: none;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.54);
    font-weight: bold; }

table.dataTable.dtr-inline.collapsed.dtr-inline.collapsed > tbody > tr.parent > td:first-child:before,
table.dataTable.dtr-inline.collapsed.dtr-inline.collapsed > tbody > tr.parent > th:first-child:before {
  background: none; }

table.dataTable.dtr-inline.collapsed > tbody > tr.child ul {
  display: block; }
  table.dataTable.dtr-inline.collapsed > tbody > tr.child ul li {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding: 8px 0; }
    table.dataTable.dtr-inline.collapsed > tbody > tr.child ul li:first-child {
      padding-top: 0; }
    table.dataTable.dtr-inline.collapsed > tbody > tr.child ul li:last-child {
      border-bottom: none;
      padding-bottom: 0; }
    table.dataTable.dtr-inline.collapsed > tbody > tr.child ul li .dtr-title {
      margin-right: 8px; }

/*

github.com style (c) Vasily Polovnyov <vast@whiteants.net>

*/
hljs,
[hljs] {
  display: block;
  overflow-x: auto;
  color: #333;
  background: #f8f8f8;
  -webkit-text-size-adjust: none; }

.hljs-comment,
.diff .hljs-header {
  color: #998;
  font-style: italic; }

.hljs-keyword,
.css .rule .hljs-keyword,
.hljs-winutils,
.nginx .hljs-title,
.hljs-subst,
.hljs-request,
.hljs-status {
  color: #333;
  font-weight: bold; }

.hljs-number,
.hljs-hexcolor,
.ruby .hljs-constant {
  color: #008080; }

.hljs-string,
.hljs-tag .hljs-value,
.hljs-doctag,
.tex .hljs-formula {
  color: #d14; }

.hljs-title,
.hljs-id,
.scss .hljs-preprocessor {
  color: #900;
  font-weight: bold; }

.hljs-list .hljs-keyword,
.hljs-subst {
  font-weight: normal; }

.hljs-class .hljs-title,
.hljs-type,
.vhdl .hljs-literal,
.tex .hljs-command {
  color: #458;
  font-weight: bold; }

.hljs-tag,
.hljs-tag .hljs-title,
.hljs-rule .hljs-property,
.django .hljs-tag .hljs-keyword {
  color: #000080;
  font-weight: normal; }

.hljs-attribute,
.hljs-variable,
.lisp .hljs-body,
.hljs-name {
  color: #008080; }

.hljs-regexp {
  color: #009926; }

.hljs-symbol,
.ruby .hljs-symbol .hljs-string,
.lisp .hljs-keyword,
.clojure .hljs-keyword,
.scheme .hljs-keyword,
.tex .hljs-special,
.hljs-prompt {
  color: #990073; }

.hljs-built_in {
  color: #0086b3; }

.hljs-preprocessor,
.hljs-pragma,
.hljs-pi,
.hljs-doctype,
.hljs-shebang,
.hljs-cdata {
  color: #999;
  font-weight: bold; }

.hljs-deletion {
  background: #fdd; }

.hljs-addition {
  background: #dfd; }

.diff .hljs-change {
  background: #0086b3; }

.hljs-chunk {
  color: #aaa; }

nvd3 {
  display: block;
  width: 100%;
  height: 100%; }
  nvd3.remove-x-lines .nv-x .tick line {
    display: none; }
  nvd3.remove-y-lines .nv-y .tick line {
    display: none; }
  nvd3.remove-line-stroke .nv-groups path.nv-line {
    stroke-width: 0 !important; }
  nvd3.remove-opacity .nv-groups .nv-group {
    fill-opacity: 1 !important; }
  nvd3.show-line-points .nv-line .nv-scatter .nv-groups .nv-point {
    fill-opacity: 1 !important;
    stroke-opacity: 1 !important; }

.nvd3 text {
  font-family: "Roboto", "Helvetica Neue", "Arial", sans-serif; }

.nvd3 line.nv-guideline {
  stroke: rgba(0, 0, 0, 0.54); }

.nvd3 .nv-groups .nv-point.hover {
  stroke-width: 3px !important;
  fill-opacity: 1 !important;
  stroke-opacity: 1 !important; }

.nvtooltip {
  background: none;
  color: white;
  padding: 0;
  border: none; }
  .nvtooltip.gravity-n:after {
    display: block;
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    left: 50%;
    bottom: 100%;
    margin-left: -5px;
    border: 5px solid transparent;
    border-bottom-color: rgba(0, 0, 0, 0.87); }
  .nvtooltip.gravity-s:after {
    display: block;
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border: 5px solid transparent;
    border-top-color: rgba(0, 0, 0, 0.87); }
  .nvtooltip.gravity-e:after {
    display: block;
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    top: 50%;
    right: 0;
    margin-top: -6px;
    margin-right: -11px;
    border: 6px solid transparent;
    border-left-color: rgba(0, 0, 0, 0.87); }
  .nvtooltip.gravity-w:after {
    display: block;
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    top: 50%;
    margin-top: -6px;
    margin-left: -11px;
    border: 6px solid transparent;
    border-right-color: rgba(0, 0, 0, 0.87); }
  .nvtooltip table {
    background: rgba(0, 0, 0, 0.87);
    padding: 8px 12px;
    margin: 0;
    border-radius: 2px; }
    .nvtooltip table tbody tr td.legend-color-guide div {
      border: none; }
    .nvtooltip table tbody tr td:last-child {
      padding-right: 0; }

.ps-container:hover .ps-in-scrolling {
  pointer-events: auto; }

.ps-container .ps-in-scrolling {
  pointer-events: auto; }

.ps-container > .ps-scrollbar-x-rail {
  background: none !important;
  margin: 4px 0;
  height: 4px;
  z-index: 49; }
  .ps-container > .ps-scrollbar-x-rail > .ps-scrollbar-x {
    height: 4px; }

.ps-container > .ps-scrollbar-y-rail {
  background: none !important;
  margin: 4px 0;
  width: 4px;
  z-index: 49; }
  .ps-container > .ps-scrollbar-y-rail > .ps-scrollbar-y {
    width: 4px; }

.ta-root {
  display: block; }
  .ta-root .ta-scroll-window.form-control {
    border: 1px solid rgba(0, 0, 0, 0.12); }
  .ta-root.focussed > .ta-scroll-window.form-control {
    outline: 0px solid transparent;
    border: 1px solid rgba(0, 0, 0, 0.12);
    box-shadow: none; }
  .ta-root textarea.ta-editor.ta-html {
    resize: vertical;
    width: 100% !important;
    border: 1px solid rgba(0, 0, 0, 0.12);
    padding: 16px; }
  .ta-root .ta-toolbar {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-bottom: none;
    background: #F5F5F5; }
    .ta-root .ta-toolbar .ta-group {
      display: flex;
      align-items: center;
      align-content: center;
      flex-wrap: wrap; }
      .ta-root .ta-toolbar .ta-group #toolbarWC,
      .ta-root .ta-toolbar .ta-group #toolbarCC {
        display: inline-block !important;
        line-height: 24px; }
      .ta-root .ta-toolbar .ta-group .md-button {
        width: 40px;
        min-width: 40px;
        line-height: 14px;
        height: 36px;
        min-height: 36px;
        padding: 6px;
        margin: 4px 0; }
        .ta-root .ta-toolbar .ta-group .md-button i {
          font-size: 20px;
          width: 20px;
          height: 20px;
          line-height: 20px; }

/*----------------------------------------------------------------*/
/*  Helpers
/*----------------------------------------------------------------*/
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important; }

.no-padding {
  padding: 0 !important; }

.no-margin {
  margin: 0 !important; }

.no-radius {
  border-radius: 0; }

.divider-vertical {
  background: rgba(0, 0, 0, 0.12);
  height: 24px;
  width: 1px;
  margin: 0 8px; }

.hidden {
  visibility: hidden;
  opacity: 0; }

[fill-width] {
  min-width: 100%; }

[fill-height] {
  min-height: 100%; }

.full-height {
  height: 100% !important; }

a img {
  display: block;
  outline: none; }

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
  border: none; }

.scrollable {
  display: block;
  position: relative;
  overflow-x: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }
  .scrollable.only-x {
    overflow-x: auto;
    overflow-y: hidden; }
  .scrollable.only-y {
    overflow-x: hidden;
    overflow-y: auto; }

.flex-scrollable {
  position: relative;
  overflow-x: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }
  .flex-scrollable.only-x {
    overflow-x: auto;
    overflow-y: hidden; }
  .flex-scrollable.only-y {
    overflow-x: hidden;
    overflow-y: auto; }

.background-image-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1; }

.align-vertical:before {
  display: inline-block;
  height: 100%;
  content: '';
  vertical-align: middle; }

.align-vertical .align-vertical-top,
.align-vertical .align-vertical-middle,
.align-vertical .align-vertical-bottom {
  display: inline-block; }

.align-vertical .align-vertical-top {
  vertical-align: top; }

.align-vertical .align-vertical-middle {
  vertical-align: middle; }

.align-vertical .align-vertical-bottom {
  vertical-align: bottom; }

.padding-0,
.p-0 {
  padding: 0px; }

.padding-top-0,
.pt-0 {
  padding-top: 0px; }

.padding-right-0,
.pr-0 {
  padding-right: 0px; }

.padding-bottom-0,
.pb-0 {
  padding-bottom: 0px; }

.padding-left-0,
.pl-0 {
  padding-left: 0px; }

.padding-horizontal-0,
.ph-0 {
  padding-left: 0px;
  padding-right: 0px; }

.padding-vertical-0,
.pv-0 {
  padding-top: 0px;
  padding-bottom: 0px; }

.padding-5,
.p-5 {
  padding: 5px; }

.padding-top-5,
.pt-5 {
  padding-top: 5px; }

.padding-right-5,
.pr-5 {
  padding-right: 5px; }

.padding-bottom-5,
.pb-5 {
  padding-bottom: 5px; }

.padding-left-5,
.pl-5 {
  padding-left: 5px; }

.padding-horizontal-5,
.ph-5 {
  padding-left: 5px;
  padding-right: 5px; }

.padding-vertical-5,
.pv-5 {
  padding-top: 5px;
  padding-bottom: 5px; }

.padding-10,
.p-10 {
  padding: 10px; }

.padding-top-10,
.pt-10 {
  padding-top: 10px; }

.padding-right-10,
.pr-10 {
  padding-right: 10px; }

.padding-bottom-10,
.pb-10 {
  padding-bottom: 10px; }

.padding-left-10,
.pl-10 {
  padding-left: 10px; }

.padding-horizontal-10,
.ph-10 {
  padding-left: 10px;
  padding-right: 10px; }

.padding-vertical-10,
.pv-10 {
  padding-top: 10px;
  padding-bottom: 10px; }

.padding-15,
.p-15 {
  padding: 15px; }

.padding-top-15,
.pt-15 {
  padding-top: 15px; }

.padding-right-15,
.pr-15 {
  padding-right: 15px; }

.padding-bottom-15,
.pb-15 {
  padding-bottom: 15px; }

.padding-left-15,
.pl-15 {
  padding-left: 15px; }

.padding-horizontal-15,
.ph-15 {
  padding-left: 15px;
  padding-right: 15px; }

.padding-vertical-15,
.pv-15 {
  padding-top: 15px;
  padding-bottom: 15px; }

.padding-20,
.p-20 {
  padding: 20px; }

.padding-top-20,
.pt-20 {
  padding-top: 20px; }

.padding-right-20,
.pr-20 {
  padding-right: 20px; }

.padding-bottom-20,
.pb-20 {
  padding-bottom: 20px; }

.padding-left-20,
.pl-20 {
  padding-left: 20px; }

.padding-horizontal-20,
.ph-20 {
  padding-left: 20px;
  padding-right: 20px; }

.padding-vertical-20,
.pv-20 {
  padding-top: 20px;
  padding-bottom: 20px; }

.padding-25,
.p-25 {
  padding: 25px; }

.padding-top-25,
.pt-25 {
  padding-top: 25px; }

.padding-right-25,
.pr-25 {
  padding-right: 25px; }

.padding-bottom-25,
.pb-25 {
  padding-bottom: 25px; }

.padding-left-25,
.pl-25 {
  padding-left: 25px; }

.padding-horizontal-25,
.ph-25 {
  padding-left: 25px;
  padding-right: 25px; }

.padding-vertical-25,
.pv-25 {
  padding-top: 25px;
  padding-bottom: 25px; }

.padding-30,
.p-30 {
  padding: 30px; }

.padding-top-30,
.pt-30 {
  padding-top: 30px; }

.padding-right-30,
.pr-30 {
  padding-right: 30px; }

.padding-bottom-30,
.pb-30 {
  padding-bottom: 30px; }

.padding-left-30,
.pl-30 {
  padding-left: 30px; }

.padding-horizontal-30,
.ph-30 {
  padding-left: 30px;
  padding-right: 30px; }

.padding-vertical-30,
.pv-30 {
  padding-top: 30px;
  padding-bottom: 30px; }

.padding-35,
.p-35 {
  padding: 35px; }

.padding-top-35,
.pt-35 {
  padding-top: 35px; }

.padding-right-35,
.pr-35 {
  padding-right: 35px; }

.padding-bottom-35,
.pb-35 {
  padding-bottom: 35px; }

.padding-left-35,
.pl-35 {
  padding-left: 35px; }

.padding-horizontal-35,
.ph-35 {
  padding-left: 35px;
  padding-right: 35px; }

.padding-vertical-35,
.pv-35 {
  padding-top: 35px;
  padding-bottom: 35px; }

.padding-40,
.p-40 {
  padding: 40px; }

.padding-top-40,
.pt-40 {
  padding-top: 40px; }

.padding-right-40,
.pr-40 {
  padding-right: 40px; }

.padding-bottom-40,
.pb-40 {
  padding-bottom: 40px; }

.padding-left-40,
.pl-40 {
  padding-left: 40px; }

.padding-horizontal-40,
.ph-40 {
  padding-left: 40px;
  padding-right: 40px; }

.padding-vertical-40,
.pv-40 {
  padding-top: 40px;
  padding-bottom: 40px; }

.padding-45,
.p-45 {
  padding: 45px; }

.padding-top-45,
.pt-45 {
  padding-top: 45px; }

.padding-right-45,
.pr-45 {
  padding-right: 45px; }

.padding-bottom-45,
.pb-45 {
  padding-bottom: 45px; }

.padding-left-45,
.pl-45 {
  padding-left: 45px; }

.padding-horizontal-45,
.ph-45 {
  padding-left: 45px;
  padding-right: 45px; }

.padding-vertical-45,
.pv-45 {
  padding-top: 45px;
  padding-bottom: 45px; }

.padding-50,
.p-50 {
  padding: 50px; }

.padding-top-50,
.pt-50 {
  padding-top: 50px; }

.padding-right-50,
.pr-50 {
  padding-right: 50px; }

.padding-bottom-50,
.pb-50 {
  padding-bottom: 50px; }

.padding-left-50,
.pl-50 {
  padding-left: 50px; }

.padding-horizontal-50,
.ph-50 {
  padding-left: 50px;
  padding-right: 50px; }

.padding-vertical-50,
.pv-50 {
  padding-top: 50px;
  padding-bottom: 50px; }

.padding-55,
.p-55 {
  padding: 55px; }

.padding-top-55,
.pt-55 {
  padding-top: 55px; }

.padding-right-55,
.pr-55 {
  padding-right: 55px; }

.padding-bottom-55,
.pb-55 {
  padding-bottom: 55px; }

.padding-left-55,
.pl-55 {
  padding-left: 55px; }

.padding-horizontal-55,
.ph-55 {
  padding-left: 55px;
  padding-right: 55px; }

.padding-vertical-55,
.pv-55 {
  padding-top: 55px;
  padding-bottom: 55px; }

.padding-60,
.p-60 {
  padding: 60px; }

.padding-top-60,
.pt-60 {
  padding-top: 60px; }

.padding-right-60,
.pr-60 {
  padding-right: 60px; }

.padding-bottom-60,
.pb-60 {
  padding-bottom: 60px; }

.padding-left-60,
.pl-60 {
  padding-left: 60px; }

.padding-horizontal-60,
.ph-60 {
  padding-left: 60px;
  padding-right: 60px; }

.padding-vertical-60,
.pv-60 {
  padding-top: 60px;
  padding-bottom: 60px; }

.padding-65,
.p-65 {
  padding: 65px; }

.padding-top-65,
.pt-65 {
  padding-top: 65px; }

.padding-right-65,
.pr-65 {
  padding-right: 65px; }

.padding-bottom-65,
.pb-65 {
  padding-bottom: 65px; }

.padding-left-65,
.pl-65 {
  padding-left: 65px; }

.padding-horizontal-65,
.ph-65 {
  padding-left: 65px;
  padding-right: 65px; }

.padding-vertical-65,
.pv-65 {
  padding-top: 65px;
  padding-bottom: 65px; }

.padding-70,
.p-70 {
  padding: 70px; }

.padding-top-70,
.pt-70 {
  padding-top: 70px; }

.padding-right-70,
.pr-70 {
  padding-right: 70px; }

.padding-bottom-70,
.pb-70 {
  padding-bottom: 70px; }

.padding-left-70,
.pl-70 {
  padding-left: 70px; }

.padding-horizontal-70,
.ph-70 {
  padding-left: 70px;
  padding-right: 70px; }

.padding-vertical-70,
.pv-70 {
  padding-top: 70px;
  padding-bottom: 70px; }

.padding-75,
.p-75 {
  padding: 75px; }

.padding-top-75,
.pt-75 {
  padding-top: 75px; }

.padding-right-75,
.pr-75 {
  padding-right: 75px; }

.padding-bottom-75,
.pb-75 {
  padding-bottom: 75px; }

.padding-left-75,
.pl-75 {
  padding-left: 75px; }

.padding-horizontal-75,
.ph-75 {
  padding-left: 75px;
  padding-right: 75px; }

.padding-vertical-75,
.pv-75 {
  padding-top: 75px;
  padding-bottom: 75px; }

.padding-80,
.p-80 {
  padding: 80px; }

.padding-top-80,
.pt-80 {
  padding-top: 80px; }

.padding-right-80,
.pr-80 {
  padding-right: 80px; }

.padding-bottom-80,
.pb-80 {
  padding-bottom: 80px; }

.padding-left-80,
.pl-80 {
  padding-left: 80px; }

.padding-horizontal-80,
.ph-80 {
  padding-left: 80px;
  padding-right: 80px; }

.padding-vertical-80,
.pv-80 {
  padding-top: 80px;
  padding-bottom: 80px; }

.padding-85,
.p-85 {
  padding: 85px; }

.padding-top-85,
.pt-85 {
  padding-top: 85px; }

.padding-right-85,
.pr-85 {
  padding-right: 85px; }

.padding-bottom-85,
.pb-85 {
  padding-bottom: 85px; }

.padding-left-85,
.pl-85 {
  padding-left: 85px; }

.padding-horizontal-85,
.ph-85 {
  padding-left: 85px;
  padding-right: 85px; }

.padding-vertical-85,
.pv-85 {
  padding-top: 85px;
  padding-bottom: 85px; }

.padding-90,
.p-90 {
  padding: 90px; }

.padding-top-90,
.pt-90 {
  padding-top: 90px; }

.padding-right-90,
.pr-90 {
  padding-right: 90px; }

.padding-bottom-90,
.pb-90 {
  padding-bottom: 90px; }

.padding-left-90,
.pl-90 {
  padding-left: 90px; }

.padding-horizontal-90,
.ph-90 {
  padding-left: 90px;
  padding-right: 90px; }

.padding-vertical-90,
.pv-90 {
  padding-top: 90px;
  padding-bottom: 90px; }

.padding-95,
.p-95 {
  padding: 95px; }

.padding-top-95,
.pt-95 {
  padding-top: 95px; }

.padding-right-95,
.pr-95 {
  padding-right: 95px; }

.padding-bottom-95,
.pb-95 {
  padding-bottom: 95px; }

.padding-left-95,
.pl-95 {
  padding-left: 95px; }

.padding-horizontal-95,
.ph-95 {
  padding-left: 95px;
  padding-right: 95px; }

.padding-vertical-95,
.pv-95 {
  padding-top: 95px;
  padding-bottom: 95px; }

.padding-100,
.p-100 {
  padding: 100px; }

.padding-top-100,
.pt-100 {
  padding-top: 100px; }

.padding-right-100,
.pr-100 {
  padding-right: 100px; }

.padding-bottom-100,
.pb-100 {
  padding-bottom: 100px; }

.padding-left-100,
.pl-100 {
  padding-left: 100px; }

.padding-horizontal-100,
.ph-100 {
  padding-left: 100px;
  padding-right: 100px; }

.padding-vertical-100,
.pv-100 {
  padding-top: 100px;
  padding-bottom: 100px; }

.padding-8,
.p-8 {
  padding: 8px; }

.padding-top-8,
.pt-8 {
  padding-top: 8px; }

.padding-right-8,
.pr-8 {
  padding-right: 8px; }

.padding-bottom-8,
.pb-8 {
  padding-bottom: 8px; }

.padding-left-8,
.pl-8 {
  padding-left: 8px; }

.padding-horizontal-8,
.ph-8 {
  padding-left: 8px;
  padding-right: 8px; }

.padding-vertical-8,
.pv-8 {
  padding-top: 8px;
  padding-bottom: 8px; }

.padding-16,
.p-16 {
  padding: 16px; }

.padding-top-16,
.pt-16 {
  padding-top: 16px; }

.padding-right-16,
.pr-16 {
  padding-right: 16px; }

.padding-bottom-16,
.pb-16 {
  padding-bottom: 16px; }

.padding-left-16,
.pl-16 {
  padding-left: 16px; }

.padding-horizontal-16,
.ph-16 {
  padding-left: 16px;
  padding-right: 16px; }

.padding-vertical-16,
.pv-16 {
  padding-top: 16px;
  padding-bottom: 16px; }

.padding-24,
.p-24 {
  padding: 24px; }

.padding-top-24,
.pt-24 {
  padding-top: 24px; }

.padding-right-24,
.pr-24 {
  padding-right: 24px; }

.padding-bottom-24,
.pb-24 {
  padding-bottom: 24px; }

.padding-left-24,
.pl-24 {
  padding-left: 24px; }

.padding-horizontal-24,
.ph-24 {
  padding-left: 24px;
  padding-right: 24px; }

.padding-vertical-24,
.pv-24 {
  padding-top: 24px;
  padding-bottom: 24px; }

.padding-32,
.p-32 {
  padding: 32px; }

.padding-top-32,
.pt-32 {
  padding-top: 32px; }

.padding-right-32,
.pr-32 {
  padding-right: 32px; }

.padding-bottom-32,
.pb-32 {
  padding-bottom: 32px; }

.padding-left-32,
.pl-32 {
  padding-left: 32px; }

.padding-horizontal-32,
.ph-32 {
  padding-left: 32px;
  padding-right: 32px; }

.padding-vertical-32,
.pv-32 {
  padding-top: 32px;
  padding-bottom: 32px; }

.padding-48,
.p-48 {
  padding: 48px; }

.padding-top-48,
.pt-48 {
  padding-top: 48px; }

.padding-right-48,
.pr-48 {
  padding-right: 48px; }

.padding-bottom-48,
.pb-48 {
  padding-bottom: 48px; }

.padding-left-48,
.pl-48 {
  padding-left: 48px; }

.padding-horizontal-48,
.ph-48 {
  padding-left: 48px;
  padding-right: 48px; }

.padding-vertical-48,
.pv-48 {
  padding-top: 48px;
  padding-bottom: 48px; }

.padding-56,
.p-56 {
  padding: 56px; }

.padding-top-56,
.pt-56 {
  padding-top: 56px; }

.padding-right-56,
.pr-56 {
  padding-right: 56px; }

.padding-bottom-56,
.pb-56 {
  padding-bottom: 56px; }

.padding-left-56,
.pl-56 {
  padding-left: 56px; }

.padding-horizontal-56,
.ph-56 {
  padding-left: 56px;
  padding-right: 56px; }

.padding-vertical-56,
.pv-56 {
  padding-top: 56px;
  padding-bottom: 56px; }

.padding-64,
.p-64 {
  padding: 64px; }

.padding-top-64,
.pt-64 {
  padding-top: 64px; }

.padding-right-64,
.pr-64 {
  padding-right: 64px; }

.padding-bottom-64,
.pb-64 {
  padding-bottom: 64px; }

.padding-left-64,
.pl-64 {
  padding-left: 64px; }

.padding-horizontal-64,
.ph-64 {
  padding-left: 64px;
  padding-right: 64px; }

.padding-vertical-64,
.pv-64 {
  padding-top: 64px;
  padding-bottom: 64px; }

.padding-72,
.p-72 {
  padding: 72px; }

.padding-top-72,
.pt-72 {
  padding-top: 72px; }

.padding-right-72,
.pr-72 {
  padding-right: 72px; }

.padding-bottom-72,
.pb-72 {
  padding-bottom: 72px; }

.padding-left-72,
.pl-72 {
  padding-left: 72px; }

.padding-horizontal-72,
.ph-72 {
  padding-left: 72px;
  padding-right: 72px; }

.padding-vertical-72,
.pv-72 {
  padding-top: 72px;
  padding-bottom: 72px; }

.padding-88,
.p-88 {
  padding: 88px; }

.padding-top-88,
.pt-88 {
  padding-top: 88px; }

.padding-right-88,
.pr-88 {
  padding-right: 88px; }

.padding-bottom-88,
.pb-88 {
  padding-bottom: 88px; }

.padding-left-88,
.pl-88 {
  padding-left: 88px; }

.padding-horizontal-88,
.ph-88 {
  padding-left: 88px;
  padding-right: 88px; }

.padding-vertical-88,
.pv-88 {
  padding-top: 88px;
  padding-bottom: 88px; }

.padding-96,
.p-96 {
  padding: 96px; }

.padding-top-96,
.pt-96 {
  padding-top: 96px; }

.padding-right-96,
.pr-96 {
  padding-right: 96px; }

.padding-bottom-96,
.pb-96 {
  padding-bottom: 96px; }

.padding-left-96,
.pl-96 {
  padding-left: 96px; }

.padding-horizontal-96,
.ph-96 {
  padding-left: 96px;
  padding-right: 96px; }

.padding-vertical-96,
.pv-96 {
  padding-top: 96px;
  padding-bottom: 96px; }

.margin-0,
.m-0 {
  margin: 0px; }

.margin-top-0,
.mt-0 {
  margin-top: 0px; }

.margin-right-0,
.mr-0 {
  margin-right: 0px; }

.margin-bottom-0,
.mb-0 {
  margin-bottom: 0px; }

.margin-left-0,
.ml-0 {
  margin-left: 0px; }

.margin-horizontal-0,
.mh-0 {
  margin-left: 0px;
  margin-right: 0px; }

.margin-vertical-0,
.mv-0 {
  margin-top: 0px;
  margin-bottom: 0px; }

.margin-5,
.m-5 {
  margin: 5px; }

.margin-top-5,
.mt-5 {
  margin-top: 5px; }

.margin-right-5,
.mr-5 {
  margin-right: 5px; }

.margin-bottom-5,
.mb-5 {
  margin-bottom: 5px; }

.margin-left-5,
.ml-5 {
  margin-left: 5px; }

.margin-horizontal-5,
.mh-5 {
  margin-left: 5px;
  margin-right: 5px; }

.margin-vertical-5,
.mv-5 {
  margin-top: 5px;
  margin-bottom: 5px; }

.margin-10,
.m-10 {
  margin: 10px; }

.margin-top-10,
.mt-10 {
  margin-top: 10px; }

.margin-right-10,
.mr-10 {
  margin-right: 10px; }

.margin-bottom-10,
.mb-10 {
  margin-bottom: 10px; }

.margin-left-10,
.ml-10 {
  margin-left: 10px; }

.margin-horizontal-10,
.mh-10 {
  margin-left: 10px;
  margin-right: 10px; }

.margin-vertical-10,
.mv-10 {
  margin-top: 10px;
  margin-bottom: 10px; }

.margin-15,
.m-15 {
  margin: 15px; }

.margin-top-15,
.mt-15 {
  margin-top: 15px; }

.margin-right-15,
.mr-15 {
  margin-right: 15px; }

.margin-bottom-15,
.mb-15 {
  margin-bottom: 15px; }

.margin-left-15,
.ml-15 {
  margin-left: 15px; }

.margin-horizontal-15,
.mh-15 {
  margin-left: 15px;
  margin-right: 15px; }

.margin-vertical-15,
.mv-15 {
  margin-top: 15px;
  margin-bottom: 15px; }

.margin-20,
.m-20 {
  margin: 20px; }

.margin-top-20,
.mt-20 {
  margin-top: 20px; }

.margin-right-20,
.mr-20 {
  margin-right: 20px; }

.margin-bottom-20,
.mb-20 {
  margin-bottom: 20px; }

.margin-left-20,
.ml-20 {
  margin-left: 20px; }

.margin-horizontal-20,
.mh-20 {
  margin-left: 20px;
  margin-right: 20px; }

.margin-vertical-20,
.mv-20 {
  margin-top: 20px;
  margin-bottom: 20px; }

.margin-25,
.m-25 {
  margin: 25px; }

.margin-top-25,
.mt-25 {
  margin-top: 25px; }

.margin-right-25,
.mr-25 {
  margin-right: 25px; }

.margin-bottom-25,
.mb-25 {
  margin-bottom: 25px; }

.margin-left-25,
.ml-25 {
  margin-left: 25px; }

.margin-horizontal-25,
.mh-25 {
  margin-left: 25px;
  margin-right: 25px; }

.margin-vertical-25,
.mv-25 {
  margin-top: 25px;
  margin-bottom: 25px; }

.margin-30,
.m-30 {
  margin: 30px; }

.margin-top-30,
.mt-30 {
  margin-top: 30px; }

.margin-right-30,
.mr-30 {
  margin-right: 30px; }

.margin-bottom-30,
.mb-30 {
  margin-bottom: 30px; }

.margin-left-30,
.ml-30 {
  margin-left: 30px; }

.margin-horizontal-30,
.mh-30 {
  margin-left: 30px;
  margin-right: 30px; }

.margin-vertical-30,
.mv-30 {
  margin-top: 30px;
  margin-bottom: 30px; }

.margin-35,
.m-35 {
  margin: 35px; }

.margin-top-35,
.mt-35 {
  margin-top: 35px; }

.margin-right-35,
.mr-35 {
  margin-right: 35px; }

.margin-bottom-35,
.mb-35 {
  margin-bottom: 35px; }

.margin-left-35,
.ml-35 {
  margin-left: 35px; }

.margin-horizontal-35,
.mh-35 {
  margin-left: 35px;
  margin-right: 35px; }

.margin-vertical-35,
.mv-35 {
  margin-top: 35px;
  margin-bottom: 35px; }

.margin-40,
.m-40 {
  margin: 40px; }

.margin-top-40,
.mt-40 {
  margin-top: 40px; }

.margin-right-40,
.mr-40 {
  margin-right: 40px; }

.margin-bottom-40,
.mb-40 {
  margin-bottom: 40px; }

.margin-left-40,
.ml-40 {
  margin-left: 40px; }

.margin-horizontal-40,
.mh-40 {
  margin-left: 40px;
  margin-right: 40px; }

.margin-vertical-40,
.mv-40 {
  margin-top: 40px;
  margin-bottom: 40px; }

.margin-45,
.m-45 {
  margin: 45px; }

.margin-top-45,
.mt-45 {
  margin-top: 45px; }

.margin-right-45,
.mr-45 {
  margin-right: 45px; }

.margin-bottom-45,
.mb-45 {
  margin-bottom: 45px; }

.margin-left-45,
.ml-45 {
  margin-left: 45px; }

.margin-horizontal-45,
.mh-45 {
  margin-left: 45px;
  margin-right: 45px; }

.margin-vertical-45,
.mv-45 {
  margin-top: 45px;
  margin-bottom: 45px; }

.margin-50,
.m-50 {
  margin: 50px; }

.margin-top-50,
.mt-50 {
  margin-top: 50px; }

.margin-right-50,
.mr-50 {
  margin-right: 50px; }

.margin-bottom-50,
.mb-50 {
  margin-bottom: 50px; }

.margin-left-50,
.ml-50 {
  margin-left: 50px; }

.margin-horizontal-50,
.mh-50 {
  margin-left: 50px;
  margin-right: 50px; }

.margin-vertical-50,
.mv-50 {
  margin-top: 50px;
  margin-bottom: 50px; }

.margin-55,
.m-55 {
  margin: 55px; }

.margin-top-55,
.mt-55 {
  margin-top: 55px; }

.margin-right-55,
.mr-55 {
  margin-right: 55px; }

.margin-bottom-55,
.mb-55 {
  margin-bottom: 55px; }

.margin-left-55,
.ml-55 {
  margin-left: 55px; }

.margin-horizontal-55,
.mh-55 {
  margin-left: 55px;
  margin-right: 55px; }

.margin-vertical-55,
.mv-55 {
  margin-top: 55px;
  margin-bottom: 55px; }

.margin-60,
.m-60 {
  margin: 60px; }

.margin-top-60,
.mt-60 {
  margin-top: 60px; }

.margin-right-60,
.mr-60 {
  margin-right: 60px; }

.margin-bottom-60,
.mb-60 {
  margin-bottom: 60px; }

.margin-left-60,
.ml-60 {
  margin-left: 60px; }

.margin-horizontal-60,
.mh-60 {
  margin-left: 60px;
  margin-right: 60px; }

.margin-vertical-60,
.mv-60 {
  margin-top: 60px;
  margin-bottom: 60px; }

.margin-65,
.m-65 {
  margin: 65px; }

.margin-top-65,
.mt-65 {
  margin-top: 65px; }

.margin-right-65,
.mr-65 {
  margin-right: 65px; }

.margin-bottom-65,
.mb-65 {
  margin-bottom: 65px; }

.margin-left-65,
.ml-65 {
  margin-left: 65px; }

.margin-horizontal-65,
.mh-65 {
  margin-left: 65px;
  margin-right: 65px; }

.margin-vertical-65,
.mv-65 {
  margin-top: 65px;
  margin-bottom: 65px; }

.margin-70,
.m-70 {
  margin: 70px; }

.margin-top-70,
.mt-70 {
  margin-top: 70px; }

.margin-right-70,
.mr-70 {
  margin-right: 70px; }

.margin-bottom-70,
.mb-70 {
  margin-bottom: 70px; }

.margin-left-70,
.ml-70 {
  margin-left: 70px; }

.margin-horizontal-70,
.mh-70 {
  margin-left: 70px;
  margin-right: 70px; }

.margin-vertical-70,
.mv-70 {
  margin-top: 70px;
  margin-bottom: 70px; }

.margin-75,
.m-75 {
  margin: 75px; }

.margin-top-75,
.mt-75 {
  margin-top: 75px; }

.margin-right-75,
.mr-75 {
  margin-right: 75px; }

.margin-bottom-75,
.mb-75 {
  margin-bottom: 75px; }

.margin-left-75,
.ml-75 {
  margin-left: 75px; }

.margin-horizontal-75,
.mh-75 {
  margin-left: 75px;
  margin-right: 75px; }

.margin-vertical-75,
.mv-75 {
  margin-top: 75px;
  margin-bottom: 75px; }

.margin-80,
.m-80 {
  margin: 80px; }

.margin-top-80,
.mt-80 {
  margin-top: 80px; }

.margin-right-80,
.mr-80 {
  margin-right: 80px; }

.margin-bottom-80,
.mb-80 {
  margin-bottom: 80px; }

.margin-left-80,
.ml-80 {
  margin-left: 80px; }

.margin-horizontal-80,
.mh-80 {
  margin-left: 80px;
  margin-right: 80px; }

.margin-vertical-80,
.mv-80 {
  margin-top: 80px;
  margin-bottom: 80px; }

.margin-85,
.m-85 {
  margin: 85px; }

.margin-top-85,
.mt-85 {
  margin-top: 85px; }

.margin-right-85,
.mr-85 {
  margin-right: 85px; }

.margin-bottom-85,
.mb-85 {
  margin-bottom: 85px; }

.margin-left-85,
.ml-85 {
  margin-left: 85px; }

.margin-horizontal-85,
.mh-85 {
  margin-left: 85px;
  margin-right: 85px; }

.margin-vertical-85,
.mv-85 {
  margin-top: 85px;
  margin-bottom: 85px; }

.margin-90,
.m-90 {
  margin: 90px; }

.margin-top-90,
.mt-90 {
  margin-top: 90px; }

.margin-right-90,
.mr-90 {
  margin-right: 90px; }

.margin-bottom-90,
.mb-90 {
  margin-bottom: 90px; }

.margin-left-90,
.ml-90 {
  margin-left: 90px; }

.margin-horizontal-90,
.mh-90 {
  margin-left: 90px;
  margin-right: 90px; }

.margin-vertical-90,
.mv-90 {
  margin-top: 90px;
  margin-bottom: 90px; }

.margin-95,
.m-95 {
  margin: 95px; }

.margin-top-95,
.mt-95 {
  margin-top: 95px; }

.margin-right-95,
.mr-95 {
  margin-right: 95px; }

.margin-bottom-95,
.mb-95 {
  margin-bottom: 95px; }

.margin-left-95,
.ml-95 {
  margin-left: 95px; }

.margin-horizontal-95,
.mh-95 {
  margin-left: 95px;
  margin-right: 95px; }

.margin-vertical-95,
.mv-95 {
  margin-top: 95px;
  margin-bottom: 95px; }

.margin-100,
.m-100 {
  margin: 100px; }

.margin-top-100,
.mt-100 {
  margin-top: 100px; }

.margin-right-100,
.mr-100 {
  margin-right: 100px; }

.margin-bottom-100,
.mb-100 {
  margin-bottom: 100px; }

.margin-left-100,
.ml-100 {
  margin-left: 100px; }

.margin-horizontal-100,
.mh-100 {
  margin-left: 100px;
  margin-right: 100px; }

.margin-vertical-100,
.mv-100 {
  margin-top: 100px;
  margin-bottom: 100px; }

.margin-8,
.m-8 {
  margin: 8px; }

.margin-top-8,
.mt-8 {
  margin-top: 8px; }

.margin-right-8,
.mr-8 {
  margin-right: 8px; }

.margin-bottom-8,
.mb-8 {
  margin-bottom: 8px; }

.margin-left-8,
.ml-8 {
  margin-left: 8px; }

.margin-horizontal-8,
.mh-8 {
  margin-left: 8px;
  margin-right: 8px; }

.margin-vertical-8,
.mv-8 {
  margin-top: 8px;
  margin-bottom: 8px; }

.margin-16,
.m-16 {
  margin: 16px; }

.margin-top-16,
.mt-16 {
  margin-top: 16px; }

.margin-right-16,
.mr-16 {
  margin-right: 16px; }

.margin-bottom-16,
.mb-16 {
  margin-bottom: 16px; }

.margin-left-16,
.ml-16 {
  margin-left: 16px; }

.margin-horizontal-16,
.mh-16 {
  margin-left: 16px;
  margin-right: 16px; }

.margin-vertical-16,
.mv-16 {
  margin-top: 16px;
  margin-bottom: 16px; }

.margin-24,
.m-24 {
  margin: 24px; }

.margin-top-24,
.mt-24 {
  margin-top: 24px; }

.margin-right-24,
.mr-24 {
  margin-right: 24px; }

.margin-bottom-24,
.mb-24 {
  margin-bottom: 24px; }

.margin-left-24,
.ml-24 {
  margin-left: 24px; }

.margin-horizontal-24,
.mh-24 {
  margin-left: 24px;
  margin-right: 24px; }

.margin-vertical-24,
.mv-24 {
  margin-top: 24px;
  margin-bottom: 24px; }

.margin-32,
.m-32 {
  margin: 32px; }

.margin-top-32,
.mt-32 {
  margin-top: 32px; }

.margin-right-32,
.mr-32 {
  margin-right: 32px; }

.margin-bottom-32,
.mb-32 {
  margin-bottom: 32px; }

.margin-left-32,
.ml-32 {
  margin-left: 32px; }

.margin-horizontal-32,
.mh-32 {
  margin-left: 32px;
  margin-right: 32px; }

.margin-vertical-32,
.mv-32 {
  margin-top: 32px;
  margin-bottom: 32px; }

.margin-48,
.m-48 {
  margin: 48px; }

.margin-top-48,
.mt-48 {
  margin-top: 48px; }

.margin-right-48,
.mr-48 {
  margin-right: 48px; }

.margin-bottom-48,
.mb-48 {
  margin-bottom: 48px; }

.margin-left-48,
.ml-48 {
  margin-left: 48px; }

.margin-horizontal-48,
.mh-48 {
  margin-left: 48px;
  margin-right: 48px; }

.margin-vertical-48,
.mv-48 {
  margin-top: 48px;
  margin-bottom: 48px; }

.margin-56,
.m-56 {
  margin: 56px; }

.margin-top-56,
.mt-56 {
  margin-top: 56px; }

.margin-right-56,
.mr-56 {
  margin-right: 56px; }

.margin-bottom-56,
.mb-56 {
  margin-bottom: 56px; }

.margin-left-56,
.ml-56 {
  margin-left: 56px; }

.margin-horizontal-56,
.mh-56 {
  margin-left: 56px;
  margin-right: 56px; }

.margin-vertical-56,
.mv-56 {
  margin-top: 56px;
  margin-bottom: 56px; }

.margin-64,
.m-64 {
  margin: 64px; }

.margin-top-64,
.mt-64 {
  margin-top: 64px; }

.margin-right-64,
.mr-64 {
  margin-right: 64px; }

.margin-bottom-64,
.mb-64 {
  margin-bottom: 64px; }

.margin-left-64,
.ml-64 {
  margin-left: 64px; }

.margin-horizontal-64,
.mh-64 {
  margin-left: 64px;
  margin-right: 64px; }

.margin-vertical-64,
.mv-64 {
  margin-top: 64px;
  margin-bottom: 64px; }

.margin-72,
.m-72 {
  margin: 72px; }

.margin-top-72,
.mt-72 {
  margin-top: 72px; }

.margin-right-72,
.mr-72 {
  margin-right: 72px; }

.margin-bottom-72,
.mb-72 {
  margin-bottom: 72px; }

.margin-left-72,
.ml-72 {
  margin-left: 72px; }

.margin-horizontal-72,
.mh-72 {
  margin-left: 72px;
  margin-right: 72px; }

.margin-vertical-72,
.mv-72 {
  margin-top: 72px;
  margin-bottom: 72px; }

.margin-88,
.m-88 {
  margin: 88px; }

.margin-top-88,
.mt-88 {
  margin-top: 88px; }

.margin-right-88,
.mr-88 {
  margin-right: 88px; }

.margin-bottom-88,
.mb-88 {
  margin-bottom: 88px; }

.margin-left-88,
.ml-88 {
  margin-left: 88px; }

.margin-horizontal-88,
.mh-88 {
  margin-left: 88px;
  margin-right: 88px; }

.margin-vertical-88,
.mv-88 {
  margin-top: 88px;
  margin-bottom: 88px; }

.margin-96,
.m-96 {
  margin: 96px; }

.margin-top-96,
.mt-96 {
  margin-top: 96px; }

.margin-right-96,
.mr-96 {
  margin-right: 96px; }

.margin-bottom-96,
.mb-96 {
  margin-bottom: 96px; }

.margin-left-96,
.ml-96 {
  margin-left: 96px; }

.margin-horizontal-96,
.mh-96 {
  margin-left: 96px;
  margin-right: 96px; }

.margin-vertical-96,
.mv-96 {
  margin-top: 96px;
  margin-bottom: 96px; }

.height-0,
.h-0 {
  height: 0px !important;
  min-height: 0px !important;
  max-height: 0px !important; }

.width-0,
.w-0 {
  width: 0px !important;
  min-width: 0px !important;
  max-width: 0px !important; }

.height-5,
.h-5 {
  height: 5px !important;
  min-height: 5px !important;
  max-height: 5px !important; }

.width-5,
.w-5 {
  width: 5px !important;
  min-width: 5px !important;
  max-width: 5px !important; }

.height-10,
.h-10 {
  height: 10px !important;
  min-height: 10px !important;
  max-height: 10px !important; }

.width-10,
.w-10 {
  width: 10px !important;
  min-width: 10px !important;
  max-width: 10px !important; }

.height-15,
.h-15 {
  height: 15px !important;
  min-height: 15px !important;
  max-height: 15px !important; }

.width-15,
.w-15 {
  width: 15px !important;
  min-width: 15px !important;
  max-width: 15px !important; }

.height-20,
.h-20 {
  height: 20px !important;
  min-height: 20px !important;
  max-height: 20px !important; }

.width-20,
.w-20 {
  width: 20px !important;
  min-width: 20px !important;
  max-width: 20px !important; }

.height-25,
.h-25 {
  height: 25px !important;
  min-height: 25px !important;
  max-height: 25px !important; }

.width-25,
.w-25 {
  width: 25px !important;
  min-width: 25px !important;
  max-width: 25px !important; }

.height-30,
.h-30 {
  height: 30px !important;
  min-height: 30px !important;
  max-height: 30px !important; }

.width-30,
.w-30 {
  width: 30px !important;
  min-width: 30px !important;
  max-width: 30px !important; }

.height-35,
.h-35 {
  height: 35px !important;
  min-height: 35px !important;
  max-height: 35px !important; }

.width-35,
.w-35 {
  width: 35px !important;
  min-width: 35px !important;
  max-width: 35px !important; }

.height-40,
.h-40 {
  height: 40px !important;
  min-height: 40px !important;
  max-height: 40px !important; }

.width-40,
.w-40 {
  width: 40px !important;
  min-width: 40px !important;
  max-width: 40px !important; }

.height-45,
.h-45 {
  height: 45px !important;
  min-height: 45px !important;
  max-height: 45px !important; }

.width-45,
.w-45 {
  width: 45px !important;
  min-width: 45px !important;
  max-width: 45px !important; }

.height-50,
.h-50 {
  height: 50px !important;
  min-height: 50px !important;
  max-height: 50px !important; }

.width-50,
.w-50 {
  width: 50px !important;
  min-width: 50px !important;
  max-width: 50px !important; }

.height-55,
.h-55 {
  height: 55px !important;
  min-height: 55px !important;
  max-height: 55px !important; }

.width-55,
.w-55 {
  width: 55px !important;
  min-width: 55px !important;
  max-width: 55px !important; }

.height-60,
.h-60 {
  height: 60px !important;
  min-height: 60px !important;
  max-height: 60px !important; }

.width-60,
.w-60 {
  width: 60px !important;
  min-width: 60px !important;
  max-width: 60px !important; }

.height-65,
.h-65 {
  height: 65px !important;
  min-height: 65px !important;
  max-height: 65px !important; }

.width-65,
.w-65 {
  width: 65px !important;
  min-width: 65px !important;
  max-width: 65px !important; }

.height-70,
.h-70 {
  height: 70px !important;
  min-height: 70px !important;
  max-height: 70px !important; }

.width-70,
.w-70 {
  width: 70px !important;
  min-width: 70px !important;
  max-width: 70px !important; }

.height-75,
.h-75 {
  height: 75px !important;
  min-height: 75px !important;
  max-height: 75px !important; }

.width-75,
.w-75 {
  width: 75px !important;
  min-width: 75px !important;
  max-width: 75px !important; }

.height-80,
.h-80 {
  height: 80px !important;
  min-height: 80px !important;
  max-height: 80px !important; }

.width-80,
.w-80 {
  width: 80px !important;
  min-width: 80px !important;
  max-width: 80px !important; }

.height-85,
.h-85 {
  height: 85px !important;
  min-height: 85px !important;
  max-height: 85px !important; }

.width-85,
.w-85 {
  width: 85px !important;
  min-width: 85px !important;
  max-width: 85px !important; }

.height-90,
.h-90 {
  height: 90px !important;
  min-height: 90px !important;
  max-height: 90px !important; }

.width-90,
.w-90 {
  width: 90px !important;
  min-width: 90px !important;
  max-width: 90px !important; }

.height-95,
.h-95 {
  height: 95px !important;
  min-height: 95px !important;
  max-height: 95px !important; }

.width-95,
.w-95 {
  width: 95px !important;
  min-width: 95px !important;
  max-width: 95px !important; }

.height-100,
.h-100 {
  height: 100px !important;
  min-height: 100px !important;
  max-height: 100px !important; }

.width-100,
.w-100 {
  width: 100px !important;
  min-width: 100px !important;
  max-width: 100px !important; }

.height-105,
.h-105 {
  height: 105px !important;
  min-height: 105px !important;
  max-height: 105px !important; }

.width-105,
.w-105 {
  width: 105px !important;
  min-width: 105px !important;
  max-width: 105px !important; }

.height-110,
.h-110 {
  height: 110px !important;
  min-height: 110px !important;
  max-height: 110px !important; }

.width-110,
.w-110 {
  width: 110px !important;
  min-width: 110px !important;
  max-width: 110px !important; }

.height-115,
.h-115 {
  height: 115px !important;
  min-height: 115px !important;
  max-height: 115px !important; }

.width-115,
.w-115 {
  width: 115px !important;
  min-width: 115px !important;
  max-width: 115px !important; }

.height-120,
.h-120 {
  height: 120px !important;
  min-height: 120px !important;
  max-height: 120px !important; }

.width-120,
.w-120 {
  width: 120px !important;
  min-width: 120px !important;
  max-width: 120px !important; }

.height-125,
.h-125 {
  height: 125px !important;
  min-height: 125px !important;
  max-height: 125px !important; }

.width-125,
.w-125 {
  width: 125px !important;
  min-width: 125px !important;
  max-width: 125px !important; }

.height-130,
.h-130 {
  height: 130px !important;
  min-height: 130px !important;
  max-height: 130px !important; }

.width-130,
.w-130 {
  width: 130px !important;
  min-width: 130px !important;
  max-width: 130px !important; }

.height-135,
.h-135 {
  height: 135px !important;
  min-height: 135px !important;
  max-height: 135px !important; }

.width-135,
.w-135 {
  width: 135px !important;
  min-width: 135px !important;
  max-width: 135px !important; }

.height-140,
.h-140 {
  height: 140px !important;
  min-height: 140px !important;
  max-height: 140px !important; }

.width-140,
.w-140 {
  width: 140px !important;
  min-width: 140px !important;
  max-width: 140px !important; }

.height-145,
.h-145 {
  height: 145px !important;
  min-height: 145px !important;
  max-height: 145px !important; }

.width-145,
.w-145 {
  width: 145px !important;
  min-width: 145px !important;
  max-width: 145px !important; }

.height-150,
.h-150 {
  height: 150px !important;
  min-height: 150px !important;
  max-height: 150px !important; }

.width-150,
.w-150 {
  width: 150px !important;
  min-width: 150px !important;
  max-width: 150px !important; }

.height-155,
.h-155 {
  height: 155px !important;
  min-height: 155px !important;
  max-height: 155px !important; }

.width-155,
.w-155 {
  width: 155px !important;
  min-width: 155px !important;
  max-width: 155px !important; }

.height-160,
.h-160 {
  height: 160px !important;
  min-height: 160px !important;
  max-height: 160px !important; }

.width-160,
.w-160 {
  width: 160px !important;
  min-width: 160px !important;
  max-width: 160px !important; }

.height-165,
.h-165 {
  height: 165px !important;
  min-height: 165px !important;
  max-height: 165px !important; }

.width-165,
.w-165 {
  width: 165px !important;
  min-width: 165px !important;
  max-width: 165px !important; }

.height-170,
.h-170 {
  height: 170px !important;
  min-height: 170px !important;
  max-height: 170px !important; }

.width-170,
.w-170 {
  width: 170px !important;
  min-width: 170px !important;
  max-width: 170px !important; }

.height-175,
.h-175 {
  height: 175px !important;
  min-height: 175px !important;
  max-height: 175px !important; }

.width-175,
.w-175 {
  width: 175px !important;
  min-width: 175px !important;
  max-width: 175px !important; }

.height-180,
.h-180 {
  height: 180px !important;
  min-height: 180px !important;
  max-height: 180px !important; }

.width-180,
.w-180 {
  width: 180px !important;
  min-width: 180px !important;
  max-width: 180px !important; }

.height-185,
.h-185 {
  height: 185px !important;
  min-height: 185px !important;
  max-height: 185px !important; }

.width-185,
.w-185 {
  width: 185px !important;
  min-width: 185px !important;
  max-width: 185px !important; }

.height-190,
.h-190 {
  height: 190px !important;
  min-height: 190px !important;
  max-height: 190px !important; }

.width-190,
.w-190 {
  width: 190px !important;
  min-width: 190px !important;
  max-width: 190px !important; }

.height-195,
.h-195 {
  height: 195px !important;
  min-height: 195px !important;
  max-height: 195px !important; }

.width-195,
.w-195 {
  width: 195px !important;
  min-width: 195px !important;
  max-width: 195px !important; }

.height-200,
.h-200 {
  height: 200px !important;
  min-height: 200px !important;
  max-height: 200px !important; }

.width-200,
.w-200 {
  width: 200px !important;
  min-width: 200px !important;
  max-width: 200px !important; }

.height-205,
.h-205 {
  height: 205px !important;
  min-height: 205px !important;
  max-height: 205px !important; }

.width-205,
.w-205 {
  width: 205px !important;
  min-width: 205px !important;
  max-width: 205px !important; }

.height-210,
.h-210 {
  height: 210px !important;
  min-height: 210px !important;
  max-height: 210px !important; }

.width-210,
.w-210 {
  width: 210px !important;
  min-width: 210px !important;
  max-width: 210px !important; }

.height-215,
.h-215 {
  height: 215px !important;
  min-height: 215px !important;
  max-height: 215px !important; }

.width-215,
.w-215 {
  width: 215px !important;
  min-width: 215px !important;
  max-width: 215px !important; }

.height-220,
.h-220 {
  height: 220px !important;
  min-height: 220px !important;
  max-height: 220px !important; }

.width-220,
.w-220 {
  width: 220px !important;
  min-width: 220px !important;
  max-width: 220px !important; }

.height-225,
.h-225 {
  height: 225px !important;
  min-height: 225px !important;
  max-height: 225px !important; }

.width-225,
.w-225 {
  width: 225px !important;
  min-width: 225px !important;
  max-width: 225px !important; }

.height-230,
.h-230 {
  height: 230px !important;
  min-height: 230px !important;
  max-height: 230px !important; }

.width-230,
.w-230 {
  width: 230px !important;
  min-width: 230px !important;
  max-width: 230px !important; }

.height-235,
.h-235 {
  height: 235px !important;
  min-height: 235px !important;
  max-height: 235px !important; }

.width-235,
.w-235 {
  width: 235px !important;
  min-width: 235px !important;
  max-width: 235px !important; }

.height-240,
.h-240 {
  height: 240px !important;
  min-height: 240px !important;
  max-height: 240px !important; }

.width-240,
.w-240 {
  width: 240px !important;
  min-width: 240px !important;
  max-width: 240px !important; }

.height-245,
.h-245 {
  height: 245px !important;
  min-height: 245px !important;
  max-height: 245px !important; }

.width-245,
.w-245 {
  width: 245px !important;
  min-width: 245px !important;
  max-width: 245px !important; }

.height-250,
.h-250 {
  height: 250px !important;
  min-height: 250px !important;
  max-height: 250px !important; }

.width-250,
.w-250 {
  width: 250px !important;
  min-width: 250px !important;
  max-width: 250px !important; }

.height-255,
.h-255 {
  height: 255px !important;
  min-height: 255px !important;
  max-height: 255px !important; }

.width-255,
.w-255 {
  width: 255px !important;
  min-width: 255px !important;
  max-width: 255px !important; }

.height-260,
.h-260 {
  height: 260px !important;
  min-height: 260px !important;
  max-height: 260px !important; }

.width-260,
.w-260 {
  width: 260px !important;
  min-width: 260px !important;
  max-width: 260px !important; }

.height-265,
.h-265 {
  height: 265px !important;
  min-height: 265px !important;
  max-height: 265px !important; }

.width-265,
.w-265 {
  width: 265px !important;
  min-width: 265px !important;
  max-width: 265px !important; }

.height-270,
.h-270 {
  height: 270px !important;
  min-height: 270px !important;
  max-height: 270px !important; }

.width-270,
.w-270 {
  width: 270px !important;
  min-width: 270px !important;
  max-width: 270px !important; }

.height-275,
.h-275 {
  height: 275px !important;
  min-height: 275px !important;
  max-height: 275px !important; }

.width-275,
.w-275 {
  width: 275px !important;
  min-width: 275px !important;
  max-width: 275px !important; }

.height-280,
.h-280 {
  height: 280px !important;
  min-height: 280px !important;
  max-height: 280px !important; }

.width-280,
.w-280 {
  width: 280px !important;
  min-width: 280px !important;
  max-width: 280px !important; }

.height-285,
.h-285 {
  height: 285px !important;
  min-height: 285px !important;
  max-height: 285px !important; }

.width-285,
.w-285 {
  width: 285px !important;
  min-width: 285px !important;
  max-width: 285px !important; }

.height-290,
.h-290 {
  height: 290px !important;
  min-height: 290px !important;
  max-height: 290px !important; }

.width-290,
.w-290 {
  width: 290px !important;
  min-width: 290px !important;
  max-width: 290px !important; }

.height-295,
.h-295 {
  height: 295px !important;
  min-height: 295px !important;
  max-height: 295px !important; }

.width-295,
.w-295 {
  width: 295px !important;
  min-width: 295px !important;
  max-width: 295px !important; }

.height-300,
.h-300 {
  height: 300px !important;
  min-height: 300px !important;
  max-height: 300px !important; }

.width-300,
.w-300 {
  width: 300px !important;
  min-width: 300px !important;
  max-width: 300px !important; }

.height-305,
.h-305 {
  height: 305px !important;
  min-height: 305px !important;
  max-height: 305px !important; }

.width-305,
.w-305 {
  width: 305px !important;
  min-width: 305px !important;
  max-width: 305px !important; }

.height-310,
.h-310 {
  height: 310px !important;
  min-height: 310px !important;
  max-height: 310px !important; }

.width-310,
.w-310 {
  width: 310px !important;
  min-width: 310px !important;
  max-width: 310px !important; }

.height-315,
.h-315 {
  height: 315px !important;
  min-height: 315px !important;
  max-height: 315px !important; }

.width-315,
.w-315 {
  width: 315px !important;
  min-width: 315px !important;
  max-width: 315px !important; }

.height-320,
.h-320 {
  height: 320px !important;
  min-height: 320px !important;
  max-height: 320px !important; }

.width-320,
.w-320 {
  width: 320px !important;
  min-width: 320px !important;
  max-width: 320px !important; }

.height-325,
.h-325 {
  height: 325px !important;
  min-height: 325px !important;
  max-height: 325px !important; }

.width-325,
.w-325 {
  width: 325px !important;
  min-width: 325px !important;
  max-width: 325px !important; }

.height-330,
.h-330 {
  height: 330px !important;
  min-height: 330px !important;
  max-height: 330px !important; }

.width-330,
.w-330 {
  width: 330px !important;
  min-width: 330px !important;
  max-width: 330px !important; }

.height-335,
.h-335 {
  height: 335px !important;
  min-height: 335px !important;
  max-height: 335px !important; }

.width-335,
.w-335 {
  width: 335px !important;
  min-width: 335px !important;
  max-width: 335px !important; }

.height-340,
.h-340 {
  height: 340px !important;
  min-height: 340px !important;
  max-height: 340px !important; }

.width-340,
.w-340 {
  width: 340px !important;
  min-width: 340px !important;
  max-width: 340px !important; }

.height-345,
.h-345 {
  height: 345px !important;
  min-height: 345px !important;
  max-height: 345px !important; }

.width-345,
.w-345 {
  width: 345px !important;
  min-width: 345px !important;
  max-width: 345px !important; }

.height-350,
.h-350 {
  height: 350px !important;
  min-height: 350px !important;
  max-height: 350px !important; }

.width-350,
.w-350 {
  width: 350px !important;
  min-width: 350px !important;
  max-width: 350px !important; }

.height-355,
.h-355 {
  height: 355px !important;
  min-height: 355px !important;
  max-height: 355px !important; }

.width-355,
.w-355 {
  width: 355px !important;
  min-width: 355px !important;
  max-width: 355px !important; }

.height-360,
.h-360 {
  height: 360px !important;
  min-height: 360px !important;
  max-height: 360px !important; }

.width-360,
.w-360 {
  width: 360px !important;
  min-width: 360px !important;
  max-width: 360px !important; }

.height-365,
.h-365 {
  height: 365px !important;
  min-height: 365px !important;
  max-height: 365px !important; }

.width-365,
.w-365 {
  width: 365px !important;
  min-width: 365px !important;
  max-width: 365px !important; }

.height-370,
.h-370 {
  height: 370px !important;
  min-height: 370px !important;
  max-height: 370px !important; }

.width-370,
.w-370 {
  width: 370px !important;
  min-width: 370px !important;
  max-width: 370px !important; }

.height-375,
.h-375 {
  height: 375px !important;
  min-height: 375px !important;
  max-height: 375px !important; }

.width-375,
.w-375 {
  width: 375px !important;
  min-width: 375px !important;
  max-width: 375px !important; }

.height-380,
.h-380 {
  height: 380px !important;
  min-height: 380px !important;
  max-height: 380px !important; }

.width-380,
.w-380 {
  width: 380px !important;
  min-width: 380px !important;
  max-width: 380px !important; }

.height-385,
.h-385 {
  height: 385px !important;
  min-height: 385px !important;
  max-height: 385px !important; }

.width-385,
.w-385 {
  width: 385px !important;
  min-width: 385px !important;
  max-width: 385px !important; }

.height-390,
.h-390 {
  height: 390px !important;
  min-height: 390px !important;
  max-height: 390px !important; }

.width-390,
.w-390 {
  width: 390px !important;
  min-width: 390px !important;
  max-width: 390px !important; }

.height-395,
.h-395 {
  height: 395px !important;
  min-height: 395px !important;
  max-height: 395px !important; }

.width-395,
.w-395 {
  width: 395px !important;
  min-width: 395px !important;
  max-width: 395px !important; }

.height-400,
.h-400 {
  height: 400px !important;
  min-height: 400px !important;
  max-height: 400px !important; }

.width-400,
.w-400 {
  width: 400px !important;
  min-width: 400px !important;
  max-width: 400px !important; }

.height-405,
.h-405 {
  height: 405px !important;
  min-height: 405px !important;
  max-height: 405px !important; }

.width-405,
.w-405 {
  width: 405px !important;
  min-width: 405px !important;
  max-width: 405px !important; }

.height-410,
.h-410 {
  height: 410px !important;
  min-height: 410px !important;
  max-height: 410px !important; }

.width-410,
.w-410 {
  width: 410px !important;
  min-width: 410px !important;
  max-width: 410px !important; }

.height-415,
.h-415 {
  height: 415px !important;
  min-height: 415px !important;
  max-height: 415px !important; }

.width-415,
.w-415 {
  width: 415px !important;
  min-width: 415px !important;
  max-width: 415px !important; }

.height-420,
.h-420 {
  height: 420px !important;
  min-height: 420px !important;
  max-height: 420px !important; }

.width-420,
.w-420 {
  width: 420px !important;
  min-width: 420px !important;
  max-width: 420px !important; }

.height-425,
.h-425 {
  height: 425px !important;
  min-height: 425px !important;
  max-height: 425px !important; }

.width-425,
.w-425 {
  width: 425px !important;
  min-width: 425px !important;
  max-width: 425px !important; }

.height-430,
.h-430 {
  height: 430px !important;
  min-height: 430px !important;
  max-height: 430px !important; }

.width-430,
.w-430 {
  width: 430px !important;
  min-width: 430px !important;
  max-width: 430px !important; }

.height-435,
.h-435 {
  height: 435px !important;
  min-height: 435px !important;
  max-height: 435px !important; }

.width-435,
.w-435 {
  width: 435px !important;
  min-width: 435px !important;
  max-width: 435px !important; }

.height-440,
.h-440 {
  height: 440px !important;
  min-height: 440px !important;
  max-height: 440px !important; }

.width-440,
.w-440 {
  width: 440px !important;
  min-width: 440px !important;
  max-width: 440px !important; }

.height-445,
.h-445 {
  height: 445px !important;
  min-height: 445px !important;
  max-height: 445px !important; }

.width-445,
.w-445 {
  width: 445px !important;
  min-width: 445px !important;
  max-width: 445px !important; }

.height-450,
.h-450 {
  height: 450px !important;
  min-height: 450px !important;
  max-height: 450px !important; }

.width-450,
.w-450 {
  width: 450px !important;
  min-width: 450px !important;
  max-width: 450px !important; }

.height-455,
.h-455 {
  height: 455px !important;
  min-height: 455px !important;
  max-height: 455px !important; }

.width-455,
.w-455 {
  width: 455px !important;
  min-width: 455px !important;
  max-width: 455px !important; }

.height-460,
.h-460 {
  height: 460px !important;
  min-height: 460px !important;
  max-height: 460px !important; }

.width-460,
.w-460 {
  width: 460px !important;
  min-width: 460px !important;
  max-width: 460px !important; }

.height-465,
.h-465 {
  height: 465px !important;
  min-height: 465px !important;
  max-height: 465px !important; }

.width-465,
.w-465 {
  width: 465px !important;
  min-width: 465px !important;
  max-width: 465px !important; }

.height-470,
.h-470 {
  height: 470px !important;
  min-height: 470px !important;
  max-height: 470px !important; }

.width-470,
.w-470 {
  width: 470px !important;
  min-width: 470px !important;
  max-width: 470px !important; }

.height-475,
.h-475 {
  height: 475px !important;
  min-height: 475px !important;
  max-height: 475px !important; }

.width-475,
.w-475 {
  width: 475px !important;
  min-width: 475px !important;
  max-width: 475px !important; }

.height-480,
.h-480 {
  height: 480px !important;
  min-height: 480px !important;
  max-height: 480px !important; }

.width-480,
.w-480 {
  width: 480px !important;
  min-width: 480px !important;
  max-width: 480px !important; }

.height-485,
.h-485 {
  height: 485px !important;
  min-height: 485px !important;
  max-height: 485px !important; }

.width-485,
.w-485 {
  width: 485px !important;
  min-width: 485px !important;
  max-width: 485px !important; }

.height-490,
.h-490 {
  height: 490px !important;
  min-height: 490px !important;
  max-height: 490px !important; }

.width-490,
.w-490 {
  width: 490px !important;
  min-width: 490px !important;
  max-width: 490px !important; }

.height-495,
.h-495 {
  height: 495px !important;
  min-height: 495px !important;
  max-height: 495px !important; }

.width-495,
.w-495 {
  width: 495px !important;
  min-width: 495px !important;
  max-width: 495px !important; }

.height-500,
.h-500 {
  height: 500px !important;
  min-height: 500px !important;
  max-height: 500px !important; }

.width-500,
.w-500 {
  width: 500px !important;
  min-width: 500px !important;
  max-width: 500px !important; }

.height-8,
.h-8 {
  height: 8px !important;
  min-height: 8px !important;
  max-height: 8px !important; }

.width-8,
.w-8 {
  width: 8px !important;
  min-width: 8px !important;
  max-width: 8px !important; }

.height-16,
.h-16 {
  height: 16px !important;
  min-height: 16px !important;
  max-height: 16px !important; }

.width-16,
.w-16 {
  width: 16px !important;
  min-width: 16px !important;
  max-width: 16px !important; }

.height-24,
.h-24 {
  height: 24px !important;
  min-height: 24px !important;
  max-height: 24px !important; }

.width-24,
.w-24 {
  width: 24px !important;
  min-width: 24px !important;
  max-width: 24px !important; }

.height-32,
.h-32 {
  height: 32px !important;
  min-height: 32px !important;
  max-height: 32px !important; }

.width-32,
.w-32 {
  width: 32px !important;
  min-width: 32px !important;
  max-width: 32px !important; }

.height-48,
.h-48 {
  height: 48px !important;
  min-height: 48px !important;
  max-height: 48px !important; }

.width-48,
.w-48 {
  width: 48px !important;
  min-width: 48px !important;
  max-width: 48px !important; }

.height-56,
.h-56 {
  height: 56px !important;
  min-height: 56px !important;
  max-height: 56px !important; }

.width-56,
.w-56 {
  width: 56px !important;
  min-width: 56px !important;
  max-width: 56px !important; }

.height-64,
.h-64 {
  height: 64px !important;
  min-height: 64px !important;
  max-height: 64px !important; }

.width-64,
.w-64 {
  width: 64px !important;
  min-width: 64px !important;
  max-width: 64px !important; }

.height-72,
.h-72 {
  height: 72px !important;
  min-height: 72px !important;
  max-height: 72px !important; }

.width-72,
.w-72 {
  width: 72px !important;
  min-width: 72px !important;
  max-width: 72px !important; }

.height-88,
.h-88 {
  height: 88px !important;
  min-height: 88px !important;
  max-height: 88px !important; }

.width-88,
.w-88 {
  width: 88px !important;
  min-width: 88px !important;
  max-width: 88px !important; }

.height-96,
.h-96 {
  height: 96px !important;
  min-height: 96px !important;
  max-height: 96px !important; }

.width-96,
.w-96 {
  width: 96px !important;
  min-width: 96px !important;
  max-width: 96px !important; }

.height-104,
.h-104 {
  height: 104px !important;
  min-height: 104px !important;
  max-height: 104px !important; }

.width-104,
.w-104 {
  width: 104px !important;
  min-width: 104px !important;
  max-width: 104px !important; }

.height-112,
.h-112 {
  height: 112px !important;
  min-height: 112px !important;
  max-height: 112px !important; }

.width-112,
.w-112 {
  width: 112px !important;
  min-width: 112px !important;
  max-width: 112px !important; }

.height-128,
.h-128 {
  height: 128px !important;
  min-height: 128px !important;
  max-height: 128px !important; }

.width-128,
.w-128 {
  width: 128px !important;
  min-width: 128px !important;
  max-width: 128px !important; }

.height-136,
.h-136 {
  height: 136px !important;
  min-height: 136px !important;
  max-height: 136px !important; }

.width-136,
.w-136 {
  width: 136px !important;
  min-width: 136px !important;
  max-width: 136px !important; }

.height-144,
.h-144 {
  height: 144px !important;
  min-height: 144px !important;
  max-height: 144px !important; }

.width-144,
.w-144 {
  width: 144px !important;
  min-width: 144px !important;
  max-width: 144px !important; }

.height-152,
.h-152 {
  height: 152px !important;
  min-height: 152px !important;
  max-height: 152px !important; }

.width-152,
.w-152 {
  width: 152px !important;
  min-width: 152px !important;
  max-width: 152px !important; }

.height-168,
.h-168 {
  height: 168px !important;
  min-height: 168px !important;
  max-height: 168px !important; }

.width-168,
.w-168 {
  width: 168px !important;
  min-width: 168px !important;
  max-width: 168px !important; }

.height-176,
.h-176 {
  height: 176px !important;
  min-height: 176px !important;
  max-height: 176px !important; }

.width-176,
.w-176 {
  width: 176px !important;
  min-width: 176px !important;
  max-width: 176px !important; }

.height-184,
.h-184 {
  height: 184px !important;
  min-height: 184px !important;
  max-height: 184px !important; }

.width-184,
.w-184 {
  width: 184px !important;
  min-width: 184px !important;
  max-width: 184px !important; }

.height-192,
.h-192 {
  height: 192px !important;
  min-height: 192px !important;
  max-height: 192px !important; }

.width-192,
.w-192 {
  width: 192px !important;
  min-width: 192px !important;
  max-width: 192px !important; }

.height-208,
.h-208 {
  height: 208px !important;
  min-height: 208px !important;
  max-height: 208px !important; }

.width-208,
.w-208 {
  width: 208px !important;
  min-width: 208px !important;
  max-width: 208px !important; }

.height-216,
.h-216 {
  height: 216px !important;
  min-height: 216px !important;
  max-height: 216px !important; }

.width-216,
.w-216 {
  width: 216px !important;
  min-width: 216px !important;
  max-width: 216px !important; }

.height-224,
.h-224 {
  height: 224px !important;
  min-height: 224px !important;
  max-height: 224px !important; }

.width-224,
.w-224 {
  width: 224px !important;
  min-width: 224px !important;
  max-width: 224px !important; }

.height-232,
.h-232 {
  height: 232px !important;
  min-height: 232px !important;
  max-height: 232px !important; }

.width-232,
.w-232 {
  width: 232px !important;
  min-width: 232px !important;
  max-width: 232px !important; }

.height-248,
.h-248 {
  height: 248px !important;
  min-height: 248px !important;
  max-height: 248px !important; }

.width-248,
.w-248 {
  width: 248px !important;
  min-width: 248px !important;
  max-width: 248px !important; }

.height-256,
.h-256 {
  height: 256px !important;
  min-height: 256px !important;
  max-height: 256px !important; }

.width-256,
.w-256 {
  width: 256px !important;
  min-width: 256px !important;
  max-width: 256px !important; }

.height-264,
.h-264 {
  height: 264px !important;
  min-height: 264px !important;
  max-height: 264px !important; }

.width-264,
.w-264 {
  width: 264px !important;
  min-width: 264px !important;
  max-width: 264px !important; }

.height-272,
.h-272 {
  height: 272px !important;
  min-height: 272px !important;
  max-height: 272px !important; }

.width-272,
.w-272 {
  width: 272px !important;
  min-width: 272px !important;
  max-width: 272px !important; }

.height-288,
.h-288 {
  height: 288px !important;
  min-height: 288px !important;
  max-height: 288px !important; }

.width-288,
.w-288 {
  width: 288px !important;
  min-width: 288px !important;
  max-width: 288px !important; }

.height-296,
.h-296 {
  height: 296px !important;
  min-height: 296px !important;
  max-height: 296px !important; }

.width-296,
.w-296 {
  width: 296px !important;
  min-width: 296px !important;
  max-width: 296px !important; }

.height-304,
.h-304 {
  height: 304px !important;
  min-height: 304px !important;
  max-height: 304px !important; }

.width-304,
.w-304 {
  width: 304px !important;
  min-width: 304px !important;
  max-width: 304px !important; }

.height-312,
.h-312 {
  height: 312px !important;
  min-height: 312px !important;
  max-height: 312px !important; }

.width-312,
.w-312 {
  width: 312px !important;
  min-width: 312px !important;
  max-width: 312px !important; }

.height-328,
.h-328 {
  height: 328px !important;
  min-height: 328px !important;
  max-height: 328px !important; }

.width-328,
.w-328 {
  width: 328px !important;
  min-width: 328px !important;
  max-width: 328px !important; }

.height-336,
.h-336 {
  height: 336px !important;
  min-height: 336px !important;
  max-height: 336px !important; }

.width-336,
.w-336 {
  width: 336px !important;
  min-width: 336px !important;
  max-width: 336px !important; }

.height-344,
.h-344 {
  height: 344px !important;
  min-height: 344px !important;
  max-height: 344px !important; }

.width-344,
.w-344 {
  width: 344px !important;
  min-width: 344px !important;
  max-width: 344px !important; }

.height-352,
.h-352 {
  height: 352px !important;
  min-height: 352px !important;
  max-height: 352px !important; }

.width-352,
.w-352 {
  width: 352px !important;
  min-width: 352px !important;
  max-width: 352px !important; }

.height-368,
.h-368 {
  height: 368px !important;
  min-height: 368px !important;
  max-height: 368px !important; }

.width-368,
.w-368 {
  width: 368px !important;
  min-width: 368px !important;
  max-width: 368px !important; }

.height-376,
.h-376 {
  height: 376px !important;
  min-height: 376px !important;
  max-height: 376px !important; }

.width-376,
.w-376 {
  width: 376px !important;
  min-width: 376px !important;
  max-width: 376px !important; }

.height-384,
.h-384 {
  height: 384px !important;
  min-height: 384px !important;
  max-height: 384px !important; }

.width-384,
.w-384 {
  width: 384px !important;
  min-width: 384px !important;
  max-width: 384px !important; }

.height-392,
.h-392 {
  height: 392px !important;
  min-height: 392px !important;
  max-height: 392px !important; }

.width-392,
.w-392 {
  width: 392px !important;
  min-width: 392px !important;
  max-width: 392px !important; }

.height-408,
.h-408 {
  height: 408px !important;
  min-height: 408px !important;
  max-height: 408px !important; }

.width-408,
.w-408 {
  width: 408px !important;
  min-width: 408px !important;
  max-width: 408px !important; }

.height-416,
.h-416 {
  height: 416px !important;
  min-height: 416px !important;
  max-height: 416px !important; }

.width-416,
.w-416 {
  width: 416px !important;
  min-width: 416px !important;
  max-width: 416px !important; }

.height-424,
.h-424 {
  height: 424px !important;
  min-height: 424px !important;
  max-height: 424px !important; }

.width-424,
.w-424 {
  width: 424px !important;
  min-width: 424px !important;
  max-width: 424px !important; }

.height-432,
.h-432 {
  height: 432px !important;
  min-height: 432px !important;
  max-height: 432px !important; }

.width-432,
.w-432 {
  width: 432px !important;
  min-width: 432px !important;
  max-width: 432px !important; }

.height-448,
.h-448 {
  height: 448px !important;
  min-height: 448px !important;
  max-height: 448px !important; }

.width-448,
.w-448 {
  width: 448px !important;
  min-width: 448px !important;
  max-width: 448px !important; }

.height-456,
.h-456 {
  height: 456px !important;
  min-height: 456px !important;
  max-height: 456px !important; }

.width-456,
.w-456 {
  width: 456px !important;
  min-width: 456px !important;
  max-width: 456px !important; }

.height-464,
.h-464 {
  height: 464px !important;
  min-height: 464px !important;
  max-height: 464px !important; }

.width-464,
.w-464 {
  width: 464px !important;
  min-width: 464px !important;
  max-width: 464px !important; }

.height-472,
.h-472 {
  height: 472px !important;
  min-height: 472px !important;
  max-height: 472px !important; }

.width-472,
.w-472 {
  width: 472px !important;
  min-width: 472px !important;
  max-width: 472px !important; }

.height-488,
.h-488 {
  height: 488px !important;
  min-height: 488px !important;
  max-height: 488px !important; }

.width-488,
.w-488 {
  width: 488px !important;
  min-width: 488px !important;
  max-width: 488px !important; }

.height-496,
.h-496 {
  height: 496px !important;
  min-height: 496px !important;
  max-height: 496px !important; }

.width-496,
.w-496 {
  width: 496px !important;
  min-width: 496px !important;
  max-width: 496px !important; }

.height-504,
.h-504 {
  height: 504px !important;
  min-height: 504px !important;
  max-height: 504px !important; }

.width-504,
.w-504 {
  width: 504px !important;
  min-width: 504px !important;
  max-width: 504px !important; }

.height-512,
.h-512 {
  height: 512px !important;
  min-height: 512px !important;
  max-height: 512px !important; }

.width-512,
.w-512 {
  width: 512px !important;
  min-width: 512px !important;
  max-width: 512px !important; }

.height-528,
.h-528 {
  height: 528px !important;
  min-height: 528px !important;
  max-height: 528px !important; }

.width-528,
.w-528 {
  width: 528px !important;
  min-width: 528px !important;
  max-width: 528px !important; }

.height-536,
.h-536 {
  height: 536px !important;
  min-height: 536px !important;
  max-height: 536px !important; }

.width-536,
.w-536 {
  width: 536px !important;
  min-width: 536px !important;
  max-width: 536px !important; }

.height-544,
.h-544 {
  height: 544px !important;
  min-height: 544px !important;
  max-height: 544px !important; }

.width-544,
.w-544 {
  width: 544px !important;
  min-width: 544px !important;
  max-width: 544px !important; }

.height-552,
.h-552 {
  height: 552px !important;
  min-height: 552px !important;
  max-height: 552px !important; }

.width-552,
.w-552 {
  width: 552px !important;
  min-width: 552px !important;
  max-width: 552px !important; }

.height-568,
.h-568 {
  height: 568px !important;
  min-height: 568px !important;
  max-height: 568px !important; }

.width-568,
.w-568 {
  width: 568px !important;
  min-width: 568px !important;
  max-width: 568px !important; }

.height-576,
.h-576 {
  height: 576px !important;
  min-height: 576px !important;
  max-height: 576px !important; }

.width-576,
.w-576 {
  width: 576px !important;
  min-width: 576px !important;
  max-width: 576px !important; }

.height-584,
.h-584 {
  height: 584px !important;
  min-height: 584px !important;
  max-height: 584px !important; }

.width-584,
.w-584 {
  width: 584px !important;
  min-width: 584px !important;
  max-width: 584px !important; }

.height-592,
.h-592 {
  height: 592px !important;
  min-height: 592px !important;
  max-height: 592px !important; }

.width-592,
.w-592 {
  width: 592px !important;
  min-width: 592px !important;
  max-width: 592px !important; }

.height-608,
.h-608 {
  height: 608px !important;
  min-height: 608px !important;
  max-height: 608px !important; }

.width-608,
.w-608 {
  width: 608px !important;
  min-width: 608px !important;
  max-width: 608px !important; }

.height-616,
.h-616 {
  height: 616px !important;
  min-height: 616px !important;
  max-height: 616px !important; }

.width-616,
.w-616 {
  width: 616px !important;
  min-width: 616px !important;
  max-width: 616px !important; }

.height-624,
.h-624 {
  height: 624px !important;
  min-height: 624px !important;
  max-height: 624px !important; }

.width-624,
.w-624 {
  width: 624px !important;
  min-width: 624px !important;
  max-width: 624px !important; }

.height-632,
.h-632 {
  height: 632px !important;
  min-height: 632px !important;
  max-height: 632px !important; }

.width-632,
.w-632 {
  width: 632px !important;
  min-width: 632px !important;
  max-width: 632px !important; }

.height-648,
.h-648 {
  height: 648px !important;
  min-height: 648px !important;
  max-height: 648px !important; }

.width-648,
.w-648 {
  width: 648px !important;
  min-width: 648px !important;
  max-width: 648px !important; }

.height-656,
.h-656 {
  height: 656px !important;
  min-height: 656px !important;
  max-height: 656px !important; }

.width-656,
.w-656 {
  width: 656px !important;
  min-width: 656px !important;
  max-width: 656px !important; }

.height-664,
.h-664 {
  height: 664px !important;
  min-height: 664px !important;
  max-height: 664px !important; }

.width-664,
.w-664 {
  width: 664px !important;
  min-width: 664px !important;
  max-width: 664px !important; }

.height-672,
.h-672 {
  height: 672px !important;
  min-height: 672px !important;
  max-height: 672px !important; }

.width-672,
.w-672 {
  width: 672px !important;
  min-width: 672px !important;
  max-width: 672px !important; }

.height-688,
.h-688 {
  height: 688px !important;
  min-height: 688px !important;
  max-height: 688px !important; }

.width-688,
.w-688 {
  width: 688px !important;
  min-width: 688px !important;
  max-width: 688px !important; }

.height-696,
.h-696 {
  height: 696px !important;
  min-height: 696px !important;
  max-height: 696px !important; }

.width-696,
.w-696 {
  width: 696px !important;
  min-width: 696px !important;
  max-width: 696px !important; }

.height-704,
.h-704 {
  height: 704px !important;
  min-height: 704px !important;
  max-height: 704px !important; }

.width-704,
.w-704 {
  width: 704px !important;
  min-width: 704px !important;
  max-width: 704px !important; }

.height-712,
.h-712 {
  height: 712px !important;
  min-height: 712px !important;
  max-height: 712px !important; }

.width-712,
.w-712 {
  width: 712px !important;
  min-width: 712px !important;
  max-width: 712px !important; }

.height-728,
.h-728 {
  height: 728px !important;
  min-height: 728px !important;
  max-height: 728px !important; }

.width-728,
.w-728 {
  width: 728px !important;
  min-width: 728px !important;
  max-width: 728px !important; }

.height-736,
.h-736 {
  height: 736px !important;
  min-height: 736px !important;
  max-height: 736px !important; }

.width-736,
.w-736 {
  width: 736px !important;
  min-width: 736px !important;
  max-width: 736px !important; }

.height-744,
.h-744 {
  height: 744px !important;
  min-height: 744px !important;
  max-height: 744px !important; }

.width-744,
.w-744 {
  width: 744px !important;
  min-width: 744px !important;
  max-width: 744px !important; }

.height-752,
.h-752 {
  height: 752px !important;
  min-height: 752px !important;
  max-height: 752px !important; }

.width-752,
.w-752 {
  width: 752px !important;
  min-width: 752px !important;
  max-width: 752px !important; }

.height-768,
.h-768 {
  height: 768px !important;
  min-height: 768px !important;
  max-height: 768px !important; }

.width-768,
.w-768 {
  width: 768px !important;
  min-width: 768px !important;
  max-width: 768px !important; }

.height-776,
.h-776 {
  height: 776px !important;
  min-height: 776px !important;
  max-height: 776px !important; }

.width-776,
.w-776 {
  width: 776px !important;
  min-width: 776px !important;
  max-width: 776px !important; }

.height-784,
.h-784 {
  height: 784px !important;
  min-height: 784px !important;
  max-height: 784px !important; }

.width-784,
.w-784 {
  width: 784px !important;
  min-width: 784px !important;
  max-width: 784px !important; }

.height-792,
.h-792 {
  height: 792px !important;
  min-height: 792px !important;
  max-height: 792px !important; }

.width-792,
.w-792 {
  width: 792px !important;
  min-width: 792px !important;
  max-width: 792px !important; }

.border,
.b {
  border: 1px solid rgba(0, 0, 0, 0.12); }

.border-top,
.bt {
  border-top: 1px solid rgba(0, 0, 0, 0.12); }

.border-right,
.br {
  border-right: 1px solid rgba(0, 0, 0, 0.12); }

.border-bottom,
.bb {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }

.border-left,
.bl {
  border-left: 1px solid rgba(0, 0, 0, 0.12); }

.border-horizontal,
.bh {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  border-right: 1px solid rgba(0, 0, 0, 0.12); }

.border-vertical,
.bv {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }

.brad-1 {
  border-radius: 1px; }

.brad-2 {
  border-radius: 2px; }

.brad-3 {
  border-radius: 3px; }

.brad-4 {
  border-radius: 4px; }

.brad-5 {
  border-radius: 5px; }

.brad-6 {
  border-radius: 6px; }

.brad-7 {
  border-radius: 7px; }

.brad-8 {
  border-radius: 8px; }

.brad-9 {
  border-radius: 9px; }

.brad-10 {
  border-radius: 10px; }

.brad-11 {
  border-radius: 11px; }

.brad-12 {
  border-radius: 12px; }

/*----------------------------------------------------------------*/
/*  Print
/*----------------------------------------------------------------*/
@media all {
  /* Never show page break in normal view */
  .page-break {
    display: none; } }

@media print {
  /* Page Styles */
  @page {}  /* Page break */
  .page-break {
    display: block;
    break-after: always;
    page-break-after: always; }
  /* General styles */
  #main #vertical-navigation {
    display: none; }
  #main #toolbar {
    display: none; }
  #main #quick-panel {
    display: none; }
  #main #content {
    position: relative !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    background: none !important; }
  ms-theme-options {
    display: none; }
  .ps-scrollbar-x-rail,
  .ps-scrollbar-y-rail {
    display: none !important; }
  /* Printable page specific styles */
  .printable {
    overflow: visible !important; }
    .printable .template-layout {
      display: inline !important; } }

.ms-card .template-1 .media .image {
  width: 100%;
  border-radius: 2px 2px 0 0; }

.ms-card .template-10 .media {
  width: 80px;
  min-width: 80px;
  max-width: 80px; }

.ms-card .template-2 .header .avatar .image {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 20px; }

.ms-card .template-2 .media .image {
  width: 100%; }

.ms-card .template-4 .info {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  background: rgba(0, 0, 0, 0.54); }

.ms-card .template-4 .media {
  z-index: 1; }
  .ms-card .template-4 .media .image {
    width: 100%; }

.ms-card .template-5 .media {
  width: 80px;
  min-width: 80px;
  max-width: 80px; }

.ms-card .template-5 .info, .ms-card .template-5 .media {
  -ms-flex: 0 1 auto; }

.ms-card .template-8 .media .image {
  width: 100%;
  border-radius: 2px 2px 0 0; }

.ms-card .template-9 .header .avatar .image {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 20px; }

.ms-card .template-9 .media .image {
  width: 100%; }

.ms-stepper .ms-stepper-horizontal {
  background: #FFFFFF;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }
  .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation {
    height: 72px;
    min-height: 72px;
    max-height: 72px;
    background: #FFFFFF;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }
    .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation .ms-stepper-navigation-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      height: 72px;
      min-height: 72px;
      max-height: 72px;
      line-height: 24px;
      margin: 0;
      padding: 24px;
      border-radius: 0;
      cursor: pointer;
      font-size: 14px;
      text-transform: none; }
      .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation .ms-stepper-navigation-item:before, .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation .ms-stepper-navigation-item:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        width: 24px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
      .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation .ms-stepper-navigation-item:before {
        left: 0; }
      .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation .ms-stepper-navigation-item:after {
        right: 0; }
      .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation .ms-stepper-navigation-item:first-child:before {
        display: none; }
      .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation .ms-stepper-navigation-item:last-child:after {
        display: none; }
      .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation .ms-stepper-navigation-item.current .title {
        font-weight: 600;
        color: rgba(0, 0, 0, 0.87); }
      .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation .ms-stepper-navigation-item.disabled {
        cursor: default; }
        .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation .ms-stepper-navigation-item.disabled .step {
          background: rgba(0, 0, 0, 0.12); }
        .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation .ms-stepper-navigation-item.disabled .title {
          color: rgba(0, 0, 0, 0.27); }
        .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation .ms-stepper-navigation-item.disabled .subtitle {
          color: rgba(0, 0, 0, 0.27); }
      .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation .ms-stepper-navigation-item.optional {
        padding: 16px 24px; }
      .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation .ms-stepper-navigation-item .step {
        width: 24px;
        min-width: 24px;
        height: 24px;
        border-radius: 50%;
        margin: 0 8px 0 16px;
        color: #FFFFFF; }
        .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation .ms-stepper-navigation-item .step .icon {
          color: #FFFFFF;
          font-weight: bold;
          line-height: 24px !important; }
      .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation .ms-stepper-navigation-item .title {
        padding-right: 16px;
        color: rgba(0, 0, 0, 0.54); }
      .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation .ms-stepper-navigation-item .subtitle {
        font-size: 12px;
        padding-right: 16px;
        color: rgba(0, 0, 0, 0.54);
        line-height: 1; }
  .ms-stepper .ms-stepper-horizontal .ms-stepper-steps {
    padding: 24px 16px; }
  .ms-stepper .ms-stepper-horizontal .ms-stepper-controls {
    padding: 0 16px 16px 16px; }
    .ms-stepper .ms-stepper-horizontal .ms-stepper-controls .ms-stepper-dots {
      display: none;
      align-items: center;
      justify-content: center;
      padding: 0 16px; }
      .ms-stepper .ms-stepper-horizontal .ms-stepper-controls .ms-stepper-dots span {
        width: 5px;
        height: 5px;
        margin: 0 4px;
        border-radius: 50%; }
        .ms-stepper .ms-stepper-horizontal .ms-stepper-controls .ms-stepper-dots span:not(.md-accent-bg) {
          background: rgba(0, 0, 0, 0.12); }
        .ms-stepper .ms-stepper-horizontal .ms-stepper-controls .ms-stepper-dots span.selected {
          width: 8px;
          height: 8px; }

@media screen and (max-width: 600px) {
  .ms-stepper .ms-stepper-horizontal .ms-stepper-navigation-wrapper .ms-stepper-navigation {
    display: none; }
  .ms-stepper .ms-stepper-horizontal .ms-stepper-controls .ms-stepper-dots {
    display: flex; } }

.ms-stepper .ms-stepper-vertical {
  position: relative;
  background: #FFFFFF;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }
  .ms-stepper .ms-stepper-vertical .ms-stepper-vertical-line {
    display: block;
    position: absolute;
    width: 1px;
    top: 32px;
    bottom: 32px;
    left: 43px;
    border-left: 1px solid rgba(0, 0, 0, 0.12); }
  .ms-stepper .ms-stepper-vertical .ms-stepper-steps {
    padding: 8px 0; }
    .ms-stepper .ms-stepper-vertical .ms-stepper-steps .ms-stepper-step {
      display: block; }
      .ms-stepper .ms-stepper-vertical .ms-stepper-steps .ms-stepper-step .ms-stepper-navigation-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        width: 100%;
        height: 72px;
        min-height: 72px;
        max-height: 72px;
        line-height: 24px;
        margin: 0;
        padding: 0 24px;
        border-radius: 0;
        cursor: pointer;
        font-size: 14px;
        text-transform: none; }
        .ms-stepper .ms-stepper-vertical .ms-stepper-steps .ms-stepper-step .ms-stepper-navigation-item:first-child:before {
          display: none; }
        .ms-stepper .ms-stepper-vertical .ms-stepper-steps .ms-stepper-step .ms-stepper-navigation-item:last-child:after {
          display: none; }
        .ms-stepper .ms-stepper-vertical .ms-stepper-steps .ms-stepper-step .ms-stepper-navigation-item:hover:not(.disabled) .step {
          background-color: #ececec;
          border-color: #ececec; }
        .ms-stepper .ms-stepper-vertical .ms-stepper-steps .ms-stepper-step .ms-stepper-navigation-item.current .title {
          font-weight: 600;
          color: rgba(0, 0, 0, 0.87); }
        .ms-stepper .ms-stepper-vertical .ms-stepper-steps .ms-stepper-step .ms-stepper-navigation-item.disabled {
          cursor: default; }
          .ms-stepper .ms-stepper-vertical .ms-stepper-steps .ms-stepper-step .ms-stepper-navigation-item.disabled .title {
            color: rgba(0, 0, 0, 0.27); }
          .ms-stepper .ms-stepper-vertical .ms-stepper-steps .ms-stepper-step .ms-stepper-navigation-item.disabled .subtitle {
            color: rgba(0, 0, 0, 0.27); }
        .ms-stepper .ms-stepper-vertical .ms-stepper-steps .ms-stepper-step .ms-stepper-navigation-item.optional {
          padding: 16px 24px; }
        .ms-stepper .ms-stepper-vertical .ms-stepper-steps .ms-stepper-step .ms-stepper-navigation-item .step {
          width: 40px;
          min-width: 40px;
          height: 40px;
          margin: 0 16px 0 0;
          border-radius: 50%;
          background: #FFFFFF;
          border: 8px solid #FFFFFF;
          color: #FFFFFF;
          transition: border-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); }
          .ms-stepper .ms-stepper-vertical .ms-stepper-steps .ms-stepper-step .ms-stepper-navigation-item .step .icon {
            font-weight: bold;
            line-height: 28px !important; }
        .ms-stepper .ms-stepper-vertical .ms-stepper-steps .ms-stepper-step .ms-stepper-navigation-item .title {
          padding-right: 16px;
          color: rgba(0, 0, 0, 0.54); }
        .ms-stepper .ms-stepper-vertical .ms-stepper-steps .ms-stepper-step .ms-stepper-navigation-item .subtitle {
          font-size: 12px;
          padding-right: 16px;
          color: rgba(0, 0, 0, 0.54);
          line-height: 1; }
      .ms-stepper .ms-stepper-vertical .ms-stepper-steps .ms-stepper-step .ms-stepper-step-content {
        padding: 0 24px 16px 64px; }
      .ms-stepper .ms-stepper-vertical .ms-stepper-steps .ms-stepper-step .ms-stepper-controls {
        padding: 0 16px 16px 56px; }

.ms-theme-options .ms-theme-options-button {
  position: absolute;
  top: 160px;
  right: 0;
  z-index: 50;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  width: 48px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  cursor: pointer;
  border-radius: 0;
  margin: 0;
  pointer-events: auto;
  opacity: 0.75; }
  .ms-theme-options .ms-theme-options-button:hover {
    opacity: 1; }

.ms-theme-options md-sidenav {
  width: 384px;
  min-width: 384px;
  max-width: 384px; }
  .ms-theme-options md-sidenav .ms-theme-options-list {
    background: #FFFFFF;
    z-index: 12;
    pointer-events: auto; }
    .ms-theme-options md-sidenav .ms-theme-options-list .theme-option {
      padding: 16px; }
      .ms-theme-options md-sidenav .ms-theme-options-list .theme-option.layout-options md-radio-button {
        width: 160px;
        max-width: 160px;
        margin: 8px 8px 16px 8px; }
        .ms-theme-options md-sidenav .ms-theme-options-list .theme-option.layout-options md-radio-button:hover ._md-label img, .ms-theme-options md-sidenav .ms-theme-options-list .theme-option.layout-options md-radio-button.md-checked ._md-label img {
          opacity: 1;
          box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); }
        .ms-theme-options md-sidenav .ms-theme-options-list .theme-option.layout-options md-radio-button ._md-container {
          display: none; }
        .ms-theme-options md-sidenav .ms-theme-options-list .theme-option.layout-options md-radio-button ._md-label {
          margin-left: 0; }
          .ms-theme-options md-sidenav .ms-theme-options-list .theme-option.layout-options md-radio-button ._md-label img {
            opacity: 0.8;
            margin-bottom: 8px; }
      .ms-theme-options md-sidenav .ms-theme-options-list .theme-option.color-palettes md-menu-item {
        max-width: 192px; }
      .ms-theme-options md-sidenav .ms-theme-options-list .theme-option.color-palettes .theme-button {
        border-bottom: 16px solid;
        text-align: left;
        width: 101px;
        height: 88px;
        box-shadow: none;
        opacity: 0.8; }
        .ms-theme-options md-sidenav .ms-theme-options-list .theme-option.color-palettes .theme-button:hover, .ms-theme-options md-sidenav .ms-theme-options-list .theme-option.color-palettes .theme-button.active {
          opacity: 1;
          box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); }
      .ms-theme-options md-sidenav .ms-theme-options-list .theme-option md-radio-button {
        margin: 8px; }
      .ms-theme-options md-sidenav .ms-theme-options-list .theme-option .option-title {
        font-size: 14px;
        font-weight: 500;
        padding: 0 8px 12px 8px; }

/*----------------------------------------------------------------*/
/*  ms-card
/*----------------------------------------------------------------*/
.ms-card {
  display: flex;
  flex-direction: row;
  padding: 8px; }
  .ms-card .ms-card-content-wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 400px;
    border-radius: 2px;
    background: white;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.14), 0px 2px 2px 0px rgba(0, 0, 0, 0.098), 0px 1px 5px 0px rgba(0, 0, 0, 0.084); }

.ms-form-wizard {
  background: #FFFFFF;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }
  .ms-form-wizard md-tabs md-tabs-wrapper {
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }
    .ms-form-wizard md-tabs md-tabs-wrapper md-tabs-canvas {
      height: 72px; }
      .ms-form-wizard md-tabs md-tabs-wrapper md-tabs-canvas md-pagination-wrapper {
        height: 72px; }
        .ms-form-wizard md-tabs md-tabs-wrapper md-tabs-canvas md-pagination-wrapper md-tab-item {
          opacity: 1 !important;
          padding: 24px; }
          .ms-form-wizard md-tabs md-tabs-wrapper md-tabs-canvas md-pagination-wrapper md-tab-item:before {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            right: 50%;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            z-index: 8; }
          .ms-form-wizard md-tabs md-tabs-wrapper md-tabs-canvas md-pagination-wrapper md-tab-item:after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            right: 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            z-index: 8; }
          .ms-form-wizard md-tabs md-tabs-wrapper md-tabs-canvas md-pagination-wrapper md-tab-item:first-of-type:before {
            display: none; }
          .ms-form-wizard md-tabs md-tabs-wrapper md-tabs-canvas md-pagination-wrapper md-tab-item:last-of-type:after {
            display: none; }
          .ms-form-wizard md-tabs md-tabs-wrapper md-tabs-canvas md-pagination-wrapper md-tab-item.md-disabled .ms-form-wizard-step-label .ms-form-wizard-step-number {
            background: rgba(0, 0, 0, 0.12) !important; }
          .ms-form-wizard md-tabs md-tabs-wrapper md-tabs-canvas md-pagination-wrapper md-tab-item .md-ripple-container {
            z-index: 11; }
      .ms-form-wizard md-tabs md-tabs-wrapper md-tabs-canvas md-ink-bar {
        display: none !important; }
  .ms-form-wizard .ms-form-wizard-step-label {
    display: inline-block;
    position: relative;
    background: #FFFFFF;
    z-index: 10;
    padding: 0 16px;
    line-height: 24px; }
    .ms-form-wizard .ms-form-wizard-step-label .ms-form-wizard-step-number {
      display: inline-block;
      vertical-align: middle;
      width: 24px;
      height: 24px;
      line-height: 24px;
      font-size: 14px;
      margin-right: 12px;
      border-radius: 50%;
      color: #FFFFFF !important; }
      .ms-form-wizard .ms-form-wizard-step-label .ms-form-wizard-step-number i {
        line-height: 24px !important; }
    .ms-form-wizard .ms-form-wizard-step-label .ms-form-wizard-step-text {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      line-height: 24px; }
  .ms-form-wizard .ms-form-wizard-form {
    padding: 24px 16px; }
  .ms-form-wizard .navigation {
    padding: 16px; }
    .ms-form-wizard .navigation .steps {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 16px; }
      .ms-form-wizard .navigation .steps span {
        width: 5px;
        height: 5px;
        margin: 0 4px;
        border-radius: 50%; }
        .ms-form-wizard .navigation .steps span:not(.md-accent-bg) {
          background: rgba(0, 0, 0, 0.12); }
        .ms-form-wizard .navigation .steps span.selected {
          width: 8px;
          height: 8px; }

body.ms-info-bar-active #main {
  top: 64px; }

ms-info-bar {
  display: block;
  position: relative;
  width: 100%;
  height: 64px;
  background-color: #E91E63;
  z-index: 99998;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12); }
  ms-info-bar #ms-info-bar-message {
    padding: 8px 16px;
    line-height: 48px;
    font-size: 16px; }
    ms-info-bar #ms-info-bar-message a {
      overflow: hidden;
      height: 48px;
      color: #FFFFFF; }

ms-masonry {
  width: 100%;
  display: block; }
  ms-masonry ms-masonry-item,
  ms-masonry [ms-masonry-item] {
    transition: transform 300ms ease;
    position: absolute;
    transform: translate3d(50vw, 100vh, 0); }

/*----------------------------------------------------------------*/
/*  ms-material-color-picker
/*----------------------------------------------------------------*/
ms-material-color-picker md-menu .md-button {
  font-size: 12px; }

md-menu-content.ms-material-color-picker-menu-content {
  width: 208px;
  max-width: 208px;
  min-width: 208px;
  padding: 0;
  overflow: hidden; }
  md-menu-content.ms-material-color-picker-menu-content header {
    height: 48px;
    text-transform: uppercase; }
    md-menu-content.ms-material-color-picker-menu-content header .md-button {
      display: flex;
      margin: 0; }
    md-menu-content.ms-material-color-picker-menu-content header > span {
      line-height: 13px; }
  md-menu-content.ms-material-color-picker-menu-content .colors {
    position: relative;
    width: 208px;
    min-height: 258px;
    height: 258px;
    padding: 4px; }
    md-menu-content.ms-material-color-picker-menu-content .colors .color {
      position: relative;
      width: 46px;
      height: 46px;
      margin: 2px;
      border-radius: 0;
      cursor: pointer; }
      md-menu-content.ms-material-color-picker-menu-content .colors .color .label {
        padding: 2px;
        font-size: 10px; }
      md-menu-content.ms-material-color-picker-menu-content .colors .color .icon-check {
        position: absolute;
        top: 2px;
        right: 2px;
        font-size: 16px;
        opacity: 0.7; }

ms-nav {
  display: block;
  position: relative;
  color: rgba(255, 255, 255, 0.7);
  user-select: none;
  z-index: 70; }
  ms-nav ms-nav-item {
    display: block;
    position: relative;
    line-height: 48px; }
    ms-nav ms-nav-item.ms-nav-title {
      line-height: 32px;
      font-size: 12px;
      font-weight: 500;
      margin-top: 8px;
      padding: 0 24px;
      width: 100%;
      color: rgba(255, 255, 255, 0.3);
      text-transform: uppercase;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    ms-nav ms-nav-item .ms-nav-button {
      display: flex;
      align-self: flex-start;
      align-items: center;
      position: relative;
      cursor: pointer;
      padding: 0 48px 0 24px;
      color: rgba(255, 255, 255, 0.7);
      text-decoration: none; }
      ms-nav ms-nav-item .ms-nav-button:not(.active):hover {
        background: rgba(0, 0, 0, 0.1);
        text-decoration: none; }
      ms-nav ms-nav-item .ms-nav-button .ms-nav-label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: opacity 0.2s ease-in-out 0.1s; }
      ms-nav ms-nav-item .ms-nav-button .arrow {
        position: absolute;
        top: 16px;
        right: 24px;
        margin: 0;
        transition: transform 0.3s ease-in-out, opacity 0.2s ease-in-out 0.1s; }
      ms-nav ms-nav-item .ms-nav-button .ms-nav-badge {
        position: absolute;
        top: 50%;
        right: 24px;
        min-width: 20px;
        height: 20px;
        line-height: 20px;
        padding: 0 7px;
        font-size: 11px;
        font-weight: bold;
        border-radius: 20px;
        transform: translateY(-50%);
        transition: opacity 0.2s ease-in-out 0.1s; }
      ms-nav ms-nav-item .ms-nav-button.active {
        color: #FFFFFF !important; }
        ms-nav ms-nav-item .ms-nav-button.active .ms-nav-badge {
          background: #FFFFFF !important;
          color: rgba(0, 0, 0, 0.87) !important; }
        ms-nav ms-nav-item .ms-nav-button.active i {
          color: #FFFFFF; }
    ms-nav ms-nav-item i {
      color: rgba(255, 255, 255, 0.7);
      margin: 0 16px 0 0; }
  ms-nav .ms-nav-toggle ms-nav-toggle-items {
    display: none;
    overflow: hidden; }
    ms-nav .ms-nav-toggle ms-nav-toggle-items.expand-animation {
      transition: height 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
    ms-nav .ms-nav-toggle ms-nav-toggle-items.collapse-animation {
      transition: height 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
    ms-nav .ms-nav-toggle ms-nav-toggle-items ms-nav-item {
      /* Level 1 */
      /* Level 2 */ }
      ms-nav .ms-nav-toggle ms-nav-toggle-items ms-nav-item .ms-nav-button {
        padding-left: 58px; }
      ms-nav .ms-nav-toggle ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item {
        /* Level 3 */ }
        ms-nav .ms-nav-toggle ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item .ms-nav-button {
          padding-left: 68px; }
        ms-nav .ms-nav-toggle ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item {
          /* Level 4 */ }
          ms-nav .ms-nav-toggle ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item .ms-nav-button {
            padding-left: 78px; }
          ms-nav .ms-nav-toggle ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item {
            /* Level 5 */ }
            ms-nav .ms-nav-toggle ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item .ms-nav-button {
              padding-left: 88px; }
            ms-nav .ms-nav-toggle ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item ms-nav-toggle-items ms-nav-item .ms-nav-button {
              padding-left: 98px; }
  ms-nav .ms-nav-toggle.active > .ms-nav-button {
    color: white; }
    ms-nav .ms-nav-toggle.active > .ms-nav-button a,
    ms-nav .ms-nav-toggle.active > .ms-nav-button md-icon {
      color: white; }
  ms-nav .ms-nav-toggle[collapsed="false"] > .ms-nav-button > .arrow {
    transform: rotate(90deg); }
  ms-nav > .ms-nav-toggle[collapsed="false"] {
    background-color: rgba(0, 0, 0, 0.12); }

@media only screen and (min-width: 1280px) {
  .ms-nav-folded #ms-nav-fold-close-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    z-index: 998; }
  .ms-nav-folded #ms-nav-fold-open-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999; }
  .ms-nav-folded:not(.ms-nav-folded-open) ms-nav ms-nav-item.ms-nav-title {
    height: 32px; }
    .ms-nav-folded:not(.ms-nav-folded-open) ms-nav ms-nav-item.ms-nav-title:before {
      content: '';
      display: block;
      position: relative;
      top: 12px;
      width: 16px;
      border-top: 1px solid rgba(255, 255, 255, 0.12); }
    .ms-nav-folded:not(.ms-nav-folded-open) ms-nav ms-nav-item.ms-nav-title span {
      display: none; }
  .ms-nav-folded:not(.ms-nav-folded-open) ms-nav ms-nav-item .ms-nav-button {
    height: 48px; }
    .ms-nav-folded:not(.ms-nav-folded-open) ms-nav ms-nav-item .ms-nav-button > .ms-nav-label,
    .ms-nav-folded:not(.ms-nav-folded-open) ms-nav ms-nav-item .ms-nav-button > .ms-nav-badge,
    .ms-nav-folded:not(.ms-nav-folded-open) ms-nav ms-nav-item .ms-nav-button > .arrow {
      opacity: 0;
      visibility: hidden; } }

/*----------------------------------------------------------------*/
/*  ms-navigation
/*----------------------------------------------------------------*/
.ms-navigation {
  display: block;
  position: relative;
  color: rgba(255, 255, 255, 0.7);
  user-select: none; }
  .ms-navigation ul, .ms-navigation li {
    padding: 0;
    margin: 0; }
  .ms-navigation ul {
    display: block;
    overflow: hidden; }
    .ms-navigation ul li {
      position: relative; }
      .ms-navigation ul li.collapsed ul {
        display: none; }
  .ms-navigation .animate-height {
    transition: height 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
  .ms-navigation .ms-navigation-node .ms-navigation-item {
    display: block;
    position: relative;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.7); }
    .ms-navigation .ms-navigation-node .ms-navigation-item:hover {
      background: rgba(0, 0, 0, 0.1); }
    .ms-navigation .ms-navigation-node .ms-navigation-item > .ms-navigation-button {
      display: flex;
      position: relative;
      width: 100%;
      height: 48px;
      line-height: 48px;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding-right: 16px;
      color: rgba(255, 255, 255, 0.7);
      text-decoration: none;
      white-space: nowrap;
      overflow: hidden; }
      .ms-navigation .ms-navigation-node .ms-navigation-item > .ms-navigation-button .icon {
        color: rgba(255, 255, 255, 0.7);
        margin: 0 16px 0 0; }
      .ms-navigation .ms-navigation-node .ms-navigation-item > .ms-navigation-button span {
        white-space: nowrap;
        overflow: hidden;
        opacity: 1;
        transition: opacity 0.25s ease-in-out 0.1s; }
      .ms-navigation .ms-navigation-node .ms-navigation-item > .ms-navigation-button a {
        white-space: nowrap;
        overflow: hidden;
        opacity: 1;
        transition: opacity 0.25s ease-in-out 0.1s; }
      .ms-navigation .ms-navigation-node .ms-navigation-item > .ms-navigation-button .arrow {
        margin: 0;
        opacity: 1;
        transition: transform 0.3s ease-in-out, opacity 0.25s ease-in-out 0.1s; }
      .ms-navigation .ms-navigation-node .ms-navigation-item > .ms-navigation-button .badge {
        position: absolute;
        top: 50%;
        right: 16px;
        min-width: 20px;
        height: 20px;
        line-height: 20px;
        padding: 0 7px;
        font-size: 11px;
        font-weight: bold;
        border-radius: 20px;
        transform: translateY(-50%);
        transition: opacity 0.2s ease-in-out 0.1s; }
      .ms-navigation .ms-navigation-node .ms-navigation-item > .ms-navigation-button.active {
        color: #FFFFFF; }
        .ms-navigation .ms-navigation-node .ms-navigation-item > .ms-navigation-button.active .icon {
          color: #FFFFFF; }
        .ms-navigation .ms-navigation-node .ms-navigation-item > .ms-navigation-button.active .badge {
          background: #FFFFFF !important;
          color: rgba(0, 0, 0, 0.87) !important; }
  .ms-navigation .ms-navigation-node:not(.collapsed) > .ms-navigation-item > .ms-navigation-button .arrow {
    transform: rotate(90deg); }
  .ms-navigation .ms-navigation-node.group > .ms-navigation-item {
    font-size: 12px;
    font-weight: 500;
    margin-top: 8px;
    cursor: auto;
    background: none !important;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
    .ms-navigation .ms-navigation-node.group > .ms-navigation-item > .ms-navigation-button {
      height: 32px;
      line-height: 32px;
      color: rgba(255, 255, 255, 0.3); }
  .ms-navigation .ms-navigation-node:not(.group).has-children:not(.collapsed) {
    background-color: rgba(0, 0, 0, 0.12); }
  .ms-navigation .ms-navigation-node:not(.group).has-children.collapsing {
    background-color: rgba(0, 0, 0, 0.12); }
  .ms-navigation .ms-navigation-node:not(.group).has-children .has-children:not(.collapsed) {
    background: none; }
  .ms-navigation li .ms-navigation-button {
    padding-left: 24px; }
  .ms-navigation li li .ms-navigation-button {
    padding-left: 56px; }
  .ms-navigation li li li .ms-navigation-button {
    padding-left: 72px; }
  .ms-navigation li li li li .ms-navigation-button {
    padding-left: 88px; }
  .ms-navigation li li li li li .ms-navigation-button {
    padding-left: 104px; }
  .ms-navigation li.group .ms-navigation-button {
    padding-left: 24px; }
  .ms-navigation li.group li .ms-navigation-button {
    padding-left: 24px; }
  .ms-navigation li.group li li .ms-navigation-button {
    padding-left: 56px; }
  .ms-navigation li.group li li li .ms-navigation-button {
    padding-left: 72px; }
  .ms-navigation li.group li li li li .ms-navigation-button {
    padding-left: 88px; }

#horizontal-navigation {
  height: 64px;
  min-height: 64px;
  max-height: 64px;
  background: #FFFFFF;
  z-index: 54; }
  #horizontal-navigation .ms-navigation-horizontal {
    display: flex;
    position: relative;
    user-select: none; }
    #horizontal-navigation .ms-navigation-horizontal ul, #horizontal-navigation .ms-navigation-horizontal li {
      padding: 0;
      margin: 0;
      list-style: none; }
    #horizontal-navigation .ms-navigation-horizontal .horizontal {
      display: flex;
      flex: 1; }
      #horizontal-navigation .ms-navigation-horizontal .horizontal li {
        position: relative;
        cursor: pointer; }
        #horizontal-navigation .ms-navigation-horizontal .horizontal li:not(.active):hover {
          background: rgba(0, 0, 0, 0.12); }
      #horizontal-navigation .ms-navigation-horizontal .horizontal > li > .ms-navigation-horizontal-item > .ms-navigation-horizontal-button {
        display: block;
        float: left;
        padding: 12px 14px; }
        #horizontal-navigation .ms-navigation-horizontal .horizontal > li > .ms-navigation-horizontal-item > .ms-navigation-horizontal-button .arrow {
          display: none; }
        #horizontal-navigation .ms-navigation-horizontal .horizontal > li > .ms-navigation-horizontal-item > .ms-navigation-horizontal-button.active {
          background: none;
          border-bottom: 2px solid; }
      #horizontal-navigation .ms-navigation-horizontal .horizontal > li:last-child {
        margin-right: 0; }
      #horizontal-navigation .ms-navigation-horizontal .horizontal > li li .ms-navigation-horizontal-item .ms-navigation-horizontal-button.active {
        color: #FFFFFF !important; }
      #horizontal-navigation .ms-navigation-horizontal .horizontal .has-children ul {
        display: none;
        position: absolute;
        background: #FFFFFF;
        z-index: 999;
        min-width: 200px;
        box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
        top: 0;
        left: 100%; }
      #horizontal-navigation .ms-navigation-horizontal .horizontal .has-children:hover > ul {
        display: block; }
      #horizontal-navigation .ms-navigation-horizontal .horizontal > .has-children > ul {
        top: 100%;
        left: 0; }
      #horizontal-navigation .ms-navigation-horizontal .horizontal .ms-navigation-horizontal-item .ms-navigation-horizontal-button {
        display: flex;
        align-items: center;
        width: 100%;
        color: rgba(0, 0, 0, 0.87);
        white-space: nowrap;
        padding: 14px 16px;
        text-decoration: none; }
        #horizontal-navigation .ms-navigation-horizontal .horizontal .ms-navigation-horizontal-item .ms-navigation-horizontal-button .icon {
          color: rgba(0, 0, 0, 0.54);
          margin-right: 8px; }
        #horizontal-navigation .ms-navigation-horizontal .horizontal .ms-navigation-horizontal-item .ms-navigation-horizontal-button .badge {
          min-width: 20px;
          height: 20px;
          line-height: 20px;
          padding: 0 7px;
          font-size: 11px;
          font-weight: bold;
          border-radius: 20px;
          transition: opacity 0.2s ease-in-out 0.1s; }
        #horizontal-navigation .ms-navigation-horizontal .horizontal .ms-navigation-horizontal-item .ms-navigation-horizontal-button .arrow {
          margin-left: 8px; }
        #horizontal-navigation .ms-navigation-horizontal .horizontal .ms-navigation-horizontal-item .ms-navigation-horizontal-button.active .badge {
          background: #FFFFFF !important;
          color: rgba(0, 0, 0, 0.87) !important; }
        #horizontal-navigation .ms-navigation-horizontal .horizontal .ms-navigation-horizontal-item .ms-navigation-horizontal-button.active .icon {
          color: #FFFFFF; }

@media only screen and (min-width: 960px) {
  body.ms-navigation-folded:not(.ms-navigation-folded-open) .ms-navigation .animate-height {
    transition: none; }
  body.ms-navigation-folded:not(.ms-navigation-folded-open) .ms-navigation .ms-navigation-node .ms-navigation-item .ms-navigation-button .icon {
    margin: 0; }
  body.ms-navigation-folded:not(.ms-navigation-folded-open) .ms-navigation .ms-navigation-node .ms-navigation-item .ms-navigation-button .title,
  body.ms-navigation-folded:not(.ms-navigation-folded-open) .ms-navigation .ms-navigation-node .ms-navigation-item .ms-navigation-button .arrow,
  body.ms-navigation-folded:not(.ms-navigation-folded-open) .ms-navigation .ms-navigation-node .ms-navigation-item .ms-navigation-button .badge {
    opacity: 0;
    visibility: hidden; }
  body.ms-navigation-folded:not(.ms-navigation-folded-open) .ms-navigation .ms-navigation-node.group > .ms-navigation-item .ms-navigation-button:before {
    content: '';
    display: block;
    position: relative;
    width: 16px;
    border-top: 1px solid rgba(255, 255, 255, 0.12); }
  body.ms-navigation-folded:not(.ms-navigation-folded-open) #ms-navigation-fold-expander {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999; }
  body.ms-navigation-folded #ms-navigation-fold-collapser {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 59; } }

@media only screen and (max-width: 960px) {
  body.ms-navigation-horizontal-mobile-menu-active #horizontal-navigation .navigation-toggle {
    height: 64px;
    margin-right: 16px; }
    body.ms-navigation-horizontal-mobile-menu-active #horizontal-navigation .navigation-toggle .md-button {
      margin: 0;
      width: 64px;
      height: 64px; }
  body.ms-navigation-horizontal-mobile-menu-active #horizontal-navigation .ms-navigation-horizontal {
    display: block !important;
    background: #FFFFFF; }
  #horizontal-navigation {
    z-index: 60; }
    #horizontal-navigation .ms-navigation-horizontal {
      position: absolute;
      display: none;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 0 0 16px 0;
      z-index: 9998;
      overflow-x: hidden;
      overflow-y: scroll; }
      #horizontal-navigation .ms-navigation-horizontal .horizontal {
        flex-direction: column;
        min-height: 100%;
        width: 100%; }
        #horizontal-navigation .ms-navigation-horizontal .horizontal li:not(.active):hover {
          background: none; }
        #horizontal-navigation .ms-navigation-horizontal .horizontal li:not(.active) .ms-navigation-horizontal-item {
          margin: 4px; }
          #horizontal-navigation .ms-navigation-horizontal .horizontal li:not(.active) .ms-navigation-horizontal-item:hover {
            background: rgba(0, 0, 0, 0.12); }
        #horizontal-navigation .ms-navigation-horizontal .horizontal > li {
          padding: 0 12px; }
          #horizontal-navigation .ms-navigation-horizontal .horizontal > li > .ms-navigation-horizontal-item > .ms-navigation-horizontal-button {
            display: flex;
            float: none; }
            #horizontal-navigation .ms-navigation-horizontal .horizontal > li > .ms-navigation-horizontal-item > .ms-navigation-horizontal-button .arrow {
              display: block; }
        #horizontal-navigation .ms-navigation-horizontal .horizontal .has-children ul {
          position: relative;
          top: 0;
          left: 0;
          box-shadow: none; }
        #horizontal-navigation .ms-navigation-horizontal .horizontal .has-children:hover > ul {
          display: none; }
        #horizontal-navigation .ms-navigation-horizontal .horizontal .has-children .expanded > .ms-navigation-horizontal-button .arrow {
          transform: rotate(90deg); }
        #horizontal-navigation .ms-navigation-horizontal .horizontal .has-children .expanded + ul {
          display: block;
          margin-left: 16px; }
        #horizontal-navigation .ms-navigation-horizontal .horizontal .ms-navigation-horizontal-item {
          min-height: 48px;
          height: 48px; } }

/*----------------------------------------------------------------*/
/*  ms-responsive-table
/*----------------------------------------------------------------*/
.ms-responsive-table-wrapper {
  overflow-x: auto;
  overflow-y: hidden; }

/*----------------------------------------------------------------*/
/*  ms-scroll
/*----------------------------------------------------------------*/
.ms-scroll {
  overflow: hidden !important; }

/*----------------------------------------------------------------*/
/*  ms-search-bar
/*----------------------------------------------------------------*/
.ms-search-bar {
  height: 64px;
  font-size: 13px; }
  .ms-search-bar .ms-search-bar-expander,
  .ms-search-bar .ms-search-bar-collapser {
    cursor: pointer;
    padding: 0 20px;
    margin: 0;
    width: 64px !important;
    height: 64px !important;
    line-height: 64px !important; }
  .ms-search-bar .ms-search-bar-loader {
    width: 64px !important;
    height: 64px !important;
    line-height: 64px !important; }
  .ms-search-bar .ms-search-bar-collapser {
    display: none; }
  .ms-search-bar #ms-search-bar-input {
    display: none;
    min-height: 64px;
    background-color: transparent;
    font-size: 16px; }
  .ms-search-bar .ms-search-bar-results {
    position: absolute;
    top: 64px;
    left: 0;
    right: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    background: #FFFFFF;
    max-height: 300px;
    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12); }
    .ms-search-bar .ms-search-bar-results .no-results {
      font-size: 18px;
      padding: 16px;
      color: rgba(0, 0, 0, 0.54); }
    .ms-search-bar .ms-search-bar-results .result {
      position: relative;
      height: 56px;
      min-height: 56px;
      max-height: 56px;
      padding: 16px;
      cursor: pointer; }
      .ms-search-bar .ms-search-bar-results .result.selected {
        background: rgba(0, 0, 0, 0.07); }
      .ms-search-bar .ms-search-bar-results .result .icon-container {
        min-width: 32px;
        margin-right: 8px; }
        .ms-search-bar .ms-search-bar-results .result .icon-container .square {
          width: 6px;
          height: 6px; }
      .ms-search-bar .ms-search-bar-results .result .title {
        font-size: 16px; }
      .ms-search-bar .ms-search-bar-results .result .description {
        padding-top: 6px;
        font-size: 14px; }
  .ms-search-bar.expanded {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #FFFFFF;
    z-index: 10; }
    .ms-search-bar.expanded #ms-search-bar-input {
      display: block; }
    .ms-search-bar.expanded .ms-search-bar-collapser {
      display: block; }

body.ms-search-bar-expanded #toolbar {
  z-index: 999 !important; }

@media screen and (max-width: 960px) {
  .ms-search-bar-label {
    padding: 0 8px 0 12px; } }

/*----------------------------------------------------------------*/
/*  ms-shortcuts
/*----------------------------------------------------------------*/
.ms-shortcuts {
  height: 64px;
  font-size: 13px; }
  .ms-shortcuts .shortcuts-container {
    height: 64px;
    padding: 0 8px;
    max-width: 400px; }
    .ms-shortcuts .shortcuts-container .shortcuts {
      position: relative;
      overflow-x: scroll; }
      .ms-shortcuts .shortcuts-container .shortcuts > div {
        height: 64px;
        min-width: 44px; }
        .ms-shortcuts .shortcuts-container .shortcuts > div.dragging {
          cursor: grabbing; }
          .ms-shortcuts .shortcuts-container .shortcuts > div.dragging .md-ripple-container {
            display: none; }
        .ms-shortcuts .shortcuts-container .shortcuts > div.ghost .shortcut {
          opacity: 0.27; }
        .ms-shortcuts .shortcuts-container .shortcuts > div .shortcut {
          margin: 2px; }
          .ms-shortcuts .shortcuts-container .shortcuts > div .shortcut .first-letter {
            display: inline-block;
            vertical-align: middle;
            font-size: 20px;
            text-transform: uppercase;
            color: rgba(0, 0, 0, 0.54); }
    .ms-shortcuts .shortcuts-container .shortcut-menu {
      margin: 0; }
    .ms-shortcuts .shortcuts-container.mobile-bar-active {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #FFFFFF;
      z-index: 99;
      max-width: none !important; }
      .ms-shortcuts .shortcuts-container.mobile-bar-active .shortcuts,
      .ms-shortcuts .shortcuts-container.mobile-bar-active .shortcut-menu,
      .ms-shortcuts .shortcuts-container.mobile-bar-active .toggle-mobile-menu {
        display: flex !important; }
      .ms-shortcuts .shortcuts-container.mobile-bar-active .toggle-mobile-menu {
        margin-left: 14px;
        min-width: 40px; }
      .ms-shortcuts .shortcuts-container.mobile-bar-active > div {
        border-right: 1px solid rgba(0, 0, 0, 0.12); }

#ms-shortcut-add-menu {
  position: relative;
  width: 240px;
  min-width: 240px;
  max-width: 240px; }
  #ms-shortcut-add-menu .add-shortcut-button {
    width: 48px;
    height: 48px;
    padding: 0 12px;
    margin: 0; }
  #ms-shortcut-add-menu .shortcut-search {
    height: 45px;
    padding: 0 8px; }
    #ms-shortcut-add-menu .shortcut-search .search-icon {
      width: 40px;
      height: 40px;
      line-height: 48px; }
    #ms-shortcut-add-menu .shortcut-search .shortcut-loader {
      padding: 10px; }
    #ms-shortcut-add-menu .shortcut-search input {
      padding: 8px; }
  #ms-shortcut-add-menu .results {
    position: relative;
    overflow-y: scroll;
    max-height: 233px;
    margin-top: 8px;
    padding-top: 8px;
    border-top: 1px solid rgba(0, 0, 0, 0.12); }
    #ms-shortcut-add-menu .results .no-results {
      font-size: 18px;
      padding: 16px;
      color: rgba(0, 0, 0, 0.54); }
    #ms-shortcut-add-menu .results .result {
      position: relative;
      min-height: 56px;
      padding: 16px;
      cursor: pointer; }
      #ms-shortcut-add-menu .results .result.selected {
        background: rgba(0, 0, 0, 0.07); }
      #ms-shortcut-add-menu .results .result .icon-container {
        min-width: 32px;
        margin-right: 8px; }
        #ms-shortcut-add-menu .results .result .icon-container .square {
          width: 6px;
          height: 6px; }
      #ms-shortcut-add-menu .results .result .title {
        font-size: 16px; }
      #ms-shortcut-add-menu .results .result .description {
        padding-top: 6px;
        font-size: 14px; }

#splash-screen {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #3C4252;
  z-index: 99999; }
  #splash-screen.ng-leave {
    opacity: 1;
    transition: all linear 200ms; }
  #splash-screen.ng-leave-active {
    opacity: 0; }
  #splash-screen .center {
    display: block;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
  #splash-screen .logo {
    width: 200px;
    height: 45px;
    line-height: 45px;
    margin: 0 auto;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 56px;
    text-align: center;
    border-radius: 2px;
    background-color: transparent;
    color: #FFFFFF; }
  #splash-screen .spinner-wrapper {
    display: block;
    position: relative;
    width: 100%;
    min-height: 100px;
    height: 100px; }
    #splash-screen .spinner-wrapper .spinner {
      position: absolute;
      overflow: hidden;
      left: 50%;
      margin-left: -50px;
      animation: outer-rotate 2.91667s linear infinite; }
      #splash-screen .spinner-wrapper .spinner .inner {
        width: 100px;
        height: 100px;
        position: relative;
        animation: sporadic-rotate 5.25s cubic-bezier(0.35, 0, 0.25, 1) infinite; }
        #splash-screen .spinner-wrapper .spinner .inner .gap {
          position: absolute;
          left: 49px;
          right: 49px;
          top: 0;
          bottom: 0;
          border-top: 10px solid;
          box-sizing: border-box; }
        #splash-screen .spinner-wrapper .spinner .inner .left,
        #splash-screen .spinner-wrapper .spinner .inner .right {
          position: absolute;
          top: 0;
          height: 100px;
          width: 50px;
          overflow: hidden; }
          #splash-screen .spinner-wrapper .spinner .inner .left .half-circle,
          #splash-screen .spinner-wrapper .spinner .inner .right .half-circle {
            position: absolute;
            top: 0;
            width: 100px;
            height: 100px;
            box-sizing: border-box;
            border: 10px solid #4285F4;
            border-bottom-color: transparent;
            border-radius: 50%; }
        #splash-screen .spinner-wrapper .spinner .inner .left {
          left: 0; }
          #splash-screen .spinner-wrapper .spinner .inner .left .half-circle {
            left: 0;
            border-right-color: transparent;
            animation: left-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
            -webkit-animation: left-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite; }
        #splash-screen .spinner-wrapper .spinner .inner .right {
          right: 0; }
          #splash-screen .spinner-wrapper .spinner .inner .right .half-circle {
            right: 0;
            border-left-color: transparent;
            animation: right-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
            -webkit-animation: right-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite; }

@keyframes outer-rotate {
  0% {
    transform: rotate(0deg) scale(0.5); }
  100% {
    transform: rotate(360deg) scale(0.5); } }

@keyframes left-wobble {
  0%, 100% {
    transform: rotate(130deg); }
  50% {
    transform: rotate(-5deg); } }

@keyframes right-wobble {
  0%, 100% {
    transform: rotate(-130deg); }
  50% {
    transform: rotate(5deg); } }

@keyframes sporadic-rotate {
  12.5% {
    transform: rotate(135deg); }
  25% {
    transform: rotate(270deg); }
  37.5% {
    transform: rotate(405deg); }
  50% {
    transform: rotate(540deg); }
  62.5% {
    transform: rotate(675deg); }
  75% {
    transform: rotate(810deg); }
  87.5% {
    transform: rotate(945deg); }
  100% {
    transform: rotate(1080deg); } }

.ms-timeline .ms-timeline-loader {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  margin: 0 auto;
  background: #FFFFFF;
  font-weight: 500;
  opacity: 0;
  z-index: 11;
  border-radius: 50%; }
  .ms-timeline .ms-timeline-loader.show {
    opacity: 1; }
  .ms-timeline .ms-timeline-loader .spinner {
    width: 24px;
    height: 24px;
    border: 3px solid rgba(255, 255, 255, 0.35);
    border-top-color: #FFFFFF;
    border-radius: 50%; }

/*----------------------------------------------------------------*/
/*  ms-widget
/*----------------------------------------------------------------*/
.ms-widget .ms-widget-front {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }

.ms-widget .ms-widget-back {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }

.ms-widget.sidenav-widget .ms-widget-front,
.ms-widget.sidenav-widget .ms-widget-back {
  box-shadow: none; }

.ms-widget .flip-to-front {
  position: absolute;
  top: 0;
  right: 0; }

.ms-widget .c3 {
  height: 100%; }

.ms-widget .chart-fill-8 {
  margin: 0 -8px -8px -8px; }

.ms-widget .chart-fill-16 {
  margin: 0 -16px -16px -16px; }

.ms-widget .chart-fill-24 {
  margin: 0 -24px -24px -24px; }

.ms-widget .chart-fill-32 {
  margin: 0 -32px -32px -32px; }

.ms-widget .chart-fill-40 {
  margin: 0 -40px -40px -40px; }

.ms-widget .chart-fill-48 {
  margin: 0 -48px -48px -48px; }

.ms-widget .chart-fill-56 {
  margin: 0 -56px -56px -56px; }

.ms-widget .chart-fill-64 {
  margin: 0 -64px -64px -64px; }

.ms-widget .chart-fill-72 {
  margin: 0 -72px -72px -72px; }

.ms-widget .chart-fill-80 {
  margin: 0 -80px -80px -80px; }

.ms-widget {
  position: relative;
  font-size: 1.3rem;
  perspective: 3000px;
  padding: 12px; }
  .ms-widget .ms-widget-front {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    position: relative;
    overflow: hidden;
    visibility: visible;
    width: 100%;
    opacity: 1;
    z-index: 10;
    border-radius: 2px;
    transition: transform 0.5s ease-out 0s, visibility 0s ease-in 0.2s, opacity 0s ease-in 0.2s;
    transform: rotateY(0deg);
    backface-visibility: hidden; }
  .ms-widget .ms-widget-back {
    display: block;
    position: absolute;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    z-index: 10;
    top: 12px;
    right: 12px;
    bottom: 12px;
    left: 12px;
    border-radius: 2px;
    transition: transform 0.5s ease-out 0s, visibility 0s ease-in 0.2s, opacity 0s ease-in 0.2s;
    transform: rotateY(180deg);
    backface-visibility: hidden; }
  .ms-widget .md-button {
    margin: 0; }
    .ms-widget .md-button.md-icon-button {
      margin: 0; }
  .ms-widget.flipped .ms-widget-front {
    visibility: hidden;
    opacity: 0;
    transform: rotateY(180deg); }
  .ms-widget.flipped .ms-widget-back {
    display: block;
    visibility: visible;
    opacity: 1;
    transform: rotateY(360deg); }
  .ms-widget.sidenav-widget {
    padding: 0; }
  .ms-widget.ar-2-1 .widget {
    padding: 0;
    position: relative; }
    .ms-widget.ar-2-1 .widget:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: 50%; }
    .ms-widget.ar-2-1 .widget > .widget {
      position: absolute;
      top: 8px;
      left: 8px;
      right: 8px;
      bottom: 8px; }
  .ms-widget.ar-1-1 .widget {
    padding: 0;
    position: relative; }
    .ms-widget.ar-1-1 .widget:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: 100%; }
    .ms-widget.ar-1-1 .widget > .widget {
      position: absolute;
      top: 8px;
      left: 8px;
      right: 8px;
      bottom: 8px; }

md-dialog.import-item-dialog {
  max-width: 600px;
  width: 600px; }
  md-dialog.import-item-dialog md-toolbar {
    height: 60px;
    min-height: 60px;
    max-height: 60px; }
    md-dialog.import-item-dialog md-toolbar .title {
      font-size: 17px; }
  md-dialog.import-item-dialog md-dialog-content {
    display: block;
    position: relative; }
    md-dialog.import-item-dialog md-dialog-content .schema-row md-icon {
      color: #a7a6a6; }
  md-dialog.import-item-dialog md-dialog-actions {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.12); }

md-dialog.import-item-group-dialog {
  max-width: 600px;
  width: 600px; }
  md-dialog.import-item-group-dialog md-toolbar {
    height: 60px;
    min-height: 60px;
    max-height: 60px; }
    md-dialog.import-item-group-dialog md-toolbar .title {
      font-size: 17px; }
  md-dialog.import-item-group-dialog md-dialog-content {
    display: block;
    position: relative; }
    md-dialog.import-item-group-dialog md-dialog-content .schema-row md-icon {
      color: #a7a6a6; }
  md-dialog.import-item-group-dialog md-dialog-actions {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.12); }

#purchasing-edit-purchase-form .center .header {
  height: 136px !important;
  min-height: 136px !important;
  max-height: 136px !important;
  padding: 24px 0; }
  #purchasing-edit-purchase-form .center .header .h1 md-icon {
    margin-right: 12px; }

#purchasing-edit-purchase-form .center .content-card .toolbar {
  padding: 8px; }
  #purchasing-edit-purchase-form .center .content-card .toolbar .action-buttons button.md-icon-button {
    border: 1px solid #efefef;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
    line-height: 30px;
    padding: 0;
    width: 30px;
    border-radius: 50%; }
    #purchasing-edit-purchase-form .center .content-card .toolbar .action-buttons button.md-icon-button md-icon {
      font-size: 21px;
      width: 21px;
      height: 21px;
      min-width: 21px;
      min-height: 21px;
      line-height: 21px; }
  #purchasing-edit-purchase-form .center .content-card .toolbar .action-buttons .md-icon-button:hover {
    background-color: #efefef; }
  #purchasing-edit-purchase-form .center .content-card .toolbar .action-buttons md-divider.vertical-divider {
    border-top-width: 0;
    border-right-width: 1px;
    border-right-style: solid;
    border-right-color: #e0e0e0;
    height: 24px;
    margin: 0 9px; }

#purchasing-edit-purchase-form .center .content-card .content {
  padding: 0 0 90px 0;
  position: relative; }
  #purchasing-edit-purchase-form .center .content-card .content .m-table-container .md-icon-button {
    margin: 0;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
    line-height: 18px;
    padding: 0;
    width: 18px;
    border-radius: 50%; }
    #purchasing-edit-purchase-form .center .content-card .content .m-table-container .md-icon-button md-icon {
      font-size: 18px;
      width: 18px;
      height: 18px;
      min-width: 18px;
      min-height: 18px;
      line-height: 18px; }
  #purchasing-edit-purchase-form .center .content-card .content .m-table-container .m-table-cbox-table {
    display: table-cell;
    padding-left: 3px;
    left: 0px; }
    #purchasing-edit-purchase-form .center .content-card .content .m-table-container .m-table-cbox-table .m-table-cbox-title-row .m-table-cbox-title-cell {
      width: 36px;
      height: 100px;
      vertical-align: middle; }
      #purchasing-edit-purchase-form .center .content-card .content .m-table-container .m-table-cbox-table .m-table-cbox-title-row .m-table-cbox-title-cell .table-header {
        height: 90px; }
    #purchasing-edit-purchase-form .center .content-card .content .m-table-container .m-table-cbox-table .m-table-cbox-column-row .m-table-cbox-column-cell {
      height: 84px;
      text-align: center; }
      #purchasing-edit-purchase-form .center .content-card .content .m-table-container .m-table-cbox-table .m-table-cbox-column-row .m-table-cbox-column-cell md-checkbox {
        margin-bottom: 0px;
        min-width: 21px;
        min-height: 21px;
        margin-right: auto;
        margin-left: auto;
        width: 21px;
        height: 21px; }
  #purchasing-edit-purchase-form .center .content-card .content .m-table-container .m-table-cbox-table.ng-hide {
    left: -33px; }
  #purchasing-edit-purchase-form .center .content-card .content .m-table-container .m-table-fixed-column-table {
    display: table-cell; }
    #purchasing-edit-purchase-form .center .content-card .content .m-table-container .m-table-fixed-column-table .m-table-header-title-row .m-table-header-title-cell {
      width: 175px;
      height: 100px;
      vertical-align: middle;
      padding: 0px; }
      #purchasing-edit-purchase-form .center .content-card .content .m-table-container .m-table-fixed-column-table .m-table-header-title-row .m-table-header-title-cell .table-header {
        height: 90px;
        text-align: center;
        background: #004566;
        color: #ffffff;
        font-weight: bold;
        font-size: 12px; }
    #purchasing-edit-purchase-form .center .content-card .content .m-table-container .m-table-fixed-column-table .m-table-fixed-column-row .m-table-fixed-column-cell {
      width: 175px;
      height: 75px;
      vertical-align: middle;
      padding: 0px;
      font-size: 11px; }
      #purchasing-edit-purchase-form .center .content-card .content .m-table-container .m-table-fixed-column-table .m-table-fixed-column-row .m-table-fixed-column-cell .item-name {
        height: 21px;
        text-align: left;
        border: 1px solid #d5d6d6;
        background: #d5d6d6;
        color: #000000;
        padding: 3px; }
      #purchasing-edit-purchase-form .center .content-card .content .m-table-container .m-table-fixed-column-table .m-table-fixed-column-row .m-table-fixed-column-cell .description {
        color: gray;
        border: 1px solid #c5c5c5;
        border-top-style: none;
        padding: 3px;
        height: 21px;
        width: 175px;
        max-width: 175px; }
      #purchasing-edit-purchase-form .center .content-card .content .m-table-container .m-table-fixed-column-table .m-table-fixed-column-row .m-table-fixed-column-cell .details {
        border: 1px solid #c5c5c5;
        border-top-style: none;
        padding: 3px;
        display: block;
        height: 21px; }
        #purchasing-edit-purchase-form .center .content-card .content .m-table-container .m-table-fixed-column-table .m-table-fixed-column-row .m-table-fixed-column-cell .details .quantity {
          float: right; }
      #purchasing-edit-purchase-form .center .content-card .content .m-table-container .m-table-fixed-column-table .m-table-fixed-column-row .m-table-fixed-column-cell .actions {
        color: gray;
        border: 1px solid #c5c5c5;
        border-top-style: none;
        padding: 3px;
        height: 21px; }
  #purchasing-edit-purchase-form .center .content-card .content .m-table-container .m-table-qtable {
    display: table-cell; }
    #purchasing-edit-purchase-form .center .content-card .content .m-table-container .m-table-qtable .m-table-header-row {
      height: 100px; }
      #purchasing-edit-purchase-form .center .content-card .content .m-table-container .m-table-qtable .m-table-header-row .m-table-header-cell {
        width: 175px;
        height: 100px;
        padding: 0px; }
        #purchasing-edit-purchase-form .center .content-card .content .m-table-container .m-table-qtable .m-table-header-row .m-table-header-cell .supplier {
          height: 21px;
          text-align: center;
          background: #004566;
          color: #ffffff;
          font-size: 11px; }
          #purchasing-edit-purchase-form .center .content-card .content .m-table-container .m-table-qtable .m-table-header-row .m-table-header-cell .supplier .name {
            line-height: 21px;
            float: left;
            padding-left: 5px;
            max-width: 165px;
            width: 165px; }
        #purchasing-edit-purchase-form .center .content-card .content .m-table-container .m-table-qtable .m-table-header-row .m-table-header-cell .desc {
          height: 24px;
          text-align: left;
          border: 1px solid #808080;
          background: #e9e9e9;
          color: #000;
          font-size: 10px;
          padding: 3px; }
          #purchasing-edit-purchase-form .center .content-card .content .m-table-container .m-table-qtable .m-table-header-row .m-table-header-cell .desc .icon {
            float: left; }
          #purchasing-edit-purchase-form .center .content-card .content .m-table-container .m-table-qtable .m-table-header-row .m-table-header-cell .desc .remarks {
            float: left;
            margin-left: 5px;
            max-width: 144px;
            width: 144px; }
        #purchasing-edit-purchase-form .center .content-card .content .m-table-container .m-table-qtable .m-table-header-row .m-table-header-cell .details {
          height: 24px;
          text-align: left;
          border: 1px solid #808080;
          border-top: none;
          background: #e9e9e9;
          color: #000;
          font-size: 10px;
          padding: 3px; }
        #purchasing-edit-purchase-form .center .content-card .content .m-table-container .m-table-qtable .m-table-header-row .m-table-header-cell .actions {
          height: 21px;
          text-align: center;
          vertical-align: middle;
          border: 1px solid #808080;
          border-top: none;
          background: #e9e9e9;
          color: #000;
          font-size: 10px;
          padding: 1px; }
          #purchasing-edit-purchase-form .center .content-card .content .m-table-container .m-table-qtable .m-table-header-row .m-table-header-cell .actions .merlon-link {
            margin-left: 3px;
            margin-right: 3px;
            cursor: pointer; }
    #purchasing-edit-purchase-form .center .content-card .content .m-table-container .m-table-qtable .m-table-qrow .m-table-qcell {
      width: 175px;
      height: 75px;
      vertical-align: middle;
      padding: 0px;
      font-size: 11px;
      color: #353535; }
      #purchasing-edit-purchase-form .center .content-card .content .m-table-container .m-table-qtable .m-table-qrow .m-table-qcell.selected {
        background-color: #b4ffb4; }
      #purchasing-edit-purchase-form .center .content-card .content .m-table-container .m-table-qtable .m-table-qrow .m-table-qcell .desc {
        height: 21px;
        color: gray;
        border: 1px solid #c5c5c5;
        padding: 3px;
        width: 175px;
        max-width: 175px;
        background-color: #e9e9e9; }
      #purchasing-edit-purchase-form .center .content-card .content .m-table-container .m-table-qtable .m-table-qrow .m-table-qcell .unit-price {
        height: 21px;
        border: 1px solid #c5c5c5;
        border-top-style: none;
        padding: 3px; }
        #purchasing-edit-purchase-form .center .content-card .content .m-table-container .m-table-qtable .m-table-qrow .m-table-qcell .unit-price .label {
          float: left; }
        #purchasing-edit-purchase-form .center .content-card .content .m-table-container .m-table-qtable .m-table-qrow .m-table-qcell .unit-price .value {
          float: right; }
      #purchasing-edit-purchase-form .center .content-card .content .m-table-container .m-table-qtable .m-table-qrow .m-table-qcell .discount {
        height: 21px;
        border: 1px solid #c5c5c5;
        border-top-style: none;
        padding: 3px;
        display: block; }
        #purchasing-edit-purchase-form .center .content-card .content .m-table-container .m-table-qtable .m-table-qrow .m-table-qcell .discount .disc-1 {
          float: left; }
        #purchasing-edit-purchase-form .center .content-card .content .m-table-container .m-table-qtable .m-table-qrow .m-table-qcell .discount .disc-2 {
          float: right; }
      #purchasing-edit-purchase-form .center .content-card .content .m-table-container .m-table-qtable .m-table-qrow .m-table-qcell .total {
        height: 21px;
        border: 1px solid #c5c5c5;
        border-top-style: none;
        padding: 3px; }
        #purchasing-edit-purchase-form .center .content-card .content .m-table-container .m-table-qtable .m-table-qrow .m-table-qcell .total .label {
          float: left; }
        #purchasing-edit-purchase-form .center .content-card .content .m-table-container .m-table-qtable .m-table-qrow .m-table-qcell .total .value {
          float: right;
          font-weight: 600; }
    #purchasing-edit-purchase-form .center .content-card .content .m-table-container .m-table-qtable .m-table-qrow .m-table-qcell:hover {
      background-color: #b4ffb4;
      cursor: pointer; }
  #purchasing-edit-purchase-form .center .content-card .content .m-table-footer-title-container {
    padding-top: 9px;
    float: left; }
    #purchasing-edit-purchase-form .center .content-card .content .m-table-footer-title-container .m-table-footer-table .m-table-footer-row .m-table-footer-cell {
      width: 175px;
      min-width: 175px;
      max-width: 175px;
      background-color: #004566;
      color: #ffffff;
      font-size: 10px;
      font-weight: 500;
      height: 21px;
      text-align: right; }
      #purchasing-edit-purchase-form .center .content-card .content .m-table-footer-title-container .m-table-footer-table .m-table-footer-row .m-table-footer-cell span {
        margin-right: 3px; }
  #purchasing-edit-purchase-form .center .content-card .content .fix-container-wrapper {
    position: fixed;
    left: 33px;
    right: 33px;
    bottom: 0px;
    background-color: #ffffff; }
    #purchasing-edit-purchase-form .center .content-card .content .fix-container-wrapper .m-table-footer-title-fix-container {
      padding: 9px 0;
      float: left; }
      #purchasing-edit-purchase-form .center .content-card .content .fix-container-wrapper .m-table-footer-title-fix-container .m-table-footer-table .m-table-footer-row .m-table-footer-cell {
        width: 175px;
        min-width: 175px;
        max-width: 175px;
        background-color: #004566;
        color: #ffffff;
        font-size: 10px;
        font-weight: 500;
        height: 21px;
        text-align: right; }
        #purchasing-edit-purchase-form .center .content-card .content .fix-container-wrapper .m-table-footer-title-fix-container .m-table-footer-table .m-table-footer-row .m-table-footer-cell span {
          margin-right: 3px; }
  #purchasing-edit-purchase-form .center .content-card .content .m-table-footer-container {
    padding: 9px 0;
    display: flex; }
    #purchasing-edit-purchase-form .center .content-card .content .m-table-footer-container .m-table-footer-table .m-table-footer-row .m-table-footer-cell {
      width: 175px;
      min-width: 175px;
      max-width: 175px;
      height: 21px;
      border: 1px solid #c5c5c5;
      background-color: #d5d6d6;
      color: #000000;
      padding-right: 3px;
      padding-left: 3px;
      font-weight: 540;
      font-size: 12px;
      text-align: right; }

#import-suppliers {
  position: relative;
  height: auto;
  min-height: 100%; }
  #import-suppliers > .header {
    height: 90px;
    min-height: 90px;
    max-height: 90px; }
    #import-suppliers > .header .h1 md-icon {
      margin-right: 12px; }
    #import-suppliers > .header md-icon {
      color: #ffffff; }
    #import-suppliers > .header .md-button {
      text-transform: inherit; }
  #import-suppliers > .content {
    position: absolute;
    top: 90px;
    bottom: 0;
    right: 0;
    left: 0; }
    #import-suppliers > .content .main {
      padding: 0px 24px 0 24px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
      #import-suppliers > .content .main .data-list {
        position: relative;
        padding-bottom: 0; }
        #import-suppliers > .content .main .data-list .md-subheader {
          box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
          border-bottom: .1px solid #dadada; }
          #import-suppliers > .content .main .data-list .md-subheader .md-subheader-inner {
            padding: 12px 16px; }
            #import-suppliers > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content {
              font-size: 12px;
              font-weight: 900; }
        #import-suppliers > .content .main .data-list .list-item {
          border-bottom: .1px solid #dadada; }
      #import-suppliers > .content .main .no-results {
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14); }

@media screen and (max-width: 960px) {
  #import-suppliers > .header .h1 {
    font-size: 1.8rem; }
  #import-suppliers > .header .records-count {
    font-size: 1.1rem; }
  #import-suppliers > .content .main {
    padding: 0; }
    #import-suppliers > .content .main .data-list {
      padding-top: 0; } }

md-dialog.delete-object-dialog {
  max-width: 500px;
  width: 500px; }
  md-dialog.delete-object-dialog md-toolbar .title {
    font-size: 17px; }
  md-dialog.delete-object-dialog md-dialog-content {
    display: block;
    position: relative;
    font-size: 14px; }
    md-dialog.delete-object-dialog md-dialog-content .message {
      margin-bottom: 30px;
      font-weight: 500; }
  md-dialog.delete-object-dialog md-dialog-actions {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.12); }

md-dialog.duplicate-process-dialog {
  max-width: 500px;
  width: 500px; }
  md-dialog.duplicate-process-dialog md-toolbar .title {
    font-size: 17px; }
  md-dialog.duplicate-process-dialog md-dialog-content {
    display: block;
    position: relative;
    font-size: 14px; }
  md-dialog.duplicate-process-dialog md-dialog-actions {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.12); }

md-dialog.verification-warning-dialog {
  max-width: 500px;
  width: 500px; }
  md-dialog.verification-warning-dialog md-toolbar .title {
    font-size: 17px; }
  md-dialog.verification-warning-dialog md-dialog-content {
    display: block;
    position: relative;
    font-size: 15px; }
    md-dialog.verification-warning-dialog md-dialog-content table {
      margin-top: 20px;
      font-size: 12px; }
      md-dialog.verification-warning-dialog md-dialog-content table tr td {
        border: 1px solid #c0c8d4;
        padding: 5px; }
  md-dialog.verification-warning-dialog md-dialog-actions {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.12); }

#import-item-groups {
  position: relative;
  height: auto;
  min-height: 100%; }
  #import-item-groups > .header {
    height: 90px;
    min-height: 90px;
    max-height: 90px; }
    #import-item-groups > .header .h1 md-icon {
      margin-right: 12px; }
    #import-item-groups > .header md-icon {
      color: #ffffff; }
    #import-item-groups > .header .md-button {
      text-transform: inherit; }
  #import-item-groups > .content {
    position: absolute;
    top: 90px;
    bottom: 0;
    right: 0;
    left: 0; }
    #import-item-groups > .content .main {
      padding: 0px 24px 0 24px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
      #import-item-groups > .content .main .data-list {
        position: relative;
        padding-bottom: 0; }
        #import-item-groups > .content .main .data-list .md-subheader {
          box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
          border-bottom: .1px solid #dadada; }
          #import-item-groups > .content .main .data-list .md-subheader .md-subheader-inner {
            padding: 12px 16px; }
            #import-item-groups > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content {
              font-size: 12px;
              font-weight: 900; }
              #import-item-groups > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:last-child {
                width: 60px;
                text-align: center; }
        #import-item-groups > .content .main .data-list .list-item {
          border-bottom: .1px solid #dadada; }
          #import-item-groups > .content .main .data-list .list-item i {
            width: 60px;
            text-align: center; }
      #import-item-groups > .content .main .no-results {
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14); }

@media screen and (max-width: 960px) {
  #import-item-groups > .header .h1 {
    font-size: 1.8rem; }
  #import-item-groups > .header .records-count {
    font-size: 1.1rem; }
  #import-item-groups > .content .main {
    padding: 0; }
    #import-item-groups > .content .main .data-list {
      padding-top: 0; } }

#import-items {
  position: relative;
  height: auto;
  min-height: 100%; }
  #import-items > .header {
    height: 90px;
    min-height: 90px;
    max-height: 90px; }
    #import-items > .header .h1 md-icon {
      margin-right: 12px; }
    #import-items > .header md-icon {
      color: #ffffff; }
    #import-items > .header .md-button {
      text-transform: inherit; }
  #import-items > .content {
    position: absolute;
    top: 90px;
    bottom: 0;
    right: 0;
    left: 0; }
    #import-items > .content .main {
      padding: 0px 24px 0 24px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
      #import-items > .content .main .data-list {
        position: relative;
        padding-bottom: 0; }
        #import-items > .content .main .data-list .md-subheader {
          box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
          border-bottom: .1px solid #dadada; }
          #import-items > .content .main .data-list .md-subheader .md-subheader-inner {
            padding: 12px 16px; }
            #import-items > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content {
              font-size: 12px;
              font-weight: 900; }
              #import-items > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:last-child {
                width: 60px;
                text-align: center; }
        #import-items > .content .main .data-list .list-item {
          border-bottom: .1px solid #dadada; }
          #import-items > .content .main .data-list .list-item i {
            width: 60px;
            text-align: center; }
      #import-items > .content .main .no-results {
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14); }

@media screen and (max-width: 960px) {
  #import-items > .header .h1 {
    font-size: 1.8rem; }
  #import-items > .header .records-count {
    font-size: 1.1rem; }
  #import-items > .content .main {
    padding: 0; }
    #import-items > .content .main .data-list {
      padding-top: 0; } }

#messaging-panel {
  width: 330px;
  min-width: 330px;
  max-width: 330px;
  z-index: 99; }
  #messaging-panel md-content {
    height: 100%; }
    #messaging-panel md-content md-tabs {
      height: 100%; }
      #messaging-panel md-content md-tabs md-tabs-wrapper {
        background-color: rgba(0, 0, 0, 0.03);
        border-bottom: 1px solid rgba(0, 0, 0, 0.06); }
        #messaging-panel md-content md-tabs md-tabs-wrapper .md-tab {
          max-width: none !important; }
      #messaging-panel md-content md-tabs md-tabs-content-wrapper md-tab-content {
        height: 100%;
        overflow: hidden; }
        #messaging-panel md-content md-tabs md-tabs-content-wrapper md-tab-content > div {
          overflow: hidden;
          height: 100%; }

@media screen and (max-width: 600px) {
  #messaging-panel {
    width: 80%;
    min-width: 80%;
    max-width: 80%; } }

#notifications-panel {
  width: 330px;
  min-width: 330px;
  max-width: 330px;
  z-index: 99; }
  #notifications-panel md-content {
    height: 100%; }
    #notifications-panel md-content md-tabs {
      height: 100%; }
      #notifications-panel md-content md-tabs md-tabs-wrapper {
        background-color: rgba(0, 0, 0, 0.03);
        border-bottom: 1px solid rgba(0, 0, 0, 0.06); }
        #notifications-panel md-content md-tabs md-tabs-wrapper .md-tab {
          max-width: none !important; }
      #notifications-panel md-content md-tabs md-tabs-content-wrapper md-tab-content {
        height: 100%;
        overflow: hidden; }
        #notifications-panel md-content md-tabs md-tabs-content-wrapper md-tab-content > div {
          overflow: hidden;
          height: 100%; }

@media screen and (max-width: 600px) {
  #notifications-panel {
    width: 80%;
    min-width: 80%;
    max-width: 80%; } }

#toolbar {
  height: 64px;
  min-height: 64px;
  max-height: 64px;
  z-index: 55 !important; }
  #toolbar #navigation-toggle {
    width: 64px;
    min-width: 64px;
    height: 64px;
    margin: 0;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 0; }
  #toolbar #user-menu {
    height: 64px;
    padding: 0; }
    #toolbar #user-menu md-menu-item .md-button {
      font-size: 15px; }
      #toolbar #user-menu md-menu-item .md-button md-icon {
        margin: auto 16px auto 0; }
    #toolbar #user-menu md-menu-divider {
      margin: 0 !important; }
    #toolbar #user-menu .user-button {
      margin: 0;
      height: 64px;
      text-transform: none;
      width: 230px;
      color: #757575;
      font-weight: 400;
      font-size: 11px; }
      #toolbar #user-menu .user-button span {
        line-height: normal; }
      #toolbar #user-menu .user-button md-icon {
        margin: 0; }
      #toolbar #user-menu .user-button .avatar-wrapper {
        position: relative; }
        #toolbar #user-menu .user-button .avatar-wrapper .status {
          position: absolute;
          bottom: -3px;
          right: 5px; }
      #toolbar #user-menu .user-button .user-card {
        text-align: left; }
        #toolbar #user-menu .user-button .user-card span {
          width: 150px;
          max-width: 150px;
          min-width: 150px; }
  #toolbar #language-menu {
    height: 64px; }
    #toolbar #language-menu .language-button {
      min-width: 64px;
      height: 64px;
      margin: 0;
      padding: 0 16px;
      border-radius: 0; }
      #toolbar #language-menu .language-button .iso {
        text-transform: uppercase; }
      #toolbar #language-menu .language-button .flag {
        margin-right: 8px; }
  #toolbar #quick-panel-toggle {
    width: 64px;
    height: 64px;
    margin: 0;
    border-radius: 0; }
  #toolbar .toolbar-separator {
    height: 64px;
    width: 1px;
    background: rgba(0, 0, 0, 0.12); }

#language-menu-content md-menu-item .flag {
  margin-right: 16px; }

#language-message {
  min-height: 88px;
  max-width: 600px; }
  #language-message .md-toast-content {
    height: 90px; }

@media screen and (max-width: 600px) {
  #toolbar #user-menu .user-button {
    min-width: 64px;
    padding: 0 2px 0 10px; } }

.doc-page > .header {
  height: 140px !important;
  min-height: 140px !important;
  max-height: 140px !important;
  padding: 24px !important; }
  .doc-page > .header .reference-button {
    padding: 0 8px;
    min-width: 45px; }
    .doc-page > .header .reference-button span {
      margin-left: 4px; }

.doc-page > .content {
  max-width: 1200px; }
  .doc-page > .content .description {
    margin-bottom: 32px; }
  .doc-page > .content .card {
    background: #FFFFFF;
    margin: 16px 0 48px 0;
    padding: 24px; }
    .doc-page > .content .card .preview,
    .doc-page > .content .card .source-code {
      margin: 8px; }
    .doc-page > .content .card .preview {
      background: #F9FAFB;
      min-height: 90px;
      padding: 8px 16px; }
    .doc-page > .content .card .source-code {
      background: #F3F4F6;
      position: relative; }
      .doc-page > .content .card .source-code [hljs], .doc-page > .content .card .source-code hljs {
        display: block;
        background-color: transparent;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0; }
  .doc-page > .content .attributes {
    margin: 16px 0 48px 0; }

@media screen and (max-width: 600px) {
  .doc-page .source-code {
    min-height: 120px; }
  .doc-page > .header .reference-button {
    margin: 0; }
    .doc-page > .header .reference-button span {
      display: none; }
  .doc-page .content .ms-responsive-table-wrapper table tbody td.description {
    min-width: 200px; } }

body .menu-panel-container {
  pointer-events: auto; }
  body .menu-panel-container .menu-panel {
    width: 180px;
    background-color: #fff;
    border-radius: 4px; }
    body .menu-panel-container .menu-panel .menu-content {
      display: flex;
      flex-direction: column;
      padding: 8px 0;
      max-height: 305px;
      overflow-y: auto;
      min-width: 180px; }
      body .menu-panel-container .menu-panel .menu-content .menu-item {
        display: flex;
        flex-direction: row;
        min-height: 48px;
        height: 48px;
        align-content: center;
        justify-content: flex-start; }
        body .menu-panel-container .menu-panel .menu-content .menu-item > * {
          width: 100%;
          margin: auto 0;
          padding-left: 16px;
          padding-right: 16px; }
        body .menu-panel-container .menu-panel .menu-content .menu-item > a.md-button {
          padding-top: 5px; }
        body .menu-panel-container .menu-panel .menu-content .menu-item > .md-button {
          display: inline-block;
          border-radius: 0;
          margin: auto 0;
          font-size: 15px;
          text-transform: none;
          font-weight: 400;
          height: 100%;
          padding-left: 16px;
          padding-right: 16px;
          width: 100%;
          text-align: left; }
          body .menu-panel-container .menu-panel .menu-content .menu-item > .md-button ::-moz-focus-inner {
            padding: 0;
            border: 0; }
          body .menu-panel-container .menu-panel .menu-content .menu-item > .md-button md-icon {
            margin: auto 16px auto 0; }
          body .menu-panel-container .menu-panel .menu-content .menu-item > .md-button p {
            display: inline-block;
            margin: auto; }
          body .menu-panel-container .menu-panel .menu-content .menu-item > .md-button span {
            margin-top: auto;
            margin-bottom: auto; }
          body .menu-panel-container .menu-panel .menu-content .menu-item > .md-button .md-ripple-container {
            border-radius: inherit; }

body .menu-divider {
  width: 100%;
  height: 1px;
  min-height: 1px;
  max-height: 1px;
  margin-top: 4px;
  margin-bottom: 4px;
  background-color: rgba(0, 0, 0, 0.11); }

body md-dialog .md-dialog-content {
  min-width: 450px; }

.chart-doc-page .content {
  max-width: 800px; }
  .chart-doc-page .content .c3 > svg {
    min-height: 300px; }

#widgets .content {
  max-width: inherit;
  padding: 8px; }

#card-change-fail .header {
  height: 160px;
  min-height: 160px;
  max-height: 160px;
  background-size: 100% auto; }

#card-change-fail .content {
  padding: 24px;
  background: #fff;
  margin: 12px auto;
  max-width: 480px;
  text-align: center;
  margin-top: 10%; }
  #card-change-fail .content md-icon {
    color: #F44336; }
  #card-change-fail .content .md-button {
    text-transform: inherit; }
  #card-change-fail .content p {
    text-align: justify; }

@media only screen and (max-width: 600px) {
  #card-change-fail .content {
    margin: 0; } }

#card-change-success .content {
  padding: 24px;
  background: #fff;
  margin: 12px auto;
  max-width: 600px;
  text-align: center;
  margin-top: 10%; }
  #card-change-success .content md-icon {
    color: #4caf50; }
  #card-change-success .content .md-button {
    text-transform: inherit; }
  #card-change-success .content p {
    text-align: center; }
  #card-change-success .content table {
    width: 100%;
    border: 1px solid #cac8c8; }
    #card-change-success .content table tr td {
      text-align: left; }
    #card-change-success .content table tr td:first-child {
      width: 105px; }

@media only screen and (max-width: 600px) {
  #card-change-success .content {
    margin: 0; } }

#console > .header {
  height: 120px;
  min-height: 120px;
  max-height: 120px;
  padding-bottom: 0; }
  #console > .header .h1 md-icon {
    margin-right: 12px;
    color: #ffffff; }

#console > .content {
  background: none;
  padding: 0; }
  #console > .content .widget-group ms-widget {
    min-height: 252px; }

#console > .sidenav {
  width: 330px;
  min-width: 330px;
  max-width: 330px;
  padding: 0;
  z-index: 51;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); }
  #console > .sidenav.md-locked-open {
    width: 250px;
    min-width: 250px;
    max-width: 250px;
    z-index: 2;
    box-shadow: none;
    background: #EEEEEE; }

#profile .header {
  height: 200px;
  min-height: 200px;
  max-height: 200px;
  background-size: 100% auto; }
  #profile .header .profile-image {
    margin-right: 0px; }
  #profile .header .name {
    font-size: 34px;
    color: #FFFFFF;
    margin-left: 24px; }
  #profile .header .img-buttons md-icon {
    color: #FFFFFF; }

#profile .content md-content {
  background-color: transparent; }

#profile .profile-box {
  margin-bottom: 16px; }
  #profile .profile-box header {
    padding: 16px; }
    #profile .profile-box header .title {
      font-size: 17px; }
    #profile .profile-box header .more {
      cursor: pointer; }
  #profile .profile-box .content {
    padding: 16px;
    background-color: #FFF; }
  #profile .profile-box footer {
    padding: 8px;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    background-color: rgba(0, 0, 0, 0.06); }
  #profile .profile-box.info-box .info-line {
    margin-bottom: 24px; }
    #profile .profile-box.info-box .info-line .title {
      font-size: 15px;
      font-weight: 500;
      padding-bottom: 4px; }
    #profile .profile-box.info-box .info-line:last-child {
      margin-bottom: 0; }

@media only screen and (max-width: 960px) {
  #profile .header {
    height: 150px;
    min-height: 150px;
    max-height: 150px; }
    #profile .header .profile-image {
      width: 48px;
      min-width: 48px;
      height: 48px;
      line-height: 48px; }
    #profile .header .name {
      font-size: 16px; }
  #profile .content .profile-about-container #profileAbout {
    font-size: 12px; }
  #profile .content .profile-account-container #profileAccount {
    font-size: 12px; } }

#subscription-success .content {
  padding: 24px;
  background: #fff;
  margin: 12px auto;
  max-width: 600px;
  text-align: center;
  margin-top: 10%; }
  #subscription-success .content md-icon {
    color: #4caf50; }
  #subscription-success .content .md-button {
    text-transform: inherit; }
  #subscription-success .content p {
    text-align: center; }
  #subscription-success .content table {
    width: 100%;
    border: 1px solid #cac8c8; }
    #subscription-success .content table tr td {
      text-align: left; }
    #subscription-success .content table tr td:first-child {
      width: 105px; }

@media only screen and (max-width: 600px) {
  #subscription-success .content {
    margin: 0; } }

#vertical-navigation {
  width: 240px;
  min-width: 240px;
  max-width: 240px;
  overflow-x: hidden;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2), width 0.1s linear, min-width 0.1s linear, max-width 0.1s linear;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12); }
  #vertical-navigation .navigation-header {
    height: 64px;
    min-height: 64px;
    background-color: rgba(255, 255, 255, 0.05);
    padding: 0 16px 0 24px; }
    #vertical-navigation .navigation-header .logo .logo-image {
      display: block;
      background: #039BE5;
      width: 32px;
      min-width: 32px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      color: #FFFFFF;
      border-radius: 2px; }
    #vertical-navigation .navigation-header .logo .logo-text {
      color: #FFFFFF;
      margin-left: 16px;
      font-size: 16px; }
    #vertical-navigation .navigation-header .fold-toggle {
      transition: transform 0.3s ease-in-out 0.1s;
      transform: rotate(0deg);
      margin: 0;
      padding: 8px;
      width: 34px !important;
      height: 34px !important;
      cursor: pointer; }
  #vertical-navigation:not(._md-locked-open) {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); }

@media only screen and (min-width: 960px) {
  .ms-navigation-folded #content-container {
    margin-left: 64px; }
  .ms-navigation-folded #vertical-navigation {
    position: absolute; }
    .ms-navigation-folded #vertical-navigation .navigation-header .fold-toggle {
      transform: rotate(180deg);
      opacity: 0; }
  .ms-navigation-folded.ms-navigation-folded-open #vertical-navigation .navigation-header .fold-toggle {
    transition: transform 0.3s ease-in-out 0.1s, opacity 0.3s ease-in-out 0.2s;
    opacity: 1; }
  .ms-navigation-folded:not(.ms-navigation-folded-open) #vertical-navigation {
    position: absolute;
    width: 64px;
    min-width: 64px;
    max-width: 64px; }
    .ms-navigation-folded:not(.ms-navigation-folded-open) #vertical-navigation .navigation-header {
      padding: 0 16px; }
      .ms-navigation-folded:not(.ms-navigation-folded-open) #vertical-navigation .navigation-header .logo-text {
        display: none; } }

#layout-vertical-navigation-fullwidth-toolbar-2 #vertical-navigation {
  width: 240px;
  min-width: 240px;
  max-width: 240px;
  overflow-x: hidden;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2), width 0.1s linear, min-width 0.1s linear, max-width 0.1s linear;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12); }
  #layout-vertical-navigation-fullwidth-toolbar-2 #vertical-navigation .navigation-header {
    height: 64px;
    min-height: 64px;
    background-color: rgba(255, 255, 255, 0.05);
    padding: 0 16px 0 24px; }
  #layout-vertical-navigation-fullwidth-toolbar-2 #vertical-navigation:not(._md-locked-open) {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); }

@media only screen and (min-width: 960px) {
  .ms-navigation-folded #content-container {
    margin-left: 64px; }
  .ms-navigation-folded #layout-vertical-navigation-fullwidth-toolbar-2 #vertical-navigation {
    position: absolute; }
  .ms-navigation-folded:not(.ms-navigation-folded-open) #layout-vertical-navigation-fullwidth-toolbar-2 #vertical-navigation {
    position: absolute;
    width: 64px;
    min-width: 64px;
    max-width: 64px; }
    .ms-navigation-folded:not(.ms-navigation-folded-open) #layout-vertical-navigation-fullwidth-toolbar-2 #vertical-navigation .navigation-header {
      padding: 0 16px; }
      .ms-navigation-folded:not(.ms-navigation-folded-open) #layout-vertical-navigation-fullwidth-toolbar-2 #vertical-navigation .navigation-header .logo-text {
        display: none; } }

#messaging-panel .chat-tab .main,
#messaging-panel .chat-tab .chat {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0; }

#messaging-panel .chat-tab .main .contact {
  position: relative; }
  #messaging-panel .chat-tab .main .contact .status {
    position: absolute;
    top: 8px;
    left: 44px;
    width: 18px;
    height: 18px;
    border: 3px solid #FFFFFF;
    border-radius: 50%;
    z-index: 10; }
    #messaging-panel .chat-tab .main .contact .status.online {
      background-color: #00C853; }
    #messaging-panel .chat-tab .main .contact .status.offline {
      background-color: #D50000; }
    #messaging-panel .chat-tab .main .contact .status.away {
      background-color: #FF6D00; }
  #messaging-panel .chat-tab .main .contact .unread-message-count {
    position: absolute;
    left: 16px;
    bottom: 16px;
    width: 18px;
    height: 18px;
    z-index: 10;
    border-radius: 50%;
    text-align: center;
    line-height: 18px;
    font-size: 11px; }

#messaging-panel .chat-tab .main .recent .contact .status {
  top: 18px; }

#messaging-panel .chat-tab .chat {
  max-height: 100%;
  overflow: hidden; }
  #messaging-panel .chat-tab .chat .message-row .bubble {
    position: relative;
    padding: 8px;
    background-color: #eceff1;
    border: 1px solid #dcdfe1; }
    #messaging-panel .chat-tab .chat .message-row .bubble:after, #messaging-panel .chat-tab .chat .message-row .bubble:before {
      content: ' ';
      position: absolute;
      width: 0;
      height: 0; }
    #messaging-panel .chat-tab .chat .message-row .bubble:after {
      left: -7px;
      right: auto;
      top: 0px;
      bottom: auto;
      border: 11px solid;
      border-color: #eceff1 transparent transparent transparent; }
    #messaging-panel .chat-tab .chat .message-row .bubble:before {
      left: -9px;
      right: auto;
      top: -1px;
      bottom: auto;
      border: 8px solid;
      border-color: #dcdfe1 transparent transparent transparent; }
    #messaging-panel .chat-tab .chat .message-row .bubble .time {
      font-size: 11px;
      margin-top: 8px; }
  #messaging-panel .chat-tab .chat .message-row.contact .avatar {
    margin: 0 16px 0 0; }
  #messaging-panel .chat-tab .chat .message-row.user {
    align-items: flex-end; }
    #messaging-panel .chat-tab .chat .message-row.user .avatar {
      order: 2;
      margin: 0 0 0 16px; }
    #messaging-panel .chat-tab .chat .message-row.user .bubble {
      background-color: #E8F5E9;
      border: 1px solid #DFEBE0;
      order: 1; }
      #messaging-panel .chat-tab .chat .message-row.user .bubble:after {
        right: -7px;
        left: auto;
        top: auto;
        bottom: 0px;
        border-color: transparent transparent #E8F5E9 transparent; }
      #messaging-panel .chat-tab .chat .message-row.user .bubble:before {
        right: -9px;
        left: auto;
        top: auto;
        bottom: -1px;
        border-color: transparent transparent #DFEBE0 transparent; }
  #messaging-panel .chat-tab .chat .reply {
    border-top: 1px solid rgba(0, 0, 0, 0.12); }
    #messaging-panel .chat-tab .chat .reply textarea {
      margin: 8px;
      resize: none;
      height: 80px;
      padding: 8px 16px 8px 8px; }

#notifications-panel .activity-tab .friends .friend {
  position: relative; }
  #notifications-panel .activity-tab .friends .friend .status {
    position: absolute;
    top: 8px;
    left: 44px;
    width: 18px;
    height: 18px;
    z-index: 10;
    border-radius: 50%;
    border: 3px solid #FFFFFF; }
    #notifications-panel .activity-tab .friends .friend .status.online {
      background-color: #00C853; }
    #notifications-panel .activity-tab .friends .friend .status.offline {
      background-color: #D50000; }
    #notifications-panel .activity-tab .friends .friend .status.away {
      background-color: #FF6D00; }
  #notifications-panel .activity-tab .friends .friend .unread-message-count {
    position: absolute;
    left: 16px;
    bottom: 16px;
    width: 18px;
    height: 18px;
    z-index: 10;
    border-radius: 50%;
    text-align: center;
    line-height: 18px;
    font-size: 11px; }

#notifications-panel .activity-tab .servers .server .status {
  margin: 24px 20px 28px 8px; }
  #notifications-panel .activity-tab .servers .server .status.normal {
    color: #00C853; }
  #notifications-panel .activity-tab .servers .server .status.warn {
    color: #D50000; }
  #notifications-panel .activity-tab .servers .server .status.error {
    color: #FF6D00; }

#notifications-panel .activity-tab .stat md-progress-linear.normal .md-bar {
  background-color: #00C853; }

#notifications-panel .activity-tab .stat md-progress-linear.normal .md-container {
  background-color: rgba(0, 200, 83, 0.3); }

#notifications-panel .activity-tab .stat md-progress-linear.warn .md-bar {
  background-color: #FF6D00; }

#notifications-panel .activity-tab .stat md-progress-linear.warn .md-container {
  background-color: rgba(255, 109, 0, 0.3); }

#notifications-panel .activity-tab .stat md-progress-linear.alert .md-bar {
  background-color: #D50000; }

#notifications-panel .activity-tab .stat md-progress-linear.alert .md-container {
  background-color: rgba(213, 0, 0, 0.3); }

#layout-content-with-toolbar #toolbar {
  padding: 0 0 0 16px;
  height: 0;
  min-height: 0;
  max-height: 0; }
  #layout-content-with-toolbar #toolbar md-select {
    margin: 0;
    font-size: 12px;
    color: #8c8c8c; }

#layout-horizontal-navigation #toolbar-navigation-container {
  z-index: 55; }
  #layout-horizontal-navigation #toolbar-navigation-container #toolbar {
    height: 64px;
    min-height: 64px;
    max-height: 64px;
    box-shadow: none; }
    #layout-horizontal-navigation #toolbar-navigation-container #toolbar .top-row .navigation-toggle {
      height: 64px;
      border-right: 1px solid rgba(0, 0, 0, 0.12); }
      #layout-horizontal-navigation #toolbar-navigation-container #toolbar .top-row .navigation-toggle .md-button {
        margin: 0;
        width: 64px;
        height: 64px; }
    #layout-horizontal-navigation #toolbar-navigation-container #toolbar .top-row .logo {
      margin: 0 16px; }
      #layout-horizontal-navigation #toolbar-navigation-container #toolbar .top-row .logo .logo-image {
        display: block;
        background: #039BE5;
        width: 32px;
        min-width: 32px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 500;
        border-radius: 2px; }
      #layout-horizontal-navigation #toolbar-navigation-container #toolbar .top-row .logo .logo-text {
        color: rgba(0, 0, 0, 0.87);
        margin-left: 16px;
        font-size: 16px; }

@media only screen and (max-width: 960px) {
  #layout-horizontal-navigation #toolbar-navigation-container {
    height: 64px;
    min-height: 64px;
    max-height: 64px; }
    #layout-horizontal-navigation #toolbar-navigation-container #toolbar {
      padding: 0; } }

#toolbar .md-virtual-repeat-container.md-autocomplete-suggestions-container {
  height: 24vh;
  min-height: 12vh;
  max-height: 24vh !important; }

#toolbar .login-panel {
  padding-right: 20px; }
  #toolbar .login-panel .link {
    font-size: 14px; }

#toolbar .search-autocomplete-template {
  color: red; }

#toolbar .search-autocomplete-template li {
  border-bottom: 1px solid #ccc;
  height: auto;
  padding-top: 8px;
  padding-bottom: 8px;
  white-space: normal;
  min-width: 300px; }

#toolbar .search-autocomplete-template li:last-child {
  border-bottom-width: 0; }

#toolbar .search-autocomplete-template .item-title {
  display: block;
  line-height: 2; }
  #toolbar .search-autocomplete-template .item-title .city, #toolbar .search-autocomplete-template .item-title .country {
    color: #757575; }

#toolbar .search-autocomplete-template .show-all {
  display: block;
  line-height: 1;
  color: #757575;
  height: 12px;
  max-height: 12px; }

#toolbar .search-autocomplete-template .show-all .link {
  padding: 0px 10px 0px 10px; }

#toolbar .search-autocomplete-template .show-all .link:hover {
  cursor: pointer;
  color: #039be5; }

#layout-vertical-navigation-fullwidth-toolbar-2 #toolbar .logo {
  width: 240px;
  padding: 0 16px; }
  #layout-vertical-navigation-fullwidth-toolbar-2 #toolbar .logo .logo-image {
    display: block;
    background: #039BE5;
    width: 32px;
    min-width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
    border-radius: 2px; }
  #layout-vertical-navigation-fullwidth-toolbar-2 #toolbar .logo .logo-text {
    color: rgba(0, 0, 0, 0.87);
    margin-left: 16px;
    font-size: 16px; }
  #layout-vertical-navigation-fullwidth-toolbar-2 #toolbar .logo :hover {
    cursor: pointer; }

#layout-vertical-navigation-fullwidth-toolbar-2 #toolbar .search-panel {
  width: 300px; }

#layout-vertical-navigation-fullwidth-toolbar-2 #toolbar .navigation-fold-toggle {
  width: 64px;
  min-width: 64px;
  height: 64px;
  margin: 0;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 0; }
  #layout-vertical-navigation-fullwidth-toolbar-2 #toolbar .navigation-fold-toggle md-icon {
    transition: transform 0.3s ease-in-out 0.1s;
    transform: rotate(0deg); }

#layout-vertical-navigation-fullwidth-toolbar-2 #toolbar .shortcut {
  position: relative; }
  #layout-vertical-navigation-fullwidth-toolbar-2 #toolbar .shortcut .badge {
    top: 4px;
    left: 50%;
    height: 14px;
    line-height: 16px;
    background-color: #f44336;
    border-radius: 50%;
    z-index: 1;
    position: absolute;
    min-width: 14px;
    font-size: 11px;
    font-weight: 400;
    text-align: center;
    padding: 0 4px;
    color: #fff; }

@media only screen and (min-width: 960px) {
  .ms-navigation-folded #layout-vertical-navigation-fullwidth-toolbar-2 #toolbar .navigation-fold-toggle md-icon {
    transform: rotate(180deg); } }

@media only screen and (max-width: 960px) {
  #layout-vertical-navigation-fullwidth-toolbar-2 #toolbar .logo {
    width: auto; } }

#forgot-password {
  height: 100%; }
  #forgot-password #forgot-password-form-wrapper {
    flex: 1 0 auto;
    padding: 32px; }
    #forgot-password #forgot-password-form-wrapper #forgot-password-form {
      width: 384px;
      max-width: 384px;
      padding: 32px;
      text-align: center;
      background: #FFFFFF; }
      #forgot-password #forgot-password-form-wrapper #forgot-password-form .logo {
        margin: 32px auto; }
      #forgot-password #forgot-password-form-wrapper #forgot-password-form .title {
        font-size: 17px;
        margin: 16px 0 32px 0; }
      #forgot-password #forgot-password-form-wrapper #forgot-password-form form {
        width: 100%;
        text-align: left; }
        #forgot-password #forgot-password-form-wrapper #forgot-password-form form .submit-button {
          width: 220px;
          margin: 16px auto;
          display: block; }
      #forgot-password #forgot-password-form-wrapper #forgot-password-form .login {
        margin: 32px auto 24px auto;
        width: 250px;
        font-weight: 500; }
        #forgot-password #forgot-password-form-wrapper #forgot-password-form .login .text {
          margin-right: 8px; }
    #forgot-password #forgot-password-form-wrapper #final-panel {
      width: 450px;
      max-width: 450px;
      padding: 32px;
      text-align: center;
      background: #FFFFFF; }
      #forgot-password #forgot-password-form-wrapper #final-panel .title {
        font-size: 17px;
        font-weight: 500;
        margin: 16px 0 32px 0; }
      #forgot-password #forgot-password-form-wrapper #final-panel .instructions {
        width: 100%;
        text-align: left;
        font-size: 14px;
        line-height: 1.5;
        color: rgba(0, 0, 0, 0.78); }
      #forgot-password #forgot-password-form-wrapper #final-panel .login {
        margin: 32px auto 24px auto;
        width: 250px;
        font-weight: 500; }
        #forgot-password #forgot-password-form-wrapper #final-panel .login .text {
          margin-right: 8px; }

@media screen and (max-width: 600px) {
  #forgot-password #forgot-password-form-wrapper {
    padding: 16px; }
    #forgot-password #forgot-password-form-wrapper #forgot-password-form {
      padding: 24px;
      width: 100%; }
      #forgot-password #forgot-password-form-wrapper #forgot-password-form form .md-button {
        width: 90%; } }

#login {
  height: 100%;
  overflow: hidden;
  background: url("/assets/images/backgrounds/auth-bg.jpg") no-repeat;
  background-size: cover; }
  #login #login-intro {
    padding: 128px; }
    #login #login-intro .logo {
      margin-bottom: 32px; }
    #login #login-intro .title {
      font-size: 42px;
      font-weight: 300; }
    #login #login-intro .description {
      padding-top: 8px;
      font-size: 14px;
      max-width: 600px; }
  #login #login-form-wrapper {
    width: 416px;
    min-width: 416px;
    max-width: 416px;
    height: 100%;
    background: #FFFFFF; }
    #login #login-form-wrapper #login-form {
      padding: 128px 48px 48px 48px; }
      #login #login-form-wrapper #login-form .logo {
        margin: 32px auto; }
      #login #login-form-wrapper #login-form .title {
        font-size: 21px; }
      #login #login-form-wrapper #login-form .description {
        padding-top: 8px; }
      #login #login-form-wrapper #login-form form {
        width: 100%;
        text-align: left;
        padding-top: 32px; }
        #login #login-form-wrapper #login-form form md-checkbox {
          margin: 0; }
        #login #login-form-wrapper #login-form form .text-center {
          color: red;
          font-size: 11px; }
        #login #login-form-wrapper #login-form form .remember-forgot-password {
          font-size: 13px;
          margin-top: 8px; }
          #login #login-form-wrapper #login-form form .remember-forgot-password .remember-me {
            margin-bottom: 16px; }
          #login #login-form-wrapper #login-form form .remember-forgot-password .forgot-password {
            font-size: 13px;
            font-weight: 500;
            margin-bottom: 16px; }
        #login #login-form-wrapper #login-form form .submit-button {
          width: 100%;
          margin: 16px auto;
          display: block; }
      #login #login-form-wrapper #login-form .register {
        margin: 32px auto 24px auto;
        width: 250px;
        font-weight: 500; }
        #login #login-form-wrapper #login-form .register .text {
          margin-right: 8px; }

@media screen and (max-width: 960px) {
  #login #login-form-wrapper {
    width: 100%;
    min-width: 100%;
    max-width: 100%; }
    #login #login-form-wrapper #login-form {
      text-align: center;
      padding: 24px; } }

#signup {
  height: 100%;
  overflow: hidden;
  background: url("/assets/images/backgrounds/auth-bg.jpg") no-repeat;
  background-size: cover; }
  #signup #signup-intro {
    padding: 128px; }
    #signup #signup-intro .logo {
      margin-bottom: 32px; }
    #signup #signup-intro .title {
      font-size: 42px;
      font-weight: 300; }
    #signup #signup-intro .description {
      padding-top: 15px;
      font-size: 14px;
      max-width: 600px;
      line-height: 24px; }
  #signup #signup-form-wrapper {
    width: 416px;
    min-width: 416px;
    max-width: 416px;
    height: 100%;
    background: #FFFFFF; }
    #signup #signup-form-wrapper #signup-form, #signup #signup-form-wrapper #joinwithtalepnet-form {
      padding: 128px 48px 48px 48px; }
      #signup #signup-form-wrapper #signup-form .logo, #signup #signup-form-wrapper #joinwithtalepnet-form .logo {
        margin: 32px auto; }
      #signup #signup-form-wrapper #signup-form .title, #signup #signup-form-wrapper #joinwithtalepnet-form .title {
        font-size: 21px; }
      #signup #signup-form-wrapper #signup-form .description, #signup #signup-form-wrapper #joinwithtalepnet-form .description {
        padding-top: 8px; }
      #signup #signup-form-wrapper #signup-form form, #signup #signup-form-wrapper #joinwithtalepnet-form form {
        width: 100%;
        text-align: left;
        padding-top: 32px; }
        #signup #signup-form-wrapper #signup-form form md-checkbox, #signup #signup-form-wrapper #joinwithtalepnet-form form md-checkbox {
          margin: 0; }
        #signup #signup-form-wrapper #signup-form form .text-center, #signup #signup-form-wrapper #joinwithtalepnet-form form .text-center {
          color: red;
          font-size: 11px; }
        #signup #signup-form-wrapper #signup-form form .terms, #signup #signup-form-wrapper #joinwithtalepnet-form form .terms {
          font-size: 13px;
          margin: 16px 0 32px 0; }
          #signup #signup-form-wrapper #signup-form form .terms a, #signup #signup-form-wrapper #joinwithtalepnet-form form .terms a {
            margin-left: 4px; }
        #signup #signup-form-wrapper #signup-form form .md-button[type=submit], #signup #signup-form-wrapper #joinwithtalepnet-form form .md-button[type=submit] {
          width: 100%;
          margin: 16px auto;
          display: block; }
      #signup #signup-form-wrapper #signup-form .login, #signup #signup-form-wrapper #joinwithtalepnet-form .login {
        margin: 32px auto 24px auto;
        width: 250px;
        font-weight: 500; }
        #signup #signup-form-wrapper #signup-form .login .text, #signup #signup-form-wrapper #joinwithtalepnet-form .login .text {
          margin-right: 8px; }
    #signup #signup-form-wrapper #portaluser-form {
      padding: 128px 48px 48px 48px; }
      #signup #signup-form-wrapper #portaluser-form .logo {
        margin: 32px auto; }
      #signup #signup-form-wrapper #portaluser-form .title {
        font-size: 21px; }
      #signup #signup-form-wrapper #portaluser-form .description {
        padding-top: 8px; }
      #signup #signup-form-wrapper #portaluser-form form {
        width: 100%;
        text-align: left;
        padding-top: 32px; }
        #signup #signup-form-wrapper #portaluser-form form .md-button {
          width: 100%;
          margin: 16px auto;
          display: block; }

@media screen and (max-width: 960px) {
  #signup #signup-form-wrapper {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    padding: 16px; }
    #signup #signup-form-wrapper #signup-form {
      padding: 24px;
      text-align: center; } }

#signup-invitation {
  height: 100%;
  overflow: hidden;
  background: url("/assets/images/backgrounds/auth-bg.jpg") no-repeat;
  background-size: cover; }
  #signup-invitation #signup-intro {
    padding: 128px; }
    #signup-invitation #signup-intro .logo {
      margin-bottom: 32px; }
    #signup-invitation #signup-intro .title {
      font-size: 42px;
      font-weight: 300; }
    #signup-invitation #signup-intro .description {
      padding-top: 8px;
      font-size: 14px;
      max-width: 600px; }
  #signup-invitation #signup-form-wrapper {
    width: 416px;
    min-width: 416px;
    max-width: 416px;
    height: 100%;
    background: #FFFFFF; }
    #signup-invitation #signup-form-wrapper #signup-form {
      padding: 128px 48px 48px 48px; }
      #signup-invitation #signup-form-wrapper #signup-form .logo {
        margin: 32px auto; }
      #signup-invitation #signup-form-wrapper #signup-form .title {
        font-size: 21px; }
      #signup-invitation #signup-form-wrapper #signup-form .description {
        padding-top: 8px; }
      #signup-invitation #signup-form-wrapper #signup-form form {
        width: 100%;
        text-align: left;
        padding-top: 32px; }
        #signup-invitation #signup-form-wrapper #signup-form form md-checkbox {
          margin: 0; }
        #signup-invitation #signup-form-wrapper #signup-form form .text-center {
          color: red;
          font-size: 11px; }
        #signup-invitation #signup-form-wrapper #signup-form form .terms {
          font-size: 13px;
          margin: 16px 0 32px 0; }
          #signup-invitation #signup-form-wrapper #signup-form form .terms a {
            margin-left: 4px; }
        #signup-invitation #signup-form-wrapper #signup-form form .md-button[type=submit] {
          width: 100%;
          margin: 16px auto;
          display: block; }
      #signup-invitation #signup-form-wrapper #signup-form .login {
        margin: 32px auto 24px auto;
        width: 250px;
        font-weight: 500; }
        #signup-invitation #signup-form-wrapper #signup-form .login .text {
          margin-right: 8px; }
  #signup-invitation #login-form-wrapper {
    width: 416px;
    min-width: 416px;
    max-width: 416px;
    height: 100%;
    background: #FFFFFF; }
    #signup-invitation #login-form-wrapper #login-form {
      padding: 128px 48px 48px 48px; }
      #signup-invitation #login-form-wrapper #login-form .logo {
        margin: 32px auto; }
      #signup-invitation #login-form-wrapper #login-form .title {
        font-size: 21px;
        display: inline-flex; }
      #signup-invitation #login-form-wrapper #login-form .description {
        padding-top: 8px; }
      #signup-invitation #login-form-wrapper #login-form form {
        width: 100%;
        text-align: left;
        padding-top: 32px; }
        #signup-invitation #login-form-wrapper #login-form form md-checkbox {
          margin: 0; }
        #signup-invitation #login-form-wrapper #login-form form .text-center {
          color: red;
          font-size: 11px; }
        #signup-invitation #login-form-wrapper #login-form form .remember-forgot-password {
          font-size: 13px;
          margin-top: 8px; }
          #signup-invitation #login-form-wrapper #login-form form .remember-forgot-password .remember-me {
            margin-bottom: 16px; }
          #signup-invitation #login-form-wrapper #login-form form .remember-forgot-password .forgot-password {
            font-size: 13px;
            font-weight: 500;
            margin-bottom: 16px; }
        #signup-invitation #login-form-wrapper #login-form form .submit-button {
          width: 100%;
          margin: 16px auto;
          display: block; }
      #signup-invitation #login-form-wrapper #login-form .register {
        margin: 32px auto 24px auto;
        width: 250px;
        font-weight: 500; }
        #signup-invitation #login-form-wrapper #login-form .register .text {
          margin-right: 8px; }

@media screen and (max-width: 960px) {
  #signup-invitation #signup-intro {
    padding: 24px; }
    #signup-invitation #signup-intro .title {
      font-size: 30px; }
  #signup-invitation #signup-form-wrapper {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    padding: 16px; }
    #signup-invitation #signup-form-wrapper #signup-form {
      padding: 24px;
      text-align: center; }
  #signup-invitation #login-form-wrapper {
    width: 100%;
    min-width: 100%;
    max-width: 100%; }
    #signup-invitation #login-form-wrapper #login-form {
      text-align: center;
      padding: 24px; } }

#user-actions {
  height: 100%;
  overflow: hidden;
  background: url("/assets/images/backgrounds/auth-bg.jpg") no-repeat;
  background-size: cover; }
  #user-actions #user-actions-form-wrapper {
    flex: 1 0 auto;
    padding: 32px; }
    #user-actions #user-actions-form-wrapper #user-actions-form {
      padding: 33px;
      width: 500px;
      max-width: 500px;
      background: #FFFFFF; }
      #user-actions #user-actions-form-wrapper #user-actions-form .email-verify .title-success {
        margin: 8px 0 8px 0;
        font-size: 17px;
        font-weight: 500;
        text-align: center; }
      #user-actions #user-actions-form-wrapper #user-actions-form .email-verify .title-error {
        margin: 8px 0 8px 0;
        font-size: 17px;
        font-weight: 500;
        text-align: center; }
      #user-actions #user-actions-form-wrapper #user-actions-form .email-verify .desc {
        width: 100%;
        margin: 32px 0 0 0;
        font-size: 14px;
        text-align: center; }
      #user-actions #user-actions-form-wrapper #user-actions-form .email-verify .continue-link {
        margin-top: 20px; }
      #user-actions #user-actions-form-wrapper #user-actions-form .email-reset .code-valid .title {
        font-size: 17px;
        font-weight: 500;
        margin: 16px 0 32px 0;
        text-align: center; }
      #user-actions #user-actions-form-wrapper #user-actions-form .email-reset .code-valid form {
        width: 100%;
        margin: 32px 0 0 0; }
        #user-actions #user-actions-form-wrapper #user-actions-form .email-reset .code-valid form .submit-button {
          width: 220px;
          margin: 32px auto;
          display: block; }
      #user-actions #user-actions-form-wrapper #user-actions-form .email-reset .code-invalid .title {
        font-size: 17px;
        font-weight: 500;
        margin: 16px 0 32px 0;
        text-align: center; }
      #user-actions #user-actions-form-wrapper #user-actions-form .email-reset .code-invalid .desc {
        width: 100%;
        margin: 32px 0 0 0;
        color: rgba(0, 0, 0, 0.78);
        font-size: 14px;
        text-align: center; }
      #user-actions #user-actions-form-wrapper #user-actions-form .email-reset .code-invalid .continue-link {
        margin-top: 20px; }
      #user-actions #user-actions-form-wrapper #user-actions-form .email-reset .reset-password-success .title {
        font-size: 17px;
        font-weight: 500;
        margin: 16px 0 32px 0;
        text-align: center; }
      #user-actions #user-actions-form-wrapper #user-actions-form .email-reset .reset-password-success .desc {
        width: 100%;
        margin: 32px 0 0 0;
        color: rgba(0, 0, 0, 0.78);
        font-size: 14px;
        text-align: center; }
      #user-actions #user-actions-form-wrapper #user-actions-form .email-reset .reset-password-success .continue-link {
        margin-top: 20px; }
      #user-actions #user-actions-form-wrapper #user-actions-form .email-unverified .title {
        margin: 8px 0 8px 0;
        font-size: 17px;
        font-weight: 500;
        text-align: center; }
      #user-actions #user-actions-form-wrapper #user-actions-form .email-unverified .desc {
        color: rgba(0, 0, 0, 0.78);
        font-size: 14px;
        text-align: center; }
      #user-actions #user-actions-form-wrapper #user-actions-form .email-unverified .email {
        text-align: center;
        font-size: 14px;
        font-weight: 300; }
      #user-actions #user-actions-form-wrapper #user-actions-form .email-unverified .logo {
        text-align: center;
        margin-right: 16px; }
      #user-actions #user-actions-form-wrapper #user-actions-form .email-unverified form {
        width: 100%;
        margin: 32px 0 0 0; }
        #user-actions #user-actions-form-wrapper #user-actions-form .email-unverified form .submit-button {
          width: 220px;
          margin: 32px auto;
          display: block; }
      #user-actions #user-actions-form-wrapper #user-actions-form .email-unverified .continue-link {
        margin-top: 20px; }
      #user-actions #user-actions-form-wrapper #user-actions-form .email-unverified .message {
        font-weight: 500;
        text-align: center; }

@media screen and (max-width: 960px) {
  #user-actions #user-actions-form-wrapper {
    padding: 16px; }
    #user-actions #user-actions-form-wrapper #user-actions-form {
      padding: 24px;
      width: 100%; }
      #user-actions #user-actions-form-wrapper #user-actions-form .icon-container {
        margin-bottom: 10px; }
      #user-actions #user-actions-form-wrapper #user-actions-form form .md-button {
        width: 90%; } }

#error-404 .content {
  width: 90%;
  max-width: 512px;
  margin-top: 128px; }
  #error-404 .content .error-code {
    font-size: 112px;
    text-align: center;
    line-height: 1;
    margin-bottom: 16px;
    font-weight: 500; }
  #error-404 .content .message {
    font-size: 24px;
    text-align: center;
    color: rgba(0, 0, 0, 0.54); }
  #error-404 .content .search {
    width: 100%;
    height: 56px;
    line-height: 56px;
    margin: 48px auto 16px auto;
    padding: 16px;
    background: #FFFFFF; }
    #error-404 .content .search input {
      padding: 0 0 0 16px; }
  #error-404 .content .back-link {
    font-size: 15px;
    text-align: center; }

#error-500 .content {
  width: 90%;
  max-width: 512px;
  margin-top: 128px; }
  #error-500 .content .error-code {
    font-size: 112px;
    line-height: 1;
    text-align: center;
    margin-bottom: 16px;
    font-weight: 500; }
  #error-500 .content .message {
    font-size: 24px;
    text-align: center;
    color: rgba(0, 0, 0, 0.54); }
  #error-500 .content .sub-message {
    font-size: 17px;
    text-align: center;
    color: rgba(0, 0, 0, 0.54);
    margin: 16px auto 48px auto; }
  #error-500 .content .report-link {
    text-align: center;
    font-size: 15px; }

md-dialog.inventory-deliver-request-dialog {
  max-width: 720px;
  width: 720px; }
  md-dialog.inventory-deliver-request-dialog md-toolbar {
    height: 60px;
    min-height: 60px;
    max-height: 60px; }
    md-dialog.inventory-deliver-request-dialog md-toolbar .title {
      font-size: 17px; }
  md-dialog.inventory-deliver-request-dialog md-dialog-content {
    display: block;
    position: relative;
    height: 400px;
    max-height: 400px;
    min-height: 400px;
    font-size: 15px; }
    md-dialog.inventory-deliver-request-dialog md-dialog-content table thead tr th {
      padding: 6px 18px 6px 0px;
      font-weight: 800;
      border-bottom: 1px solid #7d7d7d; }
    md-dialog.inventory-deliver-request-dialog md-dialog-content table thead tr th:first-child {
      padding-left: 0; }
    md-dialog.inventory-deliver-request-dialog md-dialog-content table thead tr th:last-child {
      padding-right: 0; }
    md-dialog.inventory-deliver-request-dialog md-dialog-content table thead tr th.quantity, md-dialog.inventory-deliver-request-dialog md-dialog-content table thead tr th.available {
      text-align: right;
      padding-right: 30px; }
    md-dialog.inventory-deliver-request-dialog md-dialog-content table tbody tr td.quantity, md-dialog.inventory-deliver-request-dialog md-dialog-content table tbody tr td.available {
      width: 150px;
      max-width: 150px;
      min-width: 150px;
      text-align: right;
      padding-right: 30px; }
    md-dialog.inventory-deliver-request-dialog md-dialog-content table tbody tr td.unit {
      width: 120px;
      max-width: 120px;
      min-width: 120px; }
    md-dialog.inventory-deliver-request-dialog md-dialog-content table tbody tr td.item {
      width: 270px;
      max-width: 270px;
      min-width: 270px; }
    md-dialog.inventory-deliver-request-dialog md-dialog-content table tbody tr td:first-child {
      padding-left: 0; }
    md-dialog.inventory-deliver-request-dialog md-dialog-content table tbody tr .icon-checkbox-marked-circle {
      color: green; }
    md-dialog.inventory-deliver-request-dialog md-dialog-content table tbody tr .icon-alert-circle {
      color: red; }
    md-dialog.inventory-deliver-request-dialog md-dialog-content table tbody tr:first-child td {
      padding-top: 20px; }
  md-dialog.inventory-deliver-request-dialog md-dialog-actions {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.12); }

#inventory-level-sidenav.sidenav {
  width: 320px;
  min-width: 320px;
  max-width: 320px;
  padding: 0; }
  #inventory-level-sidenav.sidenav._md-locked-open {
    width: 320px;
    min-width: 320px;
    max-width: 320px;
    overflow: hidden; }
  #inventory-level-sidenav.sidenav .header {
    height: 90px;
    min-height: 90px;
    max-height: 90px;
    padding: 24px 24px;
    background-color: #078aca; }
    #inventory-level-sidenav.sidenav .header .h1 .title {
      font-size: 18px;
      line-height: 18px; }
    #inventory-level-sidenav.sidenav .header .h1 md-icon {
      margin-right: 12px; }
    #inventory-level-sidenav.sidenav .header .item-name {
      margin: 6px 0 0 30px; }
  #inventory-level-sidenav.sidenav .content {
    position: relative;
    background: #FFFFFF;
    padding: 24px; }
    #inventory-level-sidenav.sidenav .content .widget-group > .ms-widget {
      padding-right: 0;
      padding-left: 0; }
      #inventory-level-sidenav.sidenav .content .widget-group > .ms-widget .ms-widget-front div.head .h3 {
        font-size: 15px; }
      #inventory-level-sidenav.sidenav .content .widget-group > .ms-widget .ms-widget-front div.stats .count {
        font-size: 27px;
        line-height: 27px; }
      #inventory-level-sidenav.sidenav .content .widget-group > .ms-widget .ms-widget-front div.stats .unit {
        font-size: 1.2rem;
        padding-left: 3px; }

@media screen and (max-width: 960px) {
  #inventory-level-sidenav.sidenav.sidenav {
    width: 240px;
    min-width: 240px;
    max-width: 240px;
    font-size: 11px; }
    #inventory-level-sidenav.sidenav.sidenav h3, #inventory-level-sidenav.sidenav.sidenav h4, #inventory-level-sidenav.sidenav.sidenav p {
      font-size: 11px !important; } }

#purchase-process-sidenav.sidenav {
  width: 320px;
  min-width: 320px;
  max-width: 320px;
  padding: 0; }
  #purchase-process-sidenav.sidenav._md-locked-open {
    width: 320px;
    min-width: 320px;
    max-width: 320px;
    overflow: hidden; }
  #purchase-process-sidenav.sidenav .header {
    height: 90px;
    min-height: 90px;
    max-height: 90px;
    padding: 24px 24px;
    background-color: #078aca; }
    #purchase-process-sidenav.sidenav .header .title {
      font-size: 15px;
      margin-right: 20px; }
    #purchase-process-sidenav.sidenav .header .status-icon md-icon {
      color: #ffffff; }
  #purchase-process-sidenav.sidenav .content {
    position: relative;
    background: #FFFFFF; }

@media screen and (max-width: 960px) {
  #purchase-process-sidenav.sidenav {
    width: 240px;
    min-width: 240px;
    max-width: 240px;
    font-size: 11px; }
    #purchase-process-sidenav.sidenav h3, #purchase-process-sidenav.sidenav h4, #purchase-process-sidenav.sidenav p {
      font-size: 11px !important; } }

#request-process-sidenav.sidenav {
  width: 320px;
  min-width: 320px;
  max-width: 320px;
  padding: 0; }
  #request-process-sidenav.sidenav._md-locked-open {
    width: 320px;
    min-width: 320px;
    max-width: 320px;
    overflow: hidden; }
  #request-process-sidenav.sidenav .header {
    height: 90px;
    min-height: 90px;
    max-height: 90px;
    padding: 24px 24px;
    background-color: #078aca; }
    #request-process-sidenav.sidenav .header .title {
      font-size: 15px;
      margin-right: 20px; }
    #request-process-sidenav.sidenav .header .status-icon md-icon {
      color: #ffffff; }
  #request-process-sidenav.sidenav .content {
    position: relative;
    background: #FFFFFF; }

@media screen and (max-width: 960px) {
  #request-process-sidenav.sidenav.sidenav {
    width: 240px;
    min-width: 240px;
    max-width: 240px;
    font-size: 11px; }
    #request-process-sidenav.sidenav.sidenav h3, #request-process-sidenav.sidenav.sidenav h4, #request-process-sidenav.sidenav.sidenav p {
      font-size: 11px !important; } }

#goods-receipts-add > .header {
  height: 120px;
  min-height: 120px;
  max-height: 120px; }
  #goods-receipts-add > .header .goto-goods-receipts-button {
    margin-right: 16px; }
  #goods-receipts-add > .header .subtitle {
    margin: 6px 0 0 0; }
  #goods-receipts-add > .header .md-icon-button:hover {
    background-color: #f2f2f233; }
  #goods-receipts-add > .header .md-icon-button md-icon {
    color: #fafafa; }
  #goods-receipts-add > .header .md-icon-button[disabled] md-icon {
    color: #fafafa69; }

#goods-receipts-add > .content {
  padding: 24px; }
  #goods-receipts-add > .content .detail-form-container {
    padding: 24px;
    margin-bottom: 24px;
    max-width: 990px; }
    #goods-receipts-add > .content .detail-form-container form {
      font-size: 12px !important; }
      #goods-receipts-add > .content .detail-form-container form table.simple thead tr th {
        padding: 0 5px 0 5px; }
      #goods-receipts-add > .content .detail-form-container form table.simple thead tr th.unit-price, #goods-receipts-add > .content .detail-form-container form table.simple thead tr th.quantity, #goods-receipts-add > .content .detail-form-container form table.simple thead tr th.net-total {
        text-align: right;
        padding-right: 9px; }
      #goods-receipts-add > .content .detail-form-container form table.simple thead tr th.actions {
        text-align: center; }
      #goods-receipts-add > .content .detail-form-container form table.simple tbody tr {
        padding-top: 0;
        padding-bottom: 0; }
        #goods-receipts-add > .content .detail-form-container form table.simple tbody tr td {
          padding: 0 5px 0 5px;
          border-bottom: none; }
        #goods-receipts-add > .content .detail-form-container form table.simple tbody tr td.quantity, #goods-receipts-add > .content .detail-form-container form table.simple tbody tr td.unit-price, #goods-receipts-add > .content .detail-form-container form table.simple tbody tr td.net-total {
          width: 81px;
          max-width: 81px;
          min-width: 81px;
          text-align: right; }
          #goods-receipts-add > .content .detail-form-container form table.simple tbody tr td.quantity md-input-container, #goods-receipts-add > .content .detail-form-container form table.simple tbody tr td.unit-price md-input-container, #goods-receipts-add > .content .detail-form-container form table.simple tbody tr td.net-total md-input-container {
            width: inherit;
            margin: 0;
            /* Chrome, Safari, Edge, Opera */
            /* Firefox */ }
            #goods-receipts-add > .content .detail-form-container form table.simple tbody tr td.quantity md-input-container .md-errors-spacer, #goods-receipts-add > .content .detail-form-container form table.simple tbody tr td.unit-price md-input-container .md-errors-spacer, #goods-receipts-add > .content .detail-form-container form table.simple tbody tr td.net-total md-input-container .md-errors-spacer {
              min-height: 6px; }
            #goods-receipts-add > .content .detail-form-container form table.simple tbody tr td.quantity md-input-container input::-webkit-outer-spin-button,
            #goods-receipts-add > .content .detail-form-container form table.simple tbody tr td.quantity md-input-container input::-webkit-inner-spin-button, #goods-receipts-add > .content .detail-form-container form table.simple tbody tr td.unit-price md-input-container input::-webkit-outer-spin-button,
            #goods-receipts-add > .content .detail-form-container form table.simple tbody tr td.unit-price md-input-container input::-webkit-inner-spin-button, #goods-receipts-add > .content .detail-form-container form table.simple tbody tr td.net-total md-input-container input::-webkit-outer-spin-button,
            #goods-receipts-add > .content .detail-form-container form table.simple tbody tr td.net-total md-input-container input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0; }
            #goods-receipts-add > .content .detail-form-container form table.simple tbody tr td.quantity md-input-container input[type=number], #goods-receipts-add > .content .detail-form-container form table.simple tbody tr td.unit-price md-input-container input[type=number], #goods-receipts-add > .content .detail-form-container form table.simple tbody tr td.net-total md-input-container input[type=number] {
              -moz-appearance: textfield; }
            #goods-receipts-add > .content .detail-form-container form table.simple tbody tr td.quantity md-input-container input, #goods-receipts-add > .content .detail-form-container form table.simple tbody tr td.unit-price md-input-container input, #goods-receipts-add > .content .detail-form-container form table.simple tbody tr td.net-total md-input-container input {
              text-align: end; }
        #goods-receipts-add > .content .detail-form-container form table.simple tbody tr md-select {
          margin: 0 0 6px 0; }
        #goods-receipts-add > .content .detail-form-container form table.simple tbody tr td.unit .md-select-value {
          width: 81px;
          max-width: 81px;
          min-width: 81px; }
        #goods-receipts-add > .content .detail-form-container form table.simple tbody tr td.item .md-select-value {
          width: 300px;
          max-width: 300px;
          min-width: 300px; }
        #goods-receipts-add > .content .detail-form-container form table.simple tbody tr td.actions {
          text-align: center; }
          #goods-receipts-add > .content .detail-form-container form table.simple tbody tr td.actions .md-button {
            min-width: auto;
            min-height: auto;
            line-height: unset; }
            #goods-receipts-add > .content .detail-form-container form table.simple tbody tr td.actions .md-button .icon-delete {
              font-size: 18px;
              width: 18px;
              height: 18px;
              min-width: 18px;
              min-height: 18px;
              line-height: 18px; }
        #goods-receipts-add > .content .detail-form-container form table.simple tbody tr md-input-container {
          width: inherit;
          margin: 0; }
          #goods-receipts-add > .content .detail-form-container form table.simple tbody tr md-input-container .md-errors-spacer {
            min-height: 6px; }
      #goods-receipts-add > .content .detail-form-container form table.simple tbody tr:first-child td {
        padding-top: 15px !important; }
      #goods-receipts-add > .content .detail-form-container form md-input-container.remarks {
        margin-bottom: 18px; }
      #goods-receipts-add > .content .detail-form-container form md-input-container.supplier {
        margin-bottom: 0;
        margin-top: 0; }
      #goods-receipts-add > .content .detail-form-container form md-input-container.city {
        margin-bottom: 0;
        margin-top: 0; }
      #goods-receipts-add > .content .detail-form-container form md-input-container.date {
        margin-bottom: 18px; }
      #goods-receipts-add > .content .detail-form-container form md-input-container.receipt-number {
        margin-top: 0px;
        margin-bottom: 0px; }
      #goods-receipts-add > .content .detail-form-container form md-input-container.city {
        margin-top: 18px;
        margin-bottom: 0px; }
      #goods-receipts-add > .content .detail-form-container form md-input-container.country {
        margin-top: 36px;
        margin-bottom: 0px; }
      #goods-receipts-add > .content .detail-form-container form md-input-container.text-area {
        margin-top: 36px;
        margin-bottom: 0px; }
      #goods-receipts-add > .content .detail-form-container form .header-col:last-child {
        margin-left: 15px; }

@media screen and (max-width: 600px) {
  #goods-receipts-add > .header {
    padding: 24px 0; }
  #goods-receipts-add > .content {
    padding: 0; }
    #goods-receipts-add > .content .detail-form-container form .header-col:last-child {
      margin-left: 0 !important; } }

#goods-receipts-edit > .header {
  height: 120px;
  min-height: 120px;
  max-height: 120px; }
  #goods-receipts-edit > .header .goto-goods-receipts-button {
    margin-right: 16px; }
  #goods-receipts-edit > .header .subtitle {
    margin: 6px 0 0 0; }
  #goods-receipts-edit > .header .md-icon-button:hover {
    background-color: #f2f2f233; }
  #goods-receipts-edit > .header .md-icon-button md-icon {
    color: #fafafa; }
  #goods-receipts-edit > .header .md-icon-button[disabled] md-icon {
    color: #fafafa69; }

#goods-receipts-edit > .content {
  padding: 24px; }
  #goods-receipts-edit > .content .detail-form-container {
    padding: 24px;
    margin-bottom: 24px;
    max-width: 990px; }
    #goods-receipts-edit > .content .detail-form-container form {
      font-size: 12px !important; }
      #goods-receipts-edit > .content .detail-form-container form table.simple thead tr th {
        padding: 0 5px 0 5px; }
      #goods-receipts-edit > .content .detail-form-container form table.simple thead tr th.unit-price, #goods-receipts-edit > .content .detail-form-container form table.simple thead tr th.quantity, #goods-receipts-edit > .content .detail-form-container form table.simple thead tr th.net-total {
        text-align: right;
        padding-right: 9px; }
      #goods-receipts-edit > .content .detail-form-container form table.simple thead tr th.actions {
        text-align: center; }
      #goods-receipts-edit > .content .detail-form-container form table.simple tbody tr {
        padding-top: 0;
        padding-bottom: 0; }
        #goods-receipts-edit > .content .detail-form-container form table.simple tbody tr td {
          padding: 0 5px 0 5px;
          border-bottom: none; }
        #goods-receipts-edit > .content .detail-form-container form table.simple tbody tr td.quantity, #goods-receipts-edit > .content .detail-form-container form table.simple tbody tr td.unit-price, #goods-receipts-edit > .content .detail-form-container form table.simple tbody tr td.net-total {
          width: 81px;
          max-width: 81px;
          min-width: 81px;
          text-align: right; }
          #goods-receipts-edit > .content .detail-form-container form table.simple tbody tr td.quantity md-input-container, #goods-receipts-edit > .content .detail-form-container form table.simple tbody tr td.unit-price md-input-container, #goods-receipts-edit > .content .detail-form-container form table.simple tbody tr td.net-total md-input-container {
            width: inherit;
            margin: 0;
            /* Chrome, Safari, Edge, Opera */
            /* Firefox */ }
            #goods-receipts-edit > .content .detail-form-container form table.simple tbody tr td.quantity md-input-container .md-errors-spacer, #goods-receipts-edit > .content .detail-form-container form table.simple tbody tr td.unit-price md-input-container .md-errors-spacer, #goods-receipts-edit > .content .detail-form-container form table.simple tbody tr td.net-total md-input-container .md-errors-spacer {
              min-height: 6px; }
            #goods-receipts-edit > .content .detail-form-container form table.simple tbody tr td.quantity md-input-container input::-webkit-outer-spin-button,
            #goods-receipts-edit > .content .detail-form-container form table.simple tbody tr td.quantity md-input-container input::-webkit-inner-spin-button, #goods-receipts-edit > .content .detail-form-container form table.simple tbody tr td.unit-price md-input-container input::-webkit-outer-spin-button,
            #goods-receipts-edit > .content .detail-form-container form table.simple tbody tr td.unit-price md-input-container input::-webkit-inner-spin-button, #goods-receipts-edit > .content .detail-form-container form table.simple tbody tr td.net-total md-input-container input::-webkit-outer-spin-button,
            #goods-receipts-edit > .content .detail-form-container form table.simple tbody tr td.net-total md-input-container input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0; }
            #goods-receipts-edit > .content .detail-form-container form table.simple tbody tr td.quantity md-input-container input[type=number], #goods-receipts-edit > .content .detail-form-container form table.simple tbody tr td.unit-price md-input-container input[type=number], #goods-receipts-edit > .content .detail-form-container form table.simple tbody tr td.net-total md-input-container input[type=number] {
              -moz-appearance: textfield; }
            #goods-receipts-edit > .content .detail-form-container form table.simple tbody tr td.quantity md-input-container input, #goods-receipts-edit > .content .detail-form-container form table.simple tbody tr td.unit-price md-input-container input, #goods-receipts-edit > .content .detail-form-container form table.simple tbody tr td.net-total md-input-container input {
              text-align: end; }
        #goods-receipts-edit > .content .detail-form-container form table.simple tbody tr md-select {
          margin: 0 0 6px 0; }
        #goods-receipts-edit > .content .detail-form-container form table.simple tbody tr td.unit .md-select-value {
          width: 81px;
          max-width: 81px;
          min-width: 81px; }
        #goods-receipts-edit > .content .detail-form-container form table.simple tbody tr td.item .md-select-value {
          width: 300px;
          max-width: 300px;
          min-width: 300px; }
        #goods-receipts-edit > .content .detail-form-container form table.simple tbody tr td.actions {
          text-align: center; }
          #goods-receipts-edit > .content .detail-form-container form table.simple tbody tr td.actions .md-button {
            min-width: auto;
            min-height: auto;
            line-height: unset; }
            #goods-receipts-edit > .content .detail-form-container form table.simple tbody tr td.actions .md-button .icon-delete {
              font-size: 18px;
              width: 18px;
              height: 18px;
              min-width: 18px;
              min-height: 18px;
              line-height: 18px; }
        #goods-receipts-edit > .content .detail-form-container form table.simple tbody tr md-input-container {
          width: inherit;
          margin: 0; }
          #goods-receipts-edit > .content .detail-form-container form table.simple tbody tr md-input-container .md-errors-spacer {
            min-height: 6px; }
      #goods-receipts-edit > .content .detail-form-container form table.simple tbody tr:first-child td {
        padding-top: 15px !important; }
      #goods-receipts-edit > .content .detail-form-container form md-input-container.remarks {
        margin-bottom: 18px; }
      #goods-receipts-edit > .content .detail-form-container form md-input-container.supplier {
        margin-bottom: 0;
        margin-top: 0; }
      #goods-receipts-edit > .content .detail-form-container form md-input-container.city {
        margin-bottom: 0;
        margin-top: 0; }
      #goods-receipts-edit > .content .detail-form-container form md-input-container.date {
        margin-bottom: 18px; }
      #goods-receipts-edit > .content .detail-form-container form md-input-container.receipt-number {
        margin-top: 0px;
        margin-bottom: 0px; }
      #goods-receipts-edit > .content .detail-form-container form md-input-container.city {
        margin-top: 18px;
        margin-bottom: 0px; }
      #goods-receipts-edit > .content .detail-form-container form md-input-container.country {
        margin-top: 36px;
        margin-bottom: 0px; }
      #goods-receipts-edit > .content .detail-form-container form md-input-container.text-area {
        margin-top: 36px;
        margin-bottom: 0px; }
      #goods-receipts-edit > .content .detail-form-container form .header-col:last-child {
        margin-left: 15px; }

@media screen and (max-width: 600px) {
  #goods-receipts-edit > .header {
    padding: 24px 0; }
  #goods-receipts-edit > .content {
    padding: 0; }
    #goods-receipts-edit > .content .detail-form-container form .header-col:last-child {
      margin-left: 0 !important; } }

#goods-receipts-list {
  position: relative;
  height: auto;
  min-height: 100%; }
  #goods-receipts-list > .header {
    height: 90px;
    min-height: 90px;
    max-height: 90px; }
    #goods-receipts-list > .header .h1 md-icon {
      margin-right: 12px; }
    #goods-receipts-list > .header md-icon {
      color: #ffffff; }
    #goods-receipts-list > .header .records-count {
      margin: 6px 0 0 36px; }
    #goods-receipts-list > .header .search-input-wrapper {
      position: relative; }
      #goods-receipts-list > .header .search-input-wrapper label {
        padding-right: 4px;
        cursor: pointer; }
        #goods-receipts-list > .header .search-input-wrapper label md-icon {
          color: rgba(255, 255, 255, 0.8); }
      #goods-receipts-list > .header .search-input-wrapper md-input-container .md-errors-spacer {
        display: none; }
      #goods-receipts-list > .header .search-input-wrapper md-input-container input {
        border-bottom: 1px solid rgba(255, 255, 255, 0.8);
        color: rgba(255, 255, 255, 0.8);
        width: 240px; }
        #goods-receipts-list > .header .search-input-wrapper md-input-container input::placeholder {
          color: rgba(255, 255, 255, 0.8); }
        #goods-receipts-list > .header .search-input-wrapper md-input-container input:focus {
          border-bottom: 1px solid white;
          color: white; }
    #goods-receipts-list > .header.selected-bar {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 999;
      background-image: none;
      opacity: 1 !important; }
      #goods-receipts-list > .header.selected-bar .close-button-wrapper {
        width: 244px; }
  #goods-receipts-list > .content {
    position: absolute;
    top: 90px;
    bottom: 0;
    right: 0;
    left: 0; }
    #goods-receipts-list > .content .main {
      padding: 0px 24px 0 24px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
      #goods-receipts-list > .content .main .data-list {
        position: relative;
        padding-bottom: 0; }
        #goods-receipts-list > .content .main .data-list .md-subheader {
          box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
          border-bottom: .1px solid #dadada; }
          #goods-receipts-list > .content .main .data-list .md-subheader .md-subheader-inner {
            padding: 12px 16px; }
            #goods-receipts-list > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content {
              font-size: 12px;
              font-weight: 900; }
              #goods-receipts-list > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:last-child {
                width: 60px;
                text-align: center; }
              #goods-receipts-list > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:hover {
                cursor: pointer;
                color: #636363; }
        #goods-receipts-list > .content .main .data-list .list-item {
          border-bottom: .1px solid #dadada; }
          #goods-receipts-list > .content .main .data-list .list-item span {
            padding-right: 6px; }
          #goods-receipts-list > .content .main .data-list .list-item .edit-button {
            width: 60px;
            margin: 0; }
      #goods-receipts-list > .content .main .no-results {
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14); }

@media screen and (max-width: 960px) {
  #goods-receipts-list > .header .h1 {
    font-size: 1.8rem; }
  #goods-receipts-list > .header .records-count {
    font-size: 1.1rem; }
  #goods-receipts-list > .content .main {
    padding: 0; }
    #goods-receipts-list > .content .main .data-list {
      padding-top: 0; } }

#goods-receipts-add .sidenav.supplier-orders-sidenav, #goods-receipts-edit .sidenav.supplier-orders-sidenav {
  width: 320px;
  min-width: 320px;
  max-width: 320px;
  padding: 0; }
  #goods-receipts-add .sidenav.supplier-orders-sidenav._md-locked-open, #goods-receipts-edit .sidenav.supplier-orders-sidenav._md-locked-open {
    width: 320px;
    min-width: 320px;
    max-width: 320px;
    overflow: hidden; }
  #goods-receipts-add .sidenav.supplier-orders-sidenav .header, #goods-receipts-edit .sidenav.supplier-orders-sidenav .header {
    height: 136px;
    min-height: 136px;
    max-height: 136px;
    padding: 24px 24px;
    background-color: #078aca; }
    #goods-receipts-add .sidenav.supplier-orders-sidenav .header .title, #goods-receipts-edit .sidenav.supplier-orders-sidenav .header .title {
      font-size: 18px;
      color: #a6cee2;
      margin-left: 15px; }
    #goods-receipts-add .sidenav.supplier-orders-sidenav .header .status-icon md-icon, #goods-receipts-edit .sidenav.supplier-orders-sidenav .header .status-icon md-icon {
      color: #ffffff; }
    #goods-receipts-add .sidenav.supplier-orders-sidenav .header md-select, #goods-receipts-edit .sidenav.supplier-orders-sidenav .header md-select {
      margin-left: 66px; }
      #goods-receipts-add .sidenav.supplier-orders-sidenav .header md-select .md-select-value, #goods-receipts-edit .sidenav.supplier-orders-sidenav .header md-select .md-select-value {
        color: #ffffffb3;
        border-bottom-color: #ffffffb3; }
  #goods-receipts-add .sidenav.supplier-orders-sidenav .content, #goods-receipts-edit .sidenav.supplier-orders-sidenav .content {
    position: relative;
    background: #FFFFFF;
    padding: 24px 0; }
    #goods-receipts-add .sidenav.supplier-orders-sidenav .content ms-widget ms-widget-front .md-toolbar-tools, #goods-receipts-edit .sidenav.supplier-orders-sidenav .content ms-widget ms-widget-front .md-toolbar-tools {
      height: 48px;
      max-height: 48px;
      background-color: #073c56; }
    #goods-receipts-add .sidenav.supplier-orders-sidenav .content ms-widget ms-widget-front .mid-panel, #goods-receipts-edit .sidenav.supplier-orders-sidenav .content ms-widget ms-widget-front .mid-panel {
      padding: 15px 9px 3px 9px; }
      #goods-receipts-add .sidenav.supplier-orders-sidenav .content ms-widget ms-widget-front .mid-panel .secondary-text, #goods-receipts-edit .sidenav.supplier-orders-sidenav .content ms-widget ms-widget-front .mid-panel .secondary-text {
        width: 90px;
        text-align: right; }

@media screen and (max-width: 960px) {
  #goods-receipts-add .sidenav.supplier-orders-sidenav, #goods-receipts-edit .sidenav.supplier-orders-sidenav {
    width: 240px;
    min-width: 240px;
    max-width: 240px;
    font-size: 11px; }
    #goods-receipts-add .sidenav.supplier-orders-sidenav h3, #goods-receipts-add .sidenav.supplier-orders-sidenav h4, #goods-receipts-add .sidenav.supplier-orders-sidenav p, #goods-receipts-edit .sidenav.supplier-orders-sidenav h3, #goods-receipts-edit .sidenav.supplier-orders-sidenav h4, #goods-receipts-edit .sidenav.supplier-orders-sidenav p {
      font-size: 11px !important; } }

md-dialog.item-dialog {
  max-width: 600px;
  width: 600px; }
  md-dialog.item-dialog md-toolbar {
    height: 60px;
    min-height: 60px;
    max-height: 60px; }
    md-dialog.item-dialog md-toolbar .title {
      font-size: 17px; }
  md-dialog.item-dialog md-dialog-content {
    display: block;
    position: relative; }
    md-dialog.item-dialog md-dialog-content md-input-container {
      height: 58px;
      max-height: 58px;
      min-height: 58px; }
  md-dialog.item-dialog md-dialog-actions {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.12); }

md-dialog.item-group-dialog {
  max-width: 600px;
  width: 600px; }
  md-dialog.item-group-dialog md-toolbar {
    height: 60px;
    min-height: 60px;
    max-height: 60px; }
    md-dialog.item-group-dialog md-toolbar .title {
      font-size: 17px; }
  md-dialog.item-group-dialog md-dialog-content {
    display: block;
    position: relative; }
    md-dialog.item-group-dialog md-dialog-content md-input-container {
      height: 58px;
      max-height: 58px;
      min-height: 58px; }
  md-dialog.item-group-dialog md-dialog-actions {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.12); }

md-dialog.inventory-split-request-dialog {
  max-width: 720px;
  width: 720px; }
  md-dialog.inventory-split-request-dialog md-toolbar {
    height: 60px;
    min-height: 60px;
    max-height: 60px; }
    md-dialog.inventory-split-request-dialog md-toolbar .title {
      font-size: 17px; }
  md-dialog.inventory-split-request-dialog md-dialog-content {
    display: block;
    position: relative;
    height: 400px;
    max-height: 400px;
    min-height: 400px;
    font-size: 15px; }
    md-dialog.inventory-split-request-dialog md-dialog-content table thead tr th {
      padding: 6px 18px 6px 0px;
      font-weight: 800;
      border-bottom: 1px solid #7d7d7d; }
    md-dialog.inventory-split-request-dialog md-dialog-content table thead tr th:first-child {
      padding-left: 0; }
    md-dialog.inventory-split-request-dialog md-dialog-content table thead tr th:last-child {
      padding-right: 0; }
    md-dialog.inventory-split-request-dialog md-dialog-content table thead tr th.quantity {
      text-align: right;
      padding-right: 30px; }
    md-dialog.inventory-split-request-dialog md-dialog-content table tbody tr td {
      padding: 0 5px 0 0;
      border-bottom: none; }
    md-dialog.inventory-split-request-dialog md-dialog-content table tbody tr td.quantity {
      width: 120px;
      max-width: 120px;
      min-width: 120px;
      text-align: right; }
      md-dialog.inventory-split-request-dialog md-dialog-content table tbody tr td.quantity md-input-container {
        width: inherit; }
        md-dialog.inventory-split-request-dialog md-dialog-content table tbody tr td.quantity md-input-container input {
          text-align: right; }
    md-dialog.inventory-split-request-dialog md-dialog-content table tbody tr td.unit {
      width: 120px;
      max-width: 120px;
      min-width: 120px; }
      md-dialog.inventory-split-request-dialog md-dialog-content table tbody tr td.unit md-input-container {
        width: inherit; }
    md-dialog.inventory-split-request-dialog md-dialog-content table tbody tr td.item {
      width: 270px;
      max-width: 270px;
      min-width: 270px; }
      md-dialog.inventory-split-request-dialog md-dialog-content table tbody tr td.item md-input-container {
        width: inherit; }
    md-dialog.inventory-split-request-dialog md-dialog-content table tbody tr td.split-quantity {
      width: 180px;
      max-width: 180px;
      min-width: 180px; }
      md-dialog.inventory-split-request-dialog md-dialog-content table tbody tr td.split-quantity md-input-container {
        width: 150px;
        max-width: 150px;
        min-width: 150px;
        /* Chrome, Safari, Edge, Opera */
        /* Firefox */ }
        md-dialog.inventory-split-request-dialog md-dialog-content table tbody tr td.split-quantity md-input-container input::-webkit-outer-spin-button,
        md-dialog.inventory-split-request-dialog md-dialog-content table tbody tr td.split-quantity md-input-container input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0; }
        md-dialog.inventory-split-request-dialog md-dialog-content table tbody tr td.split-quantity md-input-container input[type=number] {
          -moz-appearance: textfield; }
        md-dialog.inventory-split-request-dialog md-dialog-content table tbody tr td.split-quantity md-input-container input {
          text-align: end;
          border: none;
          background-color: #f7f7f7; }
    md-dialog.inventory-split-request-dialog md-dialog-content table tbody tr td:first-child {
      padding-left: 0; }
    md-dialog.inventory-split-request-dialog md-dialog-content table tbody tr:first-child td {
      padding-top: 20px; }
    md-dialog.inventory-split-request-dialog md-dialog-content md-input-container {
      height: 40px;
      max-height: 40px;
      min-height: 40px;
      margin: 0;
      padding: 0 27px 0 0; }
  md-dialog.inventory-split-request-dialog md-dialog-actions {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.12); }

md-dialog.unit-code-dialog {
  max-width: 600px;
  width: 600px; }
  md-dialog.unit-code-dialog md-toolbar {
    height: 60px;
    min-height: 60px;
    max-height: 60px; }
    md-dialog.unit-code-dialog md-toolbar .title {
      font-size: 17px; }
  md-dialog.unit-code-dialog md-dialog-content {
    display: block;
    position: relative; }
    md-dialog.unit-code-dialog md-dialog-content md-input-container {
      height: 58px;
      max-height: 58px;
      min-height: 58px; }
  md-dialog.unit-code-dialog md-dialog-actions {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.12); }

md-dialog.request-dialog {
  max-width: 1200px;
  width: 1200px; }
  md-dialog.request-dialog md-toolbar {
    height: 60px;
    min-height: 60px;
    max-height: 60px; }
    md-dialog.request-dialog md-toolbar .title {
      font-size: 17px; }
  md-dialog.request-dialog md-dialog-content {
    display: block;
    position: relative;
    height: 400px;
    max-height: 400px;
    min-height: 400px; }
    md-dialog.request-dialog md-dialog-content table {
      margin-top: 0px;
      font-size: 12px; }
      md-dialog.request-dialog md-dialog-content table thead tr th {
        font-weight: 600;
        padding: 3px 21px 0 12px; }
        md-dialog.request-dialog md-dialog-content table thead tr th:first-child {
          padding-left: 8px; }
        md-dialog.request-dialog md-dialog-content table thead tr th:last-child {
          padding-right: 8px;
          width: 60px;
          max-width: 60px;
          min-width: 60px; }
      md-dialog.request-dialog md-dialog-content table tbody tr:first-child td {
        padding-top: 30px; }
      md-dialog.request-dialog md-dialog-content table tbody tr td {
        padding: 6px 6px 6px 12px;
        border-bottom: none; }
        md-dialog.request-dialog md-dialog-content table tbody tr td:first-child {
          padding-left: 8px; }
        md-dialog.request-dialog md-dialog-content table tbody tr td:last-child {
          padding-right: 8px;
          width: 60px;
          max-width: 60px;
          min-width: 60px; }
        md-dialog.request-dialog md-dialog-content table tbody tr td.quantity {
          width: 120px;
          max-width: 120px;
          min-width: 120px; }
          md-dialog.request-dialog md-dialog-content table tbody tr td.quantity md-input-container {
            width: 90px;
            max-width: 90px;
            min-width: 90px;
            /* Chrome, Safari, Edge, Opera */
            /* Firefox */ }
            md-dialog.request-dialog md-dialog-content table tbody tr td.quantity md-input-container input::-webkit-outer-spin-button,
            md-dialog.request-dialog md-dialog-content table tbody tr td.quantity md-input-container input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0; }
            md-dialog.request-dialog md-dialog-content table tbody tr td.quantity md-input-container input[type=number] {
              -moz-appearance: textfield; }
            md-dialog.request-dialog md-dialog-content table tbody tr td.quantity md-input-container input {
              text-align: end; }
        md-dialog.request-dialog md-dialog-content table tbody tr td.unit {
          width: 90px;
          max-width: 90px;
          min-width: 90px; }
          md-dialog.request-dialog md-dialog-content table tbody tr td.unit md-input-container {
            width: 90px;
            max-width: 90px;
            min-width: 90px; }
        md-dialog.request-dialog md-dialog-content table tbody tr td.desc {
          width: 180px;
          max-width: 180px;
          min-width: 180px; }
          md-dialog.request-dialog md-dialog-content table tbody tr td.desc md-input-container {
            width: 180px;
            max-width: 180px;
            min-width: 180px; }
        md-dialog.request-dialog md-dialog-content table tbody tr td .md-errors-spacer {
          display: none; }
        md-dialog.request-dialog md-dialog-content table tbody tr td md-input-container {
          height: 40px;
          max-height: 40px;
          min-height: 40px;
          margin: 0;
          padding: 0; }
        md-dialog.request-dialog md-dialog-content table tbody tr td md-autocomplete > md-autocomplete-wrap > md-input-container {
          margin-top: 0px; }
          md-dialog.request-dialog md-dialog-content table tbody tr td md-autocomplete > md-autocomplete-wrap > md-input-container > label {
            -webkit-transform: translate3d(1px, 28px, 0) scale(1) !important;
            transform: translate3d(1px, 28px, 0) scale(1) !important;
            color: #BDBDBD; }
          md-dialog.request-dialog md-dialog-content table tbody tr td md-autocomplete > md-autocomplete-wrap > md-input-container.md-input-has-value > label {
            display: none !important; }
  md-dialog.request-dialog md-dialog-actions {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.12); }

#my-completed-requests {
  position: relative;
  height: auto;
  min-height: 100%; }
  #my-completed-requests > .header {
    height: 90px;
    min-height: 90px;
    max-height: 90px; }
    #my-completed-requests > .header .h1 md-icon {
      margin-right: 12px; }
    #my-completed-requests > .header md-icon {
      color: #ffffff; }
    #my-completed-requests > .header .records-count {
      margin: 6px 0 0 36px; }
    #my-completed-requests > .header .search-input-wrapper {
      position: relative; }
      #my-completed-requests > .header .search-input-wrapper label {
        padding-right: 4px;
        cursor: pointer; }
        #my-completed-requests > .header .search-input-wrapper label md-icon {
          color: rgba(255, 255, 255, 0.8); }
      #my-completed-requests > .header .search-input-wrapper md-input-container .md-errors-spacer {
        display: none; }
      #my-completed-requests > .header .search-input-wrapper md-input-container input {
        border-bottom: 1px solid rgba(255, 255, 255, 0.8);
        color: rgba(255, 255, 255, 0.8);
        width: 240px; }
        #my-completed-requests > .header .search-input-wrapper md-input-container input::placeholder {
          color: rgba(255, 255, 255, 0.8); }
        #my-completed-requests > .header .search-input-wrapper md-input-container input:focus {
          border-bottom: 1px solid white;
          color: white; }
  #my-completed-requests > .content {
    position: absolute;
    top: 90px;
    bottom: 0;
    right: 0;
    left: 0; }
    #my-completed-requests > .content .main {
      padding: 0px 24px 0 24px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
      #my-completed-requests > .content .main .data-list {
        position: relative;
        padding-bottom: 0; }
        #my-completed-requests > .content .main .data-list .md-subheader {
          box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
          border-bottom: .1px solid #dadada; }
          #my-completed-requests > .content .main .data-list .md-subheader .md-subheader-inner {
            padding: 12px 16px; }
            #my-completed-requests > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content {
              font-size: 12px;
              font-weight: 900; }
              #my-completed-requests > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:last-child {
                width: 60px;
                text-align: center; }
              #my-completed-requests > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:hover {
                cursor: pointer;
                color: #636363; }
        #my-completed-requests > .content .main .data-list .list-item {
          border-bottom: .1px solid #dadada; }
          #my-completed-requests > .content .main .data-list .list-item span {
            padding-right: 6px; }
          #my-completed-requests > .content .main .data-list .list-item .edit-button {
            width: 60px;
            margin: 0; }
      #my-completed-requests > .content .main .no-results {
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14); }

@media screen and (max-width: 960px) {
  #my-completed-requests > .header .h1 {
    font-size: 1.8rem; }
  #my-completed-requests > .header .records-count {
    font-size: 1.1rem; }
  #my-completed-requests > .content .main {
    padding: 0; }
    #my-completed-requests > .content .main .data-list {
      padding-top: 0; } }

#my-ongoing-requests {
  position: relative;
  height: auto;
  min-height: 100%; }
  #my-ongoing-requests > .header {
    height: 90px;
    min-height: 90px;
    max-height: 90px; }
    #my-ongoing-requests > .header .h1 md-icon {
      margin-right: 12px; }
    #my-ongoing-requests > .header md-icon {
      color: #ffffff; }
    #my-ongoing-requests > .header .records-count {
      margin: 6px 0 0 36px; }
    #my-ongoing-requests > .header .search-input-wrapper {
      position: relative; }
      #my-ongoing-requests > .header .search-input-wrapper label {
        padding-right: 4px;
        cursor: pointer; }
        #my-ongoing-requests > .header .search-input-wrapper label md-icon {
          color: rgba(255, 255, 255, 0.8); }
      #my-ongoing-requests > .header .search-input-wrapper md-input-container .md-errors-spacer {
        display: none; }
      #my-ongoing-requests > .header .search-input-wrapper md-input-container input {
        border-bottom: 1px solid rgba(255, 255, 255, 0.8);
        color: rgba(255, 255, 255, 0.8);
        width: 240px; }
        #my-ongoing-requests > .header .search-input-wrapper md-input-container input::placeholder {
          color: rgba(255, 255, 255, 0.8); }
        #my-ongoing-requests > .header .search-input-wrapper md-input-container input:focus {
          border-bottom: 1px solid white;
          color: white; }
  #my-ongoing-requests > .content {
    position: absolute;
    top: 90px;
    bottom: 0;
    right: 0;
    left: 0; }
    #my-ongoing-requests > .content .main {
      padding: 0px 24px 0 24px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
      #my-ongoing-requests > .content .main .data-list {
        position: relative;
        padding-bottom: 0; }
        #my-ongoing-requests > .content .main .data-list .md-subheader {
          box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
          border-bottom: .1px solid #dadada; }
          #my-ongoing-requests > .content .main .data-list .md-subheader .md-subheader-inner {
            padding: 12px 16px; }
            #my-ongoing-requests > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content {
              font-size: 12px;
              font-weight: 900; }
              #my-ongoing-requests > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:last-child {
                width: 60px;
                text-align: center; }
              #my-ongoing-requests > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:hover {
                cursor: pointer;
                color: #636363; }
        #my-ongoing-requests > .content .main .data-list .list-item {
          border-bottom: .1px solid #dadada; }
          #my-ongoing-requests > .content .main .data-list .list-item span {
            padding-right: 6px; }
          #my-ongoing-requests > .content .main .data-list .list-item .edit-button {
            width: 60px;
            margin: 0; }
      #my-ongoing-requests > .content .main .no-results {
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14); }

@media screen and (max-width: 960px) {
  #my-ongoing-requests > .header .h1 {
    font-size: 1.8rem; }
  #my-ongoing-requests > .header .records-count {
    font-size: 1.1rem; }
  #my-ongoing-requests > .content .main {
    padding: 0; }
    #my-ongoing-requests > .content .main .data-list {
      padding-top: 0; } }

#completed-purchase-approvals {
  position: relative;
  height: auto;
  min-height: 100%; }
  #completed-purchase-approvals > .header {
    height: 90px;
    min-height: 90px;
    max-height: 90px; }
    #completed-purchase-approvals > .header .h1 md-icon {
      margin-right: 12px; }
    #completed-purchase-approvals > .header md-icon {
      color: #ffffff; }
    #completed-purchase-approvals > .header .records-count {
      margin: 6px 0 0 36px; }
    #completed-purchase-approvals > .header .search-input-wrapper {
      position: relative; }
      #completed-purchase-approvals > .header .search-input-wrapper label {
        padding-right: 4px;
        cursor: pointer; }
        #completed-purchase-approvals > .header .search-input-wrapper label md-icon {
          color: rgba(255, 255, 255, 0.8); }
      #completed-purchase-approvals > .header .search-input-wrapper md-input-container .md-errors-spacer {
        display: none; }
      #completed-purchase-approvals > .header .search-input-wrapper md-input-container input {
        border-bottom: 1px solid rgba(255, 255, 255, 0.8);
        color: rgba(255, 255, 255, 0.8);
        width: 240px; }
        #completed-purchase-approvals > .header .search-input-wrapper md-input-container input::placeholder {
          color: rgba(255, 255, 255, 0.8); }
        #completed-purchase-approvals > .header .search-input-wrapper md-input-container input:focus {
          border-bottom: 1px solid white;
          color: white; }
  #completed-purchase-approvals > .content {
    position: absolute;
    top: 90px;
    bottom: 0;
    right: 0;
    left: 0; }
    #completed-purchase-approvals > .content .main {
      padding: 0px 24px 0 24px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
      #completed-purchase-approvals > .content .main .data-list {
        position: relative;
        padding-bottom: 0; }
        #completed-purchase-approvals > .content .main .data-list .md-subheader {
          box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
          border-bottom: .1px solid #dadada; }
          #completed-purchase-approvals > .content .main .data-list .md-subheader .md-subheader-inner {
            padding: 12px 16px; }
            #completed-purchase-approvals > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content {
              font-size: 12px;
              font-weight: 900; }
              #completed-purchase-approvals > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:last-child {
                width: 60px;
                text-align: center; }
              #completed-purchase-approvals > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:hover {
                cursor: pointer;
                color: #636363; }
        #completed-purchase-approvals > .content .main .data-list .list-item {
          border-bottom: .1px solid #dadada; }
          #completed-purchase-approvals > .content .main .data-list .list-item span {
            padding-right: 6px; }
          #completed-purchase-approvals > .content .main .data-list .list-item .edit-button {
            width: 60px;
            margin: 0; }
      #completed-purchase-approvals > .content .main .no-results {
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14); }

@media screen and (max-width: 960px) {
  #completed-purchase-approvals > .header .h1 {
    font-size: 1.8rem; }
  #completed-purchase-approvals > .header .records-count {
    font-size: 1.1rem; }
  #completed-purchase-approvals > .content .main {
    padding: 0; }
    #completed-purchase-approvals > .content .main .data-list {
      padding-top: 0; } }

#pending-purchase-approvals {
  position: relative;
  height: auto;
  min-height: 100%; }
  #pending-purchase-approvals > .header {
    height: 90px;
    min-height: 90px;
    max-height: 90px; }
    #pending-purchase-approvals > .header .h1 md-icon {
      margin-right: 12px; }
    #pending-purchase-approvals > .header md-icon {
      color: #ffffff; }
    #pending-purchase-approvals > .header .records-count {
      margin: 6px 0 0 36px; }
    #pending-purchase-approvals > .header .search-input-wrapper {
      position: relative; }
      #pending-purchase-approvals > .header .search-input-wrapper label {
        padding-right: 4px;
        cursor: pointer; }
        #pending-purchase-approvals > .header .search-input-wrapper label md-icon {
          color: rgba(255, 255, 255, 0.8); }
      #pending-purchase-approvals > .header .search-input-wrapper md-input-container .md-errors-spacer {
        display: none; }
      #pending-purchase-approvals > .header .search-input-wrapper md-input-container input {
        border-bottom: 1px solid rgba(255, 255, 255, 0.8);
        color: rgba(255, 255, 255, 0.8);
        width: 240px; }
        #pending-purchase-approvals > .header .search-input-wrapper md-input-container input::placeholder {
          color: rgba(255, 255, 255, 0.8); }
        #pending-purchase-approvals > .header .search-input-wrapper md-input-container input:focus {
          border-bottom: 1px solid white;
          color: white; }
    #pending-purchase-approvals > .header.selected-bar {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 999;
      background-image: none;
      opacity: 1 !important; }
      #pending-purchase-approvals > .header.selected-bar .close-button-wrapper {
        width: 244px; }
  #pending-purchase-approvals > .content {
    position: absolute;
    top: 90px;
    bottom: 0;
    right: 0;
    left: 0; }
    #pending-purchase-approvals > .content .main {
      padding: 0px 24px 0 24px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
      #pending-purchase-approvals > .content .main .data-list {
        position: relative;
        padding-bottom: 0; }
        #pending-purchase-approvals > .content .main .data-list > .toolbar {
          padding: 0 12px 0 20px;
          position: absolute;
          width: 100%;
          z-index: 3;
          background-color: #ffffff; }
          #pending-purchase-approvals > .content .main .data-list > .toolbar .action-buttons .md-icon-button:hover {
            background-color: #efefef; }
        #pending-purchase-approvals > .content .main .data-list .md-subheader {
          box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
          border-bottom: .1px solid #dadada; }
          #pending-purchase-approvals > .content .main .data-list .md-subheader .md-subheader-inner {
            padding: 12px 16px; }
            #pending-purchase-approvals > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content {
              font-size: 12px;
              font-weight: 900; }
              #pending-purchase-approvals > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:first-child {
                width: 60px;
                text-align: center; }
              #pending-purchase-approvals > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:last-child {
                width: 60px;
                text-align: center; }
              #pending-purchase-approvals > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:hover {
                cursor: pointer;
                color: #636363; }
        #pending-purchase-approvals > .content .main .data-list .list-item {
          border-bottom: .1px solid #dadada; }
          #pending-purchase-approvals > .content .main .data-list .list-item:hover {
            cursor: initial; }
          #pending-purchase-approvals > .content .main .data-list .list-item span {
            padding-right: 6px; }
          #pending-purchase-approvals > .content .main .data-list .list-item .edit-button {
            width: 60px;
            margin: 0; }
      #pending-purchase-approvals > .content .main .no-results {
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14); }

@media screen and (max-width: 960px) {
  #pending-purchase-approvals > .header .h1 {
    font-size: 1.8rem; }
  #pending-purchase-approvals > .header .records-count {
    font-size: 1.1rem; }
  #pending-purchase-approvals > .content .main {
    padding: 0; }
    #pending-purchase-approvals > .content .main .data-list {
      padding-top: 0; } }

#purchase-invoices-add > .header {
  height: 120px;
  min-height: 120px;
  max-height: 120px; }
  #purchase-invoices-add > .header .goto-purchase-invoices-button {
    margin-right: 16px; }
  #purchase-invoices-add > .header .subtitle {
    margin: 6px 0 0 0; }
  #purchase-invoices-add > .header .md-icon-button:hover {
    background-color: #f2f2f233; }
  #purchase-invoices-add > .header .md-icon-button md-icon {
    color: #fafafa; }
  #purchase-invoices-add > .header .md-icon-button[disabled] md-icon {
    color: #fafafa69; }

#purchase-invoices-add > .content {
  padding: 24px; }
  #purchase-invoices-add > .content .detail-form-container {
    padding: 24px;
    margin-bottom: 24px;
    max-width: 990px; }
    #purchase-invoices-add > .content .detail-form-container form {
      font-size: 12px !important; }
      #purchase-invoices-add > .content .detail-form-container form table.simple thead tr th {
        padding: 0 5px 0 5px; }
      #purchase-invoices-add > .content .detail-form-container form table.simple thead tr th.unit-price, #purchase-invoices-add > .content .detail-form-container form table.simple thead tr th.quantity, #purchase-invoices-add > .content .detail-form-container form table.simple thead tr th.net-total {
        text-align: right;
        padding-right: 9px; }
      #purchase-invoices-add > .content .detail-form-container form table.simple thead tr th.actions {
        text-align: center; }
      #purchase-invoices-add > .content .detail-form-container form table.simple tbody tr {
        padding-top: 0;
        padding-bottom: 0; }
        #purchase-invoices-add > .content .detail-form-container form table.simple tbody tr td {
          padding: 0 5px 0 5px;
          border-bottom: none; }
        #purchase-invoices-add > .content .detail-form-container form table.simple tbody tr td.quantity, #purchase-invoices-add > .content .detail-form-container form table.simple tbody tr td.unit-price, #purchase-invoices-add > .content .detail-form-container form table.simple tbody tr td.net-total {
          width: 81px;
          max-width: 81px;
          min-width: 81px;
          text-align: right; }
          #purchase-invoices-add > .content .detail-form-container form table.simple tbody tr td.quantity md-input-container, #purchase-invoices-add > .content .detail-form-container form table.simple tbody tr td.unit-price md-input-container, #purchase-invoices-add > .content .detail-form-container form table.simple tbody tr td.net-total md-input-container {
            width: inherit;
            margin: 0;
            /* Chrome, Safari, Edge, Opera */
            /* Firefox */ }
            #purchase-invoices-add > .content .detail-form-container form table.simple tbody tr td.quantity md-input-container .md-errors-spacer, #purchase-invoices-add > .content .detail-form-container form table.simple tbody tr td.unit-price md-input-container .md-errors-spacer, #purchase-invoices-add > .content .detail-form-container form table.simple tbody tr td.net-total md-input-container .md-errors-spacer {
              min-height: 6px; }
            #purchase-invoices-add > .content .detail-form-container form table.simple tbody tr td.quantity md-input-container input::-webkit-outer-spin-button,
            #purchase-invoices-add > .content .detail-form-container form table.simple tbody tr td.quantity md-input-container input::-webkit-inner-spin-button, #purchase-invoices-add > .content .detail-form-container form table.simple tbody tr td.unit-price md-input-container input::-webkit-outer-spin-button,
            #purchase-invoices-add > .content .detail-form-container form table.simple tbody tr td.unit-price md-input-container input::-webkit-inner-spin-button, #purchase-invoices-add > .content .detail-form-container form table.simple tbody tr td.net-total md-input-container input::-webkit-outer-spin-button,
            #purchase-invoices-add > .content .detail-form-container form table.simple tbody tr td.net-total md-input-container input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0; }
            #purchase-invoices-add > .content .detail-form-container form table.simple tbody tr td.quantity md-input-container input[type=number], #purchase-invoices-add > .content .detail-form-container form table.simple tbody tr td.unit-price md-input-container input[type=number], #purchase-invoices-add > .content .detail-form-container form table.simple tbody tr td.net-total md-input-container input[type=number] {
              -moz-appearance: textfield; }
            #purchase-invoices-add > .content .detail-form-container form table.simple tbody tr td.quantity md-input-container input, #purchase-invoices-add > .content .detail-form-container form table.simple tbody tr td.unit-price md-input-container input, #purchase-invoices-add > .content .detail-form-container form table.simple tbody tr td.net-total md-input-container input {
              text-align: end; }
        #purchase-invoices-add > .content .detail-form-container form table.simple tbody tr md-select {
          margin: 0 0 6px 0; }
        #purchase-invoices-add > .content .detail-form-container form table.simple tbody tr td.unit .md-select-value {
          width: 81px;
          max-width: 81px;
          min-width: 81px; }
        #purchase-invoices-add > .content .detail-form-container form table.simple tbody tr td.item .md-select-value {
          width: 300px;
          max-width: 300px;
          min-width: 300px; }
        #purchase-invoices-add > .content .detail-form-container form table.simple tbody tr td.actions {
          text-align: center; }
          #purchase-invoices-add > .content .detail-form-container form table.simple tbody tr td.actions .md-button {
            min-width: auto;
            min-height: auto;
            line-height: unset; }
            #purchase-invoices-add > .content .detail-form-container form table.simple tbody tr td.actions .md-button .icon-delete {
              font-size: 18px;
              width: 18px;
              height: 18px;
              min-width: 18px;
              min-height: 18px;
              line-height: 18px; }
        #purchase-invoices-add > .content .detail-form-container form table.simple tbody tr md-input-container {
          width: inherit;
          margin: 0; }
          #purchase-invoices-add > .content .detail-form-container form table.simple tbody tr md-input-container .md-errors-spacer {
            min-height: 6px; }
      #purchase-invoices-add > .content .detail-form-container form table.simple tbody tr:first-child td {
        padding-top: 15px !important; }
      #purchase-invoices-add > .content .detail-form-container form md-input-container.remarks {
        margin-bottom: 18px; }
      #purchase-invoices-add > .content .detail-form-container form md-input-container.supplier {
        margin-bottom: 0;
        margin-top: 0; }
      #purchase-invoices-add > .content .detail-form-container form md-input-container.city {
        margin-bottom: 0;
        margin-top: 0; }
      #purchase-invoices-add > .content .detail-form-container form md-input-container.date {
        margin-bottom: 18px; }
      #purchase-invoices-add > .content .detail-form-container form md-input-container.invoice-number {
        margin-top: 0px;
        margin-bottom: 0px; }
      #purchase-invoices-add > .content .detail-form-container form md-input-container.city {
        margin-top: 18px;
        margin-bottom: 0px; }
      #purchase-invoices-add > .content .detail-form-container form md-input-container.country {
        margin-top: 36px;
        margin-bottom: 0px; }
      #purchase-invoices-add > .content .detail-form-container form md-input-container.text-area {
        margin-top: 36px;
        margin-bottom: 0px; }
      #purchase-invoices-add > .content .detail-form-container form .header-col:last-child {
        margin-left: 15px; }

@media screen and (max-width: 600px) {
  #purchase-invoices-add > .header {
    padding: 24px 0; }
  #purchase-invoices-add > .content {
    padding: 0; }
    #purchase-invoices-add > .content .detail-form-container form .header-col:last-child {
      margin-left: 0 !important; } }

#purchase-invoices-add .sidenav.supplier-orders-sidenav, #purchase-invoices-edit .sidenav.supplier-orders-sidenav {
  width: 320px;
  min-width: 320px;
  max-width: 320px;
  padding: 0; }
  #purchase-invoices-add .sidenav.supplier-orders-sidenav._md-locked-open, #purchase-invoices-edit .sidenav.supplier-orders-sidenav._md-locked-open {
    width: 320px;
    min-width: 320px;
    max-width: 320px;
    overflow: hidden; }
  #purchase-invoices-add .sidenav.supplier-orders-sidenav .header, #purchase-invoices-edit .sidenav.supplier-orders-sidenav .header {
    height: 136px;
    min-height: 136px;
    max-height: 136px;
    padding: 24px 24px;
    background-color: #078aca; }
    #purchase-invoices-add .sidenav.supplier-orders-sidenav .header .title, #purchase-invoices-edit .sidenav.supplier-orders-sidenav .header .title {
      font-size: 18px;
      color: #a6cee2;
      margin-left: 15px; }
    #purchase-invoices-add .sidenav.supplier-orders-sidenav .header .status-icon md-icon, #purchase-invoices-edit .sidenav.supplier-orders-sidenav .header .status-icon md-icon {
      color: #ffffff; }
    #purchase-invoices-add .sidenav.supplier-orders-sidenav .header md-select, #purchase-invoices-edit .sidenav.supplier-orders-sidenav .header md-select {
      margin-left: 66px; }
      #purchase-invoices-add .sidenav.supplier-orders-sidenav .header md-select .md-select-value, #purchase-invoices-edit .sidenav.supplier-orders-sidenav .header md-select .md-select-value {
        color: #ffffffb3;
        border-bottom-color: #ffffffb3; }
  #purchase-invoices-add .sidenav.supplier-orders-sidenav .content, #purchase-invoices-edit .sidenav.supplier-orders-sidenav .content {
    position: relative;
    background: #FFFFFF;
    padding: 24px 0; }
    #purchase-invoices-add .sidenav.supplier-orders-sidenav .content ms-widget ms-widget-front .md-toolbar-tools, #purchase-invoices-edit .sidenav.supplier-orders-sidenav .content ms-widget ms-widget-front .md-toolbar-tools {
      height: 48px;
      max-height: 48px;
      background-color: #073c56; }
    #purchase-invoices-add .sidenav.supplier-orders-sidenav .content ms-widget ms-widget-front .mid-panel, #purchase-invoices-edit .sidenav.supplier-orders-sidenav .content ms-widget ms-widget-front .mid-panel {
      padding: 15px 9px; }
      #purchase-invoices-add .sidenav.supplier-orders-sidenav .content ms-widget ms-widget-front .mid-panel .secondary-text, #purchase-invoices-edit .sidenav.supplier-orders-sidenav .content ms-widget ms-widget-front .mid-panel .secondary-text {
        width: 90px;
        text-align: right; }

@media screen and (max-width: 960px) {
  #purchase-invoices-add .sidenav.supplier-orders-sidenav, #purchase-invoices-edit .sidenav.supplier-orders-sidenav {
    width: 240px;
    min-width: 240px;
    max-width: 240px;
    font-size: 11px; }
    #purchase-invoices-add .sidenav.supplier-orders-sidenav h3, #purchase-invoices-add .sidenav.supplier-orders-sidenav h4, #purchase-invoices-add .sidenav.supplier-orders-sidenav p, #purchase-invoices-edit .sidenav.supplier-orders-sidenav h3, #purchase-invoices-edit .sidenav.supplier-orders-sidenav h4, #purchase-invoices-edit .sidenav.supplier-orders-sidenav p {
      font-size: 11px !important; } }

#purchase-invoices-edit > .header {
  height: 120px;
  min-height: 120px;
  max-height: 120px; }
  #purchase-invoices-edit > .header .goto-purchase-invoices-button {
    margin-right: 16px; }
  #purchase-invoices-edit > .header .subtitle {
    margin: 6px 0 0 0; }
  #purchase-invoices-edit > .header .md-icon-button:hover {
    background-color: #f2f2f233; }
  #purchase-invoices-edit > .header .md-icon-button md-icon {
    color: #fafafa; }
  #purchase-invoices-edit > .header .md-icon-button[disabled] md-icon {
    color: #fafafa69; }

#purchase-invoices-edit > .content {
  padding: 24px; }
  #purchase-invoices-edit > .content .detail-form-container {
    padding: 24px;
    margin-bottom: 24px;
    max-width: 990px; }
    #purchase-invoices-edit > .content .detail-form-container form {
      font-size: 12px !important; }
      #purchase-invoices-edit > .content .detail-form-container form table.simple thead tr th {
        padding: 0 5px 0 5px; }
      #purchase-invoices-edit > .content .detail-form-container form table.simple thead tr th.unit-price, #purchase-invoices-edit > .content .detail-form-container form table.simple thead tr th.quantity, #purchase-invoices-edit > .content .detail-form-container form table.simple thead tr th.net-total {
        text-align: right;
        padding-right: 9px; }
      #purchase-invoices-edit > .content .detail-form-container form table.simple thead tr th.actions {
        text-align: center; }
      #purchase-invoices-edit > .content .detail-form-container form table.simple tbody tr {
        padding-top: 0;
        padding-bottom: 0; }
        #purchase-invoices-edit > .content .detail-form-container form table.simple tbody tr td {
          padding: 0 5px 0 5px;
          border-bottom: none; }
        #purchase-invoices-edit > .content .detail-form-container form table.simple tbody tr td.quantity, #purchase-invoices-edit > .content .detail-form-container form table.simple tbody tr td.unit-price, #purchase-invoices-edit > .content .detail-form-container form table.simple tbody tr td.net-total {
          width: 81px;
          max-width: 81px;
          min-width: 81px;
          text-align: right; }
          #purchase-invoices-edit > .content .detail-form-container form table.simple tbody tr td.quantity md-input-container, #purchase-invoices-edit > .content .detail-form-container form table.simple tbody tr td.unit-price md-input-container, #purchase-invoices-edit > .content .detail-form-container form table.simple tbody tr td.net-total md-input-container {
            width: inherit;
            margin: 0;
            /* Chrome, Safari, Edge, Opera */
            /* Firefox */ }
            #purchase-invoices-edit > .content .detail-form-container form table.simple tbody tr td.quantity md-input-container .md-errors-spacer, #purchase-invoices-edit > .content .detail-form-container form table.simple tbody tr td.unit-price md-input-container .md-errors-spacer, #purchase-invoices-edit > .content .detail-form-container form table.simple tbody tr td.net-total md-input-container .md-errors-spacer {
              min-height: 6px; }
            #purchase-invoices-edit > .content .detail-form-container form table.simple tbody tr td.quantity md-input-container input::-webkit-outer-spin-button,
            #purchase-invoices-edit > .content .detail-form-container form table.simple tbody tr td.quantity md-input-container input::-webkit-inner-spin-button, #purchase-invoices-edit > .content .detail-form-container form table.simple tbody tr td.unit-price md-input-container input::-webkit-outer-spin-button,
            #purchase-invoices-edit > .content .detail-form-container form table.simple tbody tr td.unit-price md-input-container input::-webkit-inner-spin-button, #purchase-invoices-edit > .content .detail-form-container form table.simple tbody tr td.net-total md-input-container input::-webkit-outer-spin-button,
            #purchase-invoices-edit > .content .detail-form-container form table.simple tbody tr td.net-total md-input-container input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0; }
            #purchase-invoices-edit > .content .detail-form-container form table.simple tbody tr td.quantity md-input-container input[type=number], #purchase-invoices-edit > .content .detail-form-container form table.simple tbody tr td.unit-price md-input-container input[type=number], #purchase-invoices-edit > .content .detail-form-container form table.simple tbody tr td.net-total md-input-container input[type=number] {
              -moz-appearance: textfield; }
            #purchase-invoices-edit > .content .detail-form-container form table.simple tbody tr td.quantity md-input-container input, #purchase-invoices-edit > .content .detail-form-container form table.simple tbody tr td.unit-price md-input-container input, #purchase-invoices-edit > .content .detail-form-container form table.simple tbody tr td.net-total md-input-container input {
              text-align: end; }
        #purchase-invoices-edit > .content .detail-form-container form table.simple tbody tr md-select {
          margin: 0 0 6px 0; }
        #purchase-invoices-edit > .content .detail-form-container form table.simple tbody tr td.unit .md-select-value {
          width: 81px;
          max-width: 81px;
          min-width: 81px; }
        #purchase-invoices-edit > .content .detail-form-container form table.simple tbody tr td.item .md-select-value {
          width: 300px;
          max-width: 300px;
          min-width: 300px; }
        #purchase-invoices-edit > .content .detail-form-container form table.simple tbody tr td.actions {
          text-align: center; }
          #purchase-invoices-edit > .content .detail-form-container form table.simple tbody tr td.actions .md-button {
            min-width: auto;
            min-height: auto;
            line-height: unset; }
            #purchase-invoices-edit > .content .detail-form-container form table.simple tbody tr td.actions .md-button .icon-delete {
              font-size: 18px;
              width: 18px;
              height: 18px;
              min-width: 18px;
              min-height: 18px;
              line-height: 18px; }
        #purchase-invoices-edit > .content .detail-form-container form table.simple tbody tr md-input-container {
          width: inherit;
          margin: 0; }
          #purchase-invoices-edit > .content .detail-form-container form table.simple tbody tr md-input-container .md-errors-spacer {
            min-height: 6px; }
      #purchase-invoices-edit > .content .detail-form-container form table.simple tbody tr:first-child td {
        padding-top: 15px !important; }
      #purchase-invoices-edit > .content .detail-form-container form md-input-container.remarks {
        margin-bottom: 18px; }
      #purchase-invoices-edit > .content .detail-form-container form md-input-container.supplier {
        margin-bottom: 0;
        margin-top: 0; }
      #purchase-invoices-edit > .content .detail-form-container form md-input-container.city {
        margin-bottom: 0;
        margin-top: 0; }
      #purchase-invoices-edit > .content .detail-form-container form md-input-container.date {
        margin-bottom: 18px; }
      #purchase-invoices-edit > .content .detail-form-container form md-input-container.invoice-number {
        margin-top: 0px;
        margin-bottom: 0px; }
      #purchase-invoices-edit > .content .detail-form-container form md-input-container.city {
        margin-top: 18px;
        margin-bottom: 0px; }
      #purchase-invoices-edit > .content .detail-form-container form md-input-container.country {
        margin-top: 36px;
        margin-bottom: 0px; }
      #purchase-invoices-edit > .content .detail-form-container form md-input-container.text-area {
        margin-top: 36px;
        margin-bottom: 0px; }
      #purchase-invoices-edit > .content .detail-form-container form .header-col:last-child {
        margin-left: 15px; }

@media screen and (max-width: 600px) {
  #purchase-invoices-edit > .header {
    padding: 24px 0; }
  #purchase-invoices-edit > .content {
    padding: 0; }
    #purchase-invoices-edit > .content .detail-form-container form .header-col:last-child {
      margin-left: 0 !important; } }

#purchase-invoices-list {
  position: relative;
  height: auto;
  min-height: 100%; }
  #purchase-invoices-list > .header {
    height: 90px;
    min-height: 90px;
    max-height: 90px; }
    #purchase-invoices-list > .header .h1 md-icon {
      margin-right: 12px; }
    #purchase-invoices-list > .header md-icon {
      color: #ffffff; }
    #purchase-invoices-list > .header .records-count {
      margin: 6px 0 0 36px; }
    #purchase-invoices-list > .header .search-input-wrapper {
      position: relative; }
      #purchase-invoices-list > .header .search-input-wrapper label {
        padding-right: 4px;
        cursor: pointer; }
        #purchase-invoices-list > .header .search-input-wrapper label md-icon {
          color: rgba(255, 255, 255, 0.8); }
      #purchase-invoices-list > .header .search-input-wrapper md-input-container .md-errors-spacer {
        display: none; }
      #purchase-invoices-list > .header .search-input-wrapper md-input-container input {
        border-bottom: 1px solid rgba(255, 255, 255, 0.8);
        color: rgba(255, 255, 255, 0.8);
        width: 240px; }
        #purchase-invoices-list > .header .search-input-wrapper md-input-container input::placeholder {
          color: rgba(255, 255, 255, 0.8); }
        #purchase-invoices-list > .header .search-input-wrapper md-input-container input:focus {
          border-bottom: 1px solid white;
          color: white; }
    #purchase-invoices-list > .header.selected-bar {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 999;
      background-image: none;
      opacity: 1 !important; }
      #purchase-invoices-list > .header.selected-bar .close-button-wrapper {
        width: 244px; }
  #purchase-invoices-list > .content {
    position: absolute;
    top: 90px;
    bottom: 0;
    right: 0;
    left: 0; }
    #purchase-invoices-list > .content .main {
      padding: 0px 24px 0 24px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
      #purchase-invoices-list > .content .main .data-list {
        position: relative;
        padding-bottom: 0; }
        #purchase-invoices-list > .content .main .data-list .md-subheader {
          box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
          border-bottom: .1px solid #dadada; }
          #purchase-invoices-list > .content .main .data-list .md-subheader .md-subheader-inner {
            padding: 12px 16px; }
            #purchase-invoices-list > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content {
              font-size: 12px;
              font-weight: 900; }
              #purchase-invoices-list > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:last-child {
                width: 60px;
                text-align: center; }
              #purchase-invoices-list > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:hover {
                cursor: pointer;
                color: #636363; }
        #purchase-invoices-list > .content .main .data-list .list-item {
          border-bottom: .1px solid #dadada; }
          #purchase-invoices-list > .content .main .data-list .list-item span {
            padding-right: 6px; }
          #purchase-invoices-list > .content .main .data-list .list-item .edit-button {
            width: 60px;
            margin: 0; }
      #purchase-invoices-list > .content .main .no-results {
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14); }

@media screen and (max-width: 960px) {
  #purchase-invoices-list > .header .h1 {
    font-size: 1.8rem; }
  #purchase-invoices-list > .header .records-count {
    font-size: 1.1rem; }
  #purchase-invoices-list > .content .main {
    padding: 0; }
    #purchase-invoices-list > .content .main .data-list {
      padding-top: 0; } }

#purchase-analysis-chart {
  padding: 0;
  min-height: 450px;
  height: 450px;
  background-color: #eeeeee; }
  #purchase-analysis-chart .btn-close {
    position: absolute;
    right: 0;
    z-index: 3;
    padding: 6px 12px; }
    #purchase-analysis-chart .btn-close .icon-window-close {
      color: #ffffff; }
    #purchase-analysis-chart .btn-close:hover {
      cursor: pointer; }
  #purchase-analysis-chart > .widget-group > .ms-widget {
    padding: 0; }
    #purchase-analysis-chart > .widget-group > .ms-widget .ms-widget-front md-tabs md-tabs-content-wrapper {
      padding: 0 6px; }
      #purchase-analysis-chart > .widget-group > .ms-widget .ms-widget-front md-tabs md-tabs-content-wrapper md-tab-content {
        height: 320px; }
        #purchase-analysis-chart > .widget-group > .ms-widget .ms-widget-front md-tabs md-tabs-content-wrapper md-tab-content .widget-group {
          height: 100%; }
          #purchase-analysis-chart > .widget-group > .ms-widget .ms-widget-front md-tabs md-tabs-content-wrapper md-tab-content .widget-group > .ms-widget .ms-widget-front .h3 {
            padding-bottom: 5px;
            padding-left: 5px; }

@media screen and (max-width: 600px) {
  #purchase-analysis-chart > .widget-group > .ms-widget .ms-widget-front md-tabs md-tabs-content-wrapper md-tab-content .widget-group > .ms-widget .ms-widget-front div {
    padding: 3px 0; }
    #purchase-analysis-chart > .widget-group > .ms-widget .ms-widget-front md-tabs md-tabs-content-wrapper md-tab-content .widget-group > .ms-widget .ms-widget-front div .h3 {
      font-size: 12px; }
    #purchase-analysis-chart > .widget-group > .ms-widget .ms-widget-front md-tabs md-tabs-content-wrapper md-tab-content .widget-group > .ms-widget .ms-widget-front div .count {
      font-size: 24px;
      line-height: 24px; }
    #purchase-analysis-chart > .widget-group > .ms-widget .ms-widget-front md-tabs md-tabs-content-wrapper md-tab-content .widget-group > .ms-widget .ms-widget-front div .unit {
      font-size: 1.2rem;
      padding-bottom: 1px; } }

md-dialog.import-supplier-dialog {
  max-width: 600px;
  width: 600px; }
  md-dialog.import-supplier-dialog md-toolbar {
    height: 60px;
    min-height: 60px;
    max-height: 60px; }
    md-dialog.import-supplier-dialog md-toolbar .title {
      font-size: 17px; }
  md-dialog.import-supplier-dialog md-dialog-content {
    display: block;
    position: relative; }
    md-dialog.import-supplier-dialog md-dialog-content .schema-row md-icon {
      color: #a7a6a6; }
  md-dialog.import-supplier-dialog md-dialog-actions {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.12); }

md-dialog.order-email-dialog {
  max-width: 720px;
  width: 720px; }
  md-dialog.order-email-dialog md-toolbar .title {
    font-size: 17px; }
  md-dialog.order-email-dialog md-dialog-content {
    display: block;
    position: relative; }
    md-dialog.order-email-dialog md-dialog-content .toLabel {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.54); }
    md-dialog.order-email-dialog md-dialog-content md-input-container {
      margin-bottom: 0px; }
    md-dialog.order-email-dialog md-dialog-content md-input-container.subject {
      margin-top: 36px;
      margin-bottom: 12px; }
    md-dialog.order-email-dialog md-dialog-content .md-errors-spacer {
      min-height: 9px;
      height: 9px; }
    md-dialog.order-email-dialog md-dialog-content .ta-scroll-window > .ta-bind {
      height: auto;
      min-height: 240px;
      padding: 6px 12px; }
    md-dialog.order-email-dialog md-dialog-content .ta-editor.ta-html, md-dialog.order-email-dialog md-dialog-content .ta-scroll-window.form-control {
      min-height: 240px;
      height: auto;
      overflow: auto;
      font-family: inherit;
      font-size: 100%; }
      md-dialog.order-email-dialog md-dialog-content .ta-editor.ta-html i, md-dialog.order-email-dialog md-dialog-content .ta-scroll-window.form-control i {
        font-size: inherit;
        width: inherit;
        height: inherit;
        min-width: inherit;
        min-height: inherit;
        line-height: inherit; }
    md-dialog.order-email-dialog md-dialog-content .to {
      position: relative; }
      md-dialog.order-email-dialog md-dialog-content .to.hidden-cc input {
        padding-right: 32px; }
      md-dialog.order-email-dialog md-dialog-content .to.hidden-cc.hidden-bcc input {
        padding-right: 56px; }
      md-dialog.order-email-dialog md-dialog-content .to.hidden-bcc input {
        padding-right: 32px; }
      md-dialog.order-email-dialog md-dialog-content .to .cc-bcc {
        position: absolute;
        bottom: 8px;
        right: 0; }
        md-dialog.order-email-dialog md-dialog-content .to .cc-bcc .show-cc,
        md-dialog.order-email-dialog md-dialog-content .to .cc-bcc .show-bcc {
          cursor: pointer;
          font-size: 12px; }
        md-dialog.order-email-dialog md-dialog-content .to .cc-bcc .show-bcc {
          margin-left: 8px; }
  md-dialog.order-email-dialog md-dialog-actions {
    position: relative;
    justify-content: space-between;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    background-color: rgba(0, 0, 0, 0.03); }

#poEmailContactChips md-content.autocomplete {
  min-height: 250px; }
  #poEmailContactChips md-content.autocomplete input {
    min-width: 400px; }

#poEmailContactChips .md-item-text.compact {
  padding-top: 8px;
  padding-bottom: 8px; }

#poEmailContactChips .contact-item {
  box-sizing: border-box; }
  #poEmailContactChips .contact-item.selected {
    background-color: #E3ECF5; }
    #poEmailContactChips .contact-item.selected p {
      color: rgba(0, 0, 0, 0.87);
      font-weight: 400; }
  #poEmailContactChips .contact-item .md-list-item-text {
    padding: 14px 0;
    max-width: 190px; }
    #poEmailContactChips .contact-item .md-list-item-text h3 {
      margin: 0 !important;
      padding: 0;
      line-height: 1.2em !important;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden; }
    #poEmailContactChips .contact-item .md-list-item-text p {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden; }

#poEmailContactChips md-contact-chips {
  margin-bottom: 10px; }

#poEmailContactChips .md-chips {
  padding: 5px 0 8px; }
  #poEmailContactChips .md-chips .md-chip-input-container {
    width: 100%; }

@media screen and (max-width: 960px) {
  md-dialog.order-email-dialog {
    width: 80%; } }

@media screen and (max-width: 600px) {
  md-dialog.order-email-dialog {
    width: 90%; } }

@media (min-width: 960px) {
  #poEmailContactChips .contact-item {
    float: left;
    width: 33%; } }

md-dialog.quotation-edit-dialog {
  max-width: 1200px;
  width: 1200px; }
  md-dialog.quotation-edit-dialog md-toolbar {
    height: 60px;
    min-height: 60px;
    max-height: 60px; }
    md-dialog.quotation-edit-dialog md-toolbar .title {
      font-size: 17px; }
  md-dialog.quotation-edit-dialog md-dialog-content {
    display: block;
    position: relative;
    height: 400px;
    max-height: 400px;
    min-height: 400px;
    font-size: 15px; }
    md-dialog.quotation-edit-dialog md-dialog-content input::-webkit-outer-spin-button,
    md-dialog.quotation-edit-dialog md-dialog-content input::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0;
      /* <-- Apparently some margin are still there even though it's hidden */ }
    md-dialog.quotation-edit-dialog md-dialog-content table {
      font-size: 12px; }
      md-dialog.quotation-edit-dialog md-dialog-content table thead tr th {
        padding: 6px 18px 6px 0px;
        font-weight: 800;
        border-bottom: none;
        background-color: #eaeaea;
        min-width: unset; }
      md-dialog.quotation-edit-dialog md-dialog-content table thead tr th:first-child {
        padding-left: 0; }
      md-dialog.quotation-edit-dialog md-dialog-content table thead tr th:last-child {
        padding-right: 0; }
      md-dialog.quotation-edit-dialog md-dialog-content table thead tr th.quantity {
        text-align: right;
        padding-right: 36px; }
      md-dialog.quotation-edit-dialog md-dialog-content table thead tr th.unit-price, md-dialog.quotation-edit-dialog md-dialog-content table thead tr th.net-total, md-dialog.quotation-edit-dialog md-dialog-content table thead tr th.discount, md-dialog.quotation-edit-dialog md-dialog-content table thead tr th.discount2 {
        text-align: right;
        padding-right: 15px; }
      md-dialog.quotation-edit-dialog md-dialog-content table tbody tr {
        padding-top: 0;
        padding-bottom: 0; }
        md-dialog.quotation-edit-dialog md-dialog-content table tbody tr td {
          padding: 0 5px 0 0;
          border-bottom: none; }
        md-dialog.quotation-edit-dialog md-dialog-content table tbody tr td:first-child {
          padding-left: 0; }
        md-dialog.quotation-edit-dialog md-dialog-content table tbody tr td:last-child {
          padding-right: 0; }
        md-dialog.quotation-edit-dialog md-dialog-content table tbody tr md-input-container {
          width: inherit;
          margin: 0; }
          md-dialog.quotation-edit-dialog md-dialog-content table tbody tr md-input-container .md-errors-spacer {
            min-height: 6px; }
        md-dialog.quotation-edit-dialog md-dialog-content table tbody tr td.quantity {
          width: 138px;
          max-width: 138px;
          min-width: 138px;
          text-align: right;
          padding-right: 30px; }
          md-dialog.quotation-edit-dialog md-dialog-content table tbody tr td.quantity input {
            text-align: right; }
        md-dialog.quotation-edit-dialog md-dialog-content table tbody tr td.item {
          width: 240px;
          max-width: 240px;
          min-width: 240px; }
        md-dialog.quotation-edit-dialog md-dialog-content table tbody tr td.description {
          width: 120px;
          max-width: 120px;
          min-width: 120px; }
        md-dialog.quotation-edit-dialog md-dialog-content table tbody tr td.unit-price, md-dialog.quotation-edit-dialog md-dialog-content table tbody tr td.net-total {
          width: 81px;
          max-width: 81px;
          min-width: 81px;
          text-align: right; }
          md-dialog.quotation-edit-dialog md-dialog-content table tbody tr td.unit-price input, md-dialog.quotation-edit-dialog md-dialog-content table tbody tr td.net-total input {
            text-align: right; }
        md-dialog.quotation-edit-dialog md-dialog-content table tbody tr td.discount, md-dialog.quotation-edit-dialog md-dialog-content table tbody tr td.discount2 {
          width: 63px;
          max-width: 63px;
          min-width: 63px;
          text-align: right; }
          md-dialog.quotation-edit-dialog md-dialog-content table tbody tr td.discount input, md-dialog.quotation-edit-dialog md-dialog-content table tbody tr td.discount2 input {
            text-align: right; }
      md-dialog.quotation-edit-dialog md-dialog-content table tbody tr:first-child td {
        padding-top: 6px; }
    md-dialog.quotation-edit-dialog md-dialog-content .header-col:first-child {
      margin-right: 60px; }
    md-dialog.quotation-edit-dialog md-dialog-content md-input-container.select {
      margin-top: 0px;
      margin-bottom: 30px; }
    md-dialog.quotation-edit-dialog md-dialog-content md-input-container.date {
      margin-top: 0px;
      margin-bottom: 6px; }
    md-dialog.quotation-edit-dialog md-dialog-content md-input-container.text-area {
      margin-top: 3px;
      margin-bottom: 0px; }
  md-dialog.quotation-edit-dialog md-dialog-actions {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.12); }

md-dialog.quotation-email-dialog {
  max-width: 720px;
  width: 720px; }
  md-dialog.quotation-email-dialog md-toolbar .title {
    font-size: 17px; }
  md-dialog.quotation-email-dialog md-dialog-content {
    display: block;
    position: relative; }
    md-dialog.quotation-email-dialog md-dialog-content .toLabel {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.54); }
    md-dialog.quotation-email-dialog md-dialog-content md-input-container {
      margin-bottom: 0px; }
    md-dialog.quotation-email-dialog md-dialog-content md-input-container.subject {
      margin-top: 36px;
      margin-bottom: 12px; }
    md-dialog.quotation-email-dialog md-dialog-content .md-errors-spacer {
      min-height: 9px;
      height: 9px; }
    md-dialog.quotation-email-dialog md-dialog-content .ta-scroll-window > .ta-bind {
      height: auto;
      min-height: 240px;
      padding: 6px 12px; }
    md-dialog.quotation-email-dialog md-dialog-content .ta-editor.ta-html, md-dialog.quotation-email-dialog md-dialog-content .ta-scroll-window.form-control {
      min-height: 240px;
      height: auto;
      overflow: auto;
      font-family: inherit;
      font-size: 100%; }
      md-dialog.quotation-email-dialog md-dialog-content .ta-editor.ta-html i, md-dialog.quotation-email-dialog md-dialog-content .ta-scroll-window.form-control i {
        font-size: inherit;
        width: inherit;
        height: inherit;
        min-width: inherit;
        min-height: inherit;
        line-height: inherit; }
    md-dialog.quotation-email-dialog md-dialog-content .to {
      position: relative; }
      md-dialog.quotation-email-dialog md-dialog-content .to.hidden-cc input {
        padding-right: 32px; }
      md-dialog.quotation-email-dialog md-dialog-content .to.hidden-cc.hidden-bcc input {
        padding-right: 56px; }
      md-dialog.quotation-email-dialog md-dialog-content .to.hidden-bcc input {
        padding-right: 32px; }
      md-dialog.quotation-email-dialog md-dialog-content .to .cc-bcc {
        position: absolute;
        bottom: 8px;
        right: 0; }
        md-dialog.quotation-email-dialog md-dialog-content .to .cc-bcc .show-cc,
        md-dialog.quotation-email-dialog md-dialog-content .to .cc-bcc .show-bcc {
          cursor: pointer;
          font-size: 12px; }
        md-dialog.quotation-email-dialog md-dialog-content .to .cc-bcc .show-bcc {
          margin-left: 8px; }
  md-dialog.quotation-email-dialog md-dialog-actions {
    position: relative;
    justify-content: space-between;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    background-color: rgba(0, 0, 0, 0.03); }

#rfqEmailContactChips md-content.autocomplete {
  min-height: 250px; }
  #rfqEmailContactChips md-content.autocomplete input {
    min-width: 400px; }

#rfqEmailContactChips .md-item-text.compact {
  padding-top: 8px;
  padding-bottom: 8px; }

#rfqEmailContactChips .contact-item {
  box-sizing: border-box; }
  #rfqEmailContactChips .contact-item.selected {
    background-color: #E3ECF5; }
    #rfqEmailContactChips .contact-item.selected p {
      color: rgba(0, 0, 0, 0.87);
      font-weight: 400; }
  #rfqEmailContactChips .contact-item .md-list-item-text {
    padding: 14px 0;
    max-width: 190px; }
    #rfqEmailContactChips .contact-item .md-list-item-text h3 {
      margin: 0 !important;
      padding: 0;
      line-height: 1.2em !important;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden; }
    #rfqEmailContactChips .contact-item .md-list-item-text p {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden; }

#rfqEmailContactChips md-contact-chips {
  margin-bottom: 10px; }

#rfqEmailContactChips .md-chips {
  padding: 5px 0 8px; }
  #rfqEmailContactChips .md-chips .md-chip-input-container {
    width: 100%; }

@media screen and (max-width: 960px) {
  md-dialog.quotation-email-dialog {
    width: 80%; } }

@media screen and (max-width: 600px) {
  md-dialog.quotation-email-dialog {
    width: 90%; } }

@media (min-width: 960px) {
  #rfqEmailContactChips .contact-item {
    float: left;
    width: 33%; } }

md-dialog.quotation-new-dialog {
  max-width: 1200px;
  width: 1200px; }
  md-dialog.quotation-new-dialog md-toolbar {
    height: 60px;
    min-height: 60px;
    max-height: 60px; }
    md-dialog.quotation-new-dialog md-toolbar .title {
      font-size: 17px; }
  md-dialog.quotation-new-dialog md-dialog-content {
    display: block;
    position: relative;
    height: 400px;
    max-height: 400px;
    min-height: 400px;
    font-size: 15px; }
    md-dialog.quotation-new-dialog md-dialog-content input::-webkit-outer-spin-button,
    md-dialog.quotation-new-dialog md-dialog-content input::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0;
      /* <-- Apparently some margin are still there even though it's hidden */ }
    md-dialog.quotation-new-dialog md-dialog-content table {
      font-size: 12px; }
      md-dialog.quotation-new-dialog md-dialog-content table thead tr th {
        padding: 6px 18px 6px 0px;
        font-weight: 800;
        border-bottom: none;
        background-color: #eaeaea;
        min-width: unset; }
      md-dialog.quotation-new-dialog md-dialog-content table thead tr th:first-child {
        padding-left: 0; }
      md-dialog.quotation-new-dialog md-dialog-content table thead tr th:last-child {
        padding-right: 0; }
      md-dialog.quotation-new-dialog md-dialog-content table thead tr th.quantity {
        text-align: right;
        padding-right: 36px; }
      md-dialog.quotation-new-dialog md-dialog-content table thead tr th.unit-price, md-dialog.quotation-new-dialog md-dialog-content table thead tr th.net-total, md-dialog.quotation-new-dialog md-dialog-content table thead tr th.discount, md-dialog.quotation-new-dialog md-dialog-content table thead tr th.discount2 {
        text-align: right;
        padding-right: 15px; }
      md-dialog.quotation-new-dialog md-dialog-content table tbody tr {
        padding-top: 0;
        padding-bottom: 0; }
        md-dialog.quotation-new-dialog md-dialog-content table tbody tr td {
          padding: 0 5px 0 0;
          border-bottom: none; }
        md-dialog.quotation-new-dialog md-dialog-content table tbody tr td:first-child {
          padding-left: 0; }
        md-dialog.quotation-new-dialog md-dialog-content table tbody tr td:last-child {
          padding-right: 0; }
        md-dialog.quotation-new-dialog md-dialog-content table tbody tr md-input-container {
          width: inherit;
          margin: 0; }
          md-dialog.quotation-new-dialog md-dialog-content table tbody tr md-input-container .md-errors-spacer {
            min-height: 6px; }
        md-dialog.quotation-new-dialog md-dialog-content table tbody tr td.quantity {
          width: 138px;
          max-width: 138px;
          min-width: 138px;
          text-align: right;
          padding-right: 30px; }
          md-dialog.quotation-new-dialog md-dialog-content table tbody tr td.quantity input {
            text-align: right; }
        md-dialog.quotation-new-dialog md-dialog-content table tbody tr td.item {
          width: 240px;
          max-width: 240px;
          min-width: 240px; }
        md-dialog.quotation-new-dialog md-dialog-content table tbody tr td.description {
          width: 120px;
          max-width: 120px;
          min-width: 120px; }
        md-dialog.quotation-new-dialog md-dialog-content table tbody tr td.unit-price, md-dialog.quotation-new-dialog md-dialog-content table tbody tr td.net-total {
          width: 81px;
          max-width: 81px;
          min-width: 81px;
          text-align: right; }
          md-dialog.quotation-new-dialog md-dialog-content table tbody tr td.unit-price input, md-dialog.quotation-new-dialog md-dialog-content table tbody tr td.net-total input {
            text-align: right; }
        md-dialog.quotation-new-dialog md-dialog-content table tbody tr td.discount, md-dialog.quotation-new-dialog md-dialog-content table tbody tr td.discount2 {
          width: 63px;
          max-width: 63px;
          min-width: 63px;
          text-align: right; }
          md-dialog.quotation-new-dialog md-dialog-content table tbody tr td.discount input, md-dialog.quotation-new-dialog md-dialog-content table tbody tr td.discount2 input {
            text-align: right; }
      md-dialog.quotation-new-dialog md-dialog-content table tbody tr:first-child td {
        padding-top: 6px; }
    md-dialog.quotation-new-dialog md-dialog-content .header-col:first-child {
      margin-right: 60px; }
    md-dialog.quotation-new-dialog md-dialog-content md-input-container.select {
      margin-top: 0px;
      margin-bottom: 30px; }
    md-dialog.quotation-new-dialog md-dialog-content md-input-container.date {
      margin-top: 0px;
      margin-bottom: 6px; }
    md-dialog.quotation-new-dialog md-dialog-content md-input-container.text-area {
      margin-top: 3px;
      margin-bottom: 0px; }
  md-dialog.quotation-new-dialog md-dialog-actions {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.12); }

md-dialog.quotation-print-dialog {
  max-width: 720px;
  width: 720px; }
  md-dialog.quotation-print-dialog md-toolbar .title {
    font-size: 17px; }
  md-dialog.quotation-print-dialog md-dialog-content {
    display: block;
    position: relative; }
    md-dialog.quotation-print-dialog md-dialog-content .toLabel {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.54); }
    md-dialog.quotation-print-dialog md-dialog-content md-input-container {
      margin-bottom: 0px; }
    md-dialog.quotation-print-dialog md-dialog-content md-input-container.subject {
      margin-top: 36px;
      margin-bottom: 12px; }
    md-dialog.quotation-print-dialog md-dialog-content .md-errors-spacer {
      min-height: 9px;
      height: 9px; }
    md-dialog.quotation-print-dialog md-dialog-content .ta-scroll-window > .ta-bind {
      height: auto;
      min-height: 240px;
      padding: 6px 12px; }
    md-dialog.quotation-print-dialog md-dialog-content .ta-editor.ta-html, md-dialog.quotation-print-dialog md-dialog-content .ta-scroll-window.form-control {
      min-height: 240px;
      height: auto;
      overflow: auto;
      font-family: inherit;
      font-size: 100%; }
    md-dialog.quotation-print-dialog md-dialog-content .to {
      position: relative; }
      md-dialog.quotation-print-dialog md-dialog-content .to.hidden-cc input {
        padding-right: 32px; }
      md-dialog.quotation-print-dialog md-dialog-content .to.hidden-cc.hidden-bcc input {
        padding-right: 56px; }
      md-dialog.quotation-print-dialog md-dialog-content .to.hidden-bcc input {
        padding-right: 32px; }
      md-dialog.quotation-print-dialog md-dialog-content .to .cc-bcc {
        position: absolute;
        bottom: 8px;
        right: 0; }
        md-dialog.quotation-print-dialog md-dialog-content .to .cc-bcc .show-cc,
        md-dialog.quotation-print-dialog md-dialog-content .to .cc-bcc .show-bcc {
          cursor: pointer;
          font-size: 12px; }
        md-dialog.quotation-print-dialog md-dialog-content .to .cc-bcc .show-bcc {
          margin-left: 8px; }
    md-dialog.quotation-print-dialog md-dialog-content .attachment-list {
      font-size: 13px;
      padding-top: 16px; }
      md-dialog.quotation-print-dialog md-dialog-content .attachment-list .attachment {
        background-color: rgba(0, 0, 0, 0.08);
        border: 1px solid rgba(0, 0, 0, 0.16);
        padding-left: 16px;
        margin-top: 8px;
        border-radius: 2px; }
        md-dialog.quotation-print-dialog md-dialog-content .attachment-list .attachment .filename {
          font-weight: 500; }
        md-dialog.quotation-print-dialog md-dialog-content .attachment-list .attachment .md-button {
          margin: 0; }
        md-dialog.quotation-print-dialog md-dialog-content .attachment-list .attachment:last-child {
          margin-bottom: 0; }
  md-dialog.quotation-print-dialog md-dialog-actions {
    position: relative;
    justify-content: space-between;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    background-color: rgba(0, 0, 0, 0.03); }

@media screen and (max-width: 960px) {
  md-dialog.quotation-print-dialog {
    width: 80%; } }

@media screen and (max-width: 600px) {
  md-dialog.quotation-print-dialog {
    width: 90%; } }

#contacts .main-sidenav md-list md-list-item.selected .md-button {
  font-weight: 500;
  color: #000000; }

#contacts .main-sidenav md-list md-list-item.new-group-form .editable-text {
  width: 100%; }
  #contacts .main-sidenav md-list md-list-item.new-group-form .editable-text .editable-controls {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-left: 8px; }
    #contacts .main-sidenav md-list md-list-item.new-group-form .editable-text .editable-controls .editable-input {
      border: 1px solid rgba(0, 0, 0, 0.2);
      padding: 4px 2px;
      margin-bottom: 8px;
      font-size: 13px;
      width: 100%; }
    #contacts .main-sidenav md-list md-list-item.new-group-form .editable-text .editable-controls .editable-buttons .md-button {
      margin: 0;
      margin: 0;
      padding: 0;
      height: 32px;
      min-height: 32px;
      line-height: 32px;
      width: 32px; }

#purchasing-pending-requests #draft-purchase-sidenav.sidenav {
  width: 320px;
  min-width: 320px;
  max-width: 320px;
  padding: 0; }
  #purchasing-pending-requests #draft-purchase-sidenav.sidenav._md-locked-open {
    width: 320px;
    min-width: 320px;
    max-width: 320px;
    overflow: hidden; }
  #purchasing-pending-requests #draft-purchase-sidenav.sidenav .header {
    height: 90px;
    min-height: 90px;
    max-height: 90px;
    padding: 24px 24px;
    background-color: #078aca; }
    #purchasing-pending-requests #draft-purchase-sidenav.sidenav .header .title {
      font-size: 15px;
      margin-right: 20px; }
    #purchasing-pending-requests #draft-purchase-sidenav.sidenav .header .status-icon md-icon {
      color: #ffffff; }
  #purchasing-pending-requests #draft-purchase-sidenav.sidenav .content {
    position: relative;
    background: #FFFFFF;
    padding: 24px 0; }
    #purchasing-pending-requests #draft-purchase-sidenav.sidenav .content ms-widget .sub-box {
      border-radius: 15px;
      min-width: 75px; }
    #purchasing-pending-requests #draft-purchase-sidenav.sidenav .content ms-widget ms-widget-front.drop-on-over {
      border: 1px dashed red; }

@media screen and (max-width: 960px) {
  #purchasing-pending-requests #draft-purchase-sidenav.sidenav {
    width: 240px;
    min-width: 240px;
    max-width: 240px;
    font-size: 11px; }
    #purchasing-pending-requests #draft-purchase-sidenav.sidenav h3, #purchasing-pending-requests #draft-purchase-sidenav.sidenav h4, #purchasing-pending-requests #draft-purchase-sidenav.sidenav p {
      font-size: 11px !important; } }

#purchasing-edit-purchase-form .sidenav.contracted-suppliers-sidenav {
  width: 320px;
  min-width: 320px;
  max-width: 320px;
  padding: 0; }
  #purchasing-edit-purchase-form .sidenav.contracted-suppliers-sidenav._md-locked-open {
    width: 320px;
    min-width: 320px;
    max-width: 320px;
    overflow: hidden; }
  #purchasing-edit-purchase-form .sidenav.contracted-suppliers-sidenav .header {
    height: 136px;
    min-height: 136px;
    max-height: 136px;
    padding: 24px 24px;
    background-color: #078aca; }
    #purchasing-edit-purchase-form .sidenav.contracted-suppliers-sidenav .header .title {
      font-size: 18px;
      color: #a6cee2;
      margin-left: 15px; }
    #purchasing-edit-purchase-form .sidenav.contracted-suppliers-sidenav .header .status-icon md-icon {
      color: #ffffff; }
  #purchasing-edit-purchase-form .sidenav.contracted-suppliers-sidenav .content {
    position: relative;
    background: #FFFFFF;
    padding: 24px 0; }
    #purchasing-edit-purchase-form .sidenav.contracted-suppliers-sidenav .content ms-widget ms-widget-front .md-toolbar-tools {
      height: 48px;
      max-height: 48px;
      background-color: #073c56; }
    #purchasing-edit-purchase-form .sidenav.contracted-suppliers-sidenav .content ms-widget ms-widget-front .mid-panel {
      padding: 15px 9px; }
    #purchasing-edit-purchase-form .sidenav.contracted-suppliers-sidenav .content ms-widget ms-widget-front .sub-box {
      border-radius: 15px;
      min-width: 75px; }

@media screen and (max-width: 960px) {
  #purchasing-edit-purchase-form .sidenav.contracted-suppliers-sidenav {
    width: 240px;
    min-width: 240px;
    max-width: 240px;
    font-size: 11px; }
    #purchasing-edit-purchase-form .sidenav.contracted-suppliers-sidenav h3, #purchasing-edit-purchase-form .sidenav.contracted-suppliers-sidenav h4, #purchasing-edit-purchase-form .sidenav.contracted-suppliers-sidenav p {
      font-size: 11px !important; } }

#purchasing-edit-purchase-form .sidenav.previous-quotations-sidenav {
  width: 320px;
  min-width: 320px;
  max-width: 320px;
  padding: 0; }
  #purchasing-edit-purchase-form .sidenav.previous-quotations-sidenav._md-locked-open {
    width: 320px;
    min-width: 320px;
    max-width: 320px;
    overflow: hidden; }
  #purchasing-edit-purchase-form .sidenav.previous-quotations-sidenav .header {
    height: 136px;
    min-height: 136px;
    max-height: 136px;
    padding: 24px 24px;
    background-color: #078aca; }
    #purchasing-edit-purchase-form .sidenav.previous-quotations-sidenav .header .title {
      font-size: 18px;
      color: #a6cee2;
      margin-left: 15px; }
    #purchasing-edit-purchase-form .sidenav.previous-quotations-sidenav .header .status-icon md-icon {
      color: #ffffff; }
    #purchasing-edit-purchase-form .sidenav.previous-quotations-sidenav .header md-select {
      margin-left: 66px; }
      #purchasing-edit-purchase-form .sidenav.previous-quotations-sidenav .header md-select .md-select-value {
        color: #ffffffb3;
        border-bottom-color: #ffffffb3; }
  #purchasing-edit-purchase-form .sidenav.previous-quotations-sidenav .content {
    position: relative;
    background: #FFFFFF;
    padding: 24px 0; }
    #purchasing-edit-purchase-form .sidenav.previous-quotations-sidenav .content ms-widget ms-widget-front .md-toolbar-tools {
      height: 48px;
      max-height: 48px;
      background-color: #073c56; }
    #purchasing-edit-purchase-form .sidenav.previous-quotations-sidenav .content ms-widget ms-widget-front .mid-panel {
      padding: 15px 9px; }
    #purchasing-edit-purchase-form .sidenav.previous-quotations-sidenav .content ms-widget ms-widget-front .sub-box {
      border-radius: 15px;
      min-width: 75px; }
    #purchasing-edit-purchase-form .sidenav.previous-quotations-sidenav .content ms-widget ms-widget-back .md-toolbar-tools {
      height: 48px;
      max-height: 48px; }
    #purchasing-edit-purchase-form .sidenav.previous-quotations-sidenav .content ms-widget ms-widget-back md-tabs {
      height: 108px;
      min-height: 108px; }
      #purchasing-edit-purchase-form .sidenav.previous-quotations-sidenav .content ms-widget ms-widget-back md-tabs md-tabs-wrapper {
        display: none; }
      #purchasing-edit-purchase-form .sidenav.previous-quotations-sidenav .content ms-widget ms-widget-back md-tabs md-tabs-content-wrapper {
        top: 0; }
        #purchasing-edit-purchase-form .sidenav.previous-quotations-sidenav .content ms-widget ms-widget-back md-tabs md-tabs-content-wrapper md-tab-content {
          overflow: hidden; }
    #purchasing-edit-purchase-form .sidenav.previous-quotations-sidenav .content ms-widget ms-widget-back .m-table-container {
      padding: 9px 0px 0px 0px;
      width: 180px; }
      #purchasing-edit-purchase-form .sidenav.previous-quotations-sidenav .content ms-widget ms-widget-back .m-table-container .m-table-qtable {
        display: table-cell;
        border-radius: 6px;
        padding: 3px; }
        #purchasing-edit-purchase-form .sidenav.previous-quotations-sidenav .content ms-widget ms-widget-back .m-table-container .m-table-qtable .m-table-qrow .m-table-qcell {
          width: 175px;
          height: 75px;
          vertical-align: middle;
          padding: 0px;
          font-size: 11px;
          color: #353535; }
          #purchasing-edit-purchase-form .sidenav.previous-quotations-sidenav .content ms-widget ms-widget-back .m-table-container .m-table-qtable .m-table-qrow .m-table-qcell .item-name {
            height: 21px;
            color: #313131;
            border: 1px solid #d5d6d6;
            padding: 3px;
            width: 175px;
            max-width: 175px;
            background-color: #d5d6d6; }
          #purchasing-edit-purchase-form .sidenav.previous-quotations-sidenav .content ms-widget ms-widget-back .m-table-container .m-table-qtable .m-table-qrow .m-table-qcell .unit-price {
            height: 21px;
            border: 1px solid #c5c5c5;
            border-top-style: none;
            padding: 3px; }
            #purchasing-edit-purchase-form .sidenav.previous-quotations-sidenav .content ms-widget ms-widget-back .m-table-container .m-table-qtable .m-table-qrow .m-table-qcell .unit-price .label {
              float: left; }
            #purchasing-edit-purchase-form .sidenav.previous-quotations-sidenav .content ms-widget ms-widget-back .m-table-container .m-table-qtable .m-table-qrow .m-table-qcell .unit-price .value {
              float: right; }
          #purchasing-edit-purchase-form .sidenav.previous-quotations-sidenav .content ms-widget ms-widget-back .m-table-container .m-table-qtable .m-table-qrow .m-table-qcell .discount {
            height: 21px;
            border: 1px solid #c5c5c5;
            border-top-style: none;
            padding: 3px;
            display: block; }
            #purchasing-edit-purchase-form .sidenav.previous-quotations-sidenav .content ms-widget ms-widget-back .m-table-container .m-table-qtable .m-table-qrow .m-table-qcell .discount .disc-1 {
              float: left; }
            #purchasing-edit-purchase-form .sidenav.previous-quotations-sidenav .content ms-widget ms-widget-back .m-table-container .m-table-qtable .m-table-qrow .m-table-qcell .discount .disc-2 {
              float: right; }
          #purchasing-edit-purchase-form .sidenav.previous-quotations-sidenav .content ms-widget ms-widget-back .m-table-container .m-table-qtable .m-table-qrow .m-table-qcell .total {
            height: 21px;
            border: 1px solid #c5c5c5;
            border-top-style: none;
            padding: 3px; }
            #purchasing-edit-purchase-form .sidenav.previous-quotations-sidenav .content ms-widget ms-widget-back .m-table-container .m-table-qtable .m-table-qrow .m-table-qcell .total .label {
              float: left; }
            #purchasing-edit-purchase-form .sidenav.previous-quotations-sidenav .content ms-widget ms-widget-back .m-table-container .m-table-qtable .m-table-qrow .m-table-qcell .total .value {
              float: right;
              font-weight: 600; }

@media screen and (max-width: 960px) {
  #purchasing-edit-purchase-form .sidenav.previous-quotations-sidenav {
    width: 240px;
    min-width: 240px;
    max-width: 240px;
    font-size: 11px; }
    #purchasing-edit-purchase-form .sidenav.previous-quotations-sidenav h3, #purchasing-edit-purchase-form .sidenav.previous-quotations-sidenav h4, #purchasing-edit-purchase-form .sidenav.previous-quotations-sidenav p {
      font-size: 11px !important; } }

#purchasing-edit-purchase-form .sidenav.rfq-tickets-sidenav {
  width: 320px;
  min-width: 320px;
  max-width: 320px;
  padding: 0; }
  #purchasing-edit-purchase-form .sidenav.rfq-tickets-sidenav._md-locked-open {
    width: 320px;
    min-width: 320px;
    max-width: 320px;
    overflow: hidden; }
  #purchasing-edit-purchase-form .sidenav.rfq-tickets-sidenav .header {
    height: 136px;
    min-height: 136px;
    max-height: 136px;
    padding: 24px 24px; }
    #purchasing-edit-purchase-form .sidenav.rfq-tickets-sidenav .header .title {
      font-size: 18px;
      color: #ffffff;
      margin-left: 15px; }
    #purchasing-edit-purchase-form .sidenav.rfq-tickets-sidenav .header md-icon {
      color: #ffffff; }
  #purchasing-edit-purchase-form .sidenav.rfq-tickets-sidenav .content {
    position: relative;
    background: #FFFFFF;
    padding: 24px 0; }
    #purchasing-edit-purchase-form .sidenav.rfq-tickets-sidenav .content ms-widget ms-widget-front .md-toolbar-tools {
      height: 48px;
      max-height: 48px; }
    #purchasing-edit-purchase-form .sidenav.rfq-tickets-sidenav .content ms-widget ms-widget-front .mid-panel {
      padding: 15px 9px; }
    #purchasing-edit-purchase-form .sidenav.rfq-tickets-sidenav .content ms-widget ms-widget-front .sub-box {
      border-radius: 15px; }

@media screen and (max-width: 960px) {
  #purchasing-edit-purchase-form .sidenav.rfq-tickets-sidenav {
    width: 240px;
    min-width: 240px;
    max-width: 240px;
    font-size: 11px; }
    #purchasing-edit-purchase-form .sidenav.rfq-tickets-sidenav h3, #purchasing-edit-purchase-form .sidenav.rfq-tickets-sidenav h4, #purchasing-edit-purchase-form .sidenav.rfq-tickets-sidenav p {
      font-size: 11px !important; } }

#purchasing-awaiting-approval {
  position: relative;
  height: auto;
  min-height: 100%; }
  #purchasing-awaiting-approval > .header {
    height: 90px;
    min-height: 90px;
    max-height: 90px; }
    #purchasing-awaiting-approval > .header .h1 md-icon {
      margin-right: 12px; }
    #purchasing-awaiting-approval > .header md-icon {
      color: #ffffff; }
    #purchasing-awaiting-approval > .header .records-count {
      margin: 6px 0 0 36px; }
    #purchasing-awaiting-approval > .header .search-input-wrapper {
      position: relative; }
      #purchasing-awaiting-approval > .header .search-input-wrapper label {
        padding-right: 4px;
        cursor: pointer; }
        #purchasing-awaiting-approval > .header .search-input-wrapper label md-icon {
          color: rgba(255, 255, 255, 0.8); }
      #purchasing-awaiting-approval > .header .search-input-wrapper md-input-container .md-errors-spacer {
        display: none; }
      #purchasing-awaiting-approval > .header .search-input-wrapper md-input-container input {
        border-bottom: 1px solid rgba(255, 255, 255, 0.8);
        color: rgba(255, 255, 255, 0.8);
        width: 240px; }
        #purchasing-awaiting-approval > .header .search-input-wrapper md-input-container input::placeholder {
          color: rgba(255, 255, 255, 0.8); }
        #purchasing-awaiting-approval > .header .search-input-wrapper md-input-container input:focus {
          border-bottom: 1px solid white;
          color: white; }
  #purchasing-awaiting-approval > .content {
    position: absolute;
    top: 90px;
    bottom: 0;
    right: 0;
    left: 0; }
    #purchasing-awaiting-approval > .content .main {
      padding: 0px 24px 0 24px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
      #purchasing-awaiting-approval > .content .main .data-list {
        position: relative;
        padding-bottom: 0; }
        #purchasing-awaiting-approval > .content .main .data-list .md-subheader {
          box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
          border-bottom: .1px solid #dadada; }
          #purchasing-awaiting-approval > .content .main .data-list .md-subheader .md-subheader-inner {
            padding: 12px 16px; }
            #purchasing-awaiting-approval > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content {
              font-size: 12px;
              font-weight: 900; }
              #purchasing-awaiting-approval > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:last-child {
                width: 60px;
                text-align: center; }
              #purchasing-awaiting-approval > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:hover {
                cursor: pointer;
                color: #636363; }
        #purchasing-awaiting-approval > .content .main .data-list .list-item {
          border-bottom: .1px solid #dadada; }
          #purchasing-awaiting-approval > .content .main .data-list .list-item span {
            padding-right: 6px; }
          #purchasing-awaiting-approval > .content .main .data-list .list-item .edit-button {
            width: 60px;
            margin: 0; }
      #purchasing-awaiting-approval > .content .main .no-results {
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14); }

@media screen and (max-width: 960px) {
  #purchasing-awaiting-approval > .header .h1 {
    font-size: 1.8rem; }
  #purchasing-awaiting-approval > .header .records-count {
    font-size: 1.1rem; }
  #purchasing-awaiting-approval > .content .main {
    padding: 0; }
    #purchasing-awaiting-approval > .content .main .data-list {
      padding-top: 0; } }

#purchasing-completed-purchases {
  position: relative;
  height: auto;
  min-height: 100%; }
  #purchasing-completed-purchases > .header {
    height: 90px;
    min-height: 90px;
    max-height: 90px; }
    #purchasing-completed-purchases > .header .h1 md-icon {
      margin-right: 12px; }
    #purchasing-completed-purchases > .header md-icon {
      color: #ffffff; }
    #purchasing-completed-purchases > .header .records-count {
      margin: 6px 0 0 36px; }
    #purchasing-completed-purchases > .header .search-input-wrapper {
      position: relative; }
      #purchasing-completed-purchases > .header .search-input-wrapper label {
        padding-right: 4px;
        cursor: pointer; }
        #purchasing-completed-purchases > .header .search-input-wrapper label md-icon {
          color: rgba(255, 255, 255, 0.8); }
      #purchasing-completed-purchases > .header .search-input-wrapper md-input-container .md-errors-spacer {
        display: none; }
      #purchasing-completed-purchases > .header .search-input-wrapper md-input-container input {
        border-bottom: 1px solid rgba(255, 255, 255, 0.8);
        color: rgba(255, 255, 255, 0.8);
        width: 240px; }
        #purchasing-completed-purchases > .header .search-input-wrapper md-input-container input::placeholder {
          color: rgba(255, 255, 255, 0.8); }
        #purchasing-completed-purchases > .header .search-input-wrapper md-input-container input:focus {
          border-bottom: 1px solid white;
          color: white; }
  #purchasing-completed-purchases > .content {
    position: absolute;
    top: 90px;
    bottom: 0;
    right: 0;
    left: 0; }
    #purchasing-completed-purchases > .content .main {
      padding: 0px 24px 0 24px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
      #purchasing-completed-purchases > .content .main .data-list {
        position: relative;
        padding-bottom: 0; }
        #purchasing-completed-purchases > .content .main .data-list .md-subheader {
          box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
          border-bottom: .1px solid #dadada; }
          #purchasing-completed-purchases > .content .main .data-list .md-subheader .md-subheader-inner {
            padding: 12px 16px; }
            #purchasing-completed-purchases > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content {
              font-size: 12px;
              font-weight: 900; }
              #purchasing-completed-purchases > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:last-child {
                width: 60px;
                text-align: center; }
              #purchasing-completed-purchases > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:hover {
                cursor: pointer;
                color: #636363; }
        #purchasing-completed-purchases > .content .main .data-list .list-item {
          border-bottom: .1px solid #dadada; }
          #purchasing-completed-purchases > .content .main .data-list .list-item span {
            padding-right: 6px; }
          #purchasing-completed-purchases > .content .main .data-list .list-item .edit-button {
            width: 60px;
            margin: 0; }
      #purchasing-completed-purchases > .content .main .no-results {
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14); }

@media screen and (max-width: 960px) {
  #purchasing-completed-purchases > .header .h1 {
    font-size: 1.8rem; }
  #purchasing-completed-purchases > .header .records-count {
    font-size: 1.1rem; }
  #purchasing-completed-purchases > .content .main {
    padding: 0; }
    #purchasing-completed-purchases > .content .main .data-list {
      padding-top: 0; } }

#purchasing-pending-requests {
  position: relative;
  height: auto;
  min-height: 100%; }
  #purchasing-pending-requests > .center > .header {
    height: 90px;
    min-height: 90px;
    max-height: 90px; }
    #purchasing-pending-requests > .center > .header .h1 md-icon {
      margin-right: 12px; }
    #purchasing-pending-requests > .center > .header md-icon {
      color: #ffffff; }
    #purchasing-pending-requests > .center > .header .records-count {
      margin: 6px 0 0 36px; }
    #purchasing-pending-requests > .center > .header .search-input-wrapper {
      position: relative; }
      #purchasing-pending-requests > .center > .header .search-input-wrapper label {
        padding-right: 4px;
        cursor: pointer; }
        #purchasing-pending-requests > .center > .header .search-input-wrapper label md-icon {
          color: rgba(255, 255, 255, 0.8); }
      #purchasing-pending-requests > .center > .header .search-input-wrapper md-input-container .md-errors-spacer {
        display: none; }
      #purchasing-pending-requests > .center > .header .search-input-wrapper md-input-container input {
        border-bottom: 1px solid rgba(255, 255, 255, 0.8);
        color: rgba(255, 255, 255, 0.8);
        width: 240px; }
        #purchasing-pending-requests > .center > .header .search-input-wrapper md-input-container input::placeholder {
          color: rgba(255, 255, 255, 0.8); }
        #purchasing-pending-requests > .center > .header .search-input-wrapper md-input-container input:focus {
          border-bottom: 1px solid white;
          color: white; }
    #purchasing-pending-requests > .center > .header.selected-bar {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 999;
      background-image: none;
      opacity: 1 !important; }
      #purchasing-pending-requests > .center > .header.selected-bar .close-button-wrapper {
        width: 244px; }
  #purchasing-pending-requests > .center > .content {
    position: absolute;
    top: 90px;
    bottom: 0;
    right: 0;
    left: 0; }
    #purchasing-pending-requests > .center > .content .main {
      background-color: whitesmoke;
      padding: 0px 24px 0 24px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
      #purchasing-pending-requests > .center > .content .main .data-list {
        position: relative;
        padding-bottom: 0; }
        #purchasing-pending-requests > .center > .content .main .data-list > .toolbar {
          padding: 0 12px 0 20px;
          position: absolute;
          width: 100%;
          z-index: 3;
          background-color: #ffffff; }
          #purchasing-pending-requests > .center > .content .main .data-list > .toolbar .action-buttons .md-icon-button:hover {
            background-color: #efefef; }
        #purchasing-pending-requests > .center > .content .main .data-list .md-subheader {
          box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
          border-bottom: .1px solid #dadada; }
          #purchasing-pending-requests > .center > .content .main .data-list .md-subheader .md-subheader-inner {
            padding: 12px 16px; }
            #purchasing-pending-requests > .center > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content {
              font-size: 12px;
              font-weight: 900; }
              #purchasing-pending-requests > .center > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:first-child {
                width: 60px;
                text-align: center; }
              #purchasing-pending-requests > .center > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:last-child {
                width: 60px;
                text-align: center; }
              #purchasing-pending-requests > .center > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:hover {
                cursor: pointer;
                color: #636363; }
        #purchasing-pending-requests > .center > .content .main .data-list .list-item {
          border-bottom: .1px solid #dadada; }
          #purchasing-pending-requests > .center > .content .main .data-list .list-item:hover {
            cursor: grab; }
          #purchasing-pending-requests > .center > .content .main .data-list .list-item:active {
            cursor: grabbing; }
          #purchasing-pending-requests > .center > .content .main .data-list .list-item span {
            padding-right: 6px; }
          #purchasing-pending-requests > .center > .content .main .data-list .list-item .edit-button {
            width: 60px;
            margin: 0; }
      #purchasing-pending-requests > .center > .content .main .no-results {
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14); }

@media screen and (max-width: 960px) {
  #purchasing-pending-requests > .header .h1 {
    font-size: 1.8rem; }
  #purchasing-pending-requests > .header .records-count {
    font-size: 1.1rem; }
  #purchasing-pending-requests > .content .main {
    padding: 0; }
    #purchasing-pending-requests > .content .main .data-list {
      padding-top: 0; } }

#purchasing-orders-placed {
  position: relative;
  height: auto;
  min-height: 100%; }
  #purchasing-orders-placed > .header {
    height: 90px;
    min-height: 90px;
    max-height: 90px; }
    #purchasing-orders-placed > .header .h1 md-icon {
      margin-right: 12px; }
    #purchasing-orders-placed > .header md-icon {
      color: #ffffff; }
    #purchasing-orders-placed > .header .records-count {
      margin: 6px 0 0 36px; }
    #purchasing-orders-placed > .header .search-input-wrapper {
      position: relative; }
      #purchasing-orders-placed > .header .search-input-wrapper label {
        padding-right: 4px;
        cursor: pointer; }
        #purchasing-orders-placed > .header .search-input-wrapper label md-icon {
          color: rgba(255, 255, 255, 0.8); }
      #purchasing-orders-placed > .header .search-input-wrapper md-input-container .md-errors-spacer {
        display: none; }
      #purchasing-orders-placed > .header .search-input-wrapper md-input-container input {
        border-bottom: 1px solid rgba(255, 255, 255, 0.8);
        color: rgba(255, 255, 255, 0.8);
        width: 240px; }
        #purchasing-orders-placed > .header .search-input-wrapper md-input-container input::placeholder {
          color: rgba(255, 255, 255, 0.8); }
        #purchasing-orders-placed > .header .search-input-wrapper md-input-container input:focus {
          border-bottom: 1px solid white;
          color: white; }
  #purchasing-orders-placed > .content {
    position: absolute;
    top: 90px;
    bottom: 0;
    right: 0;
    left: 0; }
    #purchasing-orders-placed > .content .main {
      padding: 0px 24px 0 24px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
      #purchasing-orders-placed > .content .main .data-list {
        position: relative;
        padding-bottom: 0; }
        #purchasing-orders-placed > .content .main .data-list .md-subheader {
          box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
          border-bottom: .1px solid #dadada; }
          #purchasing-orders-placed > .content .main .data-list .md-subheader .md-subheader-inner {
            padding: 12px 16px; }
            #purchasing-orders-placed > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content {
              font-size: 12px;
              font-weight: 900; }
              #purchasing-orders-placed > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:last-child {
                width: 60px;
                text-align: center; }
              #purchasing-orders-placed > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:hover {
                cursor: pointer;
                color: #636363; }
        #purchasing-orders-placed > .content .main .data-list .list-item {
          border-bottom: .1px solid #dadada; }
          #purchasing-orders-placed > .content .main .data-list .list-item span {
            padding-right: 6px; }
          #purchasing-orders-placed > .content .main .data-list .list-item .edit-button {
            width: 60px;
            margin: 0; }
      #purchasing-orders-placed > .content .main .no-results {
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14); }

@media screen and (max-width: 960px) {
  #purchasing-orders-placed > .header .h1 {
    font-size: 1.8rem; }
  #purchasing-orders-placed > .header .records-count {
    font-size: 1.1rem; }
  #purchasing-orders-placed > .content .main {
    padding: 0; }
    #purchasing-orders-placed > .content .main .data-list {
      padding-top: 0; } }

#purchasing-completed-requests {
  position: relative;
  height: auto;
  min-height: 100%; }
  #purchasing-completed-requests > .header {
    height: 90px;
    min-height: 90px;
    max-height: 90px; }
    #purchasing-completed-requests > .header .h1 md-icon {
      margin-right: 12px; }
    #purchasing-completed-requests > .header md-icon {
      color: #ffffff; }
    #purchasing-completed-requests > .header .records-count {
      margin: 6px 0 0 36px; }
    #purchasing-completed-requests > .header .search-input-wrapper {
      position: relative; }
      #purchasing-completed-requests > .header .search-input-wrapper label {
        padding-right: 4px;
        cursor: pointer; }
        #purchasing-completed-requests > .header .search-input-wrapper label md-icon {
          color: rgba(255, 255, 255, 0.8); }
      #purchasing-completed-requests > .header .search-input-wrapper md-input-container .md-errors-spacer {
        display: none; }
      #purchasing-completed-requests > .header .search-input-wrapper md-input-container input {
        border-bottom: 1px solid rgba(255, 255, 255, 0.8);
        color: rgba(255, 255, 255, 0.8);
        width: 240px; }
        #purchasing-completed-requests > .header .search-input-wrapper md-input-container input::placeholder {
          color: rgba(255, 255, 255, 0.8); }
        #purchasing-completed-requests > .header .search-input-wrapper md-input-container input:focus {
          border-bottom: 1px solid white;
          color: white; }
  #purchasing-completed-requests > .content {
    position: absolute;
    top: 90px;
    bottom: 0;
    right: 0;
    left: 0; }
    #purchasing-completed-requests > .content .main {
      padding: 0px 24px 0 24px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
      #purchasing-completed-requests > .content .main .data-list {
        position: relative;
        padding-bottom: 0; }
        #purchasing-completed-requests > .content .main .data-list .md-subheader {
          box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
          border-bottom: .1px solid #dadada; }
          #purchasing-completed-requests > .content .main .data-list .md-subheader .md-subheader-inner {
            padding: 12px 16px; }
            #purchasing-completed-requests > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content {
              font-size: 12px;
              font-weight: 900; }
              #purchasing-completed-requests > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:last-child {
                width: 60px;
                text-align: center; }
              #purchasing-completed-requests > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:hover {
                cursor: pointer;
                color: #636363; }
        #purchasing-completed-requests > .content .main .data-list .list-item {
          border-bottom: .1px solid #dadada; }
          #purchasing-completed-requests > .content .main .data-list .list-item span {
            padding-right: 6px; }
          #purchasing-completed-requests > .content .main .data-list .list-item .edit-button {
            width: 60px;
            margin: 0; }
      #purchasing-completed-requests > .content .main .no-results {
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14); }

@media screen and (max-width: 960px) {
  #purchasing-completed-requests > .header .h1 {
    font-size: 1.8rem; }
  #purchasing-completed-requests > .header .records-count {
    font-size: 1.1rem; }
  #purchasing-completed-requests > .content .main {
    padding: 0; }
    #purchasing-completed-requests > .content .main .data-list {
      padding-top: 0; } }

#purchase-contract > .header {
  height: 120px;
  min-height: 120px;
  max-height: 120px; }
  #purchase-contract > .header .goto-purchase-contracts-button {
    margin-right: 16px; }
  #purchase-contract > .header .subtitle {
    margin: 6px 0 0 0; }
  #purchase-contract > .header .md-icon-button:hover {
    background-color: #f2f2f233; }
  #purchase-contract > .header .md-icon-button md-icon {
    color: #fafafa; }
  #purchase-contract > .header .md-icon-button[disabled] md-icon {
    color: #fafafa69; }

#purchase-contract > .content {
  padding: 24px; }
  #purchase-contract > .content .detail-form-container {
    padding: 24px;
    margin-bottom: 24px;
    max-width: 990px; }
    #purchase-contract > .content .detail-form-container form {
      font-size: 12px !important; }
      #purchase-contract > .content .detail-form-container form table.simple thead tr th {
        padding: 0 5px 0 5px; }
      #purchase-contract > .content .detail-form-container form table.simple thead tr th.unit-price, #purchase-contract > .content .detail-form-container form table.simple thead tr th.discount, #purchase-contract > .content .detail-form-container form table.simple thead tr th.discount2 {
        text-align: right;
        padding-right: 9px; }
      #purchase-contract > .content .detail-form-container form table.simple thead tr th.actions {
        text-align: center; }
      #purchase-contract > .content .detail-form-container form table.simple tbody tr {
        padding-top: 0;
        padding-bottom: 0; }
        #purchase-contract > .content .detail-form-container form table.simple tbody tr td {
          padding: 0 5px 0 5px;
          border-bottom: none; }
        #purchase-contract > .content .detail-form-container form table.simple tbody tr td.unit-price, #purchase-contract > .content .detail-form-container form table.simple tbody tr td.discount, #purchase-contract > .content .detail-form-container form table.simple tbody tr td.discount2 {
          text-align: right; }
          #purchase-contract > .content .detail-form-container form table.simple tbody tr td.unit-price md-input-container, #purchase-contract > .content .detail-form-container form table.simple tbody tr td.discount md-input-container, #purchase-contract > .content .detail-form-container form table.simple tbody tr td.discount2 md-input-container {
            width: inherit;
            margin: 0;
            /* Chrome, Safari, Edge, Opera */
            /* Firefox */ }
            #purchase-contract > .content .detail-form-container form table.simple tbody tr td.unit-price md-input-container .md-errors-spacer, #purchase-contract > .content .detail-form-container form table.simple tbody tr td.discount md-input-container .md-errors-spacer, #purchase-contract > .content .detail-form-container form table.simple tbody tr td.discount2 md-input-container .md-errors-spacer {
              min-height: 6px; }
            #purchase-contract > .content .detail-form-container form table.simple tbody tr td.unit-price md-input-container input::-webkit-outer-spin-button,
            #purchase-contract > .content .detail-form-container form table.simple tbody tr td.unit-price md-input-container input::-webkit-inner-spin-button, #purchase-contract > .content .detail-form-container form table.simple tbody tr td.discount md-input-container input::-webkit-outer-spin-button,
            #purchase-contract > .content .detail-form-container form table.simple tbody tr td.discount md-input-container input::-webkit-inner-spin-button, #purchase-contract > .content .detail-form-container form table.simple tbody tr td.discount2 md-input-container input::-webkit-outer-spin-button,
            #purchase-contract > .content .detail-form-container form table.simple tbody tr td.discount2 md-input-container input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0; }
            #purchase-contract > .content .detail-form-container form table.simple tbody tr td.unit-price md-input-container input[type=number], #purchase-contract > .content .detail-form-container form table.simple tbody tr td.discount md-input-container input[type=number], #purchase-contract > .content .detail-form-container form table.simple tbody tr td.discount2 md-input-container input[type=number] {
              -moz-appearance: textfield; }
            #purchase-contract > .content .detail-form-container form table.simple tbody tr td.unit-price md-input-container input, #purchase-contract > .content .detail-form-container form table.simple tbody tr td.discount md-input-container input, #purchase-contract > .content .detail-form-container form table.simple tbody tr td.discount2 md-input-container input {
              text-align: end; }
        #purchase-contract > .content .detail-form-container form table.simple tbody tr td.item {
          width: 390px; }
          #purchase-contract > .content .detail-form-container form table.simple tbody tr td.item md-autocomplete > md-autocomplete-wrap > md-input-container {
            margin-top: 0px; }
            #purchase-contract > .content .detail-form-container form table.simple tbody tr td.item md-autocomplete > md-autocomplete-wrap > md-input-container > label {
              -webkit-transform: translate3d(1px, 28px, 0) scale(1) !important;
              transform: translate3d(1px, 28px, 0) scale(1) !important;
              color: #BDBDBD; }
            #purchase-contract > .content .detail-form-container form table.simple tbody tr td.item md-autocomplete > md-autocomplete-wrap > md-input-container.md-input-has-value > label {
              display: none !important; }
        #purchase-contract > .content .detail-form-container form table.simple tbody tr td.actions {
          text-align: center; }
          #purchase-contract > .content .detail-form-container form table.simple tbody tr td.actions .md-button {
            min-width: auto;
            min-height: auto;
            line-height: unset; }
            #purchase-contract > .content .detail-form-container form table.simple tbody tr td.actions .md-button .icon-delete {
              font-size: 18px;
              width: 18px;
              height: 18px;
              min-width: 18px;
              min-height: 18px;
              line-height: 18px; }
        #purchase-contract > .content .detail-form-container form table.simple tbody tr md-input-container {
          width: inherit;
          margin: 0; }
          #purchase-contract > .content .detail-form-container form table.simple tbody tr md-input-container .md-errors-spacer {
            min-height: 6px; }
      #purchase-contract > .content .detail-form-container form table.simple tbody tr:first-child td {
        padding-top: 15px !important; }
      #purchase-contract > .content .detail-form-container form md-input-container.supplier {
        margin-bottom: 42px; }
      #purchase-contract > .content .detail-form-container form md-input-container.currency {
        margin-bottom: 42px; }
      #purchase-contract > .content .detail-form-container form md-input-container.date {
        margin-top: 0px;
        margin-bottom: 23px; }
      #purchase-contract > .content .detail-form-container form md-input-container.text-area {
        margin-top: 3px;
        margin-bottom: 0px; }
      #purchase-contract > .content .detail-form-container form .header-col:last-child {
        margin-left: 15px; }
  #purchase-contract > .content .save-button {
    margin: 0; }

@media screen and (max-width: 600px) {
  #purchase-contract > .content .detail-form-container form .header-col:last-child {
    margin-left: 0 !important; } }

#purchasing-contracts {
  position: relative;
  height: auto;
  min-height: 100%; }
  #purchasing-contracts > .header {
    height: 90px;
    min-height: 90px;
    max-height: 90px; }
    #purchasing-contracts > .header .h1 md-icon {
      margin-right: 12px; }
    #purchasing-contracts > .header md-icon {
      color: #ffffff; }
    #purchasing-contracts > .header .records-count {
      margin: 6px 0 0 36px; }
    #purchasing-contracts > .header .search-input-wrapper {
      position: relative; }
      #purchasing-contracts > .header .search-input-wrapper label {
        padding-right: 4px;
        cursor: pointer; }
        #purchasing-contracts > .header .search-input-wrapper label md-icon {
          color: rgba(255, 255, 255, 0.8); }
      #purchasing-contracts > .header .search-input-wrapper md-input-container .md-errors-spacer {
        display: none; }
      #purchasing-contracts > .header .search-input-wrapper md-input-container input {
        border-bottom: 1px solid rgba(255, 255, 255, 0.8);
        color: rgba(255, 255, 255, 0.8);
        width: 240px; }
        #purchasing-contracts > .header .search-input-wrapper md-input-container input::placeholder {
          color: rgba(255, 255, 255, 0.8); }
        #purchasing-contracts > .header .search-input-wrapper md-input-container input:focus {
          border-bottom: 1px solid white;
          color: white; }
  #purchasing-contracts > .content {
    position: absolute;
    top: 90px;
    bottom: 0;
    right: 0;
    left: 0; }
    #purchasing-contracts > .content .main {
      padding: 0px 24px 0 24px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
      #purchasing-contracts > .content .main .data-list {
        position: relative;
        padding-bottom: 0; }
        #purchasing-contracts > .content .main .data-list .md-subheader {
          box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
          border-bottom: .1px solid #dadada; }
          #purchasing-contracts > .content .main .data-list .md-subheader .md-subheader-inner {
            padding: 12px 16px; }
            #purchasing-contracts > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content {
              font-size: 12px;
              font-weight: 900; }
              #purchasing-contracts > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:last-child {
                width: 60px;
                text-align: center; }
              #purchasing-contracts > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:hover {
                cursor: pointer;
                color: #636363; }
        #purchasing-contracts > .content .main .data-list .list-item {
          border-bottom: .1px solid #dadada; }
          #purchasing-contracts > .content .main .data-list .list-item span {
            padding-right: 6px; }
          #purchasing-contracts > .content .main .data-list .list-item .edit-button {
            width: 60px;
            margin: 0; }
      #purchasing-contracts > .content .main .no-results {
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14); }

@media screen and (max-width: 960px) {
  #purchasing-contracts > .header .h1 {
    font-size: 1.8rem; }
  #purchasing-contracts > .header .records-count {
    font-size: 1.1rem; }
  #purchasing-contracts > .content .main {
    padding: 0; }
    #purchasing-contracts > .content .main .data-list {
      padding-top: 0; } }

#purchase-dashboard > .center > .header {
  height: 136px;
  min-height: 136px;
  max-height: 136px;
  padding: 24px 0 24px 24px; }
  #purchase-dashboard > .center > .header .h1 md-icon {
    margin-right: 12px;
    color: #FFFFFF; }
  #purchase-dashboard > .center > .header .selected-project {
    background: rgba(0, 0, 0, 0.12);
    color: #FFFFFF;
    padding: 8px 16px;
    height: 40px;
    line-height: 24px;
    font-size: 16px; }
  #purchase-dashboard > .center > .header .project-selector {
    margin-left: 1px;
    border-radius: 0;
    background: rgba(0, 0, 0, 0.12); }
    #purchase-dashboard > .center > .header .project-selector md-icon {
      color: #FFFFFF; }

#purchase-dashboard > .center > .content {
  background: none;
  padding: 0; }
  #purchase-dashboard > .center > .content > md-tabs > md-tabs-wrapper {
    background: #FFFFFF;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
    padding: 0 24px; }
    #purchase-dashboard > .center > .content > md-tabs > md-tabs-wrapper md-tabs-canvas {
      height: 56px; }
      #purchase-dashboard > .center > .content > md-tabs > md-tabs-wrapper md-tabs-canvas md-pagination-wrapper {
        height: 56px; }
      #purchase-dashboard > .center > .content > md-tabs > md-tabs-wrapper md-tabs-canvas .md-tab {
        padding: 16px 24px;
        text-transform: none; }
  #purchase-dashboard > .center > .content > md-tabs:not(.md-dynamic-height) md-tabs-content-wrapper {
    top: 56px; }
  #purchase-dashboard > .center > .content > md-tabs > md-tabs-content-wrapper md-tab-content {
    padding: 12px; }

#purchase-dashboard > .sidenav {
  width: 330px;
  min-width: 330px;
  max-width: 330px;
  padding: 0;
  z-index: 51;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); }
  #purchase-dashboard > .sidenav.md-locked-open {
    width: 250px;
    min-width: 250px;
    max-width: 250px;
    z-index: 2;
    box-shadow: none;
    background: #EEEEEE; }

#supplier > .header {
  height: 120px;
  min-height: 120px;
  max-height: 120px; }
  #supplier > .header .goto-suppliers-button {
    margin-right: 16px; }
  #supplier > .header .subtitle {
    margin: 6px 0 0 0; }
  #supplier > .header .md-icon-button:hover {
    background-color: #f2f2f233; }
  #supplier > .header .md-icon-button md-icon {
    color: #fafafa; }
  #supplier > .header .md-icon-button[disabled] md-icon {
    color: #fafafa69; }

#supplier > .content {
  padding: 24px; }
  #supplier > .content .detail-form-container {
    padding: 24px;
    margin-bottom: 24px;
    max-width: 990px; }
    #supplier > .content .detail-form-container form {
      font-size: 12px !important; }
      #supplier > .content .detail-form-container form table.simple thead tr th {
        padding: 0 5px 0 5px; }
      #supplier > .content .detail-form-container form table.simple thead tr th.actions {
        text-align: center; }
      #supplier > .content .detail-form-container form table.simple tbody tr {
        padding-top: 0;
        padding-bottom: 0; }
        #supplier > .content .detail-form-container form table.simple tbody tr td {
          padding: 0 5px 0 5px;
          border-bottom: none; }
        #supplier > .content .detail-form-container form table.simple tbody tr td.actions {
          text-align: center; }
          #supplier > .content .detail-form-container form table.simple tbody tr td.actions .md-button {
            min-width: auto;
            min-height: auto;
            line-height: unset; }
            #supplier > .content .detail-form-container form table.simple tbody tr td.actions .md-button .icon-delete {
              font-size: 18px;
              width: 18px;
              height: 18px;
              min-width: 18px;
              min-height: 18px;
              line-height: 18px; }
        #supplier > .content .detail-form-container form table.simple tbody tr md-input-container {
          width: inherit;
          margin: 0; }
          #supplier > .content .detail-form-container form table.simple tbody tr md-input-container .md-errors-spacer {
            min-height: 6px; }
      #supplier > .content .detail-form-container form table.simple tbody tr:first-child td {
        padding-top: 15px !important; }
      #supplier > .content .detail-form-container form md-input-container {
        margin-bottom: 12px;
        margin-top: 12px; }
      #supplier > .content .detail-form-container form .header-col:last-child {
        margin-left: 15px; }
  #supplier > .content .save-button {
    margin: 0; }

@media screen and (max-width: 600px) {
  #supplier > .content .detail-form-container form .header-col:last-child {
    margin-left: 0 !important; } }

#purchasing-suppliers {
  position: relative;
  height: auto;
  min-height: 100%; }
  #purchasing-suppliers > .header {
    height: 90px;
    min-height: 90px;
    max-height: 90px; }
    #purchasing-suppliers > .header .h1 md-icon {
      margin-right: 12px; }
    #purchasing-suppliers > .header md-icon {
      color: #ffffff; }
    #purchasing-suppliers > .header .records-count {
      margin: 6px 0 0 36px; }
    #purchasing-suppliers > .header .search-input-wrapper {
      position: relative; }
      #purchasing-suppliers > .header .search-input-wrapper label {
        padding-right: 4px;
        cursor: pointer; }
        #purchasing-suppliers > .header .search-input-wrapper label md-icon {
          color: rgba(255, 255, 255, 0.8); }
      #purchasing-suppliers > .header .search-input-wrapper md-input-container .md-errors-spacer {
        display: none; }
      #purchasing-suppliers > .header .search-input-wrapper md-input-container input {
        border-bottom: 1px solid rgba(255, 255, 255, 0.8);
        color: rgba(255, 255, 255, 0.8);
        width: 240px; }
        #purchasing-suppliers > .header .search-input-wrapper md-input-container input::placeholder {
          color: rgba(255, 255, 255, 0.8); }
        #purchasing-suppliers > .header .search-input-wrapper md-input-container input:focus {
          border-bottom: 1px solid white;
          color: white; }
  #purchasing-suppliers > .content {
    position: absolute;
    top: 90px;
    bottom: 0;
    right: 0;
    left: 0; }
    #purchasing-suppliers > .content .main {
      padding: 0px 24px 0 24px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
      #purchasing-suppliers > .content .main .data-list {
        position: relative;
        padding-bottom: 0; }
        #purchasing-suppliers > .content .main .data-list .md-subheader {
          box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
          border-bottom: .1px solid #dadada; }
          #purchasing-suppliers > .content .main .data-list .md-subheader .md-subheader-inner {
            padding: 12px 16px; }
            #purchasing-suppliers > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content {
              font-size: 12px;
              font-weight: 900; }
              #purchasing-suppliers > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:last-child {
                width: 60px;
                text-align: center; }
              #purchasing-suppliers > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:hover {
                cursor: pointer;
                color: #636363; }
        #purchasing-suppliers > .content .main .data-list .list-item {
          border-bottom: .1px solid #dadada; }
          #purchasing-suppliers > .content .main .data-list .list-item span {
            padding-right: 6px; }
          #purchasing-suppliers > .content .main .data-list .list-item .edit-button {
            width: 60px;
            margin: 0; }
      #purchasing-suppliers > .content .main .no-results {
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14); }

@media screen and (max-width: 960px) {
  #purchasing-suppliers > .header .h1 {
    font-size: 1.8rem; }
  #purchasing-suppliers > .header .records-count {
    font-size: 1.1rem; }
  #purchasing-suppliers > .content .main {
    padding: 0; }
    #purchasing-suppliers > .content .main .data-list {
      padding-top: 0; } }

#purchasing-tobe-ordered {
  position: relative;
  height: auto;
  min-height: 100%; }
  #purchasing-tobe-ordered > .header {
    height: 90px;
    min-height: 90px;
    max-height: 90px; }
    #purchasing-tobe-ordered > .header .h1 md-icon {
      margin-right: 12px; }
    #purchasing-tobe-ordered > .header md-icon {
      color: #ffffff; }
    #purchasing-tobe-ordered > .header .records-count {
      margin: 6px 0 0 36px; }
    #purchasing-tobe-ordered > .header .search-input-wrapper {
      position: relative; }
      #purchasing-tobe-ordered > .header .search-input-wrapper label {
        padding-right: 4px;
        cursor: pointer; }
        #purchasing-tobe-ordered > .header .search-input-wrapper label md-icon {
          color: rgba(255, 255, 255, 0.8); }
      #purchasing-tobe-ordered > .header .search-input-wrapper md-input-container .md-errors-spacer {
        display: none; }
      #purchasing-tobe-ordered > .header .search-input-wrapper md-input-container input {
        border-bottom: 1px solid rgba(255, 255, 255, 0.8);
        color: rgba(255, 255, 255, 0.8);
        width: 240px; }
        #purchasing-tobe-ordered > .header .search-input-wrapper md-input-container input::placeholder {
          color: rgba(255, 255, 255, 0.8); }
        #purchasing-tobe-ordered > .header .search-input-wrapper md-input-container input:focus {
          border-bottom: 1px solid white;
          color: white; }
    #purchasing-tobe-ordered > .header.selected-bar {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 999;
      background-image: none;
      opacity: 1 !important; }
      #purchasing-tobe-ordered > .header.selected-bar .close-button-wrapper {
        width: 244px; }
  #purchasing-tobe-ordered > .content {
    position: absolute;
    top: 90px;
    bottom: 0;
    right: 0;
    left: 0; }
    #purchasing-tobe-ordered > .content .main {
      padding: 0px 24px 0 24px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
      #purchasing-tobe-ordered > .content .main .data-list {
        position: relative;
        padding-bottom: 0; }
        #purchasing-tobe-ordered > .content .main .data-list > .toolbar {
          padding: 0 12px 0 20px;
          position: absolute;
          width: 100%;
          z-index: 3;
          background-color: #ffffff; }
          #purchasing-tobe-ordered > .content .main .data-list > .toolbar .action-buttons .md-icon-button:hover {
            background-color: #efefef; }
        #purchasing-tobe-ordered > .content .main .data-list .md-subheader {
          box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
          border-bottom: .1px solid #dadada; }
          #purchasing-tobe-ordered > .content .main .data-list .md-subheader .md-subheader-inner {
            padding: 12px 16px; }
            #purchasing-tobe-ordered > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content {
              font-size: 12px;
              font-weight: 900; }
              #purchasing-tobe-ordered > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:first-child {
                width: 60px;
                text-align: center; }
              #purchasing-tobe-ordered > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:last-child {
                width: 60px;
                text-align: center; }
              #purchasing-tobe-ordered > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:hover {
                cursor: pointer;
                color: #636363; }
        #purchasing-tobe-ordered > .content .main .data-list .list-item {
          border-bottom: .1px solid #dadada; }
          #purchasing-tobe-ordered > .content .main .data-list .list-item:hover {
            cursor: initial; }
          #purchasing-tobe-ordered > .content .main .data-list .list-item span {
            padding-right: 6px; }
          #purchasing-tobe-ordered > .content .main .data-list .list-item .edit-button {
            width: 60px;
            margin: 0; }
      #purchasing-tobe-ordered > .content .main .no-results {
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14); }

@media screen and (max-width: 960px) {
  #purchasing-tobe-ordered > .header .h1 {
    font-size: 1.8rem; }
  #purchasing-tobe-ordered > .header .records-count {
    font-size: 1.1rem; }
  #purchasing-tobe-ordered > .content .main {
    padding: 0; }
    #purchasing-tobe-ordered > .content .main .data-list {
      padding-top: 0; } }

#purchase-invoices-add .sidenav.goods-receipts-sidenav, #purchase-invoices-edit .sidenav.goods-receipts-sidenav {
  width: 320px;
  min-width: 320px;
  max-width: 320px;
  padding: 0; }
  #purchase-invoices-add .sidenav.goods-receipts-sidenav._md-locked-open, #purchase-invoices-edit .sidenav.goods-receipts-sidenav._md-locked-open {
    width: 320px;
    min-width: 320px;
    max-width: 320px;
    overflow: hidden; }
  #purchase-invoices-add .sidenav.goods-receipts-sidenav .header, #purchase-invoices-edit .sidenav.goods-receipts-sidenav .header {
    height: 136px;
    min-height: 136px;
    max-height: 136px;
    padding: 24px 24px;
    background-color: #078aca; }
    #purchase-invoices-add .sidenav.goods-receipts-sidenav .header .title, #purchase-invoices-edit .sidenav.goods-receipts-sidenav .header .title {
      font-size: 18px;
      color: #a6cee2;
      margin-left: 15px; }
    #purchase-invoices-add .sidenav.goods-receipts-sidenav .header .status-icon md-icon, #purchase-invoices-edit .sidenav.goods-receipts-sidenav .header .status-icon md-icon {
      color: #ffffff; }
    #purchase-invoices-add .sidenav.goods-receipts-sidenav .header md-select, #purchase-invoices-edit .sidenav.goods-receipts-sidenav .header md-select {
      margin-left: 66px; }
      #purchase-invoices-add .sidenav.goods-receipts-sidenav .header md-select .md-select-value, #purchase-invoices-edit .sidenav.goods-receipts-sidenav .header md-select .md-select-value {
        color: #ffffffb3;
        border-bottom-color: #ffffffb3; }
  #purchase-invoices-add .sidenav.goods-receipts-sidenav .content, #purchase-invoices-edit .sidenav.goods-receipts-sidenav .content {
    position: relative;
    background: #FFFFFF;
    padding: 24px 0; }
    #purchase-invoices-add .sidenav.goods-receipts-sidenav .content ms-widget ms-widget-front .md-toolbar-tools, #purchase-invoices-edit .sidenav.goods-receipts-sidenav .content ms-widget ms-widget-front .md-toolbar-tools {
      height: 48px;
      max-height: 48px;
      background-color: #073c56; }
    #purchase-invoices-add .sidenav.goods-receipts-sidenav .content ms-widget ms-widget-front .mid-panel, #purchase-invoices-edit .sidenav.goods-receipts-sidenav .content ms-widget ms-widget-front .mid-panel {
      padding: 15px 9px; }
      #purchase-invoices-add .sidenav.goods-receipts-sidenav .content ms-widget ms-widget-front .mid-panel .secondary-text, #purchase-invoices-edit .sidenav.goods-receipts-sidenav .content ms-widget ms-widget-front .mid-panel .secondary-text {
        width: 90px;
        text-align: right; }

@media screen and (max-width: 960px) {
  #purchase-invoices-add .sidenav.goods-receipts-sidenav, #purchase-invoices-edit .sidenav.goods-receipts-sidenav {
    width: 240px;
    min-width: 240px;
    max-width: 240px;
    font-size: 11px; }
    #purchase-invoices-add .sidenav.goods-receipts-sidenav h3, #purchase-invoices-add .sidenav.goods-receipts-sidenav h4, #purchase-invoices-add .sidenav.goods-receipts-sidenav p, #purchase-invoices-edit .sidenav.goods-receipts-sidenav h3, #purchase-invoices-edit .sidenav.goods-receipts-sidenav h4, #purchase-invoices-edit .sidenav.goods-receipts-sidenav p {
      font-size: 11px !important; } }

#completed-request-approvals {
  position: relative;
  height: auto;
  min-height: 100%; }
  #completed-request-approvals > .header {
    height: 90px;
    min-height: 90px;
    max-height: 90px; }
    #completed-request-approvals > .header .h1 md-icon {
      margin-right: 12px; }
    #completed-request-approvals > .header md-icon {
      color: #ffffff; }
    #completed-request-approvals > .header .records-count {
      margin: 6px 0 0 36px; }
    #completed-request-approvals > .header .search-input-wrapper {
      position: relative; }
      #completed-request-approvals > .header .search-input-wrapper label {
        padding-right: 4px;
        cursor: pointer; }
        #completed-request-approvals > .header .search-input-wrapper label md-icon {
          color: rgba(255, 255, 255, 0.8); }
      #completed-request-approvals > .header .search-input-wrapper md-input-container .md-errors-spacer {
        display: none; }
      #completed-request-approvals > .header .search-input-wrapper md-input-container input {
        border-bottom: 1px solid rgba(255, 255, 255, 0.8);
        color: rgba(255, 255, 255, 0.8);
        width: 240px; }
        #completed-request-approvals > .header .search-input-wrapper md-input-container input::placeholder {
          color: rgba(255, 255, 255, 0.8); }
        #completed-request-approvals > .header .search-input-wrapper md-input-container input:focus {
          border-bottom: 1px solid white;
          color: white; }
  #completed-request-approvals > .content {
    position: absolute;
    top: 90px;
    bottom: 0;
    right: 0;
    left: 0; }
    #completed-request-approvals > .content .main {
      padding: 0px 24px 0 24px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
      #completed-request-approvals > .content .main .data-list {
        position: relative;
        padding-bottom: 0; }
        #completed-request-approvals > .content .main .data-list .md-subheader {
          box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
          border-bottom: .1px solid #dadada; }
          #completed-request-approvals > .content .main .data-list .md-subheader .md-subheader-inner {
            padding: 12px 16px; }
            #completed-request-approvals > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content {
              font-size: 12px;
              font-weight: 900; }
              #completed-request-approvals > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:last-child {
                width: 60px;
                text-align: center; }
              #completed-request-approvals > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:hover {
                cursor: pointer;
                color: #636363; }
        #completed-request-approvals > .content .main .data-list .list-item {
          border-bottom: .1px solid #dadada; }
          #completed-request-approvals > .content .main .data-list .list-item span {
            padding-right: 6px; }
          #completed-request-approvals > .content .main .data-list .list-item .edit-button {
            width: 60px;
            margin: 0; }
      #completed-request-approvals > .content .main .no-results {
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14); }

@media screen and (max-width: 960px) {
  #completed-request-approvals > .header .h1 {
    font-size: 1.8rem; }
  #completed-request-approvals > .header .records-count {
    font-size: 1.1rem; }
  #completed-request-approvals > .content .main {
    padding: 0; }
    #completed-request-approvals > .content .main .data-list {
      padding-top: 0; } }

#pending-request-approvals {
  position: relative;
  height: auto;
  min-height: 100%; }
  #pending-request-approvals > .header {
    height: 90px;
    min-height: 90px;
    max-height: 90px; }
    #pending-request-approvals > .header .h1 md-icon {
      margin-right: 12px; }
    #pending-request-approvals > .header md-icon {
      color: #ffffff; }
    #pending-request-approvals > .header .records-count {
      margin: 6px 0 0 36px; }
    #pending-request-approvals > .header .search-input-wrapper {
      position: relative; }
      #pending-request-approvals > .header .search-input-wrapper label {
        padding-right: 4px;
        cursor: pointer; }
        #pending-request-approvals > .header .search-input-wrapper label md-icon {
          color: rgba(255, 255, 255, 0.8); }
      #pending-request-approvals > .header .search-input-wrapper md-input-container .md-errors-spacer {
        display: none; }
      #pending-request-approvals > .header .search-input-wrapper md-input-container input {
        border-bottom: 1px solid rgba(255, 255, 255, 0.8);
        color: rgba(255, 255, 255, 0.8);
        width: 240px; }
        #pending-request-approvals > .header .search-input-wrapper md-input-container input::placeholder {
          color: rgba(255, 255, 255, 0.8); }
        #pending-request-approvals > .header .search-input-wrapper md-input-container input:focus {
          border-bottom: 1px solid white;
          color: white; }
    #pending-request-approvals > .header.selected-bar {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 999;
      background-image: none;
      opacity: 1 !important; }
      #pending-request-approvals > .header.selected-bar .close-button-wrapper {
        width: 244px; }
  #pending-request-approvals > .content {
    position: absolute;
    top: 90px;
    bottom: 0;
    right: 0;
    left: 0; }
    #pending-request-approvals > .content .main {
      padding: 0px 24px 0 24px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
      #pending-request-approvals > .content .main .data-list {
        position: relative;
        padding-bottom: 0; }
        #pending-request-approvals > .content .main .data-list > .toolbar {
          padding: 0 12px 0 20px;
          position: absolute;
          width: 100%;
          z-index: 3;
          background-color: #ffffff; }
          #pending-request-approvals > .content .main .data-list > .toolbar .action-buttons .md-icon-button:hover {
            background-color: #efefef; }
        #pending-request-approvals > .content .main .data-list .md-subheader {
          box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
          border-bottom: .1px solid #dadada; }
          #pending-request-approvals > .content .main .data-list .md-subheader .md-subheader-inner {
            padding: 12px 16px; }
            #pending-request-approvals > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content {
              font-size: 12px;
              font-weight: 900; }
              #pending-request-approvals > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:first-child {
                width: 60px;
                text-align: center; }
              #pending-request-approvals > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:last-child {
                width: 60px;
                text-align: center; }
              #pending-request-approvals > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:hover {
                cursor: pointer;
                color: #636363; }
        #pending-request-approvals > .content .main .data-list .list-item {
          border-bottom: .1px solid #dadada; }
          #pending-request-approvals > .content .main .data-list .list-item:hover {
            cursor: initial; }
          #pending-request-approvals > .content .main .data-list .list-item span {
            padding-right: 6px; }
          #pending-request-approvals > .content .main .data-list .list-item .edit-button {
            width: 60px;
            margin: 0; }
      #pending-request-approvals > .content .main .no-results {
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14); }

@media screen and (max-width: 960px) {
  #pending-request-approvals > .header .h1 {
    font-size: 1.8rem; }
  #pending-request-approvals > .header .records-count {
    font-size: 1.1rem; }
  #pending-request-approvals > .content .main {
    padding: 0; }
    #pending-request-approvals > .content .main .data-list {
      padding-top: 0; } }

md-dialog.account-user-dialog {
  max-width: 600px;
  width: 600px; }
  md-dialog.account-user-dialog md-toolbar {
    height: 60px;
    min-height: 60px;
    max-height: 60px; }
    md-dialog.account-user-dialog md-toolbar .title {
      font-size: 17px; }
  md-dialog.account-user-dialog md-dialog-content {
    display: block;
    position: relative; }
    md-dialog.account-user-dialog md-dialog-content .not-accepted span {
      margin-top: auto;
      margin-bottom: auto;
      text-transform: initial; }
    md-dialog.account-user-dialog md-dialog-content md-input-container {
      height: 58px;
      max-height: 58px;
      min-height: 58px; }
    md-dialog.account-user-dialog md-dialog-content fieldset {
      border-color: #f5f5f5;
      margin-top: 24px; }
      md-dialog.account-user-dialog md-dialog-content fieldset:first-of-type {
        margin-top: 0; }
      md-dialog.account-user-dialog md-dialog-content fieldset md-input-container {
        margin-bottom: 0; }
      md-dialog.account-user-dialog md-dialog-content fieldset .md-chips {
        box-shadow: 0 0px !important; }
        md-dialog.account-user-dialog md-dialog-content fieldset .md-chips .md-chip-input-container input {
          padding-left: 0 !important; }
      md-dialog.account-user-dialog md-dialog-content fieldset .md-chips-messages {
        color: #D50000;
        font-size: 12px;
        padding-left: 3px;
        position: relative;
        overflow: hidden; }
  md-dialog.account-user-dialog md-dialog-actions {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.12); }

md-dialog.branch-dialog {
  max-width: 600px;
  width: 600px; }
  md-dialog.branch-dialog md-toolbar {
    height: 60px;
    min-height: 60px;
    max-height: 60px; }
    md-dialog.branch-dialog md-toolbar .title {
      font-size: 17px; }
  md-dialog.branch-dialog md-dialog-content {
    display: block;
    position: relative; }
    md-dialog.branch-dialog md-dialog-content md-input-container {
      height: 58px;
      max-height: 58px;
      min-height: 58px; }
  md-dialog.branch-dialog md-dialog-actions {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.12); }

md-dialog.change-plan-dialog {
  max-width: 600px;
  width: 600px; }
  md-dialog.change-plan-dialog form {
    font-size: 14px; }
    md-dialog.change-plan-dialog form md-dialog-content ms-widget {
      width: 270px;
      max-width: 270px;
      min-width: 270px;
      margin-right: auto;
      margin-left: auto; }
    md-dialog.change-plan-dialog form md-input-container.text {
      margin: 3px 0; }
    md-dialog.change-plan-dialog form md-input-container.select {
      margin: 3px 6px; }
    md-dialog.change-plan-dialog form md-input-container.select:first-child {
      margin-left: 0px; }
    md-dialog.change-plan-dialog form md-input-container.select:last-child {
      margin-right: 0px; }
    md-dialog.change-plan-dialog form .cc-panel {
      padding: 18px 9px; }
    md-dialog.change-plan-dialog form .amount-panel {
      margin-top: 24px;
      margin-bottom: 24px; }
      md-dialog.change-plan-dialog form .amount-panel table.simple tbody tr td {
        padding: 9px 3px; }
      md-dialog.change-plan-dialog form .amount-panel table.simple tbody tr td:first-child {
        padding-left: 9px; }
      md-dialog.change-plan-dialog form .amount-panel table.simple tbody tr td:last-child {
        text-align: right;
        padding-right: 9px; }
      md-dialog.change-plan-dialog form .amount-panel p {
        font-size: 12px; }
    md-dialog.change-plan-dialog form table.simple {
      margin-top: 30px; }
      md-dialog.change-plan-dialog form table.simple thead tr th {
        padding: 8px; }
        md-dialog.change-plan-dialog form table.simple thead tr th:first-child {
          text-align: left; }
        md-dialog.change-plan-dialog form table.simple thead tr th:last-child {
          text-align: right; }

md-dialog.payment-method-dialog {
  max-width: 600px;
  width: 600px; }
  md-dialog.payment-method-dialog form {
    font-size: 14px; }
    md-dialog.payment-method-dialog form .logo-wrapper {
      margin-bottom: 15px;
      width: 210px; }
    md-dialog.payment-method-dialog form md-input-container.text {
      margin: 3px 0; }
    md-dialog.payment-method-dialog form md-input-container.select {
      margin: 3px 6px; }
    md-dialog.payment-method-dialog form md-input-container.select:first-child {
      margin-left: 0px; }
    md-dialog.payment-method-dialog form md-input-container.select:last-child {
      margin-right: 0px; }
    md-dialog.payment-method-dialog form .cc-panel {
      padding: 18px 9px; }

md-dialog.position-dialog {
  max-width: 600px;
  width: 600px; }
  md-dialog.position-dialog md-toolbar {
    height: 60px;
    min-height: 60px;
    max-height: 60px; }
    md-dialog.position-dialog md-toolbar .title {
      font-size: 17px; }
  md-dialog.position-dialog md-dialog-content {
    display: block;
    position: relative; }
    md-dialog.position-dialog md-dialog-content md-input-container {
      height: 58px;
      max-height: 58px;
      min-height: 58px; }
    md-dialog.position-dialog md-dialog-content fieldset {
      border-color: #f5f5f5;
      margin-top: 0; }
    md-dialog.position-dialog md-dialog-content > md-checkbox {
      margin-left: 3px;
      margin-top: 30px; }
  md-dialog.position-dialog md-dialog-actions {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.12); }

#process-bottomnav-add {
  padding: 0;
  min-height: 270px;
  height: 270px;
  background-color: #eeeeee; }
  #process-bottomnav-add .buttons-panel {
    position: absolute;
    top: -33px;
    right: 0px;
    width: 90px;
    background-color: #e4e4e4;
    padding: 6px; }
    #process-bottomnav-add .buttons-panel .btn-process {
      margin-right: 9px; }
    #process-bottomnav-add .buttons-panel .btn-process:hover {
      cursor: pointer; }
      #process-bottomnav-add .buttons-panel .btn-process:hover .icon-checkbox-marked-circle {
        color: green; }
      #process-bottomnav-add .buttons-panel .btn-process:hover .icon-cancel {
        color: #f44336; }
  #process-bottomnav-add .green {
    background-color: green; }
  #process-bottomnav-add .yellow {
    background-color: yellow; }
  #process-bottomnav-add .current-object-panel {
    width: 240px;
    min-width: 240px;
    max-width: 240px;
    padding: 6px 12px;
    border-right: 1px solid lightgray; }
    #process-bottomnav-add .current-object-panel .object-header {
      height: 48px;
      padding: 6px 3px; }
      #process-bottomnav-add .current-object-panel .object-header .icon-delete {
        color: darkgrey;
        float: right; }
      #process-bottomnav-add .current-object-panel .object-header .icon-delete:hover {
        background-color: #b1b1b1;
        color: #ffffff;
        cursor: pointer; }
    #process-bottomnav-add .current-object-panel .object-wrapper {
      border: 2px solid #e3ca4b;
      background-color: #fff7ae;
      background: -webkit-gradient(linear, left top, left bottom, from(#fff7ae), to(#eee79e));
      padding: 9px; }
    #process-bottomnav-add .current-object-panel p {
      color: #505050;
      font-weight: 300;
      font-size: 14px; }
  #process-bottomnav-add .process-panel {
    width: -webkit-fill-available; }
    #process-bottomnav-add .process-panel md-tabs {
      min-height: 180px; }
      #process-bottomnav-add .process-panel md-tabs md-tabs-wrapper {
        background-color: #e4e4e4; }
        #process-bottomnav-add .process-panel md-tabs md-tabs-wrapper md-tab-data .md-tab {
          text-transform: capitalize; }
        #process-bottomnav-add .process-panel md-tabs md-tabs-wrapper md-tabs-canvas md-tab-item:not([disabled]) {
          color: #505050;
          font-weight: 300;
          text-transform: capitalize; }
      #process-bottomnav-add .process-panel md-tabs md-tabs-content-wrapper md-tab-content md-content {
        background-color: #eeeeee;
        padding: 12px; }
        #process-bottomnav-add .process-panel md-tabs md-tabs-content-wrapper md-tab-content md-content .btn-add-process {
          background-color: #f44336; }
        #process-bottomnav-add .process-panel md-tabs md-tabs-content-wrapper md-tab-content md-content .condition-wrapper {
          background-color: #fff;
          padding: 18px 9px;
          height: 100%;
          width: 100%;
          text-align: center; }
          #process-bottomnav-add .process-panel md-tabs md-tabs-content-wrapper md-tab-content md-content .condition-wrapper .label {
            margin-top: 21px; }
          #process-bottomnav-add .process-panel md-tabs md-tabs-content-wrapper md-tab-content md-content .condition-wrapper i {
            background-color: #d69279;
            color: #fff;
            border-radius: 10%;
            padding: 6px;
            position: relative;
            top: 12px; }
        #process-bottomnav-add .process-panel md-tabs md-tabs-content-wrapper md-tab-content md-content .action-wrapper {
          background-color: #fff;
          padding: 18px 9px;
          height: 100%;
          width: 100%;
          text-align: center; }
          #process-bottomnav-add .process-panel md-tabs md-tabs-content-wrapper md-tab-content md-content .action-wrapper .label {
            margin-top: 21px; }
          #process-bottomnav-add .process-panel md-tabs md-tabs-content-wrapper md-tab-content md-content .action-wrapper i {
            background-color: #29b6f6;
            color: #fff;
            border-radius: 50%;
            padding: 6px;
            position: relative;
            top: 12px; }
        #process-bottomnav-add .process-panel md-tabs md-tabs-content-wrapper md-tab-content md-content .action-detail-wrapper {
          width: -webkit-fill-available;
          height: -webkit-fill-available;
          background-color: #fff;
          padding: 3px; }
          #process-bottomnav-add .process-panel md-tabs md-tabs-content-wrapper md-tab-content md-content .action-detail-wrapper md-input-container {
            width: -webkit-fill-available; }
            #process-bottomnav-add .process-panel md-tabs md-tabs-content-wrapper md-tab-content md-content .action-detail-wrapper md-input-container .md-icon {
              background-color: #23a8e8; }
        #process-bottomnav-add .process-panel md-tabs md-tabs-content-wrapper md-tab-content md-content .child-process-wrapper {
          background-color: #fff;
          padding: 18px 9px;
          height: 100%;
          width: 100%;
          text-align: center;
          display: flex; }
          #process-bottomnav-add .process-panel md-tabs md-tabs-content-wrapper md-tab-content md-content .child-process-wrapper .buttons-wrapper {
            position: absolute;
            top: -6px;
            right: 0;
            background-color: white; }
            #process-bottomnav-add .process-panel md-tabs md-tabs-content-wrapper md-tab-content md-content .child-process-wrapper .buttons-wrapper .icon-pencil {
              margin-right: 6px;
              color: darkgrey; }
            #process-bottomnav-add .process-panel md-tabs md-tabs-content-wrapper md-tab-content md-content .child-process-wrapper .buttons-wrapper .icon-delete {
              color: darkgrey; }
            #process-bottomnav-add .process-panel md-tabs md-tabs-content-wrapper md-tab-content md-content .child-process-wrapper .buttons-wrapper > div {
              width: 21px;
              padding: 3px; }
            #process-bottomnav-add .process-panel md-tabs md-tabs-content-wrapper md-tab-content md-content .child-process-wrapper .buttons-wrapper > div:first-child {
              border-right: 1px solid #e4e4e4; }
            #process-bottomnav-add .process-panel md-tabs md-tabs-content-wrapper md-tab-content md-content .child-process-wrapper .buttons-wrapper > div:hover {
              background-color: #b1b1b1;
              color: #ffffff;
              cursor: pointer; }
              #process-bottomnav-add .process-panel md-tabs md-tabs-content-wrapper md-tab-content md-content .child-process-wrapper .buttons-wrapper > div:hover .icon {
                color: #ffffff; }
          #process-bottomnav-add .process-panel md-tabs md-tabs-content-wrapper md-tab-content md-content .child-process-wrapper .label {
            margin-top: 21px;
            font-size: 12px; }
          #process-bottomnav-add .process-panel md-tabs md-tabs-content-wrapper md-tab-content md-content .child-process-wrapper .action i {
            background-color: #29b6f6;
            color: #fff;
            border-radius: 50%;
            padding: 6px;
            position: relative;
            top: 12px; }
          #process-bottomnav-add .process-panel md-tabs md-tabs-content-wrapper md-tab-content md-content .child-process-wrapper .condition i {
            background-color: #d69279;
            color: #fff;
            border-radius: 10%;
            padding: 6px;
            position: relative;
            top: 12px; }
        #process-bottomnav-add .process-panel md-tabs md-tabs-content-wrapper md-tab-content md-content .condition-wrapper:hover,
        #process-bottomnav-add .process-panel md-tabs md-tabs-content-wrapper md-tab-content md-content .action-wrapper:hover {
          background-color: #ebf4f9;
          cursor: pointer; }
        #process-bottomnav-add .process-panel md-tabs md-tabs-content-wrapper md-tab-content md-content .condition-wrapper.selected,
        #process-bottomnav-add .process-panel md-tabs md-tabs-content-wrapper md-tab-content md-content .action-wrapper.selected {
          border: 1.5px solid #29b6f6; }

.md-checkbox-enabled.md-default-theme[selected] .md-icon, .md-checkbox-enabled[selected] .md-icon {
  background-color: #23a8e8; }

#process-bottomnav-edit {
  padding: 0;
  min-height: 270px;
  height: 270px;
  background-color: #eeeeee; }
  #process-bottomnav-edit .buttons-panel {
    position: absolute;
    top: -33px;
    right: 0px;
    width: 90px;
    background-color: #e4e4e4;
    padding: 6px; }
    #process-bottomnav-edit .buttons-panel .btn-process {
      margin-right: 9px; }
    #process-bottomnav-edit .buttons-panel .btn-process:hover {
      cursor: pointer; }
      #process-bottomnav-edit .buttons-panel .btn-process:hover .icon-checkbox-marked-circle {
        color: green; }
      #process-bottomnav-edit .buttons-panel .btn-process:hover .icon-cancel {
        color: #f44336; }
  #process-bottomnav-edit .green {
    background-color: green; }
  #process-bottomnav-edit .yellow {
    background-color: yellow; }
  #process-bottomnav-edit .current-object-panel {
    width: 240px;
    min-width: 240px;
    max-width: 240px;
    padding: 6px 12px;
    border-right: 1px solid lightgray; }
    #process-bottomnav-edit .current-object-panel .object-header {
      height: 48px;
      padding: 6px 3px; }
      #process-bottomnav-edit .current-object-panel .object-header .icon-delete {
        color: darkgrey;
        float: right; }
      #process-bottomnav-edit .current-object-panel .object-header .icon-delete:hover {
        background-color: #b1b1b1;
        color: #ffffff;
        cursor: pointer; }
    #process-bottomnav-edit .current-object-panel .object-wrapper {
      border: 2px solid #e3ca4b;
      background-color: #fff7ae;
      background: -webkit-gradient(linear, left top, left bottom, from(#fff7ae), to(#eee79e));
      padding: 9px; }
    #process-bottomnav-edit .current-object-panel p {
      color: #505050;
      font-weight: 300;
      font-size: 14px; }
  #process-bottomnav-edit .process-panel {
    width: -webkit-fill-available; }
    #process-bottomnav-edit .process-panel md-tabs {
      min-height: 180px; }
      #process-bottomnav-edit .process-panel md-tabs md-tabs-wrapper {
        background-color: #e4e4e4; }
        #process-bottomnav-edit .process-panel md-tabs md-tabs-wrapper md-tab-data .md-tab {
          text-transform: capitalize; }
        #process-bottomnav-edit .process-panel md-tabs md-tabs-wrapper md-tabs-canvas md-tab-item:not([disabled]) {
          color: #505050;
          font-weight: 300;
          text-transform: capitalize; }
      #process-bottomnav-edit .process-panel md-tabs md-tabs-content-wrapper md-tab-content md-content {
        background-color: #eeeeee;
        padding: 12px; }
        #process-bottomnav-edit .process-panel md-tabs md-tabs-content-wrapper md-tab-content md-content .btn-add-process {
          background-color: #f44336; }
        #process-bottomnav-edit .process-panel md-tabs md-tabs-content-wrapper md-tab-content md-content .condition-wrapper {
          background-color: #fff;
          padding: 18px 9px;
          height: 100%;
          width: 100%;
          text-align: center; }
          #process-bottomnav-edit .process-panel md-tabs md-tabs-content-wrapper md-tab-content md-content .condition-wrapper .label {
            margin-top: 21px; }
          #process-bottomnav-edit .process-panel md-tabs md-tabs-content-wrapper md-tab-content md-content .condition-wrapper i {
            background-color: #d69279;
            color: #fff;
            border-radius: 10%;
            padding: 6px;
            position: relative;
            top: 12px; }
        #process-bottomnav-edit .process-panel md-tabs md-tabs-content-wrapper md-tab-content md-content .action-wrapper {
          background-color: #fff;
          padding: 18px 9px;
          height: 100%;
          width: 100%;
          text-align: center; }
          #process-bottomnav-edit .process-panel md-tabs md-tabs-content-wrapper md-tab-content md-content .action-wrapper .label {
            margin-top: 21px; }
          #process-bottomnav-edit .process-panel md-tabs md-tabs-content-wrapper md-tab-content md-content .action-wrapper i {
            background-color: #29b6f6;
            color: #fff;
            border-radius: 50%;
            padding: 6px;
            position: relative;
            top: 12px; }
        #process-bottomnav-edit .process-panel md-tabs md-tabs-content-wrapper md-tab-content md-content .action-detail-wrapper {
          width: -webkit-fill-available;
          height: -webkit-fill-available;
          background-color: #fff;
          padding: 3px; }
          #process-bottomnav-edit .process-panel md-tabs md-tabs-content-wrapper md-tab-content md-content .action-detail-wrapper md-input-container {
            width: -webkit-fill-available; }
            #process-bottomnav-edit .process-panel md-tabs md-tabs-content-wrapper md-tab-content md-content .action-detail-wrapper md-input-container .md-icon {
              background-color: #23a8e8; }
        #process-bottomnav-edit .process-panel md-tabs md-tabs-content-wrapper md-tab-content md-content .child-process-wrapper {
          background-color: #fff;
          padding: 18px 9px;
          height: 100%;
          width: 100%;
          text-align: center;
          display: flex; }
          #process-bottomnav-edit .process-panel md-tabs md-tabs-content-wrapper md-tab-content md-content .child-process-wrapper .buttons-wrapper {
            position: absolute;
            top: -6px;
            right: 0;
            background-color: white; }
            #process-bottomnav-edit .process-panel md-tabs md-tabs-content-wrapper md-tab-content md-content .child-process-wrapper .buttons-wrapper .icon-pencil {
              margin-right: 6px;
              color: darkgrey; }
            #process-bottomnav-edit .process-panel md-tabs md-tabs-content-wrapper md-tab-content md-content .child-process-wrapper .buttons-wrapper .icon-delete {
              color: darkgrey; }
            #process-bottomnav-edit .process-panel md-tabs md-tabs-content-wrapper md-tab-content md-content .child-process-wrapper .buttons-wrapper > div {
              width: 21px;
              padding: 3px; }
            #process-bottomnav-edit .process-panel md-tabs md-tabs-content-wrapper md-tab-content md-content .child-process-wrapper .buttons-wrapper > div:first-child {
              border-right: 1px solid #e4e4e4; }
            #process-bottomnav-edit .process-panel md-tabs md-tabs-content-wrapper md-tab-content md-content .child-process-wrapper .buttons-wrapper > div:hover {
              background-color: #b1b1b1;
              color: #ffffff;
              cursor: pointer; }
              #process-bottomnav-edit .process-panel md-tabs md-tabs-content-wrapper md-tab-content md-content .child-process-wrapper .buttons-wrapper > div:hover .icon {
                color: #ffffff; }
          #process-bottomnav-edit .process-panel md-tabs md-tabs-content-wrapper md-tab-content md-content .child-process-wrapper .label {
            margin-top: 21px;
            font-size: 12px; }
          #process-bottomnav-edit .process-panel md-tabs md-tabs-content-wrapper md-tab-content md-content .child-process-wrapper .action i {
            background-color: #29b6f6;
            color: #fff;
            border-radius: 50%;
            padding: 6px;
            position: relative;
            top: 12px; }
          #process-bottomnav-edit .process-panel md-tabs md-tabs-content-wrapper md-tab-content md-content .child-process-wrapper .condition i {
            background-color: #d69279;
            color: #fff;
            border-radius: 10%;
            padding: 6px;
            position: relative;
            top: 12px; }
        #process-bottomnav-edit .process-panel md-tabs md-tabs-content-wrapper md-tab-content md-content .condition-wrapper:hover,
        #process-bottomnav-edit .process-panel md-tabs md-tabs-content-wrapper md-tab-content md-content .action-wrapper:hover {
          background-color: #ebf4f9;
          cursor: pointer; }
        #process-bottomnav-edit .process-panel md-tabs md-tabs-content-wrapper md-tab-content md-content .condition-wrapper.selected,
        #process-bottomnav-edit .process-panel md-tabs md-tabs-content-wrapper md-tab-content md-content .action-wrapper.selected {
          border: 1.5px solid #29b6f6; }

.md-checkbox-enabled.md-default-theme[selected] .md-icon, .md-checkbox-enabled[selected] .md-icon {
  background-color: #23a8e8; }

#process-parameters-sidenav.sidenav {
  width: 320px;
  min-width: 320px;
  max-width: 320px;
  padding: 0; }
  #process-parameters-sidenav.sidenav._md-locked-open {
    width: 320px;
    min-width: 320px;
    max-width: 320px;
    overflow: hidden; }
  #process-parameters-sidenav.sidenav .header {
    height: 90px;
    min-height: 90px;
    max-height: 90px;
    padding: 24px 24px;
    background-color: #078aca; }
    #process-parameters-sidenav.sidenav .header .h1 .title {
      font-size: 18px;
      line-height: 18px; }
    #process-parameters-sidenav.sidenav .header .h1 md-icon {
      margin-right: 12px; }
    #process-parameters-sidenav.sidenav .header .item-name {
      margin: 6px 0 0 30px; }
  #process-parameters-sidenav.sidenav .content {
    position: relative;
    background: #FFFFFF;
    padding: 24px; }
    #process-parameters-sidenav.sidenav .content .widget-group > .ms-widget {
      padding-right: 0;
      padding-left: 0; }
      #process-parameters-sidenav.sidenav .content .widget-group > .ms-widget .ms-widget-front div.head .h3 {
        font-size: 15px; }
      #process-parameters-sidenav.sidenav .content .widget-group > .ms-widget .ms-widget-front div.stats .count {
        font-size: 27px;
        line-height: 27px; }
      #process-parameters-sidenav.sidenav .content .widget-group > .ms-widget .ms-widget-front div.stats .unit {
        font-size: 1.2rem;
        padding-left: 3px; }

@media screen and (max-width: 960px) {
  #process-parameters-sidenav.sidenav.sidenav {
    width: 240px;
    min-width: 240px;
    max-width: 240px;
    font-size: 11px; }
    #process-parameters-sidenav.sidenav.sidenav h3, #process-parameters-sidenav.sidenav.sidenav h4, #process-parameters-sidenav.sidenav.sidenav p {
      font-size: 11px !important; } }

#account-settings > .header {
  height: 120px;
  min-height: 120px;
  max-height: 120px; }
  #account-settings > .header .h1 md-icon {
    margin-right: 12px;
    color: #ffffff; }
  #account-settings > .header .md-icon-button:hover {
    background-color: #f2f2f233; }
  #account-settings > .header .md-icon-button md-icon {
    color: #fafafa; }
  #account-settings > .header .md-icon-button[disabled] md-icon {
    color: #fafafa69; }

#account-settings > .content {
  padding: 24px; }
  #account-settings > .content > .widget-group {
    max-width: 990px; }
    #account-settings > .content > .widget-group .ms-widget:first-child {
      padding: 0 12px 12px 0; }
      #account-settings > .content > .widget-group .ms-widget:first-child .change-plan span {
        text-transform: initial; }
    #account-settings > .content > .widget-group .ms-widget:last-child {
      padding: 0 0 12px 12px; }
    #account-settings > .content > .widget-group .ms-widget .payment-method-bar .md-button {
      text-transform: inherit;
      font-size: 1.3rem; }
  #account-settings > .content .form-container {
    padding: 24px;
    margin-bottom: 24px;
    max-width: 990px; }
    #account-settings > .content .form-container form {
      font-size: 12px !important; }

@media screen and (max-width: 600px) {
  #account-settings > .content > .widget-group .ms-widget:first-child {
    padding: 0 0 12px 0; }
  #account-settings > .content > .widget-group .ms-widget:last-child {
    padding: 0 0 12px 0; } }

#settings-account-users {
  position: relative;
  height: auto;
  min-height: 100%; }
  #settings-account-users > .header {
    height: 120px;
    min-height: 120px;
    max-height: 120px;
    padding: 6px 24px 24px 24px; }
    #settings-account-users > .header .h1 md-icon {
      margin-right: 12px; }
    #settings-account-users > .header md-icon {
      color: #ffffff; }
    #settings-account-users > .header .records-count {
      margin: 6px 0 0 36px; }
    #settings-account-users > .header .search-input-wrapper {
      position: relative; }
      #settings-account-users > .header .search-input-wrapper label {
        padding-right: 4px;
        cursor: pointer; }
        #settings-account-users > .header .search-input-wrapper label md-icon {
          color: rgba(255, 255, 255, 0.8); }
      #settings-account-users > .header .search-input-wrapper md-input-container .md-errors-spacer {
        display: none; }
      #settings-account-users > .header .search-input-wrapper md-input-container input {
        border-bottom: 1px solid rgba(255, 255, 255, 0.8);
        color: rgba(255, 255, 255, 0.8);
        width: 240px; }
        #settings-account-users > .header .search-input-wrapper md-input-container input::placeholder {
          color: rgba(255, 255, 255, 0.8); }
        #settings-account-users > .header .search-input-wrapper md-input-container input:focus {
          border-bottom: 1px solid white;
          color: white; }
  #settings-account-users > .content {
    position: absolute;
    top: 90px;
    bottom: 0;
    right: 0;
    left: 0; }
    #settings-account-users > .content .main {
      padding: 0px 24px 0 24px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
      #settings-account-users > .content .main .selected-type {
        background: rgba(0, 0, 0, 0.12);
        color: #FFFFFF;
        padding: 6px 16px;
        height: 30px;
        line-height: 18px;
        font-size: 12px; }
      #settings-account-users > .content .main .type-selector {
        margin-left: 1px;
        border-radius: 0;
        background: rgba(0, 0, 0, 0.12);
        min-height: 30px;
        height: 30px;
        min-width: 0;
        line-height: 18px;
        padding: 6px;
        width: 30px; }
        #settings-account-users > .content .main .type-selector md-icon {
          color: #FFFFFF;
          font-size: 18px;
          width: 18px;
          height: 18px;
          min-width: 18px;
          min-height: 18px;
          line-height: 18px; }
      #settings-account-users > .content .main .data-list {
        position: relative;
        padding-bottom: 0; }
        #settings-account-users > .content .main .data-list .md-subheader {
          box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
          border-bottom: .1px solid #dadada; }
          #settings-account-users > .content .main .data-list .md-subheader .md-subheader-inner {
            padding: 12px 16px; }
            #settings-account-users > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content {
              font-size: 12px;
              font-weight: 900; }
              #settings-account-users > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:last-child {
                width: 60px;
                text-align: center; }
              #settings-account-users > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:hover {
                cursor: pointer;
                color: #636363; }
        #settings-account-users > .content .main .data-list .list-item {
          border-bottom: .1px solid #dadada; }
          #settings-account-users > .content .main .data-list .list-item span {
            padding-right: 6px; }
          #settings-account-users > .content .main .data-list .list-item .edit-button {
            width: 60px;
            margin: 0; }
      #settings-account-users > .content .main .no-results {
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14); }

@media screen and (max-width: 960px) {
  #settings-account-users > .header .h1 {
    font-size: 1.8rem; }
  #settings-account-users > .header .records-count {
    font-size: 1.1rem; }
  #settings-account-users > .content .main {
    padding: 0; }
    #settings-account-users > .content .main .data-list {
      padding-top: 0; } }

#settings-branches {
  position: relative;
  height: auto;
  min-height: 100%; }
  #settings-branches > .header {
    height: 90px;
    min-height: 90px;
    max-height: 90px; }
    #settings-branches > .header .h1 md-icon {
      margin-right: 12px; }
    #settings-branches > .header md-icon {
      color: #ffffff; }
    #settings-branches > .header .records-count {
      margin: 6px 0 0 36px; }
    #settings-branches > .header .search-input-wrapper {
      position: relative; }
      #settings-branches > .header .search-input-wrapper label {
        padding-right: 4px;
        cursor: pointer; }
        #settings-branches > .header .search-input-wrapper label md-icon {
          color: rgba(255, 255, 255, 0.8); }
      #settings-branches > .header .search-input-wrapper md-input-container .md-errors-spacer {
        display: none; }
      #settings-branches > .header .search-input-wrapper md-input-container input {
        border-bottom: 1px solid rgba(255, 255, 255, 0.8);
        color: rgba(255, 255, 255, 0.8);
        width: 240px; }
        #settings-branches > .header .search-input-wrapper md-input-container input::placeholder {
          color: rgba(255, 255, 255, 0.8); }
        #settings-branches > .header .search-input-wrapper md-input-container input:focus {
          border-bottom: 1px solid white;
          color: white; }
  #settings-branches > .content {
    position: absolute;
    top: 90px;
    bottom: 0;
    right: 0;
    left: 0; }
    #settings-branches > .content .main {
      padding: 0px 24px 0 24px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
      #settings-branches > .content .main .data-list {
        position: relative;
        padding-bottom: 0; }
        #settings-branches > .content .main .data-list .md-subheader {
          box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
          border-bottom: .1px solid #dadada; }
          #settings-branches > .content .main .data-list .md-subheader .md-subheader-inner {
            padding: 12px 16px; }
            #settings-branches > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content {
              font-size: 12px;
              font-weight: 900; }
              #settings-branches > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:last-child {
                width: 60px;
                text-align: center; }
              #settings-branches > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:hover {
                cursor: pointer;
                color: #636363; }
        #settings-branches > .content .main .data-list .list-item {
          border-bottom: .1px solid #dadada; }
          #settings-branches > .content .main .data-list .list-item span {
            padding-right: 6px; }
          #settings-branches > .content .main .data-list .list-item .edit-button {
            width: 60px;
            margin: 0; }
      #settings-branches > .content .main .no-results {
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14); }

@media screen and (max-width: 960px) {
  #settings-branches > .header .h1 {
    font-size: 1.8rem; }
  #settings-branches > .header .records-count {
    font-size: 1.1rem; }
  #settings-branches > .content .main {
    padding: 0; }
    #settings-branches > .content .main .data-list {
      padding-top: 0; } }

#change-plan > .header {
  height: 120px;
  min-height: 120px;
  max-height: 120px; }
  #change-plan > .header .goto-purchase-contracts-button {
    margin-right: 16px; }
  #change-plan > .header .subtitle {
    margin: 6px 0 0 0; }
  #change-plan > .header .md-icon-button:hover {
    background-color: #f2f2f233; }
  #change-plan > .header .md-icon-button md-icon {
    color: #fafafa; }
  #change-plan > .header .md-icon-button[disabled] md-icon {
    color: #fafafa69; }

#change-plan > .content {
  padding: 24px; }
  #change-plan > .content > .widget-group {
    max-width: 990px; }
    #change-plan > .content > .widget-group .md-button {
      text-transform: inherit;
      font-size: 1.3rem; }
    #change-plan > .content > .widget-group .ms-widget:first-child {
      padding: 0 12px 12px 0; }
    #change-plan > .content > .widget-group .ms-widget:last-child {
      padding: 0 0 12px 12px; }
  #change-plan > .content .form-container {
    margin-bottom: 24px;
    max-width: 990px; }
    #change-plan > .content .form-container md-slider-container > :first-child:not(md-slider) {
      margin-right: 42px; }
    #change-plan > .content .form-container md-slider-container md-input-container {
      max-width: 90px;
      height: auto; }
      #change-plan > .content .form-container md-slider-container md-input-container input {
        font-size: 24px; }
    #change-plan > .content .form-container .md-button {
      text-transform: inherit; }
    #change-plan > .content .form-container ul.price-table {
      list-style: none;
      background: #fff;
      margin-top: 12px;
      border: .9px solid #e1e1e6;
      border-radius: 4px;
      box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
      padding: 0;
      width: 300px; }
      #change-plan > .content .form-container ul.price-table li {
        padding: 21px 15px;
        margin: 0;
        border-top: .9px solid #e1e1e6; }
        #change-plan > .content .form-container ul.price-table li:first-child {
          border-top: none; }
        #change-plan > .content .form-container ul.price-table li .inner-row {
          display: flex;
          flex-direction: row; }
          #change-plan > .content .form-container ul.price-table li .inner-row .inner-col {
            flex: 50%;
            -webkit-box-flex: 50%;
            -ms-flex: 50%;
            text-align: left; }
            #change-plan > .content .form-container ul.price-table li .inner-row .inner-col .name {
              font-size: 16px;
              font-weight: 600;
              color: #333d66; }
            #change-plan > .content .form-container ul.price-table li .inner-row .inner-col .desc {
              padding-top: 6px;
              margin: 0;
              color: #a3a3a7; }
            #change-plan > .content .form-container ul.price-table li .inner-row .inner-col .price {
              color: #7b7e8b;
              font-size: 15px;
              font-weight: 600; }
            #change-plan > .content .form-container ul.price-table li .inner-row .inner-col .per-user {
              padding-top: 6px;
              margin: 0;
              color: #a3a3a7; }
    #change-plan > .content .form-container ul.details {
      list-style: none;
      margin-top: 12px;
      padding: 21px 0;
      margin-right: auto;
      margin-left: auto;
      width: auto; }
      #change-plan > .content .form-container ul.details li {
        margin: 21px 0; }
        #change-plan > .content .form-container ul.details li .layout-row {
          justify-content: left;
          text-align: left; }
          #change-plan > .content .form-container ul.details li .layout-row .layout-column {
            text-align: left;
            margin: 0;
            padding: 0 18px 0 0; }
            #change-plan > .content .form-container ul.details li .layout-row .layout-column:first-child {
              width: 60px;
              margin: auto 0; }
            #change-plan > .content .form-container ul.details li .layout-row .layout-column md-icon {
              color: #039be5; }
            #change-plan > .content .form-container ul.details li .layout-row .layout-column h3 {
              margin: 0;
              font-size: 21px;
              font-weight: 500;
              color: #333d66; }
            #change-plan > .content .form-container ul.details li .layout-row .layout-column p {
              color: #7b7e8b;
              font-size: 15px; }
    #change-plan > .content .form-container .calc-wrapper {
      background: #fff;
      margin-top: 12px;
      border: .9px solid #e1e1e6;
      border-radius: 4px;
      -webkit-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
      box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
      padding: 15px;
      margin-right: auto;
      margin-left: auto;
      width: 600px; }
      #change-plan > .content .form-container .calc-wrapper h3 {
        font-size: 18px;
        padding: 6px;
        margin: 0; }
      #change-plan > .content .form-container .calc-wrapper .row {
        padding: 15px 0; }
        #change-plan > .content .form-container .calc-wrapper .row .col {
          padding: 0 15px;
          flex: 50; }
          #change-plan > .content .form-container .calc-wrapper .row .col input {
            padding: 6px;
            font-size: 15px;
            text-align: center;
            border: .6px solid #cecccc;
            border-radius: 9px; }
            #change-plan > .content .form-container .calc-wrapper .row .col input[disabled] {
              background-color: #f1f1ef; }
          #change-plan > .content .form-container .calc-wrapper .row .col .cta-button {
            font-size: 15px;
            line-height: 15px;
            padding: 12px 30px; }

@media screen and (max-width: 600px) {
  #change-plan > .content > .widget-group .ms-widget:first-child {
    padding: 0 0 12px 0; }
  #change-plan > .content > .widget-group .ms-widget:last-child {
    padding: 0 0 12px 0; } }

#company > .header {
  height: 120px;
  min-height: 120px;
  max-height: 120px; }
  #company > .header .h1 md-icon {
    margin-right: 12px;
    color: #ffffff; }
  #company > .header .md-icon-button:hover {
    background-color: #f2f2f233; }
  #company > .header .md-icon-button md-icon {
    color: #fafafa; }
  #company > .header .md-icon-button[disabled] md-icon {
    color: #fafafa69; }

#company > .content {
  padding: 24px; }
  #company > .content .form-container {
    padding: 24px;
    margin-bottom: 24px;
    max-width: 990px; }
    #company > .content .form-container form {
      font-size: 12px !important; }

#organization-chart {
  position: relative;
  height: auto;
  min-height: 100%; }
  #organization-chart > .header {
    height: 90px;
    min-height: 90px;
    max-height: 90px; }
    #organization-chart > .header .h1 md-icon {
      margin-right: 12px; }
    #organization-chart > .header md-icon {
      color: #ffffff; }
    #organization-chart > .header .records-count {
      margin: 6px 0 0 36px; }
    #organization-chart > .header .search-input-wrapper {
      position: relative; }
      #organization-chart > .header .search-input-wrapper label {
        padding-right: 4px;
        cursor: pointer; }
        #organization-chart > .header .search-input-wrapper label md-icon {
          color: rgba(255, 255, 255, 0.8); }
      #organization-chart > .header .search-input-wrapper md-input-container .md-errors-spacer {
        display: none; }
      #organization-chart > .header .search-input-wrapper md-input-container input {
        border-bottom: 1px solid rgba(255, 255, 255, 0.8);
        color: rgba(255, 255, 255, 0.8);
        width: 240px; }
        #organization-chart > .header .search-input-wrapper md-input-container input::placeholder {
          color: rgba(255, 255, 255, 0.8); }
        #organization-chart > .header .search-input-wrapper md-input-container input:focus {
          border-bottom: 1px solid white;
          color: white; }
  #organization-chart > .content {
    position: absolute;
    top: 90px;
    bottom: 0;
    right: 0;
    left: 0; }
    #organization-chart > .content .main {
      padding: 0px 24px 0 24px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
      #organization-chart > .content .main .data-list {
        position: relative;
        padding-bottom: 0; }
        #organization-chart > .content .main .data-list .list-item {
          padding: 0 45px; }
          #organization-chart > .content .main .data-list .list-item:hover {
            cursor: initial; }
          #organization-chart > .content .main .data-list .list-item .node {
            height: 48px; }
            #organization-chart > .content .main .data-list .list-item .node .btn-expand-wrapper .expand-button, #organization-chart > .content .main .data-list .list-item .node .btn-expand-wrapper .collapse-button {
              margin: 0;
              padding: 9px;
              width: auto;
              height: auto; }
            #organization-chart > .content .main .data-list .list-item .node .btn-expand-wrapper .no-button {
              width: 42px;
              min-width: 42px;
              max-width: 42px;
              display: flex; }
            #organization-chart > .content .main .data-list .list-item .node .pos-btn {
              border-radius: 9px;
              text-transform: initial;
              width: 210px;
              text-align: left;
              padding-left: 12px; }
              #organization-chart > .content .main .data-list .list-item .node .pos-btn > span.inactive {
                color: #a7a7a7; }
            #organization-chart > .content .main .data-list .list-item .node .name {
              padding-left: 3px; }
      #organization-chart > .content .main .no-results {
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14); }

@media screen and (max-width: 960px) {
  #organization-chart .btn-expand-wrapper {
    width: 66px; }
  #organization-chart > .header .h1 {
    font-size: 1.8rem; }
  #organization-chart > .header .records-count {
    font-size: 1.1rem; }
  #organization-chart > .content .main {
    padding: 0; }
    #organization-chart > .content .main .btn {
      margin-right: 8px; } }

#parameters > .header {
  height: 120px;
  min-height: 120px;
  max-height: 120px; }
  #parameters > .header .h1 md-icon {
    margin-right: 12px;
    color: #ffffff; }

#payment-method > .header {
  height: 120px;
  min-height: 120px;
  max-height: 120px; }
  #payment-method > .header .goto-purchase-contracts-button {
    margin-right: 16px; }
  #payment-method > .header .subtitle {
    margin: 6px 0 0 0; }
  #payment-method > .header .md-icon-button:hover {
    background-color: #f2f2f233; }
  #payment-method > .header .md-icon-button md-icon {
    color: #fafafa; }
  #payment-method > .header .md-icon-button[disabled] md-icon {
    color: #fafafa69; }

#payment-method > .content {
  padding: 24px; }
  #payment-method > .content > .widget-group .md-button {
    text-transform: inherit;
    font-size: 1.3rem; }
  #payment-method > .content > .widget-group .ms-widget {
    padding: 12px; }
  #payment-method > .content .form-container {
    margin-bottom: 24px; }
    #payment-method > .content .form-container .md-button {
      text-transform: inherit; }

@media screen and (max-width: 600px) {
  #payment-method > .content {
    padding: 0; }
    #payment-method > .content > .widget-group .ms-widget {
      padding: 12px; }
      #payment-method > .content > .widget-group .ms-widget .cc-number-mask {
        font-size: 24px; } }

#process md-content .center .header {
  padding: 24px 0; }
  #process md-content .center .header .sidenav-toggle {
    margin: 0;
    width: 56px;
    height: 56px;
    background: #FFFFFF;
    border-radius: 0;
    border-right: 1px solid rgba(0, 0, 0, 0.12); }

#process md-content .center .toolbar md-icon.warehouse-object {
  color: #fcf4ab; }

#process md-content .center .content-card .toolbar {
  padding: 8px 16px;
  position: relative; }
  #process md-content .center .content-card .toolbar .process-status {
    margin-right: 50px;
    margin-left: 20px;
    font-size: 16px; }

#process md-content .center .content-card .content {
  padding: 0 30px;
  overflow: hidden;
  min-height: 1000px;
  min-width: calc(100vw);
  font-family: sans-serif; }
  #process md-content .center .content-card .content #chart_div .google-visualization-orgchart-connrow-medium {
    height: 25px; }
  #process md-content .center .content-card .content #chart_div .google-visualization-orgchart-node-medium {
    min-width: 111px; }
  #process md-content .center .content-card .content #chart_div .approval-option {
    position: relative;
    top: -25px;
    background-color: #fff;
    padding: 0px; }
  #process md-content .center .content-card .content #chart_div .approval-node .user-container {
    display: flex;
    width: max-content; }
    #process md-content .center .content-card .content #chart_div .approval-node .user-container img {
      height: 40px;
      border-radius: 50%; }
    #process md-content .center .content-card .content #chart_div .approval-node .user-container .user-name {
      padding-left: 10px; }
  #process md-content .center .content-card .content #chart_div .termination-node {
    font-weight: 600; }
  #process md-content .center .content-card .content #chart_div .node-buttons-wrapper {
    display: none;
    position: relative;
    left: -27px;
    width: 45px;
    top: -36px;
    height: 0; }
    #process md-content .center .content-card .content #chart_div .node-buttons-wrapper .icon-pencil {
      color: darkgrey; }
    #process md-content .center .content-card .content #chart_div .node-buttons-wrapper .icon-delete {
      color: darkgrey; }
    #process md-content .center .content-card .content #chart_div .node-buttons-wrapper > div {
      width: 21px;
      /* padding: 3px; */
      background-color: #ffffff;
      border: 1.5px solid #e3ca4b;
      border-radius: 3px;
      margin-bottom: 1px; }
    #process md-content .center .content-card .content #chart_div .node-buttons-wrapper > div:hover {
      background-color: #e3ca4b;
      color: #ffffff;
      cursor: pointer; }
      #process md-content .center .content-card .content #chart_div .node-buttons-wrapper > div:hover .icon {
        color: #ffffff; }
  #process md-content .center .content-card .content .node-buttons-wrapper2 {
    position: absolute;
    display: inline-flex; }
    #process md-content .center .content-card .content .node-buttons-wrapper2 .icon-pencil {
      color: darkgrey; }
    #process md-content .center .content-card .content .node-buttons-wrapper2 .icon-delete {
      color: darkgrey; }
    #process md-content .center .content-card .content .node-buttons-wrapper2 > div {
      padding: 1px 3px;
      background-color: #ffffff;
      border: 1.5px solid #e3ca4b;
      border-radius: 3px;
      margin-bottom: 1px; }
    #process md-content .center .content-card .content .node-buttons-wrapper2 > div:hover {
      background-color: #e3ca4b;
      color: #ffffff;
      cursor: pointer; }
      #process md-content .center .content-card .content .node-buttons-wrapper2 > div:hover .icon {
        color: #ffffff; }

#process .options-button {
  position: absolute;
  top: 160px;
  right: 0;
  z-index: 50;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  width: 48px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  cursor: pointer;
  border-radius: 0;
  margin: 0;
  pointer-events: auto;
  opacity: 0.75; }

@media only screen and (min-width: 960px) {
  #process md-content .center .content-card .content {
    min-height: 1000px;
    min-width: calc(100vw - 240px); } }

#profile md-dialog.upload-bg-dialog {
  max-width: 700px;
  width: 700px;
  height: 500px;
  max-height: 500px; }
  #profile md-dialog.upload-bg-dialog md-toolbar {
    height: 50px;
    min-height: 50px;
    max-height: 50px; }
    #profile md-dialog.upload-bg-dialog md-toolbar .title {
      font-size: 17px; }
  #profile md-dialog.upload-bg-dialog md-dialog-content {
    display: block;
    position: relative; }
    #profile md-dialog.upload-bg-dialog md-dialog-content .cropArea {
      background: #E4E4E4;
      overflow: hidden;
      width: 600px;
      height: 300px; }
  #profile md-dialog.upload-bg-dialog md-dialog-actions {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.12); }

#profile md-dialog.upload-picture-dialog {
  max-width: 500px;
  width: 500px; }
  #profile md-dialog.upload-picture-dialog md-toolbar {
    height: 50px;
    min-height: 50px;
    max-height: 50px; }
    #profile md-dialog.upload-picture-dialog md-toolbar .title {
      font-size: 17px; }
  #profile md-dialog.upload-picture-dialog md-dialog-content {
    display: block;
    position: relative; }
    #profile md-dialog.upload-picture-dialog md-dialog-content .cropArea {
      background: #E4E4E4;
      overflow: hidden;
      width: 450px;
      height: 350px; }
  #profile md-dialog.upload-picture-dialog md-dialog-actions {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.12); }

#profile .about-tab {
  max-width: 1200px; }
  #profile .about-tab .profile-about-container {
    padding: 16px 0px 0px 24px; }

#profile .account-tab {
  max-width: 1200px; }
  #profile .account-tab .profile-account-container {
    padding: 16px 0px 0px 24px; }
    #profile .account-tab .profile-account-container .error-msg {
      font-size: 12px;
      color: #d50000; }

#inventory-completed-requests {
  position: relative;
  height: auto;
  min-height: 100%; }
  #inventory-completed-requests > .header {
    height: 90px;
    min-height: 90px;
    max-height: 90px; }
    #inventory-completed-requests > .header .h1 md-icon {
      margin-right: 12px; }
    #inventory-completed-requests > .header md-icon {
      color: #ffffff; }
    #inventory-completed-requests > .header .records-count {
      margin: 6px 0 0 36px; }
    #inventory-completed-requests > .header .search-input-wrapper {
      position: relative; }
      #inventory-completed-requests > .header .search-input-wrapper label {
        padding-right: 4px;
        cursor: pointer; }
        #inventory-completed-requests > .header .search-input-wrapper label md-icon {
          color: rgba(255, 255, 255, 0.8); }
      #inventory-completed-requests > .header .search-input-wrapper md-input-container .md-errors-spacer {
        display: none; }
      #inventory-completed-requests > .header .search-input-wrapper md-input-container input {
        border-bottom: 1px solid rgba(255, 255, 255, 0.8);
        color: rgba(255, 255, 255, 0.8);
        width: 240px; }
        #inventory-completed-requests > .header .search-input-wrapper md-input-container input::placeholder {
          color: rgba(255, 255, 255, 0.8); }
        #inventory-completed-requests > .header .search-input-wrapper md-input-container input:focus {
          border-bottom: 1px solid white;
          color: white; }
  #inventory-completed-requests > .content {
    position: absolute;
    top: 90px;
    bottom: 0;
    right: 0;
    left: 0; }
    #inventory-completed-requests > .content .main {
      padding: 0px 24px 0 24px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
      #inventory-completed-requests > .content .main .data-list {
        position: relative;
        padding-bottom: 0; }
        #inventory-completed-requests > .content .main .data-list .md-subheader {
          box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
          border-bottom: .1px solid #dadada; }
          #inventory-completed-requests > .content .main .data-list .md-subheader .md-subheader-inner {
            padding: 12px 16px; }
            #inventory-completed-requests > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content {
              font-size: 12px;
              font-weight: 900; }
              #inventory-completed-requests > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:last-child {
                width: 60px;
                text-align: center; }
              #inventory-completed-requests > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:hover {
                cursor: pointer;
                color: #636363; }
        #inventory-completed-requests > .content .main .data-list .list-item {
          border-bottom: .1px solid #dadada; }
          #inventory-completed-requests > .content .main .data-list .list-item span {
            padding-right: 6px; }
          #inventory-completed-requests > .content .main .data-list .list-item .edit-button {
            width: 60px;
            margin: 0; }
      #inventory-completed-requests > .content .main .no-results {
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14); }

@media screen and (max-width: 960px) {
  #inventory-completed-requests > .header .h1 {
    font-size: 1.8rem; }
  #inventory-completed-requests > .header .records-count {
    font-size: 1.1rem; }
  #inventory-completed-requests > .content .main {
    padding: 0; }
    #inventory-completed-requests > .content .main .data-list {
      padding-top: 0; } }

#inventory-inprogress-requests {
  position: relative;
  height: auto;
  min-height: 100%; }
  #inventory-inprogress-requests > .header {
    height: 90px;
    min-height: 90px;
    max-height: 90px; }
    #inventory-inprogress-requests > .header .h1 md-icon {
      margin-right: 12px; }
    #inventory-inprogress-requests > .header md-icon {
      color: #ffffff; }
    #inventory-inprogress-requests > .header .records-count {
      margin: 6px 0 0 36px; }
    #inventory-inprogress-requests > .header .search-input-wrapper {
      position: relative; }
      #inventory-inprogress-requests > .header .search-input-wrapper label {
        padding-right: 4px;
        cursor: pointer; }
        #inventory-inprogress-requests > .header .search-input-wrapper label md-icon {
          color: rgba(255, 255, 255, 0.8); }
      #inventory-inprogress-requests > .header .search-input-wrapper md-input-container .md-errors-spacer {
        display: none; }
      #inventory-inprogress-requests > .header .search-input-wrapper md-input-container input {
        border-bottom: 1px solid rgba(255, 255, 255, 0.8);
        color: rgba(255, 255, 255, 0.8);
        width: 240px; }
        #inventory-inprogress-requests > .header .search-input-wrapper md-input-container input::placeholder {
          color: rgba(255, 255, 255, 0.8); }
        #inventory-inprogress-requests > .header .search-input-wrapper md-input-container input:focus {
          border-bottom: 1px solid white;
          color: white; }
  #inventory-inprogress-requests > .content {
    position: absolute;
    top: 90px;
    bottom: 0;
    right: 0;
    left: 0; }
    #inventory-inprogress-requests > .content .main {
      padding: 0px 24px 0 24px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
      #inventory-inprogress-requests > .content .main .data-list {
        position: relative;
        padding-bottom: 0; }
        #inventory-inprogress-requests > .content .main .data-list .md-subheader {
          box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
          border-bottom: .1px solid #dadada; }
          #inventory-inprogress-requests > .content .main .data-list .md-subheader .md-subheader-inner {
            padding: 12px 16px; }
            #inventory-inprogress-requests > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content {
              font-size: 12px;
              font-weight: 900; }
              #inventory-inprogress-requests > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:last-child {
                width: 60px;
                text-align: center; }
              #inventory-inprogress-requests > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:hover {
                cursor: pointer;
                color: #636363; }
        #inventory-inprogress-requests > .content .main .data-list .list-item {
          border-bottom: .1px solid #dadada; }
          #inventory-inprogress-requests > .content .main .data-list .list-item span {
            padding-right: 6px; }
          #inventory-inprogress-requests > .content .main .data-list .list-item .edit-button {
            width: 60px;
            margin: 0; }
      #inventory-inprogress-requests > .content .main .no-results {
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14); }

@media screen and (max-width: 960px) {
  #inventory-inprogress-requests > .header .h1 {
    font-size: 1.8rem; }
  #inventory-inprogress-requests > .header .records-count {
    font-size: 1.1rem; }
  #inventory-inprogress-requests > .content .main {
    padding: 0; }
    #inventory-inprogress-requests > .content .main .data-list {
      padding-top: 0; } }

#inventory-dashboard > .center > .header {
  height: 136px;
  min-height: 136px;
  max-height: 136px;
  padding: 24px 0 24px 24px; }
  #inventory-dashboard > .center > .header .h1 md-icon {
    margin-right: 12px;
    color: #FFFFFF; }
  #inventory-dashboard > .center > .header .selected-project {
    background: rgba(0, 0, 0, 0.12);
    color: #FFFFFF;
    padding: 8px 16px;
    height: 40px;
    line-height: 24px;
    font-size: 16px; }
  #inventory-dashboard > .center > .header .project-selector {
    margin-left: 1px;
    border-radius: 0;
    background: rgba(0, 0, 0, 0.12); }
    #inventory-dashboard > .center > .header .project-selector md-icon {
      color: #FFFFFF; }

#inventory-dashboard > .center > .content {
  background: none;
  padding: 0; }
  #inventory-dashboard > .center > .content > md-tabs > md-tabs-wrapper {
    background: #FFFFFF;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
    padding: 0 24px; }
    #inventory-dashboard > .center > .content > md-tabs > md-tabs-wrapper md-tabs-canvas {
      height: 56px; }
      #inventory-dashboard > .center > .content > md-tabs > md-tabs-wrapper md-tabs-canvas md-pagination-wrapper {
        height: 56px; }
      #inventory-dashboard > .center > .content > md-tabs > md-tabs-wrapper md-tabs-canvas .md-tab {
        padding: 16px 24px;
        text-transform: none; }
  #inventory-dashboard > .center > .content > md-tabs:not(.md-dynamic-height) md-tabs-content-wrapper {
    top: 56px; }
  #inventory-dashboard > .center > .content > md-tabs > md-tabs-content-wrapper md-tab-content {
    padding: 12px; }

#inventory-dashboard > .sidenav {
  width: 330px;
  min-width: 330px;
  max-width: 330px;
  padding: 0;
  z-index: 51;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); }
  #inventory-dashboard > .sidenav.md-locked-open {
    width: 250px;
    min-width: 250px;
    max-width: 250px;
    z-index: 2;
    box-shadow: none;
    background: #EEEEEE; }

#inventory-itemgroups {
  position: relative;
  height: auto;
  min-height: 100%; }
  #inventory-itemgroups > .header {
    height: 90px;
    min-height: 90px;
    max-height: 90px; }
    #inventory-itemgroups > .header .h1 md-icon {
      margin-right: 12px; }
    #inventory-itemgroups > .header md-icon {
      color: #ffffff; }
    #inventory-itemgroups > .header .records-count {
      margin: 6px 0 0 36px; }
    #inventory-itemgroups > .header .search-input-wrapper {
      position: relative; }
      #inventory-itemgroups > .header .search-input-wrapper label {
        padding-right: 4px;
        cursor: pointer; }
        #inventory-itemgroups > .header .search-input-wrapper label md-icon {
          color: rgba(255, 255, 255, 0.8); }
      #inventory-itemgroups > .header .search-input-wrapper md-input-container .md-errors-spacer {
        display: none; }
      #inventory-itemgroups > .header .search-input-wrapper md-input-container input {
        border-bottom: 1px solid rgba(255, 255, 255, 0.8);
        color: rgba(255, 255, 255, 0.8);
        width: 240px; }
        #inventory-itemgroups > .header .search-input-wrapper md-input-container input::placeholder {
          color: rgba(255, 255, 255, 0.8); }
        #inventory-itemgroups > .header .search-input-wrapper md-input-container input:focus {
          border-bottom: 1px solid white;
          color: white; }
  #inventory-itemgroups > .content {
    position: absolute;
    top: 90px;
    bottom: 0;
    right: 0;
    left: 0; }
    #inventory-itemgroups > .content .main {
      padding: 0px 24px 0 24px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
      #inventory-itemgroups > .content .main .data-list {
        position: relative;
        padding-bottom: 0; }
        #inventory-itemgroups > .content .main .data-list .md-subheader {
          box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
          border-bottom: .1px solid #dadada; }
          #inventory-itemgroups > .content .main .data-list .md-subheader .md-subheader-inner {
            padding: 12px 16px; }
            #inventory-itemgroups > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content {
              font-size: 12px;
              font-weight: 900; }
              #inventory-itemgroups > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:last-child {
                width: 60px;
                text-align: center; }
              #inventory-itemgroups > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:hover {
                cursor: pointer;
                color: #636363; }
        #inventory-itemgroups > .content .main .data-list .list-item {
          border-bottom: .1px solid #dadada; }
          #inventory-itemgroups > .content .main .data-list .list-item span {
            padding-right: 6px; }
          #inventory-itemgroups > .content .main .data-list .list-item .edit-button {
            width: 60px;
            margin: 0; }
      #inventory-itemgroups > .content .main .no-results {
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14); }

@media screen and (max-width: 960px) {
  #inventory-itemgroups > .header .h1 {
    font-size: 1.8rem; }
  #inventory-itemgroups > .header .records-count {
    font-size: 1.1rem; }
  #inventory-itemgroups > .content .main {
    padding: 0; }
    #inventory-itemgroups > .content .main .data-list {
      padding-top: 0; } }

#inventory-items {
  position: relative;
  height: auto;
  min-height: 100%; }
  #inventory-items > .header {
    height: 90px;
    min-height: 90px;
    max-height: 90px; }
    #inventory-items > .header .h1 md-icon {
      margin-right: 12px; }
    #inventory-items > .header md-icon {
      color: #ffffff; }
    #inventory-items > .header .records-count {
      margin: 6px 0 0 36px; }
    #inventory-items > .header .search-input-wrapper {
      position: relative; }
      #inventory-items > .header .search-input-wrapper label {
        padding-right: 4px;
        cursor: pointer; }
        #inventory-items > .header .search-input-wrapper label md-icon {
          color: rgba(255, 255, 255, 0.8); }
      #inventory-items > .header .search-input-wrapper md-input-container .md-errors-spacer {
        display: none; }
      #inventory-items > .header .search-input-wrapper md-input-container input {
        border-bottom: 1px solid rgba(255, 255, 255, 0.8);
        color: rgba(255, 255, 255, 0.8);
        width: 240px; }
        #inventory-items > .header .search-input-wrapper md-input-container input::placeholder {
          color: rgba(255, 255, 255, 0.8); }
        #inventory-items > .header .search-input-wrapper md-input-container input:focus {
          border-bottom: 1px solid white;
          color: white; }
    #inventory-items > .header.selected-bar {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 999;
      background-image: none;
      opacity: 1 !important; }
      #inventory-items > .header.selected-bar .close-button-wrapper {
        width: 244px; }
  #inventory-items > .content {
    position: absolute;
    top: 90px;
    bottom: 0;
    right: 0;
    left: 0; }
    #inventory-items > .content .main {
      padding: 0px 24px 0 24px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
      #inventory-items > .content .main .data-list {
        position: relative;
        padding-bottom: 0; }
        #inventory-items > .content .main .data-list .md-subheader {
          box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
          border-bottom: .1px solid #dadada; }
          #inventory-items > .content .main .data-list .md-subheader .md-subheader-inner {
            padding: 12px 16px; }
            #inventory-items > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content {
              font-size: 12px;
              font-weight: 900; }
              #inventory-items > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:last-child {
                width: 60px;
                text-align: center; }
              #inventory-items > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:hover {
                cursor: pointer;
                color: #636363; }
        #inventory-items > .content .main .data-list .list-item {
          border-bottom: .1px solid #dadada; }
          #inventory-items > .content .main .data-list .list-item span {
            padding-right: 6px; }
          #inventory-items > .content .main .data-list .list-item .edit-button {
            width: 60px;
            margin: 0; }
      #inventory-items > .content .main .no-results {
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14); }

@media screen and (max-width: 960px) {
  #inventory-items > .header .h1 {
    font-size: 1.8rem; }
  #inventory-items > .header .records-count {
    font-size: 1.1rem; }
  #inventory-items > .content .main {
    padding: 0; }
    #inventory-items > .content .main .data-list {
      padding-top: 0; } }

#inventory-new-requests {
  position: relative;
  height: auto;
  min-height: 100%; }
  #inventory-new-requests > .header {
    height: 90px;
    min-height: 90px;
    max-height: 90px; }
    #inventory-new-requests > .header .h1 md-icon {
      margin-right: 12px; }
    #inventory-new-requests > .header md-icon {
      color: #ffffff; }
    #inventory-new-requests > .header .records-count {
      margin: 6px 0 0 36px; }
    #inventory-new-requests > .header .search-input-wrapper {
      position: relative; }
      #inventory-new-requests > .header .search-input-wrapper label {
        padding-right: 4px;
        cursor: pointer; }
        #inventory-new-requests > .header .search-input-wrapper label md-icon {
          color: rgba(255, 255, 255, 0.8); }
      #inventory-new-requests > .header .search-input-wrapper md-input-container .md-errors-spacer {
        display: none; }
      #inventory-new-requests > .header .search-input-wrapper md-input-container input {
        border-bottom: 1px solid rgba(255, 255, 255, 0.8);
        color: rgba(255, 255, 255, 0.8);
        width: 240px; }
        #inventory-new-requests > .header .search-input-wrapper md-input-container input::placeholder {
          color: rgba(255, 255, 255, 0.8); }
        #inventory-new-requests > .header .search-input-wrapper md-input-container input:focus {
          border-bottom: 1px solid white;
          color: white; }
    #inventory-new-requests > .header.selected-bar {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 999;
      background-image: none;
      opacity: 1 !important; }
      #inventory-new-requests > .header.selected-bar .close-button-wrapper {
        width: 244px; }
  #inventory-new-requests > .content {
    position: absolute;
    top: 90px;
    bottom: 0;
    right: 0;
    left: 0; }
    #inventory-new-requests > .content .main {
      padding: 0px 24px 0 24px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
      #inventory-new-requests > .content .main .data-list {
        position: relative;
        padding-bottom: 0; }
        #inventory-new-requests > .content .main .data-list > .toolbar {
          padding: 0 12px 0 20px;
          position: absolute;
          width: 100%;
          z-index: 3;
          background-color: #ffffff; }
          #inventory-new-requests > .content .main .data-list > .toolbar .action-buttons .md-icon-button:hover {
            background-color: #efefef; }
        #inventory-new-requests > .content .main .data-list .md-subheader {
          box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
          border-bottom: .1px solid #dadada; }
          #inventory-new-requests > .content .main .data-list .md-subheader .md-subheader-inner {
            padding: 12px 16px; }
            #inventory-new-requests > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content {
              font-size: 12px;
              font-weight: 900; }
              #inventory-new-requests > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:first-child {
                width: 60px;
                text-align: center; }
              #inventory-new-requests > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:last-child {
                width: 60px;
                text-align: center; }
              #inventory-new-requests > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:hover {
                cursor: pointer;
                color: #636363; }
        #inventory-new-requests > .content .main .data-list .list-item {
          border-bottom: .1px solid #dadada; }
          #inventory-new-requests > .content .main .data-list .list-item:hover {
            cursor: initial; }
          #inventory-new-requests > .content .main .data-list .list-item span {
            padding-right: 6px; }
          #inventory-new-requests > .content .main .data-list .list-item .edit-button {
            width: 60px;
            margin: 0; }
      #inventory-new-requests > .content .main .no-results {
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14); }

@media screen and (max-width: 960px) {
  #inventory-new-requests > .header .h1 {
    font-size: 1.8rem; }
  #inventory-new-requests > .header .records-count {
    font-size: 1.1rem; }
  #inventory-new-requests > .content .main {
    padding: 0; }
    #inventory-new-requests > .content .main .data-list {
      padding-top: 0; } }

#inventory-orders-placed {
  position: relative;
  height: auto;
  min-height: 100%; }
  #inventory-orders-placed > .header {
    height: 90px;
    min-height: 90px;
    max-height: 90px; }
    #inventory-orders-placed > .header .h1 md-icon {
      margin-right: 12px; }
    #inventory-orders-placed > .header md-icon {
      color: #ffffff; }
    #inventory-orders-placed > .header .records-count {
      margin: 6px 0 0 36px; }
    #inventory-orders-placed > .header .search-input-wrapper {
      position: relative; }
      #inventory-orders-placed > .header .search-input-wrapper label {
        padding-right: 4px;
        cursor: pointer; }
        #inventory-orders-placed > .header .search-input-wrapper label md-icon {
          color: rgba(255, 255, 255, 0.8); }
      #inventory-orders-placed > .header .search-input-wrapper md-input-container .md-errors-spacer {
        display: none; }
      #inventory-orders-placed > .header .search-input-wrapper md-input-container input {
        border-bottom: 1px solid rgba(255, 255, 255, 0.8);
        color: rgba(255, 255, 255, 0.8);
        width: 240px; }
        #inventory-orders-placed > .header .search-input-wrapper md-input-container input::placeholder {
          color: rgba(255, 255, 255, 0.8); }
        #inventory-orders-placed > .header .search-input-wrapper md-input-container input:focus {
          border-bottom: 1px solid white;
          color: white; }
    #inventory-orders-placed > .header.selected-bar {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 999;
      background-image: none;
      opacity: 1 !important; }
      #inventory-orders-placed > .header.selected-bar .close-button-wrapper {
        width: 244px; }
  #inventory-orders-placed > .content {
    position: absolute;
    top: 90px;
    bottom: 0;
    right: 0;
    left: 0; }
    #inventory-orders-placed > .content .main {
      padding: 0px 24px 0 24px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
      #inventory-orders-placed > .content .main .data-list {
        position: relative;
        padding-bottom: 0; }
        #inventory-orders-placed > .content .main .data-list > .toolbar {
          padding: 0 12px 0 20px;
          position: absolute;
          width: 100%;
          z-index: 3;
          background-color: #ffffff; }
          #inventory-orders-placed > .content .main .data-list > .toolbar .action-buttons .md-icon-button:hover {
            background-color: #efefef; }
        #inventory-orders-placed > .content .main .data-list .md-subheader {
          box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
          border-bottom: .1px solid #dadada; }
          #inventory-orders-placed > .content .main .data-list .md-subheader .md-subheader-inner {
            padding: 12px 16px; }
            #inventory-orders-placed > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content {
              font-size: 12px;
              font-weight: 900; }
              #inventory-orders-placed > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:first-child {
                width: 60px;
                text-align: center; }
              #inventory-orders-placed > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:last-child {
                width: 60px;
                text-align: center; }
              #inventory-orders-placed > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:hover {
                cursor: pointer;
                color: #636363; }
        #inventory-orders-placed > .content .main .data-list .list-item {
          border-bottom: .1px solid #dadada; }
          #inventory-orders-placed > .content .main .data-list .list-item:hover {
            cursor: initial; }
          #inventory-orders-placed > .content .main .data-list .list-item span {
            padding-right: 6px; }
          #inventory-orders-placed > .content .main .data-list .list-item .edit-button {
            width: 60px;
            margin: 0; }
      #inventory-orders-placed > .content .main .no-results {
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14); }

@media screen and (max-width: 960px) {
  #inventory-orders-placed > .header .h1 {
    font-size: 1.8rem; }
  #inventory-orders-placed > .header .records-count {
    font-size: 1.1rem; }
  #inventory-orders-placed > .content .main {
    padding: 0; }
    #inventory-orders-placed > .content .main .data-list {
      padding-top: 0; } }

#inventory-rfd-requests {
  position: relative;
  height: auto;
  min-height: 100%; }
  #inventory-rfd-requests > .header {
    height: 90px;
    min-height: 90px;
    max-height: 90px; }
    #inventory-rfd-requests > .header .h1 md-icon {
      margin-right: 12px; }
    #inventory-rfd-requests > .header md-icon {
      color: #ffffff; }
    #inventory-rfd-requests > .header .records-count {
      margin: 6px 0 0 36px; }
    #inventory-rfd-requests > .header .search-input-wrapper {
      position: relative; }
      #inventory-rfd-requests > .header .search-input-wrapper label {
        padding-right: 4px;
        cursor: pointer; }
        #inventory-rfd-requests > .header .search-input-wrapper label md-icon {
          color: rgba(255, 255, 255, 0.8); }
      #inventory-rfd-requests > .header .search-input-wrapper md-input-container .md-errors-spacer {
        display: none; }
      #inventory-rfd-requests > .header .search-input-wrapper md-input-container input {
        border-bottom: 1px solid rgba(255, 255, 255, 0.8);
        color: rgba(255, 255, 255, 0.8);
        width: 240px; }
        #inventory-rfd-requests > .header .search-input-wrapper md-input-container input::placeholder {
          color: rgba(255, 255, 255, 0.8); }
        #inventory-rfd-requests > .header .search-input-wrapper md-input-container input:focus {
          border-bottom: 1px solid white;
          color: white; }
    #inventory-rfd-requests > .header.selected-bar {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 999;
      background-image: none;
      opacity: 1 !important; }
      #inventory-rfd-requests > .header.selected-bar .close-button-wrapper {
        width: 244px; }
  #inventory-rfd-requests > .content {
    position: absolute;
    top: 90px;
    bottom: 0;
    right: 0;
    left: 0; }
    #inventory-rfd-requests > .content .main {
      padding: 0px 24px 0 24px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
      #inventory-rfd-requests > .content .main .data-list {
        position: relative;
        padding-bottom: 0; }
        #inventory-rfd-requests > .content .main .data-list > .toolbar {
          padding: 0 12px 0 20px;
          position: absolute;
          width: 100%;
          z-index: 3;
          background-color: #ffffff; }
          #inventory-rfd-requests > .content .main .data-list > .toolbar .action-buttons .md-icon-button:hover {
            background-color: #efefef; }
        #inventory-rfd-requests > .content .main .data-list .md-subheader {
          box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
          border-bottom: .1px solid #dadada; }
          #inventory-rfd-requests > .content .main .data-list .md-subheader .md-subheader-inner {
            padding: 12px 16px; }
            #inventory-rfd-requests > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content {
              font-size: 12px;
              font-weight: 900; }
              #inventory-rfd-requests > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:first-child {
                width: 60px;
                text-align: center; }
              #inventory-rfd-requests > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:last-child {
                width: 60px;
                text-align: center; }
              #inventory-rfd-requests > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:hover {
                cursor: pointer;
                color: #636363; }
        #inventory-rfd-requests > .content .main .data-list .list-item {
          border-bottom: .1px solid #dadada; }
          #inventory-rfd-requests > .content .main .data-list .list-item:hover {
            cursor: initial; }
          #inventory-rfd-requests > .content .main .data-list .list-item span {
            padding-right: 6px; }
          #inventory-rfd-requests > .content .main .data-list .list-item .edit-button {
            width: 60px;
            margin: 0; }
      #inventory-rfd-requests > .content .main .no-results {
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14); }

@media screen and (max-width: 960px) {
  #inventory-rfd-requests > .header .h1 {
    font-size: 1.8rem; }
  #inventory-rfd-requests > .header .records-count {
    font-size: 1.1rem; }
  #inventory-rfd-requests > .content .main {
    padding: 0; }
    #inventory-rfd-requests > .content .main .data-list {
      padding-top: 0; } }

#inventory-unitcodes {
  position: relative;
  height: auto;
  min-height: 100%; }
  #inventory-unitcodes > .header {
    height: 90px;
    min-height: 90px;
    max-height: 90px; }
    #inventory-unitcodes > .header .h1 md-icon {
      margin-right: 12px; }
    #inventory-unitcodes > .header md-icon {
      color: #ffffff; }
    #inventory-unitcodes > .header .records-count {
      margin: 6px 0 0 36px; }
    #inventory-unitcodes > .header .search-input-wrapper {
      position: relative; }
      #inventory-unitcodes > .header .search-input-wrapper label {
        padding-right: 4px;
        cursor: pointer; }
        #inventory-unitcodes > .header .search-input-wrapper label md-icon {
          color: rgba(255, 255, 255, 0.8); }
      #inventory-unitcodes > .header .search-input-wrapper md-input-container .md-errors-spacer {
        display: none; }
      #inventory-unitcodes > .header .search-input-wrapper md-input-container input {
        border-bottom: 1px solid rgba(255, 255, 255, 0.8);
        color: rgba(255, 255, 255, 0.8);
        width: 240px; }
        #inventory-unitcodes > .header .search-input-wrapper md-input-container input::placeholder {
          color: rgba(255, 255, 255, 0.8); }
        #inventory-unitcodes > .header .search-input-wrapper md-input-container input:focus {
          border-bottom: 1px solid white;
          color: white; }
  #inventory-unitcodes > .content {
    position: absolute;
    top: 90px;
    bottom: 0;
    right: 0;
    left: 0; }
    #inventory-unitcodes > .content .main {
      padding: 0px 24px 0 24px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
      #inventory-unitcodes > .content .main .data-list {
        position: relative;
        padding-bottom: 0; }
        #inventory-unitcodes > .content .main .data-list .md-subheader {
          box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
          border-bottom: .1px solid #dadada; }
          #inventory-unitcodes > .content .main .data-list .md-subheader .md-subheader-inner {
            padding: 12px 16px; }
            #inventory-unitcodes > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content {
              font-size: 12px;
              font-weight: 900; }
              #inventory-unitcodes > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:last-child {
                width: 60px;
                text-align: center; }
              #inventory-unitcodes > .content .main .data-list .md-subheader .md-subheader-inner .md-subheader-content .column-title:hover {
                cursor: pointer;
                color: #636363; }
        #inventory-unitcodes > .content .main .data-list .list-item {
          border-bottom: .1px solid #dadada; }
          #inventory-unitcodes > .content .main .data-list .list-item span {
            padding-right: 6px; }
          #inventory-unitcodes > .content .main .data-list .list-item .edit-button {
            width: 60px;
            margin: 0; }
      #inventory-unitcodes > .content .main .no-results {
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14); }

@media screen and (max-width: 960px) {
  #inventory-unitcodes > .header .h1 {
    font-size: 1.8rem; }
  #inventory-unitcodes > .header .records-count {
    font-size: 1.1rem; }
  #inventory-unitcodes > .content .main {
    padding: 0; }
    #inventory-unitcodes > .content .main .data-list {
      padding-top: 0; } }
