#purchase-invoices-add, #purchase-invoices-edit {

    // Sidenav
    .sidenav.supplier-orders-sidenav {
        width: 320px;
        min-width: 320px;
        max-width: 320px;
        padding: 0;

        &._md-locked-open {
            width: 320px;
            min-width: 320px;
            max-width: 320px;
            overflow: hidden;
        }

        .header {
            height: 136px;
            min-height: 136px;
            max-height: 136px;
            padding: 24px 24px;
            background-color: rgb(7, 138, 202);

            .title {
                font-size: 18px;
                color: #a6cee2;
                margin-left: 15px;
            }

            .status-icon {
                md-icon {
                    color: #ffffff;
                }  
            }

            md-select {
                margin-left: 66px;

                .md-select-value {
                    color: #ffffffb3;
                    border-bottom-color: #ffffffb3;
                }
            }
        }

        .content {
            position: relative;
            background: #FFFFFF;
            padding: 24px 0;

            ms-widget {

                ms-widget-front {
                    .md-toolbar-tools {
                        height: 48px;
                        max-height: 48px;
                        background-color: #073c56;
                    }

                    .mid-panel {
                        padding: 15px 9px;

                        .secondary-text {
                            width: 90px;
                            text-align: right;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $layout-breakpoint-sm) {
    #purchase-invoices-add, #purchase-invoices-edit {
        
        // Sidenav
        .sidenav.supplier-orders-sidenav {
            width: 240px;
            min-width: 240px;
            max-width: 240px;
            font-size: 11px;

            h3, h4, p {
                font-size: 11px !important;
            }
        }

    }

}