#profile {

    .account-tab {
        max-width: 1200px;

        .profile-account-container {
            padding: 16px 0px 0px 24px;
            .error-msg {
                font-size: 12px;
                color: #d50000;
            }
        }
    }
}