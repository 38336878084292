md-dialog {

    &.payment-method-dialog {
        max-width: 600px;
        width: 600px;

        form {
            font-size: 14px;

            .logo-wrapper {
                margin-bottom: 15px;
                width: 210px;
            }

            md-input-container.text {
                margin: 3px 0;
            }

            md-input-container.select {
                margin: 3px 6px;
            }

            md-input-container.select:first-child {
                margin-left: 0px;
            }

            md-input-container.select:last-child {
                margin-right: 0px;
            }

            .cc-panel {
                padding: 18px 9px;
            }

        }

    }
}