#profile {

    md-dialog {

        &.upload-picture-dialog {
            max-width: 500px;
            width: 500px;

            md-toolbar {
                height: 50px;
                min-height: 50px;
                max-height: 50px;

                .title {
                    font-size: 17px;
                }
            }

            md-dialog-content {
                display: block;
                position: relative;

                .cropArea {
                    background: #E4E4E4;
                    overflow: hidden;
                    width:450px;
                    height:350px;
                }
            }

            md-dialog-actions {
                position: relative;
                overflow-y: hidden;
                overflow-x: auto;
                justify-content: space-between;
                background-color: rgba(0, 0, 0, 0.03);
                border-top: 1px solid rgba(0, 0, 0, 0.12);
            }
        }
    }
}