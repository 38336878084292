#user-actions {
    height: 100%;
    overflow: hidden;
    background: url('/assets/images/backgrounds/auth-bg.jpg') no-repeat;
    background-size: cover;

    #user-actions-form-wrapper {
        flex: 1 0 auto;
        padding: 32px;

        #user-actions-form {
            padding: 33px;
            width: 500px;
            max-width: 500px;
            background: #FFFFFF;

            .email-verify {

                .title-success {
                    margin: 8px 0 8px 0;
                    font-size: 17px;
                    font-weight: 500;
                    text-align: center;
                }

                .title-error {
                    margin: 8px 0 8px 0;
                    font-size: 17px;
                    font-weight: 500;
                    text-align: center;
                }

                .desc {
                    width: 100%;
                    margin: 32px 0 0 0;
                    font-size: 14px;
                    text-align: center;
                }

                .continue-link {
                    margin-top: 20px;
                }

            }


            .email-reset {

                .code-valid {

                    .title {
                        font-size: 17px;
                        font-weight: 500;
                        margin: 16px 0 32px 0;
                        text-align: center;
                    }

                    form {
                        width: 100%;
                        margin: 32px 0 0 0;
    
                        .submit-button {
                            width: 220px;
                            margin: 32px auto;
                            display: block;
                        }
                    }
                }

                .code-invalid {
                    
                    .title {
                        font-size: 17px;
                        font-weight: 500;
                        margin: 16px 0 32px 0;
                        text-align: center;
                    }
                    
                    .desc {
                        width: 100%;
                        margin: 32px 0 0 0; 
                        color: rgba(0, 0, 0, 0.78);
                        font-size: 14px;
                        text-align: center;
                    }

                    .continue-link {
                        margin-top: 20px;
                    }

                }


                .reset-password-success {
                    
                    .title {
                        font-size: 17px;
                        font-weight: 500;
                        margin: 16px 0 32px 0;
                        text-align: center;
                    }
                    
                    .desc {
                        width: 100%;
                        margin: 32px 0 0 0; 
                        color: rgba(0, 0, 0, 0.78);
                        font-size: 14px;
                        text-align: center;
                    }

                    .continue-link {
                        margin-top: 20px;
                    }

                }

            }


            .email-unverified {
    
                .title {
                    margin: 8px 0 8px 0;
                    font-size: 17px;
                    font-weight: 500;
                    text-align: center;
                }
    
                .desc {
                    color: rgba(0, 0, 0, 0.78);
                    font-size: 14px;
                    text-align: center;
                }
    
                .email {
                    text-align:center;
                    font-size: 14px;
                    font-weight: 300;
                }
    
                .logo {
                    text-align: center;
                    margin-right: 16px;
                }
    
                form {
                    width: 100%;
                    margin: 32px 0 0 0;
    
                    .submit-button {
                        width: 220px;
                        margin: 32px auto;
                        display: block;
                    }
                }
    
                .continue-link {
                    margin-top: 20px;
                }
    
                .message {
                    font-weight: 500;
                    text-align: center;
                }
            }


        }
    }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-sm) {

    #user-actions {

        #user-actions-form-wrapper {
            padding: 16px;

            #user-actions-form {
                padding: 24px;
                width: 100%;

                .icon-container {
                    margin-bottom: 10px;
                }

                form {

                    .md-button {
                        width: 90%;
                    }
                }
            }
        }
    }

}