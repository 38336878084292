#purchase-analysis-chart {
    padding: 0;
    min-height: 450px;
    height: 450px;
    background-color: #eeeeee;

    .btn-close {
        position: absolute;
        right: 0;
        z-index: 3;
        padding: 6px 12px;

        .icon-window-close {
            color: #ffffff;
        }

        &:hover {
            cursor: pointer;
        }
    }

    >.widget-group {

        >.ms-widget {
            padding: 0;

            .ms-widget-front {

                md-tabs {

                    md-tabs-content-wrapper {
                        padding: 0 6px;

                        md-tab-content {
                            height: 320px;

                            .widget-group {
                                height: 100%;

                                >.ms-widget {

                                    .ms-widget-front {

                                        .h3 {
                                            padding-bottom: 5px;
                                            padding-left: 5px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

            }
        }
    }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-xs) {

    #purchase-analysis-chart {
    
        >.widget-group {
    
            >.ms-widget {
    
                .ms-widget-front {
    
                    md-tabs {
    
                        md-tabs-content-wrapper {
    
                            md-tab-content {
    
                                .widget-group {
    
                                    >.ms-widget {
    
                                        .ms-widget-front {

                                            div {
                                                padding: 3px 0;

                                                .h3 {
                                                    font-size: 12px;
                                                }

                                                .count {
                                                    font-size: 24px;
                                                    line-height: 24px;
                                                }
    
                                                .unit {
                                                    font-size: 1.2rem;
                                                    padding-bottom: 1px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
    
                }
            }
        }
    }

}

