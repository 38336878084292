body {

    .menu-panel-container {
        pointer-events: auto;

        .menu-panel {
            width: 180px;
            background-color: #fff;
            border-radius: 4px;

            .menu-content {
                display: flex;
                flex-direction: column;
                padding: 8px 0;
                max-height: 305px;
                overflow-y: auto;
                min-width: 180px;

                .menu-item {
                    display: flex;
                    flex-direction: row;
                    min-height: 48px;
                    height: 48px;
                    align-content: center;
                    justify-content: flex-start;

                    > * {
                        width: 100%;
                        margin: auto 0;
                        padding-left: 16px;
                        padding-right: 16px;
                    }

                    > a.md-button {
                        padding-top: 5px;
                    }

                    > .md-button {
                        display: inline-block;
                        border-radius: 0;
                        margin: auto 0;
                        font-size: 15px;
                        text-transform: none;
                        font-weight: 400;
                        height: 100%;
                        padding-left: 16px;
                        padding-right: 16px;
                        width: 100%;
                        text-align: left;

                        ::-moz-focus-inner {
                            padding: 0;
                            border: 0;
                        }

                        md-icon {
                            margin: auto 16px auto 0;
                        }

                        p {
                            display: inline-block;
                            margin: auto;
                        }

                        span {
                            margin-top: auto;
                            margin-bottom: auto;
                        }

                        .md-ripple-container {
                            border-radius: inherit;
                        }
                    }
                }
            }
        }
    }

    .menu-divider {
        width: 100%;
        height: 1px;
        min-height: 1px;
        max-height: 1px;
        margin-top: 4px;
        margin-bottom: 4px;
        background-color: rgba(0, 0, 0, 0.11);
    }

    md-dialog {
        
        .md-dialog-content {
            min-width: 450px;
        }
    }

}