#profile {

    .header {
        height: 200px;
        min-height: 200px;
        max-height: 200px;
        background-size: 100% auto;

        .profile-image {
            margin-right: 0px;
        }

        .name {
            font-size: 34px;
            color: #FFFFFF;
            margin-left: 24px;
        }

        .img-buttons {
            md-icon {
                color: #FFFFFF;
            }

        }

    }  

    .content {

        md-content {
            background-color: transparent;
        }

    }

    // Profile boxes
    .profile-box {
        margin-bottom: 16px;

        header {
            padding: 16px;

            .title {
                font-size: 17px;
            }

            .more {
                cursor: pointer;
            }
        }

        .content {
            padding: 16px;
            background-color: #FFF;
        }

        footer {
            padding: 8px;
            border-top: 1px solid rgba(0, 0, 0, 0.08);
            background-color: rgba(0, 0, 0, 0.06);
        }

        &.info-box {

            .info-line {
                margin-bottom: 24px;

                .title {
                    font-size: 15px;
                    font-weight: 500;
                    padding-bottom: 4px;
                }

                .info {

                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}


@media only screen and (max-width: $layout-breakpoint-sm) {
    
    #profile {

        .header {
            height: 150px;
            min-height: 150px;
            max-height: 150px;
            
            .profile-image {
                width: 48px;
                min-width: 48px;
                height: 48px;
                line-height: 48px;
            }

            .name {
                font-size: 16px;
            }
        }

        .content {

            .profile-about-container {

                #profileAbout {
                    font-size: 12px;
                }
            }

            .profile-account-container {
                
                #profileAccount {
                    font-size: 12px;
                }
            }

        }
    }
}