#account-settings {

    > .header {
        height: 120px;
        min-height: 120px;
        max-height: 120px;

        .h1 {

            md-icon {
                margin-right: 12px;
                color: #ffffff;
            }
        }

        .md-icon-button {
            
            &:hover {
                background-color: #f2f2f233;
            }

            md-icon {
                color: #fafafa;
            }

            &[disabled] {

                md-icon {
                    color: #fafafa69;
                }
            }
        }
    }

    > .content {
        padding: 24px;

        >.widget-group {
            max-width: 990px;

            .ms-widget {

                &:first-child {
                    padding: 0 12px 12px 0;

                    .change-plan {

                        span {
                            text-transform: initial;
                        }
                    }
                }

                &:last-child {
                    padding: 0 0 12px 12px;
                }

                .payment-method-bar {

                    .md-button {
                        text-transform: inherit;
                        font-size: 1.3rem;
                    }
                }
            } 
        }

        .form-container {
            padding: 24px;
            margin-bottom: 24px;
            max-width: 990px;
    
            form {
                font-size: 12px!important;
            }
        }
    }
}


// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-xs) {

    #account-settings {

        > .content {
    
            >.widget-group {
    
                .ms-widget {
    
                    &:first-child {
                        padding: 0 0 12px 0;
                    }
    
                    &:last-child {
                        padding: 0 0 12px 0;
                    }
                } 
            }
        }
    }
}
