md-dialog {

    &.inventory-deliver-request-dialog {
        max-width: 720px;
        width: 720px;

        md-toolbar {
            height: 60px;
            min-height: 60px;
            max-height: 60px;

            .title {
                font-size: 17px;
            }
        }

        md-dialog-content {
            display: block;
            position: relative;
            height: 400px;
            max-height: 400px;
            min-height: 400px;
            font-size: 15px;

            table {
                thead {
                    tr {
                        th {
                            padding: 6px 18px 6px 0px;
                            font-weight: 800;
                            border-bottom: 1px solid #7d7d7d;
                        }

                        th:first-child {
                            padding-left: 0;
                        }

                        th:last-child {
                            padding-right: 0;
                        }

                        th.quantity, th.available {
                            text-align: right;
                            padding-right: 30px;
                        }
                    }
                }

                tbody {
                    tr {

                        td.quantity, td.available {
                            width: 150px;
                            max-width: 150px;
                            min-width: 150px;
                            text-align: right;
                            padding-right: 30px;
                        }

                        td.unit {
                            width: 120px;
                            max-width: 120px;
                            min-width: 120px;
                        }

                        td.item {
                            width: 270px;
                            max-width: 270px;
                            min-width: 270px;
                        }

                        td:first-child {
                            padding-left: 0;
                        }

                        .icon-checkbox-marked-circle {
                            color: green;
                        }

                        .icon-alert-circle {
                            color: red;
                        }
                    }

                    tr:first-child td {
                        padding-top: 20px;
                    }
                }


            }

        }

        md-dialog-actions {
            position: relative;
            overflow-y: hidden;
            overflow-x: auto;
            justify-content: space-between;
            background-color: rgba(0, 0, 0, 0.03);
            border-top: 1px solid rgba(0, 0, 0, 0.12);
        }
    }
}