md-dialog {

    &.account-user-dialog {
        max-width: 600px;
        width: 600px;

        md-toolbar {
            height: 60px;
            min-height: 60px;
            max-height: 60px;

            .title {
                font-size: 17px;
            }
        }

        md-dialog-content {
            display: block;
            position: relative;

            .not-accepted {

                span {
                    margin-top: auto;
                    margin-bottom: auto;
                    text-transform: initial;
                }
            }

            md-input-container {
                height: 58px;
                max-height: 58px;
                min-height: 58px;
            }

            fieldset {
                border-color: #f5f5f5;
                margin-top: 24px;

                &:first-of-type {
                    margin-top: 0;
                }

                md-input-container {
                    margin-bottom: 0;
                }

                .md-chips {
                    box-shadow: 0 0px !important;

                    .md-chip-input-container {
                        
                        input {
                            padding-left: 0!important;
                        }
                    }
                }

                .md-chips-messages {
                    color: #D50000;
                    font-size: 12px;
                    padding-left: 3px;
                    position: relative;
                    overflow: hidden; 
                }
            }
        }

        md-dialog-actions {
            position: relative;
            overflow-y: hidden;
            overflow-x: auto;
            justify-content: space-between;
            background-color: rgba(0, 0, 0, 0.03);
            border-top: 1px solid rgba(0, 0, 0, 0.12);
        }
    }
}