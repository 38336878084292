#payment-method {

    > .header {
        height: 120px;
        min-height: 120px;
        max-height: 120px;

        .goto-purchase-contracts-button {
            margin-right: 16px;
        }

        .subtitle {
            margin: 6px 0 0 0;
        }

        .md-icon-button {
            
            &:hover {
                background-color: #f2f2f233;
            }

            md-icon {
                color: #fafafa;
            }

            &[disabled] {

                md-icon {
                    color: #fafafa69;
                }
            }
        }
    }

    > .content {
        padding: 24px;

        >.widget-group {
            // max-width: 990px;

            .md-button {
                text-transform: inherit;
                font-size: 1.3rem;
            }

            .ms-widget {
                padding: 12px;
            } 
        }

        .form-container {
            margin-bottom: 24px;
            // max-width: 990px;

            .md-button {
                text-transform: inherit;
            }
        }
    }
}


// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-xs) {

    #payment-method {

        > .content {
            padding: 0;
    
            >.widget-group {
    
                .ms-widget {
                    padding: 12px;

                    .cc-number-mask {
                        font-size: 24px;
                    }
                } 
            }
        }
    }
}
