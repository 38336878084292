#purchase-contract {

    > .header {
        height: 120px;
        min-height: 120px;
        max-height: 120px;

        .goto-purchase-contracts-button {
            margin-right: 16px;
        }

        .subtitle {
            margin: 6px 0 0 0;
        }

        .md-icon-button {
            
            &:hover {
                background-color: #f2f2f233;
            }

            md-icon {
                color: #fafafa;
            }

            &[disabled] {

                md-icon {
                    color: #fafafa69;
                }
            }
        }
    }

    > .content {
        padding: 24px;

        .detail-form-container {
            padding: 24px;
            margin-bottom: 24px;
            max-width: 990px;
    
            form {
                font-size: 12px!important;
    
                table.simple {
                    thead {
        
                        tr {
                            th {
                                padding: 0 5px 0 5px;
                            }
    
                            th.unit-price, th.discount, th.discount2 {
                                text-align: right;
                                padding-right: 9px;
                            }
    
                            th.actions {
                                text-align: center;
                            }
                        }
                    }
        
                    tbody {
        
                        tr {
                            padding-top: 0;
                            padding-bottom: 0;
        
                            td {
                                padding: 0 5px 0 5px;
                                border-bottom: none;
                            }
        
                            td.unit-price, td.discount, td.discount2{
                                text-align: right;
    
                                md-input-container {
                                    width: inherit;
                                    margin: 0;
        
                                    .md-errors-spacer {
                                        min-height: 6px;
                                    }
    
                                    /* Chrome, Safari, Edge, Opera */
                                    input::-webkit-outer-spin-button,
                                    input::-webkit-inner-spin-button {
                                        -webkit-appearance: none;
                                        margin: 0;
                                    }
    
                                    /* Firefox */
                                    input[type=number] {
                                        -moz-appearance:textfield;
                                    }
    
                                    input {
                                        text-align: end;
                                    }
                                }
                            }
        
                            td.unit {

                            }
        
                            td.item {
                                width: 390px;

                                md-autocomplete {

                                    > md-autocomplete-wrap {
                                        
                                        > md-input-container {
                                            margin-top: 0px;
    
                                            > label {
                                                -webkit-transform: translate3d(1px,28px,0) scale(1) !important;
                                                transform: translate3d(1px,28px,0) scale(1) !important;
                                                color: #BDBDBD;
                                            }
    
                                            &.md-input-has-value {
    
                                                > label {
                                                    display: none !important;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
        
                            td.actions {
                                text-align: center;
    
                                .md-button {
                                    min-width: auto;
                                    min-height: auto;
                                    line-height: unset;
    
                                    .icon-delete {
                                        font-size: 18px;
                                        width: 18px;
                                        height: 18px;
                                        min-width: 18px;
                                        min-height: 18px;
                                        line-height: 18px;
                                    }
                                }
                            }
    
                            md-input-container {
                                width: inherit;
                                margin: 0;
    
                                .md-errors-spacer {
                                    min-height: 6px;
                                }
                            }
                        }
        
                        tr:first-child td {
                            padding-top: 15px!important;
                        }
                    }
                }
        
                md-input-container.supplier {
                    margin-bottom: 42px;
                }
    
                md-input-container.currency {
                    margin-bottom: 42px;
                }
    
                md-input-container.date {
                    margin-top: 0px;
                    margin-bottom: 23px;
    
                    .md-datepicker-input {
    
                    }
                }
    
                md-input-container.text-area {
                    margin-top: 3px;
                    margin-bottom: 0px;
                }
    
                .header-col:last-child {
                    margin-left: 15px;
                }
            }
        }

        .save-button {
            margin: 0;
        }
    }

}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-xs) {

    #purchase-contract {

        > .content {

            .detail-form-container {

                form {
                    
                    .header-col:last-child {
                        margin-left: 0!important;
                    }
                }
            }
        }
    }

}
