#purchasing-pending-requests {
    position: relative;
    height: auto;
    min-height: 100%;

    >.center {

        > .header {
            height: 90px;
            min-height: 90px;
            max-height: 90px;

            .h1 {

                md-icon {
                    margin-right: 12px;
                }
            }

            md-icon {
                color: #ffffff;
            }

            .records-count {
                margin: 6px 0 0 36px;
            }

            .search-input-wrapper {
                position: relative;

                label {
                    padding-right: 4px;
                    cursor: pointer;

                    md-icon {
                        color: rgba(255, 255, 255, 0.8);
                    }

                }

                md-input-container {

                    .md-errors-spacer {
                        display: none;
                    }

                    input {
                        border-bottom: 1px solid rgba(255, 255, 255, 0.8);
                        color: rgba(255, 255, 255, 0.8);
                        width: 240px;

                        &::placeholder {
                            color: rgba(255, 255, 255, 0.8);
                        }

                        &:focus {
                            border-bottom: 1px solid white;
                            color: white;
                        }
                    }
                }
            }

            &.selected-bar {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                z-index: 999;
                background-image: none;
                opacity: 1 !important;

                .close-button-wrapper {
                    width: 244px;
                }
            }

        }

        > .content {
            position: absolute;
            top: 90px;
            bottom: 0;
            right: 0;
            left: 0;

            .main {
                background-color: rgb(245, 245, 245);
                padding: 0px 24px 0 24px;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;

                .data-list {
                    position: relative;
                    padding-bottom: 0;

                    >.toolbar {
                        padding: 0 12px 0 20px;
                        position: absolute;
                        width: 100%;
                        z-index: 3;
                        background-color: #ffffff;
                
                        .action-buttons {
                
                            .md-icon-button:hover {
                                background-color: #efefef;
                            }
                        }
                    }

                    .md-subheader {
                        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
                        border-bottom: .1px solid #dadada;



                        .md-subheader-inner {
                            padding: 12px 16px;

                            .md-subheader-content {
                                font-size: 12px;
                                font-weight: 900;

                                .column-title {

                                    &:first-child {
                                        width: 60px;
                                        text-align: center;
                                    }

                                    &:last-child {
                                        width: 60px;
                                        text-align: center;
                                    }

                                    &:hover {
                                        cursor: pointer;
                                        color: #636363;
                                    }
                                }
                            }
                        }
                    }

                    .list-item {
                        border-bottom: .1px solid #dadada;

                        &:hover {
                            cursor: grab;
                        }

                        &:active {
                            cursor: grabbing;
                        }

                        span {
                            padding-right: 6px;
                        }

                        .edit-button {
                            width: 60px;
                            margin: 0;
                        }
                    }
                }

                .no-results {
                    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
                }
            }
        }
    }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-sm) {

    #purchasing-pending-requests {

        >.header {

            .h1 {
                font-size: 1.8rem;
            }

            .records-count {
                font-size: 1.1rem;
            }
        }

        > .content {

            .main {
                padding: 0;
                
                .data-list {
                    padding-top: 0;
                }
            }
        }
    }
}