#purchasing-pending-requests {

    // Sidenav
    #draft-purchase-sidenav.sidenav {
        width: 320px;
        min-width: 320px;
        max-width: 320px;
        padding: 0;

        &._md-locked-open {
            width: 320px;
            min-width: 320px;
            max-width: 320px;
            overflow: hidden;
        }

        .header {
            height: 90px;
            min-height: 90px;
            max-height: 90px;
            padding: 24px 24px;
            background-color: rgb(7, 138, 202);

            .title {
                font-size: 15px;
                margin-right: 20px;
            }

            .status-icon {
                md-icon {
                    color: #ffffff;
                }  
            }

            .subtitle {

            }
        }

        .content {
            position: relative;
            background: #FFFFFF;
            padding: 24px 0;

            ms-widget {

                .sub-box {
                    border-radius: 15px;
                    min-width: 75px;
                }

                ms-widget-front.drop-on-over {
                    border: 1px dashed red;
                }
            }
        }
    }
}

@media screen and (max-width: $layout-breakpoint-sm) {
    #purchasing-pending-requests {
        
        // Sidenav
        #draft-purchase-sidenav.sidenav {
            width: 240px;
            min-width: 240px;
            max-width: 240px;
            font-size: 11px;

            h3, h4, p {
                font-size: 11px !important;
            }
        }

    }

}