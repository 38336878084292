#purchasing-edit-purchase-form {

    // Sidenav
    .sidenav.previous-quotations-sidenav {
        width: 320px;
        min-width: 320px;
        max-width: 320px;
        padding: 0;

        &._md-locked-open {
            width: 320px;
            min-width: 320px;
            max-width: 320px;
            overflow: hidden;
        }

        .header {
            height: 136px;
            min-height: 136px;
            max-height: 136px;
            padding: 24px 24px;
            background-color: rgb(7, 138, 202);

            .title {
                font-size: 18px;
                color: #a6cee2;
                margin-left: 15px;
            }

            .status-icon {
                md-icon {
                    color: #ffffff;
                }  
            }

            md-select {
                margin-left: 66px;

                .md-select-value {
                    color: #ffffffb3;
                    border-bottom-color: #ffffffb3;
                }
            }
        }

        .content {
            position: relative;
            background: #FFFFFF;
            padding: 24px 0;

            ms-widget {

                ms-widget-front {
                    .md-toolbar-tools {
                        height: 48px;
                        max-height: 48px;
                        background-color: #073c56;
                    }

                    .mid-panel {
                        padding: 15px 9px;
                    }

                    .sub-box {
                        border-radius: 15px;
                        min-width: 75px;
                    }
                }

                ms-widget-back {
                    .md-toolbar-tools {
                        height: 48px;
                        max-height: 48px;
                    }

                    md-tabs {
                        height: 108px;
                        min-height: 108px;

                        md-tabs-wrapper {
                            display: none;
                        }

                        md-tabs-content-wrapper {
                            top: 0;

                            md-tab-content {
                                overflow: hidden;
                            }
                        }
                    }

                    .m-table-container {
                        padding: 9px 0px 0px 0px;
                        width: 180px;

                        .m-table-qtable {
                            display: table-cell;
                            border-radius: 6px;
                            padding: 3px;
    
                            .m-table-qrow {
    
                                .m-table-qcell {
                                    width: 175px;
                                    height: 75px;
                                    vertical-align: middle;
                                    padding: 0px;
                                    font-size: 11px;
                                    color: #353535;
    
                                    .item-name {
                                        height: 21px;
                                        color: #313131;
                                        border: 1px solid #d5d6d6;
                                        padding: 3px;
                                        width: 175px;
                                        max-width: 175px;
                                        background-color: #d5d6d6;
                                    }
    
                                    .unit-price {
                                        height: 21px;
                                        border: 1px solid #c5c5c5;
                                        border-top-style: none;
                                        padding: 3px;
    
                                        .label {
                                            float: left;
                                        }
    
                                        .value {
                                            float: right;
                                        }
                                    }
    
                                    .discount {
                                        height: 21px;
                                        border: 1px solid #c5c5c5;
                                        border-top-style: none;
                                        padding: 3px;
                                        display: block;
    
                                        .disc-1 {
                                            float: left;
                                        }
    
                                        .disc-2 {
                                            float: right;
                                        }
                                    }
    
                                    .total {
                                        height: 21px;
                                        border: 1px solid #c5c5c5;
                                        border-top-style: none;
                                        padding: 3px;
    
                                        .label {
                                            float: left;
                                        }
    
                                        .value {
                                            float: right;
                                            font-weight: 600;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $layout-breakpoint-sm) {
    #purchasing-edit-purchase-form {
        
        // Sidenav
        .sidenav.previous-quotations-sidenav {
            width: 240px;
            min-width: 240px;
            max-width: 240px;
            font-size: 11px;

            h3, h4, p {
                font-size: 11px !important;
            }
        }

    }

}