#purchasing-edit-purchase-form {

    .center {

        .header {
            height: 136px !important;
            min-height: 136px !important;
            max-height: 136px !important;
            padding: 24px 0;

            .h1 {

                md-icon {
                    margin-right: 12px;
                }
            }


        }

        .content-card {

            .toolbar {
                padding: 8px;

                .action-buttons {

                    button.md-icon-button {
                        border: 1px solid #efefef;
                        height: 30px;
                        min-width: 30px;
                        min-height: 30px;
                        line-height: 30px;
                        padding: 0;
                        width: 30px;
                        border-radius: 50%;

                        md-icon {
                            font-size: 21px;
                            width: 21px;
                            height: 21px;
                            min-width: 21px;
                            min-height: 21px;
                            line-height: 21px;
                        }
                    }

                    .md-icon-button:hover {
                        background-color: #efefef;
                    }

                    md-divider.vertical-divider {
                        border-top-width: 0;
                        border-right-width: 1px;
                        border-right-style: solid;
                        border-right-color: #e0e0e0;
                        height: 24px;
                        margin: 0 9px;
                    }
                }
            }

            .content {
                padding: 0 0 90px 0;
                position: relative;

                .m-table-container {

                    .md-icon-button {
                        margin: 0;
                        height: 18px;
                        min-width: 18px;
                        min-height: 18px;
                        line-height: 18px;
                        padding: 0;
                        width: 18px;
                        border-radius: 50%;

                        md-icon {
                            font-size: 18px;
                            width: 18px;
                            height: 18px;
                            min-width: 18px;
                            min-height: 18px;
                            line-height: 18px;
                        }
                    }

                    .m-table-cbox-table {
                        display: table-cell;
                        padding-left: 3px;
                        left: 0px;

                        .m-table-cbox-title-row {

                            .m-table-cbox-title-cell {
                                width: 36px;
                                height: 100px;
                                vertical-align: middle;

                                .table-header {
                                    height: 90px;
                                }
                            }
                        }

                        .m-table-cbox-column-row {

                            .m-table-cbox-column-cell {
                                height: 84px;
                                text-align: center;
                                
                                md-checkbox {
                                    margin-bottom: 0px;
                                    min-width: 21px;
                                    min-height: 21px;
                                    margin-right: auto;
                                    margin-left: auto;
                                    width: 21px;
                                    height: 21px;
                                }
                            }
                        }
                    }

                    .m-table-cbox-table.ng-hide {
                        left: -33px;
                    }
                    
                    .m-table-fixed-column-table {
                        display: table-cell;

                        .m-table-header-title-row {

                            .m-table-header-title-cell {
                                width: 175px;
                                height: 100px;
                                vertical-align: middle;
                                padding: 0px;

                                .table-header {
                                    height: 90px;
                                    text-align: center;
                                    background: #004566;
                                    color: #ffffff;
                                    font-weight: bold;
                                    font-size: 12px;
                                }
                            }
                        }

                        .m-table-fixed-column-row {

                            .m-table-fixed-column-cell {
                                width: 175px;
                                height: 75px;
                                vertical-align: middle;
                                padding: 0px;
                                font-size: 11px;

                                .item-name {
                                    height: 21px;
                                    text-align: left;
                                    border: 1px solid #d5d6d6;
                                    background: #d5d6d6;
                                    color: #000000;
                                    padding: 3px;
                                }

                                .description {
                                    color: gray;
                                    border: 1px solid #c5c5c5;
                                    border-top-style: none;
                                    padding: 3px;
                                    height: 21px;
                                    width: 175px;
                                    max-width: 175px;
                                }

                                .details {
                                    border: 1px solid #c5c5c5;
                                    border-top-style: none;
                                    padding: 3px;
                                    display: block;
                                    height: 21px;

                                    .status {
                                        // float: left;
                                    }
                                    .quantity {
                                        float: right;
                                    }
                                }

                                .actions {
                                    color: gray;
                                    border: 1px solid #c5c5c5;
                                    border-top-style: none;
                                    padding: 3px;
                                    height: 21px;
                                }
                            }
                        }
                    }

                    .m-table-qtable {
                        display: table-cell;

                        .m-table-header-row {
                            height: 100px;

                            .m-table-header-cell {
                                width: 175px;
                                height: 100px;
                                padding: 0px;

                                .supplier {
                                    height: 21px;
                                    text-align: center;
                                    background: #004566;
                                    color: #ffffff;
                                    font-size: 11px;

                                     .name {
                                        line-height: 21px;
                                        float: left;
                                        padding-left: 5px;
                                        max-width: 165px;
                                        width: 165px;
                                     }
                                }

                                .desc {
                                    height: 24px;
                                    text-align: left;
                                    border: 1px solid #808080;
                                    background: #e9e9e9;
                                    color: #000;
                                    font-size: 10px;
                                    padding: 3px;

                                    .icon {
                                        float: left;
                                    }

                                    .remarks {
                                        float: left;
                                        margin-left: 5px;
                                        max-width: 144px;
                                        width: 144px;
                                    }
                                }

                                .details {
                                    height: 24px;
                                    text-align: left;
                                    border: 1px solid #808080;
                                    border-top: none;
                                    background: #e9e9e9;
                                    color: #000;
                                    font-size: 10px;
                                    padding: 3px;
                                }

                                .actions {
                                    height: 21px;
                                    text-align: center;
                                    vertical-align: middle;
                                    border: 1px solid #808080;
                                    border-top: none;
                                    background: #e9e9e9;
                                    color: #000;
                                    font-size: 10px;
                                    padding: 1px;

                                    .merlon-link {
                                        margin-left: 3px;
                                        margin-right: 3px;
                                        cursor: pointer;
                                    }
                                }
                            }
                        }

                        .m-table-qrow {

                            .m-table-qcell {
                                width: 175px;
                                height: 75px;
                                vertical-align: middle;
                                padding: 0px;
                                font-size: 11px;
                                color: #353535;

                                &.selected {
                                    background-color: #b4ffb4;
                                }

                                .desc {
                                    height: 21px;
                                    color: gray;
                                    border: 1px solid #c5c5c5;
                                    padding: 3px;
                                    width: 175px;
                                    max-width: 175px;
                                    background-color: #e9e9e9;
                                }

                                .unit-price {
                                    height: 21px;
                                    border: 1px solid #c5c5c5;
                                    border-top-style: none;
                                    padding: 3px;

                                    .label {
                                        float: left;
                                    }

                                    .value {
                                        float: right;
                                    }
                                }

                                .discount {
                                    height: 21px;
                                    border: 1px solid #c5c5c5;
                                    border-top-style: none;
                                    padding: 3px;
                                    display: block;

                                    .disc-1 {
                                        float: left;
                                    }

                                    .disc-2 {
                                        float: right;
                                    }
                                }

                                .total {
                                    height: 21px;
                                    border: 1px solid #c5c5c5;
                                    border-top-style: none;
                                    padding: 3px;

                                    .label {
                                        float: left;
                                    }

                                    .value {
                                        float: right;
                                        font-weight: 600;
                                    }
                                }
                            }

                            .m-table-qcell:hover {
                                background-color: #b4ffb4;
                                cursor: pointer;
                            }
                        }
                    }
                }

                .m-table-footer-title-container {
                    padding-top: 9px;
                    float: left;

                    .m-table-footer-table {

                        .m-table-footer-row {

                            .m-table-footer-cell {
                                width: 175px;
                                min-width: 175px;
                                max-width: 175px;
                                background-color: #004566;
                                color: #ffffff;
                                font-size: 10px;
                                font-weight: 500;
                                height: 21px;
                                text-align: right;

                                span {
                                    margin-right: 3px;
                                }
                            }
                        }
                    }
                }

                .fix-container-wrapper {
                    position: fixed;
                    left: 33px;
                    right: 33px;
                    bottom: 0px;
                    background-color: #ffffff;

                    .m-table-footer-title-fix-container {
                        padding: 9px 0;
                        float: left;
    
                        .m-table-footer-table {
    
                            .m-table-footer-row {
    
                                .m-table-footer-cell {
                                    width: 175px;
                                    min-width: 175px;
                                    max-width: 175px;
                                    background-color: #004566;
                                    color: #ffffff;
                                    font-size: 10px;
                                    font-weight: 500;
                                    height: 21px;
                                    text-align: right;
    
                                    span {
                                        margin-right: 3px;
                                    }
                                }
                            }
                        }
                    }
                }

                .m-table-footer-container {
                    padding: 9px 0;
                    display: flex;

                    .m-table-footer-table {

                        .m-table-footer-row {

                            .m-table-footer-cell {
                                width: 175px;
                                min-width: 175px;
                                max-width: 175px;
                                height: 21px;
                                border: 1px solid #c5c5c5;
                                background-color: #d5d6d6;
                                color: #000000;
                                padding-right: 3px;
                                padding-left: 3px;
                                font-weight: 540;
                                font-size: 12px;
                                text-align: right;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .m-table-footer-title-container {
        // padding-left: 30px!important;
    }
}
