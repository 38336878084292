#process-parameters-sidenav.sidenav {
    width: 320px;
    min-width: 320px;
    max-width: 320px;
    padding: 0;

    &._md-locked-open {
        width: 320px;
        min-width: 320px;
        max-width: 320px;
        overflow: hidden;
    }

    .header {
        height: 90px;
        min-height: 90px;
        max-height: 90px;
        padding: 24px 24px;
        background-color: rgb(7, 138, 202);

        .h1 {
            .title {
                font-size: 18px;
                line-height: 18px;
            }

            md-icon {
                margin-right: 12px;
            }
        }

        .item-name {
            margin: 6px 0 0 30px;
        }
    }

    .content {
        position: relative;
        background: #FFFFFF;
        padding: 24px;

        .widget-group {
    
            >.ms-widget {
                padding-right: 0;
                padding-left: 0;

                .ms-widget-front {

                    div.head {

                        .h3 {
                            font-size: 15px;
                        }
                    }

                    div.stats {

                        .count {
                            font-size: 27px;
                            line-height: 27px;
                        }

                        .unit {
                            font-size: 1.2rem;
                            padding-left: 3px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $layout-breakpoint-sm) {
    #process-parameters-sidenav.sidenav.sidenav {
        width: 240px;
        min-width: 240px;
        max-width: 240px;
        font-size: 11px;

        h3, h4, p {
            font-size: 11px !important;
        }
    }
}