#console {
    
    > .header {
        height: 120px;
        min-height: 120px;
        max-height: 120px;
        padding-bottom: 0;

        .h1 {

            md-icon {
                margin-right: 12px;
                color: #ffffff;
            }
        }
    }

    > .content {
        background: none;
        padding: 0;

        .widget-group {

            ms-widget {
                min-height: 252px;;
            }
        }
    }

    > .sidenav {
        width: 330px;
        min-width: 330px;
        max-width: 330px;
        padding: 0;
        z-index: 51;
        box-shadow: $whiteframe-shadow-8dp;

        &.md-locked-open {
            width: 250px;
            min-width: 250px;
            max-width: 250px;
            z-index: 2;
            box-shadow: none;
            background: material-color('grey', '200');
        }
    }
}