#purchasing-awaiting-approval {
    position: relative;
    height: auto;
    min-height: 100%;

    > .header {
        height: 90px;
        min-height: 90px;
        max-height: 90px;

        .h1 {

            md-icon {
                margin-right: 12px;
            }
        }

        md-icon {
            color: #ffffff;
        }

        .records-count {
            margin: 6px 0 0 36px;
        }

        .search-input-wrapper {
            position: relative;

            label {
                padding-right: 4px;
                cursor: pointer;

                md-icon {
                    color: rgba(255, 255, 255, 0.8);
                }

            }

            md-input-container {

                .md-errors-spacer {
                    display: none;
                }

                input {
                    border-bottom: 1px solid rgba(255, 255, 255, 0.8);
                    color: rgba(255, 255, 255, 0.8);
                    width: 240px;

                    &::placeholder {
                        color: rgba(255, 255, 255, 0.8);
                    }

                    &:focus {
                        border-bottom: 1px solid white;
                        color: white;
                    }
                }
            }
        }
    }

    > .content {
        position: absolute;
        top: 90px;
        bottom: 0;
        right: 0;
        left: 0;

        .main {
            padding: 0px 24px 0 24px;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            .data-list {
                position: relative;
                padding-bottom: 0;

                .md-subheader {
                    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
                    border-bottom: .1px solid #dadada;

                    .md-subheader-inner {
                        padding: 12px 16px;

                        .md-subheader-content {
                            font-size: 12px;
                            font-weight: 900;

                            .column-title {

                                &:last-child {
                                    width: 60px;
                                    text-align: center;
                                }

                                &:hover {
                                    cursor: pointer;
                                    color: #636363;
                                }
                            }
                        }
                    }
                }

                .list-item {
                    border-bottom: .1px solid #dadada;

                    span {
                        padding-right: 6px;
                    }

                    .edit-button {
                        width: 60px;
                        margin: 0;
                    }
                }
            }

            .no-results {
                box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
            }
        }
    }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-sm) {

    #purchasing-awaiting-approval {

        >.header {

            .h1 {
                font-size: 1.8rem;
            }

            .records-count {
                font-size: 1.1rem;
            }
        }

        > .content {

            .main {
                padding: 0;
                
                .data-list {
                    padding-top: 0;
                }
            }
        }
    }
}