md-dialog {

    &.item-group-dialog {
        max-width: 600px;
        width: 600px;

        md-toolbar {
            height: 60px;
            min-height: 60px;
            max-height: 60px;

            .title {
                font-size: 17px;
            }
        }

        md-dialog-content {
            display: block;
            position: relative;

            md-input-container {
                height: 58px;
                max-height: 58px;
                min-height: 58px;
            }

        }

        md-dialog-actions {
            position: relative;
            overflow-y: hidden;
            overflow-x: auto;
            justify-content: space-between;
            background-color: rgba(0, 0, 0, 0.03);
            border-top: 1px solid rgba(0, 0, 0, 0.12);
        }
    }
}