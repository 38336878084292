#toolbar {
    
    
        .md-virtual-repeat-container.md-autocomplete-suggestions-container {
            height: 24vh;
            min-height: 12vh;
            max-height: 24vh !important;
        }
    
        .login-panel {
            padding-right:20px;
            .link {
                font-size: 14px;
            }
        }
    
        .search-autocomplete-template {
        color: red;
        }
    
        .search-autocomplete-template li {
            border-bottom: 1px solid #ccc;
            height: auto;
            padding-top: 8px;
            padding-bottom: 8px;
            white-space: normal;
            min-width: 300px;
        }
        .search-autocomplete-template li:last-child {
            border-bottom-width: 0;
        }
        .search-autocomplete-template .item-title {
            display: block;
            line-height: 2;
            .city, .country {
                color: #757575;
            }
        }
    
        .search-autocomplete-template .show-all {
            display: block;
            line-height: 1;
            color: #757575;
            height: 12px;
            max-height: 12px;
        }
    
        .search-autocomplete-template .show-all .link {
            padding: 0px 10px 0px 10px;
        }
    
        .search-autocomplete-template .show-all .link:hover {
            cursor:pointer;
            color: rgb(3,155,229);
        }
    
    }
    
    
    #layout-vertical-navigation-fullwidth-toolbar-2 {
    
        #toolbar {
    
    
    
            .logo {
                width: $navigationWidth;
                padding: 0 16px;
    
                .logo-image {
                    display: block;
                    background: material-color('light-blue', '600');
                    width: 32px;
                    min-width: 32px;
                    height: 32px;
                    line-height: 32px;
                    text-align: center;
                    color: #FFFFFF;
                    font-size: 16px;
                    font-weight: 500;
                    border-radius: 2px;
                }
    
                .logo-text {
                    color: rgba(0, 0, 0, 0.87);
                    margin-left: 16px;
                    font-size: 16px;
                }
    
                & :hover {
                    cursor: pointer;
                }
            }
    
            .search-panel {
                width: 300px;
            }
    
            .navigation-fold-toggle {
                width: $toolbarHeight;
                min-width: $toolbarHeight;
                height: $toolbarHeight;
                margin: 0;
                border-right: 1px solid rgba(0, 0, 0, 0.12);
                border-radius: 0;
    
                md-icon {
                    transition: transform 0.3s ease-in-out 0.1s;
                    transform: rotate(0deg);
                }
            }
    
            .shortcut {
                position: relative;
    
                .badge {
                    top: 4px;
                    left: 50%;
                    height: 14px;
                    line-height: 16px;
                    background-color: #f44336;
                    border-radius: 50%;
                    z-index: 1;
                    position: absolute;
                    min-width: 14px;
                    font-size: 11px;
                    font-weight: 400;
                    text-align: center;
                    padding: 0 4px;
                    color: #fff;
                }
            }
        }
    }
    
    // RESPONSIVE
    @media only screen and (min-width: $layout-breakpoint-sm) {
    
        .ms-navigation-folded {
    
            #layout-vertical-navigation-fullwidth-toolbar-2 {
    
                #toolbar {
    
                    .navigation-fold-toggle {
    
                        md-icon {
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }
    }
    
    @media only screen and (max-width: $layout-breakpoint-sm) {
    
        #layout-vertical-navigation-fullwidth-toolbar-2 {
    
            #toolbar {
    
                .logo {
                    width: auto
                }
            }
        }
    }