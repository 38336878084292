md-dialog {

    &.quotation-new-dialog {
        max-width: 1200px;
        width: 1200px;

        md-toolbar {
            height: 60px;
            min-height: 60px;
            max-height: 60px;

            .title {
                font-size: 17px;
            }
        }

        md-dialog-content {
            display: block;
            position: relative;
            height: 400px;
            max-height: 400px;
            min-height: 400px;
            font-size: 15px;

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                /* display: none; <- Crashes Chrome on hover */
                -webkit-appearance: none;
                margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
            }

            table {
                font-size: 12px;

                thead {
                    tr {
                        th {
                            padding: 6px 18px 6px 0px;
                            font-weight: 800;
                            border-bottom: none;
                            background-color: #eaeaea;
                            min-width: unset;
                        }

                        th:first-child {
                            padding-left: 0;
                        }

                        th:last-child {
                            padding-right: 0;
                        }

                        th.quantity {
                            text-align: right;
                            padding-right: 36px;  
                        }

                        th.unit-price, th.net-total, th.discount, th.discount2 {
                            text-align: right;
                            padding-right: 15px;
                        }
                    }
                }

                tbody {
                    tr {
                        padding-top: 0;
                        padding-bottom: 0;

                        td {
                            padding: 0 5px 0 0;
                            border-bottom: none;
                        }

                        td:first-child {
                            padding-left: 0;
                        }

                        td:last-child {
                            padding-right: 0;
                        }

                        md-input-container {
                            width: inherit;
                            margin: 0;

                            .md-errors-spacer {
                                min-height: 6px;
                            }
                        }

                        td.quantity {
                            width: 138px;
                            max-width: 138px;
                            min-width: 138px;
                            text-align: right;
                            padding-right: 30px;

                            input {
                                text-align: right;
                            }
                        }

                        td.item {
                            width: 240px;
                            max-width: 240px;
                            min-width: 240px;
                        }

                        td.description {
                            width: 120px;
                            max-width: 120px;
                            min-width: 120px;
                        }

                        td.unit-price, td.net-total {
                            width: 81px;
                            max-width: 81px;
                            min-width: 81px;
                            text-align: right;

                            input {
                                text-align: right;
                            }
                        }

                        td.discount, td.discount2 {
                            width: 63px;
                            max-width: 63px;
                            min-width: 63px;
                            text-align: right;

                            input {
                                text-align: right;
                            }
                        }

                    }

                    tr:first-child td {
                        padding-top: 6px;
                    }
                }


            }

            .header-col:first-child {
                margin-right: 60px;
            }

            md-input-container.select {
                margin-top: 0px;
                margin-bottom: 30px;
            }

            md-input-container.date {
                margin-top: 0px;
                margin-bottom: 6px;
            }

            md-input-container.text-area {
                margin-top: 3px;
                margin-bottom: 0px;
            }

        }

        md-dialog-actions {
            position: relative;
            overflow-y: hidden;
            overflow-x: auto;
            justify-content: space-between;
            background-color: rgba(0, 0, 0, 0.03);
            border-top: 1px solid rgba(0, 0, 0, 0.12);
        }
    }
}