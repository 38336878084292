md-dialog {

    &.delete-object-dialog {
        max-width: 500px;
        width: 500px;

        md-toolbar {
            .title {
                font-size: 17px;
            }
        }

        md-dialog-content {
            display: block;
            position: relative;
            font-size: 14px;

            .message {
                margin-bottom: 30px;
                font-weight: 500;
            }
        }

        md-dialog-actions {
            position: relative;
            overflow-y: hidden;
            overflow-x: auto;
            justify-content: space-between;
            background-color: rgba(0, 0, 0, 0.03);
            border-top: 1px solid rgba(0, 0, 0, 0.12);
        }
    }
}