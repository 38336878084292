#purchasing-edit-purchase-form {

    // Sidenav
    .sidenav.rfq-tickets-sidenav  {
        width: 320px;
        min-width: 320px;
        max-width: 320px;
        padding: 0;

        &._md-locked-open {
            width: 320px;
            min-width: 320px;
            max-width: 320px;
            overflow: hidden;
        }

        .header {
            height: 136px;
            min-height: 136px;
            max-height: 136px;
            padding: 24px 24px;
            // background-color: rgb(7, 138, 202);

            .title {
                font-size: 18px;
                color: #ffffff;
                margin-left: 15px;
            }

            md-icon {
                color: #ffffff;
            }  
        }

        .content {
            position: relative;
            background: #FFFFFF;
            padding: 24px 0;

            ms-widget {

                ms-widget-front {
                    .md-toolbar-tools {
                        height: 48px;
                        max-height: 48px;
                        // background-color: #073c56;
                    }

                    .mid-panel {
                        padding: 15px 9px;
                    }

                    .sub-box {
                        border-radius: 15px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $layout-breakpoint-sm) {
    #purchasing-edit-purchase-form {
        
        // Sidenav
        .sidenav.rfq-tickets-sidenav {
            width: 240px;
            min-width: 240px;
            max-width: 240px;
            font-size: 11px;

            h3, h4, p {
                font-size: 11px !important;
            }
        }

    }

}