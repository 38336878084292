#supplier {

    > .header {
        height: 120px;
        min-height: 120px;
        max-height: 120px;

        .goto-suppliers-button {
            margin-right: 16px;
        }

        .subtitle {
            margin: 6px 0 0 0;
        }

        .md-icon-button {
            
            &:hover {
                background-color: #f2f2f233;
            }

            md-icon {
                color: #fafafa;
            }

            &[disabled] {

                md-icon {
                    color: #fafafa69;
                }
            }
        }
    }

    > .content {
        padding: 24px;

        .detail-form-container {
            padding: 24px;
            margin-bottom: 24px;
            max-width: 990px;
    
            form {
                font-size: 12px!important;
    
                table.simple {
                    thead {
        
                        tr {
                            th {
                                padding: 0 5px 0 5px;
                            }
    
                            th.actions {
                                text-align: center;
                            }
        
                        }
        
                    }
        
                    tbody {
        
                        tr {
                            padding-top: 0;
                            padding-bottom: 0;
        
                            td {
                                padding: 0 5px 0 5px;
                                border-bottom: none;
                            }
        
                            td.actions {
                                text-align: center;
    
                                .md-button {
                                    min-width: auto;
                                    min-height: auto;
                                    line-height: unset;
    
                                    .icon-delete {
                                        font-size: 18px;
                                        width: 18px;
                                        height: 18px;
                                        min-width: 18px;
                                        min-height: 18px;
                                        line-height: 18px;
                                    }
                                }
                            }
    
                            md-input-container {
                                width: inherit;
                                margin: 0;
    
                                .md-errors-spacer {
                                    min-height: 6px;
                                }
                            }
                        }
        
                        tr:first-child td {
                            padding-top: 15px!important;
                        }
        
                    }
        
        
                }
        
                md-input-container {
                    margin-bottom: 12px;
                    margin-top: 12px;
                }
    
                .header-col:last-child {
                    margin-left: 15px;
                }
            }
        }

        .save-button {
            margin: 0;
        }
    }

}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-xs) {

    #supplier {

        > .content {

            .detail-form-container {

                form {
                    
                    .header-col:last-child {
                        margin-left: 0!important;
                    }
                }
            }
        }
    }

}
