#card-change-fail {

    .header {
        height: 160px;
        min-height: 160px;
        max-height: 160px;
        background-size: 100% auto;
    }

    .content {
        padding: 24px;
        background: #fff;
        margin: 12px auto;
        max-width: 480px;
        text-align: center;
        margin-top: 10%;

        md-icon {
            color: #F44336;
        }

        .md-button {
            text-transform: inherit;
        }

        p {
            text-align: justify;
        }
    }
}


@media only screen and (max-width: $layout-breakpoint-xs) {
    
    #card-change-fail {

        .content {
            margin: 0;
        }
    }
}