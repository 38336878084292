md-dialog {

    &.inventory-split-request-dialog {
        max-width: 720px;
        width: 720px;

        md-toolbar {
            height: 60px;
            min-height: 60px;
            max-height: 60px;

            .title {
                font-size: 17px;
            }
        }

        md-dialog-content {
            display: block;
            position: relative;
            height: 400px;
            max-height: 400px;
            min-height: 400px;
            font-size: 15px;

            table {
                thead {
                    tr {
                        th {
                            padding: 6px 18px 6px 0px;
                            font-weight: 800;
                            border-bottom: 1px solid #7d7d7d;
                        }

                        th:first-child {
                            padding-left: 0;
                        }

                        th:last-child {
                            padding-right: 0;
                        }

                        th.quantity {
                            text-align: right;
                            padding-right: 30px;
                        }
                    }
                }

                tbody {
                    tr {

                        td {
                            padding: 0 5px 0 0;
                            border-bottom: none;
                        }

                        td.quantity {
                            width: 120px;
                            max-width: 120px;
                            min-width: 120px;
                            text-align: right;

                            md-input-container {
                                width: inherit;

                                input {
                                    text-align: right;
                                }
                            }
                        }

                        td.unit {
                            width: 120px;
                            max-width: 120px;
                            min-width: 120px;

                            md-input-container {
                                width: inherit;
                            }
                        }

                        td.item {
                            width: 270px;
                            max-width: 270px;
                            min-width: 270px;

                            md-input-container {
                                width: inherit;
                            }
                        }

                        td.split-quantity {
                            width: 180px;
                            max-width: 180px;
                            min-width: 180px;

                            md-input-container {
                                width: 150px;
                                max-width: 150px;
                                min-width: 150px;

                                /* Chrome, Safari, Edge, Opera */
                                input::-webkit-outer-spin-button,
                                input::-webkit-inner-spin-button {
                                    -webkit-appearance: none;
                                    margin: 0;
                                }

                                /* Firefox */
                                input[type=number] {
                                    -moz-appearance:textfield;
                                }

                                input {
                                    text-align: end;
                                    border: none;
                                    background-color: #f7f7f7;
                                }
                            }

                        }

                        td:first-child {
                            padding-left: 0;
                        }
                    }

                    tr:first-child td {
                        padding-top: 20px;
                    }
                }


            }

            md-input-container {
                height: 40px;
                max-height: 40px;
                min-height: 40px;
                margin: 0;
                padding: 0 27px 0 0;
            }

        }

        md-dialog-actions {
            position: relative;
            overflow-y: hidden;
            overflow-x: auto;
            justify-content: space-between;
            background-color: rgba(0, 0, 0, 0.03);
            border-top: 1px solid rgba(0, 0, 0, 0.12);
        }
    }
}