#signup {
    height: 100%;
    overflow: hidden;
    background: url('/assets/images/backgrounds/auth-bg.jpg') no-repeat;
    background-size: cover;

    #signup-intro {
        padding: 128px;

        .logo {
            margin-bottom: 32px;
        }

        .title {
            font-size: 42px;
            font-weight: 300;
        }

        .description {
            padding-top: 15px;
            font-size: 14px;
            max-width: 600px;
            line-height: 24px;
        }
    }

    #signup-form-wrapper {
        width: 416px;
        min-width: 416px;
        max-width: 416px;
        height: 100%;
        background: #FFFFFF;

        #signup-form, #joinwithtalepnet-form {
            padding: 128px 48px 48px 48px;

            .logo {
                margin: 32px auto;
            }

            .title {
                font-size: 21px;
            }

            .description {
                padding-top: 8px;
            }

            form {
                width: 100%;
                text-align: left;
                padding-top: 32px;

                md-checkbox {
                    margin: 0;
                }

                .text-center {
                    color: red;
                    font-size: 11px;
                }

                .terms {
                    font-size: 13px;
                    margin: 16px 0 32px 0;

                    a {
                        margin-left: 4px;
                    }
                }

                .md-button[type=submit] {
                    width: 100%;
                    margin: 16px auto;
                    display: block;
                }
            }

            .login {
                margin: 32px auto 24px auto;
                width: 250px;
                font-weight: 500;

                .text {
                    margin-right: 8px;
                }

                .link {

                }
            }
        }

        #portaluser-form {
            padding: 128px 48px 48px 48px;

            .logo {
                margin: 32px auto;
            }

            .title {
                font-size: 21px;
            }

            .description {
                padding-top: 8px;
            }

            form {
                width: 100%;
                text-align: left;
                padding-top: 32px;

                .md-button {
                    width: 100%;
                    margin: 16px auto;
                    display: block;
                }
            }
        }
    }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-sm) {

    #signup {

        #signup-form-wrapper {
            width: 100%;
            min-width: 100%;
            max-width: 100%;
            padding: 16px;

            #signup-form {
                padding: 24px;
                text-align: center;
            }
        }
    }
}