#organization-chart {
    position: relative;
    height: auto;
    min-height: 100%;

    > .header {
        height: 90px;
        min-height: 90px;
        max-height: 90px;

        .h1 {

            md-icon {
                margin-right: 12px;
            }
        }

        md-icon {
            color: #ffffff;
        }

        .records-count {
            margin: 6px 0 0 36px;
        }

        .search-input-wrapper {
            position: relative;

            label {
                padding-right: 4px;
                cursor: pointer;

                md-icon {
                    color: rgba(255, 255, 255, 0.8);
                }

            }

            md-input-container {

                .md-errors-spacer {
                    display: none;
                }

                input {
                    border-bottom: 1px solid rgba(255, 255, 255, 0.8);
                    color: rgba(255, 255, 255, 0.8);
                    width: 240px;

                    &::placeholder {
                        color: rgba(255, 255, 255, 0.8);
                    }

                    &:focus {
                        border-bottom: 1px solid white;
                        color: white;
                    }
                }
            }
        }
    }

    > .content {
        position: absolute;
        top: 90px;
        bottom: 0;
        right: 0;
        left: 0;

        .main {
            padding: 0px 24px 0 24px;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            .data-list {
                position: relative;
                padding-bottom: 0;

                .list-item {
                    padding: 0 45px;

                    &:hover {
                        cursor: initial;
                    }

                    .node {
                        height: 48px;

                        .btn-expand-wrapper {

                            .expand-button, .collapse-button {
                                margin: 0;
                                padding: 9px;
                                width: auto;
                                height: auto;
                            }

                            .no-button {
                                width: 42px;
                                min-width: 42px;
                                max-width: 42px;
                                display: flex;
                            }
                        }

                        .pos-btn {
                            border-radius: 9px;
                            text-transform: initial;
                            width: 210px;
                            text-align: left;
                            padding-left: 12px;

                            > span {

                                &.inactive {
                                    color: #a7a7a7;
                                }
                            }
                        }

                        .name {
                            padding-left: 3px;
                        }
                    }
                }
            }

            .no-results {
                box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
            }
        }
    }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-sm) {

    #organization-chart {

        .btn-expand-wrapper {
            width: 66px;
        }

        >.header {

            .h1 {
                font-size: 1.8rem;
            }

            .records-count {
                font-size: 1.1rem;
            }
        }

        > .content {

            .main {
                padding: 0;

                .btn {
                    margin-right: 8px;
                  }
            }
        }
    }
}