#process {

    md-content {
        //width: -webkit-fill-available;

        // Center
        .center {

            .header {
                padding: 24px 0;

                .sidenav-toggle {
                    margin: 0;
                    width: 56px;
                    height: 56px;
                    background: #FFFFFF;
                    border-radius: 0;
                    border-right: 1px solid rgba(0, 0, 0, 0.12);
                }
            }

            .toolbar {
                md-icon.warehouse-object {
                    color:#fcf4ab
                }
            }

            .content-card {
                // overflow: scroll;
                // height: calc(100vh - 64px);
                // width: calc(100vw - 240px);

                .toolbar {
                    padding: 8px 16px;
                    position: relative;

                    .process-status {
                        margin-right: 50px;
                        margin-left: 20px;
                        font-size: 16px;
                    }
                }

                .content {
                    padding: 0 30px;
                    overflow: hidden;
                    min-height: 1000px;
                    min-width: calc(100vw);
                    font-family: sans-serif;

                    #chart_div {

                        .google-visualization-orgchart-connrow-medium {
                            height: 25px;
                        }

                        .google-visualization-orgchart-node-medium {
                            min-width: 111px;
                        }

                        .approval-option {
                            position: relative;
                            top: -25px;
                            background-color: #fff;
                            padding: 0px;
                        }

                        .approval-node {
                            
                            .user-container {
                                display: flex;
                                width: max-content;
                            
                                img {
                                    height: 40px;
                                    border-radius: 50%;
                                }
                                .user-name {
                                    padding-left: 10px;
                                }
                            }                
                        }

                        .termination-node {
                            font-weight: 600;                         
                        }

                        .node-buttons-wrapper {
                            display: none;
                            position: relative;
                            left: -27px;
                            width: 45px;
                            top: -36px;
                            height: 0;

                            .icon-pencil {
                                color: darkgrey;
                            }

                            .icon-delete {
                                color: darkgrey;
                            }

                            > div {
                                width: 21px;
                                /* padding: 3px; */
                                background-color: #ffffff;
                                border: 1.5px solid #e3ca4b;
                                border-radius: 3px;
                                margin-bottom: 1px;
                            }

                            > div:hover {
                                background-color:#e3ca4b;
                                color: #ffffff;
                                cursor: pointer;

                                .icon {
                                    color: #ffffff;
                                }
                            }
                        }

                    }

                    .node-buttons-wrapper2 {
                        position: absolute;
                        display: inline-flex;

                        .icon-pencil {
                            color: darkgrey;
                        }

                        .icon-delete {
                            color: darkgrey;
                        }

                        > div {
                            padding: 1px 3px;
                            background-color: #ffffff;
                            border: 1.5px solid #e3ca4b;
                            border-radius: 3px;
                            margin-bottom: 1px;
                        }

                        > div:hover {
                            background-color:#e3ca4b;
                            color: #ffffff;
                            cursor: pointer;

                            .icon {
                                color: #ffffff;
                            }
                        }
                    }
                }
            }
        }
    }

    .options-button {
        position: absolute;
        top: 160px;
        right: 0;
        z-index: 50;
        -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
        width: 48px;
        height: 48px;
        line-height: 48px;
        text-align: center;
        cursor: pointer;
        border-radius: 0;
        margin: 0;
        pointer-events: auto;
        opacity: 0.75;
    }
}

// 
@media only screen and (min-width: 960px) {

    #process {

        md-content {

            .center {
    
                .content-card {
    
                    .content {
                        min-height: 1000px;
                        min-width: calc(100vw - 240px);
                    }
                }
            }
        }
    }
}