/**
 *  Do not remove this comments bellow. It's the markers used by wiredep to inject
 *  sass dependencies when defined in the bower.json of your dependencies
 */
// bower:scss
// endbower

/**
 *  Do not remove this comments bellow. It's the markers used by gulp-inject to inject
 *  all your sass files automatically
 */
// injector
@import "core/scss/global.scss";
@import "core/directives/ms-card/templates/template-1/template-1.scss";
@import "core/directives/ms-card/templates/template-10/template-10.scss";
@import "core/directives/ms-card/templates/template-2/template-2.scss";
@import "core/directives/ms-card/templates/template-3/template-3.scss";
@import "core/directives/ms-card/templates/template-4/template-4.scss";
@import "core/directives/ms-card/templates/template-5/template-5.scss";
@import "core/directives/ms-card/templates/template-6/template-6.scss";
@import "core/directives/ms-card/templates/template-7/template-7.scss";
@import "core/directives/ms-card/templates/template-8/template-8.scss";
@import "core/directives/ms-card/templates/template-9/template-9.scss";
@import "core/directives/ms-stepper/templates/horizontal/horizontal.scss";
@import "core/directives/ms-stepper/templates/vertical/vertical.scss";
@import "core/theme-options/theme-options.scss";
@import "core/directives/ms-card/ms-card.scss";
@import "core/directives/ms-form-wizard/ms-form-wizard.scss";
@import "core/directives/ms-info-bar/ms-info-bar.scss";
@import "core/directives/ms-masonry/ms-masonry.scss";
@import "core/directives/ms-material-color-picker/ms-material-color-picker.scss";
@import "core/directives/ms-nav/ms-nav.scss";
@import "core/directives/ms-navigation/ms-navigation.scss";
@import "core/directives/ms-responsive-table/ms-responsive-table.scss";
@import "core/directives/ms-scroll/ms-scroll.scss";
@import "core/directives/ms-search-bar/ms-search-bar.scss";
@import "core/directives/ms-shortcuts/ms-shortcuts.scss";
@import "core/directives/ms-splash-screen/ms-splash-screen.scss";
@import "core/directives/ms-stepper/ms-stepper.scss";
@import "core/directives/ms-timeline/ms-timeline.scss";
@import "core/directives/ms-widget/ms-widget.scss";
@import "main/apps/inventory/dialogs/item/import/import-item-dialog.scss";
@import "main/apps/inventory/dialogs/item-group/import/import-item-group-dialog.scss";
@import "main/apps/purchasing/views/pending-requests/edit-purchase-form/purchasing-edit-purchase-form.scss";
@import "main/apps/purchasing/views/suppliers/import/import-suppliers.scss";
@import "main/apps/settings/dialogs/process/delete-object/delete-object-dialog.scss";
@import "main/apps/settings/dialogs/process/duplicate-process/duplicate-process-dialog.scss";
@import "main/apps/settings/dialogs/process/verification-warning/verification-warning-dialog.scss";
@import "main/apps/inventory/views/item-groups/import/import-item-groups.scss";
@import "main/apps/inventory/views/items/import/import-items.scss";
@import "messaging-panel/messaging-panel.scss";
@import "notifications-panel/notifications-panel.scss";
@import "toolbar/toolbar.scss";
@import "main/components/components.scss";
@import "main/shared/shared.scss";
@import "main/apps/inventory/inventory.scss";
@import "main/apps/my-requests/my-requests.scss";
@import "main/apps/purchase-approvals/purchase-approvals.scss";
@import "main/apps/purchasing/purchasing.scss";
@import "main/apps/request-approvals/request-approvals.scss";
@import "main/components/charts/charts.scss";
@import "main/apps/settings/settings.scss";
@import "main/components/widgets/widgets.scss";
@import "main/pages/card-change-fail/card-change-fail.scss";
@import "main/pages/card-change-success/card-change-success.scss";
@import "main/pages/console/console.scss";
@import "main/pages/profile/profile.scss";
@import "main/pages/subscription-success/subscription-success.scss";
@import "navigation/layouts/horizontal-navigation/navigation.scss";
@import "navigation/layouts/vertical-navigation/navigation.scss";
@import "navigation/layouts/vertical-navigation-fullwidth-toolbar-2/navigation.scss";
@import "messaging-panel/tabs/messaging/messaging-tab.scss";
@import "notifications-panel/tabs/notifications/notifications-tab.scss";
@import "toolbar/layouts/content-with-toolbar/toolbar.scss";
@import "toolbar/layouts/horizontal-navigation/toolbar.scss";
@import "toolbar/layouts/vertical-navigation-fullwidth-toolbar-2/toolbar.scss";
@import "main/pages/auth/forgot-password/forgot-password.scss";
@import "main/pages/auth/login/login.scss";
@import "main/pages/auth/signup/signup.scss";
@import "main/pages/auth/signup-invitation/signup-invitation.scss";
@import "main/pages/auth/user-actions/user-actions.scss";
@import "main/pages/errors/404/error-404.scss";
@import "main/pages/errors/500/error-500.scss";
@import "main/shared/dialogs/request-deliver/request-deliver-dialog.scss";
@import "main/shared/sidenavs/inventory-level/inventory-level-sidenav.scss";
@import "main/shared/sidenavs/purchase-process/purchase-process-sidenav.scss";
@import "main/shared/sidenavs/request-process/request-process-sidenav.scss";
@import "main/apps/goods-receipts/views/add/goods-receipts-add.scss";
@import "main/apps/goods-receipts/views/edit/goods-receipts-edit.scss";
@import "main/apps/goods-receipts/views/list/goods-receipts-list.scss";
@import "main/apps/goods-receipts/sidenavs/supplier-orders/supplier-orders-sidenav.scss";
@import "main/apps/inventory/dialogs/item/item-dialog.scss";
@import "main/apps/inventory/dialogs/item-group/item-group-dialog.scss";
@import "main/apps/inventory/dialogs/request-split/request-split-dialog.scss";
@import "main/apps/inventory/dialogs/unit-code/unit-code-dialog.scss";
@import "main/apps/my-requests/dialogs/request/request-dialog.scss";
@import "main/apps/my-requests/views/my-completed-requests/my-completed-requests.scss";
@import "main/apps/my-requests/views/my-ongoing-requests/my-ongoing-requests.scss";
@import "main/apps/purchase-approvals/views/completed/completed-purchase-approvals.scss";
@import "main/apps/purchase-approvals/views/pending/pending-purchase-approvals.scss";
@import "main/apps/purchase-invoices/views/add/purchase-invoices-add.scss";
@import "main/apps/purchase-invoices/sidenavs/supplier-orders/supplier-orders-sidenav.scss";
@import "main/apps/purchase-invoices/views/edit/purchase-invoices-edit.scss";
@import "main/apps/purchase-invoices/views/list/purchase-invoices-list.scss";
@import "main/apps/purchasing/bottomnavs/purchase-analysis-chart/purchase-analysis-chart.scss";
@import "main/apps/purchasing/dialogs/import-supplier/import-supplier-dialog.scss";
@import "main/apps/purchasing/dialogs/order-email/order-email-dialog.scss";
@import "main/apps/purchasing/dialogs/quotation-edit/quotation-edit-dialog.scss";
@import "main/apps/purchasing/dialogs/quotation-email/quotation-email-dialog.scss";
@import "main/apps/purchasing/dialogs/quotation-new/quotation-new-dialog.scss";
@import "main/apps/purchasing/dialogs/quotation-print/quotation-print-dialog.scss";
@import "main/apps/purchasing/sidenavs/contacts/main-sidenav.scss";
@import "main/apps/purchasing/sidenavs/draft-purchase/draft-purchase-sidenav.scss";
@import "main/apps/purchasing/sidenavs/contracted-suppliers/contracted-suppliers-sidenav.scss";
@import "main/apps/purchasing/sidenavs/previous-quotations/previous-quotations-sidenav.scss";
@import "main/apps/purchasing/sidenavs/rfq-tickets/rfq-tickets-sidenav.scss";
@import "main/apps/purchasing/views/awaiting-approval/purchasing-awaiting-approval.scss";
@import "main/apps/purchasing/views/completed-purchases/purchasing-completed-purchases.scss";
@import "main/apps/purchasing/views/pending-requests/purchasing-pending-requests.scss";
@import "main/apps/purchasing/views/orders-placed/purchasing-orders-placed.scss";
@import "main/apps/purchasing/views/completed-requests/purchasing-completed-requests.scss";
@import "main/apps/purchasing/views/purchase-contract/purchase-contract.scss";
@import "main/apps/purchasing/views/purchase-contracts/purchase-contracts.scss";
@import "main/apps/purchasing/views/purchase-dashboard/purchase-dashboard.scss";
@import "main/apps/purchasing/views/supplier/supplier.scss";
@import "main/apps/purchasing/views/suppliers/suppliers.scss";
@import "main/apps/purchasing/views/tobe-ordered/purchasing-tobe-ordered.scss";
@import "main/apps/purchase-invoices/sidenavs/goods-receipts/goods-receipts-sidenav.scss";
@import "main/apps/request-approvals/views/completed/completed-request-approvals.scss";
@import "main/apps/request-approvals/views/pending/pending-request-approvals.scss";
@import "main/apps/settings/dialogs/account-user/account-user-dialog.scss";
@import "main/apps/settings/dialogs/branch/branch-dialog.scss";
@import "main/apps/settings/dialogs/change-plan/change-plan-dialog.scss";
@import "main/apps/settings/dialogs/payment-method/payment-method-dialog.scss";
@import "main/apps/settings/dialogs/position/position-dialog.scss";
@import "main/apps/settings/navs/process-bottomnav-add/process-bottomnav-add.scss";
@import "main/apps/settings/navs/process-bottomnav-edit/process-bottomnav-edit.scss";
@import "main/apps/settings/navs/process-parameters-sidenav/process-parameters-sidenav.scss";
@import "main/apps/settings/views/account-settings/account-settings.scss";
@import "main/apps/settings/views/account-users/account-users.scss";
@import "main/apps/settings/views/branches/branches.scss";
@import "main/apps/settings/views/change-plan/change-plan.scss";
@import "main/apps/settings/views/company/company.scss";
@import "main/apps/settings/views/organization-chart/organization-chart.scss";
@import "main/apps/settings/views/parameters/parameters.scss";
@import "main/apps/settings/views/payment-method/payment-method.scss";
@import "main/apps/settings/views/process/process.scss";
@import "main/pages/profile/dialogs/upload-bg/uploadbg-dialog.scss";
@import "main/pages/profile/dialogs/upload-picture/uploadpicture-dialog.scss";
@import "main/pages/profile/tabs/about/about.scss";
@import "main/pages/profile/tabs/account/account.scss";
@import "main/apps/inventory/views/completed/inventory-completed-requests.scss";
@import "main/apps/inventory/views/in-progress/inventory-inprogress-requests.scss";
@import "main/apps/inventory/views/inventory-dashboard/inventory-dashboard.scss";
@import "main/apps/inventory/views/item-groups/item-groups.scss";
@import "main/apps/inventory/views/items/items.scss";
@import "main/apps/inventory/views/new/inventory-new-requests.scss";
@import "main/apps/inventory/views/orders-placed/inventory-orders-placed.scss";
@import "main/apps/inventory/views/ready-for-delivery/inventory-rfd-requests.scss";
@import "main/apps/inventory/views/unit-codes/unit-codes.scss";
// endinjector