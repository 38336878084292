#process-bottomnav-edit {
    padding: 0;
    min-height: 270px;
    height: 270px;
    background-color: #eeeeee;

    .buttons-panel {
        position: absolute;
        top: -33px;
        right: 0px;
        width: 90px;
        background-color: #e4e4e4;
        padding: 6px;
        // visibility:hidden;
        // opacity:0;
        // transition:visibility 0.3s linear,opacity 0.3s linear;

        .btn-process {
            margin-right: 9px;
        }

        .btn-process:hover {
            cursor: pointer;

            .icon-checkbox-marked-circle {
                color: green;
            }

            .icon-cancel {
                color: #f44336;
            }
        }
    }

    .green {
        background-color: green;
    }

    .yellow {
        background-color: yellow;
    }

    .current-object-panel {
        width: 240px;
        min-width: 240px;
        max-width: 240px;
        padding: 6px 12px;
        border-right: 1px solid lightgray;

        .object-header {
            height: 48px;
            padding: 6px 3px;

            .icon-delete {
                color: darkgrey;
                float: right;
            }

            .icon-delete:hover {
                background-color:#b1b1b1;
                color: #ffffff;
                cursor: pointer;
            }
        }

        .object-wrapper {
            border: 2px solid #e3ca4b;
            background-color: #fff7ae;
            background: -webkit-gradient(linear, left top, left bottom, from(#fff7ae), to(#eee79e));
            padding: 9px;
        }

        p {
            color: #505050;
            font-weight: 300;
            font-size: 14px;
        }
    }

    .process-panel {
        width: -webkit-fill-available;

        md-tabs {
            min-height: 180px;

            md-tabs-wrapper {
                background-color: #e4e4e4;;

                md-tab-data {
                    .md-tab {
                        text-transform: capitalize;
                    }
                }

                md-tabs-canvas {
                    md-tab-item:not([disabled]) {
                        color: #505050;
                        font-weight: 300;
                        text-transform: capitalize;
                    }
                }

            }
            
            md-tabs-content-wrapper {
    
                md-tab-content {
    
                    md-content {
                        background-color: #eeeeee;
                        padding: 12px;

                        .btn-add-process {
                            background-color: #f44336;
                        }

                        .condition-wrapper {
                            background-color: #fff;
                            padding: 18px 9px;
                            height: 100%;
                            width: 100%;
                            text-align: center;

                            .label {
                                margin-top: 21px;
                            }

                            i {
                                background-color: #d69279;
                                color: #fff;
                                border-radius: 10%;
                                padding: 6px;
                                position: relative;
                                top: 12px;
                            }
                        }

                        .action-wrapper {
                            background-color: #fff;
                            padding: 18px 9px;
                            height: 100%;
                            width: 100%;
                            text-align: center;

                            .label {
                                margin-top: 21px;
                            }

                            i {
                                background-color: #29b6f6;
                                color: #fff;
                                border-radius: 50%;
                                padding: 6px;
                                position: relative;
                                top: 12px;
                            }
                        }

                        .action-detail-wrapper {
                            width: -webkit-fill-available;
                            height: -webkit-fill-available;
                            background-color: #fff;
                            padding: 3px;

                            md-input-container {
                                width: -webkit-fill-available;

                                .md-icon {
                                    background-color: #23a8e8;
                                }
                            }



                        }

                        .child-process-wrapper {
                            background-color: #fff;
                            padding: 18px 9px;
                            height: 100%;
                            width: 100%;
                            text-align: center;
                            display: flex;

                            .buttons-wrapper {
                                position: absolute;
                                top: -6px;
                                right: 0;
                                background-color: white;

                                .icon-pencil {
                                    margin-right: 6px;
                                    color: darkgrey;
                                }

                                .icon-delete {
                                    color: darkgrey;
                                }

                                > div {
                                    width: 21px;
                                    padding: 3px;
                                }

                                > div:first-child {
                                    border-right: 1px solid #e4e4e4;
                                }

                                > div:hover {
                                    background-color:#b1b1b1;
                                    color: #ffffff;
                                    cursor: pointer;

                                    .icon {
                                        color: #ffffff;
                                    }
                                }
                            }

                            .label {
                                margin-top: 21px;
                                font-size: 12px;
                            }

                            .action {
                                i {
                                    background-color: #29b6f6;
                                    color: #fff;
                                    border-radius: 50%;
                                    padding: 6px;
                                    position: relative;
                                    top: 12px;
                                }
                            }

                            .condition {
                                i {
                                    background-color: #d69279;
                                    color: #fff;
                                    border-radius: 10%;
                                    padding: 6px;
                                    position: relative;
                                    top: 12px;
                                }
                            }


                        }
                        
                        .condition-wrapper:hover,
                        .action-wrapper:hover {
                            background-color: #ebf4f9;
                            cursor: pointer;
                        }

                        .condition-wrapper.selected,
                        .action-wrapper.selected {
                            border: 1.5px solid #29b6f6;
                        }
                    }
                }
            }
        }
    }


}

.md-checkbox-enabled.md-default-theme[selected] .md-icon, .md-checkbox-enabled[selected] .md-icon {
    background-color: rgb(35, 168, 232);
}