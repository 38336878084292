#change-plan {

    > .header {
        height: 120px;
        min-height: 120px;
        max-height: 120px;

        .goto-purchase-contracts-button {
            margin-right: 16px;
        }

        .subtitle {
            margin: 6px 0 0 0;
        }

        .md-icon-button {
            
            &:hover {
                background-color: #f2f2f233;
            }

            md-icon {
                color: #fafafa;
            }

            &[disabled] {

                md-icon {
                    color: #fafafa69;
                }
            }
        }
    }

    > .content {
        padding: 24px;

        >.widget-group {
            max-width: 990px;

            .md-button {
                text-transform: inherit;
                font-size: 1.3rem;
            }

            .ms-widget {

                &:first-child {
                    padding: 0 12px 12px 0;
                }

                &:last-child {
                    padding: 0 0 12px 12px;
                }
            } 
        }

        .form-container {
            margin-bottom: 24px;
            max-width: 990px;

            md-slider-container {

                >:first-child:not(md-slider) {
                    margin-right: 42px;
                }

                md-input-container {
                    max-width: 90px;
                    height: auto;

                    input {
                        font-size: 24px;
                    }
                }
            }

            .md-button {
                text-transform: inherit;
            }
    
            ul.price-table {
                list-style: none;
                background: #fff;
                margin-top: 12px;
                border: .9px solid #e1e1e6;
                border-radius: 4px;
                box-shadow: 0 0 3px 0 rgba(0,0,0,.1);
                padding: 0;
                width: 300px;

                li {
                    padding: 21px 15px;
                    margin: 0;
                    border-top: .9px solid #e1e1e6;

                    &:first-child {
                        border-top: none;
                    }

                    .inner-row {
                        display: flex;
                        flex-direction: row;

                        .inner-col {
                            flex: 50%;
                            -webkit-box-flex: 50%;
                            -ms-flex: 50%;
                            text-align: left;

                            .name {
                                font-size: 16px;
                                font-weight: 600;
                                color: #333d66;
                            }

                            .desc {
                                padding-top: 6px;
                                margin: 0;
                                color: #a3a3a7;
                            }

                            .price {
                                color: #7b7e8b;
                                font-size: 15px;
                                font-weight: 600;
                            }

                            .per-user {
                                padding-top: 6px;
                                margin: 0;
                                color: #a3a3a7;
                            }
                        }
                    }
                }
            }

            ul.details {
                list-style: none;
                margin-top: 12px;
                padding: 21px 0;
                margin-right: auto;
                margin-left: auto;
                width: auto; 

                li {
                    margin: 21px 0;

                    .layout-row {
                        justify-content: left;
                        text-align: left;

                        .layout-column {
                            text-align: left;
                            margin: 0;
                            padding: 0 18px 0 0;

                            &:first-child {
                                width: 60px;
                                margin: auto 0;
                            }

                            md-icon {
                                color: #039be5;
                            }

                            h3 {
                                margin: 0;
                                font-size: 21px;
                                font-weight: 500;
                                color: #333d66;
                            }

                            p {
                                color: #7b7e8b;
                                font-size: 15px;
                            }
                        }
                    }
                }
            }

            .calc-wrapper {
                background: #fff;
                margin-top: 12px;
                border: .9px solid #e1e1e6;
                border-radius: 4px;
                -webkit-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
                box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
                padding: 15px;
                margin-right: auto;
                margin-left: auto;
                width: 600px;

                h3 {
                    font-size: 18px;
                    padding: 6px;
                    margin: 0;
                }

                .row {
                    padding: 15px 0;

                    .col {
                        padding: 0 15px;
                        flex: 50;

                        input {
                            padding: 6px;
                            font-size: 15px;
                            text-align: center;
                            border: .6px solid #cecccc;
                            border-radius: 9px;

                            &[disabled] {
                                background-color: #f1f1ef;
                            }
                        }

                        .cta-button {
                            font-size: 15px;
                            line-height: 15px;
                            padding: 12px 30px;
                        }
                    }
                }
            }
        }
    }
}


// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-xs) {

    #change-plan {

        > .content {
    
            >.widget-group {
    
                .ms-widget {
    
                    &:first-child {
                        padding: 0 0 12px 0;
                    }
    
                    &:last-child {
                        padding: 0 0 12px 0;
                    }
                } 
            }
        }
    }
}
