#parameters {

    > .header {
        height: 120px;
        min-height: 120px;
        max-height: 120px;

        .h1 {

            md-icon {
                margin-right: 12px;
                color: #ffffff;
            }
        }
    }

}
