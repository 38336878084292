#layout-content-with-toolbar {

    #toolbar {
        padding: 0 0 0 16px;

        height: 0;
        min-height: 0;
        max-height: 0;
        
        md-select {
            margin: 0;
            font-size: 12px;
            color: #8c8c8c;
        }
    }
}